import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { EnterpriseAccountBasicInfo } from './api.ent.data';

export interface IUpdateEnterpriseAccountPathParameter {
    accountID: string;
}

export interface IUpdateEnterpriseAccountTxData {
    role?: string;
    accountGroupID?: string;
    personalData?: {
        firstName?: string;
        lastName?: string;
        companyName?: string;
        departmentName?: string;
    }
}

export class APIUpdateEnterpriseAccountManager extends APIBaseManager<IUpdateEnterpriseAccountPathParameter, void, IUpdateEnterpriseAccountTxData, EnterpriseAccountBasicInfo> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UpdateEnterpriseAccount';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: IUpdateEnterpriseAccountPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/accounts/' + pathParameters.accountID;
    }
}