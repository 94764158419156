<div class='modal-header'>
    <span class="modal-title">{{ title }}</span>
    <button #dlgClose type="button" class="close d-none" data-dismiss="modal">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class='modal-body'>
    <div class="text-center">
        <p>The page has been left idle for more than 10 minutes.</p>
        <p>Connection will automatically close in 2 minutes</p>
        <strong>{{ _idleTimeout | date:'mm:ss' }}</strong>
    </div>
    <div class="text-center mt-4">
        <button type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal"
            (click)="resetIdleTimeout()">
            {{ 'Keep use' | translate | capitalFirstWord }}
        </button>
    </div>
</div>
