import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';

export interface IListGroupDevicePathParameter {
    deviceGroupID: string;
}

export class APIListGroupDeviceManager extends APIBaseManager<IListGroupDevicePathParameter, void, void, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListDeviceInDevGroup';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IListGroupDevicePathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'deviceGroups/'  + pathParameters.deviceGroupID + '/devices';
    }
}