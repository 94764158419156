import { IScepInfo } from "../../../API/v1/Enterprise/scep/api.ent.scep.data";
import { DeviceInfo } from "../../device/data/device-info";

export enum ScepDeviceOperationMode {
    add = 'add',
    update = 'update',
    selectAll = 'selectAll'
}

export class ScepServerInfo {
    id: string;
    alias: string;
    subject: string;
    url: string;
    profile: string;
    keysize: number;
    usage: string;
    autoRenewDay: number;

    constructor() {
        this.id = '';
        this.usage = SCEP_USAGE_APP;
        this.keysize = 1024;
        this.autoRenewDay = 120;
        this.subject = '';
        this.url = '';
        this.profile = '';
    }

    assignFrom(scep: IScepInfo): void {
        this.subject = scep.settingData.subject;
        this.url = scep.settingData.providerURL;
        this.profile = scep.settingData.profile;
        this.keysize = scep.settingData.keySize;
        this.usage = Object.keys(scep.settingData.usage).find(usageName => scep.settingData.usage[usageName] === 'on') || SCEP_USAGE_EAP_ETHERNET;
        this.autoRenewDay = scep.settingData.renewalDaysBeforeExpiration;
    }

    copy(): ScepServerInfo {
        const s: ScepServerInfo = new ScepServerInfo();
        s.id = this.id;
        s.alias = this.alias;
        s.subject = this.subject || '';
        s.url = this.url;
        s.profile = this.profile || '';
        s.keysize = this.keysize;
        s.usage = this.usage;
        s.autoRenewDay = this.autoRenewDay;

        return s;
    }
}

export class ScepDeviceInfo {
    dev: DeviceInfo;
    devName: string;
    devMAC: string;
    devGroup: string;
    scep: {
        status: string;
        expiryDate: string;
        alias: string;
    };
}

export class ScepChallengeInfo {
    challengePassword: string;
    eapMethod: string;
    eapDomain: string;
    eapIdentity: string;

    constructor() {
        this.eapMethod = 'TLS';
    }

    isValid(): boolean {
        return !this.challengePassword || !this.eapIdentity ? false : true;
    }
}

export const SCEP_USAGE_APP: string = 'APP';
export const SCEP_USAGE_VPN: string = 'VPN';
export const SCEP_USAGE_EAP_ETHERNET: string = 'EAP-ETHERNET';
export const SCEP_USAGE_EAP_WIFI: string = 'EAP-WIFI';