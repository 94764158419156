<div class="pwd-block">
  <div class="container-fluid">
    <form #accountPwdForm="ngForm">
      <div class="form-group">
        <label for="pwdOld">Old Password</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fas fa-lock"></i>
            </span>
          </div>
          <input id="pwdOld" name="pwdOld" type="password" class="form-control" [(ngModel)]="_oldPwd" required
            #pwdOld="ngModel" aria-label="Input your old password">
        </div>
        <div [hidden]="pwdOld.valid || pwdOld.pristine" class="alert alert-danger">Old password is required</div>
      </div>
      <div class="form-group">
        <label for="pwdNew">New Password (at least {{ PWD_MIN_LEN }} digits)</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fas fa-lock"></i>
            </span>
          </div>
          <input #pwdNew="ngModel" id="pwdNew" name="pwdNew" type="password" class="form-control" [(ngModel)]="_newPwd"
            required [minlength]="PWD_MIN_LEN" validateEqual="pwdConfirm" reverse="true" autocomplete="new-password"
            aria-label="Input your new password">
        </div>
        <div *ngIf="pwdNew.invalid && (pwdNew.dirty || pwdNew.touched)" class="alert alert-danger">
          <div *ngIf="pwdNew.errors.required">
            'New password' is required
          </div>
          <div *ngIf="pwdNew.errors.minlength">
            'New password' must be at least {{ PWD_MIN_LEN }} characters long.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="pwdConfirm">Confirm your new password</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fas fa-lock"></i>
            </span>
          </div>
          <input #pwdConfirm="ngModel" id="pwdConfirm" name="pwdConfirm" type="password" class="form-control"
            [(ngModel)]="_confirmPwd" required validateEqual="pwdNew" autocomplete="new-password"
            aria-label="Confirm by input your new password again">
        </div>
        <div *ngIf="pwdConfirm.invalid && (pwdConfirm.dirty || pwdConfirm.touched)" class="alert alert-danger">
          <div *ngIf="pwdConfirm.errors.required">
            'Confirm password' is required
          </div>
          <div *ngIf="pwdConfirm.errors.validateEqual">
            'New password' and 'Confirm password' must match.
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="_page === _enumPage.submit" class="mt-2">
      <div class="progress">
        <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
          aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
          Updating the changes... Please wait...
        </div>
      </div>
    </div>
    <div *ngIf="_page === _enumPage.result">
      <div *ngIf="!_errorMessage; else templateHintError" class="my-2 green-3">
        <div>Change password success.</div>
        <div>Re-login is required.</div>
      </div>
      <ng-template #templateHintError>
        <div class="warning">
          {{ _errorMessage }}
        </div>
      </ng-template>
    </div>
    <div class="row control-block d-flex justify-content-center mt-4">
      <button *ngIf="_page === _enumPage.action" type="button" class="btn btn-sm btn-action btn-light"
        data-dismiss="modal" (click)="close()">
        <span class="fas fa-times-circle icon-left"></span>
        <span>Cancel</span>
      </button>
      <button *ngIf="_page === _enumPage.result && _errorMessage" type="button" class="btn btn-sm btn-action btn-light"
        data-dismiss="modal" (click)="close()">
        <span class="fas fa-times-circle icon-left"></span>
        <span>Close</span>
      </button>
      <button *ngIf="_page === _enumPage.action || _errorMessage" type="button" class="btn btn-sm btn-action btn-light"
        [disabled]="!accountPwdForm.form.valid" (click)="changeAccountPwd()">
        <span class="fas fa-check-circle icon-left"></span>
        <span>Change</span>
      </button>
      <button *ngIf="_page === _enumPage.result && !_errorMessage" type="button" class="btn btn-sm btn-action btn-light"
        data-dismiss="modal" (click)="logout()">
        {{ 'key-logout' | translate | capitalFirstWord }}
        <span class="fas fa-sign-out-alt ml-2"></span>
      </button>
    </div>
  </div>
</div>