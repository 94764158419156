import { Component, OnInit } from '@angular/core';
import { ReportConfig } from '../../../../API/v1/Report/api.report.data';
import { WeeklyReportSettingInfo } from '../report.data';
import { ReportService } from '../report.service';

@Component({
    templateUrl: './report-config.component.html',
    styleUrls: ['./report-config.component.css']
})
export class ReportConfigComponent implements OnInit {
    _config: WeeklyReportSettingInfo;

    _isLoading: boolean = false;
    _isUpdating: boolean = false;
    _loadErrorMessage: string;
    _updateErrorMessage: string;

    constructor(private reportSvc: ReportService) { }

    ngOnInit(): void {
        this._isLoading = true;
        this._loadErrorMessage = null;
        this.reportSvc.getReportSetting().subscribe((res: { config: ReportConfig, errorMessage?: string }) => {
            if (!res.errorMessage) {
                this._config = new WeeklyReportSettingInfo(res.config);
            }
            
            this._loadErrorMessage = res.errorMessage;
            this._isLoading = false;
        });
    }

    applyReportSetting(): void {
        if (!this._config.isValid()) {
            this._updateErrorMessage = 'Some settings are invalid. Please correct them and apply again';
            return;
        }

        this._isUpdating = true;
        this._updateErrorMessage = null;
        this.reportSvc.updateReportSetting(this._config.toConfig()).subscribe((res: { config: ReportConfig, errorMessage?: string }) => {
            if (!res.errorMessage) {
                this._config = new WeeklyReportSettingInfo(res.config);
            }

            this._updateErrorMessage = res.errorMessage;
            this._isUpdating = false;
        });
    }
}