import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../api.wrapper.base';
import { APICreateAccessKeyManager } from './api.accessKey.create';
import { APIDeleteAccessKeyManager } from './api.accessKey.delete';
import { APIListAccessKeyManager } from './api.accessKey.list';
import { APISigninAccessKeyManager } from './api.accessKey.signin';
import { APIUpdateAccessKeyManager } from './api.accessKey.update';
import { APIResetAccountPasswordManager } from './api.account.password.reset';
import { APIResetAccountPasswordVerifyManager } from './api.account.password.resetVerify';
import { APIUpdateAccountPasswordManager } from './api.account.password.update';
import { APIAccountSignupManager } from './api.account.signup';
import { APIAccountSignupVerifyManager } from './api.account.signupVerify';
import { APICreateAccountTokenManager } from './api.account.token.create';
import { APIValidateAccountTokenManager } from './api.account.token.validate';

export class APIAccountWrapper extends APIWrapperBase {
    Signup: APIAccountSignupManager;
    SignupVerify: APIAccountSignupVerifyManager;
    CreateAccessKey: APICreateAccessKeyManager;
    UpdateAccessKey: APIUpdateAccessKeyManager;
    ListAccessKey: APIListAccessKeyManager;
    DeleteAccessKey: APIDeleteAccessKeyManager;
    SigninAccessKey: APISigninAccessKeyManager;

    CreateAccountToken: APICreateAccountTokenManager;
    ValidateAccountToken: APIValidateAccountTokenManager;

    ResetAccountPassword: APIResetAccountPasswordManager;
    ResetAccountPasswordVerify: APIResetAccountPasswordVerifyManager;
    UpdateAccountPassword: APIUpdateAccountPasswordManager;

    constructor(private http: HttpClient) {
        super();

        this.Signup = new APIAccountSignupManager(http);
        this.SignupVerify = new APIAccountSignupVerifyManager(http);
        this.CreateAccessKey = new APICreateAccessKeyManager(http);
        this.UpdateAccessKey = new APIUpdateAccessKeyManager(http);
        this.ListAccessKey = new APIListAccessKeyManager(http);
        this.DeleteAccessKey = new APIDeleteAccessKeyManager(http);
        this.SigninAccessKey = new APISigninAccessKeyManager(http);

        this.CreateAccountToken = new APICreateAccountTokenManager(http);
        this.ValidateAccountToken = new APIValidateAccountTokenManager(http);

        this.ResetAccountPassword = new APIResetAccountPasswordManager(http);
        this.ResetAccountPasswordVerify = new APIResetAccountPasswordVerifyManager(http);
        this.UpdateAccountPassword = new APIUpdateAccountPasswordManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.Signup, 
            this.SignupVerify,
            this.CreateAccessKey,
            this.UpdateAccessKey,
            this.ListAccessKey,
            this.DeleteAccessKey,
            this.SigninAccessKey,

            this.CreateAccountToken,
            this.ValidateAccountToken,

            this.ResetAccountPassword,
            this.ResetAccountPasswordVerify,
            this.UpdateAccountPassword
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}