
export const NA_STATUS__OK: string = 'OK';
export const NA_STATUS__ACCOUNT__ID_NOT_EXISTS: string = 'ACCOUNT.ID_NOT_EXISTS';
export const NA_STATUS__APP__ID_NOT_EXISTS: string = 'APPLICATION.ID_NOT_EXISTS';
export const NA_STATUS__AUTH__FAIL: string = 'AUTHORIZATION.FAIL';
export const NA_STATUS__DATA__INVALID_INPUT: string = 'DATA.INVALID_INPUT';
export const NA_STATUS__GROUP__ID_NOT_EXISTS: string = 'GROUP.ID_NOT_EXISTS';
export const NA_STATUS__GROUP__ID_ALREADY_EXISTS: string = 'GROUP.ID_ALREADY_EXISTS';
export const NA_STATUS__OEM__ID_ALREADY_EXISTS: string = 'OEM.ID_ALREADY_EXISTS';
export const NA_STATUS__OEM__ID_NOT_EXISTS: string = 'OEM.ID_NOT_EXISTS';
export const NA_STATUS__PAIRING_CODE__EXPIRED: string = 'PAIRING_CODE.EXPIRED';
export const NA_STATUS__PAIRING_CODE__INVALID: string = 'PAIRING_CODE.INVALID';
export const NA_STATUS__PERMISSION__DENIED: string = 'PERMISSION.DENIED';
export const NA_STATUS__SYSTEM__INTERNAL_ERROR: string = 'SYSTEM.INTERNAL.ERROR';
export const NA_STATUS__SYSTEM__INTERNAL_FATAL_ERROR: string = 'SYSTEM.INTERNAL.FATAL_ERROR';
export const NA_STATUS__USER__ID_ALREADY_EXISTS: string = 'USER.ID_ALREADY_EXISTS';
export const NA_STATUS__USER__ID_NOT_EXISTS: string = 'USER.ID_NOT_EXISTS';
export const NA_STATUS__USER__NAME_NOT_EXISTS: string = 'USER.NAME_NOT_EXISTS';
export const NA_STATUS__USER__VALIDATION_FAIL: string = 'USER.VALIDATION_FAIL';
export const NA_STATUS__VIRTUAL_DEVICE__ID_NOT_EXISTS: string = 'VIRTUAL_DEVICE.ID_NOT_EXISTS';
export const NA_STATUS__VIRTUAL_DEVICE__PAIRING_ID_NOT_EXISTS: string = 'VIRTUAL_DEVICE.PAIRING_ID_NOT_EXISTS';
