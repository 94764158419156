import { Component } from '@angular/core';
import { DialogPage } from '../../../../../lib/common/common.data';
import { UserBaseDlgComponent } from '../user-dlg-base.component';
import { USER_DLG_GROUP_FUNC_NEW_CONTENT } from '../user-dlg-func.def';
import { UserGroupInfo } from '../../group/user-group.data';

@Component({
    templateUrl: '../user-dlg-base.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserGroupNewDlgComponent extends UserBaseDlgComponent<UserGroupInfo, { groupName?: string }> {

    ngOnInit(): void {
        this._action_txt = 'Add user group';
        this._action_icon = 'fa-plus';
        this._content_func_name = USER_DLG_GROUP_FUNC_NEW_CONTENT;
        
        this._isDataValid = true;
        this._page = DialogPage.action;

        this.other = {
            groupName: ''
        };

        super.ngOnInit();
    }

    submit(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;

        this.userSvc.createUserGroup(this.other.groupName).subscribe((res: { group: UserGroupInfo, isFault: boolean, errorMessage?: string }) => {
            this._errorMessage = res.errorMessage;
            this._page = DialogPage.result;
        });
    }
}