<div>
  <div class="mb-1">
    Following are the setting(s) to apply :<span *ngIf="!_config.hasChangeItems()" class="highlight">(nothing to
      apply)</span>
  </div>
  <div>
    <div class="net-category">
      <div class="title green-3">Ethernet</div>
      <ul>
        <li *ngFor="let changeItem of _config.ethernetChangeItems">
          <strong>{{ changeItem.langKey | translate | capitalFirstWord }} : </strong>
          <span *ngIf="changeItem.isChanged">
            {{ changeItem.origin }} &rarr;
            <span class="highlight">{{ changeItem.value }}</span>
          </span>
          <span *ngIf="!changeItem.isChanged">{{ changeItem.value }}</span>
        </li>
      </ul>
    </div>
    <div class="net-category">
      <div class="title green-3">Wi-Fi</div>
      <ul>
        <li *ngFor="let changeItem of _config.wifiChangeItems">
          <strong>{{ changeItem.langKey | translate | capitalFirstWord }} : </strong>
          <span *ngIf="changeItem.isChanged">
            <span *ngIf="!changeItem.secure">{{ changeItem.origin }}</span>
            <span *ngIf="changeItem.secure">********</span>
            &rarr;
            <span class="highlight">
              <span *ngIf="!changeItem.secure || changeItem.value === ''">{{ changeItem.value }}</span>
              <span *ngIf="changeItem.secure && changeItem.value != ''">********</span>
            </span>
          </span>
          <span *ngIf="!changeItem.isChanged">
            <span *ngIf="!changeItem.secure || changeItem.value === ''">{{ changeItem.value }}</span>
            <span *ngIf="changeItem.secure && changeItem.value != ''">********</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div class="note text-danger">
    *** Change the network setting may cause your device to be offline. Please make sure you have the correct setting.
  </div>
</div>
