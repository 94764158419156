<ng-template [ngIf]="_menu && _menu.support">
    <div *ngFor="let menu of _menu.children" [id]="menu.id" class="nav-group" [class.darkTheme]="_useDarkTheme" data-toggle="collapse"
        [attr.data-target]="menu.isLink && _collapseID ? '#' + _collapseID : ''">
        <div *ngIf="menu.support" class="nav-title" [class.isLink]="menu.isLink" [class.active]="isMenuActive(menu)" [class.expand]="menu.isExpand" [class.vertical]="_rootIconLayout === 'top'"
            (click)="expandMenu(menu); goToMenu(menu)">
            <ng-template [ngIf]="menu.icon">
                <svg class="icon">
                    <use [attr.xlink:href]="menu.icon"></use>
                </svg>
            </ng-template>
            <a *ngIf="menu.isLink; else templateMenuPureText" [id]="menu.id">
                {{ menu.name }}
            </a>
            <ng-template #templateMenuPureText>{{ menu.name }}</ng-template>
            <i *ngIf="menu.isLink && isMenuActive(menu)" class="fas fa-chevron-right icon-anchor ml-auto">
            </i>
            <ng-template [ngIf]="_rootIconLayout === 'left' && menu.children && menu.children.length > 0">
                <i class="fas icon-expand ml-auto"
                    [ngClass]="'fa-chevron-' + (menu.isExpand ? _foldIcon : _expandIcon)"></i>
            </ng-template>
        </div>
        <div *ngIf="menu.isExpand && menu.children" class="sub-menu">
            <na-top-menu [menu]="menu" [collapseID]="_collapseID" [useDarkTheme]="_useDarkTheme" (onMenuRouteChanged)="onChildMenuRouteChange($event)">
            </na-top-menu>
        </div>
    </div>
</ng-template>