import { Component, OnInit } from "@angular/core";
import { PluginProviderInfo } from "../../plugin.data";
import { WorkplacePluginService } from "../../plugin.service";
import { Router } from "@angular/router";
import { PluginHelper } from "../../plugin.helper";
import { concatMap, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { VirtualDlgComponent } from "app/content/virtual/dlg/virtual-dlg.component";
import { AccountService } from "app/entry/account.service";

@Component({
    templateUrl: './svcnow-register.component.html',
    styleUrls: ['./svcnow.style.css', './svcnow-register.component.css']
})
export class ServiceNowRegisterComponent extends VirtualDlgComponent implements OnInit {
    _isNewRegistration: boolean = false;
    _data: PluginProviderInfo;
    _iadeaAccessInfo: { accessKeyID: string, accessKeyRemark: string, isAccessKeyEnabled: boolean };
    _message: string;
    _loading: boolean = false;
    _appname: string;

    constructor(private router: Router, protected accountSvc: AccountService,  private pluginSvc: WorkplacePluginService) { 
        super(accountSvc)
    }

    ngOnInit(): void {
        this._appname = PluginHelper.getPluginAppCategory(this.router.url);

        this._loading = true;
        this.init(this._appname).subscribe((res: { isFault: boolean, errorMessage?: string, data?: PluginProviderInfo, iadeaCareAccessInfo?: { accessKeyID: string, accessKeyRemark: string, isAccessKeyEnabled: boolean } }) => {
            this._loading = false;
            if (res.isFault) {
                this._message = res.errorMessage;
            }
        });
    }

    private init(appname: string): Observable<{ isFault: boolean, errorMessage?: string, data?: PluginProviderInfo, iadeaCareAccessInfo?: { accessKeyID: string, accessKeyRemark: string, isAccessKeyEnabled: boolean } }> {
        this._data = null;
        this._iadeaAccessInfo = null;
        
        return this.pluginSvc.getCalendarProviderConfig(appname).pipe(
            map((res: { isFault: boolean, errorMessage?: string, data?: PluginProviderInfo, iadeaCareAccessInfo?: { accessKeyID: string, accessKeyRemark: string, isAccessKeyEnabled: boolean } }) => {
                if (!res.isFault) {
                    this._data = res.data;
                    this._iadeaAccessInfo = res.iadeaCareAccessInfo;
                    this._isNewRegistration = false;
                }

                return res;
            })
        );
    }

    createNewProviderTemplate(): void {
        this._message = null;
        this._data = new PluginProviderInfo();
        this._isNewRegistration = true;
    }

    resetProviderInfo(): void {
        this._data.reset();
    }

    createProviderInfo(): void {
        this._message = null;
        this._loading = true;
        this.pluginSvc.createCalendarProviderConfig(this._appname, this._data.export()).subscribe((res: { isFault: boolean, errorMessage?: string, data?: PluginProviderInfo, iadeaCareAccessInfo?: { accessKeyID: string, accessKeyRemark: string, isAccessKeyEnabled: boolean } }) => {
            this._loading = false;

            if (res.isFault) {
                this._message = res.errorMessage;
                return;
            }

            this._data = res.data;
            this._iadeaAccessInfo = res.iadeaCareAccessInfo;
            this._isNewRegistration = false;
        });
    }

    removeProviderInfo(): void {
        this._loading = true;
        this.pluginSvc.removeCalendarProviderConfig(this._appname).pipe(
            concatMap((res: { isFault: boolean, error?: number | string, errorMessage?: string, data?: any }) => {
                if (!res.isFault) {
                    return this.init(this._appname);
                }

                return of(res);
            })
        ).subscribe((res: { isFault: boolean, error?: number | string, errorMessage?: string, data?: any }) => {
            this._loading = false;

            if (res.isFault) {
                this._message = res.errorMessage;
            }

            this.closeDlg();
        });
    }

    updateProviderInfo(): void {
        this._message = null;
        this._loading = true;
        this.pluginSvc.updateCalendarProviderConfig(this._appname, this._data.export()).subscribe((res: { isFault: boolean, errorMessage?: string, data?: PluginProviderInfo, iadeaCareAccessInfo?: { accessKeyID: string, accessKeyRemark: string, isAccessKeyEnabled: boolean } }) => {
            this._loading = false;

            if (res.isFault) {
                this._message = res.errorMessage;
                return;
            }

            this._data = res.data;
            this._iadeaAccessInfo = res.iadeaCareAccessInfo;
        });
    }
}