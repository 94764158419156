import { Component } from "@angular/core";
import { VirtualDlgComponent } from "app/content/virtual/dlg/virtual-dlg.component";
import { IRemoteFuncCtrl } from "./remote-func.def";

@Component({
    templateUrl: './remote-dlg-sendMsg.component.html'
})
export class RemoteDlgSendMsgComponent extends VirtualDlgComponent implements IRemoteFuncCtrl {
    title: string;
    funcName: string;
    data: any;
    other?: any;
    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;

    sendMessage(txtEle: HTMLInputElement): void {
        this.onActionCompleted({ funcName: this.funcName, isFault: false, data: JSON.stringify({
            action: 'keystring',
            str: txtEle.value
        })});

        setTimeout(() => { txtEle.value = ''; }, 0);
    }
}