import { Component, Input } from '@angular/core';
import { ILicenseCategoryInfo } from '../../../../../app/API/v1/License/api.license.common';

@Component({
    selector: 'na-device-license-info',
    templateUrl: './device-info-license.component.html',
    styleUrls: ['./device-info-license.component.css']
})
export class DeviceInfoLicenseComponent {
    _hasLicense: boolean = false;
    _licenseInfo: {
        iCareLicense: ILicenseCategoryInfo,
        other: { [category: string]: ILicenseCategoryInfo }
        scopes: string[]
    };
    @Input('license')
    set license(v: any) {
        this._licenseInfo = v;
        if (this._licenseInfo) {
            if (this._licenseInfo.iCareLicense) {
                this._hasLicense = true;
            }
            if (Object.keys(this._licenseInfo.other).length > 0) {
                this._hasLicense = true;
            }
        }
    }
}