<div *ngIf="!_loading; else templateLoading">
    <div class="form-group row">
        <label for="col-account-license-type" class="col-12 col-sm-5 col-md-6">
            Account type
        </label>
        <div class="col-12 col-sm-7 col-md-6 form-control-plaintext">
            {{ isEnterprise ? 'Enterprise' : 'Standard' }}
        </div>
    </div>
    <!-- account license: Admin/Editor/Viewer -->
    <div *ngIf="isEnterprise">
        <div *ngFor="let accountLicense of _accountLicenseList" class="form-group row">
            <label for="col-account-license-admin" class="col-12 col-sm-5 col-md-6">
                {{ accountLicense.name }} license
            </label>
            <div class="col-12 col-sm-7 col-md-6 form-control-plaintext">
                <span class="red-1">{{ accountLicense.usage }}</span> / {{ accountLicense.total }}
            </div>
        </div>
    </div>
    <!-- remote control license -->
    <div class="form-group row">
        <label for="col-account-license-remotectrl" class="col-12 col-sm-5 col-md-6">
            Remote control license
            <ng-template [ngIf]="_remoteCtrlLicense">
                <i *ngIf="!_showRemoteCtrlDetailLicenses" class="fas fa-caret-down"
                    (click)="showRemoteCtrlLicense(true)"></i>
                <i *ngIf="_showRemoteCtrlDetailLicenses" class="fas fa-caret-up"
                    (click)="showRemoteCtrlLicense(false)"></i>
            </ng-template>
        </label>
        <div class="col-12 col-sm-7 col-md-6 form-control-plaintext">
            <span class="red-1">{{ _remoteCtrlLicense?.usage }}</span> / {{ _remoteCtrlLicense?.total }}, expiration
            date: ~ {{ _remoteCtrlLicense?.expiryDate | date: 'yyyy-MM-dd' }}
        </div>
    </div>
</div>
<ng-template #templateLoading>
    <div class="loading">
        <p>Please wait...</p>
        <div class="loading-box"></div>
    </div>
</ng-template>
<div *ngIf="_showRemoteCtrlDetailLicenses">
    <div class="list-group mt-2">
        <div class="list-group-item list-group-header">
            <div class="row">
                <div class="d-flex col title">
                    License code
                </div>
                <div class="d-flex col title">
                    Activated
                </div>
                <div class="d-flex col title">
                    Expiry date
                </div>
            </div>
        </div>
        <div class="list-group-content list-group-hoverless list-group-content-nr-3">
            <div *ngFor="let license of _remoteCtrlLicense.licenseKeyList;" class="list-group-item">
                <div class="row align-items-center">
                    <div class="col d-flex align-items-center text-break">
                       ***** - {{ license.licenseKeyPartialCode }}
                    </div>
                    <div class="col d-flex align-items-center text-break">
                        {{ license.licenseKeyActivatedDate ? 'v' : '' }}
                     </div>
                     <div class="col d-flex align-items-center text-break">
                        {{ license.licenseKeyExpiryDate | date:'yyyy-MM-dd' }}
                     </div>
                </div>
            </div>
        </div>
    </div>
</div>