import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IReportData } from './api.report.data';

export interface IListReportQueryParameter {
    skip: number;
    limit: number;
}

export interface IListReportRxData {
    itemList: IReportData[],
    limit: number,
    skip: number,
    total: number
}

export class APIListReportManager extends APIBaseManager<void, IListReportQueryParameter, void, IListReportRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListReport';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: IListReportQueryParameter): string {
        return super.getRequestURL() + 'accounts/weeklyReports'; // + this.transformObjectQueryParameterToStr(queryParameters);
    }
}