import { Type } from '@angular/core';

export const REMOTE_FUNC_DOWNLOADSCREENSHOT: string = 'remote-downloadScreenshot';
export const REMOTE_FUNC_SENDMESSAGE: string = 'remote-sendMessage';
export const REMOTE_FUNC_IDLEREMIND: string = 'remote-idleRemind';
export const REMOTE_FUNC_NOTIFICATION: string = 'remote-notification';

export class RemoteFuncItem {
  constructor(public component: Type<any>, public name: string, public title: string) {}
}

export interface IRemoteFuncCtrl {
  title: string;
  funcName: string;
  data: any;
  other?: any;
  onActionCompleted: (ret: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }) => void;
}