import { Component, ViewChild } from '@angular/core';

import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { DevFuncActionInterface } from '../../base/action/dev-func-action.interface';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';
import { NgForm } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: './script-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', '../../dev-func.style.css']
})
export class ScriptActionSubFormComponent extends AutoUnsubscribeComponent implements DevFuncActionInterface {
    readonly PWD_MIN_LEN: number = 4;

    actionCompleteHandler?: (result: any) => void;
    actionStatusUpdateHandler?: (isValid: boolean) => void;
    actionCommunicateHandler?: (data: any) => void;

    _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._actionData.subject = this._actionData.subject || 'Custom Instructions';
        }
    }

    private _scriptForm: NgForm;
    @ViewChild('scriptForm', { static: false })
    set scriptForm(f: NgForm) {
        if (f && !this._scriptForm) {
            this._scriptForm = f;
            if (this._scriptForm) {
                this._scriptForm.statusChanges.pipe(
                    takeUntil(this._unsubscribe$)
                ).subscribe((status: string) => {
                    this.actionStatusUpdateHandler(status === "VALID" ? true : false);
                });
            }
        }
    }

    constructor(private constantSvc: ConstantService) { 
        super();
    }
}