<div *ngIf="_hasLicense; else templateNoLicense">
  <div *ngIf="_licenseInfo?.iCareLicense" class="license-item">
    <div class="col-xl-4 col-5 license-category">{{ (_licenseInfo.iCareLicense.currentActivatedShowName ||
      _licenseInfo.iCareLicense.currentActivated) | mergeContentPipe }}</div>
    <div class="col-xl-8 col-7 license-content">
      <div *ngIf="_licenseInfo.iCareLicense.currentActivated && _licenseInfo.iCareLicense.currentActivated.length > 0">
      </div>
      <div>
        <i class="far fa-clock"></i> 
        {{ _licenseInfo.iCareLicense?.expiryDate | date:'yyyy-MM-dd Z' }}
        <span *ngIf="_licenseInfo.iCareLicense.isExpired" class="warning-block">Expired</span>
      </div>
    </div>
  </div>
  <div *ngFor="let item of _licenseInfo.other | keyvalue" class="license-item">
    <div class="col-xl-4 col-5 license-category">{{ (item.value.currentActivatedShowName || item.value.currentActivated) |
      mergeContentPipe }}</div>
    <div class="col-xl-8 col-7 license-content">
      <div>
        <i class="far fa-clock"></i> {{ item.value.expiryDate | date:'yyyy-MM-dd Z' }}
        <span *ngIf="item.value.isExpired" class="warning-block">Expired</span>
      </div>
    </div>
  </div>
</div>
<ng-template #templateNoLicense>
  No allocated license. Install one to enable more features.
</ng-template>