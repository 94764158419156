import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IGetVirtualDeviceOTPPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export interface IGetVirtualDeviceOTPQueryParameter {
    timestamp: number; //10 digit timestamp
}

export class APIGetVirtualDeviceOTPManager extends APIBaseManager<IGetVirtualDeviceOTPPathParameter, IGetVirtualDeviceOTPQueryParameter, void, string> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetVirtualDeviceOTP';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IGetVirtualDeviceOTPPathParameter, queryParameters: IGetVirtualDeviceOTPQueryParameter): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/' + pathParameters.virtualDevicePairedID + '/OTP' + this.transformObjectQueryParameterToStr(queryParameters);
    }
}