import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { DeviceAdvFilterInfo } from "./dev-adv-filter.data";
import { DeviceService } from "app/content/device/device.service";
import { Logger } from "app/lib/common/logger";
import { takeUntil } from "rxjs/operators";
import { DeviceInfo } from "app/content/device/data/device-info";
import { IGetVirtualDeviceAdvanceFilterRxData } from "app/API/v1/VirtualDevice/api.virtualDevice.advanceFilter.get";
import { AutoUnsubscribeComponent } from "app/content/virtual/auto-unsubscribe.component";
import { HelperLib } from "app/lib/common/helper.lib";

@Component({
    selector: 'na-dev-adv-filter-container',
    templateUrl: './dev-adv-filter-container.component.html',
    styleUrls: ['./dev-adv-filter-container.component.css']
})
export class DeviceAdvanceFilterContainerComponent extends AutoUnsubscribeComponent implements OnInit {
    _loading: boolean = false;
    _onlineStatusFilter: { [state: string]: boolean } = {};
    _isOnlineStatusFilterChanged: boolean = false;
    _advFilterBackup: DeviceAdvFilterInfo[] = [];
    _advFilters: DeviceAdvFilterInfo[] = [];
    _filter: DeviceAdvFilterInfo;

    @Input('isReady') _isReady: boolean = false;
    @ViewChild('dropdownMenuEvent') _dropdownMenuRef: ElementRef;

    get isFilterApplied(): boolean {
        return this._isOnlineStatusFilterChanged || this._advFilters.find(filter => !filter.hide && filter.changedOptionsCount > 0) ? true : false;
    }

    constructor(private devSvc: DeviceService) {
        super();
    }

    ngOnInit(): void {
        this.resetOnlineStatus();

        this.devSvc.deviceFilterApplied.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((res: { isApplied: boolean, devices?: DeviceInfo[], sourceFilters?: { rules?: string[], labels?: string[], onlineStatus?: { [state: string]: boolean }, search?: { key: string, value: string } } }) => {
            if (!res.isApplied) {
                this.reset();
            }
        });

        this._loading = true;
        this.devSvc.getAdvanceFilterList().subscribe((res: { isFault: boolean, data: IGetVirtualDeviceAdvanceFilterRxData[], errorMessage?: string }) => {
            this._advFilters = res.data.map(advFilter => new DeviceAdvFilterInfo(advFilter));
            this._filter = this._advFilters[0];

            this._loading = false;
            Logger.logInfo('AdvFilter', 'init', 'filter list', this._advFilters);
        });
    }

    openAdvanceFilter(): void {
        // copy current filters, should fallback to last filter settings if user just cancel the dropdowns
        this._advFilterBackup = this._advFilters.map(af => af.copy());
        this._filter = this._advFilters[0];
    }

    private resetOnlineStatus(): void {
        this._onlineStatusFilter = HelperLib.getOnlineStatusState().onlineStatus;
        this._isOnlineStatusFilterChanged = false;
    }

    changeOnlineStatusFilter(key: string, checked: boolean): void {
        this._onlineStatusFilter[key] = checked;

        // check if online-status-filter is changed.
        if (!this._onlineStatusFilter[key]) {
            this._isOnlineStatusFilterChanged = true;
        }
        else {
            this._isOnlineStatusFilterChanged = false;
            for (let status of Object.keys(this._onlineStatusFilter)) {
                if (!this._onlineStatusFilter[status]) {
                    this._isOnlineStatusFilterChanged = true;
                    break;
                }
            }
        }
    }

    selectFilterCategory(filter: DeviceAdvFilterInfo): void {
        this._filter = filter;
    }

    applySearch(): void {
        const filterContents: string[] = [];
        this._advFilters.forEach(filter => filterContents.push(...filter.export()));

        this._loading = true;
        this.devSvc.getDevicesByFilter({ rules: filterContents, onlineStatus: this._onlineStatusFilter }).subscribe((res: { isFault: boolean, devices?: DeviceInfo[], errorMessage?: string }) => { 
            this._loading = false;
            this._dropdownMenuRef.nativeElement.classList.remove('show');
        });
    }

    cancelSearch(): void {
        this._advFilters = this._advFilterBackup;
        this._filter = this._advFilters[0];
        this._advFilterBackup = null;
    }

    reset(): void {
        this.resetOnlineStatus();
        this._advFilters.forEach(filter => filter.reset());
    }
}