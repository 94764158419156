import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IDeleteCertPathParameter {
    certificateID: string;
}

export class APIDeleteCertManager extends APIBaseManager<IDeleteCertPathParameter, void, void, void> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_DeleteCert';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IDeleteCertPathParameter, queryParameters: void): string {
        return `${super.getRequestURL()}accounts/certificates/${pathParameters.certificateID}`;
    }
}