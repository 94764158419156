import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { DialogPage } from '../../../lib/common/common.data';
import { UserInfo } from './user.data';
import { UserService } from './user.service';
import { UserGroupInfo } from './group/user-group.data';
import { UserDlgFuncDirective } from './dlg/user-dlg-func.directive';
import { IUserDlgFuncCtrl, UserDlgFuncItem, USER_DLG_FUNC_EDITCONFIRM, USER_DLG_FUNC_REMOVE, USER_DLG_FUNC_RESETPWD } from './dlg/user-dlg-func.def';
import { UserDlgFuncService } from './dlg/user-dlg-func.service';
import { AccountService } from '../../../entry/account.service';

@Component({
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user.style.css', './user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
    _user: UserInfo;
    _userGroupName: string;
    _loading: boolean = false;
    _errorMessage: string;

    _isInEdit: boolean = false;
    _editData: { firstName: string, lastName: string, company: string, department: string, userRole: string, userGroupID: string, userGroupName: string };
    _editPage: DialogPage = DialogPage.prepare;
    _enumPage: typeof DialogPage = DialogPage;
    _availableUserRoleList: { name: string, desc: string }[] = [];
    _availableUserGroupList: UserGroupInfo[] = [];

    get isEnterpriseSSO(): boolean {
        return this.accountSvc.isEnterpriseSSO();
    }
    
    private _userDlgFuncHost: UserDlgFuncDirective;
    @ViewChild(UserDlgFuncDirective, { static: true })
    set userDlgFuncHost(host: any) {
        this._userDlgFuncHost = host;
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private accountSvc: AccountService,
        private userSvc: UserService,
        private userDlgFuncSvc: UserDlgFuncService
    ) { }

    ngOnInit(): void {
        this._loading = true;

        this.route.paramMap.pipe(
            switchMap((params: ParamMap) => {
                return this.userSvc.getUserByID(params.get('id'))
            })
        ).subscribe((res: { user: UserInfo, isFault: boolean, errorMessage?: string }) => {
            this._user = res.user;
            this.updateCurrentGroupName();

            this._errorMessage = res.errorMessage;
            this._loading = false;
        });

        this.userSvc.getUserRoleList().subscribe((res: { userRoleList: { name: string, desc: string }[], isFault: boolean, errorMessage?: string }) => {
            if (!res.isFault) {
                this._availableUserRoleList = res.userRoleList;
            }
        });

        this.userSvc.getUserGroupList().subscribe((res: { userGroupList: UserGroupInfo[], isFault: boolean, errorMessage?: string }) => {
            if (!res.isFault) {
                this._availableUserGroupList = res.userGroupList;
            }
        });
    }

    private updateCurrentGroupName(): void {
        const currentUserGroup: UserGroupInfo = this.userSvc.getCurrentUserGroupByID(this._user.userGroupID);
        if (currentUserGroup) {
            this._userGroupName = currentUserGroup.name;
        }
    }

    beginEditUser(): void {
        this._isInEdit = true;

        this._editPage = DialogPage.action;
        this._editData = {
            firstName: this._user.firstName,
            lastName: this._user.lastName,
            company: this._user.company,
            department: this._user.department,
            userRole: this._user.userRole,
            userGroupID: this._user.userGroupID,
            userGroupName: this._userGroupName
        }
    }

    cancelEditUser(): void {
        this._isInEdit = false;
        this._editData = null;
    }

    changeUserGroup(group: { id: string, name: string }): void {
        this._editData.userGroupID = group.id;
        this._editData.userGroupName = group.name;
    }

    changeUserRole(role: { name: string, desc: string }): void {
        this._editData.userRole = role.name;
    }

    confirmEdit(): void {
        const editUser: UserInfo = this._user.copy();
        editUser.firstName = this._editData.firstName;
        editUser.lastName = this._editData.lastName;
        editUser.company = this._editData.company;
        editUser.department = this._editData.department;
        editUser.userRole = this._editData.userRole;
        editUser.userGroupID = this._editData.userGroupID;

        this.createUserDlg<UserInfo[], UserInfo>(USER_DLG_FUNC_EDITCONFIRM, [editUser], this._user);
    }

    resetUserPassword(): void {
        this.createUserDlg<UserInfo[], void>(USER_DLG_FUNC_RESETPWD, [this._user]);
    }

    deleteUser(): void {
        this.createUserDlg<UserInfo[], void>(USER_DLG_FUNC_REMOVE, [this._user]);
    }

    private onActionCancel(ret: { funcName: string }): void {
        switch (ret.funcName) {
            case USER_DLG_FUNC_EDITCONFIRM:
                {
                    this.cancelEditUser();
                }
                break;
        }
    }

    private onActionComplete(ret: { funcName: string, isFault: boolean, data?: any }): void {
        switch (ret.funcName) {
            case USER_DLG_FUNC_REMOVE:
                {
                    if (!ret.isFault && !this.userSvc.isUserExist(this._user.id)) {
                        this.router.navigate(['/app/admin/users/all']);
                    }
                }
                break;
            case USER_DLG_FUNC_EDITCONFIRM:
                {
                    if (!ret.isFault) {
                        this._user = ret.data;
                        this.updateCurrentGroupName();
                    }

                    this.cancelEditUser();
                }
                break;
        }
    }

    private createUserDlg<D, O>(userFuncName: string, user: D, other?: O): void {
        const item: UserDlgFuncItem = this.userDlgFuncSvc.getItemByName(userFuncName);
        if (item) {
            const viewContainerRef = this._userDlgFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).title = item.title;
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).funcName = userFuncName;
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).data = user;
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).other = other;
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).onActionCompleted = this.onActionComplete.bind(this);
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).onActionCancelled = this.onActionCancel.bind(this);
        }
    }

}