import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { NAService } from '../../API/na.service';
import { IAPIRx } from '../../API/api.base';

@Component({
    templateUrl: './password-forget.component.html',
    styleUrls: ['../entry.style.css']
})
export class PasswordForgetComponent implements OnInit {
    _regEmail: string;
    _isRunning: boolean = false;

    constructor(private route: ActivatedRoute, private router: Router, private naSvc: NAService) { }

    ngOnInit(): void {
        this.route.paramMap.pipe(
        ).subscribe((params: ParamMap) => {
            this._regEmail = params.get('account');
        });
    }

    submit(): void {
        this._isRunning = true;

        this.naSvc.resetPassword({ accountEmail: this._regEmail }).subscribe((res: IAPIRx<void>) => {
            this.router.navigate(['/account/forgetPassword/verify', this._regEmail]);
            //'An email has been sent to the address on record. If you do not receive one, please check the correctness of your email or contact the Administrator.';

            this._isRunning = false;
        });
    }
}