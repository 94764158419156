import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { IAPIRx } from './api.base';
import { APIVirtualDeviceWrapper } from './v1/VirtualDevice/api.virtualDevice.wrapper';
import { APILicenseWrapper } from './v1/License/api.license.wrapper';
import { APITicketWrapper } from './v1/Ticket/api.ticket.wrapper';
import { APIAlertWrapper } from './v1/Alert/api.alert.wrapper';
import { APITaskWrapper } from './v1/Task/api.task.wrapper';
import { APIAccountWrapper } from './v1/Account/api.account.wrapper';
import { APIOtherWrapper } from './v1/Other/api.other.wrapper';
import { IResetPasswordTxData } from './v1/Account/api.account.password.reset';
import { IResetPasswordVerifyTxData } from './v1/Account/api.account.password.resetVerify';
import { IUpdatePasswordTxData } from './v1/Account/api.account.password.update';
import { IAccountSignupTxData } from './v1/Account/api.account.signup';
import { IAccountSignupVerifyTxData } from './v1/Account/api.account.signupVerify';
import { IGetVirtualDeviceAvailableOptionRxData, IGetVirtualDeviceAvailableOptionPathParameter } from './v1/VirtualDevice/api.virtualDevice.availableOption.get';
import { IShadowDeviceRxData, IVirtualDeviceCalendarItem, IVirtualDeviceRxData } from './v1/VirtualDevice/virtualDevice.common';
import { ICreateVirtualDeviceTxData } from './v1/VirtualDevice/api.virtualDevice.create';
import { IGetVirtualDeviceCurrentSettingRxData, IGetVirtualDeviceCurrentSettingPathParameter } from './v1/VirtualDevice/api.virtualDevice.currentSetting.get';
import { IGetVirtualDeviceHeartbeatPathParameter } from './v1/VirtualDevice/api.virtualDevice.heartbeat.get';
import { IListVirtualDeviceQueryParameter, IListVirtualDeviceRxData } from './v1/VirtualDevice/api.virtualDevice.list';
import { IPairVirtualDevicePathParameter, IPairVirtualDeviceTxData } from './v1/VirtualDevice/api.virtualDevice.pair';
import { IRemoveVirtualDevicePathParameter } from './v1/VirtualDevice/api.virtualDevice.remove';
import { IUnPairVirtualDevicePathParameter, IUnpairVirtualDeviceOptinalQueryParamter } from './v1/VirtualDevice/api.virtualDevice.unpair';
import { IUpdateVirtualDevicePathParameter, IUpdateVirtualDeviceTxData } from './v1/VirtualDevice/api.virtualDevice.update';
import { IGetVirtualDeviceScreenshotPathParameter, IGetVirtualDeviceScreenshotQueryParameter } from './v1/VirtualDevice/api.virtualDevice.screenshot.get';
import { ICreateTicketTxData } from './v1/Ticket/api.ticket.create';
import { IGetTicketPathParameter } from './v1/Ticket/api.ticket.get';
import { ITicketData } from './v1/Ticket/api.ticket.common';
import { IListTaskPathParameter, IListTaskRxData } from './v1/Task/api.task.list';
import { ICreateAlertTxData } from './v1/Alert/setting/api.alert.create';
import { IAlertSetting } from './v1/Alert/api.alert.common';
import { IDeleteAlertPathParameter } from './v1/Alert/setting/api.alert.delete';
import { IGetAlertPathParameter } from './v1/Alert/setting/api.alert.get';
import { IUpdateAlertTxData, IUpdateAlertPathParameter } from './v1/Alert/setting/api.alert.update';
import { IGetLicenseByOwnerTxData, IGetLicenseByOwnerRxData } from './v1/License/api.license.get.owner';
import { IGetLicenseByAssigneeTxData, IGetLicenseByAssigneeRxData } from './v1/License/api.license.get.assignee';
import { IImportLicenseTxData, IImportLicenseRxData } from './v1/License/api.license.import';
import { IAssignLicenseTxData } from './v1/License/api.license.assign';
import { IListPermissionSetRxData } from './v1/Other/api.permissionSet.list';
import { IGetWarrantyRxData } from './v1/License/api.warranty.get';
import { IGetVirtualDevicePathParameter } from './v1/VirtualDevice/api.virtualDevice.get';
import { APIDeviceGroupWrapper } from './v1/device/group/api.group.wrapper';
import { ICreateGroupTxData } from './v1/device/group/api.group.create';
import { IRemoveGroupPathParameter } from './v1/device/group/api.group.remove';
import { GroupRawInfo } from './v1/device/group/api.group.common';
import { IUpdateGroupPathParameter, IUpdateGroupTxData } from './v1/device/group/api.group.update';
import { IBatchUpdateGroupTxData } from './v1/device/group/api.group.batchUpdate';
import { IUpdateGroupDevicePathParameter, IUpdateGroupDeviceTxData } from './v1/device/group/api.group.updateDevice';
import { APIPolicyWrapper } from './v1/device/policy/api.policy.wrapper';
import { ICreatePolicyTxData } from './v1/device/policy/api.policy.create';
import { PolicyRawInfo } from './v1/device/policy/api.policy.common';
import { IRemovePolicyPathParameter, IRemovePolicyQueryParameter } from './v1/device/policy/api.policy.remove';
import { IUpdatePolicyPathParameter, IUpdatePolicyTxData } from './v1/device/policy/api.policy.update';
import { IAssignPolicyPathParameter, IAssignPolicyTxData } from './v1/device/policy/api.policy.assign';
import { IListPolicyGroupPathParameter } from './v1/device/policy/api.policy.listGroup';
import { IListPolicyDevicePathParameter } from './v1/device/policy/api.policy.listDevice';
import { IGetVirtualDeviceShadowPathParameter } from './v1/VirtualDevice/api.virtualDevice.shadow.get';
import { ISetVirtualDeviceCalendarPathParameter, ISetVirtualDeviceCalendarTxData } from './v1/VirtualDevice/api.virtualDevice.calendar.set';
import { IRemoveVirtualDeviceCalendarPathParameter, IRemoveVirtualDeviceCalendarQueryParameter } from './v1/VirtualDevice/api.virtualDevice.calendar.remove';
import { IGetVirtualDeviceOTPPathParameter, IGetVirtualDeviceOTPQueryParameter } from './v1/VirtualDevice/api.virtualDevice.otp.get';
import { AccessKeyInfo } from './v1/Account/api.accessKey.common';
import { ISigninAccessKeyRxData } from './v1/Account/api.accessKey.signin';
import { APIEventLogWrapper } from './v1/EventLog/api.eventLog.wrapper';
import { IListEventLogPathParameter, IListEventLogQueryParameter, IListEventLogRxData, IListEventLogTxData } from './v1/EventLog/api.eventLog.list';
import { IGetEventLogPathParameter, IGetEventLogRxData } from './v1/EventLog/api.eventLog.get';
import { IAPIListAlertNotificationRxData } from './v1/Alert/notification/api.alert.notification.list';
import { APIReportWrapper } from './v1/Report/api.report.wrapper';
import { IReportData, ReportConfig } from './v1/Report/api.report.data';
import { IListReportRxData } from './v1/Report/api.report.list';
import { ICreateAccountTokenRxData, ICreateAccountTokenTxData } from './v1/Account/api.account.token.create';
import { IListTicketRxData } from './v1/Ticket/api.ticket.list';
import { IListAlertRxData } from './v1/Alert/setting/api.alert.list';
import { APIEnterpriseWrapper } from './v1/Enterprise/api.enterprise.wrapper';
import { EnterpriseAccountBasicInfo, EnterpriseAccountGroupBasicInfo, EnterpriseAccountPersonalInfo } from './v1/Enterprise/account/api.ent.data';
import { IInviteEnterpriseAccountTxData } from './v1/Enterprise/account/api.ent.account.invite';
import { IUpdateEnterpriseAccountTxData } from './v1/Enterprise/account/api.ent.account.update';
import { IGetEnterpriseDetailInfoRxData } from './v1/Enterprise/api.enterprise.detail.get';
import { IScepInfo, IScepSettingData } from './v1/Enterprise/scep/api.ent.scep.data';
import { IEnterpriseListScepRxData } from './v1/Enterprise/scep/api.ent.scep.list';
import { IApplyEnterpriseTxData } from './v1/Enterprise/api.enterprise.apply';
import { IStartRemoteCtrlPathParameter, IStartRemoteCtrlRxData } from './v1/device/remote/api.remoteCtrl.start';
import { APIRemoteCtrlWrapper } from './v1/device/remote/api.remoteCtrl.wrapper';
import { IStopRemoteCtrlPathParameter } from './v1/device/remote/api.remoteCtrl.stop';
import { IExtendRemoteCtrlPathParameter, IExtendRemoteCtrlRxData, IExtendRemoteCtrlTxData } from './v1/device/remote/api.remoteCtrl.extend';
import { IQueryRemoteCtrlPathParameter, IQueryRemoteCtrlRxData } from './v1/device/remote/api.remoteCtrl.query';
import { APICertWrapper } from './v1/cert/api.cert.wrapper';
import { IListCertQueryParameter, IListCertRxData } from './v1/cert/api.cert.list';
import { IAddCertRxData, IAddCertTxData } from './v1/cert/api.cert.add';
import { IGetVirtualDeviceAdvanceFilterRxData } from './v1/VirtualDevice/api.virtualDevice.advanceFilter.get';
import { ISearchVirtualDeviceByAdvanceFilterTxData } from './v1/VirtualDevice/api.virtualDevice.advanceFilter.search';
import { APIPluginWrapper } from './v1/Plugin/api.plugin.wrapper';
import { concatMap } from 'rxjs/operators';
import { IPlugin, IPluginInstance } from './v1/Plugin/api.plugin.data';

@Injectable()
export class NAService {
    VirtualDevice: APIVirtualDeviceWrapper;
    License: APILicenseWrapper;
    Ticket: APITicketWrapper;
    Alert: APIAlertWrapper;
    Task: APITaskWrapper;
    Account: APIAccountWrapper;
    DeviceGroup: APIDeviceGroupWrapper;
    Policy: APIPolicyWrapper;
    Other: APIOtherWrapper;
    EventLog: APIEventLogWrapper;
    Report: APIReportWrapper;
    Enterprise: APIEnterpriseWrapper;
    RemoteCtrl: APIRemoteCtrlWrapper;
    Cert: APICertWrapper;
    Plugin: APIPluginWrapper;

    constructor(private http: HttpClient) {
        this.VirtualDevice = new APIVirtualDeviceWrapper(http);
        this.License = new APILicenseWrapper(http);
        this.Ticket = new APITicketWrapper(http);
        this.Alert = new APIAlertWrapper(http);
        this.Task = new APITaskWrapper(http);
        this.Account = new APIAccountWrapper(http);
        this.DeviceGroup = new APIDeviceGroupWrapper(http);
        this.Policy = new APIPolicyWrapper(http);
        this.Other = new APIOtherWrapper(http);
        this.EventLog = new APIEventLogWrapper(http);
        this.Report = new APIReportWrapper(http);
        this.Enterprise = new APIEnterpriseWrapper(http);
        this.RemoteCtrl = new APIRemoteCtrlWrapper(http);
        this.Cert = new APICertWrapper(http);
        this.Plugin = new APIPluginWrapper(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.VirtualDevice,
            this.License,
            this.Ticket,
            this.Alert,
            this.Task,
            this.Account,
            this.DeviceGroup,
            this.Policy,
            this.Other,
            this.EventLog,
            this.Report,
            this.Enterprise,
            this.RemoteCtrl,
            this.Cert,
            this.Plugin
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }

    // ==================== Enterprise ==================
    public getEnterpriseDeviceGroup(token: string): Observable<IAPIRx<any>> {
        return this.Enterprise.ListEnterpriseDeviceGroup.send(null, null, null, token);
    }

    public applyEnterprise(txData: IApplyEnterpriseTxData): Observable<IAPIRx<any>> {
        return this.Enterprise.ApplyEnterprise.send(null, null, txData, null);
    }

    public getEnterpriseInfo(token: string): Observable<IAPIRx<IGetEnterpriseDetailInfoRxData>> {
        return this.Enterprise.GetEnterpriseDetailInfo.send(null, null, null, token);
    }

    public inviteEnterpriseAccount(txData: IInviteEnterpriseAccountTxData, token: string): Observable<IAPIRx<EnterpriseAccountBasicInfo[]>> {
        return this.Enterprise.InviteEnterpriseAccount.send(null, null, txData, token);
    }

    public updateEnterpriseAccount(enterpriseAccountID: string, txData: IUpdateEnterpriseAccountTxData, token: string): Observable<IAPIRx<EnterpriseAccountBasicInfo>> {
        return this.Enterprise.UpdateEnterpriseAccount.send({ accountID: enterpriseAccountID }, null, txData, token);
    }

    public getEnterpriseAccountDetail(enterpriseAccountID: string, token: string): Observable<IAPIRx<EnterpriseAccountPersonalInfo>> {
        return this.Enterprise.GetEnterpriseAccountDetail.send({ accountID: enterpriseAccountID }, null, null, token);
    }

    public removeEnterpriseAccount(enterpriseAccountID: string, token: string): Observable<IAPIRx<EnterpriseAccountBasicInfo[]>> {
        return this.Enterprise.RemoveEnterpriseAccount.send({ accountID: enterpriseAccountID }, null, null, token);
    }

    public listEnterpriseAccount(token: string): Observable<IAPIRx<EnterpriseAccountBasicInfo[]>> {
        return this.Enterprise.ListEnterpriseAccount.send(null, null, null, token);
    }

    public createEnterpriseAccountGroup(groupName: string, token: string): Observable<IAPIRx<EnterpriseAccountGroupBasicInfo>> {
        return this.Enterprise.CreateEnterpriseAccountGroup.send(null, null, { accountGroupName: groupName }, token);
    }

    public listEnterpriseAccountGroup(token: string): Observable<IAPIRx<EnterpriseAccountGroupBasicInfo[]>> {
        return this.Enterprise.ListEnterpriseAccountGroup.send(null, null, null, token);
    }

    public updateEnterpriseAccountGroup(groupID: string, groupName: string, autoIncludeNewDeviceGroup: boolean, token: string): Observable<IAPIRx<EnterpriseAccountGroupBasicInfo>> {
        return this.Enterprise.UpdateEnterpriseAccountGroup.send({ accountGroupID: groupID }, null, { accountGroupName: groupName, autoIncludeNewDeviceGroup: autoIncludeNewDeviceGroup }, token);
    }

    public removeEnterpriseAccountGroup(groupID: string, token: string): Observable<IAPIRx<{ [groupID: string]: EnterpriseAccountGroupBasicInfo }>> {
        return this.Enterprise.RemoveEnterpriseAccountGroup.send({ accountGroupID: groupID }, null, null, token);
    }

    public assignDeviceGroupToEnterpriseAccountGroup(accountGroupID: string, deviceGroupIDList: string[], token: string): Observable<IAPIRx<EnterpriseAccountGroupBasicInfo>> {
        return this.Enterprise.AssignDeviceGroupToEnterpriseAccountGroup.send({ accountGroupID: accountGroupID }, null, { accountGroupID: accountGroupID, deviceGroupIDList: deviceGroupIDList }, token);
    }

    public setEnterpriseLogo(data: FormData, token): Observable<IAPIRx<any>> {
        return this.Enterprise.SetLogo.send(null, null, data, token);
    }

    public setEnterpriseBackground(data: FormData, token): Observable<IAPIRx<any>> {
        return this.Enterprise.SetBackground.send(null, null, data, token);
    }

    // ==================== SCEP ==================== 
    public createScepServerInfo(scepInfo: IScepInfo, token: string): Observable<IAPIRx<IScepInfo>> {
        return this.Enterprise.CreateScep.send(null, null, scepInfo, token);
    }

    public removeScepServerInfo(scepID: string, token: string): Observable<IAPIRx<void>> {
        return this.Enterprise.RemoveScep.send({ SCEPSettingID: scepID }, null, null, token);
    }

    public updateScepServerInfo(scepID: string, scepSettingData: IScepSettingData, token: string): Observable<IAPIRx<IScepInfo>> {
        return this.Enterprise.UpdateScep.send({ SCEPSettingID: scepID }, null, { settingData: scepSettingData }, token);
    }

    public listScepServerInfo(token: string): Observable<IAPIRx<IEnterpriseListScepRxData>> {
        return this.Enterprise.ListScep.send(null, null, null, token);
    }

    // ==================== Cert ====================
    public listCerts(queryParameter: IListCertQueryParameter, token: string): Observable<IAPIRx<IListCertRxData>> {
        return this.Cert.ListCert.send(null, queryParameter, null, token);
    }

    public addCert(txData: IAddCertTxData, token: string): Observable<IAPIRx<IAddCertRxData>> {
        return this.Cert.AddCert.send(null, null, txData, token);
    }

    public deleteCert(certificateID: string, token: string): Observable<IAPIRx<void>> {
        return this.Cert.DeleteCert.send({ certificateID: certificateID }, null, null, token);
    }

    // ==================== Token ==================== 
    public createAccountToken(txData: ICreateAccountTokenTxData): Observable<IAPIRx<ICreateAccountTokenRxData>> {
        return this.Account.CreateAccountToken.send(null, null, txData);
    }

    public validateAccountToken(token: string): Observable<IAPIRx<string>> {
        return this.Account.ValidateAccountToken.send(null, null, null, token);
    }

    // ==================== Account ====================
    public signUp(txData: IAccountSignupTxData): Observable<IAPIRx<void>> {
        return this.Account.Signup.send(null, null, txData);
    }

    public verifySignUp(txData: IAccountSignupVerifyTxData): Observable<IAPIRx<void>> {
        return this.Account.SignupVerify.send(null, null, txData);
    }

    public resetPassword(txData: IResetPasswordTxData): Observable<IAPIRx<void>> {
        return this.Account.ResetAccountPassword.send(null, null, txData);
    }

    public verifyResetPassword(txData: IResetPasswordVerifyTxData): Observable<IAPIRx<void>> {
        return this.Account.ResetAccountPasswordVerify.send(null, null, txData);
    }

    public updatePassword(txData: IUpdatePasswordTxData, userToken: string): Observable<IAPIRx<void>> {
        return this.Account.UpdateAccountPassword.send(null, null, txData, userToken);
    }

    public createAccessKey(userToken: string): Observable<IAPIRx<AccessKeyInfo>> {
        return this.Account.CreateAccessKey.send(null, null, null, userToken);
    }

    public listAccessKey(userToken: string): Observable<IAPIRx<AccessKeyInfo[]>> {
        return this.Account.ListAccessKey.send(null, null, null, userToken);
    }

    public deleteAccessKey(accessKeyID: string, userToken: string): Observable<IAPIRx<void>> {
        return this.Account.DeleteAccessKey.send({ accessKeyID: accessKeyID }, null, null, userToken);
    }

    public updateAccessKey(accessKeyID: string, remark: string, enabled: boolean, userToken: string): Observable<IAPIRx<AccessKeyInfo>> {
        return this.Account.UpdateAccessKey.send({ accessKeyID: accessKeyID }, null, { enabled: enabled, remark: remark }, userToken);
    }

    public loginAccessKey(accessKeyID: string, accessKeySecret: string): Observable<IAPIRx<ISigninAccessKeyRxData>> {
        return this.Account.SigninAccessKey.send(null, null, { accessKeyID: accessKeyID, accessKeySecret: accessKeySecret });
    }

    // ==================== Device ====================
    public getDeviceAvailableOptions(pathParameter: IGetVirtualDeviceAvailableOptionPathParameter, userToken: string): Observable<IAPIRx<IGetVirtualDeviceAvailableOptionRxData>> {
        return this.VirtualDevice.GetAvailableOption.send(pathParameter, null, null, userToken);
    }

    public getDeviceCurrentSettings(pathParameter: IGetVirtualDeviceCurrentSettingPathParameter, userToken: string): Observable<IAPIRx<IGetVirtualDeviceCurrentSettingRxData>> {
        return this.VirtualDevice.GetCurrentSetting.send(pathParameter, null, null, userToken);
    }

    public getDeviceHeartbeat(pathParameter: IGetVirtualDeviceHeartbeatPathParameter, userToken: string): Observable<IAPIRx<string>> {
        return this.VirtualDevice.GetHeartbeat.send(pathParameter, null, null, userToken);
    }

    public createVirtualDevice(txData: ICreateVirtualDeviceTxData, userToken: string): Observable<IAPIRx<IVirtualDeviceRxData>> {
        return this.VirtualDevice.CreateVirtualDevice.send(null, null, txData, userToken);
    }

    public pairVirtualDevice(pathParameter: IPairVirtualDevicePathParameter, txData: IPairVirtualDeviceTxData, userToken: string): Observable<IAPIRx<IVirtualDeviceRxData>> {
        return this.VirtualDevice.PairVirtualDevice.send(pathParameter, null, txData, userToken);
    }

    public removeVirtualDevice(pathParameter: IRemoveVirtualDevicePathParameter, userToken: string): Observable<IAPIRx<IVirtualDeviceRxData>> {
        return this.VirtualDevice.RemoveVirtualDevice.send(pathParameter, null, null, userToken);
    }

    public unpairVirtualDevice(pathParameter: IUnPairVirtualDevicePathParameter, queryParameter: IUnpairVirtualDeviceOptinalQueryParamter, userToken: string): Observable<IAPIRx<IVirtualDeviceRxData>> {
        return this.VirtualDevice.UnpairVirtualDevice.send(pathParameter, queryParameter, null, userToken);
    }

    public updateVirtualDevice(pathParameter: IUpdateVirtualDevicePathParameter, txData: IUpdateVirtualDeviceTxData, userToken: string): Observable<IAPIRx<IVirtualDeviceRxData>> {
        return this.VirtualDevice.UpdateVirtualDevice.send(pathParameter, null, txData, userToken);
    }

    public listVirtualDevice(queryParameter: IListVirtualDeviceQueryParameter, userToken: string): Observable<IAPIRx<IListVirtualDeviceRxData>> {
        return this.VirtualDevice.ListVirtualDevice.send(null, queryParameter, null, userToken);
    }

    public getVirtualDevice(pathParameter: IGetVirtualDevicePathParameter, queryParameter: string[], userToken: string): Observable<IAPIRx<IVirtualDeviceRxData>> {
        return this.VirtualDevice.GetVirtualDevice.send(pathParameter, queryParameter, null, userToken);
    }

    public getVirtualDeviceShadow(pathParameter: IGetVirtualDeviceShadowPathParameter, userToken: string): Observable<IAPIRx<IShadowDeviceRxData>> {
        return this.VirtualDevice.GetVirtualDeviceShadow.send(pathParameter, null, null, userToken);
    }

    public getDeviceScreenshot(pathParameter: IGetVirtualDeviceScreenshotPathParameter, queryParameter: IGetVirtualDeviceScreenshotQueryParameter, userToken: string): Observable<any> {
        return this.VirtualDevice.GetScreenshot.send(pathParameter, queryParameter, null, userToken);
    }

    public setVirtualDeviceCalendar(pathParameter: ISetVirtualDeviceCalendarPathParameter, txData: ISetVirtualDeviceCalendarTxData, userToken: string): Observable<IAPIRx<IVirtualDeviceCalendarItem[]>> {
        return this.VirtualDevice.SetCalendar.send(pathParameter, null, txData, userToken);
    }

    public getVirtualDeviceCalendar(pathParameter: ISetVirtualDeviceCalendarPathParameter, userToken: string): Observable<IAPIRx<IVirtualDeviceCalendarItem[]>> {
        return this.VirtualDevice.GetCalendar.send(pathParameter, null, null, userToken);
    }

    public removeVirtualDeviceCalendar(pathParameter: IRemoveVirtualDeviceCalendarPathParameter, queryParameter: IRemoveVirtualDeviceCalendarQueryParameter, userToken: string): Observable<IAPIRx<IVirtualDeviceCalendarItem[]>> {
        return this.VirtualDevice.RemoveCalendar.send(pathParameter, queryParameter, null, userToken);
    }

    public getVirtualDeviceOTP(pathParameter: IGetVirtualDeviceOTPPathParameter, queryParameter: IGetVirtualDeviceOTPQueryParameter, userToken: string): Observable<IAPIRx<string>> {
        return this.VirtualDevice.GetOTP.send(pathParameter, queryParameter, null, userToken);
    }

    public getVirtualDeviceAdvanceFilter(userToken: string): Observable<IAPIRx<IGetVirtualDeviceAdvanceFilterRxData[]>> {
        return this.VirtualDevice.GetAdvanceFilter.send(null, null, null, userToken);
    }

    public listVirtualDeviceByAdvanceFilter(queryParameter: IListVirtualDeviceQueryParameter, txData: ISearchVirtualDeviceByAdvanceFilterTxData, userToken: string): Observable<IAPIRx<IListVirtualDeviceRxData>> {
        return this.VirtualDevice.ListVirtualDeviceByAdvanceFilter.send(null, queryParameter, txData, userToken);
    }

    public listVirtualDeviceLabels(userToken: string): Observable<IAPIRx<string[]>> {
        return this.VirtualDevice.ListVirtualDeviceLabel.send(null, null, null, userToken);
    }

    public updateVirtualDeviceLabel(appendedData: { labelName: string, virtualDeviceIDList: string[] }, removedData: { labelName: string, virtualDeviceIDList: string[] }, userToken: string): Observable<IAPIRx<string[]>> {
        return this.VirtualDevice.UpdateVirtualDeviceLabel.send(null, null, { appendOperation: appendedData, removeOperation: removedData }, userToken);
    }

    // ==================== Device Group ====================
    public createDeviceGroup(txData: ICreateGroupTxData, userToken: string): Observable<IAPIRx<GroupRawInfo[]>> {
        return this.DeviceGroup.CreateGroup.send(null, null, txData, userToken);
    }

    public removeDeviceGroup(pathParameter: IRemoveGroupPathParameter, userToken: string): Observable<IAPIRx<{ [groupID: string]: GroupRawInfo }>> {
        return this.DeviceGroup.RemoveGroup.send(pathParameter, null, null, userToken);
    }

    public listDeviceGroup(userToken: string): Observable<IAPIRx<{ [accountName: string]: { [groupID: string]: GroupRawInfo } }>> {
        return this.DeviceGroup.ListGroup.send(null, null, null, userToken);
    }

    public updateDeviceGroup(pathParameter: IUpdateGroupPathParameter, txData: IUpdateGroupTxData, userToken: string): Observable<IAPIRx<GroupRawInfo>> {
        return this.DeviceGroup.UpdateGroup.send(pathParameter, null, txData, userToken);
    }

    public batchUpdateDeviceGroup(txData: IBatchUpdateGroupTxData, userToken: string): Observable<IAPIRx<GroupRawInfo[]>> {
        return this.DeviceGroup.BatchUpdateGroup.send(null, null, txData, userToken);
    }

    public updateDeviceInDeviceGroup(pathParameter: IUpdateGroupDevicePathParameter, txData: IUpdateGroupDeviceTxData, userToken: string): Observable<IAPIRx<any>> {
        return this.DeviceGroup.UpdateGroupDevice.send(pathParameter, null, txData, userToken);
    }

    // ==================== Policy ====================
    public createPolicy(txData: ICreatePolicyTxData, userToken: string): Observable<IAPIRx<PolicyRawInfo>> {
        return this.Policy.CreatePolicy.send(null, null, txData, userToken);
    }

    public listPolicy(userToken: string): Observable<IAPIRx<{ [accountName: string]: { [policyID: string]: PolicyRawInfo } }>> {
        return this.Policy.ListPolicy.send(null, null, null, userToken);
    }

    public removePolicy(pathParameter: IRemovePolicyPathParameter, queryParameter: IRemovePolicyQueryParameter, userToken: string): Observable<IAPIRx<any>> {
        return this.Policy.RemovePolicy.send(pathParameter, queryParameter, null, userToken);
    }

    public updatePolicy(pathParameter: IUpdatePolicyPathParameter, txData: IUpdatePolicyTxData, userToken: string): Observable<IAPIRx<PolicyRawInfo>> {
        return this.Policy.UpdatePolicy.send(pathParameter, null, txData, userToken);
    }

    public assignPolicy(pathParameter: IAssignPolicyPathParameter, txData: IAssignPolicyTxData, userToken: string): Observable<IAPIRx<any>> {
        return this.Policy.AssignPolicy.send(pathParameter, null, txData, userToken);
    }

    public listDeviceGroupInPolicy(pathParameter: IListPolicyGroupPathParameter, userToken: string): Observable<IAPIRx<GroupRawInfo[]>> {
        return this.Policy.ListDeviceGroupInPolicy.send(pathParameter, null, null, userToken);
    }

    public listDeviceInPolicy(pathParameter: IListPolicyDevicePathParameter, userToken: string): Observable<IAPIRx<IVirtualDeviceRxData[]>> {
        return this.Policy.ListDeviceInPolicy.send(pathParameter, null, null, userToken);
    }

    // ==================== Remote control ==================
    public startRemoteControl(pathParameter: IStartRemoteCtrlPathParameter, userToken: string): Observable<IAPIRx<IStartRemoteCtrlRxData>> {
        return this.RemoteCtrl.StartRemoteCtrl.send(pathParameter, null, null, userToken);
    }

    public stopRemoteControl(pathParameter: IStopRemoteCtrlPathParameter, userToken: string): Observable<IAPIRx<any>> {
        return this.RemoteCtrl.StopRemoteCtrl.send(pathParameter, null, null, userToken);
    }

    public extendRemoteControl(pathParameter: IExtendRemoteCtrlPathParameter, txData: IExtendRemoteCtrlTxData, userToken: string): Observable<IAPIRx<IExtendRemoteCtrlRxData>> {
        return this.RemoteCtrl.ExtendRemoteCtrl.send(pathParameter, null, txData, userToken);
    }

    public getRemoteControlStatus(pathParameter: IQueryRemoteCtrlPathParameter, userToken: string): Observable<IAPIRx<IQueryRemoteCtrlRxData>> {
        return this.RemoteCtrl.QueryRemoteCtrl.send(pathParameter, null, null, userToken);
    }

    // ==================== Event logs ==================
    public listEventLogCategory(userToken: string): Observable<IAPIRx<{ [category: string]: { [event: string]: string } }>> {
        return this.EventLog.ListEventCategory.send(null, null, null, userToken);
    }

    public listEventLog(pathParameter: IListEventLogPathParameter, queryParameter: IListEventLogQueryParameter, txData: IListEventLogTxData, userToken: string): Observable<IAPIRx<IListEventLogRxData>> {
        return this.EventLog.ListEventLog.send(pathParameter, queryParameter, txData, userToken);
    }

    public getEventLog(pathParameter: IGetEventLogPathParameter, userToken: string): Observable<IAPIRx<IGetEventLogRxData>> {
        return this.EventLog.GetEventLog.send(pathParameter, null, null, userToken);
    }

    // ==================== Report ==================
    public getReportSetting(userToken: string): Observable<IAPIRx<ReportConfig>> {
        return this.Report.GetReportConfig.send(null, null, null, userToken);
    }

    public updateReportSetting(txData: ReportConfig, userToken: string): Observable<IAPIRx<ReportConfig>> {
        return this.Report.UpdateReportConfig.send(null, null, txData, userToken);
    }

    public listReports(skip: number, limit: number, userToken: string): Observable<IAPIRx<IListReportRxData>> {
        return this.Report.ListReport.send(null, { skip: skip, limit: limit }, null, userToken);
    }

    public getReport(reportID: string, userToken: string): Observable<IAPIRx<IReportData>> {
        return this.Report.GetReport.send({ weeklyReportID: reportID }, null, null, userToken);
    }

    // ==================== Ticket ====================
    public createTicket(txData: ICreateTicketTxData, token: string): Observable<IAPIRx<ITicketData>> {
        return this.Ticket.CreateTicket.send(null, null, txData, token);
    }

    public getTicket(pathParameter: IGetTicketPathParameter, token: string): Observable<IAPIRx<ITicketData>> {
        return this.Ticket.GetTicket.send(pathParameter, null, null, token);
    }

    public listTickets(token: string, skip: number, limit: number, search?: {
        ticketAction?: string;
        ticketStatusCode?: string;
        ticketSubject?: string;
        taskAction?: string;
        fromDate?: string;
        toDate?: string;
        success?: boolean;
    }): Observable<IAPIRx<IListTicketRxData>> {
        return this.Ticket.ListTicket.send(null, { skip: skip, limit: limit, search: search }, null, token);
    }

    public cancelTicket(ticketID: string, token: string): Observable<IAPIRx<ITicketData>> {
        return this.Ticket.CancelTicket.send({ ticketID: ticketID }, null, null, token);
    }

    // ==================== Task ====================
    public listTasks(pathParameter: IListTaskPathParameter, userToken: string): Observable<IAPIRx<IListTaskRxData>> {
        return this.Task.ListTask.send(pathParameter, null, null, userToken);
    }

    // ==================== Alert ====================
    public createAlert(txData: ICreateAlertTxData, userToken: string): Observable<IAPIRx<IAlertSetting>> {
        return this.Alert.CreateAlert.send(null, null, txData, userToken);
    }

    public removeAlert(pathParameter: IDeleteAlertPathParameter, userToken: string): Observable<IAPIRx<IAlertSetting>> {
        return this.Alert.DeleteAlert.send(pathParameter, null, null, userToken);
    }

    public getAlert(pathParameter: IGetAlertPathParameter, userToken: string): Observable<IAPIRx<IAlertSetting>> {
        return this.Alert.GetAlert.send(pathParameter, null, null, userToken);
    }

    public getAlertSample(userToken: string): Observable<IAPIRx<any>> {
        return this.Alert.GetAlertSample.send(null, null, null, userToken);
    }

    public listAlert(userToken: string): Observable<IAPIRx<IListAlertRxData>> {
        return this.Alert.ListAlert.send(null, null, null, userToken);
    }

    public updateAlert(pathParameter: IUpdateAlertPathParameter, txData: IUpdateAlertTxData, userToken: string): Observable<IAPIRx<IAlertSetting>> {
        return this.Alert.UpdateAlert.send(pathParameter, null, txData, userToken);
    }

    public listAlertNotifications(isClosed: boolean, skip: number, limit: number, userToken: string): Observable<IAPIRx<IAPIListAlertNotificationRxData>> {
        return this.Alert.ListAlertNotifications.send(null, { isClosed: isClosed, skip: skip, limit: limit }, null, userToken);
    }

    public ackAlertNotification(alertNotificationID: string, ack: boolean, userToken: string): Observable<IAPIRx<void>> {
        return ack ?
            this.Alert.AckAlertNotification.send({ alertNotificationID: alertNotificationID }, null, null, userToken) :
            this.Alert.UnackAlertNotification.send({ alertNotificationID: alertNotificationID }, null, null, userToken);
    }

    // ==================== License ====================
    public getWarranty(userToken: string, playerID: string): Observable<IAPIRx<IGetWarrantyRxData[]>> {
        return this.License.GetWarranty.send(null, null, null, userToken, null, { logSourceID: playerID });
    }

    public getLicenseByOwner(txData: IGetLicenseByOwnerTxData, userToken: string): Observable<IAPIRx<IGetLicenseByOwnerRxData[]>> {
        return this.License.GetLicenseByOwner.send(null, null, txData, userToken);
    }

    public getLicenseByAssignee(txData: IGetLicenseByAssigneeTxData, userToken: string): Observable<IAPIRx<IGetLicenseByAssigneeRxData[]>> {
        return this.License.GetLicenseByAssignee.send(null, null, txData, userToken);
    }

    public importLicense(txData: IImportLicenseTxData, userToken: string): Observable<IAPIRx<IImportLicenseRxData[]>> {
        return this.License.ImportLicense.send(null, null, txData, userToken);
    }

    public assignLicense(txData: IAssignLicenseTxData, userToken: string): Observable<IAPIRx<void>> {
        return this.License.AssignLicense.send(null, null, txData, userToken);
    }

    // ===================== Plugins ===================
    public listAvailablePluginTypes(userToken: string): Observable<IAPIRx<{ itemList: IPlugin[], limit: number, skip: number, total: number }>> {
        return this.Plugin.ListPlugins.send(null, null, null, userToken);
    }
    public listPluginInstances(userToken: string): Observable<IAPIRx<{ itemList: IPluginInstance[], limit: number, skip: number, total: number}>> {
        return this.Plugin.ListPluginInstances.send(null, null, null, userToken);
    }
    public createPluginInstance(pluginID: string, data: { credential?: string, callbackUrl?: string, remark?: string }, events: string[], userToken: string): Observable<IAPIRx<IPluginInstance>> {
        return this.Plugin.CreatePluginInstance.send(null, null, { pluginID: pluginID }, userToken).pipe(
            concatMap((res: IAPIRx<IPluginInstance>) => {
                if (data?.credential) {
                    return this.Plugin.SetPluginInstanceCredential.send({ pluginInstanceID: res.data.pluginInstanceID }, null, { credential: data.credential }, userToken);
                }

                return of(res);
            }),
            concatMap((res: IAPIRx<IPluginInstance>) => {
                if (data?.callbackUrl) {
                    return this.Plugin.SetPluginInstanceCallbackUrl.send({ pluginInstanceID: res.data.pluginInstanceID }, null, { eventCallbackURL: data.callbackUrl }, userToken);
                }

                return of(res);
            }),
            concatMap((res: IAPIRx<IPluginInstance>) => {
                if (data?.remark) {
                    return this.Plugin.SetPluginInstanceRemark.send({ pluginInstanceID: res.data.pluginInstanceID }, null, { remark: data.remark }, userToken);
                }

                return of(res);
            }),
            concatMap((res: IAPIRx<IPluginInstance>) => {
                if (events?.length > 0) {
                    return this.Plugin.SetPluginInstanceEventListeners.send({ pluginInstanceID: res.data.pluginInstanceID}, null, { eventListeners: events}, userToken);
                }

                return of(res);
            })
        )
    }
    public removePluginInstance(pluginInstanceID: string, userToken: string): Observable<IAPIRx<any>> {
        return this.Plugin.SetPluginInstanceEnable.send({ pluginInstanceID: pluginInstanceID }, null, { enabled: false }, userToken).pipe(
            concatMap(() => {
                return this.Plugin.RemovePluginInstance.send({ pluginInstanceID: pluginInstanceID }, null, null, userToken);
            })
        );
    }
    public runPluginHealthCheck(pluginInstanceID: string, userToken: string): Observable<IAPIRx<any>> {
        return this.Plugin.RunPluginInstance.send({ pluginInstanceID: pluginInstanceID }, null, null, userToken);
    }


    // ==================== Other ====================
    public getPermissionList(userToken: string): Observable<IAPIRx<IListPermissionSetRxData[]>> {
        return this.Other.ListPermissionSet.send(null, null, null, userToken);
    }
}