
export class DisplayItem {
    id: string;
    key: string;
    langKey: string;
    show: boolean;
    allowChoose: boolean;
    allowShow: boolean;
    allowSort: boolean;

    constructor(id: string, key: string, langKey: string, allowChoose: boolean, allowShow: boolean, show?: boolean, allowSort: boolean = true) {
        this.id = id;
        this.key = key;
        this.langKey = langKey;
        this.allowChoose = allowChoose;
        this.allowShow = allowShow;
        this.show = show;
        this.allowSort = allowSort;
    }

    toString(): string {
        return this.key;
    }
}