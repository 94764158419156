import { Injectable } from '@angular/core';
import { RemoteFuncItem, REMOTE_FUNC_DOWNLOADSCREENSHOT, REMOTE_FUNC_SENDMESSAGE, REMOTE_FUNC_IDLEREMIND, REMOTE_FUNC_NOTIFICATION } from './remote-func.def';
import { RemoteDlgTakeScreenshotComponent } from './remote-dlg-takeScreenshot.component';
import { RemoteDlgSendMsgComponent } from './remote-dlg-sendMsg.component';
import { RemoteDlgIdleRemindComponent } from './remote-dlg-idleRemind.component';
import { RemoteDlgNotificationComponent } from './remote-dlg-notification.component';

@Injectable()
export class RemoteFuncService {
  private _items: RemoteFuncItem[] = [];

  constructor() {
    this._items = [
        new RemoteFuncItem(RemoteDlgTakeScreenshotComponent, REMOTE_FUNC_DOWNLOADSCREENSHOT, 'Download screenshot'),
        new RemoteFuncItem(RemoteDlgSendMsgComponent, REMOTE_FUNC_SENDMESSAGE, 'Send message'),
        new RemoteFuncItem(RemoteDlgIdleRemindComponent, REMOTE_FUNC_IDLEREMIND, 'Idle alert'),
        new RemoteFuncItem(RemoteDlgNotificationComponent, REMOTE_FUNC_NOTIFICATION, 'Notification')
    ];
  }

  getAllItems(): RemoteFuncItem[] {
    return this._items;
  }

  getItemByName(name: string): RemoteFuncItem {
    return this._items.find(i => i.name === name);
  }
}
