import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { GroupRawInfo } from './api.group.common';

export interface IRemoveGroupPathParameter {
    deviceGroupID: string;
}

export class APIRemoveGroupManager extends APIBaseManager<IRemoveGroupPathParameter, void, void, { [groupID: string]: GroupRawInfo }> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_RemoveDevGroup';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IRemoveGroupPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'deviceGroups/' + pathParameters.deviceGroupID;
    }
}