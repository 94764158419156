import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { of, from } from 'rxjs';
import { concatMap, catchError } from 'rxjs/operators';

import { CaseInfo, LOGIN_SLOGAN, LOGIN_CASE_LIST } from '../login.data';
import { AccountService } from '../../account.service';
import { NAService } from '../../../API/na.service';
import { IAPIRx } from '../../../API/api.base';
import { AppConfigService } from '../../../app.config';
import { HelperLib } from '../../../lib/common/helper.lib';
import { Logger } from '../../../../app/lib/common/logger';
import { AppService } from '../../../app.service';
import { AppMode } from '../../../app.data';
import { ICreateAccountTokenRxData } from '../../../API/v1/Account/api.account.token.create';
import { IAccountToken } from '../../../lib/common/common.data';
import { ConstantService } from '../../../lib/common/constant.service';

declare var grecaptcha;

@Component({
    templateUrl: './login-iadea.component.html',
    styleUrls: ['../../entry.style.css', './login-iadea.component.css']
})
export class LoginIAdeaComponent implements OnInit {
    _slogan: string;

    _caseList: CaseInfo[] = LOGIN_CASE_LIST;
    _currentInfoAnimateState: string = 'show';

    _isLogining: boolean = false;

    _accountName: string = '';
    _accountPassword: string = '';
    _showPwd: boolean = false;
    _loginBtnText: string = 'Next';

    _enableForgetPwdFeature: boolean = AppConfigService.configs.loginPage.forgetPwdEnabled;
    _enableSignupFeature: boolean = AppConfigService.configs.loginPage.signupEnabled;
    _appVersion: string = AppConfigService.configs.common.appVersion;
    _releaseDate: string = AppConfigService.configs.common.releaseDate;

    _errorMessage: string = '';
    _redirectURL: string;
    _bReCaptchaReady: boolean = false;

    _appMode: AppMode = AppMode.normal;
    _enumAppMode: typeof AppMode = AppMode;

    @ViewChild('redirectLinkRef', { static: true }) private _redirectLinkRef: ElementRef;

    private _passwordRef: ElementRef;
    @ViewChild('passwordInput', { static: false })
    set passwordInput(v: ElementRef) {
        this._passwordRef = v;

        if (this._passwordRef && this._accountName) {
            this._passwordRef.nativeElement.focus();
        }
    }

    constructor(
        private accountSvc: AccountService,
        private naSvc: NAService,
        private router: Router,
        private route: ActivatedRoute,
        public appSvc: AppService,
        private constantSvc: ConstantService
    ) {
        this._slogan = LOGIN_SLOGAN;
        this._caseList = LOGIN_CASE_LIST;

        if (grecaptcha) {
            grecaptcha.ready(() => {
                this._bReCaptchaReady = true;
            });
        }
    }

    ngOnInit(): void {
        this._appMode = this.appSvc.appMode;
        this.route.paramMap.subscribe((params: ParamMap) => {
            this._accountName = params.get('account');
            if (this._accountName) {
                this._accountName = this._accountName.replace(/\?$/, '');
                this._showPwd = true;
                this._loginBtnText = 'Login';
            }
        });

        this.route.queryParamMap.subscribe((querys: ParamMap) => {
            const msg: string = querys.get('message');
            const msgType: string = querys.get('messageType');
            if (msg && msgType) {
                switch (msgType) {
                    case 'error':
                    default:
                        {
                            this._errorMessage = msg;
                        }
                        break;
                }
            }
        })
    }

    login(): void {
        this._isLogining = true;
        this._errorMessage = null;

        of(true).pipe(
            concatMap(() => {
                if (AppConfigService.configs.common.reCaptcha.enabled && grecaptcha) {
                    return from(grecaptcha.execute(AppConfigService.configs.common.reCaptcha.key, { action: 'loginPage' })).pipe(
                        catchError(error => {
                            Logger.logError('Comp-login', 'login', error || 'reCAPTCHA failed: Unknown error')
                            return of('');
                        }),
                    )
                }

                return of('');
            }),
            concatMap((reCaptchaToken: any) => this.naSvc.createAccountToken({
                accountName: this._accountName,
                accountPassword: this._accountPassword,
                reCaptchaSiteKey: AppConfigService.configs.common.reCaptcha.key,
                reCaptchaToken: reCaptchaToken
            }))
        ).subscribe((res: IAPIRx<ICreateAccountTokenRxData>) => {
            this._isLogining = false;
            if (res.error !== 0) {
                this._errorMessage = res.errorMessage;
                return;
            }

            if (res.data.redirectURL) {
                this._redirectURL = res.data.redirectURL;
                if (!this.accountSvc.supportLoginRedirect || !this._redirectURL || this._redirectURL.startsWith('./') || this._redirectURL.indexOf(window.location.origin) === 0 || window.location.origin.indexOf('http://localhost:') === 0) {
                    // 1. relative path, use angular routing
                    // 2. same entry point, use angular routing
                    this.router.navigate(['./account/login/' + this._accountName]);
                }
                else {
                    // different entry points or enterprise SSO user
                    if (this._redirectURL.indexOf('://') < 0) {
                        this._redirectURL = AppConfigService.configs.server.api.protocol + "://" + AppConfigService.configs.server.api.baseUrl + ':' + AppConfigService.configs.server.api.port + this._redirectURL;
                        this.appSvc.useEnterpriseLayout = true;
                    }

                    setTimeout(() => {
                        this._redirectLinkRef.nativeElement.click();
                    }, 0);
                }

                return;
            }

            if (res.data.token) {
                const tokenInfo: IAccountToken = HelperLib.parseToken(res.data.token);
                if (tokenInfo) {
                    this.accountSvc.login(res.data.token, tokenInfo);
                    this.router.navigate(['/app/device/devices']);
                }
                else {
                    this._errorMessage = 'Parse data failed';
                }
            }
        });
    }

    signinToDiffAccount(): void {
        this.router.navigate(['/account/login']);
    }

    gotoForgetPassword(): void {
        this.router.navigate(['/account/forgetPassword', this._accountName]);
    }

    keyDown(keyCode: number): void {
        if (keyCode === this.constantSvc.KEYCODE_RETURN) {
            //Press Enter, try to login
            if (!this._accountName) {
                this._errorMessage = 'Please enter your account mail';
                return;
            }

            if (!this._bReCaptchaReady) {
                this._errorMessage = 'reCAPTCHA not ready';
                return;
            }

            this.login();
        }
    }
}