import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IVirtualDeviceCalendarItem } from './virtualDevice.common';

export interface ISetVirtualDeviceCalendarPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export class APIGetVirtualDeviceCalendarManager extends APIBaseManager<ISetVirtualDeviceCalendarPathParameter, void, void, IVirtualDeviceCalendarItem[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetVirtualDeviceCalendar';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: ISetVirtualDeviceCalendarPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/' + pathParameters.virtualDevicePairedID + '/calendars';
    }
}