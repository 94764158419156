import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IImportLicenseTxData {
    importList: {
        licenseKeyCode: string;
        owner: object //ex: {accountID:.....}
    }[]
}

export interface IImportLicenseRxData {
    accountID: string;
    licenseKey: string;
    licenseKeyToken: string;
    error: number;
    errorMessage: string;
}

export class APIImportLicenseManager extends APIBaseManager<void, void, IImportLicenseTxData, IImportLicenseRxData[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ImportLicense';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'licenseKeys';
    }
}