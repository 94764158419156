import { HttpClient, HttpResponse } from '@angular/common/http';
import { IAPIRx, APIBaseManager } from '../../api.base';

export interface IGetVirtualDeviceHeartbeatPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export class APIGetVirtualDeviceHeartbeatManager extends APIBaseManager<IGetVirtualDeviceHeartbeatPathParameter, void, void, string> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetVirtualDeviceHeartbeat';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IGetVirtualDeviceHeartbeatPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/' + pathParameters.virtualDevicePairedID + '/heartbeat';
    }
 
    protected doGetAftermathAction(res: HttpResponse<IAPIRx<string>>): boolean {
        const updateTime = parseInt(res.headers.get('IAdeaCare-Server-File-Milliseconds'));
        if (updateTime) {
            res.body.updateTime = updateTime;
        }
        const serverTime = parseInt(res.headers.get('IAdea-Server-Timestamp-Milliseconds'));
        if (serverTime) {
            res.body.serverTime = serverTime;
        }

        return true;
    }
}