import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';
import { takeUntil, debounceTime, map, concatMap } from 'rxjs/operators';

import { DeviceInfo, OnlineStatus } from '../data/device-info';
import { DeviceService } from '../device.service';
import { SortType } from '../../../lib/common/common.data';
import { DeviceGroupInfo, GroupSwitch, DEVICE_GROUP_ID_HOME } from '../group/group.data';
import { DeviceGroupService } from '../group/dev-group.service';
import { HelperLib, REFRESH_DURATION } from '../../../lib/common/helper.lib';
import { ConstantService } from '../../../lib/common/constant.service';
import { DashboardView } from '../../user-preference.data';
import { UserPreferenceService } from '../../user-preference.service';
import { AccountService } from '../../../entry/account.service';
import { DisplayItem } from '../data/display-item';
import { DevFuncItem } from '../../devfunc/dev-func-item';
import { DevFuncInterface } from '../../devfunc/dev-func.interface';
import { AppConfigService } from '../../../app.config';
import { DevFuncDirective } from '../../devfunc/dev-func.directive';
import { DevFuncService } from '../../devfunc/dev-func.service';
import { PolicyService } from 'app/content/setting/policy/policy.service';
import { PolicyInfo } from 'app/content/setting/policy/policy.data';
import { DEVICE_LABEL_FUNC_ATTACH, DeviceLabelFuncItem, IDeviceLabelFunc } from '../label/dlg/dev-label-func.def';
import { DeviceLabelFuncService } from '../label/dlg/dev-label-func.service';
import { DeviceLabelFuncDirective } from '../label/dlg/dev-label-func.directive';
import { DeviceLabelInfo } from '../label/dev-label.data';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';
import { Logger } from 'app/lib/common/logger';
import { environment } from 'environments/environment';

@Component({
    selector: 'na-dev-picker',
    templateUrl: './dev-picker.component.html',
    styleUrls: ['./dev-picker.component.css']
})
export class DevicePickerComponent extends AutoUnsubscribeComponent implements OnInit {
    readonly NUMBER_IN_PAGE_OPTIONS: number[] = [30, 100, 200];
    readonly SCREENSHOT_REFRESH_DURATION: number = 1200000;

    readonly Enable_BasicConfig: boolean = AppConfigService.configs.devPage.func.basicConfig.enableSingleConfig;
    readonly Enable_Apk_update: boolean = AppConfigService.configs.devPage.func.enableApkUpdate;
    readonly Enable_Firmware_update: boolean = AppConfigService.configs.devPage.func.enableFwUpdate;
    readonly Enable_Reboot: boolean = AppConfigService.configs.devPage.func.enableReboot;
    readonly Enable_Troubleshoot: boolean = AppConfigService.configs.devPage.func.enableTroubleshoot;
    readonly Enable_ClearCache: boolean = AppConfigService.configs.devPage.func.enableClearCache;
    readonly Enable_ClearAppData: boolean = AppConfigService.configs.devPage.func.enableClearAppData;
    readonly Enable_BatchBasicConfig: boolean = AppConfigService.configs.devPage.func.basicConfig.enableBatchConfig;

    _deviceSource: DeviceInfo[] = [];

    _deviceList: DeviceInfo[] = []; // all devices
    _displayDevListByDevGroup: DeviceInfo[] = []; // devices under a device group
    _displayDevListByFilter: DeviceInfo[] = []; // devices under a device group & filter by filters
    _displayDevListByPage: DeviceInfo[] = []; // devices under a device group & filter by filters & filter by page
    _isRemoteCtrlPreCheckSupport: boolean = false;
    _viewType: DashboardView = DashboardView.table;
    _enumViewType: typeof DashboardView = DashboardView;

    _currentSortKey: string;
    _currentSortType: SortType;
    _enumSortType: typeof SortType = SortType;
    _searchInfo: { key: string, value: string, langKey?: string };
    _enumOnlineType: typeof OnlineStatus = OnlineStatus;

    _groupSwitch: GroupSwitch = GroupSwitch.on;
    _refreshCounter: number = 0;
    _loading: boolean = false;
    _loadingDevices: boolean = false;
    set loadingDevices(v: boolean) {
        this._loadingDevices = v;
        this.onDeviceLoadingStatusChanged.emit(this._loadingDevices);
    }
    _exporting: boolean = false;
    _bAllDeviceSelectedUnderCurrentPage: boolean = false;

    _screenshotInfo: {
        [virtualId: string]: {
            url: SafeUrl,
            pending?: boolean
        }
    } = {};

    _numberInPage: number = this.NUMBER_IN_PAGE_OPTIONS[0];
    _currentPage: number = 1;
    _selectedDeviceAmountByFilter: number = 0;

    _searchKeyList: { key: string, langKey: string }[] = [];
    _currentSearchKey: { key: string, langKey: string };

    _tableColMap: { [key: string]: DisplayItem } = {};
    _tableColumnList: DisplayItem[] = [];

    _isAdvanceFilterApplied: boolean = false;

    private _proactiveFilter: { onlineStatus?: { [state: string]: boolean }, label?: string };
    @Input('filter')
    set filter(v: { onlineStatus?: { [state: string]: boolean }, label?: string }) {
        this._proactiveFilter = v;
        this.refactorDevices(this._deviceSource, 'designated filter');
    }

    private _reportLinkRef: ElementRef;
    @ViewChild('reportLink', { static: true })
    set reportLink(v: ElementRef) {
        this._reportLinkRef = v;
    }

    private _searchRef: ElementRef;
    @ViewChild('search', { static: true })
    set search(v: ElementRef) {
        if (!this._searchRef && v) {
            this._searchRef = v;

            fromEvent(this._searchRef.nativeElement, 'input').pipe(
                debounceTime(1000),
                concatMap((e: any) => {
                    this._searchInfo = { key: this._currentSearchKey.key, value: e.target.value.toLocaleLowerCase(), langKey: this._currentSearchKey.langKey };
                    return this.devSvc.getDevicesByFilter({ search: this._searchInfo });
                }),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => { });
        }
    }

    private _dragImgRef: ElementRef;
    @ViewChild('dragImgSubstitute', { static: true })
    set dragImgElement(v: ElementRef) {
        this._dragImgRef = v;
    }

    @ViewChild(DevFuncDirective, { static: true }) devFuncHost: DevFuncDirective;
    @ViewChild(DeviceLabelFuncDirective) private _devLabelFuncHost: DeviceLabelFuncDirective;

    @Output() onDeviceSelectionChanged = new EventEmitter<number>();
    @Output() onDeviceLoadingStatusChanged = new EventEmitter<boolean>();

    constructor(
        private constantSvc: ConstantService,
        private accountSvc: AccountService,
        private devSvc: DeviceService,
        private devGroupSvc: DeviceGroupService,
        private userPrefSvc: UserPreferenceService,
        private policySvc: PolicyService,
        private devFuncSvc: DevFuncService,
        private devLabelFuncSvc: DeviceLabelFuncService) {
        super();
    }

    ngOnInit(): void {
        this._groupSwitch = this.devGroupSvc.groupSwitch;
        this._numberInPage = this.userPrefSvc.userPreference.home.device.pageCapacity;
        this.updateViewRelatedFunction();
        this.initColumnAndSearch();

        this.devGroupSvc.onActiveGroupChanged.pipe(
            debounceTime(500),
            takeUntil(this._unsubscribe$)
        ).subscribe((ev: { group: DeviceGroupInfo }) => {
            this.refactorDevices(this._deviceSource, 'active group change');
            this.updateSelectedDeviceAmount();
        });

        this.devGroupSvc.onGroupSwitchChanged.pipe(
            debounceTime(500),
            takeUntil(this._unsubscribe$)
        ).subscribe((groupSwitch: GroupSwitch) => {
            this._groupSwitch = groupSwitch;
            this.updateTableColumnList();
            this.refactorDevices(this._deviceSource, 'group switch change');
        });

        this.devSvc.deviceFilterApplied.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((res: { isApplied: boolean, devices?: DeviceInfo[], sourceFilters?: { rules?: string[], labels?: string[], onlineStatus?: { [state: string]: boolean }, search?: { key: string, value: string } } }) => {
            this._isAdvanceFilterApplied = res.isApplied;

            if (res.isApplied) {
                this.changeDeviceSource(res.devices, 'Adv filter apply');
            }
            else {
                this._searchInfo = null;
                this._searchRef.nativeElement.value = '';
                this.changeDeviceSource(this._deviceList, 'Adv filter reset');
            }
        });

        this._loading = true;
        this.loadingDevices = true;

        HelperLib.checkState(1, () => { return this.devGroupSvc.isReady }, () => {
            this.devSvc.getDevicesByBatch('device-overview-table.onInit').subscribe((res: { hasNext: boolean, isFault: boolean, devices: DeviceInfo[], total: number, errorMessage?: string }) => {
                if (!res.isFault) {
                    this._deviceList = this._deviceList.concat(res.devices.filter(d => d.isPaired));
                    if (!this._isAdvanceFilterApplied) {
                        this.changeDeviceSource(this._deviceList, 'init');
                    }
                }

                if (!res.hasNext) {
                    this.loadingDevices = false;
                }

                this.updateSelectedDeviceAmount();
                this._loading = false;
            });
        });

        fromEvent(window, 'resize').pipe(
            debounceTime(500),
            takeUntil(this._unsubscribe$)
        ).subscribe(e => {
            this.updateViewRelatedFunction();
        });
    }

    private changeDeviceSource(source: DeviceInfo[], reason: string, options?: { skipCommonFilter?: boolean, forceUpdateScreenshot?: boolean }): void {
        this._deviceSource = source;
        this.refactorDevices(this._deviceSource, reason, options);
    }

    private updateViewRelatedFunction(): void {
        this._isRemoteCtrlPreCheckSupport = this.devSvc.isDeviceSupportRemoteCtrl();
        this._viewType = HelperLib.isMobileLayout() ? DashboardView.grid : (this.userPrefSvc.userPreference.home.device.view || DashboardView.table);
    }

    private initColumnAndSearch(): void {
        this._tableColMap = {};
        this._searchKeyList = [];

        const p: { [key: string]: boolean } = this.userPrefSvc.userPreference.home.device.tableLayoutColumnOptionMap;
        //init table column
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_DISPLAYNAME] = new DisplayItem('name', this.constantSvc.DEVKEY_FAKE_DISPLAYNAME, 'key-name', false, true, p ? p[this.constantSvc.DEVKEY_FAKE_DISPLAYNAME] : true);
        this._tableColMap[this.constantSvc.DEVKEY_INFO_MODEL] = new DisplayItem('model', this.constantSvc.DEVKEY_INFO_MODEL, 'key-dev-model', true, true, p ? p[this.constantSvc.DEVKEY_INFO_MODEL] : true);
        this._tableColMap[this.constantSvc.DEVKEY_NET_LAN_MAC] = new DisplayItem('mac', this.constantSvc.DEVKEY_NET_LAN_MAC, 'key-dev-MAC', true, true, p ? p[this.constantSvc.DEVKEY_NET_LAN_MAC] : false);
        this._tableColMap[this.constantSvc.DEVKEY_NET_LAN_IP] = new DisplayItem('ip', this.constantSvc.DEVKEY_NET_LAN_IP, 'key-net-ip', true, true, p ? p[this.constantSvc.DEVKEY_NET_LAN_IP] : false);
        this._tableColMap[this.constantSvc.DEVKEY_INFO_FW_VERSION] = new DisplayItem('fw', this.constantSvc.DEVKEY_INFO_FW_VERSION, 'key-dev-firmwareVersion', true, true, p ? p[this.constantSvc.DEVKEY_INFO_FW_VERSION] : true);
        this._tableColMap[this.constantSvc.DEVKEY_INFO_APKVERSION] = new DisplayItem('apk', this.constantSvc.DEVKEY_INFO_APKVERSION, 'key-dev-apkVersion', true, true, p ? p[this.constantSvc.DEVKEY_INFO_APKVERSION] : false);
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_HEARTBEAT] = new DisplayItem('heartbeat', this.constantSvc.DEVKEY_FAKE_HEARTBEAT, 'key-dev-heartbeat', true, true, p ? p[this.constantSvc.DEVKEY_FAKE_HEARTBEAT] : false);
        this._tableColMap[this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE] = new DisplayItem('warranty', this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE, 'key-warranty', true, true, p ? p[this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE] : false);
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_GROUPNAME] = new DisplayItem('group', this.constantSvc.DEVKEY_FAKE_GROUPNAME, 'key-group', false, false, p ? p[this.constantSvc.DEVKEY_FAKE_GROUPNAME] : true);
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_SCEP_STATUS] = new DisplayItem('scepStatus', this.constantSvc.DEVKEY_FAKE_SCEP_STATUS, 'SCEP status', true, true, p ? p[this.constantSvc.DEVKEY_FAKE_SCEP_STATUS] : true, false);
        this._tableColMap[this.constantSvc.DEVKEY_APPMETADATA] = new DisplayItem('metadata', this.constantSvc.DEVKEY_APPMETADATA, 'Metadata', true, true, p ? p[this.constantSvc.DEVKEY_APPMETADATA] : true, false);
        this.updateTableColumnList();

        //init search key
        this._searchKeyList.push({ key: this.constantSvc.DEVKEY_INFO_PNAME, langKey: 'key-dev-name' });
        this._searchKeyList.push({ key: this.constantSvc.DEVKEY_NET_LAN_MAC, langKey: 'key-dev-MAC' });
        this._searchKeyList.push({ key: this.constantSvc.DEVKEY_NET_LAN_IP, langKey: 'key-net-ip' });

        this._currentSearchKey = this._searchKeyList[0];
    }

    private updateTableColumnList(): void {
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_GROUPNAME].allowChoose = this._tableColMap[this.constantSvc.DEVKEY_FAKE_GROUPNAME].allowShow = this._groupSwitch === GroupSwitch.off;
        this._tableColumnList = HelperLib.mapToList(this._tableColMap);
    }

    //get if remote control is support; if not, hide it.
    supportRemoteCtrl(device: DeviceInfo): boolean {
        return this.devSvc.isDeviceSupportRemoteCtrl(device);
    }

    refreshDevices(): void {
        this._refreshCounter = REFRESH_DURATION * 2;
        HelperLib.countdown(this._refreshCounter, 0, (remain_counter: number) => {
            this._refreshCounter = remain_counter;
        });

        this._loading = true;
        this.loadingDevices = true;
        this._deviceList = [];
        this._displayDevListByDevGroup = [];
        this._displayDevListByFilter = [];
        this._displayDevListByPage = [];
        this._screenshotInfo = {};

        this.devSvc.getDevicesByBatch('device-overview-table.refresh', true).subscribe((res: { isFault: boolean, hasNext: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
            if (!res.isFault) {
                this._deviceList = this._deviceList.concat(res.devices.filter(d => d.isPaired));
                this.refactorDevices(this._deviceSource, 'refresh', { forceUpdateScreenshot: true });
            }

            if (!res.hasNext) {
                this.loadingDevices = false;
            }

            this._loading = false;
        });
    }

    reduceAppFeedbackMetadata(metadata?: any): string {
        return metadata ? JSON.stringify(metadata).substring(0, 20) + ' ...' : '';
    }

    exportDevices(): void {
        this._exporting = true;

        // list policies first and print the policy name to which device group belongs
        this.policySvc.getPolicyList().pipe(
            concatMap((policies: PolicyInfo[]) => this.devSvc.exportDevices(this._displayDevListByFilter, this.devGroupSvc.getActiveGroup()?.id, this.devGroupSvc.getAllDeviceGroups(), policies)),
            map((c: { header: string, metadata?: string[], dataList: string[][], date: string }) => HelperLib.downloadCsv(this._reportLinkRef.nativeElement, 'DeviceList_' + c.date, c.header, c.dataList, c.metadata))
        ).subscribe(() => {
            this._exporting = false;
        });
    }

    changeNumberInPage(currentNumberInPage: number): void {
        if (this._numberInPage !== currentNumberInPage) {
            this._numberInPage = currentNumberInPage;
            this.userPrefSvc.changeHomeDevicePageCapacity(this._numberInPage);
            this.refactorDevices(this._deviceSource, 'numberInPage');
        }
    }

    changeTableColumnOption(option: DisplayItem): void {
        option.show = !option.show;
        this.userPrefSvc.changeHomeTableViewColumn(option.key, option.show);
    }

    chooseSearchKey(searchKey: { key: string, langKey: string }): void {
        if (this._currentSearchKey?.key !== searchKey.key) {
            this._currentSearchKey = searchKey;
            this._searchRef.nativeElement.value = null;
            this._searchInfo = { key: this._currentSearchKey.key, value: null, langKey: this._currentSearchKey.langKey };
            this.devSvc.getDevicesByFilter({ search: this._searchInfo }).subscribe();
        }
    }

    changeView(targetView: DashboardView): void {
        this._viewType = targetView;
        this.userPrefSvc.changeHomeDeviceView(this._viewType);
        if (this._viewType === DashboardView.grid) {
            this.update_screenshot_url();
        }
    }

    selectAllDevices(checked: boolean): void {
        this._bAllDeviceSelectedUnderCurrentPage = checked;
        this._displayDevListByFilter.forEach(d => d.isSelect = checked);
        this.updateSelectedDeviceAmount();
    }

    selectDevice(d: DeviceInfo, checked: boolean): void {
        d.isSelect = checked;
        this._bAllDeviceSelectedUnderCurrentPage = d.isSelect ? this.is_all_devices_selected_under_current_page() : false;
        this.updateSelectedDeviceAmount();
    }

    isTableViewShrink(): boolean {
        return this._groupSwitch == GroupSwitch.on && this.devGroupSvc.getActiveGroup()?.id !== DEVICE_GROUP_ID_HOME;
    }

    onPageChange(currentPage: number): void {
        if (this._loading) {
            return;
        }

        if (this._currentPage !== currentPage) {
            this._currentPage = currentPage;
            this.refactorDevices(this._deviceSource, 'switchPage');
        }
    }

    refreshScreenshot(device: DeviceInfo): void {
        this.update_screenshot_for_device(device, true);
    }

    private update_screenshot_url(force: boolean = false): void {
        if (this._deviceList.length === 0) { return; }

        this._displayDevListByPage.forEach((d: DeviceInfo) => { this.update_screenshot_for_device(d, force); });
    }

    private update_screenshot_for_device(dev: DeviceInfo, force: boolean = false): void {
        const deviationTime = 100 + Math.random() * 3000;

        if (!this._screenshotInfo[dev.virtualId].pending) {
            if (force) {
                this._screenshotInfo[dev.virtualId].url = null;
            }
            this._screenshotInfo[dev.virtualId].pending = true;

            setTimeout(() => {
                this.devSvc.updateScreenshot(dev, force).subscribe((res: { url: SafeUrl, useCache?: boolean, lastUpdateTime: Date }) => {
                    this._screenshotInfo[dev.virtualId].pending = false;
                    this._screenshotInfo[dev.virtualId].url = res.url;
                });
            }, deviationTime);
        }
    }

    private updateSelectedDeviceAmount(): void {
        this._selectedDeviceAmountByFilter = this._displayDevListByFilter.filter(d => d.isSelect).length;
        this.onDeviceSelectionChanged.emit(this._selectedDeviceAmountByFilter);
    }

    private is_all_devices_selected_under_current_page(): boolean {
        for (const d of this._displayDevListByPage) {
            if (!d.isSelect) { return false; }
        }

        return true;
    }

    sortAscend(sortKey: string): void {
        this.sort(sortKey, SortType.ascend);
    }

    sortDescend(sortKey: string): void {
        this.sort(sortKey, SortType.descend);
    }

    private sort(sortKey: string, sortType: SortType): void {
        if (this._currentSortKey !== sortKey || this._currentSortType !== sortType) {
            this._currentSortKey = sortKey;
            this._currentSortType = sortType;
            this.refactorDevices(this._deviceSource, 'sort');
        }
    }

    onDragStart(e: DragEvent): void {
        const activeGroup: DeviceGroupInfo = this.devGroupSvc.getActiveGroup();

        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.setData('text/plain', activeGroup ? activeGroup.id : null);
        e.dataTransfer.setDragImage(this._dragImgRef.nativeElement, -20, -20);
    }

    supportBasicConfig(): boolean {
        return this.Enable_BatchBasicConfig && this.accountSvc.hasScope_task_basicSetting();
    }

    supportDeviceSecurity(): boolean {
        return this.Enable_BatchBasicConfig && this.accountSvc.hasScope_task_securitySetting();
    }

    supportFirmwareUpdate(): boolean {
        return this.Enable_Firmware_update && this.accountSvc.hasScope_task_firmwareUpdate();
    }

    supportIAdeaCareAPKUpdate(): boolean {
        return this.Enable_Apk_update && this.accountSvc.hasScope_task_iadeaCareApkUpdate();
    }

    supportReboot(): boolean {
        return this.Enable_Reboot && this.accountSvc.hasScope_task_reboot();
    }

    supportTroubleshoot(): boolean {
        return this.Enable_Troubleshoot && this.accountSvc.hasScope_task_troubleshoot();
    }

    supportClearCache(): boolean {
        return this.Enable_ClearCache && this.accountSvc.hasScope_task_clearCache();
    }

    supportClearAppData(): boolean {
        return this.Enable_ClearAppData && this.accountSvc.hasScope_task_clearAppData();
    }

    private refactorByDeviceGroup(sourceDevices: DeviceInfo[]): { activeGroup: DeviceGroupInfo, devicesUnderGroup: DeviceInfo[] } {
        let devicesUnderGroup: DeviceInfo[] = sourceDevices;
        let activeGroup: DeviceGroupInfo;

        if (this._groupSwitch === GroupSwitch.on) {
            activeGroup = this.devGroupSvc.getActiveGroup();
            if (activeGroup) {
                if (activeGroup.id !== DEVICE_GROUP_ID_HOME) {
                    devicesUnderGroup = sourceDevices.filter(d => d.groupID === activeGroup.id);
                }
            }
        }

        return { activeGroup: activeGroup, devicesUnderGroup: devicesUnderGroup };
    }

    private refactorDevices(sources: DeviceInfo[], reason?: string, options?: { skipCommonFilter?: boolean, forceUpdateScreenshot?: boolean }): void {
        Logger.logInfo('devPicker', 'refactor', `refactor devices with ${reason}`, options);
        // all source devices
        this._displayDevListByDevGroup = sources; //.slice();
        // filter by device group
        if (!options?.skipCommonFilter) {
            const { activeGroup, devicesUnderGroup } = this.refactorByDeviceGroup(this._displayDevListByDevGroup);
            this._displayDevListByDevGroup = devicesUnderGroup;
        }

        this._displayDevListByFilter = this._displayDevListByDevGroup.slice();

        // filter by label
        if (!options?.skipCommonFilter && this._proactiveFilter) {
            if (this._proactiveFilter.label) {
                this._displayDevListByFilter = this._displayDevListByFilter.filter(d => d.virtualDeviceLabelMap.get(this._proactiveFilter.label) != null);
            }
        }

        // sort
        this._displayDevListByFilter = this._displayDevListByFilter.sort((a: DeviceInfo, b: DeviceInfo) => {
            let comp_a: string;
            let comp_b: string;
            switch (this._currentSortKey) {
                case this.constantSvc.DEVKEY_NET_LAN_MAC:
                    {
                        comp_a = a.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !a.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ? a.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC] : a.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC];
                        comp_b = b.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !b.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ? b.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC] : b.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC];
                    }
                    break;
                case this.constantSvc.DEVKEY_NET_LAN_IP:
                    {
                        comp_a = a.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !a.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ? a.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_IP] : a.currentSettings[this.constantSvc.DEVKEY_NET_LAN_IP];
                        comp_b = b.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !b.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ? b.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_IP] : b.currentSettings[this.constantSvc.DEVKEY_NET_LAN_IP];
                    }
                    break;
                default:
                    {
                        comp_a = a.currentSettings[this._currentSortKey];
                        comp_b = b.currentSettings[this._currentSortKey];
                    }
                    break;
            }

            if (comp_a === comp_b) {
                return 0;
            }

            switch (this._currentSortType) {
                case SortType.ascend:
                    {
                        return comp_a >= comp_b ? 1 : -1;
                    }
                case SortType.descend:
                    {
                        return comp_a >= comp_b ? -1 : 1;
                    }
            }
        });

        // filter by page
        if (this._displayDevListByFilter.length > this._numberInPage) {
            const startIndex = (this._currentPage - 1) * this._numberInPage;
            this._displayDevListByPage = this._displayDevListByFilter.slice(startIndex, startIndex + this._numberInPage);
        }
        else {
            this._displayDevListByPage = this._displayDevListByFilter.slice();
        }

        // update screenshot under this page
        this._displayDevListByPage.forEach((d: DeviceInfo) => {
            this._screenshotInfo[d.virtualId] = this._screenshotInfo[d.virtualId] || { url: null };
        });
        if (this._viewType === DashboardView.grid) {
            this.update_screenshot_url(options?.forceUpdateScreenshot);
        }

        this._bAllDeviceSelectedUnderCurrentPage = this.is_all_devices_selected_under_current_page();
        this.updateSelectedDeviceAmount();
    }

    playDevFunc(funcName: string): void {
        const devFuncItem: DevFuncItem = this.devFuncSvc.getFunctionByName(funcName);
        if (devFuncItem) {
            const viewContainerRef = this.devFuncHost.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(devFuncItem.component);

            (<DevFuncInterface>componentRef.instance).title = devFuncItem.title;
            (<DevFuncInterface>componentRef.instance).devices = this._displayDevListByFilter.filter(d => d.isSelect && d.virtualId);
            (<DevFuncInterface>componentRef.instance).minWidth = 1000;
        }
    }

    addDeviceLabel(): void {
        this.createDevLabelFuncDlg<{ devices: DeviceInfo[] }>(DEVICE_LABEL_FUNC_ATTACH, null, { devices: this._displayDevListByFilter.filter(d => d.isSelect && d.virtualId) });
    }

    private createDevLabelFuncDlg<T>(funcName: string, label?: DeviceLabelInfo, other?: T): void {
        const item: DeviceLabelFuncItem = this.devLabelFuncSvc.getFunctionByName(funcName);
        if (item) {
            const viewContainerRef = this._devLabelFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);
            (<IDeviceLabelFunc<T>>componentRef.instance).title = item.title;
            (<IDeviceLabelFunc<T>>componentRef.instance).label = label;
            (<IDeviceLabelFunc<T>>componentRef.instance).other = other;
        }
    }
}