<div>
    <span class="config-name">
        Daily reboot
        <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
            [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
    </span>
    <div *ngIf="_unsupportReason" class="warning-block mb-1">{{ _unsupportReason }}</div>
    <div class='switch-container'>
        <input id="cmn-toggle-dailyreboot" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
            [checked]="_info.enabled" [disabled]="_lockInfo || _disabled || _unsupportReason"
            (change)="enableFeature($event.target.checked)">
        <label for="cmn-toggle-dailyreboot">
            <span class="sr-only">Enable daily reboot</span>
        </label>
    </div>
    <div class="weekday-selector">
        <span *ngFor="let weekday of WEEKDAY_ABBR_LIST">
            <input type="checkbox" [id]="'dailyReboot-day' + weekday" class="cmn-checkbox"
                [checked]="_info.isWeekdayChecked(weekday)" [disabled]="!_info.enabled || _lockInfo || _disabled || _unsupportReason"
                (change)="checkWeekday(weekday, $event.target.checked)">
            <label [for]="'dailyReboot-day' + weekday">
                <svg>
                    <use xlink:href="#checkmark" />
                </svg>
                <span class="sr-only">Select day {{ weekday }}</span>
            </label>
            <span class="ml-2">{{ weekday }}</span>
        </span>
    </div>
    <div class="mt-2">
        <na-timePicker [time]="_info.time" [on]="_info.enabled" [autoNotify]="true" [lock]="_lockInfo"
            (onTimeChanged)="onDailyRebootTimeChanged($event)"></na-timePicker>
    </div>
</div>