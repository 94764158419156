import { Component, ElementRef, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { NA_STATUS__PAIRING_CODE__INVALID } from '../../../API/na.status';
import { DeviceGroupInfo, DeviceGroupMode } from '../group/group.data';
import { DeviceGroupService } from '../group/dev-group.service';
import { AccountService } from '../../../entry/account.service';
import { ConstantService } from '../../../lib/common/constant.service';
import { Logger } from '../../../lib/common/logger';
import { DeviceInfo, IDevicePairStatusChangeEventArgs, IDeviceUnpairStatusChangeEventArgs, OnlineStatus } from '../data/device-info';
import { VirtualDeviceActions, VirtualDeviceActionType, VirtualDeviceStatusType, VirtualDeviceUIInfo } from '../data/virtual-device-info';
import { DeviceService } from '../device.service';
import { HelperLib, REFRESH_DURATION } from '../../../lib/common/helper.lib';
import { UserPreferenceService } from '../../user-preference.service';
import { CustomResponse } from '../../../lib/common/common.data';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    templateUrl: './dev-pair-own.component.html',
    styleUrls: ['./dev-pair-own.component.css']
})
export class PairDeviceCOmponent extends AutoUnsubscribeComponent {
    className: string;

    private readonly PAIRED_PAIRING_CODE: string = '-- -- --';
    private readonly UNPAIRED_PAIRING_CODE: string = '';
    readonly NUMBER_IN_PAGE_OPTIONS: number[] = [30, 50, 100, 300];

    private _enumDeviceAction: typeof VirtualDeviceActionType = VirtualDeviceActionType;
    private _enumDeviceStatus: typeof VirtualDeviceStatusType = VirtualDeviceStatusType;

    _displayVirtualDeviceList: VirtualDeviceUIInfo[] = [];
    _virtualDeviceList: VirtualDeviceUIInfo[] = [];

    _isLoading: boolean = false;
    _refreshCounter: number = 0;
    _numberInPage: number;
    _page: number = 1;
    _displayPlayerAmount: number = 0;

    //filter elements
    private _searchInfo: { key: string, value: string };

    _devGroupRoot: DeviceGroupInfo;
    _groupMode: DeviceGroupMode = DeviceGroupMode.pickone;
    _targetPairInfo: VirtualDeviceUIInfo;

    private _searchRef: ElementRef;
    @ViewChild('search', { static: true })
    set search(v: ElementRef) {
        if (v) {
            this._searchRef = v;

            fromEvent(this._searchRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._searchInfo = { key: this.constantSvc.DEVKEY_INFO_PNAME, value: e.target.value.toLocaleLowerCase() };
                this.refactorPairInfos();
            });
        }
    }

    constructor(
        private constantSvc: ConstantService,
        private accountSvc: AccountService,
        private devSvc: DeviceService,
        private groupSvc: DeviceGroupService,
        private userPrefSvc: UserPreferenceService
    ) {
        super();
        this.className = 'Device-manage-page';
    }

    ngOnInit(): void {
        this._numberInPage = this.userPrefSvc.userPreference.home.device.pageCapacity;

        this.devSvc.devicePairStatusChanged.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((res: IDevicePairStatusChangeEventArgs) => {
            Logger.logInfo(this.className, 'OnInit', 'Pair status changed, ', res);

            if (!res.isFault) {
                //if the device is on pairing, it does not have virtualDeviceID.
                const found = this._virtualDeviceList.find(v => v.id === res.virtualDeviceID || (v.name === res.virtualDeviceName && v.status === VirtualDeviceStatusType.Pairing));
                if (found) {
                    found.id = res.virtualDeviceID;
                    this.switchToPairedActionStatus(found, this.get_virtual_device_status(res.device));
                }
            }
            else {
                const found = this._virtualDeviceList.find(v => v.name === res.virtualDeviceName && v.status === VirtualDeviceStatusType.Pairing);
                if (found) {
                    this.switchToUnpairedActionStatus(found);
                    found.errorMessage = res.errorMessage;
                    if (res.error === NA_STATUS__PAIRING_CODE__INVALID) {
                        if (res.virtualDeviceID && !found.id) {
                            found.id = res.virtualDeviceID;
                        }
                    }
                }
            }
        });

        this.devSvc.deviceUnpairStatusChanged.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((res: IDeviceUnpairStatusChangeEventArgs) => {
            Logger.logInfo(this.className, 'OnInit', 'UnPair status changed', res);

            const found = this._virtualDeviceList.find(v => v.id === res.virtualDeviceID);
            if (found) {
                if (!res.isFault) {
                    this._virtualDeviceList.splice(this._virtualDeviceList.indexOf(found), 1);
                    this.refactorPairInfos();
                }
                else {
                    if (res.error === 'VIRTUAL_DEVICE.NOT_PAIRED') {
                        this.switchToUnpairedActionStatus(found);
                    }
                    else {
                        //assume fatal error and the virtual device is not exist.
                        this.switchToPairedActionStatus(found, VirtualDeviceStatusType.Paired);
                    }
                    found.errorMessage = res.errorMessage;
                }
            }
        });

        this._isLoading = true;
        HelperLib.checkState(1, () => { return this.groupSvc.isReady }, () => {
            this._devGroupRoot = this.groupSvc.getHomeGroup();

            this.devSvc.getDevicesByBatch('device-manage-own.onInit').pipe(
                takeUntil(this._unsubscribe$)
            ).subscribe((res: { hasNext: boolean, isFault: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
                this._isLoading = false;
                if (!res.isFault) {
                    this.reset_devices(res.devices);
                    this.refactorPairInfos();
                }
            });
        });
    }

    refreshDevices(): void {
        this._refreshCounter = REFRESH_DURATION * 2;
        HelperLib.countdown(this._refreshCounter, 0, (remain_counter: number) => {
            this._refreshCounter = remain_counter;
        });

        this._virtualDeviceList = [];
        this._displayVirtualDeviceList = [];
        this._isLoading = true;
        this.devSvc.getDevicesByBatch('device-manage-own.refresh', true).subscribe((res: { isFault: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
            if (!res.isFault) {
                this.reset_devices(res.devices);
                this.refactorPairInfos();
            }
            this._isLoading = false;
        });
    }

    changeNumberInPage(currentNumberInPage: number): void {
        if (this._isLoading) {
            return;
        }

        if (this._numberInPage !== currentNumberInPage) {
            this._numberInPage = currentNumberInPage;
            this.refactorPairInfos();
            this.userPrefSvc.changeHomeDevicePageCapacity(this._numberInPage);
        }
    }

    changePage(currentPage: number): void {
        if (this._isLoading) {
            return;
        }

        this._page = currentPage;
        this.refactorPairInfos();
    }

    allowChangeGroup(p: VirtualDeviceUIInfo): boolean {
        if (p.status === VirtualDeviceStatusType.None) {
            return true;
        }

        return false;
    }

    popupGroupDialog(p: VirtualDeviceUIInfo): void {
        this._targetPairInfo = p;
        if (!this._targetPairInfo.group) {
            this._targetPairInfo.group = this.groupSvc.getDefaultGroup();
        }
    }

    onTargetGroupChange(g: DeviceGroupInfo): void {
        this._targetPairInfo.group = g;
    }

    underAction(status: VirtualDeviceStatusType): boolean {
        return status.substring(status.length - 3) === 'ing' ? true : false;
    }

    private get_virtual_device_status(device: DeviceInfo): VirtualDeviceStatusType {
        let status: VirtualDeviceStatusType = VirtualDeviceStatusType.None;
        if (!device) {
            return status;
        }

        switch (device.onlineStatus) {
            case OnlineStatus.Pairing:
                {
                    status = VirtualDeviceStatusType.Pairing;
                }
                break;
            case OnlineStatus.Syncing:
                {
                    status = VirtualDeviceStatusType.Syncing;
                }
                break;
            default:
                {
                    if (device.isPaired) {
                        status = device.currentSettings[this.constantSvc.DEVKEY_FAKE_HEARTBEAT] ? VirtualDeviceStatusType.Paired : VirtualDeviceStatusType.Syncing;
                    }
                    else {
                        status = device.virtualId ? VirtualDeviceStatusType.Created : VirtualDeviceStatusType.None;
                    }
                }
                break;
        }

        return status;
    }

    private reset_devices(devices: DeviceInfo[]): void {
        const comparedAccountID: string = this.accountSvc.isEnterprise() ? this.accountSvc.enterpriseAccountID : this.accountSvc.accountID;
        devices.filter(d => d.virtualDeviceOwnerID === comparedAccountID).forEach(d => {
            const status: VirtualDeviceStatusType = this.get_virtual_device_status(d);

            const vInfo = new VirtualDeviceUIInfo(
                d.virtualId,
                d.virtualName,
                this.groupSvc.getGroupByID(d.groupID),
                d.isPaired ? this.PAIRED_PAIRING_CODE : this.UNPAIRED_PAIRING_CODE,
                d.virtualPairId,
                status,
                VirtualDeviceActions.getActions(this.get_virtual_device_status(d))
            );

            this._virtualDeviceList.push(vInfo);
        });
    }

    add(): void {
        Logger.logInfo(this.className, 'add', 'Add new virtual device');
        this._virtualDeviceList.push(new VirtualDeviceUIInfo(
            '',
            '',
            this.groupSvc.getDefaultGroup() || this._devGroupRoot.childs[0],
            '',
            '',
            VirtualDeviceStatusType.None,
            VirtualDeviceActions.getNotPairActions()
        ));

        this.refactorPairInfos();

        if (this._virtualDeviceList.length > this._numberInPage * this._page) {
            let pageToGo = Math.floor(this._virtualDeviceList.length / this._numberInPage) + 1;
            this.changePage(pageToGo);
        }
    }

    doAction(vInfo: VirtualDeviceUIInfo, actionType: VirtualDeviceActionType): void {
        Logger.logInfo(this.className, 'doAction', 'Action = ' + actionType, vInfo);
        vInfo.errorMessage = null;
        switch (actionType) {
            case VirtualDeviceActionType.Pair:
                {
                    if (vInfo.name.trim() === '') {
                        vInfo.errorMessage = 'Device name is empty';
                        return;
                    }
                    if (vInfo.pairingCode.trim() === '') {
                        vInfo.errorMessage = 'Pairing code is empty';
                        return;
                    }
                    if (vInfo.pairingCode.toLowerCase().match(/[01O]/g)) {
                        vInfo.errorMessage = 'Pairing code is invalid. 0, 1, O is not allowed.';
                        return;
                    }
                    //check if tag name is duplicate
                    for (const exist_vInfo of this._virtualDeviceList) {
                        if (exist_vInfo !== vInfo) {
                            if (exist_vInfo.name === vInfo.name) {
                                vInfo.errorMessage = 'Device name is duplicate';
                                return;
                            }
                        }
                    }

                    vInfo.status = VirtualDeviceStatusType.Pairing;
                    vInfo.actions.updateActionStatus(vInfo.status);
                    this.devSvc.pair(vInfo.name, vInfo.pairingCode, vInfo.id, vInfo.group.id === this.groupSvc.getDefaultGroup()?.id ? null : vInfo.group.id);
                }
                break;
            case VirtualDeviceActionType.UnPair:
                {
                    //For now, unpair will also delete the virtual device.
                    let virtualDevID: string = vInfo.id;
                    if (!virtualDevID) {
                        let found = this.devSvc.devices.find(v => v.virtualName === vInfo.name);
                        if (found) {
                            virtualDevID = found.virtualId;
                        }
                    }

                    vInfo.status = VirtualDeviceStatusType.Unpairing;
                    vInfo.actions.updateActionStatus(vInfo.status);
                    this.devSvc.unPair(virtualDevID);
                }
                break;
            case VirtualDeviceActionType.Delete:
                {
                    vInfo.status = VirtualDeviceStatusType.Removing;
                    vInfo.actions.updateActionStatus(vInfo.status);
                    this.devSvc.delete(vInfo.id)
                        .subscribe((res: CustomResponse<null>) => {
                            if (res.isFault()) {
                                this.switchToUnpairedActionStatus(vInfo);
                                vInfo.errorMessage = HelperLib.getErrorMessage(res);
                            }
                            else {
                                const found = this._virtualDeviceList.find(v => v.id === vInfo.id);
                                if (found) {
                                    this._virtualDeviceList.splice(this._virtualDeviceList.indexOf(vInfo), 1);
                                    this.refactorPairInfos();
                                }
                            }
                        });
                }
                break;
            case VirtualDeviceActionType.Cancel:
            default:
                {
                    //do something
                }
                break;
        }
    }

    private refactorPairInfos(): void {
        let devs: VirtualDeviceUIInfo[] = this._searchInfo ? this._virtualDeviceList.filter(d => d.name.toLocaleLowerCase().indexOf(this._searchInfo.value) >= 0) : this._virtualDeviceList;
        let numberInPage = this._numberInPage;

        if (devs.length > numberInPage) {
            let startIndex = (this._page - 1) * numberInPage;
            this._displayVirtualDeviceList = devs.slice(startIndex, startIndex + numberInPage);
        }
        else {
            this._displayVirtualDeviceList = devs.slice(0, devs.length);
        }

        this._displayPlayerAmount = devs.length;
    }

    private switchToPairedActionStatus(vInfo: VirtualDeviceUIInfo, status: VirtualDeviceStatusType): void {
        vInfo.status = status;
        vInfo.pairingCode = this.PAIRED_PAIRING_CODE;
        vInfo.actions.updateActionStatus(vInfo.status);
    }

    private switchToUnpairedActionStatus(vInfo: VirtualDeviceUIInfo): void {
        vInfo.status = vInfo.id ? VirtualDeviceStatusType.Created : VirtualDeviceStatusType.None;
        vInfo.pairingCode = this.UNPAIRED_PAIRING_CODE;
        vInfo.actions.updateActionStatus(vInfo.status);
    }
}