import { Type } from '@angular/core';
import { Injectable } from '@angular/core';
import { AccessKeyInfo } from '../../../API/v1/Account/api.accessKey.common';
import { AccessKeyDlgCreateComponent } from './dlg/acckey-dlg-create.component';
import { AccessKeyDlgInactiveComponent } from './dlg/acckey-dlg-inactive.component';
import { AccessKeyDlgRemoveComponent } from './dlg/acckey-dlg-remove.component';

export class AccessKeyFuncItem {
  constructor(public component: Type<any>, public name: string, public title: string) {}
}

export interface IAccessKeyFunc {
  title: string;
  key: AccessKeyInfo;
  dialogCompleteHandler?: (result: any) => void;
}

export const ACCKEY_FUNC_CREATE: string = 'acckey-create';
export const ACCKEY_FUNC_INACTIVE: string = 'acckey-inactive';
export const ACCKEY_FUNC_REMOVE: string = 'acckey-remove';

@Injectable()
export class AccessKeyFuncService {
  private _items: AccessKeyFuncItem[] = [];

  constructor() {
    this._items = [
        new AccessKeyFuncItem(AccessKeyDlgCreateComponent, ACCKEY_FUNC_CREATE, 'Create access key'),
        new AccessKeyFuncItem(AccessKeyDlgInactiveComponent, ACCKEY_FUNC_INACTIVE, 'Inactive access key'),
        new AccessKeyFuncItem(AccessKeyDlgRemoveComponent, ACCKEY_FUNC_REMOVE, 'Delete access key')
    ];
  }

  getAllItems(): AccessKeyFuncItem[] {
    return this._items;
  }

  getItemByName(name: string): AccessKeyFuncItem {
    return this._items.find(i => i.name === name);
  }
}
