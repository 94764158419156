import { Component, OnInit } from '@angular/core';

import { AppService } from '../app.service';

@Component({
  selector: 'entry-wrapper',
  templateUrl: './entry-wrapper.component.html',
  styleUrls: ['./entry-wrapper.component.css']
})
export class EntryWrapperComponent implements OnInit {
  constructor(
    public appSvc: AppService
  ) {
  }

  ngOnInit(): void {
  }
}