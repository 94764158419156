import { Component } from '@angular/core';

import { DialogPage } from '../../../../../lib/common/common.data';
import { UserBaseDlgComponent } from '../user-dlg-base.component';
import { USER_DLG_FUNC_CHANGEGROUP_CONTENT } from '../user-dlg-func.def';
import { UserInfo } from '../../user.data';
import { UserGroupInfo } from '../../group/user-group.data';

@Component({
    templateUrl: '../user-dlg-base.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserChangeGroupDlgComponent extends UserBaseDlgComponent<UserInfo[], { from?: UserGroupInfo, to?: UserGroupInfo }> {
    ngOnInit(): void {
        this._action_txt = 'Move';
        this._action_icon = 'fa-exchange-alt';
        this._content_func_name = USER_DLG_FUNC_CHANGEGROUP_CONTENT;

        this._isDataValid = true;
        this._page = DialogPage.action;

        super.ngOnInit();
    }

    submit(): void {
        this._page = DialogPage.submit;

        this.userSvc.moveUserToAnotherUserGroup(this.data, this.other.from, this.other.to).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            if (res.isFault) {
                this._errorMessage = res.errorMessage;
            }

            this._page = DialogPage.result;
        });
    }
}