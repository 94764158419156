import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IPlugin } from './api.plugin.data';

export class APIListPluginManager extends APIBaseManager<void, void, void, { itemList: IPlugin[], limit: number, skip: number, total: number }> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListPlugin';
        this._apiMethod = 'GET';
        this._allowPayloadPrint = false;
    }

    protected getRequestURL(): string {
        return super.getRequestURL() + 'plugins';
    }
}