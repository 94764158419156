import { Injectable } from '@angular/core';

import { IssueActionSubFormComponent } from '../../troubleshoot/action/issue-action-subform.component';
import { FirmwareActionSubFormComponent } from '../../firmware/action/firmware-action-subform.component';
import { BasicConfigActionSubFormComponent } from '../../config/action/basic-config-action-subform.component';
import { NetConfigActionSubFormComponent } from '../../config/action/net-config-action-subform.component';
import { AlertActionSubFormComponent } from '../../alert/action/alert-action-subform.component';

import { DevFuncActionItem } from './dev-func-action-item';
import { 
  DLG_NAME_ISSUE_REPORT, 
  DLG_NAME_FIRMWARE_UPDATE, 
  DLG_NAME_BASIC_CONFIG, 
  DLG_NAME_NET_CONFIG, 
  DLG_NAME_ALERT,
  DLG_NAME_MULTI_BASIC_CONFIG,
  DLG_NAME_SECURITY,
  DLG_NAME_OTP,
  DLG_NAME_SCRIPT
} from '../base-config-data';
import { MultiBasicConfigActionSubFormComponent } from '../../config-m/action/m-basic-config-action-subform.component';
import { SecurityActionSubFormComponent } from '../../security/action/security-action-subform.component';
import { OTPActionSubFormComponent } from '../../otp/action/otp-action-subform.component';
import { ScriptActionSubFormComponent } from '../../script/action/script-action-subform.component';

@Injectable()
export class DevFuncActionService {
  private _items: DevFuncActionItem[] = [];

  constructor() {
    this._items = [
      new DevFuncActionItem(IssueActionSubFormComponent, DLG_NAME_ISSUE_REPORT),
      new DevFuncActionItem(FirmwareActionSubFormComponent, DLG_NAME_FIRMWARE_UPDATE),
      new DevFuncActionItem(BasicConfigActionSubFormComponent, DLG_NAME_BASIC_CONFIG),
      new DevFuncActionItem(MultiBasicConfigActionSubFormComponent, DLG_NAME_MULTI_BASIC_CONFIG),
      new DevFuncActionItem(NetConfigActionSubFormComponent, DLG_NAME_NET_CONFIG),
      new DevFuncActionItem(AlertActionSubFormComponent, DLG_NAME_ALERT),
      new DevFuncActionItem(SecurityActionSubFormComponent, DLG_NAME_SECURITY),
      new DevFuncActionItem(OTPActionSubFormComponent, DLG_NAME_OTP),
      new DevFuncActionItem(ScriptActionSubFormComponent, DLG_NAME_SCRIPT)
    ];
  }

  getAllItems(): DevFuncActionItem[] {
    return this._items;
  }

  getItemByName(name: string): DevFuncActionItem {
    return this._items.find(i => i.name === name);
  }
}
