import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LockScreenInfo, LockScreenMode, ScreenOffInfo, ScreenSaverInfo } from "./screen.data";

@Component({
    selector: 'na-lockScreen',
    templateUrl: './lockScreen.component.html',
    styleUrls: ['../../uiElement.style.css']
})
export class LockScreenComponent implements OnInit {
    readonly LOCKSCREEN_LIST: { displayName: string, mode: LockScreenMode }[] = [
        { displayName: 'Screen off', mode: LockScreenMode.screenOff },
        { displayName: 'Screen saver', mode: LockScreenMode.screenSaver}
    ];

    _policyLockFeature: {[policyName: string]: string[] } = {};
    _enumLockScreenMode: typeof LockScreenMode = LockScreenMode;

    _info: LockScreenInfo = new LockScreenInfo();
    @Input('data')
    set info(s: LockScreenInfo) {
        this._info = s || this._info;
    }

    _unsupportReason: string;
    @Input('unsupportReason')
    set unsupportReason(s: string) {
        this._unsupportReason = s;
    }

    _lockInfo: {[lockMode: string]: { isSync: boolean, policyID: string, policyName: string }};
    @Input('lock')
    set lock(s: {[lockMode: string]: { isSync: boolean, policyID: string, policyName: string }}) {
        this._lockInfo = s;
        // if one of lock-screen item is set by policy, show this item by default in the lock-screen dropdown list. 
        if (this._lockInfo) {
            const defaultSelectedLockMode: { displayName: string, mode: LockScreenMode } = this.LOCKSCREEN_LIST.find(lockItem => lockItem.mode === Object.keys(this._lockInfo)[0]);
            if (defaultSelectedLockMode) {
                this.selectLockScreenMode(defaultSelectedLockMode);
            }

            this._policyLockFeature = this.LOCKSCREEN_LIST.reduce((acc, cur) => {
                if (this._lockInfo[cur.mode]) {
                    acc[this._lockInfo[cur.mode].policyName] = acc[this._lockInfo[cur.mode].policyName] || [];
                    acc[this._lockInfo[cur.mode].policyName].push(cur.displayName);
                }

                return acc;
            }, {});
        }
    }

    @Output() onDataChanged = new EventEmitter<LockScreenInfo>();

    ngOnInit(): void {
        this.notify();
    }

    enableFeature(checked: boolean): void {
        this._info.enabled = checked;
        this.notify();
    }

    selectLockScreenMode(lockType: { displayName: string, mode: LockScreenMode }): void {
        this._info.lockScreenMode = lockType.mode;
        this._info.lockScreenName = lockType.displayName;
        this.notify();
    }

    onLockScreenSettingChanged(data: ScreenSaverInfo | ScreenOffInfo): void {
        this._info.data = data;
        this.notify();
    }

    notify(): void {
        this.onDataChanged.emit(this._info);
    }
}