import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TimePickerComponent } from './timePicker/timePicker.component';
import { CustomPipeModule } from '../lib/pipes/custom-pipe.module';
import { TranslateModule } from '../translate/translate.module';
import { PagerComponent } from './pager/pager.component';
import { VolumeComponent } from './volume/volume.component';
import { ScreenOffComponent } from './schedule/screen/screenOff.component';
import { MaintenancePlaybackComponent } from './maintenancePlayback/mtPlayback.component';
import { MaintenancePlaybackPreviewComponent } from './maintenancePlayback/mtPlayback-preview.component';
import { ScreenOffPreviewComponent } from './schedule/screen/screenOff-preview.component';
import { VolumePreviewComponent } from './volume/volume-preview.component';
import { TimeserverComponent } from './schedule/timeserver/timeserver.component';
import { TimeserverPreviewComponent } from './schedule/timeserver/timeserver-preview.component';
import { DebugComponent } from './debug/debug.component';
import { DebugPreviewComponent } from './debug/debug-preview.component';
import { AppStartPreviewComponent } from './appstart/appstart-preview.component';
import { AppStartComponent } from './appstart/appstart.component';
import { OverlayQRCodeComponent } from './appstart/overlay-qrcode.component';
import { AccountPasswordComponent } from './password/account-pwd.component';
import { AppStartScreensaverComponent } from './appstart/appstart-screensaver.component';
import { AppStartScreensaverPreviewComponent } from './appstart/appstart-screensaver-preview.component';
import { CustomDirectiveModule } from '../directive/custom-directive.module';
import { OverlayQRCodePreviewComponent } from './appstart/overlay-qrcode-preview.component';
import { PowerSaveComponent } from './powerSave/power-save.component';
import { PowerSavePreviewComponent } from './powerSave/power-save-preview.component';
import { DeviceSelectionComponent } from './dev/dev-select.component';
import { TriToggleComponent } from './toggle/tri-toggle.component';
import { AppManagementComponent } from './appMgr/appmgr.component';
import { AppManagementPreviewComponent } from './appMgr/appmgr-preview.component';
import { TimeszoneComponent } from './schedule/timezone/timezone.component';
import { ScreenOrientationComponent } from './display/screen-orientation.component';
import { LockScreenComponent } from './schedule/screen/lockScreen.component';
import { WeekScheduleSelectorComponent } from './schedule/basic/weekScheduleSelector.component';
import { ScreenSaverComponent } from './schedule/screen/screenSaver.component';
import { LockScreenPreviewComponent } from './schedule/screen/lockScreen-preview.component';
import { ScreenSaverPreviewComponent } from './schedule/screen/screenSaver-preview.component';
import { WeekScheduleSelectorPreviewComponent } from './schedule/basic/weekScheduleSelector-preview.component';
import { DailyRebootComponent } from './schedule/reboot/dailyReboot.component';
import { DailyRebootPreviewComponent } from './schedule/reboot/dailyReboot-preview.component';
import { DeviceAdvanceFilterContainerComponent } from './dev/dev-adv-filter-container.component';
import { DeviceAdvanceFilterItemComponent } from './dev/dev-adv-filter-item.component';
import { CustomizationItemImageComponent } from './imgUpload/customization-item-img.component';
import { ColorPickerComponent } from './other/color-picker.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        CustomPipeModule,
        TranslateModule,
        CustomDirectiveModule
    ],
    declarations: [
        TimeszoneComponent,
        TimePickerComponent,
        TimeserverComponent,
        TimeserverPreviewComponent,
        PagerComponent,
        VolumeComponent,
        VolumePreviewComponent,
        ScreenOffComponent,
        ScreenOffPreviewComponent,
        MaintenancePlaybackComponent,
        MaintenancePlaybackPreviewComponent,
        DebugComponent,
        DebugPreviewComponent,
        AppStartComponent,
        AppStartPreviewComponent,
        OverlayQRCodeComponent,
        OverlayQRCodePreviewComponent,
        AccountPasswordComponent,
        AppStartScreensaverComponent,
        AppStartScreensaverPreviewComponent,
        PowerSaveComponent,
        PowerSavePreviewComponent,
        DeviceSelectionComponent,
        TriToggleComponent,
        AppManagementComponent,
        AppManagementPreviewComponent,
        ScreenOrientationComponent,
        LockScreenComponent,
        LockScreenPreviewComponent,
        ScreenSaverComponent,
        ScreenSaverPreviewComponent,
        WeekScheduleSelectorComponent,
        WeekScheduleSelectorPreviewComponent,
        DailyRebootComponent,
        DailyRebootPreviewComponent,
        DeviceAdvanceFilterContainerComponent,
        DeviceAdvanceFilterItemComponent,
        CustomizationItemImageComponent,
        ColorPickerComponent
    ],
    exports: [
        TimeszoneComponent,
        TimePickerComponent,
        TimeserverComponent,
        TimeserverPreviewComponent,
        PagerComponent,
        VolumeComponent,
        VolumePreviewComponent,
        ScreenOffComponent,
        ScreenOffPreviewComponent,
        MaintenancePlaybackComponent,
        MaintenancePlaybackPreviewComponent,
        DebugComponent,
        DebugPreviewComponent,
        AppStartComponent,
        AppStartPreviewComponent,
        OverlayQRCodeComponent,
        OverlayQRCodePreviewComponent,
        AccountPasswordComponent,
        AppStartScreensaverComponent,
        AppStartScreensaverPreviewComponent,
        PowerSaveComponent,
        PowerSavePreviewComponent,
        DeviceSelectionComponent,
        TriToggleComponent,
        AppManagementComponent,
        AppManagementPreviewComponent,
        ScreenOrientationComponent,
        LockScreenComponent,
        LockScreenPreviewComponent,
        ScreenSaverComponent,
        ScreenSaverPreviewComponent,
        WeekScheduleSelectorComponent,
        WeekScheduleSelectorPreviewComponent,
        DailyRebootComponent,
        DailyRebootPreviewComponent,
        DeviceAdvanceFilterContainerComponent,
        DeviceAdvanceFilterItemComponent,
        CustomizationItemImageComponent,
        ColorPickerComponent
    ]
})
export class UIElementModule { }