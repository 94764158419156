<div>
  <div>
    <span class="blue-2">Enabled :</span>
    <span class="ml-2">{{ _enabled }}</span>
  </div>
  <div>
    <span class="blue-2">Sync location :</span>
    <span class="ml-2">{{ _source }}</span>
  </div>
</div>
