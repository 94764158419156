import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TriState } from '../../../app/lib/common/common.data';

@Component({
    selector: 'na-tri-toggle',
    templateUrl: './tri-toggle.component.html',
    styleUrls: ['./tri-toggle.component.css']
})
export class TriToggleComponent implements OnInit {
    _dataMap: { [id: string]: { displayName: string, checked: boolean } } = {};
    _enumTriState: typeof TriState = TriState;
    _currentState: TriState = TriState.Undetermin;

    _toggleNameList: string[] = ['OFF', 'KEEP', 'ON'];
    @Input('toggleNameList')
    set toggleNameList(o: string[]) {
        if (o.length === 3) {
            this._toggleNameList = o;
        }
    }

    _initState: TriState = TriState.Undetermin;
    @Input('state')
    set state(o: TriState) {
        this._initState = o;
        this._currentState = this._initState;
    }

    _disabled: boolean = false;
    @Input('disabled')
    set disabled(d: boolean) {
        this._disabled = d;
    }

    @Output() onToggleChanged = new EventEmitter<TriState>();

    ngOnInit(): void {
        let i = 0;
        Object.keys(TriState).map(s => {
            this._dataMap[s] = {
                checked: s === this._initState ? true : false,
                displayName: this._toggleNameList[i++]
            }
        });
    }

    private resetState(): void {
        Object.keys(this._dataMap).forEach(s => this._dataMap[s].checked = false);
    }

    private click(state: TriState): void {
        if (!this._disabled && !this._dataMap[state].checked) {
            this._currentState = state;
            this.resetState();
            this._dataMap[state].checked = true;
            this.onToggleChanged.emit(state);
        }
    }

    clickPositive(): void {
        this.click(TriState.Positive);
    }

    clickUndetermin(): void {
        this.click(TriState.Undetermin);
    }

    clickNegative(): void {
        this.click(TriState.Negative);
    }
}