import { Component } from '@angular/core';
import { DialogPage } from '../../../../lib/common/common.data';
import { ScepServerInfo } from '../scep.data';
import { ScepService } from '../scep.service';
import { IScepFuncCtrl } from './scep-func.def';
import { VirtualDlgComponent } from 'app/content/virtual/dlg/virtual-dlg.component';
import { AccountService } from 'app/entry/account.service';

@Component({
    templateUrl: './scep-dlg-remove.component.html',
    styleUrls: ['../scep.style.css']
})
export class ScepRemoveDlgComponent extends VirtualDlgComponent implements IScepFuncCtrl {
    title: string;
    funcName: string;
    scepList: ScepServerInfo[];
    other?: any;
    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;

    _scep: ScepServerInfo;
    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;
    _errorMessage: string;

    constructor(protected accountSvc: AccountService, private scepSvc: ScepService) { super(accountSvc); }

    ngOnInit(): void {
        super.ngOnInit();
        this._scep = this.scepList ? this.scepList[0] : null;
    }

    remove(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;

        this.scepSvc.removeScep(this._scep).subscribe((res: { scep: ScepServerInfo, isFault: boolean, errorMessage?: string }) => {
            this._page = DialogPage.result;
            this._errorMessage = res.errorMessage;
            
            if (!res.isFault) {
                setTimeout(() => {
                    this._dlgCloseElementRef.nativeElement.click();
                }, 0);
            }
        });      
    }
}