import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CustomPipeModule } from "../../../../app/lib/pipes/custom-pipe.module";
import { TranslateModule } from "../../../../app/translate/translate.module";
import { UIElementModule } from "../../../../app/uiElement/uiElement.module";
import { CertOverviewComponent } from "./cert-overview.component";
import { CertService } from "./cert.service";
import { CertPickupComponent } from "./comp/cert-pickup.component";
import { CertAddDlgComponent } from "./dlg/cert-dlg-add.component";
import { CertRemoveDlgComponent } from "./dlg/cert-dlg-remove.component";
import { CertFuncDirective } from "./dlg/cert-func.directive";
import { CertFuncService } from "./dlg/cert-func.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        CustomPipeModule,
        TranslateModule,
        UIElementModule
    ],
    providers: [
        CertService,
        CertFuncService
    ],
    declarations: [
        CertFuncDirective,
        CertOverviewComponent,
        CertAddDlgComponent,
        CertRemoveDlgComponent,
        CertPickupComponent
    ],
    exports: [
        CertPickupComponent
    ]
})
export class CertModule { }