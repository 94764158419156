import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { EnterpriseAccountPersonalInfo } from './api.ent.data';

export interface IGetEnterpriseAccountPathParameter {
    accountID: string;
}

export class APIGetEnterpriseAccountDetailManager extends APIBaseManager<IGetEnterpriseAccountPathParameter, void, void, EnterpriseAccountPersonalInfo> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetEnterpriseAccountDetail';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IGetEnterpriseAccountPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/accounts/' + pathParameters.accountID;
    }
}