<div class="row">
  <div class="dropdown btn-group w-100 mx-3 mb-2 pb-2 layout-mobile" style="border-bottom:1px dashed #777">
    <button id="dropdownConfigurationLink" class="btn btn-action btn-light dropdown-toggle text-center" type="button"
      data-toggle="dropdown">
      Select configurations
    </button>
    <ul class="dropdown-menu option-list w-100" aria-labelledby="dropdownConfigurationLink">
      <li class="dropdown-item" [class.active]="_menuMap[MENU_GENERAL]?.active"
        [class.error]="_menuMap[MENU_GENERAL]?.isFault" (click)="selectMenu(MENU_GENERAL)">
        <span>General</span>
      </li>
      <li class="dropdown-item" [class.active]="_menuMap[MENU_CONTENT_APPSTART]?.active"
        [class.error]="_menuMap[MENU_CONTENT_APPSTART]?.isFault" (click)="selectMenu(MENU_CONTENT_APPSTART)">
        <span>AppStart</span>
      </li>
      <li *ngIf="Enable_overlay" class="dropdown-item" [class.active]="_menuMap[MENU_CONTENT_QRCODE]?.active"
        [class.error]="_menuMap[MENU_CONTENT_QRCODE]?.isFault" (click)="selectMenu(MENU_CONTENT_QRCODE)">
        <span>QR code overlay</span>
      </li>
      <li *ngIf="Enable_maintenance" class="dropdown-item" [class.active]="_menuMap[MENU_CONTENT_MAINTENANCE]?.active"
        [class.error]="_menuMap[MENU_CONTENT_MAINTENANCE]?.isFault" (click)="selectMenu(MENU_CONTENT_MAINTENANCE)">
        <span>{{ 'key-maintenancePlayback' | translate | capitalFirstWord }}</span>
      </li>
      <li *ngIf="Enable_timezone" class="dropdown-item" [class.active]="_menuMap[MENU_SCHEDULE_TIME]?.active"
        [class.error]="_menuMap[MENU_SCHEDULE_TIME]?.isFault" (click)="selectMenu(MENU_SCHEDULE_TIME)">
        <span>Time</span>
      </li>
      <li *ngIf="Enable_dailyReboot" class="dropdown-item" [class.active]="_menuMap[MENU_SCHEDULE_REBOOT]?.active"
        [class.error]="_menuMap[MENU_SCHEDULE_REBOOT]?.isFault" (click)="selectMenu(MENU_SCHEDULE_REBOOT)">
        <span>Reboot schedule</span>
      </li>
      <li *ngIf="Enable_screenOff || Enable_screensaver" class="dropdown-item"
        [class.active]="_menuMap[MENU_SCHEDULE_LOCKSCREEN]?.active"
        [class.error]="_menuMap[MENU_SCHEDULE_LOCKSCREEN]?.isFault" (click)="selectMenu(MENU_SCHEDULE_LOCKSCREEN)">
        <span>{{ 'key-lockScreen' | translate | capitalFirstWord }}</span>
      </li>
    </ul>
  </div>
  <div class="col-lg-3 col-12 layout-desktop menu">
    <ul class="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
      <li class="nav-link" [class.active]="_menuMap[MENU_GENERAL]?.active"
        [class.error]="_menuMap[MENU_GENERAL]?.isFault" (click)="selectMenu(MENU_GENERAL)">
        <i class="fas fa-cog symbol"></i>
        <span>General</span>
      </li>
      <li class="nav-link" [class.active]="_menuMap[MENU_CONTENT]?.active"
        [class.error]="_menuMap[MENU_CONTENT]?.isFault" (click)="selectMenu(MENU_CONTENT)">
        <i class="fas fa-calendar-alt symbol"></i>
        <span>Content</span>
      </li>
      <li class="nav-link sub-nav-link" [class.active]="_menuMap[MENU_CONTENT_APPSTART]?.active"
        [class.error]="_menuMap[MENU_CONTENT_APPSTART]?.isFault" (click)="selectMenu(MENU_CONTENT_APPSTART)">
        <span>AppStart</span>
      </li>
      <li *ngIf="Enable_overlay" class="nav-link sub-nav-link" [class.active]="_menuMap[MENU_CONTENT_QRCODE]?.active"
        [class.error]="_menuMap[MENU_CONTENT_QRCODE]?.isFault" (click)="selectMenu(MENU_CONTENT_QRCODE)">
        <span>QR code overlay</span>
      </li>
      <li *ngIf="Enable_maintenance" class="nav-link sub-nav-link"
        [class.active]="_menuMap[MENU_CONTENT_MAINTENANCE]?.active"
        [class.error]="_menuMap[MENU_CONTENT_MAINTENANCE]?.isFault" (click)="selectMenu(MENU_CONTENT_MAINTENANCE)">
        <span>{{ 'key-maintenancePlayback' | translate | capitalFirstWord }}</span>
      </li>
      <li *ngIf="Enable_timezone || Enable_dailyReboot || Enable_screenOff || Enable_screensaver" class="nav-link" [class.active]="_menuMap[MENU_SCHEDULE]?.active"
        [class.error]="_menuMap[MENU_SCHEDULE]?.isFault" (click)="selectMenu(MENU_SCHEDULE)">
        <i class="fas fa-calendar-alt symbol"></i>
        <span>Schedule</span>
      </li>
      <li *ngIf="Enable_timezone" class="nav-link sub-nav-link" [class.active]="_menuMap[MENU_SCHEDULE_TIME]?.active"
        [class.error]="_menuMap[MENU_SCHEDULE_TIME]?.isFault" (click)="selectMenu(MENU_SCHEDULE_TIME)">
        <span>Time</span>
      </li>
      <li *ngIf="Enable_dailyReboot" class="nav-link sub-nav-link"
        [class.active]="_menuMap[MENU_SCHEDULE_REBOOT]?.active" [class.error]="_menuMap[MENU_SCHEDULE_REBOOT]?.isFault"
        (click)="selectMenu(MENU_SCHEDULE_REBOOT)">
        <span>Reboot schedule</span>
      </li>
      <li *ngIf="Enable_screenOff || Enable_screensaver" class="nav-link sub-nav-link"
        [class.active]="_menuMap[MENU_SCHEDULE_LOCKSCREEN]?.active"
        [class.error]="_menuMap[MENU_SCHEDULE_LOCKSCREEN]?.isFault" (click)="selectMenu(MENU_SCHEDULE_LOCKSCREEN)">
        <span>{{ 'key-lockScreen' | translate | capitalFirstWord }}</span>
      </li>
    </ul>
  </div>
  <div class="col-lg-9 col-12 right-block">
    <div class="tab-content">
      <div *ngIf="_menuMap[MENU_GENERAL]?.active">
        <!-- Player name -->
        <div *ngIf="Enable_name" class="form-group">
          <label for="config-pName" class="config-name">Device name</label>
          <input id="config-pName" type="text" class="form-control form-control-sm" [(ngModel)]="_config.name">
          <div *ngIf="_config.ctrl_name.errorMessage" class="warning-block">
            {{ _config.ctrl_name.errorMessage }}
          </div>
        </div>
        <!-- Resolution -->
        <div *ngIf="Enable_resolution" class="form-group">
          <span class="config-name">Output resolution</span>
          <div class="btn-group w-100">
            <button id="dropdownResolutionLink" type="button"
              class="btn btn-sm btn-action btn-light dropdown-toggle text-center text-lg-left"
              [disabled]="!_device?.availableOptions?.Resolutions || _device?.availableOptions?.Resolutions.length === 0"
              data-toggle="dropdown">
              {{ _config.resolution || 'Select resolution' }}
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu dropdown-menu-left option-list w-100" aria-labelledby="dropdownResolutionLink">
              <li *ngFor="let resolution of _device?.availableOptions?.Resolutions"
                [class.selected]="_config?.resolution === resolution" (click)="_config.resolution = resolution">
                <div>
                  {{ resolution }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- Orientation -->
        <div *ngIf="Enable_orientation" class="form-group">
          <na-screen-orientation [data]="_config.orientation" [lock]="_lockMap[constantSvc.DEVKEY_HD_VIDEO_ROTATION]"
            [availableOptionList]="_device?.availableOptions?.Rotations"
            (changed)="onBasicConfigDisplayOrientationChanged($event)"></na-screen-orientation>
        </div>
        <!-- Volume -->
        <div *ngIf="Enable_volume" class="form-group">
          <na-volume [volume]="_config.volume" [volumeMax]="_config.volumeMax"
            [lock]="_lockMap[constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL]" [showBorder]="true"
            (onVolumeChanged)="_config.volume = $event">
          </na-volume>
        </div>
        <!-- Debugging -->
        <div *ngIf="Enable_adb" class="form-group">
          <span class="config-name">Debugging</span>
          <na-debug [debugUSB]="_config.enableUSBDebug" [debugTCP]="_config.enableTCPDebug" [showBorder]="true"
            (onDebugModeChanged)="_config.onDebugModeChanged($event)"></na-debug>
        </div>
        <!-- HDCP -->
        <div *ngIf="Enable_hdcp && _device?.availableOptions?.HDCP" class="form-group">
          <span class="config-name">HDCP</span>
          <div class='switch-container'>
            <input id="cmn-toggle-hdcp" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
              [checked]="_config.enableHDCP ? 'checked': null" (change)="_config.enableHDCP = $event.target.checked">
            <label for="cmn-toggle-hdcp">
              <span class="sr-only">Enable HDCP</span>
            </label>
          </div>
        </div>
        <!-- Power save -->
        <div *ngIf="Enable_powersave">
          <na-powersave [keyPair]="_config.ctrl_powersave.settingMap"
            [unsupportReason]="!_device?.features.powersave.isSupport ? constantSvc.FEATURE_UNSUPPORT_V44 : ''"
            [lock]="_lockMap[constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE]"
            (onPowersaveChanged)="onBasicConfigPowersaveChanged($event)">
          </na-powersave>
        </div>
      </div>
      <!-- Appstart -->
      <div *ngIf="_menuMap[MENU_CONTENT_APPSTART]?.active">
        <na-appstart [device]="_device" [config]="_config.appStart.settingMap"
          [lock]="_lockMap[constantSvc.DEVKEY_APPSTART]" (onAppstartChanged)="onBasicConfigAppstartChanged($event)">
        </na-appstart>
      </div>
      <!-- QRCode -->
      <div *ngIf="_menuMap[MENU_CONTENT_QRCODE]?.active">
        <na-overlay-qrcode [config]="_config.ctrl_qrcode.settingMap" [lock]="_lockMap[constantSvc.DEVKEY_APPSTART]"
          (onOverlayQRCodeChanged)="onBasicConfigOverlayQRCodeChanged($event)"></na-overlay-qrcode>
      </div>
      <!-- Maintenance playback -->
      <div *ngIf="_menuMap[MENU_CONTENT_MAINTENANCE]?.active">
        <div *ngIf="Enable_maintenance" class="form-group">
          <na-mtPlayback [data]="_config.maintenancePlayback"
            [unsupportReason]="!_device?.features.maintenance.isSupport ? constantSvc.FEATURE_UNSUPPORT : ''"
            [lock]="_lockMap[constantSvc.DEVKEY_FAKE_MAINTENANCE]" (updated)="onBasicConfigMaintenanceChanged($event)">
          </na-mtPlayback>
          <div *ngIf="_config.ctrl_maintenancePlayback.errorMessage" class="warning-block">
            {{ _config.ctrl_maintenancePlayback.errorMessage }}
          </div>
        </div>
      </div>
      <!-- Schedule -> Time -> Timezone / Timeserver -->
      <div *ngIf="_menuMap[MENU_SCHEDULE_TIME]?.active">
        <!-- Timezone -->
        <div *ngIf="Enable_timezone" class="form-group">
          <span class="config-name">
            Timezone
            <i *ngIf="_lockMap[constantSvc.DEVKEY_TIME_TIMEZONE]" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
              [title]="'Lock by policy <<' + _lockMap[constantSvc.DEVKEY_TIME_TIMEZONE].policyName + '>>'"></i>
          </span>
          <div class="btn-group w-100">
            <button id="dropdownTimezoneLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
              [disabled]="_lockMap[constantSvc.DEVKEY_TIME_TIMEZONE]" data-toggle="dropdown">
              {{ _config.timezone }}
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu dropdown-menu-left option-list" aria-labelledby="dropdownTimezoneLink">
              <li *ngFor="let timezone of _device?.availableOptions?.Timezones"
                [class.selected]="_config?.timezone === timezone.id" (click)="_config.timezone = timezone.id">
                <div>
                  GMT{{ timezone.offset }}&nbsp;&nbsp;{{ timezone.displayName }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- Time server -->
        <div *ngIf="Enable_timeserver" class="form-group">
          <na-timeserver [source]="_config.timeserverSyncLocation" [on]="_config.enableTimeserver"
            [lock]="_lockMap[constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED]"
            (changed)="onBasicConfigTimeserverChanged($event)">
          </na-timeserver>
          <div *ngIf="_config.ctrl_timeserver.errorMessage" class="warning-block">
            {{ _config.ctrl_timeserver.errorMessage }}
          </div>
        </div>
      </div>
      <!-- Schedule -> Daily reboot -->
      <div *ngIf="_menuMap[MENU_SCHEDULE_REBOOT]?.active">
        <div *ngIf="Enable_dailyReboot" class="form-group">
          <na-dailyReboot [config]="_config.dailyReboot.settingMap"
            [lock]="_lockMap[constantSvc.DEVKEY_SCHEDULE_REBOOT] || _lockMap[constantSvc.DEVKEY_SCHEDULE_REBOOT_ENABLED] || _lockMap[constantSvc.DEVKEY_SCHEDULE_REBOOT_TIME]"
            (onDataChanged)="onBasicConfigDailyRebootChanged($event)"></na-dailyReboot>
        </div>
      </div>
      <!-- Schedule -> Lock screen -->
      <div *ngIf="_menuMap[MENU_SCHEDULE_LOCKSCREEN]?.active">
        <ng-container *ngIf="Enable_screenOff || Enable_screensaver">
          <na-lockScreen [data]="_config.lockScreen"
            [unsupportReason]="!_device?.features.screenOff.isSupport && !_device?.features.screensaver.isSupport ? constantSvc.FEATURE_UNSUPPORT : ''"
            [lock]="getLockScreenPolicyLock()" (onDataChanged)="onBasicConfigLockScreenChanged($event)">
          </na-lockScreen>
          <div *ngIf="_config.ctrl_lockScreen.errorMessage" class="warning-block">
            {{ _config.ctrl_lockScreen.errorMessage }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>