import { Component, Input, OnInit } from '@angular/core';

import { ITimezoneInfo } from '../../../../../app/lib/common/common.data';
import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { DeviceInfo } from '../../data/device-info';
import { HelperLib } from '../../../../lib/common/helper.lib';
import { DEVICE_GROUP_ID_ROOT, DeviceGroupInfo } from '../../group/group.data';
import { DeviceGroupService } from '../../group/dev-group.service';
import { Logger } from 'app/lib/common/logger';

@Component({
    selector: 'na-device-general-info',
    templateUrl: './device-info-general.component.html',
    styleUrls: ['./device.style.css', './device-info-general.component.css']
})
export class DeviceInfoGeneralComponent implements OnInit {

    @Input('device') _device: DeviceInfo;

    _devGroupRouteList: DeviceGroupInfo[] = [];
    _timezoneDisplayName: string;
    _timezoneOffset: string;
    _timezoneFormat: string = 'yyyy-MM-dd HH:mm:ss';

    constructor(public constantSvc: ConstantService, private devGroupSvc: DeviceGroupService) { }

    ngOnInit(): void {
        if (this._device) {
            if (this._device.currentSettings[this.constantSvc.DEVKEY_TIME_TIMEZONE]) {
                const timezone_pieces: string[] = this._device.currentSettings[this.constantSvc.DEVKEY_TIME_TIMEZONE].split(',');
                if (timezone_pieces.length === 3 && timezone_pieces[2]) {
                    this._timezoneDisplayName = timezone_pieces[0];
                    if (this._device.availableOptions?.Timezones) {
                        const match: ITimezoneInfo = this._device.availableOptions.Timezones.find(t => t.id === timezone_pieces[2]);
                        if (match) {
                            this._timezoneOffset = match.offset;
                        }
                    }

                    if (!this._timezoneOffset) {
                        const match: ITimezoneInfo = HelperLib.getDefaultTimezoneList().find(t => t.id === timezone_pieces[2]);
                        if (match) {
                            this._timezoneOffset = match.offset;
                        }
                    }
                }

                if (!this._timezoneOffset) {
                    this._timezoneOffset = '';
                    this._timezoneFormat += ' Z';
                }

                Logger.logInfo('dev-info-gen', 'init', 'timezone: ', this._timezoneDisplayName, this._timezoneOffset);
            }

            HelperLib.checkState(1, () => { return this.devGroupSvc.isReady }, () => {
                let devGroup: DeviceGroupInfo = this.devGroupSvc.getGroupByID(this._device.virtualId);
                if (devGroup) {
                    devGroup = this.devGroupSvc.getGroupByID(devGroup.parentID);
                }
                else if (this._device.groupID) {
                    devGroup = this.devGroupSvc.getGroupByID(this._device.groupID);
                }

                if (devGroup) {
                    this._devGroupRouteList = this.getDevGroupRoute(devGroup);
                }
            });
        }
    }

    private getDevGroupRoute(devGroup: DeviceGroupInfo): DeviceGroupInfo[] {
        let groupRoute: DeviceGroupInfo[] = [devGroup];
        while (devGroup?.parentID && devGroup.parentID !== DEVICE_GROUP_ID_ROOT) {
            devGroup = this.devGroupSvc.getGroupByID(devGroup.parentID);
            if (devGroup) {
                groupRoute.push(devGroup);
            }
        }

        return groupRoute.reverse();
    }
}