import { Component, Input } from '@angular/core';

import { IAlertFuncCtrl } from './alert-func.def';
import { AlertInfo } from '../alert.data';
import { AlertService } from '../../alert.service';
import { DialogPage } from '../../../../../lib/common/common.data';
import { AccountService } from '../../../../../entry/account.service';
import { VirtualDlgComponent } from 'app/content/virtual/dlg/virtual-dlg.component';

@Component({
    templateUrl: './alertConfig-dlg-remove.component.html',
    styleUrls: ['../alert.style.css']
})
export class AlertConfigDlgRemoveComponent extends VirtualDlgComponent implements IAlertFuncCtrl {
    title: string;

    private _alert: AlertInfo;
    @Input()
    set alert(a: AlertInfo) {
        if (!a) {
            return;
        }

        this._alert = a.id ? a.copy() : a;
    }

    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;
    _errorMessage: string;

    constructor(protected accountSvc: AccountService, private alertSvc: AlertService) {
        super(accountSvc);
    }

    remove(): void {
        this._page = DialogPage.submit;
        this.alertSvc.deleteAlert(this._alert.id).subscribe((errorMessage: string) => {
            this._errorMessage = errorMessage;
            this._page = DialogPage.result;
        });
    }
}