import { Component, OnInit } from '@angular/core';
import { IUserDlgFuncContent } from '../user-dlg-func.def';
import { UserGroupInfo } from '../../group/user-group.data';

@Component({
    templateUrl: './user-group-dlg-new-content.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserGroupNewDlgContentComponent implements OnInit, IUserDlgFuncContent<UserGroupInfo, { groupName?: string }> {
    data: UserGroupInfo;
    funcName: string;
    other?: { groupName?: string } = {};
    onActionPrepared: () => void;
    onActionValidChanged: (valid: boolean) => void;

    ngOnInit(): void {
        // validate default group name
        this.onActionValidChanged(this.other?.groupName ? true : false);
    }

    changeGroupName(name: string): void {
        this.other.groupName = name;
        this.onActionValidChanged(name ? true : false);
    }
}