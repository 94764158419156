import { HttpClient, HttpResponse } from '@angular/common/http';
import { APIBaseManager, IAPIRx } from '../../api.base';
import { ILicenseInfo } from './api.license.common';

export interface IGetLicenseByOwnerTxData {
    ownerList: {
        accountID: string;
    }[]
}

export interface IGetLicenseByOwnerRxData {
    owner: {
        accountID: string;
    },
    licenses: {
        [licenseCategory: string]: {
            detail: {
                [licenseType: string]: {
                    licenseKeyList: ILicenseInfo[];
                }
            }
        }
    }
}

export class APIGetLicenseByOwnerManager extends APIBaseManager<void, void, IGetLicenseByOwnerTxData, IGetLicenseByOwnerRxData[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetLicenseByOwner';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'licenseKeys/owner';
    }

    protected doPostAftermathAction(res: HttpResponse<IAPIRx<IGetLicenseByOwnerRxData[]>>): boolean {
        const serverTime = parseInt(res.headers.get('IAdea-Server-Timestamp-Milliseconds'));
        if (serverTime) {
            res.body.serverTime = serverTime;
        }

        return true;
    }
}