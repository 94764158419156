export enum UserInviteStatus {
    requested = 'requested',
    invited = 'invited',
    accepted = 'accepted'
}

export class UserInfo {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    userRole: string;
    userGroupID: string;
    company: string;
    department: string;
    createdDate: string;
    lastLoginDate: string;
    invitationStatus: UserInviteStatus;

    copy(): UserInfo {
        const u: UserInfo = new UserInfo();
        u.id = this.id;
        u.firstName = this.firstName;
        u.lastName = this.lastName;
        u.email = this.email;
        u.userRole = this.userRole;
        u.userGroupID = this.userGroupID;
        u.company = this.company;
        u.department = this.department;
        u.createdDate = this.createdDate;
        u.lastLoginDate = this.lastLoginDate;
        u.invitationStatus = this.invitationStatus;

        return u;
    }
}












