import { DeviceConfigItem } from './device-config.item';
import { NetworkType } from '../../../device/data/device-net-info';
import { SSIDInfo } from '../../../device/data/device-info';

export class DeviceNetConfigControl {
    errorMessage: string;
    changeItems: { name: string, value: any, langKey?: string, secure?: boolean }[];
    ethernetChangeItems: { name: string, value: any, langKey?: string, secure?: boolean }[];
    wifiChangeItems: { name: string, value: any, langKey?: string, secure?: boolean }[];

    private _netType: DeviceConfigItem<NetworkType> = new DeviceConfigItem<NetworkType>();
    get netType(): NetworkType {
        return this._netType.value;
    }
    set netType(netType: NetworkType) {
        this._netType.value = netType;
    }

    //ethernet
    ethernetEnableItem: DeviceConfigItem<boolean> = new DeviceConfigItem<boolean>();
    ethernetEnableDHCPItem: DeviceConfigItem<boolean> = new DeviceConfigItem<boolean>();
    ethernetIPItem: DeviceConfigItem<string> = new DeviceConfigItem<string>();
    ethernetGatewayItem: DeviceConfigItem<string> = new DeviceConfigItem<string>();
    ethernetMaskItem: DeviceConfigItem<string> = new DeviceConfigItem<string>();
    ethernetDns1Item: DeviceConfigItem<string> = new DeviceConfigItem<string>();
    ethernetDns2Item: DeviceConfigItem<string> = new DeviceConfigItem<string>();

    //wifi
    wifiEnableItem: DeviceConfigItem<boolean> = new DeviceConfigItem<boolean>();
    wifiEnableDHCPItem: DeviceConfigItem<boolean> = new DeviceConfigItem<boolean>();
    wifiIPItem: DeviceConfigItem<string> = new DeviceConfigItem<string>();
    wifiGatewayItem: DeviceConfigItem<string> = new DeviceConfigItem<string>();
    wifiMaskItem: DeviceConfigItem<string> = new DeviceConfigItem<string>();
    wifiDns1Item: DeviceConfigItem<string> = new DeviceConfigItem<string>();
    wifiDns2Item: DeviceConfigItem<string> = new DeviceConfigItem<string>();
    wifiSSIDItem: DeviceConfigItem<string> = new DeviceConfigItem<string>();
    wifiPasswordItem: DeviceConfigItem<string> = new DeviceConfigItem<string>();
    wifiAuthItem: DeviceConfigItem<string> = new DeviceConfigItem<string>();

    constructor() {
        this.errorMessage = '';
        this.changeItems = [];
        this.ethernetChangeItems = [];
        this.wifiChangeItems = [];
    }

    init(
        ethernetEnabled: { name: string, value: boolean },
        ethernetDHCPEnabled: { name: string, value: boolean },
        ethernetIP: { name: string, value: string },
        ethernetGateway: { name: string, value: string },
        ethernetMask: { name: string, value: string },
        ethernetDns1: { name: string, value: string },
        ethernetDns2: { name: string, value: string },
        wifiEnabled: { name: string, value: boolean },
        wifiDHCPEnabled: { name: string, value: boolean },
        wifiIP: { name: string, value: string },
        wifiGateway: { name: string, value: string },
        wifiMask: { name: string, value: string },
        wifiDns1: { name: string, value: string },
        wifiDns2: { name: string, value: string },
        wifiSSID: { name: string, value: string },
        wifiAuth: { name: string, value: string },
        wifiPassword: { name: string, value: string }
    ) {
        this.ethernetEnableItem.init(ethernetEnabled, 'key-net-enableLan');
        this.ethernetEnableDHCPItem.init(ethernetDHCPEnabled, 'key-net-enableDHCP');
        this.ethernetIPItem.init(ethernetIP, 'key-net-ip');
        this.ethernetGatewayItem.init(ethernetGateway, 'key-net-gateway');
        this.ethernetMaskItem.init(ethernetMask, 'key-net-mask');
        this.ethernetDns1Item.init(ethernetDns1, 'key-net-dns1');
        this.ethernetDns2Item.init(ethernetDns2, 'key-net-dns2');

        this.wifiEnableItem.init(wifiEnabled, 'key-net-enableWifi');
        this.wifiEnableDHCPItem.init(wifiDHCPEnabled, 'key-net-enableDHCP');
        this.wifiIPItem.init(wifiIP, 'key-net-ip');
        this.wifiGatewayItem.init(wifiGateway, 'key-net-gateway');
        this.wifiMaskItem.init(wifiMask, 'key-net-mask');
        this.wifiDns1Item.init(wifiDns1, 'key-net-dns1');
        this.wifiDns2Item.init(wifiDns2, 'key-net-dns2');
        this.wifiSSIDItem.init(wifiSSID, 'SSID');
        this.wifiAuthItem.init(wifiAuth, 'key-security');
        this.wifiPasswordItem.init(wifiPassword, 'key-password');

        this.errorMessage = '';
        this.changeItems = [];
        this.ethernetChangeItems = [];
        this.wifiChangeItems = [];
    }

    clear(): void {
        this._netType.clear();

        this.ethernetEnableDHCPItem.clear();
        this.ethernetIPItem.clear();
        this.ethernetGatewayItem.clear();
        this.ethernetMaskItem.clear();
        this.ethernetDns1Item.clear();
        this.ethernetDns2Item.clear();

        this.wifiEnableDHCPItem.clear();
        this.wifiIPItem.clear();
        this.wifiGatewayItem.clear();
        this.wifiMaskItem.clear();
        this.wifiDns1Item.clear();
        this.wifiDns2Item.clear();
        this.wifiSSIDItem.clear();
        this.wifiAuthItem.clear();
        this.wifiPasswordItem.clear();

        this.errorMessage = '';
        this.changeItems = [];
        this.ethernetChangeItems = [];
        this.wifiChangeItems = [];
    }

    skip(): void {
        this.clear();
    }

    isSettingAvailable(): boolean {
        //reset
        this.wifiSSIDItem.warning = false;
        this.ethernetIPItem.warning = false;
        this.ethernetGatewayItem.warning = false;
        this.ethernetMaskItem.warning = false;
        this.ethernetDns1Item.warning = false;
        this.wifiIPItem.warning = false;
        this.wifiGatewayItem.warning = false;
        this.wifiMaskItem.warning = false;
        this.wifiDns1Item.warning = false;

        let ret: boolean = true;

        if (this.wifiEnableItem.value && !this.ethernetEnableItem.value) {
            if (this.wifiSSIDItem.value === '') {
                this.wifiSSIDItem.warning = true;
                ret = false;
            }
        }

        if (this.ethernetEnableItem.value && !this.ethernetEnableDHCPItem.value) {
            if (this.ethernetIPItem.value === '') {
                this.ethernetIPItem.warning = true;
                ret = false;
            }
            else if (!this.ethernetIPItem.value.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)) {
                this.ethernetIPItem.warning = true;
                ret = false;
            }
            if (this.ethernetGatewayItem.value === '') {
                this.ethernetGatewayItem.warning = true;
                ret = false;
            }
            if (this.ethernetMaskItem.value === '') {
                this.ethernetMaskItem.warning = true;
                ret = false;
            }
            if (this.ethernetDns1Item.value === '') {
                this.ethernetDns1Item.warning = true;
                ret = false;
            }
        }

        if (this.wifiEnableItem.value && !this.wifiEnableDHCPItem.value) {
            if (this.wifiIPItem.value === '') {
                this.wifiIPItem.warning = true;
                ret = false;
            }
            if (this.wifiGatewayItem.value === '') {
                this.wifiGatewayItem.warning = true;
                ret = false;
            }
            if (this.wifiMaskItem.value === '') {
                this.wifiMaskItem.warning = true;
                ret = false;
            }
            if (this.wifiDns1Item.value === '') {
                this.wifiDns1Item.warning = true;
                ret = false;
            }
        }

        if (!ret) {
            this.errorMessage = 'You have to fill or correct the above field(s)';
        }
        return ret;
    }

    hasChangeItems(): boolean {
        return this.changeItems.length > 0;
    }

    getChangeItems(bSearchWifiSSID: boolean = false): { name: string, value: any, langKey?: string, secure?: boolean, warning?: boolean, isChanged?: boolean, origin?: any }[] {
        //reset
        this.changeItems = [];
        this.ethernetChangeItems = [];
        this.wifiChangeItems = [];

        if (bSearchWifiSSID) {
            this.ethernetChangeItems.push(this.wifiEnableItem.toObject());
        }
        else {
            //ethernet
            if (this.ethernetEnableItem.isChanged() ||
                this.ethernetEnableDHCPItem.isChanged() ||
                this.ethernetIPItem.isChanged() ||
                this.ethernetGatewayItem.isChanged() ||
                this.ethernetMaskItem.isChanged() ||
                this.ethernetDns1Item.isChanged() ||
                this.ethernetDns2Item.isChanged()) {

                this.ethernetChangeItems.push(this.ethernetEnableItem.toObject());

                if (this.ethernetEnableItem.value) {
                    this.ethernetChangeItems.push(this.ethernetEnableDHCPItem.toObject());
                    if (!this.ethernetEnableDHCPItem.value) {
                        //use static ip
                        this.ethernetChangeItems.push(this.ethernetIPItem.toObject());
                        this.ethernetChangeItems.push(this.ethernetGatewayItem.toObject());
                        this.ethernetChangeItems.push(this.ethernetMaskItem.toObject());
                        this.ethernetChangeItems.push(this.ethernetDns1Item.toObject());

                        if (this.ethernetDns2Item.value !== '') {
                            this.ethernetChangeItems.push(this.ethernetDns2Item.toObject());
                        }
                    }
                }
            }

            //wifi
            if (this.wifiEnableItem.isChanged() ||
                this.wifiEnableDHCPItem.isChanged() ||
                this.wifiSSIDItem.isChanged() ||
                this.wifiAuthItem.isChanged() ||
                this.wifiPasswordItem.isChanged() ||
                this.wifiIPItem.isChanged() ||
                this.wifiGatewayItem.isChanged() ||
                this.wifiMaskItem.isChanged() ||
                this.wifiDns1Item.isChanged() ||
                this.wifiDns2Item.isChanged()) {

                this.wifiChangeItems.push(this.wifiEnableItem.toObject());

                if (this.wifiEnableItem.value) {
                    this.wifiChangeItems.push(this.wifiEnableDHCPItem.toObject());
                    this.wifiChangeItems.push(this.wifiSSIDItem.toObject());
                    this.wifiChangeItems.push(this.wifiAuthItem.toObject());
                    this.wifiChangeItems.push(this.wifiPasswordItem.toObject(true));

                    if (!this.wifiEnableDHCPItem.value) {
                        //use static ip
                        this.wifiChangeItems.push(this.wifiIPItem.toObject());
                        this.wifiChangeItems.push(this.wifiGatewayItem.toObject());
                        this.wifiChangeItems.push(this.wifiMaskItem.toObject());
                        this.wifiChangeItems.push(this.wifiDns1Item.toObject());

                        if (this.wifiDns2Item.value !== '') {
                            this.wifiChangeItems.push(this.wifiDns2Item.toObject());
                        }
                    }
                }
            }
        }

        this.changeItems = this.ethernetChangeItems.concat(this.wifiChangeItems);
        return this.changeItems;
    }

    changeEthernetConnectType(useStaticIP: boolean): void {
        this.ethernetEnableDHCPItem.value = !useStaticIP;
    }
    changeWifiConnectType(useStaticIP: boolean): void {
        this.wifiEnableDHCPItem.value = !useStaticIP;
    }
    changeWifiSecurity(se: string): void {
        this.wifiAuthItem.value = se;
    }
    changeNetType(netType: NetworkType, checked: boolean): void {
        if (netType === NetworkType.Ethernet) {
            this.ethernetEnableItem.value = checked;
        }
        else if (netType === NetworkType.Wifi) {
            this.wifiEnableItem.value = checked;
        }

        this.netType = this.ethernetEnableItem.value ? NetworkType.Ethernet : (this.wifiEnableItem.value ? NetworkType.Wifi : NetworkType.None);
    }

    changeSSID(ssidInfo: SSIDInfo): void {
        this.wifiSSIDItem.value = ssidInfo.name;
        this.wifiAuthItem.value = ssidInfo.auth;
    }
}