import { Component } from '@angular/core';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { DLG_NAME_SECURITY } from '../base/base-config-data';
import { LicenseScopeType } from '../../../../app/content/license/license.data';
import { HelperLib } from '../../../lib/common/helper.lib';
import { PolicyType } from 'app/content/setting/policy/policy.data';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css']
})
export class SecurityFormComponent extends BaseConfigFormComponent {
    ngOnInit(): void {
        this._dialogName = DLG_NAME_SECURITY;
        this._licenseScopeType = LicenseScopeType.taskConfig;
        this._policyLockType = PolicyType.Security;
        
        super.ngOnInit();
    }

    protected submit(): void {
        super.submit();
        
        this.devSvc.batchUpdateLocalPassword(this._bSpecific ? [this._actionData.deviceList[0]] : this._actionData.deviceList.filter(d => d.isSelect), this._actionData.newPwd || '').subscribe(res => {     
            this._page++;
            if (res.isFault()) {
                this._errorMessage = HelperLib.getErrorMessage(res);
            }

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler(res);
            }
        });
    }
}