import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WEEKDAY_ABBR_LIST } from '../../../../app/lib/common/helper.lib';
import { IUIElement } from '../../uiElement.interface';
import { DailyRebootInfo } from './dailyReboot.data';
import { ConstantService } from '../../../../app/lib/common/constant.service';

@Component({
    selector: 'na-dailyReboot',
    templateUrl: './dailyReboot.component.html',
    styleUrls: ['../../uiElement.style.css', './dailyReboot.component.css']
})
export class DailyRebootComponent implements IUIElement {
    readonly WEEKDAY_ABBR_LIST: string[] = WEEKDAY_ABBR_LIST;

    _info: DailyRebootInfo = new DailyRebootInfo();
    @Input('config')
    set config(c: { [name: string]: { value: any } }) {
        this._info = new DailyRebootInfo(c[this.constantSvc.DEVKEY_SCHEDULE_REBOOT]?.value);
    }

    _showBorder: boolean = false;
    @Input()
    showBorder(v: boolean) {
        this._showBorder = v;
    }

    _disabled: boolean;
    @Input('disabled')
    set disabled(v: boolean) {
        this._disabled = v;
    }

    _unsupportReason: string;
    @Input('unsupportReason')
    set unsupportReason(v: string) {
        this._unsupportReason = v;
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(d: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = d;
    }

    @Output() onDataChanged = new EventEmitter<DailyRebootInfo>();

    constructor(private constantSvc: ConstantService) {}

    enableFeature(checked: boolean): void {
        this._info.enabled = checked;
        this.notify();
    }

    checkWeekday(weekdayId: string, checked: boolean): void {
        this._info.checkWeekday(weekdayId, checked);
        this.notify();
    }

    onDailyRebootTimeChanged(time: string): void {
        this._info.time = time;
        this.notify();
    }

    notify(): void {
        this.onDataChanged.emit(this._info);
    }
}