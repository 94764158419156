import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WeekDayScheduleMode, WeekScheduleInfo } from "./weekScheduleSelector.data";

@Component({
    selector: 'na-weekScheduleSelector',
    templateUrl: './weekScheduleSelector.component.html',
    styleUrls: ['../../uiElement.style.css']
})
export class WeekScheduleSelectorComponent implements OnInit {
    _actionName: string;
    @Input('actionName')
    set actionName(s: string) {
        this._actionName = s;
    }
    
    _info: WeekScheduleInfo;
    @Input('data')
    set screenOffData(s: WeekScheduleInfo) {
        if (s) {
            this._info = s;
        }
    }

    _enabled: boolean;
    @Input('disabled')
    set disabled(s: boolean) {
        this._enabled = !s;
    }

    _enumWeekDayScheduleMode: typeof WeekDayScheduleMode = WeekDayScheduleMode;

    @Output() updated = new EventEmitter<WeekScheduleInfo>();

    ngOnInit(): void {
        this.notify();
    }

    changeBasicBeginTime(t: string): void {
        if (this._info.basic.beginTime === t) return;

        this._info.basic.beginTime = t;
        this.notify();
    }

    changeBasicEndTime(t: string): void {
        if (this._info.basic.endTime === t) return;

        this._info.basic.endTime = t;
        this.notify();
    }

    changeScheduleMode(m: WeekDayScheduleMode): void {
        if (this._info.basic.scheduleMode === m) return;

        this._info.basic.scheduleMode = m;
        this.notify();
    }

    changeBasicPeriodBeginDate(d: string): void {
        if (this._info.basic.beginDate === d) return;

        this._info.basic.beginDate = d;
        this.notify();
    }

    changeBasicPeriodEndDate(d: string): void {
        if (this._info.basic.endDate === d) return;

        this._info.basic.endDate = d;
        this.notify();
    }

    checkDay(ds: { day: string, checked: boolean }, checked: boolean): void {
        if (ds.checked == checked) return;

        ds.checked = checked;
        this.notify();
    }

    changeWeekendBeginTime(t: string): void {
        if (this._info.weekend.beginTime === t) return;

        this._info.weekend.beginTime = t;
        this.notify();
    }

    changeWeekendEndTime(t: string): void {
        if (this._info.weekend.endTime === t) return;

        this._info.weekend.endTime = t;
        this.notify();
    }

    enableWeekendSetting(enabled: boolean): void {
        if (this._info.weekend.enabled !== enabled) {
            this._info.weekend.enabled = enabled;
            this.notify();
        }
    }

    notify(): void {
        this.updated.emit(this._info);
    }
}