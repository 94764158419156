
import { Component } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { NAService } from '../../API/na.service';
import { IApplyEnterpriseTxData } from '../../API/v1/Enterprise/api.enterprise.apply';
import { DialogPage } from '../../lib/common/common.data';
import { EnterpriseInfo } from './enterprise.data';

@Component({
    templateUrl: './enterprise-apply.component.html',
    styleUrls: ['../entry.style.css', './enterprise-apply.component.css']
})
export class EnterpriseApplyFormComponent {
    _data: EnterpriseInfo = new EnterpriseInfo();

    _checkingSite: boolean;
    _isSiteAvailable: boolean;

    _errorMessage: string;
    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;

    constructor(private naSvc: NAService) {
    }

    changeRootAccount(rootAccount: string): void {
        this._data.rootAccount = rootAccount;
        const pieces: string[] = rootAccount.split('@');
        if (pieces.length >= 2) {
            this._data.domain = pieces[pieces.length - 1];
        }
    }

    onFileClick(e: any): void {
        e.target.value = null;
    }

    onCertFileChange(e: any): void {
        this.extractContent(e.target.files[0]);
    }

    onQueryStatusChange(e: { valid: boolean, exist?: boolean, checking?: boolean }): void {
        this._checkingSite = e.checking;
        if (!this._checkingSite) {
            this._isSiteAvailable = !e.exist;
        }
    }

    private extractContent(f: File): void {
        const reader = new FileReader();
        reader.onloadend = () => {
            const rawdata: string = reader.result as string;
            this._data.sso.appCert = btoa(rawdata.trim());
        };
        reader.onerror = (error: any) => {
        };
        reader.readAsText(f);
    }

    apply(): void {
        this._errorMessage = null;
        this._page = DialogPage.submit;

        const txData: IApplyEnterpriseTxData = {
            enterpriseName: this._data.name,
            portalURL: this._data.url,
            rootUser: this._data.rootAccount,
            contactEmail: this._data.secondEmail,
            enterpriseDomainList: [this._data.domain],
            singleSignOnSettings: this._data.sso.enabled ? {
                directoryID: this._data.sso.tenantID,
                applicationID: this._data.sso.appID,
                applicationName: this._data.sso.appName,
                identifier: this._data.sso.appIdentity,
                cert64: this._data.sso.appCert
            }: null
        };

        this.naSvc.Enterprise.ApplyEnterprise.send(null, null, txData).subscribe(res => {
            this._page = DialogPage.result;
        });
    }
}