<div class="content">
    <div *ngIf="!_loading; else templateLoading">
        <div *ngIf="!_data; else templateProviderRegsitered" class="text-center">
            App is not registered
            <div class="mt-4">
                <button type="button" class="btn btn-sm btn-action btn-ligth"
                    (click)="createNewProviderTemplate()">Create</button>
            </div>
        </div>
        <ng-template #templateProviderRegsitered>
            <div class="data-block">
                <h5 class="title">ServiceNow information</h5>
                <div class="ml-2">
                    <div class="form-group row">
                        <label for="col-svcnow-tenant" class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                            Instance URL
                        </label>
                        <div class="col-6 col-sm-7 col-md-8">
                            <input id="col-svcnow-tenant" type="text" class="form-control form-control-sm" required
                                [class.changed]="_data.tenantUrl.isChanged" [(ngModel)]="_data.tenantUrl.current">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="col-svcnow-username"
                            class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                            Username
                        </label>
                        <div class="col-6 col-sm-7 col-md-8">
                            <input id="col-svcnow-username" type="text" class="form-control form-control-sm" required
                                [class.changed]="_data.username.isChanged" [(ngModel)]="_data.username.current">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="col-svcnow-cred" class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                            Credential
                        </label>
                        <div class="col-6 col-sm-7 col-md-8">
                            <input id="col-svcnow-cred" type="password" class="form-control form-control-sm" required
                                [class.changed]="_data.userCredential.isChanged"
                                [(ngModel)]="_data.userCredential.current">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="_iadeaAccessInfo" class="data-block">
                <div class="d-flex align-items-top justify-content-between">
                    <strong>IAdeaCare access key for event synchronization</strong>
                    <span class="alert alert-danger">Please do not delete the key</span>
                </div>
                <div class="ml-2">
                    <div class="form-group row">
                        <label for="col-ia-accKeyId" class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                            Accesskey ID
                        </label>
                        <div class="col-6 col-sm-7 col-md-8">
                            <div id="col-ia-accKeyId" class="form-control-plaintext-sm">{{ _iadeaAccessInfo.accessKeyID
                                }}</div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="col-ia-accKeyRemark"
                            class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                            Remark
                        </label>
                        <div class="col-6 col-sm-7 col-md-8">
                            <div id="col-ia-accKeyRemark" class="form-control-plaintext-sm">{{
                                _iadeaAccessInfo.accessKeyRemark }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4 text-center">
                <div *ngIf="_message" class="error-block">{{ _message }}</div>
                <div *ngIf="_data" class="d-flex align-items-center justify-content-center">
                    <button *ngIf="!_isNewRegistration" type="button" class="btn btn-sm btn-action btn-light"
                        [disabled]="_loading || !_data?.isValid()" data-toggle="modal"
                        data-target="#disconnectModalHost">
                        <i class="fas fa-unlink mr-1"></i>
                        Disconnect
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-light ml-auto"
                        [disabled]="_loading || !_data?.isChanged()" (click)="resetProviderInfo()">
                        <i class="fas fa-times mr-1"></i>
                        Reset
                    </button>
                    <button *ngIf="_isNewRegistration" type="button" class="btn btn-sm btn-action btn-light ml-2"
                        [disabled]="_loading || !_data?.isValid() || !_data.isChanged()" (click)="createProviderInfo()">
                        <i class="fas fa-link mr-1"></i>
                        Connect
                    </button>
                    <button *ngIf="!_isNewRegistration" type="button" class="btn btn-sm btn-action btn-ligth ml-2"
                        [disabled]="_loading || !_data?.isValid() || !_data.isChanged()" (click)="updateProviderInfo()">
                        <i class="fas fa-check mr-1"></i>
                        Update
                    </button>
                    <div *ngIf="_updating" class="loading loading-p0 ml-4">
                        <div class="loading-box"></div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
    <ng-template #templateLoading>
        <div class="loading">
            <p>Please wait...</p>
            <div class="loading-box"></div>
        </div>
    </ng-template>
</div>
<div id="disconnectModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <div class='modal-dialog'>
        <div class='modal-content'>
            <div class='modal-header'>
                <span class="modal-title">Disconnect ServiceNow</span>
                <button #dlgClose type="button" class="close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class='modal-body'>
                <p>Are you sure you want to disconnect from ServiceNow?</p>
                <p>All paired devices will be disconnected from the rooms in ServiceNow.</p>
                <div class='row control-block d-flex justify-content-center'>
                    <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_loading" data-dismiss="modal">
                        <i class="fas fa-times mr-1"></i>
                        {{ 'key-cancel' | translate | capitalFirstWord }}
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_loading" (click)="removeProviderInfo()">
                        <i class="fas fa-check mr-1"></i>
                        {{ 'key-ok' | translate | capitalFirstWord }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>