import { Component } from '@angular/core';
import { of } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { DLG_NAME_BASIC_CONFIG, ConfigDialogPage } from '../base/base-config-data';
import { DeviceBasicConfigControl, MENU_GENERAL, MENU_SCHEDULE, MENU_SCHEDULE_TIME, MENU_SCHEDULE_REBOOT, MENU_CONTENT_MAINTENANCE, MENU_CONTENT_QRCODE, MENU_CONTENT, MENU_CONTENT_APPSTART, MENU_SCHEDULE_LOCKSCREEN } from './data/device-basic-config.control';
import { DeviceInfo } from '../../device/data/device-info';
import { LicenseScopeType } from '../../license/license.data';
import { LockScreenInfo } from '../../../uiElement/schedule/screen/screen.data';
import { MaintenancePlaybackInfo } from '../../../uiElement/maintenancePlayback/mtPlayback.data';
import { AppConfigService } from '../../../app.config';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css']
})
export class BasicConfigFormComponent extends BaseConfigFormComponent {
    readonly Enable_screenOff: boolean = AppConfigService.configs.devPage.func.basicConfig.element.screenOff.enabled;
    readonly Enable_maintenance: boolean = AppConfigService.configs.devPage.func.basicConfig.element.maintenancePlayback;
    readonly Enable_powersave: boolean = AppConfigService.configs.devPage.func.basicConfig.element.powersave;
    readonly Enable_screensaver: boolean = AppConfigService.configs.devPage.func.basicConfig.element.screenSaver;

    ngOnInit(): void {
        this._dialogName = DLG_NAME_BASIC_CONFIG;
        this._licenseScopeType = LicenseScopeType.taskConfig;

        super.ngOnInit();
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        switch (this._page) {
            case ConfigDialogPage.checking:
                {
                    this._actionData.configData = this.init_config_data(this._legalDevices, this._actionData.configData);
                    this._actionData.configMenu = this.init_config_menu(this._actionData.configMenu);
                }
                break;
            case ConfigDialogPage.action:
                {
                    //check error and update
                    const config: DeviceBasicConfigControl = this._actionData.configData;
                    if (config) {
                        let hasError: boolean = false;
                        //check if device name is duplicate
                        if (config.ctrl_name.isChanged() || config.ctrl_name.errorMessage) {
                            const isValid: boolean = config.ctrl_name.isChanged() ? this.devSvc.isPlayerNameValid(config.name) : true;
                            if (isValid) {
                                config.ctrl_name.errorMessage = null;
                                this._actionData.configMenu[MENU_GENERAL].isFault = false;
                            }
                            else {
                                config.ctrl_name.errorMessage = 'Duplicate name';
                                this._actionData.configMenu[MENU_GENERAL].isFault = true;
                                hasError = true;
                            }
                        }
                        //check if timeserver has valid sync location
                        if (config.ctrl_timeserver.isChanged() || config.ctrl_timeserver.errorMessage) {
                            const isFault: boolean = config.ctrl_timeserver.isChanged() ? config.enableTimeserver && !config.timeserverSyncLocation : false;
                            if (!isFault) {
                                config.ctrl_timeserver.errorMessage = null;
                                this._actionData.configMenu[MENU_SCHEDULE_TIME].isFault = false;
                            }
                            else {
                                config.ctrl_timeserver.errorMessage = 'Do not provide valid timeserver synchronization source';
                                this._actionData.configMenu[MENU_SCHEDULE_TIME].isFault = true;
                                hasError = true;
                            }
                        }
                        //check if maintenance is valid
                        if (config.ctrl_maintenancePlayback.isValid) {
                            config.ctrl_maintenancePlayback.errorMessage = null;
                            this._actionData.configMenu[MENU_CONTENT_MAINTENANCE].isFault = false;
                        }
                        else {
                            config.ctrl_maintenancePlayback.errorMessage = 'Do not provide valid date or download url';
                            this._actionData.configMenu[MENU_CONTENT_MAINTENANCE].isFault = true;
                            hasError = true;
                        }
                        // check if lockScreen is valid
                        if (config.ctrl_lockScreen.isValid) {
                            config.ctrl_lockScreen.errorMessage = null;
                            this._actionData.configMenu[MENU_SCHEDULE_LOCKSCREEN].isFault = false;
                        }
                        else {
                            config.ctrl_lockScreen.errorMessage = 'All fields are required';
                            this._actionData.configMenu[MENU_SCHEDULE_LOCKSCREEN].isFault = true;
                            hasError = true;
                        }
                        if (hasError) {
                            return;
                        }
                    }
                }
                break;
        }

        super.goNext();
    }

    private init_config_menu(currentMenu: { [menuName: string]: { active?: boolean, isFault?: boolean, firstSubMenu?: string } }): { [menuName: string]: { active?: boolean, isFault?: boolean, firstSubMenu?: string } } {
        if (currentMenu) {
            return currentMenu;
        }

        const menuMap: { [menuName: string]: { active?: boolean, isFault?: boolean, firstSubMenu?: string } } = {};
        menuMap[MENU_GENERAL] = { active: true };

        menuMap[MENU_CONTENT] = { firstSubMenu: MENU_CONTENT_APPSTART };
        menuMap[MENU_CONTENT_APPSTART] = {};
        menuMap[MENU_CONTENT_QRCODE] = {};

        if (this.Enable_maintenance) {
            menuMap[MENU_CONTENT_MAINTENANCE] = {};
        }
        menuMap[MENU_SCHEDULE] = { firstSubMenu: MENU_SCHEDULE_TIME };
        menuMap[MENU_SCHEDULE_TIME] = {};
        menuMap[MENU_SCHEDULE_REBOOT] = {};
        // merge screenOff and screenSaver into lockScreen
        if (this.Enable_screenOff || this.Enable_screensaver) {
            menuMap[MENU_SCHEDULE_LOCKSCREEN] = {};
        }

        return menuMap;
    }

    private init_config_data(devs: DeviceInfo[], currentConfigData: DeviceBasicConfigControl): DeviceBasicConfigControl {
        if (currentConfigData) {
            return currentConfigData;
        }

        //only get first device now.
        const dev: DeviceInfo = devs[0];
        if (dev) {
            const configData: DeviceBasicConfigControl = new DeviceBasicConfigControl(this.constantSvc);
            configData.init(
                { key: this.constantSvc.DEVKEY_INFO_PNAME, value: dev.virtualName + ((dev.currentSettings[this.constantSvc.DEVKEY_INFO_PNAME] && dev.virtualName !== dev.currentSettings[this.constantSvc.DEVKEY_INFO_PNAME]) ? '(' + dev.currentSettings[this.constantSvc.DEVKEY_INFO_PNAME] + ')' : '') },
                { key: this.constantSvc.DEVKEY_APPSTART, value: dev.currentSettings[this.constantSvc.DEVKEY_APPSTART] },
                { key: this.constantSvc.DEVKEY_HD_VIDEO_FORMAT, value: dev.applySettings[this.constantSvc.DEVKEY_HD_VIDEO_FORMAT] },
                { key: this.constantSvc.DEVKEY_HD_VIDEO_ROTATION, value: dev.applySettings[this.constantSvc.DEVKEY_HD_VIDEO_ROTATION] },
                {
                    volume: { key: this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_INDEX, value: parseInt(dev.currentSettings[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_INDEX]) || 0 },
                    volumeMax: { key: this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_MAXINDEX, value: dev.currentSettings[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_MAXINDEX] }
                },
                {
                    enableUSB: { key: this.constantSvc.DEVKEY_DEBUG_ADB_ENABLED, value: dev.currentSettings[this.constantSvc.DEVKEY_DEBUG_ADB_ENABLED] },
                    enableTCP: { key: this.constantSvc.DEVKEY_DEBUG_ADB_TCP_ENABLED, value: dev.currentSettings[this.constantSvc.DEVKEY_DEBUG_ADB_TCP_ENABLED] }
                },
                { key: this.constantSvc.DEVKEY_HD_VIDEO_HDCP_ENABLED, value: dev.currentSettings[this.constantSvc.DEVKEY_HD_VIDEO_HDCP_ENABLED] },
                { key: this.constantSvc.DEVKEY_TIME_TIMEZONE, value: dev.currentSettings[this.constantSvc.DEVKEY_TIME_TIMEZONE] },
                {
                    enableTimeserver: { key: this.constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED, value: dev.currentSettings[this.constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED] },
                    syncLocation: { key: this.constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE, value: dev.currentSettings[this.constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE] }
                },
                {
                    enableDailyReboot: { key: this.constantSvc.DEVKEY_SCHEDULE_REBOOT_ENABLED, value: dev.currentSettings[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_ENABLED] },
                    rebootTime: { key: this.constantSvc.DEVKEY_SCHEDULE_REBOOT_TIME, value: dev.currentSettings[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_TIME] },
                    reboot: { key: this.constantSvc.DEVKEY_SCHEDULE_REBOOT, value: dev.currentSettings[this.constantSvc.DEVKEY_SCHEDULE_REBOOT]}
                },
                {
                    maintenancePlayback: { key: this.constantSvc.DEVKEY_FAKE_MAINTENANCE, value: dev.currentSettings[this.constantSvc.DEVKEY_FAKE_MAINTENANCE] },
                    lockScreen: { key: this.constantSvc.DEVKEY_FAKE_LOCKSCREEN, value: dev.currentSettings[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN] }
                },
                {
                    disablePowersave: { key: this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE, value: dev.currentSettings[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE] },
                    powersaveTimeout: { key: this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT, value: dev.currentSettings[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT] },
                    powersaveAction: { key: this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_ACTION, value: dev.currentSettings[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_ACTION] }
                }
            );

            return configData;
        }

        return null;
    }

    protected submit(): void {
        super.submit();

        const devList = this._bSpecific ? [this._legalDevices[0]] : this._legalDevices.filter(d => d.isSelect);

        let lockScreenSettingData: LockScreenInfo = null;
        let maintenanceSettingData: MaintenancePlaybackInfo = null;
        let taskItemList: { name: string, value: any, origin: any, langKey: string }[] = [];
        for (let changeItem of this._actionData.configData.getChangeItems()) {
            switch (changeItem.name) {
                case this.constantSvc.DEVKEY_FAKE_LOCKSCREEN:
                    {
                        lockScreenSettingData = changeItem.value;
                    }
                    break;
                case this.constantSvc.DEVKEY_FAKE_MAINTENANCE:
                    {
                        maintenanceSettingData = changeItem.value;
                    }
                    break;
                default:
                    {
                        taskItemList.push({
                            name: changeItem.name,
                            value: changeItem.value,
                            origin: changeItem.origin,
                            langKey: changeItem.langKey
                        });
                    }
                    break;
            }
        }

        const errorList: string[] = [];
        of(true).pipe(
            concatMap(() => {
                if (lockScreenSettingData || maintenanceSettingData) {
                    const appStartSettingData = taskItemList.find(taskItem => taskItem.name === 'app.start');
                    return this.devSvc.batchScheduleUpdate(devList.map(d => { return { device: d, lockScreenData: lockScreenSettingData, maintenanceData: maintenanceSettingData, options: { updated: { appStart: appStartSettingData?.value } } } })).pipe(
                        map((scheduleResultList: { device: DeviceInfo, isFault: boolean, errorMessage?: string }[]) => {
                            scheduleResultList.filter(r => r.isFault).forEach(r => {
                                errorList.push(r.errorMessage);
                            });

                            return true;
                        })
                    );

                }

                return of(true);
            }),
            concatMap(() => {
                if (taskItemList.length > 0) {
                    return this.devSvc.batchBasicConfig(devList.map(d => { return { device: d, configData: taskItemList } })).pipe(
                        map(res => {
                            if (res.isFault()) {
                                errorList.push(res.errorMessage);
                            }

                            return res;
                        })
                    );
                }

                return of(null);
            })
        ).subscribe(res => {
            this._page++;

            if (errorList.length > 0) {
                this._errorMessage = errorList.join(', ');
            }

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler(res);
            }
        });
    }
}