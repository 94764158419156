<div *ngIf="_info">
    <div *ngIf="_showSwitch">
        <span class="text-info">Enabled :</span>
        <span class="ml-2">{{ _info?.enabled }}</span>
    </div>
    <ng-template [ngIf]="_info?.enabled">
        <div>
            <span class="text-info">Timeout :</span>
            <span class="ml-2">{{ _info?.timeout / 60 }} minutes</span>
        </div>
        <div>
            <span class="text-info">Content :</span>
            <span class="ml-2">{{ _info?.url }}</span>
        </div>
        <ng-template [ngIf]="_info?.enabled">
            <na-weekScheduleSelector-preview [data]="_info?.schedule"></na-weekScheduleSelector-preview>
        </ng-template>
    </ng-template>
</div>