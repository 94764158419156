<div class="mt-2 mb-4">
    <div>
        <div class="block-titlebar">
            <strong class="txt-bold">Label detail</strong>
            <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-auto" [class.active]="_isInEdit"
                [disabled]="!_devLabel" (click)="startEdit()">
                <i class="fas fa-pen mr-1"></i>
                Edit
            </button>
            <button type="button"
                class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-2" [disabled]="_isInEdit || !_devLabel" data-toggle="modal"
                data-target="#devLabelDetailFuncModalHost" (click)="removeDeviceLabel()">
                <i class="fas fa-trash-alt mr-1"></i>
                Delete label
            </button>
        </div>
        <div class="form-group row mt-2">
            <label for="col-devLabel-name" class="col-12 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                Label name
            </label>
            <div class="col-12 col-sm-7 col-md-8 col-xl-6">
                <div *ngIf="!_isInEdit; else templateEditDevLabelName" id="col-devLabel-name"
                    class="form-control-plaintext-sm">
                    {{ _devLabel?.name || '<< Pick up a label >>' }}
                </div>
                <ng-template #templateEditDevLabelName>
                    <input id="col-devLabel-name" type="text" class="form-control form-control-sm"
                        [(ngModel)]="_editDevLabelName">
                </ng-template>
            </div>
        </div>
    </div>
    <div *ngIf="_page === _enumPage.result" class="mt-2">
        <div *ngIf="_errorMessage" class="block block-error">
            <i class="fas fa-exclamation-circle"></i>
            <span class="ml-2">Edit device label name failed : </span>
            <div>{{ _errorMessage }}</div>
        </div>
    </div>
    <div *ngIf="_isInEdit" class="mt-2 d-flex align-items-center">
        <button type="button" class="btn btn-sm btn-action btn-light ml-auto" [disabled]="_page === _enumPage.submit"
            (click)="cancelEdit()">
            <span class="fas fa-times-circle icon-left"></span>
            Cancel
        </button>
        <button type="button" class="btn btn-sm btn-action btn-light ml-2" [disabled]="_page === _enumPage.submit"
            (click)="saveEdit()">
            <span class="fas fa-save icon-left"></span>
            Save
        </button>
        <div *ngIf="_page === _enumPage.submit" class="loading loading-p0 ml-4">
            <div class="loading-box"></div>
        </div>
    </div>
</div>
<div id="devLabelDetailFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template dev-label-func-host></ng-template>
</div>