<div class="row">
  <div class="col-lg-5 col-12">
    <div class="d-flex align-items-center mb-2">
      <div>
        <button *ngIf="supportAlertUpdate()" id="id_alert_config_do_create" type="button"
          class="btn btn-sm btn-action btn-light" data-toggle="modal" data-target="#alertFuncModalHost"
          (click)="createAlert()">
          Create
          <i class="icon fas fa-plus-circle"></i>
        </button>
        <button *ngIf="supportAlertUpdate()" id="id_alert_config_do_edit" type="button"
          class="btn btn-sm btn-action btn-light ml-2" data-toggle="modal" data-target="#alertFuncModalHost"
          [disabled]="!_alert" (click)="editAlert()">
          Edit
          <i class="icon fas fa-edit"></i>
        </button>
        <button *ngIf="supportAlertUpdate()" id="id_alert_config_do_delete" class="btn btn-sm btn-action btn-light ml-2"
          [disabled]="!_alert" data-toggle="modal" data-target="#alertFuncModalHost" (click)="deleteAlert()">
          Delete
          <i class="icon fas fa-trash-alt"></i>
        </button>
      </div>
      <div class="ml-auto">
        <button id="id_alert_config_do_refreshAll" type="button" class="btn btn-sm btn-action btn-light"
          [disabled]="_isLoading" (click)="refreshAlert(true)">
          <i class="fas fa-redo-alt"></i>
          <span class="sr-only">Refresh alert</span>
        </button>
      </div>
    </div>
    <div class="list-group">
      <div class="list-group-item list-group-header">
        <div class="row">
          <span class="col title">Alert rule</span>
          <span class="col title">Alert event</span>
        </div>
      </div>
      <div class="accordion list-group-content list-group-content-nr-3" id="alertAccordionList">
        <ng-template ngFor let-alert [ngForOf]="_alertList">
          <div class="list-group-item" data-toggle="collapse" [attr.data-target]="'#alert-' + alert.shortID"
            aria-expanded="false" [ngClass]="_alert?.id === alert.id ? 'active': ''" (click)="selectAlert(alert)">
            <div class="row align-items-center">
              <span class="col text-break">
                <i class="d-lg-none d-inline-block far fa-bell mr-2"></i>
                {{ alert.name }}
              </span>
              <span class="col col-sub-mobile">{{ alert.eventType?.type }}</span>
            </div>
          </div>
          <div *ngIf="_alert?.id === alert.id" [id]="'alert-' + alert.shortID" class="collapse layout-mobile"
            data-parent="#alertAccordionList">
            <alert-config-report [alert]="_alert"></alert-config-report>
          </div>
        </ng-template>
      </div>
    </div>
    <div *ngIf="_isLoading; else templateAlertConfigLoadingComplete" class="loading">
      <p>Please wait...</p>
      <div class="loading-box"></div>
    </div>
    <ng-template #templateAlertConfigLoadingComplete>
      <div *ngIf="_alertList.length === 0" class="my-4 text-center">
        Do not have any alert now. Click <q><strong>Create</strong></q> to create an alert.
      </div>
    </ng-template>
  </div>
  <div class="col-lg-7 col-12 pl-lg-0 layout-desktop">
    <div class="scroll scroll-sm border">
      <alert-config-report [alert]="_alert"></alert-config-report>
    </div>
  </div>
</div>
<div id="alertFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
  <ng-template alert-func></ng-template>
</div>