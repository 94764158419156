import { Component, Input, ViewChild } from '@angular/core';
import { DeviceGroupType } from '../../../device/group/group.data';
import { PolicyInfo, PolicyType } from '../policy.data';
import { PolicyTypeDirective } from './policy-type.directive';
import { PolicyTypeItem, IPolicyTypeFunc, PolicyTypeService } from './policy-type.service';

@Component({
    selector: 'na-policy-report',
    templateUrl: './policy-report.component.html',
    styleUrls: ['../policy.style.css', './policy-report.component.css']
})
export class PolicyReportComponent {
    _enumDevGroupType: typeof DeviceGroupType = DeviceGroupType;
    
    _policy: PolicyInfo;
    @Input()
    set policy(p: PolicyInfo) {
        this._policy = p;
        setTimeout(() => {
            this.showPolicyContent(this._policy.type, this._policy);
        }, 0);
    }

    @ViewChild(PolicyTypeDirective) private _policyTypeHost: PolicyTypeDirective;

    constructor(private policyTypeSvc: PolicyTypeService) {}

    private showPolicyContent(type: PolicyType, p: PolicyInfo): void {
        const item: PolicyTypeItem = this.policyTypeSvc.getItemByName(type);
        if (item) {
            const viewContainerRef = this._policyTypeHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IPolicyTypeFunc>componentRef.instance).type = type;
            (<IPolicyTypeFunc>componentRef.instance).data = p.data;
            (<IPolicyTypeFunc>componentRef.instance).isInEdit = false;
        }
    }
}