<!-- name -->
<div class="form-group row">
  <label for="col-basic-name" class="col-xl-4 col-5 col-form-label col-form-label-sm">
    {{ 'key-dev-name' | translate | capitalFirstWord }}
  </label>
  <div class="col-xl-8 col-7">
    <div id="col-basic-name" class="form-control-plaintext">
      {{ _device?.virtualName }}
      <ng-template
        [ngIf]="_device?.currentSettings[constantSvc.DEVKEY_INFO_PNAME] && _device?.virtualName !== _device?.currentSettings[constantSvc.DEVKEY_INFO_PNAME]">
        ({{ _device?.currentSettings[constantSvc.DEVKEY_INFO_PNAME] }})</ng-template>
    </div>
  </div>
</div>
<!-- dev group -->
<div class="form-group row">
  <label for="col-basic-devGroup" class="col-xl-4 col-5 col-form-label col-form-label-sm">
    {{ 'key-dev-group' | translate | capitalFirstWord }}
  </label>
  <div class="col-xl-8 col-7">
    <div id="col-basic-devGroup" class="form-control-plaintext">
      <div *ngIf="_devGroupRouteList.length > 0" class="group-routes">
        <div class="d-flex">
          <div *ngFor="let g of _devGroupRouteList" class="group-route-item" (click)="inspectGroup(g, true)">
            <a [routerLink]="['/app/device/devices']" [queryParams]="{'group': g.id}">{{ g.name }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- model -->
<div class="form-group row">
  <label for="col-basic-model" class="col-xl-4 col-5 col-form-label col-form-label-sm">
    {{ 'key-dev-model' | translate | capitalFirstWord }}
  </label>
  <div class="col-xl-8 col-7">
    <div id="col-basic-model" class="form-control-plaintext">
      {{ _device?.currentSettings[constantSvc.DEVKEY_INFO_MODELDESC] ||
      _device?.currentSettings[constantSvc.DEVKEY_INFO_MODEL] }}
    </div>
  </div>
</div>
<!-- mac -->
<div class="form-group row">
  <label for="col-basic-mac" class="col-xl-4 col-5 col-form-label col-form-label-sm">
    {{ 'key-dev-mainMAC' | translate | capitalFirstWord }}
  </label>
  <div class="col-xl-8 col-7">
    <ng-template [ngIf]="_device?.currentSettings[constantSvc.DEVKEY_NET_LAN_ENABLED]">
      <div id="col-basic-mac" class="form-control-plaintext">
        {{ _device?.currentSettings[constantSvc.DEVKEY_NET_LAN_MAC] }} (Ethernet)
      </div>
    </ng-template>
    <ng-template
      [ngIf]="_device?.currentSettings[constantSvc.DEVKEY_NET_WIFI_ENABLED] && !_device?.currentSettings[constantSvc.DEVKEY_NET_LAN_ENABLED]">
      <div id="col-basic-mac" class="form-control-plaintext">
        {{ _device?.currentSettings[constantSvc.DEVKEY_NET_WIFI_MAC] }} (Wi-Fi)
      </div>
    </ng-template>
  </div>
</div>
<!-- content -->
<div class="form-group row">
  <label for="col-basic-url" class="col-xl-4 col-5 col-form-label col-form-label-sm">
    {{ 'key-dev-contentUrl' | translate | capitalFirstWord }}
  </label>
  <div class="col-xl-8 col-7">
    <div id="col-basic-url" class="form-control-plaintext">
      {{ _device?.currentSettings[this.constantSvc.DEVKEY_APPSTART_CONTENTURL] }}
    </div>
  </div>
</div>
<!-- webview provider -->
<div *ngIf="_device?.currentSettings[this.constantSvc.DEVKEY_INFO_FW_WEBVIEWPROVIDER]" class="form-group row">
  <label for="col-basic-webview" class="col-xl-4 col-5 col-form-label col-form-label-sm">
    WebView provider
  </label>
  <div class="col-xl-8 col-7">
    <div id="col-basic-webview" class="form-control-plaintext">
      {{ _device?.currentSettings[this.constantSvc.DEVKEY_INFO_FW_WEBVIEWPROVIDER] }}
    </div>
  </div>
</div>
<!-- firmware -->
<div class="form-group row">
  <label for="col-basic-firmware" class="col-xl-4 col-5 col-form-label col-form-label-sm">
    {{ 'key-dev-firmwareVersion' | translate | capitalFirstWord }}
  </label>
  <div class="col-xl-8 col-7">
    <div id="col-basic-firmware" class="form-control-plaintext">
      {{ _device?.currentSettings[constantSvc.DEVKEY_INFO_FW_VERSION] }}
    </div>
  </div>
</div>
<!-- apk -->
<div class="form-group row">
  <label for="col-basic-apk" class="col-xl-4 col-5 col-form-label col-form-label-sm">
    {{ 'key-dev-apkVersion' | translate | capitalFirstWord }}
  </label>
  <div class="col-xl-8 col-7">
    <div id="col-basic-apk" class="form-control-plaintext">
      {{ _device?.currentSettings[constantSvc.DEVKEY_INFO_APKVERSION] }}
    </div>
  </div>
</div>
<!-- uptime -->
<div class="form-group row">
  <label for="col-basic-uptime" class="col-xl-4 col-5 col-form-label col-form-label-sm">
    Uptime
  </label>
  <div class="col-xl-8 col-7">
    <div id="col-basic-uptime" class="form-control-plaintext">
      {{ _device?.currentSettings[constantSvc.DEVKEY_INFO_SYSTEM_UPTIME] | secondTransform }}
    </div>
  </div>
</div>
<!-- local time -->
<div class="form-group row">
  <label for="col-basic-localtime" class="col-xl-4 col-5 col-form-label col-form-label-sm">
    {{ 'key-dev-time' | translate | capitalFirstWord }}
  </label>
  <div class="col-xl-8 col-7">
    <div id="col-basic-localtime" class="form-control-plaintext">
      {{ _device?.currentSettings[constantSvc.DEVKEY_TIME_LOCAL] | date:_timezoneFormat:_timezoneOffset }}
      <span *ngIf="_timezoneDisplayName">{{ _timezoneDisplayName }}</span>
    </div>
  </div>
</div>
<!-- heartbeat -->
<div class="form-group row">
  <label for="col-basic-heartbeat" class="col-xl-4 col-5 col-form-label col-form-label-sm">
    {{ 'key-dev-heartbeat' | translate | capitalFirstWord }}
  </label>
  <div class="col-xl-8 col-7">
    <div id="col-basic-heartbeat" class="form-control-plaintext">
      {{ _device?.currentSettings[constantSvc.DEVKEY_FAKE_HEARTBEAT] | date:'yyyy-MM-dd HH:mm:ss zzzz' }}
    </div>
  </div>
</div>
<!-- warranty -->
<div class="form-group row">
  <label for="col-basic-warranty" class="col-xl-4 col-5 col-form-label col-form-label-sm">
    {{ 'key-warranty' | translate | capitalFirstWord }}
  </label>
  <div class="col-xl-8 col-7">
    <div id="col-basic-warranty" class="form-control-plaintext">
      {{ _device?.currentSettings[constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE] | date:'yyyy-MM-dd' }}
    </div>
  </div>
</div>