import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export class APIListEventLogCategoryManager extends APIBaseManager<void, void, void, { [category: string]: { [event: string]: string } }> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListEventLogCategory';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'eventLogs/categories';
    }
}