<div *ngIf="isInEdit; else templateViewMode">
    <div class="form-group row">
        <div class="col-12 d-flex align-items-center">
            <input id="col-devPwd" type="checkbox" class="cmn-checkbox" [checked]="_data.enableDevicePassword"
                (change)="enableDevicePassword($event.target.checked)" />
            <label for="col-devPwd">
                <svg>
                    <use xlink:href="#checkmark" />
                </svg>
                <span class="sr-only">Check to enable device password</span>
            </label>
            <span class="col-form-label col-form-label-sm ml-2">
                Enable device password
            </span>
        </div>
        <div class="col-12 ml-5">
            <div class="cmn-radio">
                <input id="radio-pwd-manual" type="radio" name="dev-pwd-option" [checked]="_data.devicePassword.enabled"
                    [disabled]="!_data.enableDevicePassword" (click)="enableSpecificPassword()" />
                <label for="radio-pwd-manual" class="radio mr-2"></label>
                Specific password
            </div>
            <div class="col-form-label col-form-label-sm ml-5 d-flex align-items-top flex-wrap">
                <div class="mr-4">
                    Apply new password
                    <input #devPasswordNew="ngModel" id="devPasswordNew" name="devPasswordNew"
                        [attr.type]="_showPasswordPlainText ? 'text' : 'password'" autocomplete="new-password"
                        class="form-control form-control-sm" [disabled]="!_data.devicePassword.enabled" minlength="4"
                        [ngModel]="_data.devicePassword.new" (ngModelChange)="changeDeviceNewPassword($event)">
                    <div *ngIf="devPasswordNew.invalid && (devPasswordNew.dirty || devPasswordNew.touched)"
                        class="alert alert-danger">
                        <div *ngIf="devPasswordNew.errors.minlength">
                            Password must be at least 4 characters long.
                        </div>
                    </div>
                </div>
                <div class="mr-4">
                    Confirm new password
                    <input #devPasswordConfirm="ngModel" id="devPasswordConfirm" name="devPasswordConfirm"
                        [attr.type]="_showPasswordPlainText ? 'text' : 'password'" autocomplete="new-password"
                        class="form-control form-control-sm" minlength="4" [disabled]="!_data.devicePassword.enabled"
                        [ngModel]="_data.devicePassword.confirm" (ngModelChange)="changeDeviceConfirmPassword($event)">
                    <div *ngIf="devPasswordConfirm.invalid && (devPasswordConfirm.dirty || devPasswordConfirm.touched)"
                        class="alert alert-danger">
                        <div *ngIf="devPasswordConfirm.errors.minlength">
                            Password must be at least 4 characters long.
                        </div>
                    </div>
                    <div *ngIf="_data.devicePassword && !_data.isDevicePasswordMatch" class="alert alert-danger">
                        Password mismatch
                    </div>
                </div>
            </div>
            <div class="ml-5 font-italic">
                <small>*** Leave the password empty to remove the password.</small>
            </div>
            <div class="cmn-radio">
                <input id="cb-pwd-otp" type="radio" name="dev-pwd-option" [checked]="_data.enableOTP"
                    [disabled]="!_data.enableDevicePassword" (click)="enableOTP()" />
                <label for="cb-pwd-otp" class="radio mr-2"></label>
                Enable OTP (one time password)
            </div>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-12 d-flex align-items-center">
            <input id="col-usb-update" type="checkbox" class="cmn-checkbox" [checked]="_data.disableUSBUpdate"
                (change)="_data.disableUSBUpdate = $event.target.checked" />
            <label for="col-usb-update">
                <svg>
                    <use xlink:href="#checkmark" />
                </svg>
                <span class="sr-only">Check to disable USB update</span>
            </label>
            <span class="col-form-label col-form-label-sm ml-2">
                Disable USB update
            </span>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-12 d-flex align-items-center">
            <input id="col-api" type="checkbox" class="cmn-checkbox" [checked]="_data.disableRESTApi"
                (change)="_data.disableRESTApi = $event.target.checked" />
            <label for="col-api">
                <svg>
                    <use xlink:href="#checkmark" />
                </svg>
                <span class="sr-only">Check to disable REST API</span>
            </label>
            <span class="col-form-label col-form-label-sm ml-2">
                Disable REST API from external connection
            </span>
        </div>
    </div>
</div>
<ng-template #templateViewMode>
    <ul class="pl-5 mb-0">
        <li *ngIf="_data.enableDevicePassword && _data.devicePassword?.enabled"><strong class="red-1">Enable</strong> device password</li>
        <li *ngIf="_data.enableDevicePassword && _data.enableOTP"><strong class="red-1">Enable</strong> OTP</li>
        <li *ngIf="_data.disableUSBUpdate"><strong class="red-1">Disable</strong> USB update</li>
        <li *ngIf="_data.disableRESTApi"><strong class="red-1">Disable</strong> REST API from external connection</li>
    </ul>
</ng-template>