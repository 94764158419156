<div>
    <span class="blue-2">Enabled :</span>
    <span class="ml-2">{{ _data?.enabled }}</span>
</div>
<ng-template [ngIf]="_data?.enabled">
    <div>
        <span class="blue-2">Data :</span>
        <span class="ml-2 text-break">{{ _data?.data }}</span>
    </div>
    <div *ngIf="_data?.landscape?.enabled">
        <span class="blue-2">Landscape :</span>
        <div class="container-fluid layout-block">
            <div class="row">
                <div class="col-xl-3 col-lg-6 col-12">
                    <span>w:</span>
                    <span class="ml-2">{{ _data?.landscape?.width }}</span>
                </div>
                <div class="col-xl-3 col-lg-6 col-12">
                    <span>h:</span>
                    <span class="ml-2">{{ _data?.landscape?.height }}</span>
                </div>
                <div class="col-xl-3 col-lg-6 col-12">
                    <span>left:</span>
                    <span class="ml-2">{{ _data?.landscape?.left }}</span>
                </div>
                <div class="col-xl-3 col-lg-6 col-12">
                    <span>top:</span>
                    <span class="ml-2">{{ _data?.landscape?.top }}</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="_data?.portrait?.enabled">
        <span class="blue-2">Portrait :</span>
        <div class="container-fluid layout-block">
            <div class="row">
                <div class="col-xl-3 col-lg-6 col-12">
                    <span>w:</span>
                    <span class="ml-2">{{ _data?.portrait?.width }}</span>
                </div>
                <div class="col-xl-3 col-lg-6 col-12">
                    <span>h:</span>
                    <span class="ml-2">{{ _data?.portrait?.height }}</span>
                </div>
                <div class="col-xl-3 col-lg-6 col-12">
                    <span>left:</span>
                    <span class="ml-2">{{ _data?.portrait?.left }}</span>
                </div>
                <div class="col-xl-3 col-lg-6 col-12">
                    <span>top:</span>
                    <span class="ml-2">{{ _data?.portrait?.top }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>