import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map, concatMap, concatAll } from 'rxjs/operators';

import { CustomResponse, IClass } from '../../../lib/common/common.data';
import { ActivityInfo, ActivityStatus } from './activity.data';
import { NAService } from '../../../API/na.service';
import { AccountService } from '../../../entry/account.service';
import { IAPIRx } from '../../../API/api.base';
import { DeviceService } from '../../device/device.service';
import { DevFuncService } from '../../devfunc/dev-func.service';
import { DeviceInfo, TaskStatus } from '../../device/data/device-info';
import { DatePipe } from '@angular/common';
import { ConstantService } from '../../../lib/common/constant.service';
import { MaintenancePlaybackInfo } from '../../../uiElement/maintenancePlayback/mtPlayback.data';
import { ScreenOffInfo } from '../../../uiElement/schedule/screen/screen.data';
import { IListTicketRxData } from '../../../API/v1/Ticket/api.ticket.list';
import { ITicketData } from '../../../API/v1/Ticket/api.ticket.common';
import { HelperLib } from '../../../lib/common/helper.lib';

@Injectable()
export class ActivityService implements IClass {
    className: string;
    private readonly ACTIVITY_REQUEST_LIMIT: number = 50;
    private readonly ACTIVITY_REFRESH_THRESHOLD: number = 600000;
    private _activityInfoMap: { [activityID: string]: { info: ActivityInfo, lastRefreshTime: number } } = {};

    _deviceMap: { [virtualDeviceID: string]: DeviceInfo } = {};

    constructor(
        private datePipe: DatePipe,
        private naSvc: NAService,
        private constantSvc: ConstantService,
        private accountSvc: AccountService,
        private devSvc: DeviceService,
        private devFuncSvc: DevFuncService
    ) {
        this.className = 'Activity';
        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            if (!isLogin) {
                this._deviceMap = {};
                this._activityInfoMap = {};
            }
            else {
                this.devSvc.getDevicesByBatch('activitySvc.onLogin').subscribe((res: { hasNext: boolean, isFault: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
                    if (!res.isFault) {
                        this._deviceMap = this._deviceMap || {};
                        res.devices.forEach(d => this._deviceMap[d.virtualId] = d);
                    }
                });
            }
        });

        this.devSvc.getDevicesByBatch('activitySvc.constructor').subscribe((res: { hasNext: boolean, isFault: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
            if (!res.isFault) {
                this._deviceMap = this._deviceMap || {};
                res.devices.forEach(d => this._deviceMap[d.virtualId] = d);
            }
        });
    }

    getActivityList(force: boolean = false, page: number = 0, searchOption?: {
        ticketStatusCode?: ActivityStatus;
        ticketSubject?: string;
        taskAction?: string;
        fromDate?: string;
        toDate?: string;
        success?: boolean;
    }): Observable<{ activityList: ActivityInfo[], total: number, skip: number, limit: number, errorMessage?: string }> {
        const search: {
            ticketAction?: string;
            ticketStatusCode?: ActivityStatus;
            ticketSubject?: string;
            taskAction?: string;
            fromDate?: string;
            toDate?: string;
            success?: boolean;
        } = {
            ticketAction: this.constantSvc.TICKETTYPE_TASK
        };

        if (searchOption.ticketStatusCode !== ActivityStatus.all) {
            search.ticketStatusCode = searchOption.ticketStatusCode;
            switch (searchOption.ticketStatusCode) {
                case ActivityStatus.finished:
                    {
                        search.ticketStatusCode = ActivityStatus.finished;
                        search.success = true;
                    }
                    break;
                case ActivityStatus.fail:
                    {
                        search.ticketStatusCode = ActivityStatus.finished;
                        search.success = false;
                    }
                    break;
            }
        }
        if (searchOption.taskAction !== 'all') {
            search.taskAction = searchOption.taskAction;
        }
        //date filter should appear together
        if (searchOption.fromDate && searchOption.toDate) {
            search.fromDate = searchOption.fromDate;
            let toDate: Date = new Date(searchOption.toDate);
            toDate.setDate(toDate.getDate() + 1);
            const month: number = toDate.getMonth() + 1;
            search.toDate = toDate.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + toDate.getDate(); 
        }
        if (searchOption.ticketSubject) {
            search.ticketSubject = searchOption.ticketSubject;
        }

        return this.naSvc.listTickets(this.accountSvc.token, (page - 1) * this.ACTIVITY_REQUEST_LIMIT, this.ACTIVITY_REQUEST_LIMIT, search).pipe(
            map((res: IAPIRx<IListTicketRxData>) => {
                if (res.error === 0 && res.data) {
                    const transformedActivityList: ActivityInfo[] = [];
                    res.data.itemList.forEach((activity: ITicketData) => {
                        const transformedActivity: ActivityInfo = this.transform(activity, this._activityInfoMap[activity.ticketID] ? this._activityInfoMap[activity.ticketID].info : null);
                        if (transformedActivity) {
                            this._activityInfoMap[activity.ticketID] = this._activityInfoMap[activity.ticketID] || { info: null, lastRefreshTime: 0 };
                            this._activityInfoMap[activity.ticketID].info = transformedActivity;
                            this._activityInfoMap[activity.ticketID].lastRefreshTime = Date.now();

                            transformedActivityList.push(transformedActivity);
                        }
                    });

                    return {
                        activityList: transformedActivityList,
                        total: res.data.total,
                        skip: res.data.skip,
                        limit: res.data.limit
                    };
                }

                return {
                    activityList: [],
                    total: 0,
                    skip: 0,
                    limit: this.ACTIVITY_REQUEST_LIMIT,
                    errorMessage: HelperLib.getErrorMessage(res)
                };
            })
        );
    }

    getActivityByID(activityID: string, force: boolean = false): Observable<ActivityInfo> {
        if (!this.need_refresh(activityID, force)) {
            return observableOf(this._activityInfoMap[activityID].info);
        }

        return this.naSvc.getTicket({ ticketID: activityID }, this.accountSvc.token).pipe(
            map((res: IAPIRx<ITicketData>) => {
                if (res.error === 0 && res.data) {
                    const transform_activity = this.transform(res.data, this._activityInfoMap[res.data.ticketID] ? this._activityInfoMap[res.data.ticketID].info : null);
                    if (transform_activity) {
                        this._activityInfoMap[res.data.ticketID] = {
                            info: transform_activity,
                            lastRefreshTime: Date.now()
                        };
                    }

                    return this._activityInfoMap[activityID].info;
                }

                return null;
            })
        );
    }

    getDeviceStatusList(activity: ActivityInfo): Observable<{
        progress: { virtualDeviceID: string, device?: DeviceInfo, errorMessage?: string, inputData?: { actionID: string, resourceData: any } }[],
        success: { virtualDeviceID: string, device?: DeviceInfo, errorMessage?: string, inputData?: { actionID: string, resourceData: any } }[],
        pending: { virtualDeviceID: string, device?: DeviceInfo, errorMessage?: string, inputData?: { actionID: string, resourceData: any } }[],
        failed: { virtualDeviceID: string, device?: DeviceInfo, errorMessage?: string, inputData?: { actionID: string, resourceData: any } }[],
        cancelled: { virtualDeviceID: string, device?: DeviceInfo, errorMessage?: string, inputData?: { actionID: string, resourceData: any } }[]
    }> {
        return new Observable(observer => {
            observableOf(true).pipe(
                concatMap(() => {
                    const obs: Observable<boolean>[] = [];
                    activity.taskList.forEach(t => {
                        if (t.virtualDevice && t.virtualDevice.virtualDeviceID) {
                            obs.push(this.devSvc.getDeviceByID(t.virtualDevice.virtualDeviceID, false, false).pipe(
                                map((res: CustomResponse<DeviceInfo>) => {
                                    if (!res.isFault() && res.data) {
                                        this._deviceMap[t.virtualDevice.virtualDeviceID] = res.data;
                                    }
                                    return true;
                                })
                            ));
                        }
                    });

                    obs.push(observableOf(false));

                    return obs;
                }),
                concatAll()
            ).subscribe((hasNext: boolean) => {
                if (!hasNext) {
                    observer.next();
                    observer.complete();
                }
            });
        }).pipe(
            map(() => {
                const ret = {
                    progress: [],
                    pending: [],
                    success: [],
                    failed: [],
                    cancelled: []
                };

                activity.taskList.forEach(t => {
                    if (t.virtualDevice && t.virtualDevice.virtualDeviceID && t.status.currentStatus) {
                        //special handling for maintenance and screenOff
                        if (t.seperateActivityInput && t.seperateActivityInput.resourceData && t.seperateActivityInput.resourceData.rawData) {
                            try {
                                const rList: any[] = JSON.parse(t.seperateActivityInput.resourceData.rawData);
                                if (rList) {
                                    t.seperateActivityInput.resourceData.settings = [];
                                    rList.forEach(r => {
                                        switch (r.type) {
                                            case 'maintenance':
                                                {
                                                    t.seperateActivityInput.resourceData.settings = t.seperateActivityInput.resourceData.settings || [];
                                                    t.seperateActivityInput.resourceData.settings.push({
                                                        langKey: 'key-maintenancePlayback',
                                                        name: this.constantSvc.DEVKEY_FAKE_MAINTENANCE,
                                                        value: new MaintenancePlaybackInfo(r.metaData ? r.metaData.rawData : null)
                                                    });
                                                }
                                                break;
                                            case 'screenOff':
                                                {
                                                    t.seperateActivityInput.resourceData.settings = t.seperateActivityInput.resourceData.settings || [];
                                                    t.seperateActivityInput.resourceData.settings.push({
                                                        langKey: 'key-screenoff',
                                                        name: this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF,
                                                        value: new ScreenOffInfo(r.metaData ? r.metaData.rawData : null)
                                                    });
                                                }
                                                break;
                                        }
                                    });
                                }
                            }
                            catch {
                            }
                        }

                        switch (t.status.currentStatus) {
                            case TaskStatus.progress:
                                {
                                    ret.progress.push({
                                        virtualDeviceID: t.virtualDevice.virtualDeviceID,
                                        device: this._deviceMap[t.virtualDevice.virtualDeviceID],
                                        inputData: t.seperateActivityInput
                                    });
                                }
                                break;
                            case TaskStatus.pending:
                                {
                                    ret.pending.push({
                                        virtualDeviceID: t.virtualDevice.virtualDeviceID,
                                        device: this._deviceMap[t.virtualDevice.virtualDeviceID],
                                        inputData: t.seperateActivityInput
                                    });
                                }
                                break;
                            case TaskStatus.finish:
                                {
                                    t.status.success ? ret.success.push({
                                        virtualDeviceID: t.virtualDevice.virtualDeviceID,
                                        device: this._deviceMap[t.virtualDevice.virtualDeviceID],
                                        inputData: t.seperateActivityInput
                                    }) : (t.status.isCancelled ? ret.cancelled.push({
                                        virtualDeviceID: t.virtualDevice.virtualDeviceID,
                                        device: this._deviceMap[t.virtualDevice.virtualDeviceID],
                                        errorMessage: t.status.errorMessage,
                                        inputData: t.seperateActivityInput
                                    }) : ret.failed.push({
                                        virtualDeviceID: t.virtualDevice.virtualDeviceID,
                                        device: this._deviceMap[t.virtualDevice.virtualDeviceID],
                                        errorMessage: t.status.errorMessage,
                                        inputData: t.seperateActivityInput
                                    }));
                                }
                                break;
                        }
                    }
                });

                return ret;
            })
        );
    }

    cancel(act: ActivityInfo): Observable<any> {
        return this.naSvc.cancelTicket(act.activityID, this.accountSvc.token);
    }

    export(act: ActivityInfo): Observable<{ header: string, dataList: string[][], date: string }> {
        return observableOf(true).pipe(
            map(() => {
                const date: string = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH_mm_ss');
                const header = [
                    '"Activity name : ' + act.subject + '"',
                    'Activity status : ' + ActivityStatus[act.statusCode],
                    'Activity ID : ' + act.activityID,
                    'Issue date : ' + act.issueDateTime,
                    'Start date : ' + act.startDateTime,
                    'Finish date : ' + act.finishedDateTime,
                    '',
                    ''
                ].join('\n');

                const rowList: string[][] = [];
                rowList.push(['Device name', 'MAC', 'Status', 'Update date', 'Error message']);
                act.taskList.forEach(t => {
                    let devName: string = t.virtualDevice ? t.virtualDevice.virtualDeviceID : 'Unknown';
                    let devMac: string = 'Unknown';

                    if (this._deviceMap[t.virtualDevice.virtualDeviceID]) {
                        devName = this._deviceMap[t.virtualDevice.virtualDeviceID].virtualName;
                        devMac = 'LAN : ' + this._deviceMap[t.virtualDevice.virtualDeviceID].currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC] + '; Wi-Fi : ' + this._deviceMap[t.virtualDevice.virtualDeviceID].currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC];
                    }

                    rowList.push([
                        '"' + devName + '"',
                        devMac,
                        t.status.currentStatus,
                        t.status.finishedTimestamp ? this.datePipe.transform(new Date(t.status.finishedTimestamp), 'yyyy-MM-dd HH_mm_ss') : '###',
                        '"' + t.status.errorMessage + '"'
                    ]);
                });

                return { header: header, dataList: rowList, date: date };
            })
        );
    }

    private transform(rawData: ITicketData, oldData?: ActivityInfo): ActivityInfo {
        if (!rawData.ticketStatus || !rawData.ticketInput || !rawData.ticketInput.resourceData) {
            return null;
        }

        const bCommonTaskDataValid: number = rawData.ticketInput.resourceData.taskList && rawData.ticketInput.resourceData.taskList.length > 0 ? 1 : 0;
        const bCommonDeviceDataValid: number = rawData.ticketInput.resourceData.virtualDeviceList && rawData.ticketInput.resourceData.virtualDeviceList.length > 0 ? 1 : 0;
        const bSeperateTaskDataValid: number = rawData.ticketInput.resourceData.groupedTaskList && rawData.ticketInput.resourceData.groupedTaskList.length > 0 ? 1 : 0;
        if (bCommonTaskDataValid ^ bCommonDeviceDataValid) {
            return null;
        }
        if ((bCommonTaskDataValid & bCommonDeviceDataValid) === 0 && !bSeperateTaskDataValid) {
            return null;
        }

        const info = oldData || new ActivityInfo();
        info.activityID = rawData.ticketID;
        info.isScheduled = rawData.isScheduled;
        info.issuer = rawData.clientName;
        info.isAdmin = rawData.isAdminTicket;
        info.scheduledDateTime = new Date(rawData.scheduledDate);
        info.issueDateTime = new Date(rawData.issueDate);
        info.startDateTime = rawData.ticketStatus.startedDate ? new Date(rawData.ticketStatus.startedDate) : null;
        info.finishedDateTime = rawData.ticketStatus.finishedDate ? new Date(rawData.ticketStatus.finishedDate) : null;
        info.requestForCancel = rawData.requestForCancel;
        info.requestForCancelDateTime = rawData.requestCancelTimestamp ? new Date(rawData.requestCancelTimestamp) : null;
        info.statusCode = ActivityStatus[rawData.ticketStatusCode];
        if (ActivityStatus[rawData.ticketStatusCode] === ActivityStatus.finished) {
            info.statusCode = rawData.ticketStatus.taskList.find(t => !t.status.success) ? ActivityStatus.fail : ActivityStatus.finished;
        }
        info.subject = rawData.ticketInput.resourceData.ticketSubject;
        info.content = rawData.ticketInput.resourceData.ticketBody;
        if (rawData.ticketInput.resourceData.taskList && rawData.ticketInput.resourceData.virtualDeviceList) {
            //common task data for devices under virtualDeviceList;
            info.commonActivityInputList = rawData.ticketInput.resourceData.taskList.map(ti => { return { actionID: ti.taskAction, resourceData: ti.resourceData } });
        }

        info.taskList = [];
        info.statusCount = {
            success: 0,
            failed: 0,
            cancelled: 0,
            progress: 0,
            pending: 0
        };

        const taskMap: {
            [virtualDeviceID: string]: {
                taskID: string;
                virtualDevice: {
                    virtualDeviceID: string;
                    virtualDevicePairedID: string;
                };
                seperateActivityInput?: {
                    actionID: string;
                    resourceData: any;
                };
                status: {
                    currentStatus: string;
                    errorMessage?: string;
                    startTimestamp?: number;
                    finishedTimestamp?: number;
                    retryTimes?: number;
                    success?: boolean;
                    isCancelled?: boolean;
                    isTimeout?: boolean;
                }
            }
        } = {};

        if (rawData.ticketInput.resourceData) {
            if (rawData.ticketInput.resourceData.virtualDeviceList && rawData.ticketInput.resourceData.virtualDeviceList.length > 0) {
                rawData.ticketInput.resourceData.virtualDeviceList.forEach(v => {
                    taskMap[v.virtualDeviceID] = {
                        taskID: null,
                        virtualDevice: v,
                        status: {
                            currentStatus: TaskStatus.pending
                        }
                    };
                });
            }
            else if (rawData.ticketInput.resourceData.groupedTaskList && rawData.ticketInput.resourceData.groupedTaskList.length > 0) {
                rawData.ticketInput.resourceData.groupedTaskList.forEach(v => {
                    taskMap[v.virtualDeviceID] = {
                        taskID: null,
                        virtualDevice: {
                            virtualDeviceID: v.virtualDeviceID,
                            virtualDevicePairedID: v.virtualDevicePairedID
                        },
                        status: {
                            currentStatus: TaskStatus.pending
                        },
                        seperateActivityInput: {
                            actionID: v.taskAction,
                            resourceData: v.resourceData
                        }
                    };
                })
            }
        }

        if (rawData.isExtracted) {
            //when ticket has been extracted...
            if (rawData.ticketStatus.taskList && rawData.ticketStatus.taskList.length > 0) {
                for (let i = 0; i < rawData.ticketStatus.taskList.filter(t => t && t.virtualDevice && t.virtualDevice.virtualDeviceID).length; ++i) {
                    const t = rawData.ticketStatus.taskList[i];

                    if (taskMap[t.virtualDevice.virtualDeviceID] && !taskMap[t.virtualDevice.virtualDeviceID].taskID) {
                        taskMap[t.virtualDevice.virtualDeviceID].taskID = t.taskID;
                        taskMap[t.virtualDevice.virtualDeviceID].status = t.status;

                        if (t.status) {
                            switch (t.status.currentStatus) {
                                case TaskStatus.finish:
                                    {
                                        t.status.success ? info.statusCount.success++ : (t.status.isCancelled ? info.statusCount.cancelled++ : info.statusCount.failed++);
                                    }
                                    break;
                                case TaskStatus.progress:
                                    {
                                        info.statusCount.progress++;
                                    }
                                    break;
                                case TaskStatus.pending:
                                    {
                                        info.statusCount.pending++;
                                    }
                                    break;
                            }
                        }
                    }
                }
            }
        }
        else {
            //scheduled ticket, ticket is not extracted...
            if (rawData.ticketInput.resourceData.groupedTaskList) {
                info.statusCount.pending = rawData.ticketInput.resourceData.groupedTaskList.length;
            }
            else if (rawData.ticketInput.resourceData.virtualDeviceList) {
                info.statusCount.pending = rawData.ticketInput.resourceData.virtualDeviceList.length;
            }
        }

        Object.keys(taskMap).forEach((virtualDeviceID: string) => {
            info.taskList.push(taskMap[virtualDeviceID]);
        });

        //we should have one taskActionID on each batch-task from UI manipulation.
        //how to handle seperate tasks??
        let funcName: string;
        let targetTaskActionID: string = bCommonTaskDataValid ? rawData.ticketInput.resourceData.taskList[0].taskAction : rawData.ticketInput.resourceData.groupedTaskList[0].taskAction;
        switch (targetTaskActionID) {
            case this.constantSvc.TASKTYPE_REBOOT:
                {
                    funcName = this.devFuncSvc.FUNCNAME_REBOOT;
                }
                break;
            case this.constantSvc.TASKTYPE_INSTALL_FIRMWARE:
                {
                    funcName = this.devFuncSvc.FUNCNAME_FIRMWARE;
                }
                break;
            case this.constantSvc.TASKTYPE_INSTALL_APK:
                {
                    funcName = this.devFuncSvc.FUNCNAME_APK;
                }
                break;
            case this.constantSvc.TASKTYPE_CONFIG_BASIC:
                {
                    funcName = this.devFuncSvc.FUNCNAME_MULTI_BASIC_CONFIG;
                }
                break;
            case this.constantSvc.TASKTYPE_CONFIG_NET:
                {
                    funcName = this.devFuncSvc.FUNCNAME_NET_CONFIG;
                }
                break;
            case this.constantSvc.TASKTYPE_SECURITY_LOCALPWD:
                {
                    funcName = this.devFuncSvc.FUNCNAME_SECURITY;
                }
                break;
        }
        info.funcName = funcName;

        return info;
    }

    private need_refresh(activityID: string, force: boolean = false): boolean {
        return force ||
            !this._activityInfoMap[activityID] ||
            !this._activityInfoMap[activityID].info.startDateTime ||
            new Date().getTime() - this._activityInfoMap[activityID].lastRefreshTime > this.ACTIVITY_REFRESH_THRESHOLD ? true : false;
    }
}