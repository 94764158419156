import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export class APISetPluginInstanceEventManager extends APIBaseManager<{ pluginInstanceID: string }, void, { eventListeners: string[] }, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_SetPluginInstanceEvent';
        this._apiMethod = 'PATCH';
        this._allowPayloadPrint = false;
    }

    protected getRequestURL(pathParameters: { pluginInstanceID: string }, queryParameters: void): string {
        return super.getRequestURL() + 'pluginInstances/' + pathParameters.pluginInstanceID + '/eventListeners';
    }
}