import { Component } from '@angular/core';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { ConfigDialogPage, DLG_NAME_SCRIPT } from '../base/base-config-data';
import { LicenseScopeType } from '../../../../app/content/license/license.data';
import { HelperLib } from '../../../lib/common/helper.lib';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css']
})
export class ScriptFormComponent extends BaseConfigFormComponent {
    ngOnInit(): void {
        this._dialogName = DLG_NAME_SCRIPT;
        this._licenseScopeType = LicenseScopeType.taskConfig;

        super.ngOnInit();
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        switch (this._page) {
            case ConfigDialogPage.action:
                {
                    this._page = ConfigDialogPage.confirm;
                    this.submit();
                }
                break;
        }

        super.goNext();
    }

    protected submit(): void {
        super.submit();

        this.devSvc.batchDoCustomAction(this._bSpecific ? [this._actionData.deviceList[0]] : this._actionData.deviceList.filter(d => d.isSelect), {
            actionID: 'System.RunScript',
            subject: 'Custom script',
            resourceData: { cmd: this._actionData.instructionContent }
        }).subscribe(res => {
            this._page++;
            if (res.isFault()) {
                this._errorMessage = HelperLib.getErrorMessage(res);
            }

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler(res);
            }
        });
    }
}