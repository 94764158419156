import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DevFuncConfirmDirective } from './dev-func-confirm.directive';
import { DevFuncConfirmService } from './dev-func-confirm.service';

import { TranslateModule } from '../../../../translate/translate.module';
import { CustomPipeModule } from '../../../../lib/pipes/custom-pipe.module';

import { UIElementModule } from '../../../../uiElement/uiElement.module';
import { IssueConfirmSubFormComponent } from '../../troubleshoot/confirm/issue-confirm-subform.component';
import { RebootConfirmSubFormComponent } from '../../reboot/confirm/reboot-confirm-subform.component';
import { FirmwareConfirmSubFormComponent } from '../../firmware/confirm/firmware-confirm-subform.component';
import { BasicConfigConfirmSubFormComponent } from '../../config/confirm/basic-config-confirm-subform.component';
import { NetConfigConfirmSubFormComponent } from '../../config/confirm/net-config-confirm-subform.component';
import { AlertConfirmSubFormComponent } from '../../alert/confirm/alert-confirm-subform.component';
import { APKConfirmSubFormComponent } from '../../apk/confirm/apk-confirm-subform.component';
import { AlertElementModule } from '../../../setting/alert/alertConfig/comp/alert-element.module';
import { MultiBasicConfigConfirmSubFormComponent } from '../../config-m/confirm/m-basic-config-confirm-subform.component';
import { SecurityConfirmSubFormComponent } from '../../security/confirm/security-confirm-subform.component';
import { ClearCacheConfirmSubFormComponent } from '../../clear/confirm/clearCache-confirm-subform.component';
import { ClearAppDataConfirmSubFormComponent } from '../../clear/confirm/clearAppData-confirm-subform.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        TranslateModule,
        CustomPipeModule,
        UIElementModule,
        AlertElementModule
    ],
    providers: [
        DevFuncConfirmService
    ],
    declarations: [
        DevFuncConfirmDirective,
        IssueConfirmSubFormComponent,
        RebootConfirmSubFormComponent,
        FirmwareConfirmSubFormComponent,
        BasicConfigConfirmSubFormComponent,
        MultiBasicConfigConfirmSubFormComponent,
        NetConfigConfirmSubFormComponent,
        AlertConfirmSubFormComponent,
        APKConfirmSubFormComponent,
        SecurityConfirmSubFormComponent,
        ClearCacheConfirmSubFormComponent,
        ClearAppDataConfirmSubFormComponent
    ],
    exports: [
        DevFuncConfirmDirective
    ]
})
export class DevFuncConfirmModule {
}