import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';

export interface IRemovePolicyPathParameter {
    devicePolicyID: string;
}

export interface IRemovePolicyQueryParameter {
    sourceType?: string; // PluginInstance
    source?: string;
}

export class APIRemovePolicyManager extends APIBaseManager<IRemovePolicyPathParameter, IRemovePolicyQueryParameter, void, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_RemovePolicy';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IRemovePolicyPathParameter, queryParameters: IRemovePolicyQueryParameter): string {
        return super.getRequestURL() + 'devicePolicies/' + pathParameters.devicePolicyID + this.transformObjectQueryParameterToStr(queryParameters);
    }
}