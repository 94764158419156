import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IVirtualDeviceRxData } from './virtualDevice.common';

export interface IPairVirtualDevicePathParameter {
    virtualDeviceID: string;
}

export interface IPairVirtualDeviceTxData {
    pairingCode: string
}

export class APIPairVirtualDeviceManager extends APIBaseManager<IPairVirtualDevicePathParameter, void, IPairVirtualDeviceTxData, IVirtualDeviceRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_PairVirtualDevice';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: IPairVirtualDevicePathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/pair';
    }
}