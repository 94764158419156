<label class="title">
    QR code overlay
    <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
        [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
</label>
<div *ngIf="_unsupportReason" class="warning-block mb-1">{{ _unsupportReason }}</div>
<div class="overlay-block">
    <div>
        <div *ngIf="!_keepSupport; else templateTriToggle" class='switch-container'>
            <input id="cmn-toggle-qrcode" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
                [disabled]="_lockInfo || _disabled || _unsupportReason" [checked]="_info?.enabled"
                (change)="enableQRCode($event.target.checked)">
            <label for="cmn-toggle-qrcode">
                <span class="sr-only">Enable qrcode overlay</span>
            </label>
        </div>
        <ng-template #templateTriToggle>
            <na-tri-toggle [disabled]="_lockInfo || _disabled || _unsupportReason" [state]="_enableState"
                (onToggleChanged)="onQRCodeTriToggleChanged($event)"></na-tri-toggle>
        </ng-template>
    </div>
    <ul>
        <li>
            <div class="form-group mt-2">
                <label for="config-overlay-data">Data</label>
                <input #overlayUrl id="config-overlay-data" type="text" class="form-control form-control-sm"
                    [disabled]="_lockInfo || _disabled || _unsupportReason || !_info?.enabled || _info?.keep"
                    [value]="_info?.data">
            </div>
        </li>
        <li>
            <div class="form-group">
                <div class="d-flex align-items-center">
                    <label for="config-overlay-landscape" class="mr-2">Landscape (px)</label>
                    <input id="config-overlay-enableLandscape" type="checkbox" class="cmn-checkbox"
                        [disabled]="overlayUrl.disabled || !_info?.data" [checked]="_info?.landscape.enabled"
                        (change)="enableLandscape($event.target.checked)" />
                    <label for="config-overlay-enableLandscape">
                        <svg>
                            <use xlink:href="#checkmark" />
                        </svg>
                        <span class="sr-only">Enable overlay landscape advance setting</span>
                    </label>
                </div>
                <div id="config-overlay-landscape" class="qrcode-block">
                    <div>
                        <span>QR code size (pixel)</span>
                        <div class="mt-1 ml-2">
                            <div class="form-group row">
                                <label for="landscape-w" class="col col-form-label col-form-label-sm">Width</label>
                                <div class="col">
                                    <input #overlayLandscapeWidth id="landscape-w" type="text"
                                        class="form-control form-control-sm" aria-label="Landscape width"
                                        [disabled]="overlayUrl.disabled || !_info?.landscape.enabled"
                                        [value]="_info?.landscape.width">
                                </div>
                            </div>
                            <div class="form-group row mt-1">
                                <label for="landscape-h" class="col col-form-label col-form-label-sm">Height</label>
                                <div class="col">
                                    <input #overlayLandscapeHeight id="landscape-h" type="text"
                                        class="form-control form-control-sm" aria-label="Landscape height"
                                        [disabled]="overlayUrl.disabled || !_info?.landscape.enabled"
                                        [value]="_info?.landscape.height">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <span>QR code position (pixel)</span>
                        <div class="mt-1 ml-2">
                            <div class="form-group row">
                                <label for="landscape-left" class="col col-form-label col-form-label-sm">Left</label>
                                <div class="col">
                                    <input #overlayLandscapeLeft id="landscape-left" type="text"
                                        class="form-control form-control-sm" aria-label="Landscape left position"
                                        [disabled]="overlayUrl.disabled || !_info?.landscape.enabled"
                                        [value]="_info?.landscape.left">
                                </div>
                            </div>
                            <div class="form-group row mt-1">
                                <label for="landscape-top" class="col col-form-label col-form-label-sm">Top</label>
                                <div class="col">
                                    <input #overlayLandscapeTop id="landscape-top" type="text"
                                        class="form-control form-control-sm" aria-label="Landscape top position"
                                        [disabled]="overlayUrl.disabled || !_info?.landscape.enabled"
                                        [value]="_info?.landscape.top">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li>
            <div class="form-group">
                <div class="d-flex align-items-center">
                    <label for="config-overlay-portrait" class="mr-2">Portrait (px)</label>
                    <input id="config-overlay-enablePortrait" type="checkbox" class="cmn-checkbox"
                        [disabled]="overlayUrl.disabled || !_info?.data" [checked]="_info?.portrait.enabled"
                        (change)="enablePortrait($event.target.checked)" />
                    <label for="config-overlay-enablePortrait">
                        <svg>
                            <use xlink:href="#checkmark" />
                        </svg>
                        <span class="sr-only">Enable overlay portrait advance setting</span>
                    </label>
                </div>
                <div id="config-overlay-portrait" class="qrcode-block">
                    <div>
                        <span>QR code size (pixel)</span>
                        <div class="mt-1 ml-2">
                            <div class="form-group row">
                                <label for="portrait-w" class="col col-form-label col-form-label-sm">Width</label>
                                <div class="col">
                                    <input #overlayPortraitWidth id="portrait-w" type="text"
                                        class="form-control form-control-sm" aria-label="Landscape width"
                                        [disabled]="overlayUrl.disabled || !_info?.portrait.enabled"
                                        [value]="_info?.portrait.width">
                                </div>
                            </div>
                            <div class="form-group row mt-1">
                                <label for="portrait-h" class="col col-form-label col-form-label-sm">Height</label>
                                <div class="col">
                                    <input #overlayPortraitHeight id="portrait-h" type="text"
                                        class="form-control form-control-sm" aria-label="Landscape height"
                                        [disabled]="overlayUrl.disabled || !_info?.portrait.enabled"
                                        [value]="_info?.portrait.height">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <span>QR code position (pixel)</span>
                        <div class="mt-1 ml-2">
                            <div class="form-group row">
                                <label for="portrait-left" class="col col-form-label col-form-label-sm">Left</label>
                                <div class="col">
                                    <input #overlayPortraitLeft id="portrait-left" type="text"
                                        class="form-control form-control-sm" aria-label="Portrait left position"
                                        [disabled]="overlayUrl.disabled || !_info?.portrait.enabled"
                                        [value]="_info?.portrait.left">
                                </div>
                            </div>
                            <div class="form-group row mt-1">
                                <label for="portrait-top" class="col col-form-label col-form-label-sm">Top</label>
                                <div class="col">
                                    <input #overlayPortraitTop id="portrait-top" type="text"
                                        class="form-control form-control-sm" aria-label="Portrait top position"
                                        [disabled]="overlayUrl.disabled || !_info?.portrait.enabled"
                                        [value]="_info?.portrait.top">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>