<div class="row">
  <div class="col-12">
    <div class="title">Please confirm the update method you choose.</div>
    <div *ngIf="!_needUpdate" class="warning-block my-2">
      No need update
    </div>
    <div class="mt-2">
      <label for="fwUpdateMethod" class="txt-bold">Update method : </label>
      <span id="fwUpdateMethod" class="text-info ml-2">{{ _enumFwUpdateMethod[_fwUpdateMethod] }}</span>
    </div>
    <div *ngIf="_fwSchedule && _fwSchedule.enabled" class="mt-1">
      <label for="fwUpdateSchedule" class="txt-bold">Schedule : </label>
      <span id="fwUpdateSchedule" class="text-info ml-2">{{ _fwSchedule.date }} {{ _fwSchedule.time }} GMT {{ _fwSchedule.offset }}:00</span>
    </div>
    <div>
      <div class="list-group">
        <div class="list-group-item list-group-header black">
          <div class="row align-items-center">
            <div class="col title">
              Name
            </div>
            <div class="col title">
              Update status
            </div>
          </div>
        </div>
        <div class="list-group-content list-group-hoverless">
          <div *ngFor="let dev of _devices" class="list-group-item">
            <div class="row align-items-center">
              <div class="col text-break">
                {{ dev.virtualName }}
              </div>
              <div class="col text-break">
                {{ _fwStatusMap[dev.virtualId].status }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>