import { Component, Input, ViewChild, ElementRef } from '@angular/core';

import { AlertService } from '../../alert.service';
import { AlertInfo, AlertType, AlertEventTypeMap, OfflineAlertConfigData, AccountAccessAlertConfigData, DeviceCommandAlertConfigData, DeviceStatusAlertConfigData } from '../alert.data';
import { AlertDataCtrlDirective } from '../alertData/alert-data-ctrl.directive';
import { AlertDataCtrlService, AlertDataCtrlItem } from '../alertData/alert-data-ctrl.service';
import { IAlertDataCtrl } from '../alertData/alert-data-ctrl.interface';
import { DialogPage } from '../../../../../lib/common/common.data';
import { IAlertFuncCtrl } from './alert-func.def';
import { IAlertSetting } from '../../../../../API/v1/Alert/api.alert.common';
import { IAPIRx } from '../../../../../API/api.base';
import { AccountService } from '../../../../../entry/account.service';
import { VirtualDlgComponent } from 'app/content/virtual/dlg/virtual-dlg.component';

@Component({
    templateUrl: './alertConfig-dlg-edit.component.html',
    styleUrls: ['../alert.style.css', './alertConfig-dlg-edit.component.css']
})
export class AlertConfigDlgEditComponent extends VirtualDlgComponent implements IAlertFuncCtrl {
    title: string;
    _alertName: string;
    _receivers: string;
    _alertEventTypeList: { name: string, type: AlertType, displayName: string }[];
    _enumAlertType: typeof AlertType = AlertType;

    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;

    _errorMessage: string;
    _isRuleAvailable: boolean = false;

    private _alertDataCtrlHost: AlertDataCtrlDirective;
    @ViewChild(AlertDataCtrlDirective)
    set alertDataCtrlHost(host: any) {
        if (host) {
            this._alertDataCtrlHost = host;
        }
    }

    _alert: AlertInfo;
    @Input()
    set alert(a: AlertInfo) {
        if (!a) {
            return;
        }

        this._alert = a.id ? a.copy() : a;
    }

    constructor(
        protected accountSvc: AccountService,
        private alertSvc: AlertService,
        private alertRuleSpecialCtrlSvc: AlertDataCtrlService) {
            super(accountSvc);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this._alertEventTypeList = Object.keys(AlertEventTypeMap).filter((eventName) => AlertEventTypeMap[eventName].type !== AlertType.Unknown).map((eventName: string) => { return { name: eventName, displayName: AlertEventTypeMap[eventName].displayName, type: AlertEventTypeMap[eventName].type } });
        this._errorMessage = null;

        this._alertName = this._alert.name;
        this._receivers = this._alert.alertData && this._alert.alertData.alertReceiverList ? this._alert.alertData.alertReceiverList.join('\n') : '';
        if (this._alert && this._alert.alertData) {
            setTimeout(() => {
                this.showAlertData(this._alert);
            }, 0);
        }
    }

    changeAlertEventType(alertEventType: { type: AlertType, name: string, displayName: string }): void {
        if (!this._alert || !this._alert.eventType) {
            return;
        }

        if (this._alert.eventType.type === alertEventType.type) {
            return;
        }

        this._alert.eventType = alertEventType;
        this._alert.alertEvent = alertEventType.name;
        switch (alertEventType.type) {
            case AlertType.Offline:
                {
                    this._alert.alertData = new OfflineAlertConfigData();
                }
                break;
            case AlertType.AccountAccess:
                {
                    this._alert.alertData = new AccountAccessAlertConfigData();
                }
                break;
            case AlertType.DeviceCmd:
                {
                    this._alert.alertData = new DeviceCommandAlertConfigData();
                }
                break;
            case AlertType.DeviceStatus:
                {
                    this._alert.alertData = new DeviceStatusAlertConfigData();
                }
                break;
        }

        this.showAlertData(this._alert);
    }

    submit(): void {
        this._alert.name = this._alertName;

        //check if the rule is available first
        const validTestResult: { isFault: boolean, errorMessage?: string } = this.alertSvc.isAlertValid(this._alert);

        if (!validTestResult.isFault) {
            this._errorMessage = null;
            this._page = DialogPage.submit;

            this.alertSvc.addAlert(this._alert).subscribe((result: IAPIRx<IAlertSetting>) => {
                this._page = DialogPage.result;
                if (result && result.error === 0) {
                }
                else {
                    this._errorMessage = result ? result.errorMessage : 'Unknown error';
                }
            });
            
        }
        else {
            this._errorMessage = validTestResult.errorMessage;
        }
    }

    cancel(): void {
        this._alert = new AlertInfo();
        this._alertName = null;
        this._receivers = null;
    }

    private showAlertData(alert: AlertInfo): void {
        const item: AlertDataCtrlItem = this.alertRuleSpecialCtrlSvc.getItem(alert.eventType.type);
        if (item) {
            const viewContainerRef = this._alertDataCtrlHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IAlertDataCtrl>componentRef.instance).alertData = this._alert.alertData;
            (<IAlertDataCtrl>componentRef.instance).isViewMode = false;
        }
    }
}