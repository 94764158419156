import { Component } from '@angular/core';
import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { DeviceGroupFuncInterface } from './group-func.def';
import { DeviceGroupInfo, DeviceGroupType } from '../group.data';
import { DeviceGroupService } from '../dev-group.service';
import { AccountService } from '../../../../entry/account.service';
import { PolicyService } from '../../../setting/policy/policy.service';
import { UserService } from '../../../admin/user/user.service';
import { VirtualDlgComponent } from 'app/content/virtual/dlg/virtual-dlg.component';


@Component({
    templateUrl: './group-dlg-delete.component.html'
})
export class DeviceGroupDeleteDlgComponent extends VirtualDlgComponent implements DeviceGroupFuncInterface {
    title: string;
    group: DeviceGroupInfo;
    _updating: boolean;

    _deleteList: DeviceGroupInfo[] = [];
    _errorMessage: string;
    _enumGroupType: typeof DeviceGroupType = DeviceGroupType;

    constructor(protected accountSvc: AccountService, private groupSvc: DeviceGroupService, private policySvc: PolicyService, private userSvc: UserService) {
        super(accountSvc)
     }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.group) {
            const selectedChildList: DeviceGroupInfo[] = this.group.childs.filter(c => c.selected || (c.data ? c.data.isSelect : false));
            this._deleteList = selectedChildList.length === 0 ? [this.group] : selectedChildList;
        }
    }

    deleteGroup(): void {
        this._errorMessage = '';
        this._updating = true;

        this.groupSvc.deleteGroup(null, this.group, this._deleteList).pipe(
            concatMap((res: { isFault: boolean, errorMessage?: string }) => {
                if (!res.isFault) {
                    return this.policySvc.getPolicyList(true);
                }
                
                return of(res);
            })
        ).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._updating = false;

            if (res.isFault) {
                this._errorMessage = res.errorMessage;
                return;
            }

            this.userSvc.updateUserGroupCache();

            setTimeout(() => this.closeDlg(), 0);

        });
    }
}