import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IVirtualDeviceRxData } from './virtualDevice.common';

export interface IUpdateVirtualDevicePathParameter {
    virtualDeviceID: string;
}

export interface IUpdateVirtualDeviceTxData {
    virtualDeviceName: string;
}

export class APIUpdateVirtualDeviceManager extends APIBaseManager<IUpdateVirtualDevicePathParameter, void, IUpdateVirtualDeviceTxData, IVirtualDeviceRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UpdateVirtualDevice';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: IUpdateVirtualDevicePathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID;
    }
}