import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IResetPasswordVerifyTxData {
    accountEmail: string;
    activationCode: string;
}

export class APIResetAccountPasswordVerifyManager extends APIBaseManager<void, void, IResetPasswordVerifyTxData, void> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ResetAccountPasswordVerify';
        this._apiMethod = 'PATCH';
        this._allowPayloadPrint = false;
    }

    protected getRequestURL(): string {
        return super.getRequestURL() + 'accounts/password/reset';
    }
}