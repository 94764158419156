<div *ngIf="!_loading; else templateLoadingUser" class="container-fluid mt-2">
    <div *ngIf="_errorMessage; else templateNoUserError" class="row">
        <div>
            <p>Some errors occurred. Please refresh the website or login again.</p>
            <strong>Error message : </strong>
            <div class="warning-block">
                {{ _errorMessage }}
            </div>
        </div>
    </div>
    <ng-template #templateNoUserError>
        <div class="row">
            <h3 class="col-12 px-0">
                <i class="fas fa-arrow-alt-circle-left green-2 icon-arrow" [routerLink]="['/app/admin/users/all']"></i>
                User <{{ _user?.firstName }} {{ _user?.lastName }}>
            </h3>
            <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 user-block">
                <div class="ctrl-bar" [class.edit]="_isInEdit">
                    <button type="button" class="btn btn-sm btn-action btn-nb btn-edit" [class.active]="_isInEdit"
                        (click)="beginEditUser()">
                        <i class="fas fa-pen"></i>
                        <span class="ml-2 d-none d-sm-inline">Edit</span>
                    </button>
                    <button *ngIf="!isEnterpriseSSO" type="button" class="btn btn-sm btn-action btn-nb" [disabled]="_isInEdit"
                        data-toggle="modal" data-target="#userDlgFuncModalHost" (click)="resetUserPassword()">
                        <i class="fas fa-key"></i>
                        <span class="ml-2 d-none d-sm-inline">Reset password</span>
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-nb" [disabled]="_isInEdit"
                        data-toggle="modal" data-target="#userDlgFuncModalHost" (click)="deleteUser()">
                        <i class="fas fa-user-minus"></i>
                        <span class="ml-2 d-none d-sm-inline">Delete</span>
                    </button>
                </div>
                <div class="mt-2">
                    <div class="mb-2">
                        <label for="id-user-identity" class="font-weight-bold">Identity</label>
                        <div id="id-user-identity">
                            <div class="form-group row">
                                <label for="col-user-firstName"
                                    class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                                    First name
                                </label>
                                <div class="col-6 col-sm-7 col-md-8">
                                    <div *ngIf="!_isInEdit; else templateEditFirstName" id="col-user-firstName"
                                        class="form-control-plaintext-sm">
                                        {{ _user?.firstName }}
                                    </div>
                                    <ng-template #templateEditFirstName>
                                        <input id="col-user-firstName" type="text" class="form-control form-control-sm"
                                            [(ngModel)]="_editData.firstName">
                                    </ng-template>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="col-user-lastName"
                                    class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                                    Last name
                                </label>
                                <div class="col-6 col-sm-7 col-md-8">
                                    <div *ngIf="!_isInEdit; else templateEditLastName" id="col-user-lastName"
                                        class="form-control-plaintext-sm">
                                        {{ _user?.lastName }}
                                    </div>
                                    <ng-template #templateEditLastName>
                                        <input id="col-user-lastName" type="text" class="form-control form-control-sm"
                                            [(ngModel)]="_editData.lastName">
                                    </ng-template>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="col-user-email"
                                    class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                                    Email
                                </label>
                                <div class="col-6 col-sm-7 col-md-8">
                                    <div id="col-user-email" class="form-control-plaintext-sm text-break">
                                        {{ _user?.email }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <label for="id-user-job" class="font-weight-bold">Job info</label>
                        <div id="id-user-job">
                            <div class="form-group row">
                                <label for="col-user-company"
                                    class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                                    Company
                                </label>
                                <div class="col-6 col-sm-7 col-md-8">
                                    <div *ngIf="!_isInEdit; else templateEditCompany" id="col-user-company"
                                        class="form-control-plaintext-sm">
                                        {{ _user?.company }}
                                    </div>
                                    <ng-template #templateEditCompany>
                                        <input id="col-user-company" type="text" class="form-control form-control-sm"
                                            [(ngModel)]="_editData.company">
                                    </ng-template>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="col-user-depart"
                                    class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                                    Department
                                </label>
                                <div class="col-6 col-sm-7 col-md-8">
                                    <div *ngIf="!_isInEdit; else templateEditDepart" id="col-user-depart"
                                        class="form-control-plaintext-sm">
                                        {{ _user?.department }}
                                    </div>
                                    <ng-template #templateEditDepart>
                                        <input id="col-user-depart" type="text" class="form-control form-control-sm"
                                            [(ngModel)]="_editData.department">
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="col-user-policy"
                            class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm font-weight-bold">
                            User role
                        </label>
                        <div class="col-6 col-sm-7 col-md-8">
                            <div *ngIf="!_isInEdit; else templateEditUserPolicy" id="col-user-policy"
                                class="form-control-plaintext-sm">
                                {{ _user?.userRole }}
                            </div>
                            <ng-template #templateEditUserPolicy>
                                <div id="col-user-policy" class="position-relative">
                                    <button id="dropdownUserRoleLink" type="button"
                                        class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
                                        {{ _editData.userRole }}
                                    </button>
                                    <ul class="dropdown-menu option-list w-100" data-backdrop="static"
                                        data-keyboard="false" aria-labelledby="dropdownUserRoleLink">
                                        <ng-template ngFor let-role [ngForOf]="_availableUserRoleList">
                                            <li class="dropdown-item"
                                                [class.active]="_editData.userRole === role.name"
                                                (click)="changeUserRole(role)">
                                                <span class="ml-2">
                                                    {{ role.name | capitalFirstWord }}
                                                </span>
                                            </li>
                                        </ng-template>
                                    </ul>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="col-user-group"
                            class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm font-weight-bold">
                            User group
                        </label>
                        <div class="col-6 col-sm-7 col-md-8">
                            <div *ngIf="!_isInEdit; else templateEditUserGroup" id="col-user-group"
                                class="form-control-plaintext-sm">
                                {{ _userGroupName }}
                            </div>
                            <ng-template #templateEditUserGroup>
                                <div id="col-user-group" class="position-relative">
                                    <button id="dropdownUserGroupLink" type="button"
                                        class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
                                        {{ _editData.userGroupName }}
                                    </button>
                                    <ul class="dropdown-menu option-list w-100" data-backdrop="static"
                                        data-keyboard="false" aria-labelledby="dropdownUserGroupLink">
                                        <ng-template ngFor let-group [ngForOf]="_availableUserGroupList">
                                            <li class="dropdown-item"
                                                [class.active]="_editData.userGroupID === group.id"
                                                (click)="changeUserGroup(group)">
                                                <span class="ml-2">
                                                    {{ group.name | capitalFirstWord }}
                                                </span>
                                            </li>
                                        </ng-template>
                                    </ul>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="mb-2">
                        <label for="id-user-others" class="font-weight-bold">Others</label>
                        <div id="id-user-others">
                            <div class="form-group row">
                                <label for="col-user-lastLoginTime"
                                    class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                                    Last login
                                </label>
                                <div class="col-6 col-sm-7 col-md-8">
                                    <div id="col-user-lastLoginTime" class="form-control-plaintext-sm">
                                        {{ _user?.lastLoginDate | date:'yyyy-MM-dd HH:mm:ss' }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="col-user-creationTime"
                                    class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                                    Creation time
                                </label>
                                <div class="col-6 col-sm-7 col-md-8">
                                    <div id="col-user-creationTime" class="form-control-plaintext-sm">
                                        {{ _user?.createdDate | date:'yyyy-MM-dd HH:mm:ss' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_isInEdit" class="mt-2 d-flex">
                        <button type="button" class="btn btn-sm btn-action btn-light ml-auto" (click)="cancelEditUser()">
                            <span class="fas fa-times-circle"></span>
                            Cancel
                        </button>
                        <button type="button" class="btn btn-sm btn-action btn-light ml-2" data-toggle="modal"
                            data-target="#userDlgFuncModalHost" (click)="confirmEdit()">
                            Next
                            <span class="fas fa-angle-double-right icon-right"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
<ng-template #templateLoadingUser>
    <div class="loading">
        <p>Please wait...</p>
        <div class="loading-box"></div>
    </div>
</ng-template>
<div id="userDlgFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template user-dlg-func></ng-template>
</div>