import { Component, Input } from "@angular/core";
import { ScreenSaverInfo } from "./screen.data";

@Component({
    selector: 'na-screenSaver-preview',
    templateUrl: './screenSaver-preview.component.html'
})
export class ScreenSaverPreviewComponent {
    _info: ScreenSaverInfo;
    @Input('data')
    set data(s: ScreenSaverInfo) {
        if (s) {
            this._info = s;
        }
    }

    _showSwitch: boolean = true;
    @Input('showSwitch')
    set showSwitch(s: boolean) {
        this._showSwitch = s;
    }
}