import { Component, Input } from '@angular/core';

@Component({
    selector: 'na-debug-preview',
    templateUrl: './debug-preview.component.html'
})
export class DebugPreviewComponent {
    _debug_usb_enabled: boolean = false;
    @Input('debugUSB')
    set usb(v: boolean) {
        this._debug_usb_enabled = v;
    }

    _debug_tcp_enabled: boolean = false;
    @Input('debugTCP')
    set tcp(v: boolean) {
        this._debug_tcp_enabled = v;
    }
}