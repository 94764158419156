import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { ActivityInfo, ActivityStatus } from './activity.data';
import { ActivityService } from './activity.service';
import { HelperLib, REFRESH_DURATION } from '../../../lib/common/helper.lib';
import { SortType } from '../../../lib/common/common.data';
import { TaskStatus } from '../../device/data/device-info';
import { ConstantService } from '../../../../app/lib/common/constant.service';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    templateUrl: './activity-overview.component.html',
    styleUrls: ['./activity-overview.component.css']
})
export class ActivityOverviewComponent extends AutoUnsubscribeComponent implements OnInit {
    readonly FILTER_TASK_CATEGORY_LIST: { name: string, actionID: string }[] = [];
    readonly FILTER_TASK_STATUS_LIST: ActivityStatus[] = [ActivityStatus.all, ActivityStatus.finished, ActivityStatus.fail, ActivityStatus.pending, ActivityStatus.progress];

    _tableHeaderList: { key: string, displayName: string }[] = [];
    _isLoading: boolean = false;

    _activityList: ActivityInfo[] = [];

    _currentSortKey: string;
    _currentSortType: SortType = SortType.descend;
    _enumSortType: typeof SortType = SortType;

    _currentPage: number = 1;
    _numberInPage: number = 50;
    _total: number = 0;

    _enumActivityStatus: typeof ActivityStatus = ActivityStatus;
    _enumTaskStatus: typeof TaskStatus = TaskStatus;
    _filterTaskType: { name: string, actionID: string };
    _filterTaskStatus: ActivityStatus = ActivityStatus.all;
    _filterSubject: string = '';
    _filterDate: { from: string, to: string } = { from: '', to: '' };
    _filterTempDate: { from: string, to: string } = { from: '', to: '' };
    _isFilterChanged: boolean;

    _refreshCounter: number = 0;

    private _searchRef: ElementRef;
    @ViewChild('search', { static: true })
    set search(v: ElementRef) {
        if (v) {
            this._searchRef = v;

            fromEvent(this._searchRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._filterSubject = e.target.value;
            });
        }
    }

    constructor(
        private constantSvc: ConstantService,
        private activitySvc: ActivityService
    ) {
        super();
        
        this._tableHeaderList = [
            { key: 'issueDateTime', displayName: 'Issue date' },
            { key: 'subject', displayName: 'Activity' },
            { key: 'statusCode', displayName: 'Status' },
            { key: 'issuer', displayName: 'Issuer' }
        ];
        this._currentSortKey = this._tableHeaderList[0].key;

        this.FILTER_TASK_CATEGORY_LIST = [
            {
                name: 'All',
                actionID: 'all'
            },
            {
                name: 'Reboot',
                actionID: this.constantSvc.TASKTYPE_REBOOT
            },
            {
                name: 'Clear cache',
                actionID: this.constantSvc.TASKTYPE_CLEARCACHE
            },
            {
                name: 'Update firmware',
                actionID: this.constantSvc.TASKTYPE_INSTALL_FIRMWARE
            },
            {
                name: 'Update IAdeaCare APK',
                actionID: this.constantSvc.TASKTYPE_INSTALL_APK
            },
            {
                name: 'Basic configuration',
                actionID: this.constantSvc.TASKTYPE_CONFIG_BASIC
            },
            {
                name: 'Network configuration',
                actionID: this.constantSvc.TASKTYPE_CONFIG_NET
            },
            {
                name: 'Update local password',
                actionID: this.constantSvc.TASKTYPE_SECURITY_LOCALPWD
            }
        ];

        this._filterTaskType = this.FILTER_TASK_CATEGORY_LIST[0];
    }

    ngOnInit(): void {
        this.getActivityList();
    }

    changeTaskType(type: { name: string, actionID: string }): void {
        if (this._filterTaskType.actionID !== type.actionID) {
            this._filterTaskType = type;
            this._isFilterChanged = true;
        }
    }

    changeTaskStatus(status: ActivityStatus): void {
        if (this._filterTaskStatus !== status) {
            this._filterTaskStatus = status;
            this._isFilterChanged = true;
        }
    }

    startChangeTaskData(): void {
        this._filterTempDate.from = this._filterDate.from;
        this._filterTempDate.to = this._filterDate.to;
    }

    resetDateFilter(): void {
        this._filterDate.from = this._filterDate.to = '';
        this._filterTempDate.from = this._filterTempDate.to = '';
        this._isFilterChanged = true;
    }

    changeTaskDate(): void {
        if (this._filterTempDate.from !== this._filterDate.from || this._filterTempDate.to !== this._filterDate.to) {
            this._filterDate.from = this._filterTempDate.from;
            this._filterDate.to = this._filterTempDate.to;
            this._isFilterChanged = true;
        }
    }

    applyFilter(): void {
        this.getActivityList();
    }

    keyDown(keyCode: number): void {
        if (keyCode === this.constantSvc.KEYCODE_RETURN) {
            this.applySearch();
        }
    }

    applySearch(): void {
        this.getActivityList();
    }

    sortDescend(key: string): void {
        if (this._currentSortKey !== key || this._currentSortType !== SortType.descend) {
            this._currentSortType = SortType.descend;
            this._currentSortKey = key;
            this.sort();
        }
    }

    sortAscend(key: string): void {
        if (this._currentSortKey !== key || this._currentSortType !== SortType.ascend) {
            this._currentSortType = SortType.ascend;
            this._currentSortKey = key;
            this.sort();
        }
    }

    onPageChange(page: number): void {
        this._currentPage = page;
        this.getActivityList();
    }

    getActivityList(refresh: boolean = false): void {
        if (refresh) {
            this._refreshCounter = REFRESH_DURATION;
            HelperLib.countdown(this._refreshCounter, 0, (counter: number) => {
                this._refreshCounter = counter;
            });
        }

        this._isLoading = true;
        this.activitySvc.getActivityList(refresh, this._currentPage, { ticketSubject: this._filterSubject, taskAction: this._filterTaskType.actionID, ticketStatusCode: this._filterTaskStatus, fromDate: this._filterDate.from, toDate: this._filterDate.to }).subscribe(
            (res: { activityList: ActivityInfo[], total: number, skip: number, limit: number, errorMessage?: string }) => {
                this._isLoading = false;
                this._activityList = res.activityList;
                this._total = res.total;
                if (!res.errorMessage) {
                    this._isFilterChanged = false;
                }
                this.sort();

                return {
                    isFault: res.errorMessage ? true : false,
                    errorMessage: res.errorMessage
                };
            }
        );
    }

    sort(): void {
        this._activityList.sort((a: ActivityInfo, b: ActivityInfo) => {
            if (a[this._currentSortKey] === b[this._currentSortKey]) {
                return 0;
            }

            switch (this._currentSortType) {
                case SortType.ascend:
                    {
                        return a[this._currentSortKey] >= b[this._currentSortKey] ? 1 : -1;
                    }
                case SortType.descend:
                    {
                        return a[this._currentSortKey] >= b[this._currentSortKey] ? -1 : 1;
                    }
            }
        });
    }
}