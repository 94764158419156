import { Component } from "@angular/core";
import { DialogPage } from "app/lib/common/common.data";
import { CertInfo } from "../cert.data";
import { CertService } from "../cert.service";
import { ICertFuncCtrl } from "./cert-func.def";
import { PolicyService } from "app/content/setting/policy/policy.service";
import { VirtualDlgComponent } from "app/content/virtual/dlg/virtual-dlg.component";
import { AccountService } from "app/entry/account.service";

@Component({
    templateUrl: './cert-dlg-remove.component.html',
    styleUrls: ['../cert.style.css']
})
export class CertRemoveDlgComponent extends VirtualDlgComponent implements ICertFuncCtrl {
    title: string;
    funcName: string;
    certList: CertInfo[];
    other?: any;
    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;

    _enumPage: typeof DialogPage = DialogPage;
    _page: DialogPage = DialogPage.action;
    _errorMessage: string;

    constructor(protected accountSvc: AccountService, private certSvc: CertService, private policySvc: PolicyService) {
        super(accountSvc);
    }

    removeCert(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;

        this.certSvc.deleteCert(this.certList[0]).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._page = DialogPage.result;
            this._errorMessage = res.errorMessage;

            if (!res.isFault) {
                // reset last-update-time of policySvc to make policySvc to get the policies again when entering policy pages
                this.policySvc.resetLastUpdateTime();
                setTimeout(() => {
                    this._dlgCloseElementRef.nativeElement.click();
                }, 0);
            }
        });
    }
}