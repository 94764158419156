<ng-template [ngIf]="_licenseScopeMap[_enumLicenseScope.devicePolicy]">
    <span *ngIf="_loading; else templateShowPolicy" class="text-loading" [attr.data-text]="TEXT_LOADING">
        {{ TEXT_LOADING }}
    </span>
    <ng-template #templateShowPolicy>
        <div *ngFor="let pod of _policyMap | keyvalue" class="form-group row">
            <label [for]="'col-policy-configuration-' + pod.key"
                class="col-xl-4 col-5 col-form-label col-form-label-sm">
                {{ pod.key }}
            </label>
            <div class="col-xl-8 col-7">
                <div [id]="'col-policy-configuration-' + pod.key" class="form-control-plaintext">
                    <span *ngFor="let p of pod.value.list">
                        <a [routerLink]="'/app/device/policy/' + p.id">{{ p.name }}</a>
                    </span>
                    <span class="ml-1 policy-status" [ngClass]="'status-' + pod.value.status">
                        {{ pod.value.status }}
                    </span>
                    <span *ngIf="pod.value.isPartialSync" class="dropdown">
                        <i class="fas fa-info-circle red-1 ml-1" data-toggle="collapse" data-target="#dropdownPolicyPartialDesc" aria-expanded="false" aria-controls="dropdownPolicyPartialDesc"></i>
                        <ul class="collapse" id="dropdownPolicyPartialDesc">
                            <li *ngFor="let partialSyncPolicyDesc of pod.value.partialSyncDescList">{{ partialSyncPolicyDesc }}</li>
                        </ul>
                    </span>
                </div>
            </div>
        </div>
    </ng-template>
</ng-template>