import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LicenseBaseDlgComponent } from './license-base-dlg.component';
import { LicenseDlgAddComponent } from './license-dlg-add.component';
import { LicenseDlgImportComponent } from './license-dlg-import.component';
import { LicenseDlgReAllocateComponent } from './license-dlg-reallocate.component';

import { LicenseFuncDirective } from './license-func.directive';
import { LicenseFuncService } from './license-func.service';

import { TranslateModule } from '../../../translate/translate.module';
import { CustomPipeModule } from '../../../lib/pipes/custom-pipe.module';
import { UIElementModule } from '../../../uiElement/uiElement.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        CustomPipeModule,
        UIElementModule
    ],
    providers: [
        LicenseFuncService
    ],
    declarations: [
        LicenseBaseDlgComponent,
        LicenseDlgAddComponent,
        LicenseDlgImportComponent,
        LicenseDlgReAllocateComponent,
        LicenseFuncDirective
    ],
    exports: [
        LicenseDlgAddComponent,
        LicenseDlgImportComponent,
        LicenseDlgReAllocateComponent,
        LicenseFuncDirective
    ]
})
export class LicenseDlgModule { }