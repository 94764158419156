<div *ngIf="!_isLoading; else templateLoadingReportConfig" class="px-2">
    <div *ngIf="_loadErrorMessage; else templateGetReportConfigPass" class="warning-block mt-4">
        {{ _loadErrorMessage }}
    </div>
    <ng-template #templateGetReportConfigPass>
        <div class="d-flex align-items-center">
            <input id="switch-report" type="checkbox" class="cmn-checkbox" [checked]="_config.enabled.value"
                (change)="_config.enableWeeklyReport($event.target.checked)" />
            <label for="switch-report" class="mr-2">
                <svg>
                    <use xlink:href="#checkmark" />
                </svg>
            </label>
            Subscribe weekly report
        </div>
        <div class="content">
            <div class="form-group row">
                <strong class="col-lg-3 col-md-4 col-12">Good network evaluation :</strong>
                <div class="col-lg-9 col-md-8 col-12 d-flex align-items-center">
                    <div>
                        <input id="switch-reportNetEvaluation" type="checkbox" class="cmn-checkbox mr-2"
                            [disabled]="!_config.enabled.value" [checked]="_config.deviceStatus.enabled.value"
                            (change)="_config.enableNetworkEvaluation($event.target.checked)" />
                        <label for="switch-reportNetEvaluation">
                            <svg>
                                <use xlink:href="#checkmark" />
                            </svg>
                        </label>
                    </div>
                    <input type="number" max="100" min="5" step="5"
                        class="form-control form-control-sm input-ratio mx-2"
                        [disabled]="!_config.enabled.value || !_config.deviceStatus.enabled.value"
                        [class.error]="_config.deviceStatus.deviceAmount.error"
                        [ngModel]="_config.deviceStatus.deviceAmount.value"
                        (ngModelChange)="_config.changeDeviceStatusAmountRatio($event)">
                    <span>% devices uptime is over</span>
                    <input type="number" max="100" min="5" step="5"
                        class="form-control form-control-sm input-ratio mx-2"
                        [disabled]="!_config.enabled.value || !_config.deviceStatus.enabled.value"
                        [class.error]="_config.deviceStatus.uptimeThreshold.error"
                        [ngModel]="_config.deviceStatus.uptimeThreshold.value"
                        (ngModelChange)="_config.changeDeviceStatusUptimeRatio($event)">
                    %
                </div>
            </div>
            <div class="form-group row">
                <strong class="col-lg-3 col-md-4 col-12">Expiration warning :</strong>
                <div class="col-lg-9 col-md-8 col-12">
                    <div class="d-flex align-items-center">
                        <div>
                            <input id="switch-reportWarrantyExpiration" type="checkbox" class="cmn-checkbox mr-2"
                                [disabled]="!_config.enabled.value" [checked]="_config.warrantyExpiration.enabled.value"
                                (change)="_config.enableWarrantyExpiration($event.target.checked)" />
                            <label for="switch-reportWarrantyExpiration">
                                <svg>
                                    <use xlink:href="#checkmark" />
                                </svg>
                            </label>
                        </div>
                        <span class="mx-2">Warranty is expiring within</span>
                        <input type="number" min="1" step="1" class="form-control form-control-sm input-ratio"
                            [disabled]="!_config.enabled.value || !_config.warrantyExpiration.enabled.value"
                            [class.error]="_config.warrantyExpiration.threshold.error"
                            [ngModel]="_config.warrantyExpiration.threshold.value"
                            (ngModelChange)="_config.changeWarrantyThreshold($event)">
                        <span class="mx-2">days</span>
                    </div>
                    <div class="mt-2 d-flex align-items-center">
                        <div>
                            <input id="switch-reportLicenseExpiration" type="checkbox" class="cmn-checkbox mr-2"
                                [disabled]="!_config.enabled.value" [checked]="_config.licenseExpiration.enabled.value"
                                (change)="_config.enableLicenseExipiration($event.target.checked)" />
                            <label for="switch-reportLicenseExpiration">
                                <svg>
                                    <use xlink:href="#checkmark" />
                                </svg>
                            </label>
                        </div>
                        <span class="mx-2">License is expiring within</span>
                        <input type="number" min="1" step="1" class="form-control form-control-sm input-ratio"
                            [disabled]="!_config.enabled.value || !_config.licenseExpiration.enabled.value"
                            [class.error]="_config.licenseExpiration.threshold.error"
                            [ngModel]="_config.licenseExpiration.threshold.value"
                            (ngModelChange)="_config.changeLicenseThreshold($event)">
                        <span class="mx-2">days</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div>
                    <strong>Deliver to :</strong>
                    <span class="note text-muted ml-2">(split by comma)</span>
                </div>
                <div>
                    <textarea class="form-control" rows="6"
                        placeholder="Enter valid email(s) here by spliting with comma ..."
                        [disabled]="!_config.enabled.value" [ngModel]="_config.receivers.value"
                        (ngModelChange)="_config.changeReceivers($event)">
                    </textarea>
                </div>
            </div>
        </div>
        <div *ngIf="_updateErrorMessage" class="warning-block">{{ _updateErrorMessage }}</div>
        <div class="d-flex mt-2">
            <button type="button" class="btn btn-sm btn-action ml-auto" [disabled]="_isUpdating || !_config.isChange()"
                (click)="_config.reset()">
                Cancel
            </button>
            <button type="button" class="btn btn-sm btn-action ml-2"
                [disabled]="_isUpdating || !_config.isChange() || !_config.isValid()" (click)="applyReportSetting()">
                Apply
                <span *ngIf="_isUpdating" class="text-loading" data-text="'...'">...</span>
            </button>
        </div>
    </ng-template>
</div>
<ng-template #templateLoadingReportConfig>
    <div class="loading">
        <p>Please wait...</p>
        <div class="loading-box"></div>
    </div>
</ng-template>