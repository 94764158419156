import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { EnterpriseAccountGroupBasicInfo } from './api.ent.data';

export interface IUpdateEnterpriseAccountGroupPathParameter {
    accountGroupID: string;
}

export interface IUpdateEnterpriseAccountGroupTxData {
    accountGroupName: string;
    autoIncludeNewDeviceGroup: boolean;
}

export class APIUpdateEnterpriseAccountGroupManager extends APIBaseManager<IUpdateEnterpriseAccountGroupPathParameter, void, IUpdateEnterpriseAccountGroupTxData, EnterpriseAccountGroupBasicInfo> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UpdateEnterpriseAccountGroup';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: IUpdateEnterpriseAccountGroupPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/accountGroups/' + pathParameters.accountGroupID;
    }
}