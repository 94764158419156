import { Component } from '@angular/core';
import * as _semver from 'semver';
import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { DLG_NAME_APK_UPDATE, ConfigDialogPage } from '../base/base-config-data';
import { LicenseScopeType } from '../../license/license.data';
import { APKUpdateInfo } from './apk.service';
import { DeviceInfo } from '../../device/data/device-info';
import { HelperLib } from '../../../lib/common/helper.lib';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css', './apk-form.component.css']
})
export class APKFormComponent extends BaseConfigFormComponent {
    ngOnInit(): void {
        this._dialogName = DLG_NAME_APK_UPDATE;
        this._licenseScopeType = LicenseScopeType.firmwareUpdate;
        this._allowSubmit = false;

        super.ngOnInit();
    }

    allowGoBack(): boolean {
        const isAllowBack = super.allowGoBack();
        if (isAllowBack && this._page === ConfigDialogPage.confirm) {
            return false;
        }

        return isAllowBack;
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        let from: ConfigDialogPage = this._page;
        if (from === ConfigDialogPage.checking) {
            from = ConfigDialogPage.action;

            this._legalDevices = this._legalDevices.filter(d => this._bSpecific ? true : d.isSelect);
            //category: platform | bundle
            const apkInfoMap: { [category: string]: { info: APKUpdateInfo, devices: { virtualDeviceName: string, currentAPKVersion: string, origin: DeviceInfo, needUpdate?: boolean }[] } } = {};
            for (const device of this._legalDevices) {
                if (!device.currentSettings[this.constantSvc.DEVKEY_INFO_APP] || !device.currentSettings[this.constantSvc.DEVKEY_INFO_APP].category) {
                    apkInfoMap['platform'] = apkInfoMap['platform'] || { info: {}, devices: [] };
                    apkInfoMap['platform'].devices.push({
                        virtualDeviceName: device.virtualName,
                        currentAPKVersion: device.currentSettings[this.constantSvc.DEVKEY_INFO_APP].version ? device.currentSettings[this.constantSvc.DEVKEY_INFO_APP].version : device.currentSettings[this.constantSvc.DEVKEY_INFO_APKVERSION],
                        origin: device,
                        needUpdate: false
                    });
                }
                else {
                    const category: string = device.currentSettings[this.constantSvc.DEVKEY_INFO_APP].category;
                    apkInfoMap[category] = apkInfoMap[category] || { info: {}, devices: [] };
                    apkInfoMap[category].devices.push({
                        virtualDeviceName: device.virtualName,
                        currentAPKVersion: device.currentSettings[this.constantSvc.DEVKEY_INFO_APP].version,
                        origin: device,
                        needUpdate: false
                    });
                }
            }

            this._actionData.updateInfo = apkInfoMap;
        }

        super.goNext(from);
    }

    protected goBack(): void {
        let from: ConfigDialogPage = this._page;
        if (from === ConfigDialogPage.confirm) {
            from = ConfigDialogPage.action;
        }

        super.goBack(from);
    }

    protected submit(): void {
        const reqDataList: { device: DeviceInfo, downloadLink: string, downloadVersion: string, md5: string }[] = [];
        Object.keys(this._actionData.updateInfo).forEach((category: string) => {
            for (const d of this._actionData.updateInfo[category].devices) {
                if (d.needUpdate) {
                    reqDataList.push({ device: d.origin, downloadLink: this._actionData.updateInfo[category].info.link, downloadVersion: this._actionData.updateInfo[category].info.version, md5: this._actionData.updateInfo[category].info.md5 });
                }
            }
        });

        this.devSvc.batchAPKUpdate(reqDataList).subscribe(res => {
            this._page++;
            if (res.isFault()) {
                this._errorMessage = HelperLib.getErrorMessage(res);
            }

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler(res);
            }
        });
    }
}