<div class="list-group">
  <div class="list-group-item list-group-header black">
    <div class="row">
      <span class="col title">Activity</span>
      <span class="col title">Status</span>
      <span class="col title">Issue date</span>
      <span class="col title layout-desktop">Finish date</span>
    </div>
  </div>
  <div *ngIf="_bLoadingTask; else templateLoadComplete" class="loading">
    <div class="loading-box"></div>
  </div>
  <ng-template #templateLoadComplete>
    <div *ngIf="!_device; else templateDeviceReady" class="text-center mt-3 mb-5">
      Select a device to inspect its activities.
    </div>
    <ng-template #templateDeviceReady>
      <div *ngIf="!_bLicenseEnabled; else templateLicenseReady" class="warning text-center">
        No license to inspect device activity.
      </div>
      <ng-template #templateLicenseReady>
        <div id="activityAccordionList" class="accordion list-group-content list-group-hoverless">
          <ng-template ngFor let-taskInfo [ngForOf]="_device?.taskInfos">
            <div class="list-group-item list-group-item-action" data-toggle="collapse"
              [attr.data-target]="'#activity-' + taskInfo.id" aria-expanded="false"
              [ngClass]="_task?.id === taskInfo.id ? 'active': ''" (click)="selectTask(taskInfo)">
              <div class="row align-items-center">
                <span class="col col-main">
                  <span [ngSwitch]="taskInfo.type" data-toggle="tooltip" title="{{ taskInfo.id }}" class="text-break">
                    <ng-template [ngSwitchCase]="constantSvc.TASKTYPE_SCREENSHOT">
                      Refresh screenshot
                    </ng-template>
                    <ng-template [ngSwitchCase]="constantSvc.TASKTYPE_REBOOT">
                      Reboot
                    </ng-template>
                    <ng-template [ngSwitchCase]="constantSvc.TASKTYPE_INSTALL_FIRMWARE">
                      Install firmware
                    </ng-template>
                    <ng-template [ngSwitchCase]="constantSvc.TASKTYPE_CHECK_FIRMWARE">
                      Check latest firmware
                    </ng-template>
                    <ng-template [ngSwitchCase]="constantSvc.TASKTYPE_INSTALL_APK">
                      Install APK
                    </ng-template>
                    <ng-template [ngSwitchCase]="constantSvc.TASKTYPE_CONFIG_BASIC">
                      Basic configuration
                    </ng-template>
                    <ng-template [ngSwitchCase]="constantSvc.TASKTYPE_CONFIG_NET">
                      Network configuration
                    </ng-template>
                    <ng-template [ngSwitchCase]="constantSvc.TASKTYPE_DATA_SYNC">
                      Sync
                    </ng-template>
                    <ng-template [ngSwitchCase]="constantSvc.TASKTYPE_APP_LICENSE_RELOAD">
                      Reload license
                    </ng-template>
                    <ng-template [ngSwitchCase]="constantSvc.TASKTYPE_DEBUG_LOG_COLLECT">
                      Troubleshoot
                    </ng-template>
                    <ng-template [ngSwitchCase]="constantSvc.TASKTYPE_SECURITY_LOCALPWD">
                      Update security password
                    </ng-template>
                    <ng-template [ngSwitchCase]="constantSvc.TASKTYPE_CLEARCACHE">
                      Clear cache
                    </ng-template>
                    <div *ngSwitchDefault>
                      {{ getGeneralTypeName(taskInfo.type) }}
                    </div>
                  </span>
                </span>
                <span class="col col-sub">
                  <span [ngSwitch]="taskInfo.status" class="task-status-block">
                    <ng-template [ngSwitchCase]="_enumTaskStatus.success">
                      <span class="action" [ngClass]="'status-' + _enumTaskStatus[taskInfo.status]">Finish</span>
                    </ng-template>
                    <ng-template [ngSwitchCase]="_enumTaskStatus.fail">
                      <span class="action" [ngClass]="'status-' + _enumTaskStatus[taskInfo.status]">Fail</span>
                    </ng-template>
                    <ng-template [ngSwitchCase]="_enumTaskStatus.pending">
                      <span class="action" [ngClass]="'status-' + _enumTaskStatus[taskInfo.status]">Pending</span>
                    </ng-template>
                    <ng-template [ngSwitchCase]="_enumTaskStatus.progress">
                      <span class="action" [ngClass]="'status-' + _enumTaskStatus[taskInfo.status]">In progress</span>
                      <span> {{ taskInfo.progress }}%</span>
                    </ng-template>
                  </span>
                </span>
                <span class="col col-sub">
                  {{ taskInfo.issueDate | date:'yyyy-MM-dd HH:mm:ss' }}
                </span>
                <span class="col col-sub layout-desktop">
                  {{ taskInfo.finishDate | date:'yyyy-MM-dd HH:mm:ss' }}
                </span>
              </div>
            </div>
            <div [id]="'activity-' + taskInfo.id" class="collapse p-2 task-detail" data-parent="#activityAccordionList">
              <div *ngIf="_task">
                <div class="task-item">
                  <span class="title">Activity status :</span>
                  <span [ngSwitch]="_task?.status" class="task-status-block">
                    <span *ngSwitchCase="_enumTaskStatus.pending">
                      <span class="action" [ngClass]="'status-' + _enumTaskStatus[_task?.status]">Pending</span>
                    </span>
                    <span *ngSwitchCase="_enumTaskStatus.fail">
                      <span class="action" [ngClass]="'status-' + _enumTaskStatus[_task?.status]">Fail</span>
                    </span>
                    <span *ngSwitchCase="_enumTaskStatus.success">
                      <span class="action" [ngClass]="'status-' + _enumTaskStatus[_task?.status]">Finish</span>
                    </span>
                    <span *ngSwitchCase="_enumTaskStatus.progress">
                      <span class="action" [ngClass]="'status-' + _enumTaskStatus[_task?.status]">
                        In progress
                      </span>
                    </span>
                  </span>
                </div>
                <div class="task-item">
                  <span class="title">Activity ID :</span>
                  <span>{{ _task?.id }}</span>
                </div>
                <div class="task-item">
                  <span class="title">Issue date :</span>
                  <span>{{ _task?.issueDate | date:'yyyy-MM-dd HH:mm:ss' }}</span>
                </div>
                <div class="task-item">
                  <span class="title">Start date :</span>
                  <span>{{ _task?.startDate | date:'yyyy-MM-dd HH:mm:ss' }}</span>
                </div>
                <div class="task-item">
                  <span class="title">Finish date :</span>
                  <span>{{ _task?.finishDate | date:'yyyy-MM-dd HH:mm:ss' }}</span>
                </div>
                <div *ngIf="_task?.resources" class="task-item">
                  <span class="title">Configurations</span>
                  <div class="my-1 pl-2 border-top">
                    <div class="list-group my-1">
                      <div class="list-group-item list-group-header black">
                        <div class="row">
                          <div class="col-2 title text-break">
                            Setting
                          </div>
                          <div class="col-5 title text-break">
                            Last value
                          </div>
                          <div class="col-5 title text-break">
                            New value
                          </div>
                        </div>
                      </div>
                      <div class="list-group-content list-group-hoverless">
                        <ng-template ngFor let-config [ngForOf]="_task?.resources">
                          <ng-template [ngIf]="!config.hidden">
                            <div class="list-group-item">
                              <div class="row">
                                <div class="col-2 d-flex align-items-center text-break">
                                  {{ (config.langKey | translate | capitalFirstWord) || (config.name | capitalFirstWord) }}
                                </div>
                                <div class="col-5 text-break">
                                  <div [ngSwitch]="config.name">
                                    <ng-template [ngSwitchCase]="constantSvc.DEVKEY_APPSTART">
                                      <na-appstart-preview [data]="config.origin" [compare]="config.value">
                                      </na-appstart-preview>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="constantSvc.DEVKEY_FAKE_MAINTENANCE">
                                      <na-mtPlayback-preview [data]="config.origin">
                                      </na-mtPlayback-preview>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENSAVER">
                                      <na-screenSaver-preview [data]="config.origin">
                                      </na-screenSaver-preview>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF">
                                      <na-screenOff-preview [data]="config.origin">
                                      </na-screenOff-preview>
                                    </ng-template>
                                    <div *ngSwitchDefault>
                                      <span>{{ expandContent(config.origin) }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-5 text-break">
                                  <div [ngSwitch]="config.name">
                                    <ng-template [ngSwitchCase]="constantSvc.DEVKEY_APPSTART">
                                      <na-appstart-preview [data]="config.value" [compare]="config.origin">
                                      </na-appstart-preview>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="constantSvc.DEVKEY_FAKE_MAINTENANCE">
                                      <na-mtPlayback-preview [data]="config.value">
                                      </na-mtPlayback-preview>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENSAVER">
                                      <na-screenSaver-preview [data]="config.value">
                                      </na-screenSaver-preview>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF">
                                      <na-screenOff-preview [data]="config.value">
                                      </na-screenOff-preview>
                                    </ng-template>
                                    <div *ngSwitchDefault>
                                      <span *ngIf="config.secure" class="ml-1">********</span>
                                      <span *ngIf="!config.secure"
                                        class="ml-1">{{ expandContent(config.value) }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="_task?.errorMessage" class="task-item">
                  <span class="title">Error :</span>
                  <p class="text-danger">{{ _task?.errorMessage | capitalFirstWord }}</p>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </ng-template>
  </ng-template>
</div>
<div *ngIf="_showLastTrackTime">
  <strong>Last activity tracking time :</strong>
  <span *ngIf="_device" class="ml-2">
    {{ _device?.taskTrackTime | date:'yyyy-MM-dd HH:mm:ss' }}
  </span>
</div>
<div *ngIf="_device" class="d-flex justify-content-center">
  <button *ngIf="_allowClose" type="button" class="btn btn-sm btn-action btn-light" (click)="close()">
    Close
  </button>
  <button *ngIf="_allowRefresh" type="button" class="btn btn-sm btn-action btn-light ml-2"
    [disabled]="!_device || _bLoadingTask || !_bLicenseEnabled || _refreshCountdownMap[_device.virtualId] > 0"
    (click)="refreshDeviceTask(true)">
    <span>Refresh</span>
    <ng-template [ngIf]="_device && _refreshCountdownMap[_device.virtualId] > 0">
      - {{ _refreshCountdownMap[_device.virtualId] }} s
    </ng-template>
  </button>
</div>