import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AlertService } from './alert.service';
import { AlertConfigOverviewComponent } from './alertConfig/alert-config-overview.component';
import { AlertConfigDlgEditComponent } from './alertConfig/dlg/alertConfig-dlg-edit.component';
import { AlertConfigDlgRemoveComponent } from './alertConfig/dlg/alertConfig-dlg-remove.component';
import { AlertElementModule } from './alertConfig/comp/alert-element.module';

import { CustomPipeModule } from '../../../lib/pipes/custom-pipe.module';
import { TranslateModule } from '../../../translate/translate.module';

import { UIElementModule } from '../../../uiElement/uiElement.module';

import { AlertDataCtrlModule } from './alertConfig/alertData/alert-data-ctrl.module';
import { AlertFuncService } from './alertConfig/dlg/alert-func.service';
import { AlertFuncDirective } from './alertConfig/dlg/alert-func.directive';
import { AlertWrapperComponent } from './alert-wrapper.component';
import { OpenAlertOverviewComponent } from './alertAck/alert-open-overview.component';
import { CloseAlertOverviewComponent } from './alertAck/alert-close-overview.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        CustomPipeModule,
        TranslateModule,
        UIElementModule,
        AlertElementModule,
        AlertDataCtrlModule,
    ],
    providers: [
        AlertService,
        AlertFuncService
    ],
    declarations: [
        AlertWrapperComponent,
        OpenAlertOverviewComponent,
        CloseAlertOverviewComponent,
        AlertFuncDirective,
        AlertConfigOverviewComponent,
        AlertConfigDlgEditComponent,
        AlertConfigDlgRemoveComponent
    ],
    exports: [
        AlertConfigOverviewComponent,
    ]
})
export class AlertModule { }