import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

import { IClass } from '../../../lib/common/common.data';
import { PolicyInfo, PolicyType } from './policy.data';
import { PolicyFuncDirective } from './policy-func.directive';
import { PolicyFuncItem, PolicyFuncService, IPolicyFunc, POLICY_FUNC_CREATE, POLICY_FUNC_EDIT, POLICY_FUNC_REMOVE } from './policy-func.service';
import { PolicyService } from './policy.service';
import { AccountService } from '../../../../app/entry/account.service';
import { HelperLib } from 'app/lib/common/helper.lib';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    templateUrl: './policy-overview.component.html',
    styleUrls: ['./policy.style.css']
})
export class PolicyOverviewComponent extends AutoUnsubscribeComponent implements OnInit, IClass {
    className: string;
    _loading: boolean = false;

    _policy: PolicyInfo;
    _policyCategoryMap: { [type: string]: { policyList: PolicyInfo[], displayName: string, expand: boolean } } = {};
    _policyCount: number = 0;
    _queryPolicyID: string;
    _enumPolicyType: typeof PolicyType = PolicyType;
    private _searchContent: string = '';

    private _searchRef: ElementRef;
    @ViewChild('search')
    set search(v: ElementRef) {
        if (!this._searchRef && v) {
            this._searchRef = v;

            fromEvent(this._searchRef.nativeElement, 'input').pipe(
                debounceTime(1000),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._searchContent = e.target.value.toLocaleLowerCase();
            });
        }
    }

    private _policyFuncHost: PolicyFuncDirective;
    @ViewChild(PolicyFuncDirective, { static: true })
    set policyFuncHost(host: any) {
        if (host) {
            this._policyFuncHost = host;
        }
    }

    constructor(
        private route: ActivatedRoute,
        private accountSvc: AccountService,
        private policySvc: PolicyService,
        private policyFuncSvc: PolicyFuncService) {
            super();
    }

    ngOnInit(): void {
        this.policySvc.policyUpdated.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((p: PolicyInfo) => {
            if (this._policy?.id === p.id) {
                this._policy = null;
                setTimeout(() => {
                    //for update the policy-report view
                    this._policy = p;
                }, 0);
            }
        });

        this.route.paramMap.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((params: ParamMap) => {
            this._queryPolicyID = params.get('id');
        });

        this.refreshPolicyList();
    }

    supportPolicyUpdate(): boolean {
        return this.accountSvc.hasScope_devicePolicy_update();
    }

    refreshPolicyList(force: boolean = false): void {
        this._policyCategoryMap = {};
        this._loading = true;
        this._policy = null;

        this.policySvc.getPolicyList(force).subscribe((policyList: PolicyInfo[]) => {
            this._policyCategoryMap = policyList.reduce<{ [type: string]: { policyList: PolicyInfo[], displayName: string, expand: boolean } }>((prev, p: PolicyInfo) => {
                prev[p.type] = prev[p.type] || { policyList: [], displayName: HelperLib.splitUpperWord(p.type), expand: true };
                prev[p.type].policyList.push(p);

                return prev;
            }, {});

            this._policyCount = policyList.length;
            if (this._queryPolicyID) {
                this._policy = policyList.find(p => p.id === this._queryPolicyID);
            }

            this._loading = false;
        });
    }

    isPolicyNameMatch(policyName: string): boolean {
        return !this._searchContent || policyName.toLocaleLowerCase().indexOf(this._searchContent) >= 0;
    }

    expandCategory(category: { policyList: PolicyInfo[], expand: boolean }, expand: boolean): void {
        category.expand = expand;
    }

    createPolicy(): void {
        this.createPolicyForm(POLICY_FUNC_CREATE);
    }

    selectPolicy(p: PolicyInfo): void {
        this._policy = p;
    }

    editPolicy(): void {
        this.createPolicyForm(POLICY_FUNC_EDIT, this._policy.copy());
    }

    removePolicy(): void {
        this.createPolicyForm(POLICY_FUNC_REMOVE, this._policy);
    }

    private dlgCompleteFunc(ret: { func: string, errorMessage?: string, data?: any }): void {
        this._policy = null;
        if (!ret.errorMessage) {
            this.refreshPolicyList();
        }
    }

    private createPolicyForm(funcName: string, p?: PolicyInfo): void {
        const item: PolicyFuncItem = this.policyFuncSvc.getItemByName(funcName);
        if (item) {
            const viewContainerRef = this._policyFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IPolicyFunc>componentRef.instance).title = item.title;
            (<IPolicyFunc>componentRef.instance).policy = p;
            (<IPolicyFunc>componentRef.instance).dialogCompleteHandler = this.dlgCompleteFunc.bind(this);
        }
    }
}