import { Component, OnInit } from "@angular/core";
import { VirtualDlgComponent } from "app/content/virtual/dlg/virtual-dlg.component";
import { IDeviceLabelFunc } from "./dev-label-func.def";
import { DeviceLabelInfo, LABEL_MAX_LIMIT } from "../dev-label.data";
import { DeviceInfo } from "../../data/device-info";
import { AccountService } from "app/entry/account.service";
import { DeviceService } from "../../device.service";
import { ConstantService } from "app/lib/common/constant.service";

@Component({
    templateUrl: 'dev-label-attach-dlg.component.html',
    styleUrls: ['../dev-label.style.css']
})
export class DeviceLabelAttachDlgComponent extends VirtualDlgComponent implements IDeviceLabelFunc<{ devices: DeviceInfo[] }>, OnInit {
    readonly MAX_LABEL_COUNT: number = LABEL_MAX_LIMIT;

    title: string;
    label: DeviceLabelInfo;
    other?: { devices: DeviceInfo[] };

    _currentLabelNames: string[] = [];
    _labelName: string;
    
    _updating: boolean;
    _errorMessage: string;

    constructor(protected accountSvc: AccountService, private devSvc: DeviceService, private constantSvc: ConstantService) {
        super(accountSvc);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.devSvc.getDeviceLabels().subscribe((labels: string[]) => this._currentLabelNames = labels);
    }

    selectCurrentLabel(labelName: string): void {
        this._labelName = labelName;
    }

    attachLabel(): void {
        if (this._currentLabelNames.indexOf(this._labelName) < 0 && this._currentLabelNames.length + 1 > this.MAX_LABEL_COUNT) {
            this._errorMessage = `At most ${this.MAX_LABEL_COUNT} are permitted. Please remove a label first`;
            return;
        }

        this._errorMessage = null;
        this._updating = true;

        this.devSvc.updateDeviceLabels({ labelName: this._labelName, virtualDeviceIDList: this.other?.devices.map(d => d.virtualId )}).subscribe((res: { isFault: boolean, errorMessage?: string}) => {
            this._updating = false;

            if (res.isFault) {
                this._errorMessage = res.errorMessage;
                return;
            }

            setTimeout(() => this.closeDlg(), 0);
        });
    }
}