<div class='modal-dialog config-dlg' role="document">
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body my-2'>
            <div class="m-2">
                <div>
                    <label class="col-form-label px-2">Certificate to remove : <strong>{{ this.certList[0].name }}</strong></label>
                </div>
                <div class="mt-4">
                    <p>
                        <span class="warning-block mr-2">Warning</span>Removing this certificate will also remove all policies that contain it.
                    </p>
                    <p>
                        Please confirm that you want to remove this certificate from the list. Once you confirm, all
                        policies that reference this certificate will be removed and the certificate will no longer be
                        available.
                    </p>
                    <p>
                        The following policies will be affected by this action :
                    </p>
                    <p>
                        To proceed with removing this certificate, please click the 'Confirm' button below. If you do not
                        want to remove the certificate, please click 'Cancel' to return to the previous page.
                    </p>
                    <p>
                        Note that this action cannot be undone, so please make sure that you want to remove the certificate
                        and all associated polilcies before confirming.
                    </p>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-4">
                <button type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_page === _enumPage.submit" data-dismiss="modal">
                    <span class="fas fa-times-circle mr-1"></span>
                    {{ 'key-cancel' | translate | capitalFirstWord }}
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light ml-2" [disabled]="_page === _enumPage.submit"
                    (click)="removeCert()">
                    <span class="fas fa-plus-circle mr-1"></span>
                    Confirm
                </button>
                <div *ngIf="_page === _enumPage.submit" class="loading loading-p0 ml-4">
                    <div class="loading-box"></div>
                </div>
            </div>
        </div>
    </div>
</div>