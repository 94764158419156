<div class='modal-dialog config-dlg' role="document">
    <div class='modal-content'>
      <div class='modal-header'>
        <h4 class="modal-title">{{ _title }}</h4>
        <button #dlgClose type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class='modal-body'>
        <div *ngIf="_page === _enumPage.action">
          <p *ngIf="_policy.isManaged" class="warning-block">
            This policy is managed.
          </p>
          <p>Are you sure to remove the policy?</p>
          <strong>{{ _policy?.name }}</strong>
        </div>
        <div *ngIf="_page === _enumPage.submit">
          <div class="progress">
            <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
              aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
              Updating the changes... Please wait...
            </div>
          </div>
        </div>
        <div *ngIf="_page === _enumPage.result">
          <p *ngIf="!_errorMessage">Policy
            <q>{{ _policy?.name }}</q> has been removed successfully.</p>
          <div *ngIf="_errorMessage">
            <p>Remove policy
              <q>{{ _policy?.name }}</q> failed.</p>
            <p class="warning">{{ _errorMessage }}</p>
          </div>
        </div>
        <div class="row control-block d-flex justify-content-center">
          <button *ngIf="_page === _enumPage.action" type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal">
            <span class="fas fa-times-circle icon-left"></span>
            {{ 'key-cancel' | translate | capitalFirstWord }}
          </button>
          <button *ngIf="_page === _enumPage.action" type="button" class="btn btn-sm btn-action btn-light" (click)="remove()">
            <span class="fas fa-check-circle icon-left"></span>
            {{ 'key-ok' | translate | capitalFirstWord }}
          </button>
          <button *ngIf="_page === _enumPage.result" type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal">
            <span class="fas fa-times-circle icon-left"></span>
            {{ 'key-close' | translate | capitalFirstWord }}
          </button>
        </div>
      </div>
    </div>
  </div>
  