import { Component } from '@angular/core';

import { DeviceInfo } from '../../../../../device/data/device-info';
import { DeviceService } from '../../../../../device/device.service';
import { OfflineAlertConfigData, OfflineAlertEventMonitorType, OFFLINE_ALERT_AVAILABLE_CHECKPOINT_LIST, OFFLINE_ALERT_FIRE_THRESHOLD_LIST } from '../../alert.data';
import { AlertHelper } from '../../alert.helper';
import { IAlertDataCtrl } from '../alert-data-ctrl.interface';

@Component({
    templateUrl: './alert-data-offline.component.html',
    styleUrls: ['../../alert.style.css', '/alert-data-offline.component.css']
})
export class AlertOfflineDataComponent implements IAlertDataCtrl {
    _alertThresholdList: string[] = OFFLINE_ALERT_FIRE_THRESHOLD_LIST;
    _alertCheckPointList: string[] = OFFLINE_ALERT_AVAILABLE_CHECKPOINT_LIST;
    _enumAlertEventMonitorType: typeof OfflineAlertEventMonitorType = OfflineAlertEventMonitorType;
    _receivers: string;
    _defaultSelectDeviceIDList: string[] = [];
    _updateDeviceList: DeviceInfo[] = [];

    private _data: OfflineAlertConfigData = new OfflineAlertConfigData();
    set alertData(data: OfflineAlertConfigData) {
        this._data = data;
        this._receivers = this._data && this._data.alertReceiverList ? this._data.alertReceiverList.join('\n') : '';
        this._defaultSelectDeviceIDList = this._data.alertApplyDeviceList.map(d => d.virtualDeviceID);
    }

    _isViewMode: boolean = true;
    set isViewMode(isView: boolean) {
        this._isViewMode = isView;
    }

    constructor(private devSvc: DeviceService) {}

    changeReceivers(receiver: string): void {
        this._data.changeReceiver(receiver);
    }

    transformReceiverList(receiverList: string[]): string {
        return AlertHelper.transformReceiverList(receiverList);
    }

    transformApplyDeviceList(deviceIDList: { virtualDeviceID: string }[]): string {
        return AlertHelper.transformApplyDeviceList(this.devSvc, deviceIDList);
    }

    onOfflineAlertDeviceSelectChange(deviceList: DeviceInfo[]): void {
        this._data.changeApplyDeviceList(deviceList);
    }
}