<div class='modal-header'>
    <h4 class="modal-title">{{ title }}</h4>
    <button #dlgClose type="button" class="close" data-dismiss="modal">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class='modal-body'>
    <div>
        <div class="input-group">
            <input #txtInput type="text" class="form-control" aria-label="String content">
        </div>
    </div>
    <div class="d-flex mt-4">
        <button type="button" class="btn btn-sm btn-action btn-light ml-auto" data-dismiss="modal">
            <span class="fas fa-times-circle"></span>
            {{ 'key-cancel' | translate | capitalFirstWord }}
        </button>
        <button type="button" class="btn btn-sm btn-action btn-light ml-2" data-dismiss="modal"
            [disabled]="!txtInput.value" (click)="sendMessage(txtInput);">
            <span class="fas fa-check"></span>
            {{ 'key-ok' | translate | capitalFirstWord }}
        </button>
    </div>
</div>
