export class ReportConfig {
    isWeeklyReportEnabled: boolean;
    deviceStatus: {
        enabled: boolean;
        deviceAmountThresholdInPercentage: number;
        deviceUptimeThresholdInPercentage: number;
    };
    warrantyStatus: {
        enabled: boolean;
        warrantyExpiryThresholdInDays: number;
    };
    licenseKeyStatus: {
        enabled: boolean;
        licenseKeyExpiryThresholdInDays: number;
    };
    reportReceiverList: string[];

    constructor(o?: ReportConfig) {
        this.isWeeklyReportEnabled = o?.isWeeklyReportEnabled ?? false;
        this.deviceStatus = {
            enabled: o?.deviceStatus?.enabled ?? true,
            deviceAmountThresholdInPercentage: o?.deviceStatus?.deviceAmountThresholdInPercentage || 95,
            deviceUptimeThresholdInPercentage: o?.deviceStatus?.deviceUptimeThresholdInPercentage || 90
        };
        this.warrantyStatus = {
            enabled: o?.warrantyStatus?.enabled ?? true,
            warrantyExpiryThresholdInDays: o?.warrantyStatus?.warrantyExpiryThresholdInDays || 30
        };
        this.licenseKeyStatus = {
            enabled: o?.licenseKeyStatus?.enabled ?? true,
            licenseKeyExpiryThresholdInDays: o?.licenseKeyStatus?.licenseKeyExpiryThresholdInDays || 30
        };
        this.reportReceiverList = o && o.reportReceiverList ? o.reportReceiverList.map(r => r) : [];
    }

    copy(): ReportConfig {
        const c: ReportConfig = new ReportConfig();
        c.isWeeklyReportEnabled = this.isWeeklyReportEnabled;

        c.deviceStatus.enabled = this.deviceStatus.enabled;
        c.deviceStatus.deviceAmountThresholdInPercentage = this.deviceStatus.deviceAmountThresholdInPercentage;
        c.deviceStatus.deviceUptimeThresholdInPercentage = this.deviceStatus.deviceUptimeThresholdInPercentage;

        c.warrantyStatus.enabled = this.warrantyStatus.enabled;
        c.warrantyStatus.warrantyExpiryThresholdInDays = this.warrantyStatus.warrantyExpiryThresholdInDays;

        c.licenseKeyStatus.enabled = this.licenseKeyStatus.enabled;
        c.licenseKeyStatus.licenseKeyExpiryThresholdInDays = this.licenseKeyStatus.licenseKeyExpiryThresholdInDays;

        c.reportReceiverList = this.reportReceiverList.map(r => r);

        return c;
    }
}

export interface IReportData {
    accountID: string;
    body: string;
    reportData: {
        summary: {
            networkEvaluation: string,
            licenseEvaluation: string,
            warrantyEvaluation: string,
            errorCount: number,
            warningCount: number
        }
    };
    reportDate: string;
    reportPeriodEnd: string;
    reportPeriodStart: string;
    subject: string;
    weeklyReportID: string;
}