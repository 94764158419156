import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { IScepInfo, IScepSettingData } from './api.ent.scep.data';

export interface IUpdateEnterpriseScepPathParameter {
    SCEPSettingID: string;
}

export interface IUpdateEnterpriseScepTxData {
    settingData: IScepSettingData;
}

export class APIEnterpriseUpdateScepManager extends APIBaseManager<IUpdateEnterpriseScepPathParameter, void, IUpdateEnterpriseScepTxData, IScepInfo> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UpdateScep';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: IUpdateEnterpriseScepPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/scepSettings/' + pathParameters.SCEPSettingID;
    }
}