import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { of } from 'rxjs';
import { map, concatMap, catchError } from 'rxjs/operators';

import { AccessKeyInfo } from '../../../../API/v1/Account/api.accessKey.common';
import { AppConfigService } from '../../../../app.config';
import { AccountService } from '../../../../entry/account.service';
import { HelperLib } from '../../../../lib/common/helper.lib';
import { IAccessKeyFunc } from '../accessKey-func.service';
import { AccessKeyService } from '../accessKey.service';
import { VirtualDlgComponent } from 'app/content/virtual/dlg/virtual-dlg.component';

@Component({
    templateUrl: './acckey-dlg-create.component.html',
    styleUrls: ['../accessKey.style.css', './acckey-dlg-create.component.css']
})
export class AccessKeyDlgCreateComponent extends VirtualDlgComponent implements IAccessKeyFunc {
    readonly ACCKEY_LIMIT: number = 3;

    title: string;
    key: AccessKeyInfo;
    dialogCompleteHandler?: (result: any) => void;

    _loading: boolean = false;
    _key: AccessKeyInfo;
    _showSecret: boolean = false;
    _errorMessage: string;

    _appSettingList: { appName: string, templatePath?: string, setting?: string }[] = [];
    _appSetting: { appName: string, templatePath?: string, setting?: string };
    _appSettingStatus: { loading: boolean, error?: string } = { loading: false };

    private _reportLinkRef: ElementRef;
    @ViewChild('reportLink', { static: true })
    set reportLink(v: ElementRef) {
        if (v) {
            this._reportLinkRef = v;
        }
    }

    constructor(protected accountSvc: AccountService, private accKeySvc: AccessKeyService, private http: HttpClient) {
        super(accountSvc);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this._loading = true;
        this._appSettingList = this.accKeySvc.appSettingList;

        this.accKeySvc.getAccessKeyList().pipe(
            concatMap((res: AccessKeyInfo[]) => {
                if (res.length >= this.ACCKEY_LIMIT) {
                    throw "You can have at most 3 set of access keys at a time";
                }

                return this.accKeySvc.createAccessKey();
            }),
            map((res: { isFault: boolean, key: AccessKeyInfo, errorMessage?: string }) => {
                if (res.isFault) {
                    throw res.errorMessage;
                }

                this.dialogCompleteHandler(res.key);
                this._key = res.key;

                return { isFault: false };
            }),
            catchError((error: any) => of({ isFault: true, error: error }))
        ).subscribe((res: { isFault: boolean, error?: string }) => {
            this._errorMessage = res.error;
            this._loading = false;
        });
    }

    showSecret(show: boolean): void {
        this._showSecret = show;
    }

    downloadKey(): void {
        const rowList: string[][] = [];
        rowList.push(['Access key ID', 'Access key secret']);
        rowList.push([this._key.accessKeyID, '"' + this._key.accessKeySecret.trim() + '"']); //in case accesskeySecret has ',' character

        HelperLib.downloadCsv(this._reportLinkRef.nativeElement, this.accountSvc.accountName + '-accesskey', null, rowList);
    }

    showAppSetting(appSetting: { appName: string, templatePath?: string, setting?: string }): void {
        this._appSettingStatus = { loading: true, error: null };
        this._appSetting = appSetting;

        of(true).pipe(
            concatMap(() => {
                if (!appSetting.setting) {
                    //load from templatePath
                    return this.http.get(appSetting.templatePath, { responseType: 'text' }).pipe(
                        map((setting: string) => ({ isFault: false, setting: setting }))
                    );
                }

                return of({ isFault: false, setting: appSetting.setting });
            }),
            catchError(error => of({ isFault: true, error: error }))
        ).subscribe((res: { isFault: boolean, setting?: string, error?: string }) => {
            if (!res.isFault) {
                //general setting?
                this._appSetting.setting = res.setting;
                this._appSetting.setting = appSetting.setting.replace(/\[IAdeaAccID\]/, "\"" + this._key.accessKeyID + "\"");
                this._appSetting.setting = appSetting.setting.replace(/\[IAdeaAccSecret\]/, "\"" + this._key.accessKeySecret + "\"");
                switch (this._appSetting.appName) {
                    case 'Planon':
                        {
                            this._appSetting.setting = appSetting.setting.replace(/\[IAdeaAPIUrl\]/, "\"" + AppConfigService.configs.server.api.protocol + "://" + AppConfigService.configs.server.api.baseUrl + ":" + AppConfigService.configs.server.api.port + "\"");
                        }
                        break;
                }
            }

            this._appSettingStatus = { loading: false, error: res.error };
        });
    }

    copyAppSetting(): void {
        if (this._appSetting) {
            const selBox = document.createElement('textarea');

            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            selBox.value = this._appSetting.setting;

            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();

            document.execCommand('copy');
            document.body.removeChild(selBox);
        }
    }
}