<label class="title">
    Power save
    <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
        [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
</label>
<div *ngIf="_unsupportReason" class="warning-block mb-1">{{ _unsupportReason }}</div>
<div [ngClass]="_showBorder? 'showBorder' : ''">
    <div class='switch-container'>
        <input id="cmn-toggle-screensaver" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
            [disabled]="_lockInfo || _disabled || _unsupportReason" [checked]="_info.enabled ? 'checked': null"
            (change)="enablePowersave($event.target.checked)">
        <label for="cmn-toggle-screensaver">
            <span class="sr-only">Enable screen saver</span>
        </label>
    </div>
    <div>
        Enter sleep mode when device idle more then
        <input #powersaveTimeout type="number" aria-label="Power save timeout" max="300" min="1" step="1"
            [disabled]="_lockInfo || _disabled || _unsupportReason || !_info.enabled" [value]="_info.timeout"
            (change)="onTimeoutChange($event.target.valueAsNumber)">
        mins (1-300 minutes)
    </div>
</div>