import { Component } from '@angular/core';
import { AccessKeyInfo } from '../../../../API/v1/Account/api.accessKey.common';
import { DialogPage } from '../../../../lib/common/common.data';
import { IAccessKeyFunc } from '../accessKey-func.service';
import { AccessKeyService } from '../accessKey.service';
import { VirtualDlgComponent } from 'app/content/virtual/dlg/virtual-dlg.component';
import { AccountService } from 'app/entry/account.service';

@Component({
    templateUrl: './acckey-dlg-inactive.component.html',
    styleUrls: ['../accessKey.style.css']
})
export class AccessKeyDlgInactiveComponent extends VirtualDlgComponent implements IAccessKeyFunc {
    title: string;
    key: AccessKeyInfo;
    dialogCompleteHandler?: (result: any) => void;

    _page: DialogPage = DialogPage.action;
    _enumDialogPage: typeof DialogPage = DialogPage;
    _loading: boolean;
    _inactiveResult: { isFault: boolean, errorMessage?: string };

    constructor(protected accountSvc: AccountService, private accKeySvc: AccessKeyService) {
        super(accountSvc);
    }

    inactiveAcc(): void {
        this._loading = true;
        this.accKeySvc.updateAccessKey(this.key, false).subscribe((res: { isFault: boolean, key: AccessKeyInfo, errorMessage?: string }) => {
            this._inactiveResult = {
                isFault: res.isFault,
                errorMessage: res.errorMessage
            };

            this._page = DialogPage.result;
            this._loading = false;
        });
    }
}