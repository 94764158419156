import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UserModule } from './user/user.module';
import { CustomizationComponent } from './customization/customization.component';
import { CustomPipeModule } from '../../../app/lib/pipes/custom-pipe.module';
import { SCEPModule } from './scep/scep.module';
import { CertModule } from './cert/cert.module';
import { SvcNowModule } from './3rdIntegrate/servicenow/svcnow.module';
import { UIElementModule } from 'app/uiElement/uiElement.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        CustomPipeModule,
        UserModule,
        SCEPModule,
        CertModule,
        SvcNowModule,
        UIElementModule
    ],
    declarations: [
        CustomizationComponent
    ],
    exports: []
})
export class AdminModule { }