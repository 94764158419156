import { Component } from '@angular/core';
import { MultiConfigItem } from '../multi-config-data';
import { DevFuncConfirmInterface } from '../../base/confirm/dev-func-confirm.interface';
import { ConfigurableItemKey } from '../../../../../app/lib/common/common.data';
import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { AndroidGroupType } from '../../firmware/firmware-data';

@Component({
    templateUrl: './m-basic-config-confirm-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './m-basic-config-confirm-subform.component.css', '../../dev-func.style.css']
})
export class MultiBasicConfigConfirmSubFormComponent implements DevFuncConfirmInterface {
    private _enumConfigKey: typeof ConfigurableItemKey = ConfigurableItemKey;

    _configList: MultiConfigItem[] = [];

    _actionData: any;
    set actionData(data: any) {
        if (data) {
            this._actionData = data;
            if (this._actionData.generalConfigList) {
                this._configList = this._configList.concat(this._actionData.generalConfigList);
            }
            if (this._actionData.contentConfigList) {
                this._configList = this._configList.concat(this._actionData.contentConfigList);
            }
            if (this._actionData.scheduleConfigList) {
                this._configList = this._configList.concat(this._actionData.scheduleConfigList);
            }
        } 
    }

    _appstartExtraList: {
        property: string;
        displayName: string;
        condition?: (androidVersion: AndroidGroupType) => boolean
    }[] = [];

    allowSubmitCallback: (isAllow: boolean) => void;

    constructor(private constantSvc: ConstantService) {
        this._appstartExtraList = this.constantSvc.getAppstartExtraList();
    }

    getConfigValue(config: MultiConfigItem): any {
        const keys: string[] = Object.keys(config.settingMap);
        if (keys.length === 1) {
            return config.settingMap[keys[0]].value;
        }

        return 'Unknown';
    }
}