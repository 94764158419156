<div *ngIf="!_loading; else templateLoadingScep" class="mt-2">
    <div *ngIf="_page === _enumPage.prepare && _errorMessage; else templateNoScepError" class="row">
        <div>
            <p>Some errors occurred. Please refresh the website or login again.</p>
            <strong>Error message : </strong>
            <div class="warning-block">
                {{ _errorMessage }}
            </div>
        </div>
    </div>
    <ng-template #templateNoScepError>
        <div>
            <div class="block-titlebar">
                <i class="fas fa-arrow-alt-circle-left green-2" style="font-size:1.5rem" [routerLink]="_scepRoute"></i>
                <strong class="font-weight-bold ml-2">Credential details</strong>
                <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-auto"
                    [class.active]="_isInEdit" (click)="startEdit()">
                    <i class="fas fa-pen mr-1"></i>
                    Edit
                </button>
            </div>
            <div class="scep-server-detail-wrapper">
                <na-scep-server-detail [scep]="_scep" [isInEdit]="_isInEdit"
                    (onScepInfoUpdated)="onScepInfoUpdate($event)"></na-scep-server-detail>
            </div>
            <div *ngIf="_page === _enumPage.result && this._errorMessage" class="block block-error">
                <i class="fas fa-exclamation-circle"></i>
                <span class="ml-2">Update credential {{ _scep?.alias }} failed :</span>
                <div>{{ _errorMessage }}</div>
            </div>
            <div *ngIf="_isInEdit" class="mt-2 d-flex align-items-center">
                <button type="button" class="btn btn-sm btn-action btn-light ml-auto" (click)="cancelEdit()">
                    <span class="fas fa-times-circle mr-1"></span>
                    Cancel
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light ml-2" (click)="updateScep()">
                    <span class="fas fa-save mr-1"></span>
                    Save
                </button>
                <div *ngIf="_page === _enumPage.submit" class="loading loading-p0 ml-3">
                    <div class="loading-box"></div>
                </div>
            </div>
            <button #btnUpdate type="button" class="d-none" data-toggle="modal" data-target="#scepFuncModalHost">
                <span class="sr-only">Save</span>
            </button>
        </div>
        <div class="mt-2">
            <div class="block-titlebar">
                <strong class="font-weight-bold">Device list</strong>
                <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-auto"
                    data-toggle="modal" data-target="#scepFuncModalHost"
                    [disabled]="_exporting || _selectedDeviceList.length === 0" (click)="renewDevice()">
                    <i class="fas fa-retweet mr-1"></i>
                    Renew SCEP
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-2" data-toggle="modal"
                    data-target="#scepFuncModalHost" [disabled]="_exporting" (click)="addDevice()">
                    <i class="fas fa-plus mr-1"></i>
                    Add device
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-2"
                    [disabled]="_exporting" (click)="export()">
                    <i class="fas fa-file-download mr-1"></i>
                    Export
                </button>
                <a #exportLink class="d-none"></a>
            </div>
            <div class="mt-2">
                <na-scep-dev-pickup [scep]="_scep" (onDeviceSearchChanged)="onDeviceSearchChange($event)" (onDeviceSelected)="onDeviceSelect($event)"></na-scep-dev-pickup>
            </div>
        </div>
    </ng-template>
</div>
<ng-template #templateLoadingScep>
    <div class="loading">
        <p>Please wait...</p>
        <div class="loading-box"></div>
    </div>
</ng-template>
<div id="scepFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template scep-func></ng-template>
</div>