import { Component } from '@angular/core';
import { DeviceInfo } from '../../../device/data/device-info';
import { DevFuncActionInterface } from '../../base/action/dev-func-action.interface';
import { DeviceNetConfigControl } from '../data/device-net-config.control';
import { NetworkType } from '../../../device/data/device-net-info';
import { AppConfigService } from '../../../../../app/app.config';
import { HelperLib, REFRESH_DURATION } from '../../../../lib/common/helper.lib';

@Component({
    templateUrl: './net-config-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './net-config-action-subform.component.css', '../../dev-func.style.css']
})
export class NetConfigActionSubFormComponent implements DevFuncActionInterface {
    //global setting
    readonly Enable_ethernet: boolean = AppConfigService.configs.devPage.func.netConfig.element.ethernet;
    readonly Enable_wifi: boolean = AppConfigService.configs.devPage.func.netConfig.element.wifi;

    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._monitorDeviceNames = this._actionData.deviceNames;
            this._bSpecific = this._actionData.bSpecific;
            this._config = this._actionData.configData;
            this._device = this._actionData.deviceList[0];
        }
    }

    actionCompleteHandler: (result: any) => void;
    actionStatusUpdateHandler: (isValid: boolean) => void;
    actionCommunicateHandler: (data: any) => void;

    private _bSpecific: boolean;
    private _monitorDeviceNames: string;
    _config: DeviceNetConfigControl;
    _device: DeviceInfo;
    _refreshAvailableOptionCounter: number = 0;
    _enumNetType: typeof NetworkType = NetworkType;

    constructor() { }

    slideNetConfigBody(): void {
        const b = document.getElementById('netConfigBody');
        if (b) {
            const b_minH = parseInt(b.style.minHeight.replace(/px/, ''));
            if (b.scrollHeight >= b_minH) {
                setTimeout(() => {
                    b.scrollTop = b.scrollHeight - b_minH;
                }, 0);
            }
        }
    }

    refreshSSIDs(): void {
        if (this.actionCommunicateHandler) {
            this._refreshAvailableOptionCounter = REFRESH_DURATION;
            HelperLib.countdown(this._refreshAvailableOptionCounter, 0, (counter: number) => {
                this._refreshAvailableOptionCounter = counter;
            });

            this.actionCommunicateHandler({
                command: 'cmd-refresh-availableOption',
                properties: ['SSIDs'],
                device: this._device
            });
        }
    }
}