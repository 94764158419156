import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { IVirtualDeviceRxData } from '../../VirtualDevice/virtualDevice.common';

export interface IListPolicyDevicePathParameter {
    devicePolicyID: string;
}

export class APIListPolicyDeviceManager extends APIBaseManager<IListPolicyDevicePathParameter, void, void, IVirtualDeviceRxData[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListPolicyDevice';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IListPolicyDevicePathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'devicePolicies/' + pathParameters.devicePolicyID + '/devices';
    }
}