import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { PolicyRawInfo } from './api.policy.common';

export interface IUpdatePolicyPathParameter {
    devicePolicyID: string;
}

export interface IUpdatePolicyTxData {
    policyName?: string;
    policyContent?: any;
}

export class APIUpdatePolicyManager extends APIBaseManager<IUpdatePolicyPathParameter, void, IUpdatePolicyTxData, PolicyRawInfo> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UpdatePolicy';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: IUpdatePolicyPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'devicePolicies/' + pathParameters.devicePolicyID;
    }
}