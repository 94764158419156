import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../../api.wrapper.base';
import { APIBatchUpdateGroupManager } from './api.group.batchUpdate';
import { APICreateGroupManager } from './api.group.create';
import { APIListGroupManager } from './api.group.list';
import { APIListGroupDeviceManager } from './api.group.listDevice';
import { APIRemoveGroupManager } from './api.group.remove';
import { APIUpdateGroupManager } from './api.group.update';
import { APIUpdateGroupDeviceManager } from './api.group.updateDevice';

export class APIDeviceGroupWrapper extends APIWrapperBase {
    CreateGroup: APICreateGroupManager;
    ListGroup: APIListGroupManager;
    ListGroupDevice: APIListGroupDeviceManager;
    UpdateGroup: APIUpdateGroupManager;
    BatchUpdateGroup: APIBatchUpdateGroupManager;
    UpdateGroupDevice: APIUpdateGroupDeviceManager;
    RemoveGroup: APIRemoveGroupManager;

    constructor(private http: HttpClient) {
        super();

        this.CreateGroup = new APICreateGroupManager(http);
        this.ListGroup = new APIListGroupManager(http);
        this.ListGroupDevice = new APIListGroupDeviceManager(http);
        this.UpdateGroup = new APIUpdateGroupManager(http);
        this.BatchUpdateGroup = new APIBatchUpdateGroupManager(http);
        this.UpdateGroupDevice = new APIUpdateGroupDeviceManager(http);
        this.RemoveGroup = new APIRemoveGroupManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.CreateGroup,
            this.ListGroup,
            this.ListGroupDevice,
            this.UpdateGroup,
            this.BatchUpdateGroup,
            this.UpdateGroupDevice,
            this.RemoveGroup
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}