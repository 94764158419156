import { Component } from '@angular/core';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { DLG_NAME_CLEARCACHE, ConfigDialogPage } from '../base/base-config-data';
import { LicenseScopeType } from '../../../../app/content/license/license.data';
import { HelperLib } from '../../../lib/common/helper.lib';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css']
})
export class ClearCacheFormComponent extends BaseConfigFormComponent {
    ngOnInit(): void {
        this._dialogName = DLG_NAME_CLEARCACHE;
        this._licenseScopeType = LicenseScopeType.clearCache;

        super.ngOnInit();
    }

    allowGoBack(): boolean {
        const isAllowBack = super.allowGoBack();
        if (isAllowBack && this._page === ConfigDialogPage.confirm) {
            return false;
        }

        return isAllowBack;
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        let from: ConfigDialogPage = this._page;
        if (from === ConfigDialogPage.checking) {
            from = ConfigDialogPage.action;
        }

        super.goNext(from);
    }

    protected submit(): void {
        super.submit();

        this.devSvc.batchClearCache(this._bSpecific ? this._legalDevices : this._legalDevices.filter(d => d.isSelect)).subscribe(res => {
            this._page++;
            if (res.isFault()) {
                this._errorMessage = HelperLib.getErrorMessage(res);
            }

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler(res);
            }
        });
    }
}