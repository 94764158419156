import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, mergeMap, takeUntil } from 'rxjs/operators';

import { DeviceGroupInfo, DEVICE_GROUP_FUNC_EXCLUDE, DEVICE_GROUP_FUNC_MOVE, GroupSwitch } from './group/group.data';
import { DeviceGroupService } from './group/dev-group.service';
import { HelperLib } from '../../../app/lib/common/helper.lib';
import { DeviceGroupFuncItem, DeviceGroupFuncInterface } from './group/dlg/group-func.def';
import { DeviceGroupFuncService } from './group/dlg/group-func.service';
import { DeviceGroupFuncDirective } from './group/dlg/group-func.directive';
import { DeviceLabelInfo } from './label/dev-label.data';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DeviceService } from './device.service';
import { PolicyService } from '../setting/policy/policy.service';
import { PolicyInfo } from '../setting/policy/policy.data';
import { DeviceAdvFilterOptionInfo } from 'app/uiElement/dev/dev-adv-filter.data';
import { Logger } from 'app/lib/common/logger';
import { DeviceInfo } from './data/device-info';
import { AutoUnsubscribeComponent } from '../virtual/auto-unsubscribe.component';
import { TranslateService } from 'app/translate/translate.service';

enum DeviceFilterType {
  ByGroup = 'ByGroup',
  ByLabel = 'ByLabel'
}

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit {
  _loading: boolean = false;

  _devFilterType: DeviceFilterType = DeviceFilterType.ByGroup;
  _enumDevFilterType: typeof DeviceFilterType = DeviceFilterType;
  _isDeviceLoading: boolean = false;
  // group
  _devGroupRouteList: DeviceGroupInfo[] = [];
  _showGroupTree: boolean = true;
  _groupCtrlAllowHide: boolean = false;
  _devGroupSwitch: GroupSwitch = GroupSwitch.on;
  _enumGroupSwitch: typeof GroupSwitch = GroupSwitch;
  _selectedDeviceGroup: DeviceGroupInfo;
  _proactiveFilter: { onlineStatus?: { [state: string]: boolean }, label?: string };
  _filterRecord: { [type: string]: string[] };
  // label
  _selectedDeviceLabel: DeviceLabelInfo = null;

  _selectedDeviceCount: number = 0;

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    if (HelperLib.isMobileLayout(event.target.innerWidth)) {
      this._showGroupTree = false;
      this._groupCtrlAllowHide = true;
    }
    else {
      this._showGroupTree = true;
      this._groupCtrlAllowHide = false;
    }
  }

  @ViewChild(DeviceGroupFuncDirective, { static: true }) devGroupFuncHost: DeviceGroupFuncDirective;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private devGroupSvc: DeviceGroupService,
    private devGroupFuncSvc: DeviceGroupFuncService,
    private devSvc: DeviceService,
    private policySvc: PolicyService,
    private translateSvc: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this._devGroupSwitch = this.devGroupSvc.groupSwitch;

    this.devGroupSvc.onRouteChanged.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe((routeList: DeviceGroupInfo[]) => {
      this._devGroupRouteList = routeList;
    });

    this.devGroupSvc.onGroupSwitchChanged.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe((groupSwitch: GroupSwitch) => {
      this._devGroupSwitch = groupSwitch;
    });

    this.devSvc.deviceFilterApplied.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe((res: { isApplied: boolean, devices?: DeviceInfo[], sourceFilters?: { rules?: string[], labels?: string[], onlineStatus?: { [state: string]: boolean }, search?: { key: string, value: string, langKey?: string } } }) => {
      if (res.isApplied && res.sourceFilters) {
        if (res.sourceFilters.rules) {
          const advRulesExceptGroupAndPolicy: number = res.sourceFilters.rules.filter(rule => !rule.startsWith('[#DevicePolicy') && !rule.startsWith('[#DeviceGroup') && rule.startsWith('[#Ticket')).length;
          if (advRulesExceptGroupAndPolicy > 0) {
            this.addFilterRecord('Advance filter', `${advRulesExceptGroupAndPolicy} filter(s) applied`);
          }
          else {
            this.removeFilterRecord('Advance filter');
          }
        }
        if (res.sourceFilters.onlineStatus) {
          const onlineStatusState = HelperLib.getOnlineStatusState(res.sourceFilters.onlineStatus);
          if (onlineStatusState.isChanged) {
            this.addFilterRecord('Online status', ...Object.keys(onlineStatusState.onlineStatusIndicator).map(key => `${key}: ${onlineStatusState.onlineStatusIndicator[key]}`));
          }
          else {
            this.removeFilterRecord('Online status');
          }
        }
        if (res.sourceFilters.search?.value) {
          this.addFilterRecord('Search', `"${this.translateSvc.instant(res.sourceFilters.search.langKey)}" includes "${res.sourceFilters.search.value}"`);
        }
        else {
          this.removeFilterRecord('Search');
        }
      }
      else {
        this._filterRecord = null;
      }
    });

    this._loading = true;
    HelperLib.checkState(1, () => { return this.devGroupSvc.isReady }, () => {
      this.route.queryParamMap.pipe().pipe(
        takeUntil(this._unsubscribe$)
      ).subscribe((params: ParamMap) => {
        const targetDevGroupID: string = params.get('group');
        const targetDevPolicyID: string = params.get('policy');
        const targetDevPolicyState: string = params.get('policyState');
        const devActivityID: string = params.get('devActivity');
        const devActivityState: string = params.get('devActivityState');

        Logger.logInfo('dashboard', 'init', `query params: group id: ${targetDevGroupID}, policy id: (${targetDevPolicyID}, ${targetDevPolicyState}), dev activity: (${devActivityID}, ${devActivityState})`);

        if (targetDevGroupID) {
          const targetDevGroup: DeviceGroupInfo = this.devGroupSvc.getGroupByID(targetDevGroupID);
          if (targetDevGroup) {
            this.updateSelectedDeviceGroup(targetDevGroup);
          }
        }
        else {
          this.updateSelectedDeviceGroup(null);
        }

        if (targetDevGroupID || (targetDevPolicyID && targetDevPolicyState) || devActivityID) {
          this.doAdvanceFilterSearch({
            deviceGroup: { groupID: targetDevGroupID },
            devicePolicy: { policyID: targetDevPolicyID, policyState: targetDevPolicyState },
            deviceActivity: { activityID: devActivityID, activityState: devActivityState }
          });
        }

        // remove the route parameter
        this.router.navigate([], { queryParams: {}, replaceUrl: true, relativeTo: this.route });
      });

      this._devGroupRouteList = this.devGroupSvc.getHomeGroupRoute();
      this._loading = false;
    });
  }

  ngAfterViewInit(): void {
    if (window.innerWidth < 992) {
      this._groupCtrlAllowHide = true;
      this._showGroupTree = false;
    }
  }

  selectDeviceFilterType(filterType: DeviceFilterType): void {
    // change between dev group <--> dev label
    if (this._devFilterType !== filterType) {
      this._devFilterType = filterType;

      switch (this._devFilterType) {
        case DeviceFilterType.ByLabel:
          {
            this.inspectGroup(this.devGroupSvc.getHomeGroup(), true);
          }
          break;
        case DeviceFilterType.ByGroup:
          {
            this._selectedDeviceLabel = null;
          }
          break;
      }

      this.devSvc.resetAdvanceFilter();
    }
  }

  inspectGroup(g: DeviceGroupInfo, expand: boolean = false): void {
    this.devGroupSvc.inspectGroup(null, g, expand);
    this.updateSelectedDeviceGroup(g);
  }

  turnOnOffSidebar(target?: GroupSwitch): void {
    this._devGroupSwitch = target || (this._devGroupSwitch === GroupSwitch.on ? GroupSwitch.off : GroupSwitch.on);

    switch (this._devFilterType) {
      case DeviceFilterType.ByLabel:
        {
          this._selectedDeviceLabel = null;
        }
        break;
      case DeviceFilterType.ByGroup:
        {
          this.devGroupSvc.turnOnOffGroup(this._devGroupSwitch);
        }
        break;
    }
  }

  changeDeviceGroup(): void {
    this.playGroupFunc(DEVICE_GROUP_FUNC_MOVE);
  }

  removeDeviceFromGroup(): void {
    this.playGroupFunc(DEVICE_GROUP_FUNC_EXCLUDE);
  }

  clearFilters(): void {
    this.devSvc.resetAdvanceFilter();
    this._filterRecord = null;
  }

  onDeviceGroupInspect(ev: { devGroup: DeviceGroupInfo, filter?: { onlineStatus: { [state: string]: boolean } } }): void {
    Logger.logInfo('dashboard', 'onDeviceGroupInspect', 'ev: ', ev);

    this.updateSelectedDeviceGroup(ev.devGroup);

    if (this.devSvc.isAdvanceFilterApplied) {
      this.addFilterRecord('Device group', `Group name = ${ev.devGroup.name}`);
    }

    if (ev.filter?.onlineStatus) {
      this.devSvc.getDevicesByFilter({ onlineStatus: ev.filter?.onlineStatus, devGroup: { id: ev.devGroup.id, switch: this._devGroupSwitch } }).subscribe((res: { isFault: boolean, devices?: DeviceInfo[], errorMessage?: string }) => {
        this.addFilterRecord('Device group', `Group name = ${ev.devGroup.name}`);

        const onlineStatusState = HelperLib.getOnlineStatusState(ev.filter.onlineStatus);
        if (onlineStatusState.isChanged) {
          this.addFilterRecord('Online status', ...Object.keys(onlineStatusState.onlineStatusIndicator).map(key => `${key}: ${onlineStatusState.onlineStatusIndicator[key]}`));
        }
      });
    }
  }

  onDeviceLabelSelected(ev: { label: DeviceLabelInfo, filter?: { onlineStatus: { [state: string]: boolean } } }): void {
    Logger.logInfo('dashboard', 'onDeviceLabelSelected', 'ev: ', ev);

    this._selectedDeviceLabel = ev.label;
    this._proactiveFilter = { label: ev.label?.name };

    if (this.devSvc.isAdvanceFilterApplied && ev.label) {
      this.addFilterRecord('Device label', `Label name = ${this._selectedDeviceLabel?.name || 'Not select'}`);
    }

    if (ev.label && ev.filter?.onlineStatus) {
      this.devSvc.getDevicesByFilter({ onlineStatus: ev.filter?.onlineStatus, labels: [ev.label?.name] }).subscribe((res: { isFault: boolean, devices?: DeviceInfo[], errorMessage?: string }) => {
        this.addFilterRecord('Device label', `Label name = ${this._selectedDeviceLabel.name}`);

        const onlineStatusState = HelperLib.getOnlineStatusState(ev.filter.onlineStatus);
        if (onlineStatusState.isChanged) {
          this.addFilterRecord('Online status', ...Object.keys(onlineStatusState.onlineStatusIndicator).map(key => `${key}: ${onlineStatusState.onlineStatusIndicator[key]}`));
        }
      });
    }
  }

  onDeviceSelectionChange(selectedDeviceAmount: number): void {
    this._selectedDeviceCount = selectedDeviceAmount;
  }

  onDeviceLoadingStatusChange(isLoading: boolean): void {
    this._isDeviceLoading = isLoading;
  }

  playGroupFunc(funcName: string, g?: DeviceGroupInfo): void {
    const item: DeviceGroupFuncItem = this.devGroupFuncSvc.getFunctionByName(funcName);
    if (!item) {
      return;
    }

    const viewContainerRef = this.devGroupFuncHost.viewContainerRef;
    if (!viewContainerRef) {
      return;
    }

    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(item.component);
    (<DeviceGroupFuncInterface>componentRef.instance).title = item.title;
    (<DeviceGroupFuncInterface>componentRef.instance).group = g || this.devGroupSvc.getActiveGroup();
  }

  onDeviceDetailGroupSearchFilterRequest(ev: { deviceGroup?: { groupID: string }, devicePolicy?: { policyID: string, policyState: string } }): void {
    this.doAdvanceFilterSearch(ev);
  }

  private doAdvanceFilterSearch(searchOptions: {
    deviceGroup?: { groupID: string },
    devicePolicy?: { policyID: string, policyState: string },
    deviceActivity?: { activityID: string, activityState: string }
  }): void {
    of(searchOptions.devicePolicy).pipe(
      concatMap((devicePolicy: { policyID: string, policyState: string }) => devicePolicy?.policyID ? this.policySvc.getPolicyList() : of([])),
      mergeMap((policies: PolicyInfo[]) => {
        let searchByPolicy$: Observable<Partial<DeviceAdvFilterOptionInfo>> = of(null);
        let searchByDevGroup$: Observable<Partial<DeviceAdvFilterOptionInfo>> = of(null);
        let searchByDevActivity$: Observable<Partial<DeviceAdvFilterOptionInfo>> = of(null);

        const policy: PolicyInfo = policies.find(p => p.id === searchOptions.devicePolicy?.policyID);
        if (policy) {
          this.addFilterRecord('Device policy', `Policy name = ${policy.name}`, `Sync status = Not synced`);
          searchByPolicy$ = this.devSvc.getAdvanceFilterOptionsByPolicy(policy, 'Not Synced');
        }

        if (searchOptions.deviceGroup?.groupID) {
          const targetDevGroup: DeviceGroupInfo = this.devGroupSvc.getGroupByID(searchOptions.deviceGroup?.groupID);
          if (targetDevGroup) {
            this.addFilterRecord('Device group', `Group name = ${targetDevGroup.name}`);
          }

          searchByDevGroup$ = this.devSvc.getAdvanceFilterOptionByDeviceGroupID(searchOptions.deviceGroup?.groupID);
        }

        if (searchOptions.deviceActivity?.activityID) {
          this.addFilterRecord('Device activity', `Activity ID = ${searchOptions.deviceActivity.activityID}`, searchOptions.deviceActivity.activityState ? `Activity status = ${searchOptions.deviceActivity.activityState}` : '');
          searchByDevActivity$ = of({
            value: `[Ticket:\"${searchOptions.deviceActivity.activityID}${searchOptions.deviceActivity.activityState ? ':' + searchOptions.deviceActivity.activityState : ''}\"]`
          });
        }

        return forkJoin([searchByPolicy$, searchByDevGroup$, searchByDevActivity$])
      }),
      concatMap((res: Partial<DeviceAdvFilterOptionInfo>[]) => this.devSvc.getDevicesByFilter({ rules: res.filter(cmd => cmd).map(cmd => cmd.value) }))
    ).subscribe();
  }

  private addFilterRecord(type: string, ...desc: string[]): void {
    this._filterRecord = this._filterRecord || {};
    this._filterRecord[type] = [...desc.filter(d => d)];
  }

  private removeFilterRecord(type: string): void {
    if (this._filterRecord?.[type]) {
      delete this._filterRecord[type];
    }
  }

  private updateSelectedDeviceGroup(g: DeviceGroupInfo): void {
    this._selectedDeviceGroup = g?.id === this.devGroupSvc.getHomeGroup().id ? null : g;
  }
}