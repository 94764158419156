import { Component } from '@angular/core';
import { IPolicyTypeFunc } from './policy-type.service';
import { PolicyType, PolicyDataAppManagement } from '../policy.data';

@Component({
    templateUrl: './policy-appmgr.component.html',
    styleUrls: ['../policy.style.css']
})
export class PolicyTypeAppManagementComponent implements IPolicyTypeFunc {
    onPolicyDataValidate?: (valid: boolean) => void;
    type: PolicyType;
    _data: PolicyDataAppManagement;
    set data(d: PolicyDataAppManagement) {
        this._data = d;
    }
    isInEdit: boolean;

    onAppDataValidChange(valid: boolean): void {
        this.onPolicyDataValidate(valid);
    }
}