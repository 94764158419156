import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

@Component({
    selector: 'na-colorPicker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.css']
})
export class ColorPickerComponent implements OnChanges {
    _isColorChanged: boolean = false;
    _isOpacityChanged: boolean = false;
    _opacity: number = 1; // 0 - 1
    _pureColor: string = '#000000ff'; // color = 6 hex digits
    _combinedColor: string = '#000000ff'; // color + opacity = 8 hex digits = rgba(r,g,b,a)

    @Input('disabled') _disabled: boolean = false;
    @Input('color') color: string;
    @Input('isChanged') _isChanged: boolean = false;

    @Output() onColorChanged = new EventEmitter<string>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['color']) {
            this._pureColor = changes['color'].currentValue.slice(0, 7);
            this._opacity = this.extractAlphaValue(changes['color'].currentValue);
            this._combinedColor = this.hexToRgba(this._pureColor, this._opacity);
        }
    }

    onOpacityUpdated(opacity: number): void {
        this._opacity = opacity;
        this._combinedColor = this.hexToRgba(this._pureColor, this._opacity);
        this.onColorChanged.emit(this.combineColorAndOpacity(this._pureColor, this._opacity));
    }

    onColorUpdated(color: string): void {
        this._pureColor = color;
        this._combinedColor = this.hexToRgba(this._pureColor, this._opacity);
        this.onColorChanged.emit(this.combineColorAndOpacity(this._pureColor, this._opacity));
    }

    private toFixedNumber(n: number, decimalPoint: number): number {
        return parseFloat(n.toFixed(decimalPoint));
    }

    private combineColorAndOpacity(color: string, opacity: number): string {
        return color + this.opacityToHex(opacity);
    }

    private opacityToHex(opIn: number): string {
        opIn = opIn * 2.55;
        opIn = Math.round(opIn);
        return opIn.toString(16);
    }

    private hexToRgba(hex: string, alpha?: number): string {
        const r: number = parseInt(hex.slice(1, 3), 16);
        const g: number = parseInt(hex.slice(3, 5), 16);
        const b: number = parseInt(hex.slice(5, 7), 16);
        let a: number = 1;
        if (alpha !== undefined) {
            a = alpha > 100 ? 100 : alpha;
            a = alpha / 100;
        }
        else {
            a = this.extractAlphaValue(hex);
        }

        return `rgba(${r}, ${g}, ${b}, ${a})`;
    }

    private extractAlphaValue(color: string, defaultHexAlphaValue = 'ff'): number {
        return Math.round(parseInt((color.slice(7, 9) || defaultHexAlphaValue), 16) * 100 / 255);
    }
}