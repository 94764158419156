<div class="label-picker">
    <div class="input-group input-group-sm mb-1">
        <input #search type='text' class="form-control form-control-sm search-box" [class.active]="_filter?.searchContent" [disabled]="_loading" name='search'
            placeholder="Search device label ..." aria-label="Search device label">
        <div class="input-group-append">
            <span class="input-group-text">
                <i class="fas fa-search"></i>
            </span>
        </div>
    </div>
    <div class="labels" [class.active]="_displayLabels.length !== _labelMap.size">
        <div class="title" [class.active]="!_selectedLabel" (click)="selectLabel(null)">
            <i class="fas fa-caret-down mr-1"></i>
            Label 
            <small *ngIf="_filter.searchContent">
                <i class="fas fa-filter mx-1"></i>{{ _displayLabels.length }} / {{ _labelMap.size }}
            </small>
        </div>
        <div class="ml-3">
            <div *ngFor="let label of _displayLabels" class="label-item" [class.active]="_selectedLabel?.id === label.id"
                (click)="selectLabel(label)">
                <i class="fas fa-caret-right mr-1"></i>
                <span class="name mr-1">{{ label.name }}</span> 
                <span>
                    <span class="online-dev-link" (click)="searchOnlineDeviceWithLabel(label); $event.stopPropagation()">
                        ({{ getOnlineDevices(label.deviceList).length }}
                    </span>
                    / {{ label.deviceList.length }})
                </span>
            </div>
        </div>
    </div>
</div>