import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as _semver from 'semver';
import { Observable, of } from 'rxjs';
import { switchMap, map, takeUntil, catchError, delay } from 'rxjs/operators';

import { DeviceService } from '../device.service';
import { NetworkType } from '../data/device-net-info';
import { DeviceInfo, DeviceType, IPolicyLockMap, OnlineStatus, TaskStatus } from '../data/device-info';
import { AccountService } from '../../../entry/account.service';
import { LicenseScopeType, ScopeFunctionInfo } from '../../license/license.data';
import { HelperLib, REFRESH_DURATION } from '../../../lib/common/helper.lib';
import { DevFuncDirective } from '../../devfunc/dev-func.directive';
import { DevFuncService } from '../../devfunc/dev-func.service';
import { DevFuncItem } from '../../devfunc/dev-func-item';
import { DevFuncInterface } from '../../devfunc/dev-func.interface';
import { LicenseService } from '../../license/license.service';
import { LicenseCategory } from '../../license/license.data';
import { LICENSE_SCOPE_FUNCTION_MAP } from '../../license/license-scope-map';
import { ILicenseCategoryInfo } from '../../../API/v1/License/api.license.common';
import { CustomResponse, IClass } from '../../../lib/common/common.data';
import { Logger } from '../../../lib/common/logger';
import { APKService, APKUpdateInfo } from '../../devfunc/apk/apk.service';
import { AppConfigService } from '../../../app.config';
import { ConstantService } from '../../../lib/common/constant.service';
import { IVirtualDeviceRxDataSecurity } from '../../../API/v1/VirtualDevice/virtualDevice.common';
import { DeviceLabelFuncDirective } from '../label/dlg/dev-label-func.directive';
import { DEVICE_LABEL_FUNC_ATTACH, DeviceLabelFuncItem, IDeviceLabelFunc } from '../label/dlg/dev-label-func.def';
import { DeviceLabelInfo } from '../label/dev-label.data';
import { DeviceLabelFuncService } from '../label/dlg/dev-label-func.service';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';
import { environment } from 'environments/environment';

@Component({
    selector: 'nt-device-detail',
    templateUrl: './device-detail.component.html',
    styleUrls: ['./device-detail.component.css']
})
export class DeviceDetailComponent extends AutoUnsubscribeComponent implements OnInit, IClass {
    className: string;

    _dev: DeviceInfo;
    _devType: DeviceType = DeviceType.and;
    _lockMap: IPolicyLockMap = {};
    _errorMessage: string = '';
    _showActivityDlg: boolean = false;

    // if user supports the function; if not, hide the function
    // if device supports the function; if not, disable the function
    _funcSupportMap: { [funcName: string]: { userSupport: boolean, licenseSupport: boolean, countdown: number } } = {};

    private _licenseInfo: {
        iCareLicense: ILicenseCategoryInfo,
        other: { [category: string]: ILicenseCategoryInfo }
        scopes: string[]
    };
    _licenseScopeMap: { [scopeType: string]: ScopeFunctionInfo } = {};

    _loading: boolean = false;
    _loadingLicenses: boolean = false;

    _enumOnlineStatus: typeof OnlineStatus = OnlineStatus;
    _currentNetworkType: NetworkType = NetworkType.None;
    _enumLicenseScopeType: typeof LicenseScopeType = LicenseScopeType;

    _hasPendingTask: boolean = false;

    // Action control
    _rebootCounter: number = 0;
    _firmwareCounter: number = 0;
    _basicConfigCounter: number = 0;
    _netConfigCounter: number = 0;
    _securityCounter: number = 0;

    private _apkInfo: { version?: string, link?: string, md5?: string, category?: string, needUpdate: boolean } = { needUpdate: false };
    private _bAPKUpdating: boolean = false;

    @ViewChild(DevFuncDirective, { static: true }) devFuncHost: DevFuncDirective;
    @ViewChild(DeviceLabelFuncDirective) private _devLabelFuncHost: DeviceLabelFuncDirective;

    private _btnCloseActivityRef: ElementRef;
    @ViewChild('activityCloseBtn')
    set activityCloseBtn(holder: ElementRef) {
        if (holder) {
            this._btnCloseActivityRef = holder;
        }
    }

    private dlgCloseElementRef: ElementRef;
    @ViewChild('dlgClose')
    set dlgClose(holder: ElementRef) {
        if (holder) {
            this.dlgCloseElementRef = holder;
        }
    }

    constructor(
        private route: ActivatedRoute,
        private constantSvc: ConstantService,
        private devSvc: DeviceService,
        private accountSvc: AccountService,
        private licenseSvc: LicenseService,
        private devFuncSvc: DevFuncService,
        private apkSvc: APKService,
        private devLabelFuncSvc: DeviceLabelFuncService
    ) {
        super();
        this.className = 'Device-detail-page';
    }

    ngOnInit(): void {
        this.accountSvc.loginChanged.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((isLogin: boolean) => {
            if (!isLogin) {
                if (this.dlgCloseElementRef && this.dlgCloseElementRef.nativeElement) {
                    this.dlgCloseElementRef.nativeElement.click();
                }
            }
        });

        this.devSvc.deviceTaskUpdated.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((dev: DeviceInfo) => {
            if (this._dev && dev.virtualId === this._dev.virtualId) {
                this.update_pending_status();
            }
        });

        this._loadingLicenses = true;
        this._loading = true;

        this.route.paramMap.pipe(
            switchMap((params: ParamMap) => this.update(params.get('id')))
        ).subscribe((err: any) => {
            this._errorMessage = err;
            this._loadingLicenses = false;
            this._loading = false;
        });

        this.devSvc.devicesChanged.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((devices: DeviceInfo[]) => {
            if (this._dev && devices.length > 0) {
                const found: DeviceInfo = devices.find(d => d.virtualId === this._dev.virtualId);
                if (found) {
                    this._dev = found;
                }
            }
        });
    }

    get supportReboot(): boolean {
        return AppConfigService.configs.devPage.func.enableReboot && this.accountSvc.hasScope_task_reboot() && environment.deviceFuncMap[this._devType]?.enableReboot;
    }

    get isRebootValid(): boolean {
        return this.isActionValid({ checkOnlineState: true, delayCounter: this._rebootCounter, licenseScope: LicenseScopeType.taskConfig });
    }

    get supportBasicConfig(): boolean {
        return AppConfigService.configs.devPage.func.basicConfig.enableSingleConfig && this.accountSvc.hasScope_task_basicSetting() && environment.deviceFuncMap[this._devType]?.basicConfig?.enableSingleConfig;
    }

    get isBasicConfigValid(): boolean {
        return this.isActionValid({ checkOnlineState: true, delayCounter: this._basicConfigCounter, licenseScope: LicenseScopeType.taskConfig });
    }

    get supportTroubleshoot(): boolean {
        return AppConfigService.configs.devPage.func.enableTroubleshoot && this.accountSvc.hasScope_task_troubleshoot() && environment.deviceFuncMap[this._devType]?.enableTroubleshoot;
    }

    get isTroubleshootValid(): boolean {
        return this.isActionValid({ checkOnlineState: false, licenseScope: LicenseScopeType.remoteAssistance });
    }

    get supportFwUpdate(): boolean {
        return AppConfigService.configs.devPage.func.enableFwUpdate && this.accountSvc.hasScope_task_firmwareUpdate() && environment.deviceFuncMap[this._devType]?.enableFwUpdate;
    }

    get isFirmwareUpdateValid(): boolean {
        return this.isActionValid({ checkOnlineState: true, delayCounter: this._firmwareCounter, licenseScope: LicenseScopeType.firmwareUpdate });
    }

    get supportLock(): boolean {
        return AppConfigService.configs.devPage.func.enableLock && this.accountSvc.hasScope_task_reboot() && environment.deviceFuncMap[this._devType]?.enableLock;
    }

    get supportUnlock(): boolean {
        return AppConfigService.configs.devPage.func.enableUnlock && this.accountSvc.hasScope_task_reboot() && environment.deviceFuncMap[this._devType]?.enableUnlock;
    }

    get supportCAScript(): boolean {
        return AppConfigService.configs.devPage.func.enableCAScript && this.accountSvc.hasScope_task_reboot() && environment.deviceFuncMap[this._devType]?.enableCAScript;
    }

    get isLockValid(): boolean {
        return this.isActionValid({ checkOnlineState: true, licenseScope: LicenseScopeType.taskConfig }) && !this._dev?.currentSettings[this.constantSvc.DEVKEY_INFO_SYSTEM_ISLOCK];
    }

    get isUnlockValid(): boolean {
        return this.isActionValid({ checkOnlineState: true, licenseScope: LicenseScopeType.taskConfig }) && this._dev?.currentSettings[this.constantSvc.DEVKEY_INFO_SYSTEM_ISLOCK];
    }

    get isCAScriptValid(): boolean {
        return this.isActionValid({ checkOnlineState: true, licenseScope: LicenseScopeType.taskConfig });
    }

    get supportNetConfig(): boolean {
        return AppConfigService.configs.devPage.func.netConfig.enableSingleConfig && this.accountSvc.hasScope_task_netSetting() && environment.deviceFuncMap[this._devType]?.netConfig?.enableSingleConfig;
    }

    get isNetConfigValid(): boolean {
        return this.isActionValid({ checkOnlineState: true, delayCounter: this._netConfigCounter, licenseScope: LicenseScopeType.taskConfig });
    }

    get supportAPKUpdate(): boolean {
        return AppConfigService.configs.devPage.func.enableApkUpdate && this.accountSvc.hasScope_task_iadeaCareApkUpdate() && this._apkInfo?.needUpdate && environment.deviceFuncMap[this._devType]?.enableApkUpdate;
    }

    get isAPKUpdateValid(): boolean {
        return this.isActionValid({ checkOnlineState: true, updating: this._bAPKUpdating, licenseScope: LicenseScopeType.firmwareUpdate });
    }

    get supportDeviceSecurity(): boolean {
        return AppConfigService.configs.devPage.func.enableSecurity && this.accountSvc.hasScope_task_securitySetting() && environment.deviceFuncMap[this._devType]?.enableSecurity;
    }

    get isDeviceSecurityValid(): boolean {
        return this.isActionValid({ checkOnlineState: true, delayCounter: this._securityCounter, licenseScope: LicenseScopeType.taskConfig });
    }

    get supportDeviceAlertUpdate(): boolean {
        return AppConfigService.configs.devPage.func.enableAlert && this.accountSvc.hasScope_alertSetting_update() && environment.deviceFuncMap[this._devType]?.enableAlert;
    }

    get isDeviceAlertUpdateValid(): boolean {
        return this.isActionValid({ checkOnlineState: false, licenseScope: LicenseScopeType.alertEmail });
    }

    get supportOTP(): boolean {
        if (this._lockMap[this.constantSvc.DEVKEY_SECURITY_USER_ADMIN]) {
            return true;
        }

        if (this._dev.currentSettings[this.constantSvc.DEVKEY_SECURITY_USER_ADMIN]) {
            const security: IVirtualDeviceRxDataSecurity = this._dev.currentSettings[this.constantSvc.DEVKEY_SECURITY_USER_ADMIN] as IVirtualDeviceRxDataSecurity;
            if (security.users && security.users.admin && security.users.admin.auth && security.users.admin.auth.type === 'totp') {
                return true;
            }
        }

        return false;
    }

    get isOTPValid(): boolean {
        return this.isActionValid({ checkOnlineState: false, licenseScope: LicenseScopeType.devicePolicy });
    }

    get supportViewDeviceActivity(): boolean {
        return AppConfigService.configs.devPage.func.enableUserActivity && this.accountSvc.hasScope_deviceActivity_view() && environment.deviceFuncMap[this._devType]?.enableUserActivity;
    }

    get isViewDeviceActivityValid(): boolean {
        return this.isActionValid({ checkOnlineState: false, licenseScope: LicenseScopeType.activityHistory });
    }

    get supportViewLicense(): boolean {
        return AppConfigService.configs.licensePage.enabled && this.accountSvc.hasScope_license_view();
    }

    get supportAddLicense(): boolean {
        if (!AppConfigService.configs.licensePage.element.enabledAddLicense || !AppConfigService.configs.licensePage.element.enabledReallocateLicense || !this.accountSvc.hasScope_license_assign()) {
            return false;
        }

        if (!this.accountSvc.isEnterprise() && this._dev.virtualDeviceOwnerID !== this.accountSvc.accountID) {
            return false;
        }

        return true;
    }

    private isFunctionAvailable(checkOnlineStatus: boolean = false): boolean {
        if (!this._dev) {
            return false;
        }

        if (!this._licenseScopeMap) {
            return false;
        }

        if (checkOnlineStatus) {
            if (this._dev.onlineStatus !== OnlineStatus.Online) {
                return false;
            }
        }

        return true;
    }

    private isActionValid(options: { checkOnlineState?: boolean, delayCounter?: number, updating?: boolean, licenseScope?: LicenseScopeType }): boolean {
        if (!this.isFunctionAvailable(options.checkOnlineState)) {
            return false;
        }

        if (options.delayCounter !== undefined && options.delayCounter > 0) {
            return false;
        }

        if (options.updating) {
            return false;
        }

        if (options.licenseScope !== undefined && !this._licenseScopeMap[options.licenseScope]) {
            return false;
        }

        return true;
    }

    private onActivityDlgClosed(): void {
        this._showActivityDlg = false;
        if (this._btnCloseActivityRef) {
            this._btnCloseActivityRef.nativeElement.click();
        }
    }

    showActivityDlg(): void {
        this._showActivityDlg = true;
    }

    private update_pending_status(): void {
        this._hasPendingTask = this._dev.taskInfos && this._dev.taskInfos.filter(a => a.status === TaskStatus.pending || a.status === TaskStatus.progress).length > 0 ? true : false;
    }

    private submitFirmwareUpdateComplete(result: CustomResponse<any>): void {
        Logger.logInfo(this.className, 'submitFrimwareUpdateComplete', 'Data = ', result);

        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }

        this._firmwareCounter = REFRESH_DURATION;
        HelperLib.countdown(this._firmwareCounter, 0, (counter: number) => {
            this._firmwareCounter = counter;
        });
    }

    private submitRebootComplete(result: CustomResponse<any>): void {
        Logger.logInfo(this.className, 'submitRebootComplete', 'Data = ', result);

        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }

        this._rebootCounter = REFRESH_DURATION;
        HelperLib.countdown(this._rebootCounter, 0, (counter: number) => {
            this._rebootCounter = counter;
        });
    }

    private submitDeviceConfigComplete(result: CustomResponse<any>): void {
        Logger.logInfo(this.className, 'submitDeviceConfigComplete', 'Data = ', result);

        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }

        this._basicConfigCounter = REFRESH_DURATION;
        HelperLib.countdown(this._basicConfigCounter, 0, (counter: number) => {
            this._basicConfigCounter = counter;
        });
    }

    private submitNetConfigComplete(result: CustomResponse<any>): void {
        Logger.logInfo(this.className, 'submitNetConfigComplete', 'Data = ', result);

        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }

        this._netConfigCounter = REFRESH_DURATION;
        HelperLib.countdown(this._netConfigCounter, 0, (counter: number) => {
            this._netConfigCounter = counter;
        });
    }

    private submitSecurityComplete(result: CustomResponse<any>): void {
        Logger.logInfo(this.className, 'submitSecurityComplete', 'Data = ', result);

        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }

        this._securityCounter = REFRESH_DURATION;
        HelperLib.countdown(this._securityCounter, 0, (counter: number) => {
            this._securityCounter = counter;
        });
    }

    private netConfigInteract(result: CustomResponse<any>): void {
        Logger.logInfo(this.className, 'netConfigInteract', 'Data = ', result);

        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }
    }

    private onScreenshotTaken(result: CustomResponse<any>): void {
        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }
    }

    refreshPlayer(): void {
        this._loadingLicenses = true;
        this._loading = true;
        this._errorMessage = null;

        this.update(this._dev.virtualId, false, true).subscribe(err => {
            this._errorMessage = err;
            this._loadingLicenses = false;
            this._loading = false;
        });
    }

    private update(virtualDeviceID: string, autoCheck: boolean = true, force: boolean = false): Observable<any> {
        return this.devSvc.getDeviceByID(virtualDeviceID, autoCheck, autoCheck, force, force).pipe(
            switchMap((res: CustomResponse<DeviceInfo>) => {
                if (res.isFault()) {
                    throw res.errorMessage;
                }

                this._dev = res.data;
                // check device type: android, win, linux
                this._devType = HelperLib.getDeviceType(this._dev.currentSettings[this.constantSvc.DEVKEY_INFO_FW_FAMILY]);
                Logger.logInfo(this.className, 'update', `dev type = ${this._devType}`);

                this._currentNetworkType = this._dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ?
                    NetworkType.Ethernet :
                    (this._dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] ? NetworkType.Wifi : NetworkType.None);

                if (this._dev && this._dev.currentSettings) {
                    // do apk update
                    this.apkSvc.getLatestAPKUpdateInfo(this._dev.currentSettings[this.constantSvc.DEVKEY_INFO_APP] ? this._dev.currentSettings[this.constantSvc.DEVKEY_INFO_APP].category : null, force).subscribe((res: { isFault: boolean, info?: APKUpdateInfo, errorMessage?: string }) => {
                        if (res && !res.isFault && res.info) {
                            this._apkInfo.version = res.info.version;
                            this._apkInfo.link = res.info.link;
                            this._apkInfo.md5 = res.info.md5;
                            this._apkInfo.category = res.info.category;

                            if (res.info.version && this._dev.currentSettings[this.constantSvc.DEVKEY_INFO_APKVERSION] && _semver.lt(this._dev.currentSettings[this.constantSvc.DEVKEY_INFO_APKVERSION], res.info.version, true)) {
                                this._apkInfo.needUpdate = true;
                            }
                        }
                    });
                }

                return this.devSvc.updateShadowDevice(this._dev, force);
            }),
            switchMap((res: { isFault: boolean, errorMessage?: string, data: IPolicyLockMap }) => {
                if (!res.isFault) {
                    this._lockMap = res.data;
                    Logger.logInfo(this.className, 'update', 'Policy lock map = ', this._lockMap);
                }

                return this.devSvc.updateCalendar(this._dev, force);
            }),
            switchMap(() => this.devSvc.updateWarranty(this._dev, force)),
            switchMap(() => this.licenseSvc.getLicenseByDevice(this._dev.virtualId, force).pipe(
                map((res: { scopes: string[], licenses: { [category: string]: ILicenseCategoryInfo } }) => {
                    if (res) {
                        this._licenseInfo = {
                            iCareLicense: res.licenses?.[LicenseCategory.ICare],
                            other: Object.keys(res.licenses).filter((category: string) => category !== LicenseCategory.ICare).reduce((prev, curr) => {
                                prev[curr] = res.licenses[curr];
                                return prev;
                            }, {}),
                            scopes: res.scopes
                        }

                        this._licenseInfo.scopes.forEach((scope: string) => {
                            if (LICENSE_SCOPE_FUNCTION_MAP[scope]) {
                                this._licenseScopeMap[LICENSE_SCOPE_FUNCTION_MAP[scope].type] = LICENSE_SCOPE_FUNCTION_MAP[scope]
                            }
                        });
                    }

                    Logger.logInfo(this.className, 'update', 'Available license scope =  ', this._licenseScopeMap);
                    this._loadingLicenses = false;

                    return null;
                })
            )),
            map(() => {
                Logger.logInfo(this.className, 'update', 'Device = ', this._dev);
                return null;
            }),
            catchError((err) => of(err))
        );
    }

    syncDevice(): void {
        this.devSvc.batchSyncCurrentSetting([this._dev]).subscribe();
    }

    lockDevice(lock: boolean): void {
        this.devSvc.batchDoCustomAction([this._dev], { actionID: lock ? 'System.Lock' : 'System.Unlock', subject: lock ? 'Lock' : 'Unlock' }).subscribe();
    }

    private playDevFunc(funcName: string): void {
        const devFuncItem: DevFuncItem = this.devFuncSvc.getFunctionByName(funcName);
        if (devFuncItem) {
            const viewContainerRef = this.devFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(devFuncItem.component);

            (<DevFuncInterface>componentRef.instance).minWidth = 1000;
            (<DevFuncInterface>componentRef.instance).title = devFuncItem.title;
            (<DevFuncInterface>componentRef.instance).devices = this._dev;
            (<DevFuncInterface>componentRef.instance).lockMap = this._lockMap;
            switch (funcName) {
                case this.devFuncSvc.FUNCNAME_REBOOT:
                    {
                        (<DevFuncInterface>componentRef.instance).dialogCompleteHandler = this.submitRebootComplete.bind(this);
                    }
                    break;
                case this.devFuncSvc.FUNCNAME_FIRMWARE:
                    {
                        (<DevFuncInterface>componentRef.instance).dialogCompleteHandler = this.submitFirmwareUpdateComplete.bind(this);
                    }
                    break;
                case this.devFuncSvc.FUNCNAME_BASIC_CONFIG:
                    {
                        (<DevFuncInterface>componentRef.instance).dialogCompleteHandler = this.submitDeviceConfigComplete.bind(this);
                    }
                    break;
                case this.devFuncSvc.FUNCNAME_NET_CONFIG:
                    {
                        (<DevFuncInterface>componentRef.instance).dialogCompleteHandler = this.submitNetConfigComplete.bind(this);
                        (<DevFuncInterface>componentRef.instance).dialogInteractHandler = this.netConfigInteract.bind(this);
                    }
                    break;
                case this.devFuncSvc.FUNCNAME_SECURITY:
                    {
                        (<DevFuncInterface>componentRef.instance).dialogCompleteHandler = this.submitSecurityComplete.bind(this);
                    }
            }
        }
    }

    addDeviceLabel(): void {
        this.createDevLabelFuncDlg<{ devices: DeviceInfo[] }>(DEVICE_LABEL_FUNC_ATTACH, null, { devices: [this._dev] });
    }

    private createDevLabelFuncDlg<T>(funcName: string, label?: DeviceLabelInfo, other?: T): void {
        const item: DeviceLabelFuncItem = this.devLabelFuncSvc.getFunctionByName(funcName);
        if (item) {
            const viewContainerRef = this._devLabelFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IDeviceLabelFunc<T>>componentRef.instance).title = item.title;
            (<IDeviceLabelFunc<T>>componentRef.instance).label = label;
            (<IDeviceLabelFunc<T>>componentRef.instance).other = other;
        }
    }
}