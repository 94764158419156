import { Component, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ScepChallengeInfo } from '../scep.data';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    selector: 'na-scep-auth',
    templateUrl: './scep-auth.component.html',
    styleUrls: ['../scep.style.css']
})
export class SCEPAuthComponent extends AutoUnsubscribeComponent {
    readonly EAP_IDENTITY_LIST: string[] = ['MAC', 'Device ID'];

    _challengeInfo: ScepChallengeInfo = new ScepChallengeInfo();

    private _scepChallengePwdRef: ElementRef;
    @ViewChild('scepChallengePwd', { static: false })
    set scepChallengePwd(v: ElementRef) {
        if (!this._scepChallengePwdRef && v) {
            this._scepChallengePwdRef = v;

            fromEvent(this._scepChallengePwdRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._challengeInfo.challengePassword = e.target.value;
                this.onChallengeChanged.emit(this._challengeInfo);
            });
        }
    }

    private _scepEAPDomainRef: ElementRef;
    @ViewChild('scepEAPDomain', { static: false })
    set scepEAPDomain(v: ElementRef) {
        if (!this._scepEAPDomainRef && v) {
            this._scepEAPDomainRef = v;

            fromEvent(this._scepEAPDomainRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._challengeInfo.eapDomain = e.target.value;
                this.onChallengeChanged.emit(this._challengeInfo);
            });
        }
    }

    @Output() onChallengeChanged = new EventEmitter<ScepChallengeInfo>();

    changeEAPIdentity(identity: string): void {
        if (this._challengeInfo.eapIdentity !== identity) {
            this._challengeInfo.eapIdentity = identity;
            this.onChallengeChanged.emit(this._challengeInfo);
        }
    }
}