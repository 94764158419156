<div class='modal-dialog config-dlg' role="document">
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body'>
            <div>
                <na-scep-server-detail [scep]="_scep" [isInEdit]="true" [mode]="'add'" [disabled]="_page === _enumPage.submit" (onScepInfoUpdated)="onScepUpdate($event)"></na-scep-server-detail>
                <div *ngIf="_page === _enumPage.result" class="mt-2">
                    <div *ngIf="_errorMessage" class="block block-error">
                        <i class="fas fa-exclamation-circle"></i>
                        <span class="ml-2">Create credential failed :</span>
                        <div>{{ _errorMessage }}</div>
                    </div>
                </div>
            </div>
            <div class="row control-block d-flex justify-content-center align-items-center mt-4">
                <button type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_page === _enumPage.submit" data-dismiss="modal">
                    <span class="fas fa-times-circle mr-1"></span>
                    {{ 'key-cancel' | translate | capitalFirstWord }}
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_page === _enumPage.submit"
                    (click)="create()">
                    <span class="fas fa-plus-circle mr-1"></span>
                    Create
                </button>
                <div *ngIf="_page === _enumPage.submit" class="loading loading-p0 ml-4">
                    <div class="loading-box"></div>
                </div>
            </div>
        </div>
    </div>
</div>