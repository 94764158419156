import { DeviceLabelInfo } from "../dev-label.data";
import { Type } from '@angular/core';

export const DEVICE_LABEL_FUNC_DELETE: string = 'dev-label-func-delete';
export const DEVICE_LABEL_FUNC_ATTACH: string = 'dev-label-func-attach';

export interface IDeviceLabelFunc<T> {
    title: string;
    label: DeviceLabelInfo;
    other?: T;
}

export class DeviceLabelFuncItem {
  constructor(public component: Type<any>, public name: string, public title: string, public data?: any) {}
}