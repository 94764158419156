import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export class APIRunPluginInstanceManager extends APIBaseManager<{ pluginInstanceID: string }, void, void, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_RunPluginInstance';
        this._apiMethod = 'POST';
        this._allowPayloadPrint = false;
    }

    protected getRequestURL(pathParameters: { pluginInstanceID: string }, queryParameters: void): string {
        return super.getRequestURL() + `pluginInstances/${pathParameters.pluginInstanceID}/tryRun`;
    }
}