<div>
    <div>Following are the request you report.</div>
    <div class="block mt-1">
        <span class="title">{{ _str_mode }}</span>
        <div class="form-group">
            <strong>Subject :</strong>
            <div class="ml-2">{{ _issueTitle }}</div>
        </div>
        <div class="form-group">
            <strong>Description :</strong>
            <div class="ml-2">{{ _issueContent }}</div>
        </div>
        <div [ngSwitch]="_mode">
            <div *ngSwitchCase="_enumIssueMode.Email">
                <div class="form-group">
                    <strong>Recipients :</strong>
                    <div class="ml-2">{{ _email }}</div>
                </div>
                <div class="form-group">
                    <strong>Monitor devices :</strong>
                    <div class="ml-2">{{ _monitorDeviceNames }}</div>
                </div>
            </div>
            <div *ngSwitchCase="_enumIssueMode.FAESupport">
                <div class="form-group">
                    <strong>Symptom :</strong>
                    <div class="ml-2">{{ _issueSymptom }}</div>
                </div>
                <div class="form-group">
                    <strong>Frequency :</strong>
                    <div class="ml-2">{{ _issueFrequency }}</div>
                </div>
                <div class="form-group">
                    <strong>Monitor devices :</strong>
                    <div class="ml-2">{{ _monitorDeviceNames }}</div>
                </div>
            </div>
        </div>
    </div>
</div>