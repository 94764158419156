import { Component, Input } from "@angular/core";
import { LockScreenInfo, LockScreenMode } from "./screen.data";

@Component({
    selector: 'na-lockScreen-preview',
    templateUrl: './lockScreen-preview.component.html'
})
export class LockScreenPreviewComponent {
    _enumLockScreenMode: typeof LockScreenMode = LockScreenMode;

    _info: LockScreenInfo;
    @Input('data')
    set data(s: LockScreenInfo) {
        if (s) {
            this._info = s;
        }
    }
}