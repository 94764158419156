import { Component } from '@angular/core';
import { ILicenseDynamicComponent } from './license-func.def';
import { DeviceInfo } from '../../device/data/device-info';
import { DialogPage } from '../../../lib/common/common.data';
import { DeviceService } from '../../device/device.service';
import { LicenseService } from '../license.service';
import { AccountService } from '../../../entry/account.service';
import { VirtualDlgComponent } from 'app/content/virtual/dlg/virtual-dlg.component';

@Component({
    template: '',
    styleUrls: ['./license-dlg.style.css']
})
export class LicenseBaseDlgComponent extends VirtualDlgComponent implements ILicenseDynamicComponent {
    title: string;
    dialogCompleteHandler?: (result: any) => void;
    _devices: DeviceInfo[] = [];

    _page: DialogPage = DialogPage.prepare;
    _enumPage: typeof DialogPage = DialogPage;
    protected _errorMessage: string;
    protected _legalDevices: DeviceInfo[] = [];
    
    constructor(protected devSvc: DeviceService, protected licenseSvc: LicenseService, protected accountSvc: AccountService) { 
        super(accountSvc);
    }

    allowClose(): boolean {
        switch (this._page) {
            case DialogPage.prepare:
                {
                    return this.isPageValid(this._page) ? false : true;
                }
            case DialogPage.result:
                {
                    return true;
                }
            default:
                {
                    return false;
                }
        }
    }

    allowSubmit(): boolean {
        switch (this._page) {
            case DialogPage.confirm:
                {
                    return true;
                }
            default:
                {
                    return false;
                }
        }
    }

    allowCancel(): boolean {
        switch (this._page) {
            case DialogPage.prepare:
                {
                    return this.isPageValid(this._page) ? true : false;
                }
            case DialogPage.action:
            case DialogPage.confirm:
                {
                    return true;
                }
            default:
                {
                    return false;
                }
        }
    }

    allowGoBack(): boolean {
        switch (this._page) {
            case DialogPage.confirm:
                {
                    return true;
                }
            default:
                {
                    return false;
                }
        }
    }

    allowGoNext(): boolean {
        switch (this._page) {
            case DialogPage.prepare:
                {
                    return this.isPageValid(this._page) ? true : false;
                }
            case DialogPage.action:
                {
                    //check if license code is valid => length match?
                    return true;
                }
            default:
                {
                    return false;
                }
        }
    }

    goBack(): void {
        switch (this._page) {
            case DialogPage.confirm:
                {
                    this._page = DialogPage.action;
                }
                break;
        }
    }

    goNext(): void {
        switch (this._page) {
            case DialogPage.prepare:
                {
                    this._page = DialogPage.action;
                }
                break;
            case DialogPage.action:
                {
                    this._page = DialogPage.confirm;
                }
                break;
            case DialogPage.confirm:
                {
                    this._page = DialogPage.submit;
                }
                break;
            case DialogPage.submit:
                {
                    this._page = DialogPage.result;
                }
                break;
        }
    }

    protected isPageValid(page: DialogPage): boolean {
        return true;
    }
}