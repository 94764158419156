<div class='modal-header'>
    <h4 class="modal-title">{{ title }}</h4>
    <button #dlgClose type="button" class="close d-none" data-dismiss="modal">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class='modal-body'>
    <div class="text-center">
        <p>{{ data?.title }}</p>
        <div>{{ data?.content }}</div>
        <div *ngIf="data?.showMsgTimeout">{{ data?.timeout | date:'mm:ss' }}</div>
    </div>
    <div class="text-center mt-4">
        <button type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal">
            {{ 'key-ok' | translate | capitalFirstWord }}
        </button>
    </div>
</div>