import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IResetPasswordTxData {
    accountEmail: string;
}

export class APIResetAccountPasswordManager extends APIBaseManager<void, void, IResetPasswordTxData, void> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ResetAccountPassword';
        this._apiMethod = 'POST';
        this._allowPayloadPrint = false;
    }

    protected getRequestURL(): string {
        return super.getRequestURL() + 'accounts/password/reset';
    }
}