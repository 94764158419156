<div>
    <div class="block-titlebar">
        <strong class="txt-bold">Device group</strong>
        <ng-template [ngIf]="supportDeviceGroupUpdate()">
            <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-auto"
                [class.active]="_isInEdit" (click)="startEdit()">
                <i class="fas fa-pen mr-1"></i>
                Edit
            </button>
            <button *ngIf="_devGroup?.allowRemove" type="button"
                class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-2" [disabled]="_isInEdit" data-toggle="modal"
                data-target="#devGroupDetailFuncModalHost" (click)="removeDeviceGroup()">
                <i class="fas fa-trash-alt mr-1"></i>
                Delete group
            </button>
        </ng-template>
    </div>
    <div class="form-group row mt-2">
        <label for="col-devGroup-name" class="col-12 col-sm-5 col-md-4 col-form-label col-form-label-sm">
            Group name
        </label>
        <div class="col-12 col-sm-7 col-md-8">
            <div *ngIf="!_isInEdit; else templateEditDevGroupName" id="col-devGroup-name"
                class="form-control-plaintext-sm">
                {{ _devGroup?.name }}
            </div>
            <ng-template #templateEditDevGroupName>
                <input id="col-devGroup-name" type="text" class="form-control form-control-sm"
                    [disabled]="_isDefaultGroup" [(ngModel)]="_editGroupName">
            </ng-template>
        </div>
    </div>
    <div class="form-group row mt-2">
        <label for="col-devGroup-policy" class="col-12 col-sm-5 col-md-4 col-form-label col-form-label-sm">
            Group policy
        </label>
        <div id="col-devGroup-policy" class="col-12 col-sm-7 col-md-8">
            <div *ngIf="_loading; else templateLoadingPolicyComplete" class="text-loading form-control-plaintext-sm"
                [attr.data-text]="TEXT_LOADING">
                {{ TEXT_LOADING }}
            </div>
            <ng-template #templateLoadingPolicyComplete>
                <div *ngFor="let policy of _policyMap | keyvalue">
                    <div *ngIf="_isInEdit || policy.value.next?.name"
                        class="input-group input-group-sm align-items-center mb-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text" [id]="'policy-current-' + policy.key">
                                {{ policy.value.displayName }}
                            </span>
                        </div>
                        <input type="text" class="form-control form-control-plaintext-sm mr-1"
                            [attr.aria-label]="'Policy-' + policy.value.displayName" [disabled]="!_isInEdit"
                            [attr.aria-describedby]="'policy-current-' + policy.key" [value]="policy.value.next?.name"
                            readonly>
                        <ng-container *ngIf="!_isInEdit && policy.value.current?.usage?.[_devGroup.id]">
                            <div *ngIf="policy.value.current.usage[_devGroup.id].total > 0; else templateNoDevice">
                                <small
                                    *ngIf="policy.value.current.usage[_devGroup.id].total == policy.value.current.usage[_devGroup.id].synced; else templatePolicyUnsync"
                                    class="green-2">
                                    ({{ policy.value.current.usage[_devGroup.id]?.synced }} synced)
                                </small>
                                <ng-template #templatePolicyUnsync>
                                    <small>
                                        (<span
                                            [class.online-dev-link]="policy.value.current.usage[_devGroup.id]?.total - policy.value.current.usage[_devGroup.id]?.synced > 0"
                                            (click)="searchUnsyncDeviceInPolicy(policy.value.current)">{{
                                            policy.value.current.usage[_devGroup.id]?.total -
                                            policy.value.current.usage[_devGroup.id]?.synced }}</span> / {{
                                        policy.value.current.usage[_devGroup.id]?.total }}) not synced
                                    </small>
                                </ng-template>
                            </div>
                            <ng-template #templateNoDevice>
                                <small>(No device)</small>
                            </ng-template>
                        </ng-container>
                        <div *ngIf="_isInEdit" class="input-group-append">
                            <button type="button" class="btn btn-sm btn-action btn-light"
                                (click)="removeCurrentPolicy(policy.value)">
                                <i class="fas fa-times"></i>
                            </button>
                            <button [id]="'dropdown-' + policy.key + '-policyLink'" type="button"
                                class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
                            </button>
                            <ul class="dropdown-menu dropdown-menu-left option-list"
                                [attr.aria-labelledby]="'dropdown-' + policy.key + '-policyLink'">
                                <li *ngFor="let option of policy.value.avaliableList"
                                    [class.selected]="policy.value.next?.id === option.id"
                                    (click)="changeCurrentPolicy(policy.value, option)">
                                    {{ option.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div *ngIf="supportDeviceGroupUpdate()" class="form-group row mt-2">
        <label for="col-devGroup-userGroup" class="col-12 col-sm-5 col-md-4 col-form-label col-form-label-sm">
            User group
        </label>
        <div id="col-devGroup-userGroup" class="col-12 col-sm-7 col-md-8">
            <div *ngIf="_loading; else templateLoadingUserGroupComplete" class="text-loading form-control-plaintext-sm"
                [attr.data-text]="TEXT_LOADING">
                {{ TEXT_LOADING }}
            </div>
            <ng-template #templateLoadingUserGroupComplete>
                <div *ngIf="!_isInEdit; else templateEditDevGroupUserGroup" id="col-devGroup-userGroup"
                    class="form-control-plaintext-sm">
                    <ng-template ngFor let-ugKeyValue [ngForOf]="_ctrlUserGroupMap | keyvalue" let-i="index"
                        let-last="last">
                        <span *ngIf="ugKeyValue.value.checkBefore">
                            [ <strong>{{ ugKeyValue.value.userGroup?.name }}</strong> ]
                            <ng-template [ngIf]="!last">, </ng-template>
                        </span>
                    </ng-template>
                </div>
                <ng-template #templateEditDevGroupUserGroup>
                    <div class="position-relative">
                        <button id="dropdownDevGroupUserGroupLink" type="button"
                            class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
                            Select user groups
                        </button>
                        <div class="dropdown-menu dropdown-menu-left option-list w-100"
                            aria-labelledby="dropdownDevGroupUserGroupLink">
                            <div class="px-2" (click)="$event.stopPropagation()">
                                <div *ngFor="let ugKeyValue of _ctrlUserGroupMap | keyvalue"
                                    class="d-flex align-items-center mt-1">
                                    <input [id]="'cb-td-' + ugKeyValue.value.userGroup.id" type="checkbox"
                                        class="cmn-checkbox" [checked]="ugKeyValue.value.checkAfter"
                                        (change)="checkoutUserGroup(ugKeyValue.key, $event.target.checked)" />
                                    <label [for]="'cb-td-' + ugKeyValue.value.userGroup.id" class="mr-2">
                                        <svg>
                                            <use xlink:href="#checkmark" />
                                        </svg>
                                        <span class="sr-only">Select user group {{ ugKeyValue.value.userGroup.name
                                            }}</span>
                                    </label>
                                    {{ ugKeyValue.value.userGroup.name }}
                                </div>
                            </div>
                            <div class="mt-2 text-right">
                                <button type="button" class="btn btn-sm btn-action btn-light">OK</button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
        </div>
    </div>
</div>
<div *ngIf="_page === _enumPage.result" class="mt-2">
    <div *ngIf="_errorMessage" class="block block-error">
        <i class="fas fa-exclamation-circle"></i>
        <span class="ml-2">Edit device group failed : </span>
        <div>{{ _errorMessage }}</div>
    </div>
</div>
<div *ngIf="_isInEdit" class="mt-2 d-flex align-items-center">
    <button type="button" class="btn btn-sm btn-action btn-light ml-auto" [disabled]="_page === _enumPage.submit"
        (click)="cancelEdit()">
        <span class="fas fa-times-circle icon-left"></span>
        Cancel
    </button>
    <button type="button" class="btn btn-sm btn-action btn-light ml-2" [disabled]="_page === _enumPage.submit"
        (click)="saveEdit()">
        <span class="fas fa-save icon-left"></span>
        Save
    </button>
    <div *ngIf="_page === _enumPage.submit" class="loading loading-p0 ml-4">
        <div class="loading-box"></div>
    </div>
</div>
<div id="devGroupDetailFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template dev-group-func-host></ng-template>
</div>