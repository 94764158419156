import { Component, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

@Component({
    template: ``
})
export class AutoUnsubscribeComponent implements OnDestroy {
    protected _unsubscribe$: Subject<void> = new Subject();

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }
}