import { Injectable } from '@angular/core';

import { LicenseDlgAddComponent } from './license-dlg-add.component';
import { LicenseDlgReAllocateComponent } from './license-dlg-reallocate.component';
import { LicenseDlgImportComponent } from './license-dlg-import.component';
import { LicenseFuncItem, FUNCNAME_LICENSE_ADD, FUNCNAME_LICENSE_REALLOCATE, FUNCNAME_LICENSE_IMPORT } from './license-func.def';

@Injectable()
export class LicenseFuncService {
  private _funcs: LicenseFuncItem[] = [];
  get Funcs(): LicenseFuncItem[] {
      return this._funcs;
  }

  constructor() {
    this._funcs = [
      new LicenseFuncItem(LicenseDlgAddComponent, FUNCNAME_LICENSE_ADD, 'Add License'),
      new LicenseFuncItem(LicenseDlgImportComponent, FUNCNAME_LICENSE_IMPORT, 'Import License'),
      new LicenseFuncItem(LicenseDlgReAllocateComponent, FUNCNAME_LICENSE_REALLOCATE, 'Reallocate License'),
    ];
  }

  getFunctionByName(name: string): LicenseFuncItem {
    return this._funcs.find(i => i.name === name);
  }
}