import { Component, EventEmitter, Input, Output, ElementRef, ViewChild } from '@angular/core';
import { fromEvent as observableFromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IUIElement } from '../uiElement.interface';
import { MaintenancePlaybackInfo, MaintenanceScheduleMode } from './mtPlayback.data';

@Component({
    selector: 'na-mtPlayback',
    templateUrl: './mtPlayback.component.html',
    styleUrls: ['../uiElement.style.css', './mtPlayback.component.css']
})
export class MaintenancePlaybackComponent implements IUIElement {
    _info: MaintenancePlaybackInfo = new MaintenancePlaybackInfo();
    _enumMaintenanceScheduleMode: typeof MaintenanceScheduleMode = MaintenanceScheduleMode;

    private _urlRef: ElementRef;
    @ViewChild('maintenanceURL', { static: true })
    set url(v: ElementRef) {
        if (v) {
            this._urlRef = v;
            const searchElement = this._urlRef.nativeElement;
            const searchInputOb = observableFromEvent(searchElement, 'input');

            searchInputOb.pipe(
                debounceTime(200))
                .subscribe((e: any) => {
                    this._info.url = e.target.value;
                    this.notify();
                });
        }
    }

    _disabled: boolean = false;
    @Input('disabled')
    set disabled(d: boolean) {
        this._disabled = d;
    }

    _unsupportReason: string;
    @Input('unsupportReason')
    set unsupportReason(v: string) {
        this._unsupportReason = v;
    }

    @Input('data')
    set data(raw: MaintenancePlaybackInfo) {
        if (raw) {
            this._info = raw;
        }
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(d: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = d;
    }

    @Output() updated = new EventEmitter<MaintenancePlaybackInfo>();

    enableMaintenance(checked: boolean): void {
        if (this._info.enabled === checked) return;
        this._info.enabled = checked;
        this.notify();
    }

    changeScheduleMode(scheduleMode: MaintenanceScheduleMode): void {
        if (this._info.scheduleMode === scheduleMode) return;
        this._info.scheduleMode = scheduleMode;
        this.notify();
    }

    changePeriodBeginDate(dateStr: string): void {
        if (this._info.beginDate === dateStr) return;
        this._info.beginDate = dateStr;
        this.notify();
    }

    changePeriodEndDate(dateStr: string): void {
        if (this._info.endDate === dateStr) return;
        this._info.endDate = dateStr;
        this.notify();
    }

    changeBeginTime(timeStr: string): void {
        if (this._info.beginTime === timeStr) return;
        this._info.beginTime = timeStr;
        this.notify();
    }

    changeEndTime(timeStr: string): void {
        if (this._info.endTime === timeStr) return;
        this._info.endTime = timeStr;
        this.notify();
    }

    private notify(): void {
        this.updated.next(this._info);
    }
}