import { Component, OnInit, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { TopMenuService } from '../../../../app/content/menu.service';
import { SortType } from '../../../../app/lib/common/common.data';
import { IScepFuncCtrl, ScepFuncItem, SCEP_FUNC_ADD, SCEP_FUNC_REMOVE } from './dlg/scep-func.def';
import { ScepFuncDirective } from './dlg/scep-func.directive';
import { ScepFuncService } from './dlg/scep-func.service';
import { ScepServerInfo } from './scep.data';
import { ScepService } from './scep.service';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    templateUrl: './scep-overview.component.html',
    styleUrls: []
})
export class SCEPOverviewComponent extends AutoUnsubscribeComponent implements OnInit {
    _scepList: ScepServerInfo[] = [];
    _loading: boolean;
    _errorMessage: string;

    _scepRoute: string;

    _currentSortKey: string = this.scepSvc.SCEP_SERVER_COLKEY_ALIAS;
    _currentSortType: SortType = SortType.ascend;
    _enumSortType: typeof SortType = SortType;

    private _funcHost: ScepFuncDirective;
    @ViewChild(ScepFuncDirective, { static: true })
    set funcHost(host: any) {
        this._funcHost = host;
    }

    constructor(
        public scepSvc: ScepService,
        private scepFuncSvc: ScepFuncService,
        private menuSvc: TopMenuService) {
        super();
    }

    ngOnInit(): void {
        this._scepRoute = this.menuSvc.getScepRoute();

        this.scepSvc.onScepAdded.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((res: { addedScepList: ScepServerInfo[], remainingScepList: ScepServerInfo[] }) => {
            this._scepList = res.remainingScepList;
            this.refactor();
        });

        this.scepSvc.onScepRemoved.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((res: { removedScepList: ScepServerInfo[], remainingScepList: ScepServerInfo[] }) => {
            this._scepList = res.remainingScepList;
            this.refactor();
        });

        this._loading = true;
        this.scepSvc.getScepList().subscribe((res: { scepList: ScepServerInfo[], isFault: boolean, errorMessage?: string }) => {
            if (!res.isFault) {
                this._scepList = res.scepList;
                this.refactor();
            }

            this._loading = false;
        });
    }

    createScep(): void {
        this.createScepFuncDlg(SCEP_FUNC_ADD);
    }

    removeScepServer(scep: ScepServerInfo): void {
        this.createScepFuncDlg(SCEP_FUNC_REMOVE, [scep]);
    }

    sortDescend(key: string): void {
        if (this._currentSortKey === key && this._currentSortType === SortType.descend) {
            return;
        }

        this._currentSortKey = key;
        this._currentSortType = SortType.descend;
        this.refactor();
    }

    sortAscend(key: string): void {
        if (this._currentSortKey === key && this._currentSortType === SortType.ascend) {
            return;
        }

        this._currentSortKey = key;
        this._currentSortType = SortType.ascend;
        this.refactor();
    }

    private refactor(): void {
        //by sort
        this._scepList = this._scepList.sort((a: ScepServerInfo, b: ScepServerInfo) => {
            let ca: string = a[this._currentSortKey];
            let cb: string = b[this._currentSortKey];

            if (ca === cb) {
                return 0;
            }

            switch (this._currentSortType) {
                case SortType.ascend:
                    {
                        return ca >= cb ? 1 : -1;
                    }
                case SortType.descend:
                    {
                        return ca >= cb ? -1 : 1;
                    }
            }
        });
    }

    private onActionComplete(res: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }): void { }

    private createScepFuncDlg(scepFuncName: string, scepList: ScepServerInfo[] = [], other?: any): void {
        const item: ScepFuncItem = this.scepFuncSvc.getItemByName(scepFuncName);
        if (item) {
            const viewContainerRef = this._funcHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IScepFuncCtrl>componentRef.instance).title = item.title;
            (<IScepFuncCtrl>componentRef.instance).funcName = scepFuncName;
            (<IScepFuncCtrl>componentRef.instance).scepList = scepList;
            (<IScepFuncCtrl>componentRef.instance).other = other;
            (<IScepFuncCtrl>componentRef.instance).onActionCompleted = this.onActionComplete.bind(this);
        }
    }
}