import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { DeviceInfo, OnlineStatus } from '../../data/device-info';
import { DeviceService } from '../../device.service';
import { ScopeFunctionInfo, LicenseScopeType } from '../../../../../app/content/license/license.data';
import { Logger } from '../../../../../app/lib/common/logger';
import { NAService } from '../../../../../app/API/na.service';
import { FileReaderParseType, HelperLib, REFRESH_DURATION } from '../../../../../app/lib/common/helper.lib';
import { AppConfigService } from '../../../../../app/app.config';
import { AccountService } from '../../../../../app/entry/account.service';
import { CustomResponse } from '../../../../lib/common/common.data';
import { ConstantService } from 'app/lib/common/constant.service';

@Component({
    selector: 'na-device-screenshot-info',
    templateUrl: './device-info-screenshot.component.html',
    styleUrls: ['./device-info-screenshot.component.css']
})
export class DeviceInfoScreenshotComponent implements OnDestroy {
    private _device: DeviceInfo;
    _screenshotUrl: SafeUrl;
    _bScreenshotLoadFail: boolean = false;
    _errorMessage: string;
    private _countdown: number = 0;
    private _licenseScopeMap: { [scopeType: string]: ScopeFunctionInfo } = {};
    private _screenshotRefreshTimerID: NodeJS.Timer;
    private _screenshotMD5: string = '';
    _screenshotCaptureTime: Date;
    _isDeviceLocked: boolean = false;

    @Input('device')
    set device(d: DeviceInfo) {
        this._device = d;
        this.getScreenshot(1);
        this.updateDeviceLockStatus();
    }
    @Input('licenseScope')
    set licenseScope(lsp: { [scopeType: string]: ScopeFunctionInfo }) {
        this._licenseScopeMap = lsp;
    }

    @Output() onScreenshotTaken = new EventEmitter<CustomResponse<any>>();

    constructor(
        private devSvc: DeviceService,
        private accountSvc: AccountService,
        private constantSvc: ConstantService,
        private naSvc: NAService) {
    }

    ngOnDestroy(): void {
        clearTimeout(this._screenshotRefreshTimerID);
    }

    get supportScreenshot(): boolean {
        return AppConfigService.configs.devPage.func.enableScreenshotRefresh && this.accountSvc.hasScope_task_screenshot();
    }

    get isScreenshotValid(): boolean {
        if (this._countdown > 0) {
            return false;
        }

        if (this._device.onlineStatus !== OnlineStatus.Online) {
            return false;
        }

        if (!this._licenseScopeMap[LicenseScopeType.takeScreenshot]) {
            return false;
        }

        return true;
    }

    takeScreenshot(): void {
        if (this._screenshotRefreshTimerID) {
            clearTimeout(this._screenshotRefreshTimerID);
            this._screenshotRefreshTimerID = null;
        }

        this.devSvc.batchTaskScreenshot([this._device]).subscribe((res: CustomResponse<any>) => {
            if (res && !res.isFault()) {
                this.getScreenshot(10, 4);
                this.onScreenshotTaken.emit(res);
            }
        });

        this._countdown = REFRESH_DURATION * 2;
        HelperLib.countdown(this._countdown, 0, (counter: number) => {
            this._countdown = counter;
        });
    }

    private updateDeviceLockStatus(): void {
        this._isDeviceLocked = this._device?.currentSettings[this.constantSvc.DEVKEY_INFO_SYSTEM_ISLOCK] ? true : false;
    }

    private getScreenshot(refreshInterval: number = 15, retryTime: number = 0): void {
        const className = 'Screenshot';
        const method = 'getScreenshot';

        if (!this._device) {
            return;
        }

        this._screenshotRefreshTimerID = setTimeout(() => {
            if (this._screenshotRefreshTimerID) {
                clearTimeout(this._screenshotRefreshTimerID);
                this._screenshotRefreshTimerID = null;
            }

            this.naSvc.getDeviceScreenshot({ virtualDeviceID: this._device.virtualId, virtualDevicePairedID: this._device.virtualPairId }, { raw: true, dummy: true }, this.accountSvc.token).subscribe((res: any) => {
                if (res && res.type === 'application/json') {
                    this._bScreenshotLoadFail = true;
                    HelperLib.blobToOtherType(res, FileReaderParseType.base64).subscribe((s: string) => {
                        if (s) {
                            const base64DecodeStr: string = atob(s);
                            if (base64DecodeStr) {
                                try {
                                    const sjon: { data: any, error: string, errorMessage?: string } = JSON.parse(base64DecodeStr);
                                    this._errorMessage = sjon.errorMessage;
                                }
                                catch (ex) {
                                    Logger.logError(className, method, 'Parse screen string to json failed. Error = ', ex);
                                }
                            }
                        }
                    }, (error: any) => {
                        Logger.logError(className, method, 'Transform screenshot blob to string failed. Error = ', error);
                    });

                    return;
                }

                HelperLib.blobToMd5(res).subscribe((md5: string) => {
                    try {
                        if (md5 && md5 !== this._screenshotMD5) {
                            this._screenshotMD5 = md5;
                            const dataUrl = window.URL.createObjectURL(res);

                            if (dataUrl) {
                                this._bScreenshotLoadFail = false;
                                this._screenshotUrl = null;

                                setTimeout(() => {
                                    this._screenshotUrl = dataUrl;
                                    if (res.serverTime) {
                                        this._screenshotCaptureTime = new Date(res.serverTime);
                                    }
                                }, 500);
                            }
                            else {
                                this._bScreenshotLoadFail = true;
                            }
                        }
                        else if (retryTime > 0) {
                            this.getScreenshot(refreshInterval + 5, --retryTime);
                        }
                    }
                    catch (ex) {
                        Logger.logError(className, method, 'Transform screenshot to ObjectURL failed. Error = ', ex);
                        this._bScreenshotLoadFail = true;
                    }
                }, (error: any) => {
                    Logger.logError(className, method, 'Transform screenshot blob to md5 failed. Error = ', error);
                    this._bScreenshotLoadFail = true;
                });
            });
        }, refreshInterval * 1000);
    }

    //get if remote control is support; if not, hide it.
    get supportRemoteCtrl(): boolean {
        return this.devSvc.isDeviceSupportRemoteCtrl(this._device);
    }

    //get if remote control is valid; if not, show it on UI but disable it.
    get isRemoteCtrlValid(): boolean {
        if (this._device.onlineStatus !== OnlineStatus.Online) {
            return false;
        }

        if (!this._licenseScopeMap[LicenseScopeType.remoteAssistance]) {
            return false;
        }

        return true;
    }
}