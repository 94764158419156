import { Type } from '@angular/core';
import { AlertInfo } from '../alert.data';

export class AlertFuncItem {
  constructor(public component: Type<any>, public name: string, public title: string) {}
}

export interface IAlertFuncCtrl {
  title: string;
  alert: AlertInfo;
}