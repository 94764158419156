export enum DashboardView {
    table = 'table',
    grid = 'grid'
}

export class UserPreferenceGlobalInfo {
    theme: string; //standard, high
}

export class UserPreferenceHomeInfo {
    overviewLayout: string;
    group: {
        defaultAccount?: string;
    };
    device: {
        view?: DashboardView; //table, grid
        pageCapacity?: number; //number of devices shown in a page
        tableLayoutColumnOptionMap?: { [key: string]: boolean }; //column name?
    };
}

export class UserPreferenceEventLogInfo {
    filter: {
        level?: { [levelName: string]: boolean },
        time?: { beginDate?: string, beginTime?: string, endDate?: string, endTime?: string },
        event?: { [categoryName: string]: { [eventName: string]: boolean } }
    }
}

export class UserPreferenceLicenseInfo {
    filter: {
        licenseType: { [type: string]: boolean },
        expiryDate: { beginDate?: string, endDate?: string }
    }
}

export class UserPreferenceInfo {
    global: UserPreferenceGlobalInfo;
    home: UserPreferenceHomeInfo;
    eventLog: UserPreferenceEventLogInfo;
    license: UserPreferenceLicenseInfo;
}