import { Component, ElementRef, ViewChild } from "@angular/core";
import { DialogPage } from "app/lib/common/common.data";
import { CertInfo } from "../cert.data";
import { CertService } from "../cert.service";
import { ICertFuncCtrl } from "./cert-func.def";
import { FileReaderParseType, HelperLib } from "app/lib/common/helper.lib";
import { VirtualDlgComponent } from "app/content/virtual/dlg/virtual-dlg.component";
import { AccountService } from "app/entry/account.service";

@Component({
    templateUrl: './cert-dlg-add.component.html',
    styleUrls: ['../cert.style.css']
})
export class CertAddDlgComponent extends VirtualDlgComponent implements ICertFuncCtrl {
    title: string;
    funcName: string;
    certList: CertInfo[];
    other?: any;
    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;

    _enumPage: typeof DialogPage = DialogPage;
    _page: DialogPage = DialogPage.action;
    _errorMessage: string;

    _certName: string;
    _certData: string;
    _certSize: number;

    private _certFilePickerRef: ElementRef;
    @ViewChild('certFilePicker')
    set certFilePicker(v: any) {
        this._certFilePickerRef = v;
    }

    constructor(protected accountSvc: AccountService, private certSvc: CertService) { 
        super(accountSvc);
    }

    triggerCertFilePicker(): void {
        this._certFilePickerRef?.nativeElement.click();
    }

    onFileChange(e: any): void {
        this._errorMessage = null;
        const file: File = e.target.files[0];
        this._certName = file.name;
        this._certSize = file.size;

        HelperLib.blobToOtherType(file, FileReaderParseType.text).subscribe((data: string) => {
            this._certData = data;
        });
    }

    addCert(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;

        this.certSvc.addCert(this._certName, this._certData).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._page = DialogPage.result;
            this._errorMessage = res.errorMessage;

            if (!res.isFault) {
                setTimeout(() => {
                    this._dlgCloseElementRef.nativeElement.click();
                }, 0);
            }
        });
    }
}