<div class="p-2">
    <div>
        Run local scripts on the target device.
    </div>
    <form #scriptForm="ngForm" class="container-fluid mt-2">
        <div>
            <label>Script</label>
            <textarea name="instData" [(ngModel)]="_actionData.instructionContent" required #instData="ngModel" class="w-100 p-1"></textarea>
        </div>
    </form>
</div>