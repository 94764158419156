import { Injectable } from '@angular/core';
import { DEVICE_LABEL_FUNC_ATTACH, DEVICE_LABEL_FUNC_DELETE, DeviceLabelFuncItem } from './dev-label-func.def';
import { DeviceLabelDeleteDlgComponent } from './dev-label-delete-dlg.component';
import { DeviceLabelAttachDlgComponent } from './dev-label-attach-dlg.component';

@Injectable()
export class DeviceLabelFuncService {
  private _funcs: DeviceLabelFuncItem[] = [];

  constructor() {
    this._funcs = [
      new DeviceLabelFuncItem(DeviceLabelDeleteDlgComponent, DEVICE_LABEL_FUNC_DELETE, 'Delete label'),
      new DeviceLabelFuncItem(DeviceLabelAttachDlgComponent, DEVICE_LABEL_FUNC_ATTACH, 'Create label')
    ];
  }

  getAllFuncs(): DeviceLabelFuncItem[] {
    return this._funcs;
  }

  getFunctionByName(name: string): DeviceLabelFuncItem {
    return this._funcs.find(i => i.name === name);
  }
}
