import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../api.wrapper.base';
import { APIListVirtualDeviceManager } from "./api.virtualDevice.list";
import { APICreateVirtualDeviceManager } from './api.virtualDevice.create';
import { APIPairVirtualDeviceManager } from './api.virtualDevice.pair';
import { APIUnPairVirtualDeviceManager } from './api.virtualDevice.unpair';
import { APIRemoveVirtualDeviceManager } from './api.virtualDevice.remove';
import { APIGetVirtualDeviceManager } from './api.virtualDevice.get';
import { APIGetVirtualDeviceHeartbeatManager } from './api.virtualDevice.heartbeat.get';
import { APIGetVirtualDeviceCurrentSettingManager } from './api.virtualDevice.currentSetting.get';
import { APIGetVirtualDeviceAvailableOptionManager } from './api.virtualDevice.availableOption.get';
import { APIGetVirtualDeviceScreenshotManager } from './api.virtualDevice.screenshot.get';
import { APIUpdateVirtualDeviceManager } from './api.virtualDevice.update';
import { APIGetVirtualDeviceShadowManager } from './api.virtualDevice.shadow.get';
import { APISetVirtualDeviceCalendarManager } from './api.virtualDevice.calendar.set';
import { APIGetVirtualDeviceCalendarManager } from './api.virtualDevice.calendar.get';
import { APIRemoveVirtualDeviceCalendarManager } from './api.virtualDevice.calendar.remove';
import { APIGetVirtualDeviceOTPManager } from './api.virtualDevice.otp.get';
import { APIGetVirtualDeviceAdvanceFilterManager } from './api.virtualDevice.advanceFilter.get';
import { APISearchVirtualDeviceByAdvanceFilterManager } from './api.virtualDevice.advanceFilter.search';
import { APIListVirtualDeviceLabelManager } from './label/api.virtualDevice.label.list';
import { APIUpdateVirtualDeviceLabelManager } from './label/api.virtualDevice.label.update';

export class APIVirtualDeviceWrapper extends APIWrapperBase {
    ListVirtualDevice: APIListVirtualDeviceManager;
    CreateVirtualDevice: APICreateVirtualDeviceManager;
    PairVirtualDevice: APIPairVirtualDeviceManager;
    UnpairVirtualDevice: APIUnPairVirtualDeviceManager;
    RemoveVirtualDevice: APIRemoveVirtualDeviceManager;
    UpdateVirtualDevice: APIUpdateVirtualDeviceManager;
    GetVirtualDevice: APIGetVirtualDeviceManager;
    GetVirtualDeviceShadow: APIGetVirtualDeviceShadowManager;
    GetHeartbeat: APIGetVirtualDeviceHeartbeatManager;
    GetCurrentSetting: APIGetVirtualDeviceCurrentSettingManager;
    GetAvailableOption: APIGetVirtualDeviceAvailableOptionManager;
    GetScreenshot: APIGetVirtualDeviceScreenshotManager;
    SetCalendar: APISetVirtualDeviceCalendarManager;
    GetCalendar: APIGetVirtualDeviceCalendarManager;
    RemoveCalendar: APIRemoveVirtualDeviceCalendarManager;
    GetOTP: APIGetVirtualDeviceOTPManager;
    GetAdvanceFilter: APIGetVirtualDeviceAdvanceFilterManager;
    ListVirtualDeviceByAdvanceFilter: APISearchVirtualDeviceByAdvanceFilterManager;
    ListVirtualDeviceLabel: APIListVirtualDeviceLabelManager;
    UpdateVirtualDeviceLabel: APIUpdateVirtualDeviceLabelManager;

    constructor(private http: HttpClient) {
        super();

        this.ListVirtualDevice = new APIListVirtualDeviceManager(http);
        this.CreateVirtualDevice = new APICreateVirtualDeviceManager(http);
        this.PairVirtualDevice = new APIPairVirtualDeviceManager(http);
        this.UnpairVirtualDevice = new APIUnPairVirtualDeviceManager(http);
        this.RemoveVirtualDevice = new APIRemoveVirtualDeviceManager(http);
        this.GetVirtualDevice = new APIGetVirtualDeviceManager(http);
        this.GetVirtualDeviceShadow = new APIGetVirtualDeviceShadowManager(http);
        this.UpdateVirtualDevice = new APIUpdateVirtualDeviceManager(http);
        this.GetHeartbeat = new APIGetVirtualDeviceHeartbeatManager(http);
        this.GetCurrentSetting = new APIGetVirtualDeviceCurrentSettingManager(http);
        this.GetAvailableOption = new APIGetVirtualDeviceAvailableOptionManager(http);
        this.GetScreenshot = new APIGetVirtualDeviceScreenshotManager(http);
        this.SetCalendar = new APISetVirtualDeviceCalendarManager(http);
        this.GetCalendar = new APIGetVirtualDeviceCalendarManager(http);
        this.RemoveCalendar = new APIRemoveVirtualDeviceCalendarManager(http);
        this.GetOTP = new APIGetVirtualDeviceOTPManager(http);
        this.GetAdvanceFilter = new APIGetVirtualDeviceAdvanceFilterManager(http);
        this.ListVirtualDeviceByAdvanceFilter = new APISearchVirtualDeviceByAdvanceFilterManager(http);
        this.ListVirtualDeviceLabel = new APIListVirtualDeviceLabelManager(http);
        this.UpdateVirtualDeviceLabel = new APIUpdateVirtualDeviceLabelManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.ListVirtualDevice,
            this.CreateVirtualDevice, 
            this.PairVirtualDevice,
            this.UnpairVirtualDevice,
            this.RemoveVirtualDevice,
            this.GetVirtualDevice,
            this.GetVirtualDeviceShadow,
            this.UpdateVirtualDevice,
            this.GetHeartbeat,
            this.GetCurrentSetting,
            this.GetAvailableOption,
            this.GetScreenshot,
            this.SetCalendar,
            this.GetCalendar,
            this.RemoveCalendar,
            this.GetOTP,
            this.GetAdvanceFilter,
            this.ListVirtualDeviceByAdvanceFilter,
            this.ListVirtualDeviceLabel,
            this.UpdateVirtualDeviceLabel
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}