<div>
    <div class="form-group row">
        <label for="col-scep-alias" class="col-12 col-sm-5 col-md-4 col-form-label txt-bold">
            Alias
        </label>
        <div class="col-12 col-sm-7 col-md-8 col-xl-6">
            <div *ngIf="!_isInEdit || _mode !== 'add'; else templateEditScepAlias" id="col-scep-alias"
                class="form-control-plaintext">
                {{ _scep?.alias }}
            </div>
            <ng-template #templateEditScepAlias>
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">{{ SCEP_ALIAS_PREFIX }}</span>
                    </div>
                    <input #colAlias id="col-scep-alias" type="text" class="form-control form-control-sm"
                        [disabled]="_disabled">
                </div>
            </ng-template>
        </div>
    </div>
    <div class="form-group row">
        <label for="col-scep-subject" class="col-12 col-sm-5 col-md-4 col-form-label txt-bold">
            Subject
        </label>
        <div class="col-12 col-sm-7 col-md-8 col-xl-6">
            <div *ngIf="!_isInEdit; else templateEditScepSubject" id="col-scep-subject" class="form-control-plaintext">
                {{ _scep?.subject }}
            </div>
            <ng-template #templateEditScepSubject>
                <input #colSubject id="col-scep-subject" type="text" class="form-control form-control-sm"
                    [disabled]="_disabled" [value]="_editData.subject">
            </ng-template>
        </div>
    </div>
    <div class="form-group row">
        <label for="col-scep-keysize" class="col-12 col-sm-5 col-md-4 col-form-label txt-bold">
            Key size
        </label>
        <div class="col-12 col-sm-7 col-md-8 col-xl-6">
            <div *ngIf="!_isInEdit; else templateEditScepKeysize" id="col-scep-keysize" class="form-control-plaintext">
                {{ _scep?.keysize }}
            </div>
            <ng-template #templateEditScepKeysize>
                <input #colKeysize id="col-scep-keysize" type="number" min="100"
                    class="form-control form-control-sm" [disabled]="_disabled" [value]="_editData.keysize">
            </ng-template>
        </div>
    </div>
    <div class="form-group row">
        <label for="col-scep-url" class="col-12 col-sm-5 col-md-4 col-form-label txt-bold">
            SCEP server
        </label>
        <div class="col-12 col-sm-7 col-md-8 col-xl-6">
            <div *ngIf="!_isInEdit; else templateEditScepUrl" id="col-scep-url" class="form-control-plaintext">
                {{ _scep?.url }}
            </div>
            <ng-template #templateEditScepUrl>
                <input #colUrl id="col-scep-url" type="text" class="form-control form-control-sm" [disabled]="_disabled"
                    [value]="_editData.url">
            </ng-template>
        </div>
    </div>
    <div class="form-group row">
        <label for="col-scep-profile" class="col-12 col-sm-5 col-md-4 col-form-label txt-bold">
            Profile
        </label>
        <div class="col-12 col-sm-7 col-md-8 col-xl-6">
            <div *ngIf="!_isInEdit; else templateEditScepProfile" id="col-scep-profile" class="form-control-plaintext">
                {{ _scep?.profile }}
            </div>
            <ng-template #templateEditScepProfile>
                <input #colProfile id="col-scep-profile" type="text" class="form-control form-control-sm"
                    [disabled]="_disabled" [value]="_editData.profile">
            </ng-template>
        </div>
    </div>
    <div class="form-group row">
        <label for="col-scep-usage" class="col-12 col-sm-5 col-md-4 col-form-label txt-bold">
            Usage
        </label>
        <div class="col-12 col-sm-7 col-md-8 col-xl-6">
            <div *ngIf="!_isInEdit; else templateEditScepUsage" id="col-scep-usage" class="form-control-plaintext">
                {{ _scep?.usage }}
            </div>
            <ng-template #templateEditScepUsage>
                <div id="col-scep-usage" class="position-relative">
                    <button id="dropdownScepUsageLink" type="button" [disabled]="_disabled"
                        class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
                        {{ _editData.usage }}
                    </button>
                    <ul class="dropdown-menu option-list w-100" data-backdrop="static" data-keyboard="false"
                        aria-labelledby="dropdownScepUsageLink">
                        <li *ngFor="let usage of _scepUsageList" [class.active]="_editData.usage === usage"
                            (click)="changeScepUsage(usage)">
                            {{ usage }}
                        </li>
                    </ul>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="form-group row">
        <label for="col-scep-renew" class="col-12 col-sm-5 col-md-4 col-form-label txt-bold">
            Auto renew
        </label>
        <div class="col-12 col-sm-7 col-md-8 col-xl-6">
            <div *ngIf="!_isInEdit; else templateEditScepRenew" id="col-scep-renew" class="form-control-plaintext">
                {{ _scep?.autoRenewDay }}
            </div>
            <ng-template #templateEditScepRenew>
                <input #colRenew id="col-scep-renew" type="number" min="60"
                    class="form-control form-control-sm w-50 d-inline-block" [value]="_editData.autoRenewDay">
                days before expiration
            </ng-template>
        </div>
    </div>
</div>