<div>
    <div class="row">
        <div class="col-12 col-lg-6">
            <na-customization-item-img [title]="'1. Logo - Dark color'" [note]="'Login page'"
                [data]="_logoDark" [fileFormat]="['png', 'svg']" (onItemChanged)="onLogoDarkImageChanged($event)">
            </na-customization-item-img>
        </div>
        <div class="col-12 col-lg-6 mt-2 mt-lg-0">
            <na-customization-item-img [title]="'2. Logo - Light color'" [note]="'Header bar'"
                [data]="_logoLight" [fileFormat]="['png', 'svg']" [bgColor]="'var(--green-3)'"
                [iconColor]="'var(--white)'" (onItemChanged)="onLogoLightImageChanged($event)">
            </na-customization-item-img>
        </div>
    </div>
    <div class="mt-2 row">
        <div class="col-12">
            <na-customization-item-img [title]="'3. Background image'" [note]="'Login page'" [data]="_bg" [fileFormat]="['png', 'jpg']"
                [maxAllowSize]="4" (onItemChanged)="onBackgroundImageChanged($event)"></na-customization-item-img>
        </div>
    </div>
</div>
<div class="d-flex justify-content-center align-items-center mt-4">
    <button type="button" class="btn btn-sm btn-action btn-light ml-2"
        [disabled]="_page === _enumPage.submit || !isResourceChanged()" (click)="preview()">
        <i class="fas fa-image mr-1"></i>
        Preview
    </button>
    <button type="button" class="btn btn-sm btn-action btn-light ml-2"
        [disabled]="_page === _enumPage.submit || !isResourceChanged()" (click)="save()">
        <i class="fas fa-save mr-1"></i>
        Save
    </button>
    <div *ngIf="_page === _enumPage.submit" class="loading loading-p0 ml-4">
        <div class="loading-box"></div>
    </div>
</div>
<div *ngIf="_message" class="mt-2 text-center">{{ _message }}</div>