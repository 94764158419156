<div *ngIf="isInEdit; else templateViewMode">
    <div>
        <na-cert-pickup [title]="'Policy certificates'" [certInstallStatusMap]="_certInstallStatusMap"
            [sources]="_installedCerts" [supportAddCertAction]="false" [supportChooseCertAction]="false"
            [supportRemoveCertAction]="false" [supportUpdateInstallStatus]="true" [numberInPage]="15"
            (onCertInstallStatusChanged)="onUsedCertInstallStatusChanged($event)"></na-cert-pickup>
    </div>
    <div class="d-flex justify-content-center my-4">
        <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_selectedUnusedCerts.length === 0"
            (click)="addCertificate()">
            <i class="fas fa-angle-up mr-1"></i>
            Add
        </button>
    </div>
    <div>
        <na-cert-pickup [title]="'Certificate manager'" [supportCertManageLink]="true" [supportAddCertAction]="false"
            [supportRemoveCertAction]="false" [numberInPage]="15" (onCertSelected)="onUnsedCertSelected($event)"
            (onCertManagementLinkClicked)="onCertManagementLinkClicked()"></na-cert-pickup>
    </div>
    <div *ngIf="_unmanagedCerts.length > 0">
        <i class="fas fa-exclamation-triangle red-1 mr-1"></i>
        Unmanageable certificates ({{ _unmanagedCerts.length }}) :
        <small class="red-1 ml-2">
            (Certificates that have been applied to the policy but are removed from database directly)
        </small>
        <ul>
            <li *ngFor="let cert of _unmanagedCerts">
                <small class="p-1 shadow-sm">{{ cert.name }} ({{ cert.fingerprint }})</small>
            </li>
        </ul>
    </div>
</div>
<ng-template #templateViewMode>
    <div>
        <na-cert-pickup [title]="'Policy certificates'" [supportSearch]="false" [supportChooseCertAction]="false"
            [certInstallStatusMap]="_certInstallStatusMap" [sources]="_installedCerts" [supportAddCertAction]="false"
            [supportViewInstallStatus]="true" [supportRemoveCertAction]="false" [numberInPage]="15"></na-cert-pickup>
    </div>
</ng-template>