<label class="title">
    Screen saver
    <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
        [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
</label>
<div *ngIf="_unsupportReason" class="warning-block mb-1">{{ _unsupportReason }}</div>
<div [ngClass]="_showBorder? 'showBorder' : ''">
    <div class='switch-container'>
        <input id="cmn-toggle-screensaver" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
            [disabled]="_lockInfo || _disabled || _unsupportReason" [checked]="_info.enabled ? 'checked': null"
            (change)="enableScreensaver($event.target.checked)">
        <label for="cmn-toggle-screensaver">
            <span class="sr-only">Enable screen saver</span>
        </label>
    </div>
    <ul class="mt-2">
        <li>
            Device goes screen saver after
            <div class="dropdown btn-group">
                <button id="dropdownScreensaverTimeoutLink" type="button"
                    class="btn btn-sm btn-action btn-light dropdown-toggle text-right" [class.error]="!isTimeoutValid()"
                    [disabled]="_lockInfo || _disabled || _unsupportReason || !_info.enabled" data-toggle="dropdown">
                    {{ _info.timeout / 60 }}
                </button>
                <ul class="dropdown-menu option-list w-100" aria-labelledby="dropdownScreensaverTimeoutLink">
                    <li *ngFor="let timeout of _timeoutList" [class.active]="_info.timeout === timeout"
                        class="dropdown-item" (click)="changeTimeout(timeout)">
                        {{ timeout / 60 }}
                    </li>
                </ul>
            </div>
            minutes
        </li>
        <li>
            <div>Screen saver content</div>
            <div class="screensaver-content">
                <div class="form-group row mb-0">
                    <label for="screensaver-url" class="col-12 col-md-4 col-xl-3 col-form-label-sm">
                        Content URL
                    </label>
                    <div class="col-12 col-md-8 col-xl-9">
                        <input #screensaverUrl id="screensaver-url" type="text" class="form-control form-control-sm"
                            aria-label="Screen saver content URL" [class.error]="!isDataValid()"
                            [disabled]="_lockInfo || _disabled || _unsupportReason || !_info.enabled"
                            [value]="_info.data">
                    </div>
                </div>
                <!--
                //do not need to set the media type, some redirected url may not work            
                <div class="form-group row">
                    <label for="screensaver-type" class="col-12 col-md-4 col-xl-3">
                        Media type
                    </label>
                    <div class="col-12 col-md-8 col-xl-9">
                        <div id="screensaver-type" class="dropdown btn-group">
                            <button id="dropdownScreensaverContentTypeLink" type="button"
                                class="btn btn-sm btn-action btn-light dropdown-toggle"
                                [class.error]="!isMediaTypeValid()"
                                [disabled]="_lockInfo || _disabled || _unsupportReason || !_info.enabled"
                                data-toggle="dropdown">
                                {{ _info.type }}
                            </button>
                            <ul class="dropdown-menu option-list w-100"
                                aria-labelledby="dropdownScreensaverContentTypeLink">
                                <li *ngFor="let mediaType of _mediaTypeList"
                                    [class.active]="_info.type === mediaType.type" class="dropdown-item"
                                    (click)="changeMediaType(mediaType)">
                                    {{ mediaType.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> 
                -->
            </div>
        </li>
    </ul>
</div>