import { Component, Input, EventEmitter, Output, ViewChild, OnInit, ElementRef } from '@angular/core';
import { forkJoin, fromEvent, merge, race } from 'rxjs';
import { DeviceGroupInfo, DeviceGroupMode, DeviceGroupType, DEVICE_GROUP_FUNC_CREATE, DEVICE_GROUP_ID_HOME } from '../group.data';
import { PolicyService } from '../../../setting/policy/policy.service';
import { PolicyInfo, PolicyType } from '../../../setting/policy/policy.data';
import { AccountService } from '../../../../entry/account.service';
import { DeviceGroupService } from '../dev-group.service';
import { HelperLib } from '../../../../lib/common/helper.lib';
import { DeviceGroupFuncInterface, DeviceGroupFuncItem } from '../dlg/group-func.def';
import { DeviceGroupFuncService } from '../dlg/group-func.service';
import { DeviceGroupFuncDirective } from '../dlg/group-func.directive';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DeviceService } from '../../device.service';
import { DeviceInfo } from '../../data/device-info';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    selector: 'na-dev-group-tree-wrapper',
    templateUrl: './dev-group-tree-wrapper.component.html',
    styleUrls: ['./dev-group-tree-wrapper.component.css']
})
export class DeviceGroupTreeWrapperComponent extends AutoUnsubscribeComponent implements OnInit {
    static INSTANCE_ID: number = 1;
    readonly TEXT_LOADING: string = 'loading ...';
    readonly GROUP_ID_HOME: string = DEVICE_GROUP_ID_HOME;
    _id: string;
    _loadingPolicy: boolean = false;
    _deviceGroupPolicyList: { type: PolicyType, policyList: PolicyInfo[] }[] = [];
    _targetPolicyGroup: DeviceGroupInfo;

    _currentAccount: { accountID: string, accountName: string };
    _ownerAccount: { accountID: string, accountName: string };
    _allowSwitchAccount: boolean = true;
    _enumGroupMode: typeof DeviceGroupMode = DeviceGroupMode;
    _filter: { isFilterApplied: boolean, searchContent?: string, devices?: DeviceInfo[], allowedDeviceGroupSource: Map<string, boolean>, allowedDeviceSource: Map<string, boolean> } = {
        isFilterApplied: false,
        allowedDeviceGroupSource: new Map(),
        allowedDeviceSource: new Map()
    };

    _groupStatistic: { [groupId: string]: { online: number, total: number } } = {};

    @Input('group') _g: DeviceGroupInfo;
    @Input('selectGroup') _selectedGroup: DeviceGroupInfo;
    @Input('showTitle') _showTitle: boolean = false;
    @Input('title') _title: string = '';
    @Input('mode') _groupMode: string = DeviceGroupMode.edit;
    @Input('moveTarget') _moveTarget: DeviceGroupInfo;
    @Input('showDevice') _showDevice: boolean = false;
    @Input('pickMap') _pickMap: { [groupID: string]: { checked: boolean, groupType: DeviceGroupType, name: string } } = {};
    @Input('enableUnfoldFunc') _enableUnfoldFunc: boolean = false;
    @Input('enableCollapseFunc') _enableCollapseFunc: boolean = false;
    @Input('policyMode') _policyDisplayMode: string = 'embed'; //dlg or embed
    @Input('allowFilter') _allowFilter: boolean = false;
    @Input('styleH') _styleH: string;
    @Input('styleBorder') _styleBorder: boolean;

    private _searchRef: ElementRef;
    @ViewChild('search')
    set search(v: ElementRef) {
        if (!this._searchRef && v) {
            this._searchRef = v;

            fromEvent(this._searchRef.nativeElement, 'input').pipe(
                debounceTime(1000),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._filter.searchContent = e.target.value.toLocaleLowerCase();
            });
        }
    }

    @ViewChild(DeviceGroupFuncDirective) devGroupFuncHost: DeviceGroupFuncDirective;

    @Output() onGroupFuncTrigger = new EventEmitter<{ funcName: string, group: DeviceGroupInfo }>();
    @Output() onGroupMoveTargetChanged = new EventEmitter<DeviceGroupInfo>();
    @Output() onGroupTreeDisplayChanged = new EventEmitter<boolean>();
    @Output() onGroupSelected = new EventEmitter<{ devGroup: DeviceGroupInfo, filter?: { onlineStatus: { [state: string]: boolean } } }>();

    constructor(
        private accountSvc: AccountService,
        private policySvc: PolicyService,
        private devGroupSvc: DeviceGroupService,
        private devGroupFuncSvc: DeviceGroupFuncService,
        private devSvc: DeviceService
    ) {
        super();
    }

    ngOnInit(): void {
        this._ownerAccount = { accountID: this.accountSvc.enterpriseAccountID || this.accountSvc.accountID, accountName: this.accountSvc.enterpriseAccountName || this.accountSvc.accountName };

        if (!this._g) {
            HelperLib.checkState(1, () => { return this.devGroupSvc.isReady }, () => {
                this._g = this.devGroupSvc.getRootGroup(this.devGroupSvc.owner.accountName);
                this.devGroupSvc.inspectGroup(null, this._selectedGroup || this.devGroupSvc.getActiveGroup(), true);
            });
        }

        if (this._allowFilter) {
            this.devSvc.deviceFilterApplied.pipe(
                takeUntil(this._unsubscribe$)
            ).subscribe((res: { isApplied: boolean, devices?: DeviceInfo[], sourceFilters?: { rules?: string[], labels?: string[], onlineStatus?: { [state: string]: boolean }, search?: { key: string, value: string } } }) => {
                this._filter.allowedDeviceGroupSource.clear();
                this._filter.allowedDeviceSource.clear();
                this._filter.devices = [];
                this._filter.isFilterApplied = false;

                if (res.isApplied) {
                    this._filter.isFilterApplied = true;
                    this._filter.devices = res.devices;

                    this._filter.devices?.forEach(dev => {
                        const devGroupID: string = this.devGroupSvc.getGroupByID(dev.virtualId)?.parentID || dev.groupID;
                        this._filter.allowedDeviceGroupSource.set(devGroupID, true);
                        this._filter.allowedDeviceSource.set(dev.virtualId, true);
                    });
                }

                this.devGroupSvc.updateDeviceGroupStatistic('device filter ev', this._filter.allowedDeviceSource);
            });

            merge(this.devGroupSvc.onActiveGroupChanged, this.devGroupSvc.onRouteChanged).pipe(
                debounceTime(500),
                takeUntil(this._unsubscribe$)
            ).subscribe(() => {
                if (this._filter?.devices) {
                    // re-caculate allowed-device-group since device maybe changed to another device group
                    this._filter.allowedDeviceGroupSource.clear();
                    this._filter.devices?.forEach(dev => {
                        const devGroupID: string = this.devGroupSvc.getGroupByID(dev.virtualId)?.parentID || dev.groupID;
                        this._filter.allowedDeviceGroupSource.set(devGroupID, true);
                    });
                }

                this.devGroupSvc.updateDeviceGroupStatistic('devGroup route change ev', this._filter.allowedDeviceSource);
            });
        }

        this.devGroupSvc.updateDeviceGroupStatistic('devGroup init', this._filter.allowedDeviceSource);
        this._groupStatistic = this.devGroupSvc.groupStatistic;

        DeviceGroupTreeWrapperComponent.INSTANCE_ID++;
        this._id = this._id || 'DevGroupTreeContainer-' + DeviceGroupTreeWrapperComponent.INSTANCE_ID;
    }

    supportGroupUpdate(): boolean {
        return this.accountSvc.hasScope_device_group_update();
    }

    unfoldGroups(): void {
        this.devGroupSvc.unfoldAllGroups();
    }

    expandGroups(): void {
        this.devGroupSvc.expandAllGroups();
    }

    createGroup(): void {
        this.playGroupFunc(DEVICE_GROUP_FUNC_CREATE);
    }

    onMoveTargetChange(g: DeviceGroupInfo): void {
        this._moveTarget = g;
        this.onGroupMoveTargetChanged.emit(this._moveTarget);
    }

    onGroupCtrlFuncTrigger(funcName: string): void {
        this.playGroupFunc(funcName);
    }

    hideGroup(): void {
        this.onGroupTreeDisplayChanged.emit(false);
    }

    onGroupSelect(ev: { devGroup: DeviceGroupInfo, filter?: { onlineStatus: { [state: string]: boolean } } }): void {
        this.onGroupSelected.emit(ev);
    }

    onSubgroupPolicyInspect(group: DeviceGroupInfo): void {
        if (group && group.policies) {
            const supportedPolicyTypes: PolicyType[] = this.policySvc.getSupportPolicyTypesByLevel(this.accountSvc.isEnterprise());
            const policyIDListUnderDeviceGroup: string[] = [].concat(...supportedPolicyTypes.map(type => group.policies[type] || []));
            if (policyIDListUnderDeviceGroup.length > 0) {
                this._loadingPolicy = true;
                this._targetPolicyGroup = group;

                this.policySvc.getPolicyByIDList(policyIDListUnderDeviceGroup).subscribe((policyList: PolicyInfo[]) => {
                    supportedPolicyTypes.forEach(type => {
                        this._deviceGroupPolicyList.push({
                            type: type,
                            policyList: policyList.filter(p => p.type === type)
                        });
                    });

                    this._loadingPolicy = false;
                });
            }
        }
    }

    closePolicyDetailView(): void {
        this._targetPolicyGroup = null;
        this._deviceGroupPolicyList = [];
    }

    playGroupFunc(funcName: string, g?: DeviceGroupInfo): void {
        const item: DeviceGroupFuncItem = this.devGroupFuncSvc.getFunctionByName(funcName);
        if (item) {
            const viewContainerRef = this.devGroupFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<DeviceGroupFuncInterface>componentRef.instance).title = item.title;
            (<DeviceGroupFuncInterface>componentRef.instance).group = g || this.devGroupSvc.getActiveGroup();
        }
    }
}