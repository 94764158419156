import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export class IApplyEnterpriseTxData {
    enterpriseName: string;
    portalURL: string;
    rootUser: string;
    contactEmail: string;
    enterpriseDomainList: string[];
    singleSignOnSettings?: {
        directoryID: string;
        applicationID: string;
        applicationName: string;
        identifier: string;
        cert64: string;
        //testAccountName?: string;
        //testAccountPassword?: string;
    }
}

export class APIApplyEnterpriseManager extends APIBaseManager<void, void, IApplyEnterpriseTxData, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ApplyEnterprise';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises';
    }
}