import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IListEventLogPathParameter {
    ownerIDName: string; //ex: accountID, virtualDeviceID
    ownerIDValue: string;
}

export interface IListEventLogQueryParameter {
    skip?: number;
    limit?: number
}

export interface IListEventLogTxData {
    fromDate: string;
    toDate: string;
    search: {
        subjectKeyword?: string,
        eventKeyword?: string,
        levelCodeList?: string[]; //Info, Warn, Error
        categoryList?: string[];
        eventList?: string[];
    },
    sort?: {
        date?: number,
        level?: number,
        category?: number,
        event?: number,
        subject?: number
    }
}

export interface IListEventLogRxData {
    itemList: {
        logLevel: { level: number, code: string };
        logIssuer: { accountID: string, accountName?: string };
        logOwner: { accountID: string, accountName?: string, virtualDeviceID?: string };
        logCategory: string;
        logEvent: string;
        logSubject: string;
        logDate: string;
        logID: string;
    }[];
    limit: number;
    skip: number;
    total: number;
}

export class APIListEventLogManager extends APIBaseManager<IListEventLogPathParameter, IListEventLogQueryParameter, IListEventLogTxData, IListEventLogRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_SearchEventLog';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: IListEventLogPathParameter, queryParameters: IListEventLogQueryParameter): string {
        return super.getRequestURL() + 'eventLogs/' + pathParameters.ownerIDName + '/' + pathParameters.ownerIDValue + this.transformObjectQueryParameterToStr(queryParameters);
    }
}