<div class='modal-dialog config-dlg'>
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" [disabled]="_updating" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body'>
            <div>
                <div *ngIf="other?.devices.length > 0; else templateNoDevices">
                    <p>Create a label for the following device(s)</p>
                    <div>
                        <div class="form-group">
                            <div class="d-flex align-items-center mb-1">
                                <label for="dev-label-name">Label name</label>
                                <div class="ml-auto">
                                    <div class="dropdown">
                                        <button class="btn btn-sm btn-action btn-light dropdown-toggle" type="button"
                                            data-toggle="dropdown" aria-expanded="false">
                                            Select from current labels (#{{ _currentLabelNames.length }})
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let labelName of _currentLabelNames" class="dropdown-item" [class.active]="_labelName === labelName" (click)="selectCurrentLabel(labelName)">
                                                {{ labelName }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <input type="email" class="form-control" id="dev-label-name" [(ngModel)]="_labelName"
                                maxlength="20">
                            <small class="text-muted font-italic">At most {{ MAX_LABEL_COUNT }} labels are permitted</small>
                        </div>
                    </div>
                    <div>
                        <label for="dev-label-devices">Device(s)</label>
                        <div id="dev-label-devices" class="list-group">
                            <div class="list-group-item list-group-header black">
                                <div class="row">
                                    <div class="col title">
                                        Device name
                                    </div>
                                    <div class="col title">
                                        Device model
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-content list-group-content-modal">
                                <div *ngFor="let dev of other?.devices;" class="list-group-item list-group-item-action">
                                    <div class="row align-items-center">
                                        <div class="col text-break">
                                            {{ dev.virtualName }}
                                        </div>
                                        <div class="col text-break">
                                            {{ dev.currentSettings[constantSvc.DEVKEY_INFO_MODELDESC] ||
                                            dev.currentSettings[constantSvc.DEVKEY_INFO_MODEL] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <ng-template #templateNoDevices>
                    <p>Please select a device.</p>
                </ng-template>
            </div>
            <div *ngIf="_errorMessage" class="mt-2 warning-block">{{ _errorMessage }}</div>
            <div *ngIf="_updating" class="progress mt-2">
                <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                    Updating the changes... Please wait...
                </div>
            </div>
            <div class='row control-block mt-4 d-flex justify-content-center'>
                <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_updating"
                    data-dismiss="modal">
                    <span class="fas fa-times-circle icon-left"></span>
                    {{ 'key-cancel' | translate | capitalFirstWord }}
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_updating || !_labelName"
                    (click)="attachLabel()">
                    <span class="fas fa-check-circle icon-left"></span>
                    {{ 'key-apply' | translate | capitalFirstWord }}
                </button>
            </div>
        </div>
    </div>
</div>