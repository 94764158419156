import { Component, OnInit } from "@angular/core";
import { SvcNowFuncTab } from "./svcnow.data";
import { TopMenuService } from "app/content/menu.service";
import { Router } from "@angular/router";
import { NAService } from "app/API/na.service";
import { AccountService } from "app/entry/account.service";
import { IAPIRx } from "app/API/api.base";
import { IPlugin, IPluginInstance } from "app/API/v1/Plugin/api.plugin.data";
import { of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import { WorkplacePluginService } from "../plugin.service";

@Component({
    templateUrl: './svcnow-wrapper.component.html'
})
export class ServiceNowWrapperComponent implements OnInit {
    _loading: boolean = false;
    _activeTab: SvcNowFuncTab = SvcNowFuncTab.Register;
    _funcs: { type: SvcNowFuncTab, link: string, displayName: string }[] = [];

    constructor(private router: Router, private menuSvc: TopMenuService, private naSvc: NAService, private accountSvc: AccountService, private pluginSvc: WorkplacePluginService) {
        this._funcs = [
            { type: SvcNowFuncTab.Register, link: this.menuSvc.getAppServiceNowRouter('register'), displayName: 'Registration' },
            { type: SvcNowFuncTab.RoomMapping, link: this.menuSvc.getAppServiceNowRouter('pairing'), displayName: 'Device - Room configuration' },
            { type: SvcNowFuncTab.CalendarConfig, link: this.menuSvc.getAppServiceNowRouter('calendar-config'), displayName: 'Booking software configuration' }
        ];
    }

    ngOnInit(): void {
        this._loading = true;
        this.naSvc.listAvailablePluginTypes(this.accountSvc.token).pipe(
            concatMap((res: IAPIRx<{ itemList: IPlugin[], limit: number, skip: number, total: number }>) => {
                if (res.error != 0) {
                    throw res.errorMessage;
                }

                const svcnowPlugin: IPlugin = res.data.itemList.find(p => p.pluginShowName.indexOf('ServiceNow') >= 0);
                if (!svcnowPlugin) {
                    throw 'No ServiceNow plugin';
                }

                return this.naSvc.listPluginInstances(this.accountSvc.token).pipe(
                    concatMap((res: IAPIRx<{ itemList: IPluginInstance[], limit: number, skip: number, total: number }>) => {
                        if (res.error != 0) {
                            throw res.errorMessage;
                        }

                        const svcnowPluginInstance: IPluginInstance = res.data.itemList.find(pInst => pInst.pluginInstanceOwnerID === this.accountSvc.enterpriseAccountID && pInst.pluginID === svcnowPlugin.pluginID);
                        if (!svcnowPluginInstance) {
                            // create one servicenow plugin instance
                            return this.naSvc.createPluginInstance(
                                svcnowPlugin.pluginID,
                                {
                                    credential: '1234',
                                    callbackUrl: 'https://servicenow.plugins.oniadea.com/workplace/plugin/event',
                                    remark: `Created by IAdeaCare enterprise: ${this.accountSvc.enterpriseAccountID}`
                                },
                                ['EVENT_VIRTUALDEVICE_UNPAIR'],
                                this.accountSvc.token
                            ).pipe(
                                map((res: IAPIRx<IPluginInstance>) => {
                                    if (res.error != 0) {
                                        throw res.errorMessage;
                                    }

                                    return res.data;
                                })
                            )
                        }

                        return of(svcnowPluginInstance);
                    }),
                );
            }),
            catchError((err) => {
                return of(err);
            })
        ).subscribe((pluginInstance: IPluginInstance) => {
            this.pluginSvc.savePluginInstance(pluginInstance);
            this._loading = false;
            this.router.navigate([this.menuSvc.getAppServiceNowRouter('register')]);
        });

        /*
        this.naSvc.runPluginHealthCheck('c8d8c1e0-aeed-4a46-b0c4-a3b7b6a5a4e4', this.accountSvc.token).subscribe(res => {
            console.log('------------ run plugin check: ', res);
        });
        */
        /*
        this.naSvc.removePluginInstance("a57bc64a-7c1a-493e-86b4-37fbe734fe14", this.accountSvc.token).subscribe(res => {
            console.log('--------- remove plugin instance: ', res);
        });
        */
    }
}