import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../api.wrapper.base';
import { APIListPermissionSetManager } from './api.permissionSet.list';

export class APIOtherWrapper extends APIWrapperBase {
    ListPermissionSet: APIListPermissionSetManager;

    constructor(private http: HttpClient) {
        super();

        this.ListPermissionSet = new APIListPermissionSetManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.ListPermissionSet
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}