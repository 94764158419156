import { HttpClient, HttpResponse } from '@angular/common/http';
import { IAPIRx, APIBaseManager } from '../../api.base';
import { IVirtualDeviceRxData } from './virtualDevice.common';

export interface IListVirtualDeviceQueryParameter {
    detail?: string[] | string;
    limit?: number; //how much to query
    skip?: number; //start index
    search?: { [searchKey: string]: string } | string;
    sort?: { [sortKey: string]: number } | string;
}

export interface IListVirtualDeviceRxData {
    total: number,
    limit: number,
    skip: number,
    itemList: IVirtualDeviceRxData[]
}

//GET https://.../iAdeaCare/v1/virtualDevices
export class APIListVirtualDeviceManager extends APIBaseManager<void, IListVirtualDeviceQueryParameter, void, IListVirtualDeviceRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListVirtualDevice';
    }

    protected getRequestURL(pathParameters: void, queryParameters: IListVirtualDeviceQueryParameter): string {
        if (Array.isArray(queryParameters.detail)) {
            queryParameters.detail = queryParameters.detail.join(',');
        }
        
        if (queryParameters.sort instanceof Object) {
            queryParameters.sort = Object.keys(queryParameters.sort).map(key => key + ':' + queryParameters.sort[key]).join(',');
        }

        if (queryParameters.search instanceof Object) {
            queryParameters.search = JSON.stringify(queryParameters.search);
        }

        return super.getRequestURL() + 'virtualDevices' + this.transformObjectQueryParameterToStr(queryParameters);
    }

    protected doGetAftermathAction(res: HttpResponse<IAPIRx<IListVirtualDeviceRxData>>): boolean {
        const serverTime = parseInt(res.headers.get('IAdea-Server-Timestamp-Milliseconds'));
        if (serverTime) {
            res.body.serverTime = serverTime;
        }

        return true;
    }
}