<div class="row">
    <div class="col-12 col-md-6">
        <div class="txt-bold">SCEP enrollment settings</div>
        <div class="ml-2">
            <div class="form-group row">
                <label for="col-scep-challengePwd" class="col-12 col-sm-5 col-md-6 col-form-label-sm required">
                    Challenge password
                </label>
                <div class="col-12 col-sm-7 col-md-6">
                    <input #scepChallengePwd id="col-scep-challengePwd" type="password" class="form-control form-control-sm">
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div class="txt-bold">802.1x EAP settings</div>
        <div class="ml-2">
            <div class="form-group row">
                <label for="col-scep-eap-method" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                    EAP method
                </label>
                <div class="col-12 col-sm-7 col-md-8">
                    <div id="col-scep-eap-method" class="form-control-plaintext-sm">
                        {{ _challengeInfo.eapMethod }}
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="col-scep-eap-domain" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                    Domain
                </label>
                <div class="col-12 col-sm-7 col-md-8">
                    <input #scepEAPDomain id="col-scep-eap-domain" type="text" class="form-control form-control-sm">
                </div>
            </div>
            <div class="form-group row">
                <label for="col-scep-eap-identity" class="col-12 col-sm-5 col-md-4 col-form-label-sm required">
                    Identity
                </label>
                <div class="col-12 col-sm-7 col-md-8">
                    <div id="col-scep-eap-identity" class="position-relative">
                        <button id="dropdownScepEAPIdentityLink" type="button"
                            class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
                            {{ _challengeInfo.eapIdentity }}
                        </button>
                        <ul class="dropdown-menu option-list w-100" data-backdrop="static" data-keyboard="false"
                            aria-labelledby="dropdownScepEAPIdentityLink">
                            <li *ngFor="let identity of EAP_IDENTITY_LIST"
                                [class.active]="_challengeInfo.eapIdentity === identity"
                                (click)="changeEAPIdentity(identity)">
                                {{ identity }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>