<div class="row login-page">
  <h1 class="col-12 title">Activate account</h1>
  <div class="col-xl-6 offset-xl-3 col-md-8 offset-md-2 col-12">
    <div class="wrapper">
      <h5 class="sub-title">Please input the activation code from your Email to activate your account.</h5>
      <div class="content">
        <form #signUpActivateForm="ngForm">
          <div class="form-group mb-0">
            <label for="regActivateCode">Activation code</label>
            <input id="regActivateCode" name="regActivateCode" type="text" class="form-control"
              [(ngModel)]="_activateCode" required [disabled]="_page === _enumPage.submit" #regActivateCode="ngModel">
            <div *ngIf="regActivateCode.invalid && (regActivateCode.dirty || regActivateCode.touched)"
              class="alert alert-danger">
              <div *ngIf="regActivateCode.errors.required">
                Activation code is required
              </div>
            </div>
          </div>
        </form>
        <small>
          *** If you do not receive the confirmation E-mail within a few minutes, please check your Junk E-mail folder.
        </small>
        <div *ngIf="_page === _enumPage.result" class="mt-2">
          <div *ngIf="_errorMessage; else templateActivatePass" class="red-1">{{ _errorMessage }}</div>
          <ng-template #templateActivatePass>
            <div class="green-3">
              Your account has successfully activated.
            </div> 
          </ng-template>
        </div>
      </div>
      <div class="footer">
        <button type="button" class="btn btn-custom btn-blue" [disabled]="_page === _enumPage.submit" [routerLink]="['/account/login']">Back to login</button>
        <button [disabled]="!signUpActivateForm.form.valid || _page === _enumPage.submit" class="btn btn-custom btn-green"
          type="submit" (click)="submit()">
          Activate
        </button>
        <div class="ml-2" [class.loading-box]="_page === _enumPage.submit"></div>
      </div>
    </div>
  </div>
</div>