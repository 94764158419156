import { DeviceService } from "../../../device/device.service";

export class AlertHelper {
    static transformReceiverList(receivers: string[]): string {
        return receivers ? receivers.join(', ') : '';
    }

    static transformApplyDeviceList(devSvc: DeviceService, deviceIDList: { virtualDeviceID: string }[]): string {
        return deviceIDList.map(d => devSvc.getDeviceName(d.virtualDeviceID)).join(', ');
    }
}