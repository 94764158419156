<div class="row login-page">
  <h1 class="col-12 title">Forget password</h1>
  <div class="col-xl-6 offset-xl-3 col-md-8 offset-md-2 col-12">
    <div class="wrapper">
      <p class="sub-title">Please input your ID for IAdeaCare service. We will guide you to reset your password.</p>
      <div class="content">
        <form #pwdForgetForm="ngForm">
          <div class="form-group">
            <label for="regEmail">Login account</label>
            <input id="regEmail" name="regEmail" type="text" class="form-control" [(ngModel)]="_regEmail" required
              email #regEmail="ngModel">
            <div *ngIf="regEmail.invalid && (regEmail.dirty || regEmail.touched)" class="alert alert-danger">
              <div *ngIf="regEmail.errors.required">
                Account is required
              </div>
              <div *ngIf="regEmail.errors.email">
                Invalid login ID format
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="footer">
        <button [disabled]="_isRunning" class="btn btn-custom btn-gray" type="button"
          [routerLink]="['/account/login']">Cancel</button>
        <button [disabled]="!pwdForgetForm.form.valid || _isRunning" class="btn btn-custom btn-green" type="submit"
          (click)="submit()">Submit</button>
        <div class="ml-2" [class.loading-box]="_isRunning"></div>
      </div>
    </div>
  </div>
</div>