import { InjectionToken } from '@angular/core';

import { LANG_EN_NAME, LANG_EN_TRANS } from './langs/lang-en';
import { LANG_TW_NAME, LANG_TW_TRANS } from './langs/lang-zhTW';

export const TRANSLATIONS_TOKEN = new InjectionToken('translations');

const dictionary = {};
dictionary[LANG_EN_NAME] = LANG_EN_TRANS;
dictionary[LANG_TW_NAME] = LANG_TW_TRANS;

export const TRANSLATION_PROVIDERS = [
    { provide: TRANSLATIONS_TOKEN, useValue: dictionary }
];