import { Component, ViewChild, OnInit } from '@angular/core';
import { AppConfigService } from '../../app.config';
import { ITheme } from '../../theme/theme';
import { ThemeService } from '../../theme/theme.service';
import { LoginCompanyDirective } from './login-company.directive';
import { LoginCompanyService, LoginCompanyItem } from './login-company.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    readonly Enable_THEME: boolean = AppConfigService.configs.common.theme.enabled;
    private readonly COMPANY: string = AppConfigService.configs.common.company.toLocaleLowerCase();

    _themeList: ITheme[];
    _theme: ITheme;
    
    @ViewChild(LoginCompanyDirective, { static: true }) companyHost: LoginCompanyDirective;

    constructor(
        private loginCompanySvc: LoginCompanyService,
        private themeSvc: ThemeService) {     
    }

    ngOnInit(): void {
        this._themeList = this.themeSvc.getThemeList();
        this._theme = this.themeSvc.getCurrentTheme();

        const item: LoginCompanyItem = this.loginCompanySvc.getByName(this.COMPANY);
        if (item) {
            const viewContainerRef = this.companyHost.viewContainerRef;
            viewContainerRef.clear();
            viewContainerRef.createComponent(item.component);
        }
    }

    changeTheme(theme: ITheme): void {
        this._theme = this.themeSvc.setActiveTheme(theme);
    }

}