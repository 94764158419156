<div>
    <div class="form-group">
        <label for="col-create-userGroup-name" class="col-form-label-sm required">
            Group name
        </label>
        <div class="">
            <input id="col-create-userGroup-name" type="text" class="form-control form-control-sm"
                 [ngModel]="other.groupName"
                (ngModelChange)="changeGroupName($event)">
        </div>
    </div>
</div>