import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';

export interface IAckAlertNotificationPathParameter {
    alertNotificationID: string;
}

export class APIAckAlertNotificationManager extends APIBaseManager<IAckAlertNotificationPathParameter, void, void, void> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_AckAlertNotification';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: IAckAlertNotificationPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'accounts/alertNotifications/' + pathParameters.alertNotificationID + '/acknowledge';
    }
}