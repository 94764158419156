import { Component, OnInit } from '@angular/core';
import { IPolicyTypeFunc } from './policy-type.service';
import { PolicyType, PolicyDataGroupConfig, PolicyDataGroupConfigItem } from '../policy.data';
import { ConfigurableItemKey } from '../../../../lib/common/common.data';
import { HelperLib } from '../../../../lib/common/helper.lib';
import { AppStartOverlayInfo } from '../../../../../app/content/device/data/device-info';
import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { ScreenOffInfo, ScreenSaverInfo } from '../../../../uiElement/schedule/screen/screen.data';
import { MaintenancePlaybackInfo } from '../../../../uiElement/maintenancePlayback/mtPlayback.data';
import { PowerSaveInfo } from '../../../../uiElement/powerSave/power-save.data';
import { AppConfigService } from '../../../../app.config';
import { AndroidGroupType } from '../../../../../app/content/devfunc/firmware/firmware-data';
import { AccountService } from '../../../../entry/account.service';
import { AppStartPlaybackMode } from 'app/uiElement/appstart/appstart.data';
import { DailyRebootInfo } from 'app/uiElement/schedule/reboot/dailyReboot.data';

@Component({
    templateUrl: './policy-group-config.component.html',
    styleUrls: ['./policy-group-config.component.css', '../policy.style.css']
})
export class PolicyTypeGroupConfigComponent implements OnInit, IPolicyTypeFunc {
    readonly Enable_screenOff: boolean = AppConfigService.configs.devPage.func.basicConfig.element.screenOff.enabled;
    readonly Enable_maintenance: boolean = AppConfigService.configs.devPage.func.basicConfig.element.maintenancePlayback;
    readonly Enable_powersave: boolean = AppConfigService.configs.devPage.func.basicConfig.element.powersave;
    readonly Enable_screenSaver: boolean = AppConfigService.configs.devPage.func.basicConfig.element.screenSaver;

    onPolicyDataValidate?: (valid: boolean) => void;
    type: PolicyType;
    _data: PolicyDataGroupConfig;
    set data(d: PolicyDataGroupConfig) {
        this._data = d;
    }
    isInEdit: boolean;
    _configList: PolicyDataGroupConfigItem[] = [];
    _selectedConfigCount: number = 0;
    _enumConfigKey: typeof ConfigurableItemKey = ConfigurableItemKey;
    _appstartExtraList: {
        property: string;
        displayName: string;
        condition?: (androidVersion: AndroidGroupType) => boolean
    }[] = [];
    _config: PolicyDataGroupConfigItem;

    constructor(private constantSvc: ConstantService, private accountSvc: AccountService) {
        this._appstartExtraList = this.constantSvc.getAppstartExtraList();
    }

    ngOnInit(): void {
        // Appstart
        this._data.configMap[ConfigurableItemKey.AppStart] = new PolicyDataGroupConfigItem(ConfigurableItemKey.AppStart, 'Appstart', {
            valid: this._data.configMap[ConfigurableItemKey.AppStart]?.valid ?? true,
            checked: this._data.configMap[ConfigurableItemKey.AppStart]?.checked,
            defaultSettingMap: this._data.configMap[ConfigurableItemKey.AppStart]?.settingMap,
            resetFunc: (s: PolicyDataGroupConfigItem) => {
                s.settingMap = s.settingMap || {};
                s.settingMap[this.constantSvc.DEVKEY_APPSTART_CONTENTURL] = { langKey: 'Content URL', value: '' };
                s.settingMap[this.constantSvc.DEVKEY_APPSTART_OVERLAY] = { langKey: 'Overlay', value: new AppStartOverlayInfo() };
                // set screen saver by solution
                // s.settingMap[this.constantSvc.DEVKEY_APPSTART_SCREENSAVER] = { langKey: 'Screen saver', value: new AppStartScreensaverInfo() };

                this.constantSvc.getAppstartExtraList().forEach(extra => {
                    s.settingMap[extra.property] = { langKey: extra.displayName, value: false };
                });
            }
        });

        // Powersave
        if (this.Enable_powersave) {
            this._data.configMap[ConfigurableItemKey.Powersave] = new PolicyDataGroupConfigItem(ConfigurableItemKey.Powersave, 'Power save', {
                valid: this._data.configMap[ConfigurableItemKey.Powersave]?.valid ?? true,
                checked: this._data.configMap[ConfigurableItemKey.Powersave]?.checked,
                defaultSettingMap: this._data.configMap[ConfigurableItemKey.Powersave]?.settingMap,
                resetFunc: (s: PolicyDataGroupConfigItem) => {
                    s.settingMap = s.settingMap || {};
                    s.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE] = { langKey: 'Disable power save', value: true };
                    s.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT] = { langKey: 'Power save timeout', value: 10 };
                    s.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_ACTION] = { langKey: 'Power save action', value: 'sleep' };
                }
            });
        }

        // Daily reboot
        this._data.configMap[ConfigurableItemKey.DailyReboot] = new PolicyDataGroupConfigItem(ConfigurableItemKey.DailyReboot, 'Daily reboot', {
            valid: this._data.configMap[ConfigurableItemKey.DailyReboot]?.valid ?? true,
            checked: this._data.configMap[ConfigurableItemKey.DailyReboot]?.checked,
            defaultSettingMap: this._data.configMap[ConfigurableItemKey.DailyReboot]?.settingMap,
            resetFunc: (s: PolicyDataGroupConfigItem) => {
                s.settingMap = s.settingMap || {};
                s.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT] = { langKey: 'key-dev-dailyReboot', value: { days: [], time: '04:00' } };
            }
        });

        // Volume
        this._data.configMap[ConfigurableItemKey.Volume] = new PolicyDataGroupConfigItem(ConfigurableItemKey.Volume, 'Volume', {
            valid: this._data.configMap[ConfigurableItemKey.Volume]?.valid ?? true,
            checked: this._data.configMap[ConfigurableItemKey.Volume]?.checked,
            defaultSettingMap: this._data.configMap[ConfigurableItemKey.Volume]?.settingMap,
            resetFunc: (s: PolicyDataGroupConfigItem) => {
                s.settingMap = s.settingMap || {};
                s.settingMap[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL] = { langKey: 'Volume', value: 20 };
            }
        });

        // Timezone
        this._data.configMap[ConfigurableItemKey.Timezone] = new PolicyDataGroupConfigItem(ConfigurableItemKey.Timezone, 'Timezone', {
            isDefaultValid: false,
            valid: this._data.configMap[ConfigurableItemKey.Timezone]?.valid ?? false,
            checked: this._data.configMap[ConfigurableItemKey.Timezone]?.checked,
            defaultSettingMap: this._data.configMap[ConfigurableItemKey.Timezone]?.settingMap,
            availableSettings: HelperLib.getDefaultTimezoneList(),
            resetFunc: (s: PolicyDataGroupConfigItem) => {
                s.settingMap = s.settingMap || {};
                s.settingMap[this.constantSvc.DEVKEY_TIME_TIMEZONE] = { langKey: 'Timezone', value: undefined };
            }
        });

        // Time server
        this._data.configMap[ConfigurableItemKey.Timeserver] = new PolicyDataGroupConfigItem(ConfigurableItemKey.Timeserver, 'Time server', {
            valid: this._data.configMap[ConfigurableItemKey.Timeserver]?.valid ?? true,
            checked: this._data.configMap[ConfigurableItemKey.Timeserver]?.checked,
            defaultSettingMap: this._data.configMap[ConfigurableItemKey.Timeserver]?.settingMap,
            resetFunc: (s: PolicyDataGroupConfigItem) => {
                s.settingMap = s.settingMap || {};
                s.settingMap[this.constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED] = { langKey: 'Enable time server', value: false };
                s.settingMap[this.constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE] = { langKey: 'Time server sync location', value: 'default' }
            }
        });

        // Orientation
        this._data.configMap[ConfigurableItemKey.DisplayOrientation] = new PolicyDataGroupConfigItem(ConfigurableItemKey.DisplayOrientation, 'Display orientation', {
            valid: this._data.configMap[ConfigurableItemKey.DisplayOrientation]?.valid ?? true,
            checked: this._data.configMap[ConfigurableItemKey.DisplayOrientation]?.checked,
            defaultSettingMap: this._data.configMap[ConfigurableItemKey.DisplayOrientation]?.settingMap,
            availableSettings: this.constantSvc.DISPLAY_ORIENTATION_LIST,
            resetFunc: (s: PolicyDataGroupConfigItem) => {
                s.settingMap = s.settingMap || {};
                s.settingMap[this.constantSvc.DEVKEY_HD_VIDEO_ROTATION] = { langKey: 'Display orientation', value: this.constantSvc.DISPLAY_ORIENTATION_LIST[0] };
            }
        });

        // Lock screen - screen off
        if (this.Enable_screenOff) {
            this._data.configMap[ConfigurableItemKey.ScreenOff] = new PolicyDataGroupConfigItem(ConfigurableItemKey.ScreenOff, 'Lock screen - screen off', {
                valid: this._data.configMap[ConfigurableItemKey.ScreenOff]?.valid ?? true,
                checked: this._data.configMap[ConfigurableItemKey.ScreenOff]?.checked,
                defaultSettingMap: this._data.configMap[ConfigurableItemKey.ScreenOff]?.settingMap,
                resetFunc: (s: PolicyDataGroupConfigItem) => {
                    s.settingMap = s.settingMap || {};
                    s.settingMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF] = { langKey: 'Screen off', value: new ScreenOffInfo() }
                }
            });
        }

        // Lock screen - screen saver
        if (this.Enable_screenSaver) {
            this._data.configMap[ConfigurableItemKey.ScreenSaver] = new PolicyDataGroupConfigItem(ConfigurableItemKey.ScreenSaver, 'Lock screen - screen saver', {
                valid: this._data.configMap[ConfigurableItemKey.ScreenSaver]?.valid ?? true,
                checked: this._data.configMap[ConfigurableItemKey.ScreenSaver]?.checked,
                defaultSettingMap: this._data.configMap[ConfigurableItemKey.ScreenSaver]?.settingMap,
                resetFunc: (s: PolicyDataGroupConfigItem) => {
                    s.settingMap = s.settingMap || {};
                    s.settingMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENSAVER] = { langKey: 'Screen saver', value: new ScreenSaverInfo() }
                }
            });
        }

        // Maintenance
        if (this.Enable_maintenance) {
            this._data.configMap[ConfigurableItemKey.MaintenancePlayback] = new PolicyDataGroupConfigItem(ConfigurableItemKey.MaintenancePlayback, 'Maintenance playback', {
                valid: this._data.configMap[ConfigurableItemKey.MaintenancePlayback]?.valid ?? true,
                checked: this._data.configMap[ConfigurableItemKey.MaintenancePlayback]?.checked,
                defaultSettingMap: this._data.configMap[ConfigurableItemKey.MaintenancePlayback]?.settingMap,
                resetFunc: (s: PolicyDataGroupConfigItem) => {
                    s.settingMap = s.settingMap || {};
                    s.settingMap[this.constantSvc.DEVKEY_FAKE_MAINTENANCE] = { langKey: 'Maintenance playback', value: new MaintenancePlaybackInfo() }
                }
            });

        }

        this._configList = Object.keys(this._data.configMap).filter(key => !this.isInEdit ? this._data.configMap[key].checked : true).map(key => this._data.configMap[key]);
        this.validateData();
    }

    selectConfig(c: PolicyDataGroupConfigItem): void {
        this._config = !this._config || this._config.key !== c.key ? c : null;
        this.validateData();
    }

    enableConfig(c: PolicyDataGroupConfigItem, checked: boolean = false): void {
        c.checked = checked;
        if (!checked) {
            c.reset?.(c);
            if (this._config && this._config.key == c.key) {
                this._config = null;
            }
        }
        this.validateData();
    }

    onPolicySettingChanged(itemKey: ConfigurableItemKey, c: PolicyDataGroupConfigItem, rawData: any): void {
        let isValid: boolean = true;
        switch (itemKey) {
            case ConfigurableItemKey.AppStart:
                {
                    const data: { playbackMode?: AppStartPlaybackMode, property?: { name: string, value: any } } = rawData;
                    if (data.property && data.property.name) {
                        c.settingMap[data.property.name].value = data.property.value;
                    }
                }
                break;
            case ConfigurableItemKey.OverlayQRCode:
                {
                    const data: AppStartOverlayInfo = rawData;
                    c.settingMap[this.constantSvc.DEVKEY_APPSTART_OVERLAY].value = data;
                }
                break;
            case ConfigurableItemKey.ScreenSaver:
                {
                    const data: ScreenSaverInfo = rawData;
                    c.settingMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENSAVER].value = data;
                    isValid = data.isValid;
                }
                break;
            case ConfigurableItemKey.Powersave:
                {
                    const data: { data: PowerSaveInfo, isValid: boolean } = rawData;
                    c.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE].value = !data.data.enabled;
                    c.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT].value = data.data.timeout;
                    c.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_ACTION].value = data.data.mode;
                    isValid = data.isValid;
                }
                break;
            case ConfigurableItemKey.ScreenOff:
                {
                    const data: ScreenOffInfo = rawData;
                    c.settingMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF].value = data;
                    isValid = data.isValid;
                }
                break;
            case ConfigurableItemKey.MaintenancePlayback:
                {
                    const data: MaintenancePlaybackInfo = rawData;
                    c.settingMap[this.constantSvc.DEVKEY_FAKE_MAINTENANCE].value = data;
                    isValid = data.isValid;
                }
                break;
            case ConfigurableItemKey.DisplayOrientation:
                {
                    const data: string | number = rawData;
                    c.settingMap[this.constantSvc.DEVKEY_HD_VIDEO_ROTATION].value = data;
                    isValid = true;
                }
                break;
            case ConfigurableItemKey.Volume:
                {
                    const data: number = rawData;
                    c.settingMap[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL].value = data;
                    isValid = true;
                }
                break;
            case ConfigurableItemKey.Timezone:
                {
                    const data: string = rawData;
                    c.settingMap[this.constantSvc.DEVKEY_TIME_TIMEZONE].value = data;
                    isValid = true;
                }
                break;
            case ConfigurableItemKey.Timeserver:
                {
                    const data: { enabled: boolean, isValid: boolean, url: string } = rawData;
                    c.settingMap[this.constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED].value = data.enabled;
                    c.settingMap[this.constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE].value = data.url;
                    isValid = data.isValid;
                }
                break;
            case ConfigurableItemKey.DailyReboot:
                {
                    const data: DailyRebootInfo = rawData;
                    // do not support old format
                    if (c.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_ENABLED]) {
                        c.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_ENABLED].value = data.enabled;
                    }
                    if (c.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_TIME]) {
                        c.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_TIME].value = data.time;
                    }
                    if (c.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT]) {
                        c.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT].value = data.transform();
                    }
                    
                    isValid = true;
                }
                break;
        }

        c.valid = isValid;
        this.validateData({ config: c, valid: c.valid });
    }

    private validateData(validateData?: { config: PolicyDataGroupConfigItem, valid: boolean }): void {
        if (!this.isInEdit) {
            return;
        }

        // validate config only
        if (validateData && !validateData.valid) {
            return this.onPolicyDataValidate?.(false);
        }

        // validate all configs if needed
        const selectedConfigList = this._configList.filter(c => c.checked);
        this._selectedConfigCount = selectedConfigList.length;
        let isValid: boolean = !!selectedConfigList.length;

        for (let c of selectedConfigList) {
            if (c.checked && !c.valid) {
                isValid = false;
                break;
            }
        }

        this.onPolicyDataValidate?.(isValid);
    }
}