<div id="id-page-login" class="login-page">
  <div class="header">
    <div class="center">
      {{ _slogan }}
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-8 offset-sm-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
      <div class="login-box">
        <div class="text-center">
          <img  [src]="appSvc.logoDarkUrl | safe: 'url'" style="width: 70%; object-fit: contain;">
        </div>    
        <div class="content">
          <div id="id-btn-login" class="row mx-0" (click)="loginICare()">
            <svg class="col-4 icon">
              <use xlink:href="#login_icon_microsoft" />
            </svg>
            <span class="col-8 text">
              Login with Microsoft
            </span>
            <a #msLoginLink [href]="_msAuthUrl" class="d-none">
              Login with Microsoft
            </a>
          </div>
          <div class="release-info" style="text-align:center;">
            <p>Version: {{ _appVersion }}</p>
            <p>Release: {{ _releaseDate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row footer">
    <div class="col-12 col-sm-8 offset-sm-2 col-xl-4 offset-xl-4">
      <div *ngIf="_enableRecaptcha" class="recaptcha">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </div>
  </div>
</div>
<svg xmlns="http://www.w3.org/2000/svg" style="display: none">
  <symbol id="login_icon_microsoft" viewBox="0 0 223 220">
    <path class="st3" d="M99,27c35-4.9,69.9-9.7,104.9-14.5c-0.1,30.8,0,61.6,0,92.4c-35,0-70,0-105,0" />
    <path class="st3" d="M15.1,38.8c26.6-3.8,53.3-7,79.9-11.4c0.2,25.9,0,51.7,0.1,77.6c-26.6,0.2-53.2-0.3-79.8,0.3" />
    <path class="st3" d="M15.3,108.8c26.6,0,53.2,0,79.8,0c0.1,25.7,0,51.4,0.1,77.2c-26.7-2.4-53.2-7.4-79.8-10.6" />
    <path class="st3" d="M98.9,186.8c0.2-26-0.2-52.1,0.2-78.1c34.9,0.2,69.9-0.1,104.8,0.1c0,30.9,0,61.7,0,92.6" />
  </symbol>
</svg>
