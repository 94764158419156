import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { IScepInfo } from './api.ent.scep.data';

export interface IEnterpriseRemoveScepPathParameter {
    SCEPSettingID: string;
}

export class APIEnterpriseRemoveScepManager extends APIBaseManager<IEnterpriseRemoveScepPathParameter, void, void, void> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_RemoveScep';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IEnterpriseRemoveScepPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/scepSettings/' + pathParameters.SCEPSettingID;
    }
}