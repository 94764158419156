<div class="group-tree-wrapper">
    <div *ngIf="_showTitle" class="d-flex align-items-center">
        <label *ngIf="_title" class="mr-2">{{ _title }}</label>
        <div class="input-group input-group-sm mb-1 w-50" [class.w-100]="!_title">
            <input #search type='text' class="form-control form-control-sm search-box" [class.active]="_filter?.searchContent" [disabled]="_loading" name='search'
                placeholder="Search device group ..." aria-label="Search device group">
            <div class="input-group-append">
                <span class="input-group-text">
                    <i class="fas fa-search"></i>
                </span>
            </div>
        </div>
        <div class="ml-auto">
            <div class="group-ctrls">
                <ng-template [ngIf]="_enableUnfoldFunc">
                    <div class="icon" (click)="unfoldGroups()">
                        <i class="fas fa-folder" data-toggle="tooltip" title="Collapse group"></i>
                    </div>
                    <div class="icon" (click)="expandGroups()">
                        <i class="fas fa-folder-open" data-toggle="tooltip" title="Expand group"></i>
                    </div>
                </ng-template>
                <ng-template [ngIf]="supportGroupUpdate() && _groupMode === _enumGroupMode.edit">
                    <div data-toggle="modal" [attr.data-target]="'#groupFuncModalHost-' + _id" class="icon"
                        (click)="createGroup()">
                        <i class="fas fa-folder-plus" data-toggle="tooltip" title="Create group"></i>
                    </div>
                </ng-template>
                <div *ngIf="_enableCollapseFunc" class="icon" (click)="hideGroup()">
                    <i class="fas fa-times-circle" data-toggle="tooltip" title="Hide group list"></i>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="_targetPolicyGroup" class="container-fluid policy-detail-block my-1 py-1">
        <div class="form-group row">
            <span for="col-policy" class="col-12">
                Policy of group <strong class="green-3">{{ _targetPolicyGroup.name }}</strong>
                <button type="button" class="close" (click)="closePolicyDetailView()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </span>
            <div class="col-12">
                <span *ngIf="_loadingPolicy; else templateShowPolicyName" class="text-loading"
                    [attr.data-text]="TEXT_LOADING">{{ TEXT_LOADING }}</span>
                <ng-template #templateShowPolicyName>
                    <div *ngFor="let item of _deviceGroupPolicyList" class="form-group row">
                        <label [for]="'col-policy-' + item.type" class="col-6 col-form-label col-form-label-sm">
                            {{ item.type }}
                        </label>
                        <div class="col-6">
                            <div [id]="'col-policy-' + item.type"
                                class="form-control-plaintext form-control-plaintext-sm">
                                <div *ngFor="let p of item.policyList">
                                    <a [routerLink]="'/app/device/policy/' + p.id" data-dismiss="modal">{{ p.name }}</a>
                                    <i class="fas fa-external-link-alt ml-2 blue-2"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="group-tree-block" [class.active]="_filter.isFilterApplied || _filter.searchContent" [class.border]="_styleBorder"
        [style.height]="_styleH && !_enableCollapseFunc ? _styleH : 'auto'">
        <na-dev-group-tree [group]="_g" [mode]="_groupMode" [moveTarget]="_moveTarget" [showDevice]="_showDevice"
            [showPolicy]="_policyDisplayMode === 'embed'" [pickMap]="_pickMap" [statistic]="_groupStatistic"
            [filter]="_filter" (onGroupMoveTargetChanged)="onMoveTargetChange($event)"
            (onGroupPolicyInspected)="onSubgroupPolicyInspect($event)" (onGroupSelected)="onGroupSelect($event)">
        </na-dev-group-tree>
    </div>
</div>
<div [id]="'groupFuncModalHost-' + _id" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog"
    tabindex="-1">
    <ng-template dev-group-func-host></ng-template>
</div>