import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { GroupRawInfo } from './api.group.common';

export interface IBatchUpdateGroupTxData {
    groupList: {
        groupID: string,
        groupName?: string,
        parentID?: string
    }[]
}

export class APIBatchUpdateGroupManager extends APIBaseManager<void, void, IBatchUpdateGroupTxData, GroupRawInfo[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_BatchUpdateGroup';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'deviceGroups';
    }
}