<div class="form-group row">
  <label for="col-fw-update-method" class="col-4 col-md-2 col-form-label txt-bold">
    Method :
  </label>
  <div class="col-8 col-md-10">
    <div id="col-fw-update-method" class="form-control-plaintext-sm">
      <button type="button" class="btn btn-sm btn-action btn-light"
        [class.active]="_fwUpdateMethod === _enumFwUpdateMethod.Latest"
        (click)="changeUpdateMethod(_enumFwUpdateMethod.Latest)">
        <i *ngIf="_fwUpdateMethod === _enumFwUpdateMethod.Latest" class="fas fa-check mr-2"></i>
        Latest version
      </button>
      <button type="button" class="btn btn-sm btn-action btn-light ml-2"
        [class.active]="_fwUpdateMethod === _enumFwUpdateMethod.FromURL"
        (click)="changeUpdateMethod(_enumFwUpdateMethod.FromURL)">
        <i *ngIf="_fwUpdateMethod === _enumFwUpdateMethod.FromURL" class="fas fa-check mr-2"></i>
        Specific URL
      </button>
    </div>
  </div>
</div>
<!-- fw update schedule -->
<div *ngIf="_fwSchedule">
  <div class="form-group row">
    <label for="col-fw-schedule-update" class="col-4 col-md-2 col-form-label txt-bold">
      Schedule :
    </label>
    <div class="col-8 col-md-10 d-flex align-items-center">
      <div id="col-fw-schedule-update" class='switch-container'>
        <input id="cmn-toggle-fw-update" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
          [checked]="_fwSchedule.enabled" (change)="enableFwSchedule($event.target.checked)">
        <label for="cmn-toggle-fw-update">
          <span class="sr-only">Enable firmware schedule update</span>
        </label>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-12">
      <div class="d-flex align-items-center flex-wrap border py-2 px-3 ml-2">
        <span>Update on</span>
        <input type="date" class="ml-2" [value]="_fwSchedule.date" [disabled]="!_fwSchedule.enabled"
          aria-label="Firmware schedule date" (change)="changeFwScheduleDate($event.target.value)">
        <span class="ml-2">starting at</span>
        <div class="btn-group ml-2">
          <button id="dropdownFirmwareScheduleTimeLink" type="button"
            class="btn btn-sm btn-action btn-light dropdown-toggle" [disabled]="!_fwSchedule.enabled"
            data-toggle="dropdown">
            {{ _fwSchedule.time }}
            <span class="sr-only">modify firmware update time</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownFirmwareScheduleTimeLink"
            style="min-width:300px">
            <div class="px-2">
              <na-timePicker [time]="_fwSchedule.time" [minuteIncre]="5" [showBorder]="false"
                [showSelectionTime]="false" (onTimeChanged)="changeFwScheduleTime($event)">
              </na-timePicker>
            </div>
          </ul>
        </div>
        <div class="btn-group ml-2">
          <button id="dropdownFirmwareScheduleOffsetLink" type="button"
            class="btn btn-sm btn-action btn-light dropdown-toggle" [disabled]="!_fwSchedule.enabled"
            data-toggle="dropdown">
            GMT <ng-template [ngIf]="_fwSchedule.offset >= 0">+</ng-template>{{ _fwSchedule.offset }}:00
            <span class="sr-only">modify firmware update time offset</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-left option-list" aria-labelledby="dropdownFirmwareScheduleOffsetLink">
            <li *ngFor="let o of GMTLIST" (click)="changeFwScheduleTimeOffset(o)">
              GMT <ng-template [ngIf]="o >= 0">+</ng-template>{{ o }}:00
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="form-group row">
  <label for="col-fw-content" class="col-12 col-form-label txt-bold">
    Content :
  </label>
  <div class="col-12">
    <div id="col-fw-content" [ngSwitch]="_fwUpdateMethod">
      <ng-template [ngSwitchCase]="_enumFwUpdateMethod.FromURL">
        <div class="update-block border py-2 px-3 ml-2">
          <label for="fw-custom-url">Provide the download URL for your firmware file</label>
          <input #downloadLink id="fw-custom-url" type="text" class="form-control download-link"
            placeholder='http(s)://your.firmware.link/' [(ngModel)]="_customDownloadLink">
        </div>
      </ng-template>
      <ng-template [ngSwitchCase]="_enumFwUpdateMethod.Latest">
        <div class="list-group">
          <div class="list-group-item list-group-header black">
            <div class="row align-items-center">
              <div class="col title">
                Name
              </div>
              <div class="col title">
                Current Firmware
              </div>
              <div class="col title">
                Auto detect latest version
              </div>
              <div class="col title">
                Status
              </div>
            </div>
          </div>
          <div class="list-group-content list-group-hoverless">
            <div *ngFor="let dev of _devices" class="list-group-item">
              <div class="row align-items-center">
                <div class="col text-break">
                  {{ dev.virtualName }}
                </div>
                <div class="col text-break">
                  {{ dev.currentSettings[constantSvc.DEVKEY_INFO_FW_VERSION] }}
                </div>
                <div class="col text-break d-flex align-items-center">
                  <div *ngIf="!dev.trackingMap.has(constantSvc.TASKTYPE_CHECK_FIRMWARE); else templateTrackingLatestFw">
                    <div
                      *ngIf="dev.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_STATUS]; else templateUnknownFw">
                      <div [ngSwitch]="dev.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_STATUS]">
                        <ng-template [ngSwitchCase]="_enumFwUpdateStatus.WAITING_INSTALL">
                          {{ dev.currentSettings[constantSvc.DEVKEY_SYSTEM_UPDATE_FW_INFO]?.firmwareVersion }}
                          <i class="fas fa-info-circle ml-2" (click)="showFwInfo(dev, true)"></i>
                        </ng-template>
                        <ng-template [ngSwitchCase]="_enumFwUpdateStatus.WAITING_CHECK">
                          You're up to date.
                        </ng-template>
                        <ng-template [ngSwitchCase]="_enumFwUpdateStatus.CHECKING">
                          Checking...
                        </ng-template>
                        <ng-template [ngSwitchCase]="[_enumFwUpdateStatus.INSTALLING, _enumFwUpdateStatus.WAITING_REBOOT].includes(dev.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_STATUS]) ? dev.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_STATUS] : ''">
                          Updating...
                        </ng-template>
                      </div>
                    </div>
                    <ng-template #templateUnknownFw>
                      Unknown <small class="d-block">(device firmware may be too old)</small>
                    </ng-template>
                    <small *ngIf="dev.currentSettings[constantSvc.DEVKEY_SYSTEM_UPDATE_FW_LASTCHECKTIME]"
                      class="d-block badge badge-info font-weight-normal">
                      LST: {{ dev.currentSettings[constantSvc.DEVKEY_SYSTEM_UPDATE_FW_LASTCHECKTIME] |
                      date:'yyyy-MM-dd HH:mm:ss'}}
                    </small>
                  </div>
                  <ng-template #templateTrackingLatestFw>
                    <span class="lds-dual-ring">
                    </span>
                  </ng-template>

                </div>
                <div class="col text-break">
                  <div>
                    <button type="button" class="btn btn-sm btn-action btn-light"
                      [disabled]="dev.trackingMap.has(constantSvc.TASKTYPE_CHECK_FIRMWARE) || [_enumFwUpdateStatus.INSTALLING, _enumFwUpdateStatus.WAITING_REBOOT].includes(dev.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_STATUS])" (click)="checkLatestFW(dev)">
                      Check for updates
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="_activeDev && _activeDev.virtualId === dev.virtualId" class="fw-detail">
                <div class="hide">
                  <i class="fas fa-times-circle" (click)="showFwInfo(dev, false)"></i>
                </div>
                <div>
                  <strong>Version</strong>
                  {{ _activeDev.currentSettings[constantSvc.DEVKEY_SYSTEM_UPDATE_FW_INFO].firmwareVersion }}
                </div>
                <div class="mt-1">
                  <strong>Release date</strong>
                  {{ _activeDev.currentSettings[constantSvc.DEVKEY_SYSTEM_UPDATE_FW_INFO].releaseDate }}
                </div>
                <div class="mt-1">
                  <strong>Detail</strong>
                  <div class="fw-notice"
                    innerHTML="{{ transformFwNotice(_activeDev.currentSettings[constantSvc.DEVKEY_SYSTEM_UPDATE_FW_INFO].notice) }}">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ml-auto">
            <small>*** LST: Last check time</small>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>