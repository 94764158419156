<div class="row">
    <div class="col-0 col-md-4 col-xl-3">
        <na-user-group-view (onUserGroupChosen)="onUserGroupSelected($event)"></na-user-group-view>
    </div>
    <button #btnInvite type="button" class="d-none" data-toggle="modal" data-target="#userDlgFuncModalHost">
        Reinvite
    </button>
    <div class="col-12 col-md-8 col-xl-9 pl-md-0 mt-1 mt-md-0">
        <div *ngIf="supportUpdateUser()" class="row-action mb-2" [class.d-none]="_selectedUserGroup">
            <button type="button" class="btn btn-sm btn-action btn-light" data-toggle="modal"
                data-target="#userDlgFuncModalHost" (click)="invite()">
                <i class="fas fa-envelope mr-1"></i>
                New invitation
            </button>
            <button type="button" class="btn btn-sm btn-action btn-light" data-toggle="modal"
                data-target="#userDlgFuncModalHost" (click)="batchInvite()">
                <i class="fas fa-mail-bulk mr-1"></i>
                Bulk invitation
            </button>
            <button type="button" class="btn btn-sm btn-action btn-light" data-toggle="modal"
                data-target="#userDlgFuncModalHost" (click)="removeUsers()">
                <i class="fas fa-user-minus mr-1"></i>
                Delete user
            </button>
        </div>
        <div *ngIf="_selectedUserGroup">
            <na-user-group-detail [group]="_selectedUserGroup"></na-user-group-detail>
            <div class="block-titlebar mt-4">
                <strong class="txt-bold">Members</strong>
                <div *ngIf="supportUpdateUserGroup()" class="ml-auto">
                    <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit" data-toggle="modal"
                        data-target="#userDlgFuncModalHost" [disabled]="_selectedUserList.length === 0"
                        (click)="changeUserGroup()">
                        <i class="fas fa-exchange-alt mr-1"></i>
                        Change user group
                    </button>
                    <!--
                    <button *ngIf="_selectedUserGroupID !== userSvc.defaultUserGroupID" type="button"
                        class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-2" data-toggle="modal"
                        data-target="#userDlgFuncModalHost" [disabled]="_selectedUserList.length === 0"
                        (click)="removeUserFromGroup(_selectedUserGroup)">
                        <i class="fas fa-trash-alt mr-1"></i>
                        Remove user from group
                    </button>
                    -->
                </div>
            </div>
        </div>
        <div *ngIf="supportViewUser()" class="mt-2">
            <na-user-pickup [userGroup]="_selectedUserGroup" (onUserSelected)="onUserSelectionChange($event)" [limitHeight]="_selectedUserGroup ? '45vh' : ''"
                (onUserInviteRequest)="onUserInviteRequest($event)">
            </na-user-pickup>
        </div>
    </div>
</div>
<div id="userDlgFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template user-dlg-func></ng-template>
</div>