import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '../../translate/translate.module';
import { CustomPipeModule } from '../../lib/pipes/custom-pipe.module';

import { LoginComponent } from './login.component';
import { LoginAccentureComponent } from './accenture/login-accenture.component';
import { LoginIAdeaComponent } from './iadea/login-iadea.component';
import { LoginCompanyService } from './login-company.service';
import { LoginCompanyDirective } from './login-company.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        CustomPipeModule
    ],
    declarations: [
        LoginCompanyDirective,
        LoginComponent,
        LoginAccentureComponent,
        LoginIAdeaComponent,
    ],
    providers: [
        LoginCompanyService
    ]
})
export class LoginModule {}