<div class="troubleshoot-page">
  <div class='row'>
    <div class="col-lg-5 col-12">
      <div class="input-group">
        <input #search class="form-control form-control-sm" type='text' name='search' [disabled]="_loading"
          placeholder='Search in subject ...' aria-label="Search in troubleshoot subject"
          aria-describedby="id_toubleshoot_do_search" (keypress)="keyDown($event.keyCode)">
        <button id="id_toubleshoot_do_search" type="button" class="btn btn-sm btn-action btn-light"
          (click)="applySearch()">
          Search
        </button>
      </div>
      <div class="list-group mt-2">
        <div class="list-group-item list-group-header black">
          <div class="row">
            <span class="col title">Subject</span>
            <span class="col title">Issue date</span>
          </div>
        </div>
        <ng-template [ngIf]="!_loading">
          <div class="accordion list-group-content" id="logAccordionList">
            <ng-template ngFor let-ticket [ngForOf]="_ticketList">
              <div class="list-group-item" data-toggle="collapse" [attr.data-target]="'#ticket-' + ticket.id"
                aria-expanded="false" [ngClass]="_selectTicket?.id === ticket.id ? 'active': ''"
                (click)="selectTicket(ticket)">
                <div class="row align-items-center">
                  <span class="col col-main text-break">{{ ticket.subject }}</span>
                  <span class="col text-break">{{ ticket.issueDateTime | date:'yyyy-MM-dd HH:mm:ss Z' }}</span>
                </div>
              </div>
              <div [id]="'ticket-' + ticket.id" class="collapse border layout-mobile" data-parent="#logAccordionList">
                <ticket-report *ngIf="_selectTicket?.id === ticket.id" [ticket]="_selectTicket"></ticket-report>
              </div>
            </ng-template>
          </div>
        </ng-template>
        <div *ngIf="_loading" class="loading">
          <p>Please wait...</p>
          <div class="loading-box"></div>
        </div>
      </div>
      <div class="float-right">
        <na-pager [currentPage]="_currentPage" [numberInPage]="_numberInPage" [total]="_total"
          (onPageChanged)="onPageChange($event)">
        </na-pager>
      </div>
    </div>
    <div class="col-lg-7 col-12 pl-0 layout-desktop">
      <ticket-report [ticket]="_selectTicket"></ticket-report>
    </div>
  </div>
</div>