<div>
    <span class="text-info">Enabled :</span>
    <span class="ml-2">{{ _info?.enabled }}</span>
</div>
<div>
    <span class="text-info">Reboot time :</span>
    <span class="ml-2">{{ _info?.time }}</span>
</div>
<div>
    <span class="text-info">Reboot days :</span>
    <span class="ml-2">{{ _info?.transformWeekDays() }}</span>
</div>