import { Component, EventEmitter, Input, Output, ElementRef, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { IUIElement } from '../../uiElement.interface';
import { TimeserverInfo, TimeserverOption } from './timeserver.data';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    selector: 'na-timeserver',
    templateUrl: './timeserver.component.html',
    styleUrls: ['../../uiElement.style.css']
})
export class TimeserverComponent extends AutoUnsubscribeComponent implements IUIElement {
    _enumTimeserverOption: typeof TimeserverOption = TimeserverOption;
    _timeserverInfo: TimeserverInfo = new TimeserverInfo();

    @Input('showBorder') _showBorder: boolean = false;

    @Input('source')
    set source(v: string) {
        switch (v) {
            case TimeserverOption[TimeserverOption.default]:
                {
                    this._timeserverInfo.changeTimeserverOption(TimeserverOption.default);
                }
                break;
            case TimeserverOption[TimeserverOption.content]:
                {
                    this._timeserverInfo.changeTimeserverOption(TimeserverOption.content);
                }
                break;
            default:
                {
                    this._timeserverInfo.changeTimeserverOption(TimeserverOption.customize, v);
                }
                break;
        }
    }


    @Input('disabled') _disabled: boolean;
    @Input('unsupportReason') _unsupportReason: string;
    @Input('lock') _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('on')
    set on(v: boolean) {
        this._timeserverInfo.turnOnOff(v);
    }

    private _urlRef: ElementRef;
    @ViewChild('customUrl', { static: true })
    set search(v: ElementRef) {
        if (v) {
            this._urlRef = v;

            fromEvent(this._urlRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._timeserverInfo.url = e.target.value;
                this.changed.next(this._timeserverInfo);
            });
        }
    }

    @Output() changed = new EventEmitter<TimeserverInfo>();

    turnOnOff(checked: boolean): void {
        if (this._timeserverInfo.turnOnOff(checked)) {
            this.changed.next(this._timeserverInfo);
        }
    }

    changeTimeserverOption(option: TimeserverOption, url?: string): void {
        if (this._timeserverInfo.changeTimeserverOption(option, url)) {
            this.changed.next(this._timeserverInfo);
        }
    }
}