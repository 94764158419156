import { Type } from '@angular/core';
import { DeviceInfo } from '../../device/data/device-info';

export class LicenseFuncItem {
  constructor(public component: Type<any>, public name: string, public title: string, public data?: any) {}
}

export interface ILicenseDynamicComponent {
    title: string;
    _devices: DeviceInfo[];
    
    dialogCompleteHandler?: (result: any) => void;
}

export const FUNCNAME_LICENSE_EXPLANATION: string = 'license-func-explanation';
export const FUNCNAME_LICENSE_ADD: string = 'license-func-add';
export const FUNCNAME_LICENSE_IMPORT: string = 'license-func-import';
export const FUNCNAME_LICENSE_REALLOCATE: string = 'license-func-reallocate';