import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { AccessKeyInfo } from './api.accessKey.common';

export interface IUpdateAccessKeyPathParameter {
    accessKeyID: string;
}

export interface IUpdateAccessKeyTxData {
    enabled?: boolean;
    remark?: string;
}

export class APIUpdateAccessKeyManager extends APIBaseManager<IUpdateAccessKeyPathParameter, void, IUpdateAccessKeyTxData, AccessKeyInfo> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UpdateAccessKey';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: IUpdateAccessKeyPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'accounts/accessKeys/' + pathParameters.accessKeyID;
    }
}