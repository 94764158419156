<div class="d-flex">
    <button type="button" class="btn btn-sm btn-action" data-toggle="modal" data-target="#scepFuncModalHost" (click)="createScep()">
        <i class="fas fa-plus mr-1"></i>
        Add new credential setting
    </button>
</div>
<div class="list-group mt-2">
    <div class="list-group-item list-group-header">
        <div class="row align-items-center">
            <div class="col title">
                Alias
                <span class="sorter">
                    <i class="fas fa-caret-up sort-icon descend"
                        [ngClass]="_currentSortKey === scepSvc.SCEP_SERVER_COLKEY_ALIAS && _currentSortType === _enumSortType.descend ? 'active' : ''"
                        (click)="sortDescend(scepSvc.SCEP_SERVER_COLKEY_ALIAS)">
                    </i>
                    <i class="fas fa-caret-down sort-icon ascend"
                        [ngClass]="_currentSortKey === scepSvc.SCEP_SERVER_COLKEY_ALIAS && _currentSortType === _enumSortType.ascend ? 'active' : ''"
                        (click)="sortAscend(scepSvc.SCEP_SERVER_COLKEY_ALIAS)">
                    </i>
                </span>
            </div>
            <div class="col title">
                Subject
                <span class="sorter">
                    <i class="fas fa-caret-up sort-icon descend"
                        [ngClass]="_currentSortKey === scepSvc.SCEP_SERVER_COLKEY_SUBJECT && _currentSortType === _enumSortType.descend ? 'active' : ''"
                        (click)="sortDescend(scepSvc.SCEP_SERVER_COLKEY_SUBJECT)">
                    </i>
                    <i class="fas fa-caret-down sort-icon ascend"
                        [ngClass]="_currentSortKey === scepSvc.SCEP_SERVER_COLKEY_SUBJECT && _currentSortType === _enumSortType.ascend ? 'active' : ''"
                        (click)="sortAscend(scepSvc.SCEP_SERVER_COLKEY_SUBJECT)">
                    </i>
                </span>
            </div>
            <div class="col title">
                SCEP server
            </div>
            <div class="col title">
                Profile
            </div>
            <div class="col title">
                Action
            </div>
        </div>
    </div>
    <div *ngIf="!_loading; else templateLoadingScepServer">
        <div class="list-group-content list-group-hoverless">
            <div *ngFor="let scep of _scepList" class="list-group-item">
                <div class="row align-items-center">
                    <div class="col text-break">
                        <a [routerLink]="[_scepRoute, scep.id]">{{ scep.alias }}</a>
                    </div>
                    <div class="col text-break">
                        {{ scep.subject }}
                    </div>
                    <div class="col text-break">
                        {{ scep.url }}
                    </div>
                    <div class="col text-break">
                        {{ scep.profile }}
                    </div>
                    <div class="col text-break">
                        <i class="fas fa-trash-alt" data-toggle="modal" data-target="#scepFuncModalHost" (click)="removeScepServer(scep)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #templateLoadingScepServer>
        <div class="loading">
            <p>Please wait...</p>
            <div class="loading-box"></div>
        </div>
    </ng-template>
</div>
<div id="scepFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template scep-func></ng-template>
</div>