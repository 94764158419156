import { HttpClient, HttpResponse } from '@angular/common/http';
import { IAPIRx, APIBaseManager } from '../../api.base';

export interface ICreateAccountTokenTxData {
    accountName: string;
    accountPassword?: string;
    reCaptchaSiteKey?: string;
    reCaptchaToken?: string;
}

export interface ICreateAccountTokenRxData {
    accountID?: string;
    token?: string;
    redirectURL?: string; //for enterprise && sso client
}

export class APICreateAccountTokenManager extends APIBaseManager<void, void, ICreateAccountTokenTxData, ICreateAccountTokenRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_CreateAccountToken';
        this._apiMethod = 'POST';
        this._allowPayloadPrint = false;
    }

    protected getRequestURL(): string {
        return super.getRequestURL() + 'tokens/account';
    }

    protected doPostAftermathAction(res: HttpResponse<IAPIRx<ICreateAccountTokenRxData>>): boolean {
        if (res.body.error === 0 && res.body.data) {
            APIBaseManager.USER_TOKEN = res.body.data.token;
        }

        return true;
    }
}