/**
 * A: UI, B: Device, Server
 * 
 * 0. Launch this page with player virtual id
 * 1. A create a task to B. The task should include the room name 'room_{virtual id}'. A starts polling to wait until the task is finished.
 * 2. B finishs the task and send a 'create_or_join' with room name to the server. Server create a room and join the socket of B to the room.
 * 3. B recvs 'room_create' event from server.
 * 4. A detects the task is finished and also sends 'create_or_join' to the server.
 * 5. Server detects the existence of the room and join the socket of A to the room. Server then emit 'room_join' to socket A and 'room_ready' to socket B
 * 6. B recvs 'room_ready' and starts the peer connection as initiator. B create Offer and send it by 'message' event to A through server
 * 7. A recvs 'room_join' event and start the peer connection. When A recvs Offer from 'message' event, it sends back a Answer to B through server.
 * 8. Both A and B will send ICE candidate message to each other through server.
 * 9. Peer connection established. B starts send screenshot to A through peer connection.
 */

import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NAService } from '../../../../app/API/na.service';
import { AccountService } from '../../../../app/entry/account.service';
import { HelperLib } from '../../../../app/lib/common/helper.lib';
import { BehaviorSubject, defer, EMPTY, fromEvent, merge, Observable, of, Subject, timer } from 'rxjs';
import { switchMap, takeUntil, debounceTime, map, concatMap, catchError, expand, filter } from 'rxjs/operators';
import { io, Socket } from "socket.io-client";

import { CustomResponse } from '../../../lib/common/common.data';
import { DeviceInfo, OnlineStatus } from '../data/device-info';
import { DeviceService } from '../device.service';
import { IAPIRx } from 'app/API/api.base';
import { IStartRemoteCtrlRxData } from 'app/API/v1/device/remote/api.remoteCtrl.start';
import { Logger } from 'app/lib/common/logger';
import { IExtendRemoteCtrlRxData } from 'app/API/v1/device/remote/api.remoteCtrl.extend';
import { IQueryRemoteCtrlRxData } from 'app/API/v1/device/remote/api.remoteCtrl.query';
import { ConstantService } from 'app/lib/common/constant.service';
import { LicenseService } from 'app/content/license/license.service';
import { LicenseCategory, LicenseScopeType } from 'app/content/license/license.data';
import { ILicenseCategoryInfo } from 'app/API/v1/License/api.license.common';
import { LICENSE_SCOPE_FUNCTION_MAP } from '../../license/license-scope-map';
import { IRemoteFuncCtrl, REMOTE_FUNC_DOWNLOADSCREENSHOT, REMOTE_FUNC_IDLEREMIND, REMOTE_FUNC_SENDMESSAGE, REMOTE_FUNC_NOTIFICATION, RemoteFuncItem } from './dlg/remote-func.def';
import { RemoteFuncService } from './dlg/remote-func.service';
import { RemoteFuncDirective } from './dlg/remote-func.directive';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

interface ServerToClientEvents {
    message: (msg: { type: string } | any) => void;
    room_create: (room: string, socketID: string) => void;
    room_join: (room: string, socketID: string) => void;
    room_full: (room: string) => void;
    room_ready: () => void;
    log: (logs: string[]) => void;
    create_room: (room: string) => void;
    //build-in events
    connect: () => void;
    disconnect: (reason: string) => void;
    connect_error: () => void;
}

interface ClientToServerEvents {
    message: (msg: { type: string }) => void;
    create_or_join: (room: string) => void;
}

enum ClickMode {
    check = 'check',
    click = 'click',
    press = 'press',
    scroll = 'scroll'
}

@Component({
    templateUrl: './dev-remote-ctrl.component.html',
    styleUrls: ['./dev-remote-ctrl.component.css']
})
export class DeviceRemoteControlComponent extends AutoUnsubscribeComponent implements OnInit {
    private readonly TAG: string = 'RemoteCtrl';
    private readonly MAX_ZOOM_RATIO: number = 180;
    private readonly MIN_ZOOM_RATIO: number = 50;
    private readonly ZOOM_SMALL_THRESHOLD: number = 90;
    private readonly ZOOM_LARGE_THREASHOLD: number = 130;

    _loading: boolean = false;
    _device: DeviceInfo;

    _signalServerUrl: string = '';
    _signalServerTransports: string[] = ['websocket', 'polling'];
    _keepAliveDuration: number = 300;
    _keepAliveToken: string;
    _iceServerList: { urls: string, username?: string, credential?: string }[];
    _peerConn: RTCPeerConnection;
    _socket: Socket<ServerToClientEvents, ClientToServerEvents>;
    _isInitiator: boolean = false;
    _isConnect: boolean = false;
    _dataChannel: RTCDataChannel;

    _room: string = '';
    _imgW: number = 1;
    _imgH: number = 1;
    _imgRatio: number = 1.0;
    _zoomRatio: number = 80;
    _zoomInSupport: boolean = true;
    _zoomOutSupport: boolean = true;
    _isPortrait: boolean = false;
    _currentBase64Image: string = '';
    readonly ROTATE_DEGREE_OPTIONS: number[] = [0, 90, 180, 270];
    _rotateDegree: number = 0;
    _keyEventSupport: boolean = true;
    _isPanMode: boolean = false;
    _panModeOffset: { x: number, y: number } = { x: 0, y: 0 };
    _panMode$: Subject<boolean> = new Subject<boolean>();

    _initMsg: string | undefined;
    _errorMessage: string | undefined;

    private readonly TIMEOUT_IDLE: number = 600
    _idleTimeout: Date;
    _idleDlgRecord$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    _connectStatus$: BehaviorSubject<number> = new BehaviorSubject(0);
    _disconnect$: Observable<number> = this._connectStatus$.pipe(filter(val => val === 0), takeUntil(this._unsubscribe$));

    private _imgRef: ElementRef;
    @ViewChild('img')
    set img(holder: ElementRef) {
        const lastImgRef: ElementRef = this._imgRef;
        this._imgRef = holder;
        if (!lastImgRef && holder) {
            this.handleCanvasOperation(this._imgRef);
        }
    }

    private _funcHost: RemoteFuncDirective;
    @ViewChild(RemoteFuncDirective, { static: true })
    set funcHost(host: any) {
        this._funcHost = host;
    }

    @ViewChild('errorDlgPopBtn') _errorDlgPopBtnRef: ElementRef;
    @ViewChild('idleDlgPopBtn') _idleDlgPopBtnRef: ElementRef;

    constructor(
        private route: ActivatedRoute,
        private devSvc: DeviceService,
        private naSvc: NAService,
        private accountSvc: AccountService,
        private constantSvc: ConstantService,
        private licenseSvc: LicenseService,
        private remoteFuncSvc: RemoteFuncService,
        private ngzone: NgZone
    ) {
        super();
    }

    ngOnInit(): void {
        this._loading = true;
        this._initMsg = 'Connecting device. Please wait ...';

        this.route.paramMap.pipe(
            switchMap((params: ParamMap) => this.devSvc.getDeviceByID(params.get('id')).pipe(
                concatMap((res: CustomResponse<DeviceInfo>) => {
                    if (res.isFault()) {
                        throw 'Could not get device id';
                    }

                    if (!res.data || !res.data.virtualId || !res.data.virtualPairId) {
                        throw 'Device data is wrong';
                    }

                    this._device = res.data;
                    this._keyEventSupport = this._device.features.remoteControl.keyEventSupport;

                    return this.connect();
                })
            )),
            takeUntil(this._unsubscribe$),
            catchError((error) => {
                Logger.logError(this.TAG, 'init', 'Init failed. Error = ' + error);
                return of({ isFault: true, errorMessage: error });
            })
        ).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            if (res.isFault) {
                this._initMsg = res.errorMessage;
                this._loading = false;
            }
        });

        const connectionKeepAlive$ = defer(() => timer(this._keepAliveDuration * 1000 * 0.5).pipe(
            concatMap(() => this.naSvc.extendRemoteControl({ virtualDeviceID: this._device.virtualId, virtualDevicePairedID: this._device.virtualPairId }, { room: this._room, keepAliveToken: this._keepAliveToken }, this.accountSvc.token))
        ));

        this._connectStatus$.pipe(
            switchMap((connectStatus: number) => connectStatus === 1 ? connectionKeepAlive$.pipe(
                expand((res: IAPIRx<IExtendRemoteCtrlRxData>) => {
                    let hasError: boolean = res.error !== 0;

                    if (res.error === 0) {
                        this._keepAliveDuration = res.data.duration;
                        this._keepAliveToken = res.data.keepAliveToken;
                        if (this._room !== res.data.room) {
                            //someone connect same device ??
                            hasError = true;
                        }
                    }

                    return hasError ? EMPTY : connectionKeepAlive$;
                })
            ) : EMPTY
            ),
            takeUntil(this._unsubscribe$),
        ).subscribe();

        this.initIdleTimeoutDlg();
    }

    ngOnDestroy(): void {
        this.disconnect();
        super.ngOnDestroy();
    }

    startConnect(): void {
        this._loading = true;
        this._initMsg = 'Connecting device. Please wait ...';
        this._errorMessage = '';
        this.connect().subscribe();
    }

    stopConnect(): void {
        this.disconnect();
    }

    private connect(): Observable<{ isFault: boolean, errorMessage?: string }> {
        Logger.logInfo(this.TAG, 'connect', '');

        return of(true).pipe(
            concatMap(() => {
                //check device feature, default setting, user scope, mobile layout to see if remote control is support
                if (!this.devSvc.isDeviceSupportRemoteCtrl(this._device)) {
                    throw 'Remote control is not support';
                }
                //check if device is online
                if (this._device.onlineStatus !== OnlineStatus.Online) {
                    throw 'Device is not online';
                }
                // check device license
                return this.licenseSvc.hasLicenseScope(this._device.virtualId, LicenseScopeType.remoteAssistance).pipe(
                    map((hasLicense: boolean) => {
                        if (hasLicense) {
                            return true;
                        }

                        throw 'No valid license';
                    })
                );
            }),
            concatMap(() => this.naSvc.startRemoteControl({ virtualDeviceID: this._device.virtualId, virtualDevicePairedID: this._device.virtualPairId }, this.accountSvc.token)),
            map((res: IAPIRx<IStartRemoteCtrlRxData>) => {
                if (res.error != 0) {
                    Logger.logError(this.TAG, 'init', 'Start remote control task failed. Error = ' + res.errorMessage);
                    throw HelperLib.getErrorMessage(res);
                }

                this._room = res.data.room;
                this._signalServerUrl = 'https://' + res.data.signalingServer?.hostname;
                this._signalServerTransports = res.data.signalingServer?.priority;
                this._keepAliveDuration = res.data.duration;
                this._keepAliveToken = res.data.keepAliveToken;
                this._iceServerList = res.data.serverList.map(s => {
                    let ret = { urls: s.url };
                    if (s.sessionToken) {
                        const credential: string = atob(s.sessionToken);
                        ret = Object.assign(ret, { username: credential.split('::')[0], credential: credential.split('::')[1] });
                    }

                    return ret;
                });

                this._socket = io(this._signalServerUrl, { reconnection: true, reconnectionAttempts: 3, reconnectionDelay: 5000, transports: this._signalServerTransports });
                this.initSocketEvent();

                return { isFault: false };
            }),
            catchError((error) => {
                Logger.logError(this.TAG, 'connect', 'start remote control failed. Error = ', error);
                return of({ isFault: true, errorMessage: error });
            })
        );
    }

    private disconnect(isInitiator: boolean = true): void {
        Logger.logInfo(this.TAG, 'disconnect', '');

        if (isInitiator) {
            this.naSvc.stopRemoteControl({ virtualDeviceID: this._device.virtualId, virtualDevicePairedID: this._device.virtualPairId }, this.accountSvc.token).subscribe();
        }

        if (this._socket) {
            this._socket.disconnect();
            this._socket.close();
        }

        this._dataChannel ? this._dataChannel.close() : '';
        this._peerConn ? this._peerConn.close() : '';

        this._currentBase64Image = '';
        if (this._idleDlgRecord$.value === 1) {
            this._idleDlgRecord$.next(0);
        }

        this._connectStatus$.next(0);
        this._isConnect = false;
    }

    initSocketEvent(): void {
        this._socket.on('room_create', (room: string, socketID: string) => {
            Logger.logInfo(this.TAG, 'socket', `Create room ${room}. Socket id: ${socketID}`);
            this._isInitiator = true;
        });

        this._socket.on('room_join', (room: string, socketID: string) => {
            Logger.logInfo(this.TAG, 'socket', `Join room ${room}. Socket id: ${socketID}`);
            this._isInitiator = false;
            this.createPeerConnection(this._isInitiator);
        });

        this._socket.on('room_full', (room: string) => {
            Logger.logInfo(this.TAG, 'socket', 'room_full');
        });

        this._socket.on('room_ready', () => {
            Logger.logInfo(this.TAG, 'socket', 'room_ready');
            this.createPeerConnection(this._isInitiator);
        });

        this._socket.on('message', (msg: any) => {
            this.handleSDPMessage(msg);
        });

        //pre-defined events
        this._socket.on('connect', () => {
            Logger.logInfo(this.TAG, 'socket', 'Socket connect');
            this._socket.emit('create_or_join', this._room); //room-111);
        });

        this._socket.on('connect_error', (error) => {
            Logger.logError(this.TAG, 'socket', 'Connection error', error);
            this._loading = false;
            this._initMsg = 'Connect signal server failed. Please try again. <br /> Error = ' + error.toString();
        });

        this._socket.on('disconnect', (reason) => {
            Logger.logInfo(this.TAG, 'socket', 'Socket disconnect', reason);
        });
    }

    sendMsgToSignalServer(message: { type: string } | any): void {
        this._socket.emit('message', message);
    }

    handleSDPMessage(message: any): void {
        switch (message.type) {
            case 'offer':
                {
                    Logger.logInfo(this.TAG, 'SDP-msg', '[B] Get offer, set remote sdp');
                    this._peerConn.setRemoteDescription(new RTCSessionDescription(message)).then(() => {
                        return this._peerConn.createAnswer();
                    }).then((answer: RTCSessionDescriptionInit) => {
                        return this._peerConn.setLocalDescription(answer);
                    }).then(() => {
                        Logger.logInfo(this.TAG, 'SDP-msg', '[B] Send answer to another peer. ', this._peerConn.localDescription);
                        this.sendMsgToSignalServer(this._peerConn.localDescription);
                    }).catch(error => {
                        Logger.logError(this.TAG, 'SDP-msg', '[B] Error = ', error);
                    });
                }
                break;
            case 'answer':
                {
                    Logger.logInfo(this.TAG, 'SDP-msg', '[A] Get answer, set remote sdp');
                    this._peerConn.setRemoteDescription(new RTCSessionDescription(message))
                }
                break;
            case 'candidate':
                {
                    Logger.logInfo(this.TAG, 'SDP-msg', 'Get ICE msg:', message);
                    this._peerConn.addIceCandidate(new RTCIceCandidate({
                        candidate: message.candidate,
                        sdpMLineIndex: message.label,
                        sdpMid: message.id
                    }))
                }
                break;
        }
    }

    createPeerConnection(isInitiator) {
        const peerConnConfig: RTCConfiguration = {
            iceServers: this._iceServerList
        };
        Logger.logInfo(this.TAG, 'RTC', `Creating Peer connection as initiator? (${isInitiator}), config is `, peerConnConfig);
        this._peerConn = new RTCPeerConnection(peerConnConfig);

        // send any ice candidates to the other peer
        this._peerConn.onicecandidate = (ev: RTCPeerConnectionIceEvent) => {
            Logger.logInfo(this.TAG, 'RTC', 'Onicecandidate = ', ev);
            if (ev.candidate) {
                this.sendMsgToSignalServer({
                    type: 'candidate',
                    label: ev.candidate.sdpMLineIndex,
                    id: ev.candidate.sdpMid,
                    candidate: ev.candidate.candidate
                });
            }
        };

        this._peerConn.oniceconnectionstatechange = (ev: Event) => {
            Logger.logInfo(this.TAG, 'RTC', 'oniceconnectionstatechange = ', this._peerConn.iceConnectionState);
            if (this._peerConn.iceConnectionState === 'disconnected') {
                this.ngzone.run(() => {
                    this._initMsg = '';
                    this._errorMessage = 'Cannot find suitable connection candidates. Please wait and try again.';
                });
            }
        };

        this._peerConn.onicecandidateerror = (ev: RTCPeerConnectionIceErrorEvent) => {
            Logger.logError(this.TAG, 'RTC', 'onicecandidateerror = ', ev);
        };

        if (isInitiator) {
            this._dataChannel = this._peerConn.createDataChannel('photos');
            this.initDataChannel(this._dataChannel, 'A');

            Logger.logInfo(this.TAG, 'RTC', '[A] Create an offer');
            this._peerConn.createOffer().then((offer: RTCSessionDescriptionInit) => {
                return this._peerConn.setLocalDescription(offer);
            }).then(() => {
                Logger.logInfo(this.TAG, 'RTC', '[A] Send offer to another peer. ', this._peerConn.localDescription);
                this.sendMsgToSignalServer(this._peerConn.localDescription);
            }).catch(error => {
                Logger.logError(this.TAG, 'RTC', '[A] Error = ', error);
            });
        } else {
            Logger.logInfo(this.TAG, 'RTC', '[B] Wait for RTC connection');
            this._peerConn.ondatachannel = (ev: RTCDataChannelEvent) => {
                Logger.logInfo(this.TAG, 'RTC', '[B] ondatachannel created', ev.channel);
                this._dataChannel = ev.channel;
                this.initDataChannel(this._dataChannel, 'B');
            };
        }
    }

    initDataChannel(channel: RTCDataChannel, listener: string) {
        channel.onopen = () => {
            Logger.logInfo(this.TAG, 'RTC', listener + ' channel onopen!!');
            this._isConnect = true;
            this._connectStatus$.next(1);
            this._loading = false;
            this._initMsg = '';

            let dominatedStat = null;
            const remoteCandidateStateMap: { [id: string]: any } = {};
            const localCandidateStateMap: { [id: string]: any } = {};
            this._peerConn.getStats().then((stats: RTCStatsReport) => {
                stats.forEach((stat) => {
                    switch (stat.type) {
                        case 'candidate-pair':
                            {
                                if (stat.nominated && (stat.state === 'in-progress' || stat.state === 'succeeded')) {
                                    dominatedStat = stat;
                                }
                            }
                            break;
                        case 'remote-candidate':
                            {
                                remoteCandidateStateMap[stat.id] = stat;
                            }
                            break;
                        case 'local-candidate':
                            {
                                localCandidateStateMap[stat.id] = stat;
                            }
                            break;
                    }
                });

                Logger.logInfo(this.TAG, 'RTC', 'Dominated candidate-pair info: ', dominatedStat);
                if (dominatedStat) {
                    const loCandidate = localCandidateStateMap[dominatedStat.localCandidateId];
                    const reCandidate = remoteCandidateStateMap[dominatedStat.remoteCandidateId];

                    Logger.logInfo(this.TAG, 'RTC', `Candidate info: 
    Lo:(type:${loCandidate.candidateType}, port:${loCandidate.port}, protocol:${loCandidate.protocol}, relatedAddress:${loCandidate.relatedAddress}, relatedPort:${loCandidate.relatedPort})
    Re:(type:${reCandidate.candidateType}, port:${reCandidate.port}, protocol:${reCandidate.protocol}, relatedAddress:${reCandidate.relatedAddress}, relatedPort:${reCandidate.relatedPort})
`);
                }
            });
        };

        channel.onclose = () => {
            Logger.logInfo(this.TAG, 'RTC', listener + ' channel onclose.', this._dataChannel.readyState);
            this._imgRef.nativeElement.src = null;
            if (this._connectStatus$.value) {
                //connection is closed by device
                this._currentBase64Image = '';
                this.naSvc.getRemoteControlStatus({ virtualDeviceID: this._device.virtualId, virtualDevicePairedID: this._device.virtualPairId }, this.accountSvc.token).subscribe((res: IAPIRx<IQueryRemoteCtrlRxData>) => {
                    const strConnectLater: string = 'Please try connecting later.';
                    let content: string = 'Connection is closed by the device. ' + strConnectLater;
                    if (res.error === 0 && res.data.room) {
                        if (res.data.room !== this._room) {
                            content = 'Another user connected to this session so your connection was lost. ' + strConnectLater;
                        }
                    }
                    this.ngzone.run(() => { this.sendNotification({ title: 'Your remote control session has ended.', content: content, showMsgTimeout: true }); });

                    this.disconnect(false);
                });

            }
        }

        channel.onerror = (e) => {
            Logger.logInfo(this.TAG, 'RTC', listener + ' channel onerror. ', e);
            this.ngzone.run(() => { this.sendNotification({ title: 'Your remote control session has ended.', content: 'Connection error: ' + e.toString() }); });
        }

        channel.onbufferedamountlow = () => {
            Logger.logInfo(this.TAG, 'RTC', listener + ' onbufferedamountlow');
        }

        channel.onmessage = this.receiveDataChromeFactory(listener);
    }

    receiveDataChromeFactory(listener: string) {
        let buf: Uint8ClampedArray, count: number = 0, isDataValid: boolean = true;

        return (ev: MessageEvent<any>) => {
            if (typeof ev.data === 'string') {
                try {
                    const regResult = ev.data.match(/w=(?<width>\d+);h=(?<height>\d+);zoomRatio=(?<zoomRatio>\d+.\d+);size=(?<size>\d+)/);
                    const { width, height, zoomRatio, size } = regResult?.groups;
                    if (width && height && size) {
                        buf = new Uint8ClampedArray(parseInt(size));
                        count = 0;
                        //Logger.logInfo(this.TAG, 'RTC', `img width: ${width}, height: ${height}, ratio: ${zoomRatio}, size: ${size} bytes`);
                        isDataValid = true;
                        this._imgW = parseInt(width);
                        this._imgH = parseInt(height);
                        this._imgRatio = parseFloat(zoomRatio);
                        this._isPortrait = this._imgW < this._imgH ? true : false;
                    }
                }
                catch (ex) {
                    Logger.logError(this.TAG, 'RTC', 'Parse channel data failed. Error = ', ex);
                    isDataValid = false;
                }

                return;
            }

            if (isDataValid && buf) {
                const data = new Uint8ClampedArray(ev.data);
                buf.set(data, count);
                count += data.byteLength;

                if (count === buf.byteLength) {
                    this.renderImage(buf);
                    return;
                }
            }
        };
    }

    private initIdleTimeoutDlg(): void {
        const mouseDown$ = fromEvent<MouseEvent>(document, 'mousedown');

        //when web-rtc connect, start a stream to wait TIMEOUT_IDLE seconds to pop up an idle dialog.
        //this stream should be reset when mouse down event is detected or when keep-use button on idle dialog is clicked.
        this._connectStatus$.pipe(
            switchMap((connectionStatus: number) => {
                return connectionStatus === 1 ? merge(mouseDown$, this._idleDlgRecord$).pipe(
                    debounceTime(200),
                    switchMap(() => {
                        return this._idleDlgRecord$.value === 0 ? timer(this.TIMEOUT_IDLE * 1000).pipe(map(() => this._idleDlgRecord$.next(1))) : EMPTY;
                    })
                ) : EMPTY;
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe();

        //when idle dialog is pop up, wait for 120s and then disconnect web-rtc connection.
        this._idleDlgRecord$.pipe(
            switchMap((state: number) => {
                if (state === 1) {
                    this.ngzone.run(() => { this.sendIdleRemind(); });
                }

                return EMPTY;
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe();
    }

    handleCanvasOperation(ele: ElementRef): void {
        let isLongClick: boolean = false;
        let pressDownMouseEvent: MouseEvent;

        const eleClick$ = fromEvent<MouseEvent>(ele.nativeElement, 'click');
        eleClick$.pipe(
            debounceTime(200),
            takeUntil(this._unsubscribe$)
        ).subscribe((e: MouseEvent) => {
            let delay: number = 0;

            if (isLongClick) {
                isLongClick = false;
            }
            else {
                if (!this._isPanMode && this._dataChannel.readyState === 'open') {
                    const pos: { x: number, y: number } = this.getCanvasCursorPosition(ele.nativeElement, e);
                    const data = {
                        action: 'click',
                        delay: delay,
                        x: pos.x,
                        y: pos.y
                    };

                    this._dataChannel.send(JSON.stringify(data));
                }
            }
        });

        const eleMouseDown$ = fromEvent<MouseEvent>(ele.nativeElement, 'mousedown').pipe(map((e: MouseEvent) => ({ isDown: true, ev: e })));
        const eleMouseUp$ = fromEvent<MouseEvent>(ele.nativeElement, 'mouseup').pipe(map((e: MouseEvent) => ({ isDown: false, ev: e })));
        const mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove');
        const mouseUp$ = fromEvent<MouseEvent>(document, 'mouseup');

        this._panMode$.pipe(
            debounceTime(500),
            switchMap((isPanMode: boolean) => {
                if (isPanMode) {
                    return eleMouseDown$.pipe(
                        switchMap((md: { isDown: boolean, ev: MouseEvent }) => {
                            let lastX: number = md.ev.x;
                            let lastY: number = md.ev.y;
                            return mouseMove$.pipe(
                                map((moveEvent: MouseEvent) => {
                                    const result = { move: true, offsetX: moveEvent.x - lastX, offsetY: moveEvent.y - lastY };
                                    lastX = moveEvent.x;
                                    lastY = moveEvent.y;

                                    return result;
                                }),
                                takeUntil(mouseUp$)
                            );
                        })
                    )
                }

                return of({ move: false });
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe((ret: { move: boolean, offsetX?: number, offsetY?: number }) => {
            this._panModeOffset.x = ret.offsetX ? this._panModeOffset.x + ret.offsetX : this._panModeOffset.x;
            this._panModeOffset.y = ret.offsetY ? this._panModeOffset.y + ret.offsetY : this._panModeOffset.y;
        });

        merge(eleMouseDown$, eleMouseUp$).pipe(
            debounceTime(200),
            switchMap((e: { isDown: boolean, ev: MouseEvent }) => {
                return e.isDown ? timer(500, 3000).pipe(map(timerIndex => Object.assign({ isPress: timerIndex > 0 }, e))) : (isLongClick ? of(e) : EMPTY)
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe((e: { isPress?: boolean, isDown: boolean, ev: MouseEvent }) => {
            let keep: boolean = false;
            let mode: ClickMode = ClickMode.check;

            if (e.isPress) {
                mode = ClickMode.press;
                keep = true;
            }

            if (e.isDown) {
                isLongClick = true;
                pressDownMouseEvent = e.ev;
            }
            else {
                //up
                if (isLongClick) {
                    keep = false;
                    const diff: number = Math.sqrt(Math.pow(e.ev.x - pressDownMouseEvent.x, 2) + Math.pow(e.ev.y - pressDownMouseEvent.y, 2));
                    if (diff > 50) {
                        mode = ClickMode.scroll;
                    }
                    else if (mode === ClickMode.check) {
                        mode = ClickMode.click;
                    }
                }
            }

            if (!this._isPanMode && this._dataChannel.readyState === 'open') {
                let data: any = null;
                switch (mode) {
                    case ClickMode.click:
                        {
                            const pos: { x: number, y: number } = this.getCanvasCursorPosition(ele.nativeElement, pressDownMouseEvent);
                            data = {
                                action: mode,
                                delay: 0,
                                x: pos.x,
                                y: pos.y
                            };
                        }
                        break;
                    case ClickMode.press:
                        {
                            const pos = this.getCanvasCursorPosition(ele.nativeElement, e.ev);
                            data = {
                                action: mode,
                                x: pos.x,
                                y: pos.y,
                                duration: 2,
                                keep: keep
                            };
                        }
                        break;
                    case ClickMode.scroll:
                        {
                            const pos1 = this.getCanvasCursorPosition(ele.nativeElement, pressDownMouseEvent);
                            const pos2 = this.getCanvasCursorPosition(ele.nativeElement, e.ev);
                            data = {
                                action: mode,
                                x1: pos1.x,
                                y1: pos1.y,
                                x2: pos2.x,
                                y2: pos2.y,
                                keep: false
                            }
                        }
                        break;
                    default:
                        return;
                }

                this._dataChannel.send(JSON.stringify(data));
            }
        })
    }

    sendText(txtEle: HTMLInputElement): void {
        this._dataChannel.send(JSON.stringify({
            action: 'keystring',
            str: txtEle.value
        }));

        setTimeout(() => {
            txtEle.value = '';
        });
    }

    private renderImage(data: Uint8ClampedArray): void {
        var str = String.fromCharCode.apply(null, data);
        this._currentBase64Image = 'data:image/jpeg;base64,' + btoa(str);
        if (this._imgRef) {
            this.ngzone.run(() => {
                this._imgRef.nativeElement.src = this._currentBase64Image;
            });
        }
    }

    private getCanvasCursorPosition(canvas: any, event: MouseEvent) {
        const rect = canvas.getBoundingClientRect();

        let x: number = 0, y: number = 0, x_ratio: number = 1, y_ratio: number = 1;
        switch (this._rotateDegree) {
            case 0: {
                x = event.clientX - rect.left;
                y = event.clientY - rect.top;
                x_ratio = x * (this._imgW / rect.width);
                y_ratio = y * (this._imgH / rect.height);
            }
                break;
            case 90: {
                x = event.clientY - rect.top;
                y = rect.width - (event.clientX - rect.left);
                x_ratio = x * (this._imgW / rect.height);
                y_ratio = y * (this._imgH / rect.width);
            }
                break;
            case 180: {
                x = rect.width - (event.clientX - rect.left);
                y = rect.height - (event.clientY - rect.top);
                x_ratio = x * (this._imgW / rect.width);
                y_ratio = y * (this._imgH / rect.height);
            }
                break;
            case 270: {
                x = rect.height - (event.clientY - rect.top);
                y = event.clientX - rect.left;
                x_ratio = x * (this._imgW / rect.height);
                y_ratio = y * (this._imgH / rect.width);
            }
                break;
        }

        x_ratio /= this._imgRatio;
        y_ratio /= this._imgRatio;

        //Logger.logInfo(this.TAG, 'RTC', `Mouse down on (x, y)=(${x}, ${y}), ratio=(${x_ratio}, ${y_ratio})`);

        return {
            x: x_ratio,
            y: y_ratio
        };
    }

    zoomIn(): void {
        this.updateZoomStatus(true);
    }

    zoomOut(): void {
        this.updateZoomStatus(false);
    }

    private updateZoomStatus(zoomIn: boolean): void {
        const lastZoomRatio: number = this._zoomRatio;
        const zoomIndex: number = zoomIn ? 10 : -10;

        this._zoomRatio += zoomIndex;
        if (this._zoomRatio >= this.MAX_ZOOM_RATIO) {
            this._zoomInSupport = false;
            this._zoomRatio = this.MAX_ZOOM_RATIO;
        }
        else if (this._zoomRatio <= this.MIN_ZOOM_RATIO) {
            this._zoomOutSupport = false;
            this._zoomRatio = this.MIN_ZOOM_RATIO;
        }
        else {
            this._zoomInSupport = this._zoomOutSupport = true;
        }

        if (lastZoomRatio === this.ZOOM_SMALL_THRESHOLD || lastZoomRatio === this.ZOOM_LARGE_THREASHOLD) {
            this.sendCmd("zoom", this._zoomRatio);
        }
    }

    rotate(degree: number): void {
        this._rotateDegree = degree;
    }

    sendHomeCmd(): void {
        this.sendCmd('HOME');
    }

    private sendCmd(cmd: string, value?: any): void {
        if (!this._isPanMode && this._dataChannel.readyState === 'open') {
            Logger.logInfo(this.TAG, 'RTC', `Send cmd: ${cmd}, value: ${value}`);
            this._dataChannel.send(JSON.stringify({
                action: 'cmd',
                cmd: cmd,
                value: value
            }));
        }
    }

    panMode(): void {
        this._isPanMode = !this._isPanMode;
        this._panMode$.next(this._isPanMode);
    }

    sendNotification(data: { title: string, content?: string, showMsgTimeout?: boolean, timeout?: Date }): void {
        this._errorDlgPopBtnRef.nativeElement.click();
        this.createRemoteFuncDlg(REMOTE_FUNC_NOTIFICATION, data);
    }

    sendIdleRemind(): void {
        this._idleDlgPopBtnRef.nativeElement.click();
        this.createRemoteFuncDlg(REMOTE_FUNC_IDLEREMIND);
    }

    sendMessage(): void {
        this.createRemoteFuncDlg(REMOTE_FUNC_SENDMESSAGE);
    }

    downloadScreenshot(): void {
        this.createRemoteFuncDlg(REMOTE_FUNC_DOWNLOADSCREENSHOT);
    }

    private onActionComplete(res: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }): void {
        if (res.isFault) {
            return;
        }

        switch (res.funcName) {
            case REMOTE_FUNC_DOWNLOADSCREENSHOT:
                {
                    if (this._currentBase64Image) {
                        const downloadLink = document.createElement("a");
                        downloadLink.href = this._currentBase64Image;
                        downloadLink.download = res.data;
                        downloadLink.click();
                    }
                }
                break;
            case REMOTE_FUNC_SENDMESSAGE:
                {
                    this._dataChannel.send(res.data);
                }
                break;
            case REMOTE_FUNC_IDLEREMIND:
                {
                    this._idleDlgRecord$.next(0);
                    if (res.data.toDisconnect) {
                        this.disconnect();
                    }
                }
                break;
        }
    }

    private createRemoteFuncDlg(funcName: string, data?: any, other?: any): void {
        const item: RemoteFuncItem = this.remoteFuncSvc.getItemByName(funcName);
        if (item) {
            const viewContainerRef = this._funcHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IRemoteFuncCtrl>componentRef.instance).title = item.title;
            (<IRemoteFuncCtrl>componentRef.instance).funcName = funcName;
            (<IRemoteFuncCtrl>componentRef.instance).data = data;
            (<IRemoteFuncCtrl>componentRef.instance).other = other;
            (<IRemoteFuncCtrl>componentRef.instance).onActionCompleted = this.onActionComplete.bind(this);
        }
    }
}