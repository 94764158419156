import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../api.wrapper.base';
import { APIListTaskManager } from './api.task.list';

export class APITaskWrapper extends APIWrapperBase {
    ListTask: APIListTaskManager;

    constructor(private http: HttpClient) {
        super();

        this.ListTask = new APIListTaskManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        const targets = [
            this.ListTask
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}