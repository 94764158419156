import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IAccountSignupTxData {
    accountEmail: string;
    accountPassword: string;
    firstName: string;
    lastName: string;
    accountCompanyName: string;
}

export class APIAccountSignupManager extends APIBaseManager<void, void, IAccountSignupTxData, void> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_AccountSignup';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(): string {
        return super.getRequestURL() + 'accounts/signup';
    }
}