<div [ngClass]="_showBorder ? 'showBorder' : ''">
  <div class="row">
    <span class="col-4">{{ 'key-dev-usbDebugEnable' | translate | capitalFirstWord }}</span>
    <div class='col-8 switch-container'>
      <input id="cmn-toggle-usb-debug" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
        [checked]="_debug_usb_enabled ? 'checked': ''" (change)="switchUSBDebug($event.target.checked)">
      <label for="cmn-toggle-usb-debug">
        <span class="sr-only">Enable USB debug</span>
      </label>
    </div>
  </div>
  <div class="row mt-2">
    <span class="col-4">{{ 'key-dev-tcpDebugEnable' | translate | capitalFirstWord }}</span>
    <div class='col-8 switch-container'>
      <input id="cmn-toggle-tcp-debug" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
        [checked]="_debug_tcp_enabled ? 'checked': ''" [disabled]="!_debug_usb_enabled"
        (change)="switchTCPDebug($event.target.checked)">
      <label for="cmn-toggle-tcp-debug">
        <span class="sr-only">Enable TCP debug</span>
      </label>
    </div>
  </div>
</div>
