import { Component } from '@angular/core';

import { DevFuncConfirmInterface } from '../../base/confirm/dev-func-confirm.interface';
import { DeviceNetConfigControl } from '../data/device-net-config.control';

@Component({
    templateUrl: './net-config-confirm-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './net-config-confirm-subform.component.css']
})
export class NetConfigConfirmSubFormComponent implements DevFuncConfirmInterface {
    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._monitorDeviceNames = this._actionData.deviceNames;
            this._bSpecific = this._actionData.bSpecific;
            this._config = this._actionData.configData;
            this._config.getChangeItems();
        }
    }

    set allowSubmitCallback(fn: (isAllow: boolean) => void) {
        fn(this._config.hasChangeItems());
    }

    private _bSpecific: boolean;
    private _monitorDeviceNames: string;
    _config: DeviceNetConfigControl;

    constructor() {}
}