import { Component } from '@angular/core';
import { IGetVirtualDeviceOTPQueryParameter } from '../../../API/v1/VirtualDevice/api.virtualDevice.otp.get';
import { LicenseScopeType } from '../../license/license.data';
import { ConfigDialogPage, DLG_NAME_OTP } from '../base/base-config-data';

import { BaseConfigFormComponent } from '../base/base-config-form.component';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css']
})
export class OTPFormComponent extends BaseConfigFormComponent {
    ngOnInit(): void {
        this._dialogName = DLG_NAME_OTP;
        this._licenseScopeType = LicenseScopeType.taskConfig;
        this._customActionName = 'Get password';

        super.ngOnInit();
    }

    allowGoNext(): boolean {
        return false;
    }

    allowClose(): boolean {
        return true;
    }

    allowCancel(): boolean {
        return false;
    }

    allowCustomAction(): boolean {
        return this._devices[0] && this._devices[0].features.otp.isSupport && this._page === ConfigDialogPage.action ? true : false;
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        switch (this._page) {
            case ConfigDialogPage.checking:
                {
                    this._actionData.config = this._actionData.config || {
                        current: {
                            enabled: true,
                            date: ''
                        },
                        past: {
                            enabled: false,
                            date: ''
                        },
                        loading: false,
                        errorMessage: '',
                        otp: ''
                    };
                    
                    //offline device could get OTP password.
                    this._legalDevices = this._legalDevices.concat(this._offlineForbiddenDevices);
                }
                break;
        }

        super.goNext();
    }

    protected isCustomActionValid(): boolean {
        const config: {
            current: { enabled: boolean, date: string },
            past: { enabled: boolean, date: string },
            loading: boolean,
            errorMessage: string,
            otp: string;
        } = this._actionData.config;

        if (!config || !config.past || config.loading || (config.past.enabled && !config.past.date)) {
            return false;
        }

        return true;
    }

    protected doCustomAction(): void {
        this._actionData.config.loading = true;
        this._actionData.config.errorMessage = null;
        this._actionData.config.otp = null;

        const queryTimestamp: IGetVirtualDeviceOTPQueryParameter = {
            timestamp: Math.floor(new Date(this._actionData.config.current.date).getTime() / 1000)
        };

        if (this._actionData.config.past && this._actionData.config.past.enabled && this._actionData.config.past.date) {
            queryTimestamp.timestamp = Math.floor(new Date(this._actionData.config.past.date).getTime() / 1000);
        }

        this.naSvc.getVirtualDeviceOTP({ virtualDeviceID: this._devices[0].virtualId, virtualDevicePairedID: this._devices[0].virtualPairId }, queryTimestamp, this.accountSvc.token).subscribe(res => {
            this._actionData.config.loading = false;

            if (res.error !== 0) {
                this._actionData.config.errorMessage = res.errorMessage;
            }
            else {
                this._actionData.config.otp = res.data;
            }
        });
    }
}