<div id="netConfigBody">
  <ul class="nav nav-tabs" role="tablist">
    <li *ngIf="Enable_ethernet" class="nav-item">
      <a class="nav-link active" id="config-ethernet-tab" data-toggle="tab" href="#config-ethernet-content" role="tab"
        aria-controls="config-ethernet-content" aria-selected="true">
        Ethernet
        <i class="fas fa-network-wired ml-2"></i>
      </a>
    </li>
    <li *ngIf="Enable_wifi" class="nav-item">
      <a class="nav-link" id="config-wifi-tab" data-toggle="tab" href="#config-wifi-content" role="tab"
        aria-controls="config-wifi-content" aria-selected="false">
        Wi-Fi
        <i class="fas fa-wifi ml-2"></i>
      </a>
    </li>
  </ul>
  <div class="tab-content scroll scroll-lg">
    <div *ngIf="Enable_ethernet" class="tab-pane fade show active" id="config-ethernet-content" role="tabpanel"
      aria-labelledby="config-ethernet-tab">
      <div class="form-group">
        <strong>Enable Ethernet</strong>
        <div class='switch-container'>
          <input id="cmn-toggle-eth" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
            [checked]="_config.ethernetEnableItem.value ? 'checked': null"
            (change)="_config.changeNetType(_enumNetType.Ethernet, $event.target.checked)">
          <label for="cmn-toggle-eth">
            <span class="sr-only">Enable Ethernet</span>
          </label>
        </div>
      </div>
      <div class="form-group">
        <strong>Use static IP</strong>
        <div class='switch-container'>
          <input id="cmn-toggle-eth-dhcp" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
            [disabled]="!_config.ethernetEnableItem.value"
            [checked]="_config.ethernetEnableItem.value && !_config.ethernetEnableDHCPItem.value ? 'checked' : null"
            (change)="_config.changeEthernetConnectType($event.target.checked)">
          <label for="cmn-toggle-eth-dhcp">
            <span class="sr-only">Enable static IP</span>
          </label>
        </div>
      </div>
      <ng-template [ngIf]="_config.ethernetEnableItem.value && !_config.ethernetEnableDHCPItem.value">
        <div class="form-group">
          <label for="config-eth-ip">IP addres</label>
          <span *ngIf="!_config.ethernetEnableDHCPItem.value" class="text-danger ml-2">*</span>
          <input id="config-eth-ip" type="text" class="form-control" [class.warning]="_config.ethernetIPItem.warning"
            [disabled]="!_config.ethernetEnableItem.value" [(ngModel)]="_config.ethernetIPItem.value">
        </div>
        <div class="form-group">
          <label for="config-eth-gateway">Gateway</label>
          <span *ngIf="!_config.ethernetEnableDHCPItem.value" class="text-danger ml-2">*</span>
          <input id="config-eth-gateway" type="text" class="form-control"
            [class.warning]="_config.ethernetGatewayItem.warning" [disabled]="!_config.ethernetEnableItem.value"
            [(ngModel)]="_config.ethernetGatewayItem.value">
        </div>
        <div class="form-group">
          <label for="config-eth-netmask">Netmask</label>
          <span *ngIf="!_config.ethernetEnableDHCPItem.value" class="text-danger ml-2">*</span>
          <input id="config-eth-netmask" type="text" class="form-control"
            [class.warning]="_config.ethernetMaskItem.warning" [disabled]="!_config.ethernetEnableItem.value"
            [(ngModel)]="_config.ethernetMaskItem.value">
        </div>
        <div class="form-group">
          <label for="config-eth-dns1">DNS 1</label>
          <span *ngIf="!_config.ethernetEnableDHCPItem.value" class="text-danger ml-2">*</span>
          <input id="config-eth-dns1" type="text" class="form-control"
            [class.warning]="_config.ethernetDns1Item.warning" [disabled]="!_config.ethernetEnableItem.value"
            [(ngModel)]="_config.ethernetDns1Item.value">
        </div>
        <div class="form-group">
          <label for="config-eth-dns2">DNS 2</label>
          <input id="config-eth-dns2" type="text" class="form-control" [disabled]="!_config.ethernetEnableItem.value"
            [(ngModel)]="_config.ethernetDns2Item.value">
        </div>
      </ng-template>
    </div>
    <div *ngIf="Enable_wifi" class="tab-pane fade" id="config-wifi-content" role="tabpanel"
      aria-labelledby="config-wifi-tab">
      <div class="form-group">
        <strong>Enable</strong>
        <div class='switch-container'>
          <input id="cmn-toggle-wifi" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
            [checked]="_config.wifiEnableItem.value ? 'checked': null"
            (change)="_config.changeNetType(_enumNetType.Wifi, $event.target.checked)">
          <label for="cmn-toggle-wifi">
            <span class="sr-only">Enable Wi-Fi</span>
          </label>
        </div>
      </div>
      <div class="form-group">
        <div class="d-flex align-items-center mb-1">
          <label for="config-wifi-ssid">SSID</label>
          <span *ngIf="_config.wifiEnableItem.value && !_config.ethernetEnableItem.value"
            class="text-danger ml-2">*</span>
          <div class="btn-group ml-auto">
            <button id="dropdownSSIDLink" type="button"
              class="btn btn-sm btn-action btn-light dropdown-toggle"
              [disabled]="!_config.wifiEnableItem.value" data-toggle="dropdown" (click)="slideNetConfigBody()">Select
              SSID<span class="caret" style="margin-left:5px"></span></button>
            <ul class="dropdown-menu dropdown-menu-left option-list" aria-labelledby="dropdownSSIDLink" style="max-height:200px">
              <li *ngFor="let ssidInfo of _device?.availableOptions?.ssids" (click)="_config.changeSSID(ssidInfo)">
                <div>
                  {{ ssidInfo.name }}
                </div>
              </li>
            </ul>
          </div>
          <button type="button" class="btn btn-sm btn-action btn-light ml-2"
            [disabled]="_refreshAvailableOptionCounter > 0 || !_device || !_config.wifiEnableItem.value"
            (click)="refreshSSIDs()">
            <span [class.rotate]="_refreshAvailableOptionCounter > 0" class="icon fas fa-sync" data-toggle="tooltip"
              title="refresh ssid"></span>
            <span class="sr-only">Refresh SSID</span>
            <span *ngIf="_refreshAvailableOptionCounter > 0" style="display:inline-block"> -
              {{ _refreshAvailableOptionCounter }} s</span>
          </button>
        </div>
        <input id="config-wifi-ssid" type="text" class="form-control" [class.warning]="_config.wifiSSIDItem.warning"
          [disabled]="!_config.wifiEnableItem.value" [(ngModel)]="_config.wifiSSIDItem.value">
      </div>
      <div class="form-group">
        <label for="config-wifi-pwd">Password</label>
        <input id="config-wifi-pwd" type="password" class="form-control" [disabled]="!_config.wifiEnableItem.value"
          [(ngModel)]="_config.wifiPasswordItem.value">
      </div>
      <div class="form-group">
        <label for="config-wifi-alg">Security</label>
        <input id="config-wifi-alg" type="text" class="form-control" [disabled]="!_config.wifiEnableItem.value"
          [(ngModel)]="_config.wifiAuthItem.value">
      </div>
      <div class="form-group">
        <strong>Use static IP</strong>
        <div class='switch-container'>
          <input id="cmn-toggle-wifi-dhcp" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
            [disabled]="!_config.wifiEnableItem.value"
            [checked]="_config.wifiEnableItem.value && !_config.wifiEnableDHCPItem.value ? 'checked' : null"
            (change)="_config.changeWifiConnectType($event.target.checked)">
          <label for="cmn-toggle-wifi-dhcp">
            <span class="sr-only">Enalbe static IP</span>
          </label>
        </div>
      </div>
      <ng-template [ngIf]="_config.wifiEnableItem.value && !_config.wifiEnableDHCPItem.value">
        <div class="form-group">
          <label for="config-wifi-ip">IP address</label>
          <span *ngIf="!_config.wifiEnableDHCPItem.value" class="text-danger ml-2">*</span>
          <input id="config-wifi-ip" type="text" class="form-control" [class.warning]="_config.wifiIPItem.warning"
            [disabled]="!_config.wifiEnableItem.value" [(ngModel)]="_config.wifiIPItem.value">
        </div>
        <div class="form-group">
          <label for="config-wifi-gateway">Gateway</label>
          <span *ngIf="!_config.wifiEnableDHCPItem.value" class="text-danger ml-2">*</span>
          <input id="config-wifi-gateway" type="text" class="form-control"
            [class.warning]="_config.wifiGatewayItem.warning" [disabled]="!_config.wifiEnableItem.value"
            [(ngModel)]="_config.wifiGatewayItem.value">
        </div>
        <div class="form-group">
          <label for="config-wifi-netmask">Netmask</label>
          <span *ngIf="!_config.wifiEnableDHCPItem.value" class="text-danger ml-2">*</span>
          <input id="config-wifi-netmask" type="text" class="form-control"
            [class.warning]="_config.wifiMaskItem.warning" [disabled]="!_config.wifiEnableItem.value"
            [(ngModel)]="_config.wifiMaskItem.value">
        </div>
        <div class="form-group">
          <label for="config-wifi-dns1">DNS 1</label>
          <span *ngIf="!_config.wifiEnableDHCPItem.value" class="text-danger ml-2">*</span>
          <input id="config-wifi-dns1" type="text" class="form-control" [class.warning]="_config.wifiDns1Item.warning"
            [disabled]="!_config.wifiEnableItem" [(ngModel)]="_config.wifiDns1Item.value">
        </div>
        <div class="form-group">
          <label for="config-wifi-dns2">DNS 2</label>
          <input id="config-wifi-dns2" type="text" class="form-control" [disabled]="!_config.wifiEnableItem"
            [(ngModel)]="_config.wifiDns2Item.value">
        </div>
      </ng-template>
    </div>
  </div>
</div>