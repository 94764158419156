import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IShadowDeviceRxData } from './virtualDevice.common';

export interface IGetVirtualDeviceShadowPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export interface IGetVirtualDeviceShadowQueryParameter {
    shadowTimestamp?: string;
}

export class APIGetVirtualDeviceShadowManager extends APIBaseManager<IGetVirtualDeviceShadowPathParameter, IGetVirtualDeviceShadowQueryParameter, void, IShadowDeviceRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetVirtualDeviceShadow';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IGetVirtualDeviceShadowPathParameter, queryParameters: IGetVirtualDeviceShadowQueryParameter): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/' + pathParameters.virtualDevicePairedID + '/shadow' + this.transformObjectQueryParameterToStr(queryParameters);
    }
}