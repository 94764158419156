import { Component } from '@angular/core';

import { OfflineAlertConfigData } from '../../alert.data';
import { AlertHelper } from '../../alert.helper';
import { IAlertDataCtrl } from '../alert-data-ctrl.interface';

@Component({
    templateUrl: './alert-data-account-access.component.html',
    styleUrls: ['../../alert.style.css', './alert-data-account-access.component.css']
})
export class AlertAccountAccessDataComponent implements IAlertDataCtrl {
    _receivers: string;
    
    private _data: OfflineAlertConfigData = new OfflineAlertConfigData();
    set alertData(data: OfflineAlertConfigData) {
        this._data = data;
        this._receivers = this._data && this._data.alertReceiverList ? this._data.alertReceiverList.join('\n') : '';
    }

    _isViewMode: boolean = true;
    set isViewMode(isView: boolean) {
        this._isViewMode = isView;
    }

    changeReceivers(receiver: string): void {
        this._data.changeReceiver(receiver);
    }

    transformReceiverList(receiverList: string[]): string {
        return AlertHelper.transformReceiverList(receiverList);
    }
}