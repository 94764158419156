import { Component, ElementRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subject, fromEvent, interval, of } from 'rxjs';
import { concatMap, debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';

import { DeviceService } from '../../../device/device.service';
import { DevFuncActionInterface } from '../../base/action/dev-func-action.interface';
import { FwUpdateMethod, FirmwareUpdateScheduleInfo, FwUpdateStatus } from '../firmware-data';
import { GMT_OFFSET_LIST } from '../../../../lib/common/helper.lib';
import { DeviceInfo } from '../../../../../app/content/device/data/device-info';
import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { CustomResponse } from '../../../../../app/lib/common/common.data';
import { ITicketData } from '../../../../../app/API/v1/Ticket/api.ticket.common';
import { ActivityService } from '../../../../../app/content/event/activity/activity.service';
import { ActivityInfo } from '../../../../../app/content/event/activity/activity.data';
import { ActivityStatus } from '../../../../../app/content/event/activity/activity.data';

@Component({
    templateUrl: './firmware-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './firmware-action-subform.component.css']
})
export class FirmwareActionSubFormComponent implements DevFuncActionInterface {
    private readonly GMTLIST: number[] = GMT_OFFSET_LIST;
    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._fwSchedule = this._actionData.fwUpdateSchedule;
            this._fwSchedule.date = this._fwSchedule.date || this.datePipe.transform(new Date(), 'yyyy-MM-dd');
            this._devices = this._actionData.devices;
            this._fwUpdateMethod = this._actionData.fwUpdateMethod;
            this._customDownloadLink = this._actionData.customDownloadLink;

            setTimeout(() => {
                this.updateActionStatus();
            }, 0);
        }
    }

    actionCompleteHandler: (result: any) => void;
    actionStatusUpdateHandler: (isValid: boolean) => void = () => { };

    _fwSchedule: FirmwareUpdateScheduleInfo;
    _fwUpdateMethod: FwUpdateMethod = FwUpdateMethod.Unknown;
    _enumFwUpdateMethod: typeof FwUpdateMethod = FwUpdateMethod;
    _customDownloadLink: string = '';
    _devices: DeviceInfo[] = [];
    _activeDev: DeviceInfo;
    _enumFwUpdateStatus: typeof FwUpdateStatus = FwUpdateStatus;

    private _customUrlRef: ElementRef;
    @ViewChild('downloadLink')
    set customUrl(v: ElementRef) {
        this._customUrlRef = v;
        if (this._customUrlRef) {
            fromEvent(this._customUrlRef?.nativeElement, 'input').pipe(
                debounceTime(200)
            ).subscribe((e: any) => {
                this.updateActionStatus();
                this._actionData.customDownloadLink = this._customDownloadLink;
            });
        }
    }

    constructor(private datePipe: DatePipe, private constantSvc: ConstantService, private devSvc: DeviceService, private activitySvc: ActivityService) { }

    changeUpdateMethod(method: FwUpdateMethod): void {
        if (this._fwUpdateMethod === method) { return; }

        this._fwUpdateMethod = method;
        this._actionData.fwUpdateMethod = method;
        if (this._actionData.fwUpdateMethod === FwUpdateMethod.Latest) {
            const now: number = Date.now();
            this._devices.forEach(d => {
                const trackInfo: { lastTrackTime: Date } = d.trackingMap.get(this.constantSvc.TASKTYPE_CHECK_FIRMWARE);
                if (d.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_STATUS] === FwUpdateStatus.CHECKING && (!trackInfo || !trackInfo.lastTrackTime || now - trackInfo.lastTrackTime.getTime() > 1800000)) {
                    // simulate user action to send a check-fw task
                    this.checkLatestFW(d);
                }
            })
        }
        this.updateActionStatus();
    }

    checkLatestFW(dev: DeviceInfo): void {
        dev.trackingMap.set(this.constantSvc.TASKTYPE_CHECK_FIRMWARE, { lastTrackTime: new Date() });
        this.showFwInfo(dev, false);
        this.devSvc.batchFirmwareCheck([dev]).subscribe((res: CustomResponse<ITicketData>) => {
            if (res.isFault()) {
                return;
            }

            // start task tracking...
            this.trackingTask(dev, res.data.ticketID);
        });
    }

    showFwInfo(dev: DeviceInfo, toShow: boolean): void {
        this._activeDev = toShow ? dev : null;
    }

    enableFwSchedule(enabled: boolean): void {
        this._fwSchedule.enabled = enabled;
        this.updateActionStatus();
    }

    changeFwScheduleDate(d: string): void {
        this._fwSchedule.date = d;
        this.updateActionStatus();
    }

    changeFwScheduleTime(t: string): void {
        this._fwSchedule.time = t;
    }

    changeFwScheduleTimeOffset(o: number): void {
        this._fwSchedule.offset = o;
    }

    transformFwNotice(notice: string): string {
        return notice.split('\r\n').join('<br />');
    }

    private updateActionStatus(): void {
        let isValid: boolean = this._fwUpdateMethod !== FwUpdateMethod.Unknown ? true : false;
        if (this._fwUpdateMethod === FwUpdateMethod.FromURL) {
            isValid = this._customDownloadLink ? true : false;
        }
        if (isValid && this._fwSchedule.enabled && !this._fwSchedule.date) {
            isValid = false;
        }

        this.actionStatusUpdateHandler(isValid);
    }

    private trackingTask(dev: DeviceInfo, taskID: string): void {
        const stop$: Subject<void> = new Subject();
        const source$ = interval(60000);
        source$.pipe(
            switchMap(() => this.activitySvc.getActivityByID(taskID, true)),
            concatMap((res: ActivityInfo) => {
                const trackInfo: { lastTrackTime: Date} = dev.trackingMap.get(this.constantSvc.TASKTYPE_CHECK_FIRMWARE);
                if (trackInfo) {
                    trackInfo.lastTrackTime = new Date();
                }

                if (res.statusCode === ActivityStatus.finished || res.statusCode === ActivityStatus.fail) {
                    dev.trackingMap.delete(this.constantSvc.TASKTYPE_CHECK_FIRMWARE);
                    // refresh device current settings
                    return this.devSvc.getDeviceByID(dev.virtualId, true, true, true).pipe(
                        map(() => ({ isFinished: true }))
                    );
                }

                return of({ isFinished: false });
            }),
            takeUntil(stop$)
        ).subscribe((res: { isFinished: boolean }) => {
            if (res.isFinished) {
                stop$.next();
                stop$.complete();
            }
        });
    }
}