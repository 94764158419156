import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IVirtualDeviceRxData } from './virtualDevice.common';

export interface ICreateVirtualDeviceTxData {
    virtualDeviceName: string;
}

export class APICreateVirtualDeviceManager extends APIBaseManager<void, void, ICreateVirtualDeviceTxData, IVirtualDeviceRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_CreateVirtualDevice';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(): string {
        return super.getRequestURL() + 'virtualDevices';
    }
}