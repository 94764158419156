import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { EnterpriseAccountBasicInfo } from './api.ent.data';

export interface IRemoveEnterpriseAccountPathParameter {
    accountID: string;
}

export class APIRemoveEnterpriseAccountManager extends APIBaseManager<IRemoveEnterpriseAccountPathParameter, void, void, EnterpriseAccountBasicInfo[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_RemoveEnterpriseAccount';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IRemoveEnterpriseAccountPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/accounts/' + pathParameters.accountID;
    }
}