import { Injectable } from "@angular/core";
import { Observable, of as observableOf } from "rxjs";
import { concatMap, delay, map } from 'rxjs/operators';

import { IAPIRx } from "../../../API/api.base";
import { NAService } from '../../../API/na.service';
import { IGetEventLogRxData } from "../../../API/v1/EventLog/api.eventLog.get";
import { IListEventLogRxData, IListEventLogTxData } from "../../../API/v1/EventLog/api.eventLog.list";
import { AccountService } from "../../../entry/account.service";
import { SortType } from "../../../lib/common/common.data";
import { Logger } from "../../../lib/common/logger";
import { EventLogInfo } from "./eventLog.data";

@Injectable()
export class EventLogService {
    _logStructureTable: { [category: string]: { [event: string]: string } };
    _deviceTaskEventLogMap: { [eventID: string]: string } = {}; //'Device task' type event should have a link to device activity page

    _firstPageCahceData: EventLogInfo[] = [];
    _firstPageCacheRequest: { fromDate?: string, toDate?: string, levelList?: string[], categoryList?: string[], eventList?: string[] } = {};
    _firstPageCacheTime: number;
    _cacheTotal: number;

    constructor(
        private naSvc: NAService,
        private accountSvc: AccountService) {
        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            if (!isLogin) {
            }
            else {
                this._logStructureTable = null;
                this._deviceTaskEventLogMap = {};
                this._firstPageCahceData = [];
            }
        });
    }

    getEventStructure(): Observable<{ [category: string]: { [event: string]: string } }> {
        return observableOf(this._logStructureTable).pipe(
            concatMap((table: { [category: string]: { [event: string]: string } }) => {
                return table ? observableOf(table) : this.naSvc.listEventLogCategory(this.accountSvc.token).pipe(
                    map((res: IAPIRx<{ [category: string]: { [event: string]: string } }>) => {
                        if (res.error === 0 && res.data) {
                            this._logStructureTable = res.data;
                            delete this._logStructureTable['System admin activity'];
                        }

                        return this._logStructureTable;
                    })
                )
            })
        );
    }

    getDeviceTaskEventLogLink(eventID: string): Observable<string> {
        return observableOf(this._deviceTaskEventLogMap[eventID]).pipe(
            concatMap((taskID: string) => {
                if (!taskID) {
                    return this.naSvc.getEventLog({ eventID: eventID }, this.accountSvc.token).pipe(
                        map((res: IAPIRx<IGetEventLogRxData>) => {
                            if (res.error === 0 && res.data) {
                                if (res.data.logCategory === "Device task") {
                                    if (res.data.logData && res.data.logData.data) {
                                        this._deviceTaskEventLogMap[res.data.logID] = res.data.logData.data.ticket;
                                    }
                                }
                            }

                            return this._deviceTaskEventLogMap[res.data.logID];
                        }));
                }
                else {
                    return observableOf(taskID);
                }
            }),
            map((taskID: string) => {
                return taskID ? '/app/event/activity/' + taskID : null;
            })
        );
    }

    searchEventLogs(force: boolean = false, fromDate: string, toDate: string, levelList?: string[], categoryList?: string[], eventList?: string[], subjectKeyWord?: string, skip?: number, sort?: { key: string, sortType: SortType }, limit: number = 50): Observable<{ data: EventLogInfo[], limit?: number, skip?: number, total?: number, errorMessage?: string }> {
        if (skip === 0 && !sort && !subjectKeyWord && !this.need_update_cache(force, fromDate, toDate, levelList, categoryList, eventList)) {
            Logger.logInfo('LogSvc', 'search', 'use cache');
            return observableOf({
                data: this._firstPageCahceData,
                limit: 50,
                skip: 0,
                total: this._cacheTotal
            });
        }

        const txData: IListEventLogTxData = {
            fromDate: fromDate,
            toDate: toDate,
            search: {
                levelCodeList: levelList || [],
                subjectKeyword: subjectKeyWord || null,
                categoryList: categoryList || [],
                eventList: eventList || []
            }
        };
        if (sort && sort.key && sort.sortType) {
            txData.sort = {};
            txData.sort[sort.key] = sort.sortType === SortType.ascend ? 1 : -1;
        }

        //search at most 3 pages a time? if user click the 4th page, then load 4~6 page automatically?
        return this.naSvc.listEventLog({ ownerIDName: 'accountID', ownerIDValue: this.accountSvc.accountID }, { skip: skip, limit: limit }, txData, this.accountSvc.token).pipe(
            map((res: IAPIRx<IListEventLogRxData>) => {
                if (res.error === 0 && res.data) {
                    if (skip === 0 && !sort && !subjectKeyWord) {
                        //cache the first page
                        this._firstPageCacheRequest = {
                            fromDate: fromDate,
                            toDate: toDate,
                            levelList: levelList,
                            categoryList: categoryList,
                            eventList: eventList
                        };

                        this._firstPageCahceData = res.data.itemList.map(d => {
                            return new EventLogInfo(d.logID, d.logDate, d.logCategory, d.logEvent, d.logLevel.code, d.logSubject);
                        });
                        this._cacheTotal = res.data.total;
                        this._firstPageCacheTime = new Date().getTime();
                    }

                    return {
                        data: res.data.itemList.map(d => {
                            return new EventLogInfo(d.logID, d.logDate, d.logCategory, d.logEvent, d.logLevel.code, d.logSubject);
                        }),
                        limit: res.data.limit,
                        skip: res.data.skip,
                        total: res.data.total
                    };
                }

                return {
                    data: [],
                    errorMessage: res.errorMessage + ' (' + res.error + ')'
                };
            })
        );
    }

    private need_update_cache(force: boolean, fromDate: string, toDate: string, levelList?: string[], categoryList?: string[], eventList?: string[]): boolean {
        if (!this._firstPageCacheRequest || this._firstPageCahceData.length === 0) {
            return true;
        }

        if (force) {
            return true;
        }

        if (!this._firstPageCacheTime || new Date().getTime() - this._firstPageCacheTime > 1800000) {
            return true;
        }

        if (this._firstPageCacheRequest.fromDate !== fromDate || this._firstPageCacheRequest.toDate !== toDate) {
            return true;
        }

        if (!this._firstPageCacheRequest.levelList || this._firstPageCacheRequest.levelList.length !== levelList.length) {
            return true;
        }

        if (!this._firstPageCacheRequest.categoryList || this._firstPageCacheRequest.categoryList.length !== categoryList.length) {
            return true;
        }

        if (!this._firstPageCacheRequest.eventList || this._firstPageCacheRequest.eventList.length !== eventList.length) {
            return true;
        }

        for (let level of levelList) {
            if (!this._firstPageCacheRequest.levelList.find(l => l === level)) {
                return true;
            }
        }

        for (let category of categoryList) {
            if (!this._firstPageCacheRequest.categoryList.find(c => c === category)) {
                return true;
            }
        }

        for (let event of eventList) {
            if (!this._firstPageCacheRequest.eventList.find(e => e === event)) {
                return true;
            }
        }

        return false;
    }
}