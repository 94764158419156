import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { PolicyRawInfo } from './api.policy.common';

export interface ICreatePolicyTxData {
    policyName: string;
    policyType: string; //Security | Configuration
    policyContent: any;
    source?: string;
    sourceType?: string;  // PluginInstance
}

export class APICreatePolicyManager extends APIBaseManager<void, void, ICreatePolicyTxData, PolicyRawInfo> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_CreatePolicy';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'devicePolicies';
    }
}