<div>
  <form #securityForm="ngForm">
    <div class="form-group mt-2">
      <label for="sp-new-pwd">New password</label>
      <span class="ml-2">
        <span class="fas fa-eye" [class.d-none]="_showPwd" (click)="showPwd(true)"></span>
        <span class="fas fa-eye-slash" [class.d-none]="!_showPwd" (click)="showPwd(false)"></span>
      </span>
      <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
        [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
      <input id="sp-new-pwd" #pwdNew="ngModel" name="pwd" type="password" class="form-control form-control-sm"
        [class.d-none]="_showPwd" [class.d-none]="_showPwd" [minlength]="PWD_MIN_LEN" [disabled]="_lockInfo" [(ngModel)]="_actionData.newPwd">
      <input type="text" name="pwd-plain" [minlength]="PWD_MIN_LEN" [class.d-none]="!_showPwd"
        class="form-control-plaintext" [(ngModel)]="_actionData.newPwd">
      <div *ngIf="pwdNew.invalid && (pwdNew.dirty || pwdNew.touched)" class="alert alert-danger">
        <div *ngIf="pwdNew.errors.minlength">
          New password must be empty or at least {{ PWD_MIN_LEN }} characters long.
        </div>
      </div>
    </div>
  </form>
  <div class="text-right">
    <span>Total :</span>
    <span>&nbsp;&nbsp;{{_actionData?.deviceList.length}}</span>
  </div>
  <div class="px-0">
    <div class="list-group">
      <div class="list-group-item list-group-header black">
        <div class="row">
          <div class="col-6 title text-break">
            Device name
          </div>
          <div class="col-6 title text-break">
            MAC
          </div>
        </div>
      </div>
      <div class="list-group-content list-group-content-modal-nr-4 list-group-hoverless">
        <div *ngFor="let device of _actionData?.deviceList" class="list-group-item">
          <div class="row">
            <div class="col-6 text-break">
              {{ device.virtualName }}
            </div>
            <div class="col-6 text-break">
              {{ device.currentSettings[constantSvc.DEVKEY_NET_LAN_MAC] ||
              device.currentSettings[constantSvc.DEVKEY_NET_WIFI_MAC] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>