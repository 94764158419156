import { Component } from '@angular/core';

import { DevFuncConfirmInterface } from '../../base/confirm/dev-func-confirm.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { STR_MODE_EMAIL, STR_MODE_FAE_SUPPORT, TroubleshootMode } from '../troubleshoot.data';

@Component({
    templateUrl: './issue-confirm-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './issue-confirm-subform.component.css']
})
export class IssueConfirmSubFormComponent implements DevFuncConfirmInterface {
    readonly STR_MODE_FAE_SUPPORT: string = STR_MODE_FAE_SUPPORT;
    readonly STR_MODE_EMAIL: string = STR_MODE_EMAIL;
    
    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;

        if (this._actionData) {
            this._monitorDeviceNames = this._actionData.deviceNames;
            this._issueTitle = this._actionData.issueTitle;
            this._issueContent = this.parseContent(this._actionData.issueContent);
            this._issueSymptom = this._actionData.issueSymptom;
            this._issueFrequency = this._actionData.issueFrequency;
            this._email = this._actionData.issueEmailList ? this._actionData.issueEmailList.join(', ') : '';
            this._mode = this._actionData.issueMode;
            switch (this._mode) {
                case TroubleshootMode.Email:
                    {
                        this._str_mode = this.STR_MODE_EMAIL;
                    }
                    break;
                case TroubleshootMode.FAESupport:
                    {
                        this._str_mode = this.STR_MODE_FAE_SUPPORT;
                    }
                    break;
            }
        }
    }

    allowSubmitCallback: (isAllow: boolean) => void;

    _str_mode: string;
    _mode: TroubleshootMode = TroubleshootMode.Unknown;
    _enumIssueMode: typeof TroubleshootMode = TroubleshootMode;

    _issueTitle: string;
    _issueContent: string;
    _issueSymptom: string;
    _issueFrequency: string;
    _monitorDeviceNames: string;
    _email: string;

    constructor(private domSaniter: DomSanitizer) {}

    parseContent(content: string): string {
        return content;
    }
}