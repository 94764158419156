import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { ICertData } from './api.cert.data';

export interface IAddCertTxData {
    certificateName: string;
    certificateData: string;
}

export type IAddCertRxData = Pick<ICertData, 'certificateExpiryDate' | 'certificateID' | 'certificateName' | 'certificateFingerprint'>;

export class APIAddCertManager extends APIBaseManager<void, void, IAddCertTxData, IAddCertRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_AddCert';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return `${super.getRequestURL()}accounts/certificates`;
    }
}