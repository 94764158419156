import { Type } from '@angular/core';
import { Injectable } from '@angular/core';
import { PolicyType } from '../policy.data';
import { PolicyTypeSecurityComponent } from './policy-security.component';
import { PolicyTypeGroupConfigComponent } from './policy-group-config.component';
import { PolicyTypeAppManagementComponent } from './policy-appmgr.component';
import { PolicyTypeFirmwareUpdateComponent } from './policy-firmwareUpdate.component';
import { PolicyTypeCertificateComponent } from './policy-cert.component';

export class PolicyTypeItem {
  constructor(public component: Type<any>, public type: PolicyType) {}
}

export interface IPolicyTypeFunc {
  type: PolicyType;
  data: any;
  isInEdit: boolean;
  onPolicyDataValidate?: (valid: boolean) => void;
  onPolicyFormCancelled?: () => void;
}

@Injectable()
export class PolicyTypeService {
  private _items: PolicyTypeItem[] = [];

  constructor() {
    this._items = [
      new PolicyTypeItem(PolicyTypeSecurityComponent, PolicyType.Security),
      new PolicyTypeItem(PolicyTypeGroupConfigComponent, PolicyType.Configuration),
      new PolicyTypeItem(PolicyTypeAppManagementComponent, PolicyType.Application),
      new PolicyTypeItem(PolicyTypeFirmwareUpdateComponent, PolicyType.FirmwareUpdate),
      new PolicyTypeItem(PolicyTypeCertificateComponent, PolicyType.Certificate)
    ];
  }

  getAllItems(): PolicyTypeItem[] {
    return this._items;
  }

  getItemByName(type: PolicyType): PolicyTypeItem {
    return this._items.find(i => i.type === type);
  }
}
