import { EventEmitter, Injectable, Output } from "@angular/core";
import { Observable, of as observableOf, of } from "rxjs";
import { catchError, concatAll, concatMap, map } from "rxjs/operators";

import { AccountService } from "../../../entry/account.service";
import { HelperLib, REFRESH_DURATION } from "../../../lib/common/helper.lib";
import { UserInfo, UserInviteStatus } from "./user.data";
import { UserGroupInfo } from "./group/user-group.data";
import { NAService } from "../../../../app/API/na.service";
import { IAPIRx } from "../../../../app/API/api.base";
import { EnterpriseAccountBasicInfo, EnterpriseAccountGroupBasicInfo, EnterpriseAccountPersonalInfo } from "../../../../app/API/v1/Enterprise/account/api.ent.data";
import { IGetEnterpriseDetailInfoRxData } from "../../../API/v1/Enterprise/api.enterprise.detail.get";

@Injectable()
export class UserService {
    readonly USER_ROLE_EXCEED_ALERT: string = "You have reached the maximum number of licensed users. If there is any questions, please contact support@iadea.com";
    readonly USER_REFRESH_DURATION: number = REFRESH_DURATION * 60000;

    readonly COL_KEY_NAME: string = 'name';
    readonly COL_KEY_EMAIL: string = 'email';
    readonly COL_KEY_POLICY: string = 'policy';
    readonly COL_KEY_GROUP: string = 'group';
    readonly COL_KEY_COMPANY: string = 'company';
    readonly COL_KEY_DEPART: string = 'department';
    readonly COL_KEY_STATUS: string = 'invitationStatus';
    readonly COL_KEY_LOGIN: string = 'lastLoginDateTime';
    readonly COL_KEY_CREATE: string = 'createDateTime';

    private _defaultUserGroupID: string;
    get defaultUserGroupID(): string {
        return this._defaultUserGroupID;
    }

    private _enterpriseLicense: { [roleName: string]: { limit: number, usage: number } } = {};
    private _enterpriseDomainList: string[] = [];
    private _updatingEnterpriseInfo: boolean;

    private _userMap: { [userID: string]: UserInfo } = {};
    private _updatingUser: boolean;
    private _lastUserUpdateTime: Date;

    private _userRoleMap: { [policyID: string]: { name: string, desc: string } } = {};
    private _updatingUserRole: boolean;
    private _lastUserRoleUpdateTime: Date;

    private _userGroupMap: { [groupID: string]: UserGroupInfo } = {};
    private _updatingUserGroup: boolean;
    private _lastUserGroupUpdateTime: Date;

    private _userTableColMap: { [key: string]: { name: string, sortSupport?: boolean, filterSupport?: boolean, show?: boolean } } = {};
    get userTableColumnMap(): { [key: string]: { name: string, sortSupport?: boolean, filterSupport?: boolean, show?: boolean } } {
        return this._userTableColMap;
    }

    private _userLicenseAmount: number = 10;
    get userLicenseAmount(): number {
        return this._userLicenseAmount;
    }

    @Output() onUserRemoved = new EventEmitter<{ removedUserList: UserInfo[], remainingUserList: UserInfo[] }>();
    @Output() onUserAdded = new EventEmitter<{ addedUserList: UserInfo[], remainingUserList: UserInfo[] }>();
    @Output() onUserLicenseUpdate = new EventEmitter<{
        usedAmount: number,
        totalAmount: number,
        detail: { [policyID: string]: number }
    }>();
    @Output() onUserUpdated = new EventEmitter<{ updatedUserList: UserInfo[], remainingUserList: UserInfo[] }>();
    @Output() onUserGroupRemoved = new EventEmitter<{ removedUserGroup: UserGroupInfo, remainingUserGroupList: UserGroupInfo[] }>();
    @Output() onUserGroupAdded = new EventEmitter<{ addedUserGroup: UserGroupInfo, remainingUserGroupList: UserGroupInfo[] }>();
    @Output() onTargetDeviceGroupUpdated = new EventEmitter<{ deviceGroupIDList: string[] }>();

    constructor(private accountSvc: AccountService, private naSvc: NAService) {
        this.initDefaultUserColumn();
        this.initDefaultUserRole();
    }

    private initDefaultUserColumn(): void {
        this._userTableColMap = this._userTableColMap || {};
        this._userTableColMap[this.COL_KEY_POLICY] = { name: 'User role', show: true };
        this._userTableColMap[this.COL_KEY_STATUS] = { name: 'Invitation status', filterSupport: true, show: true };
    }

    private initDefaultUserRole(): void {
        this._userRoleMap = {
            'Admin': {
                name: 'Admin',
                desc: 'All permissions are enabled'
            },
            'Editor': {
                name: 'Editor',
                desc: 'Able to access devices'
            },
            'Viewer': {
                name: 'Viewer',
                desc: 'View function only'
            }
        }
    }

    getEnterpriseInfo(): Observable<{ data: { domainList: string[], licenses: { [roleName: string]: { limit: number, usage: number } } }, isFault: boolean, errorMessage?: string }> {
        if (this._updatingEnterpriseInfo) {
            return new Observable((observer) => {
                HelperLib.checkState(1, () => { return !this._updatingEnterpriseInfo }, () => {
                    observer.next({
                        isFault: false,
                        data: {
                            domainList: this._enterpriseDomainList,
                            licenses: this._enterpriseLicense
                        }
                    });
                    observer.complete();
                });
            });
        }

        const needUpdate: boolean = (!this._lastUserUpdateTime || Date.now() - this._lastUserUpdateTime.getTime() > this.USER_REFRESH_DURATION) || HelperLib.mapToList(this._enterpriseLicense).length === 0 ? true : false;
        if (needUpdate) {
            this._updatingEnterpriseInfo = true;
            return this.naSvc.getEnterpriseInfo(this.accountSvc.token).pipe(
                map((res: IAPIRx<IGetEnterpriseDetailInfoRxData>) => {
                    if (res.error === 0) {
                        this._enterpriseDomainList = res.data.enterpriseDomainList;
                        if (res.data && res.data.terms && res.data.terms.roles && res.data.terms.roles.limitations) {
                            res.data.terms.roles.limitations.forEach((limitation: { limit: number, roleList: string[] }) => {
                                this._enterpriseLicense[limitation.roleList.join('+')] = { limit: limitation.limit, usage: 0 };
                            });
                        }
                    }

                    this._updatingEnterpriseInfo = false;

                    return {
                        isFault: false,
                        data: {
                            domainList: this._enterpriseDomainList,
                            licenses: this._enterpriseLicense
                        }
                    };
                })
            );
        }
        else {
            return observableOf({
                isFault: false,
                data: {
                    domainList: this._enterpriseDomainList,
                    licenses: this._enterpriseLicense
                }
            });
        }
    }

    isEnterpriseLicenseSufficient(targetRoleName: string, count: number = 1): Observable<boolean> {
        return this.getEnterpriseLicenseUsage().pipe(
            map((res: { license?: { [roleName: string]: { limit: number, usage: number } }, isFault: boolean, errorMessage?: string }) => {
                if (res.isFault) {
                    return false;
                }

                let role: string = targetRoleName;
                if (targetRoleName === 'Admin' || targetRoleName === 'Editor') {
                    role = 'Admin+Editor';
                }

                if (!res.license[role]) {
                    return false;
                }

                return res.license[role].usage + count <= res.license[role].limit ? true : false;
            })
        );
    }

    getEnterpriseLicenseUsage(): Observable<{ license?: { [roleName: string]: { limit: number, usage: number } }, isFault: boolean, errorMessage?: string }> {
        return this.getEnterpriseInfo().pipe(
            map((res: { data: { domainList: string[], licenses: { [roleName: string]: { limit: number, usage: number } } }, isFault: boolean, errorMessage?: string }) => {
                if (res.isFault) {
                    throw res.errorMessage;
                }

                return true;
            }),
            concatMap(() => {
                return this.getUserList();
            }),
            map((res: { userList: UserInfo[], isFault: boolean, errorMessage?: string }) => {
                if (res.isFault) {
                    throw res.errorMessage;
                }

                if (res.userList) {
                    //reset usage first
                    HelperLib.mapToList(this._enterpriseLicense).forEach(e => e.usage = 0);
                    res.userList.forEach(u => {
                        let key: string = u.userRole;
                        switch (key) {
                            case 'Admin':
                            case 'Editor':
                                {
                                    key = 'Admin+Editor';
                                }
                                break;
                        }

                        if (this._enterpriseLicense[key]) {
                            this._enterpriseLicense[key].usage++;
                        }
                    });
                }

                return {
                    isFault: false,
                    license: this._enterpriseLicense
                }
            }),
            catchError(err => {
                return observableOf({
                    isFault: true,
                    errorMessage: err.toString()
                });
            })
        );
    }

    getUserList(forceRefresh: boolean = false): Observable<{ userList: UserInfo[], isFault: boolean, errorMessage?: string }> {
        if (this._updatingUser) {
            return new Observable((observer) => {
                HelperLib.checkState(1, () => { return !this._updatingUser }, () => {
                    observer.next({ isFault: false, userList: HelperLib.mapToList(this._userMap) });
                    observer.complete();
                });
            });
        }

        //1. no last update
        //2. last update time > 30 min
        //3. no user
        const needUpdate: boolean = (forceRefresh || !this._lastUserUpdateTime || Date.now() - this._lastUserUpdateTime.getTime() > this.USER_REFRESH_DURATION) || HelperLib.mapToList(this._userMap).length === 0 ? true : false;
        if (needUpdate) {
            this._updatingUser = true;
            return this.naSvc.listEnterpriseAccount(this.accountSvc.token).pipe(
                map((res: IAPIRx<EnterpriseAccountBasicInfo[]>) => {
                    this._updatingUser = false;

                    if (res.error === 0 && res.data) {
                        this._lastUserUpdateTime = new Date();
                        res.data.forEach(r => {
                            const user: UserInfo = new UserInfo();
                            user.id = r.accountID;
                            user.email = r.accountEmail;
                            user.invitationStatus = UserInviteStatus[r.status];
                            user.userGroupID = r.accountGroupID;
                            user.userRole = r.accountRole;
                            
                            this._userMap[user.id] = user;
                        });
                    }

                    return { isFault: false, userList: HelperLib.mapToList(this._userMap) };
                })
            )
        }
        else {
            return observableOf({ isFault: false, userList: HelperLib.mapToList(this._userMap) });
        }
    }

    getUserByID(userID: string): Observable<{ user: UserInfo, isFault: boolean, errorMessage?: string }> {
        //if user did not have createdDate info -> get user detail from server
        if (this._userMap[userID] && this._userMap[userID].createdDate) {
            return observableOf({ user: this._userMap[userID], isFault: false });
        }

        return observableOf(this._userMap[userID]).pipe(
            concatMap((user: UserInfo) => {
                if (!user) {
                    return this.getUserList().pipe(
                        map((res: { userList: UserInfo[], isFault: boolean, errorMessage?: string }) => {
                            if (res.isFault) {
                                throw res.errorMessage;
                            }
                        })
                    );
                }

                return observableOf(true);
            }),
            concatMap(() => {
                return this.naSvc.getEnterpriseAccountDetail(userID, this.accountSvc.token);
            }),
            map((res: IAPIRx<EnterpriseAccountPersonalInfo>) => {
                if (res.error !== 0) {
                    throw HelperLib.getErrorMessage(res);
                }

                if (this._userMap[res.data.accountID]) {
                    if (res.data.personalData) {
                        this._userMap[res.data.accountID].firstName = res.data.personalData.firstName;
                        this._userMap[res.data.accountID].lastName = res.data.personalData.lastName;
                        this._userMap[res.data.accountID].company = res.data.personalData.companyName;
                        this._userMap[res.data.accountID].department = res.data.personalData.departmentName;
                        this._userMap[res.data.accountID].lastLoginDate = res.data.lastLoginDate;
                        this._userMap[res.data.accountID].createdDate = res.data.createdDate;
                    }
                }

                return {
                    user: this._userMap[userID],
                    isFault: this._userMap[userID] ? false : true,
                    errorMessage: !this._userMap[userID] ? 'User is not exist' : null
                }
            }),
            catchError((err: any) => {
                return observableOf({
                    user: null,
                    isFault: true,
                    errorMessage: err.toString()
                });
            })
        );
    }

    getUserByIDList(userIDList: string[]): Observable<{ userList: UserInfo[], isFault: boolean, errorMessage?: string }> {
        return new Observable((observer) => {
            const errorList: string[] = [];
            const userList: UserInfo[] = [];

            observableOf(true).pipe(
                concatMap(() => {
                    const obs: Observable<{ hasNext: boolean, user: UserInfo, isFault: boolean, errorMessage?: string }>[] = [];

                    userIDList.forEach(uID => {
                        obs.push(this.getUserByID(uID).pipe(
                            map((res: { user: UserInfo, isFault: boolean, errorMessage?: string }) => Object.assign({ hasNext: true }, res))
                        ))
                    });

                    obs.push(observableOf({ hasNext: false, user: null, isFault: false }));

                    return obs;
                }),
                concatAll()
            ).subscribe((res: { hasNext: boolean, user: UserInfo, isFault: boolean, errorMessage?: string }) => {
                if (res.hasNext) {
                    if (res.isFault) {
                        errorList.push(res.errorMessage);
                        return;
                    }

                    userList.push(res.user);
                }
                else {
                    observer.next({ userList: userList, isFault: errorList.length > 0 ? true : false, errorMessage: errorList.join(', ') });
                    observer.complete();
                }
            });
        });
    }

    getCurrentUserByID(userID: string): UserInfo {
        return this._userMap[userID];
    }

    isUserExist(userID: string): boolean {
        return this._userMap[userID] ? true : false;
    }

    inviteMultipleUsers(inviteUserList: UserInfo[], commonMessage?: string): Observable<{ user: UserInfo, hasNext: boolean, isFault: boolean, errorMessage?: string }> {
        //check user group & user policy first to see if they are exist.
        let errorMessage: string;
        for (let user of inviteUserList) {
            if (!user.firstName) {
                errorMessage = 'Missing first name on some users';
                break;
            }
            if (!user.lastName) {
                errorMessage = 'Missing last name on some users';
                break;
            }
            if (!user.email) {
                errorMessage = 'Missing email on some users';
                break;
            }
            if (!user.userGroupID) {
                errorMessage = 'Missing user group name on some users';
                break;
            }
            if (!user.userRole) {
                errorMessage = 'Missing user policy name on some users';
                break;
            }
        }

        if (errorMessage) {
            return observableOf({ hasNext: false, user: null, isFault: true, errorMessage: errorMessage });
        }

        return observableOf(true).pipe(
            concatMap(() => {
                const obs: Observable<{ user: UserInfo, isFault: boolean, hasNext: boolean, errorMessage?: string }>[] = [];

                inviteUserList.forEach((u: UserInfo) => {
                    obs.push(this.inviteUser(u).pipe(
                        map((res: { user: UserInfo, isFault: boolean, errorMessage?: string }) => Object.assign({ hasNext: true }, res))
                    ));
                });

                obs.push(observableOf({
                    hasNext: false,
                    user: null,
                    isFault: false
                }));

                return obs;
            }),
            concatAll()
        );
    }

    inviteUser(user: UserInfo, message?: string): Observable<{ user: UserInfo, isFault: boolean, errorMessage?: string }> {
        const isNewUser: boolean = user.id ? false : true;
        if (isNewUser) {
            user.invitationStatus = UserInviteStatus.invited;
        }

        //send a invitation email
        return this.naSvc.inviteEnterpriseAccount({
            accountRole: user.userRole,
            accountGroupID: user.userGroupID,
            accountInfo: {
                accountEmail: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                accountCompanyName: user.company,
                department: user.department
            },
            message: message
        }, this.accountSvc.token).pipe(
            map((res: IAPIRx<EnterpriseAccountBasicInfo[]>) => {
                if (res.error === 0) {
                    if (isNewUser) {
                        const newUser: EnterpriseAccountBasicInfo = res.data.find(u => u.accountEmail === user.email);
                        if (newUser) {
                            user.id = newUser.accountID;
                            this._userMap[newUser.accountID] = user;
                        }

                        this.onUserAdded.emit({ addedUserList: [user], remainingUserList: HelperLib.mapToList(this._userMap) });
                    }
                }

                return {
                    user: user,
                    isFault: res.error !== 0,
                    errorMessage: HelperLib.getErrorMessage(res)
                }
            })
        );
    }

    removeUser(userList: UserInfo[]): Observable<{ isFault: boolean, errorMessage?: string }> {
        return new Observable((observer) => {
            const errorList: string[] = [];
            const removedUserList: UserInfo[] = [];

            const obs: Observable<{ isFault: boolean, hasNext: boolean }>[] = userList.map(u => this.naSvc.removeEnterpriseAccount(u.id, this.accountSvc.token).pipe(
                map((res: IAPIRx<EnterpriseAccountBasicInfo[]>) => {
                    if (res.error === 0) {
                        removedUserList.push(u);
                    }

                    return {
                        hasNext: true,
                        isFault: res.error !== 0,
                        errorMessage: HelperLib.getErrorMessage(res)
                    }
                })
            ));

            obs.push(observableOf({
                hasNext: false,
                isFault: false
            }));

            observableOf(true).pipe(
                concatMap(() => obs),
                concatAll()
            ).subscribe((res: { isFault: boolean, hasNext: boolean, errorMessage?: string }) => {
                if (!res.hasNext) {
                    if (removedUserList.length > 0) {
                        removedUserList.forEach((user: UserInfo) => {
                            if (this._userMap[user.id]) {
                                delete this._userMap[user.id];
                            }
                        });

                        this.onUserRemoved.emit({ removedUserList: removedUserList, remainingUserList: HelperLib.mapToList(this._userMap) });
                    }

                    observer.next({ isFault: errorList.length > 0, errorMessage: errorList.join(',') });
                    observer.complete();
                    return;
                }

                if (res.errorMessage) {
                    errorList.push(res.errorMessage);
                }
            });
        });
    }

    resetUserPassword(user: UserInfo): Observable<{ isFault: boolean, errorMessage?: string }> {
        return this.naSvc.resetPassword({ accountEmail: user.email }).pipe(
            map(res => {
                return {
                    isFault: res.error !== 0,
                    errorMessage: HelperLib.getErrorMessage(res)
                }
            })
        );
    }

    updateMultipleUsers(userList: UserInfo[]): Observable<{ hasNext: boolean, isFault: boolean, errorMessage?: string, user?: UserInfo }> {
        return observableOf(true).pipe(
            concatMap(() => {
                const obs: Observable<{ user?: UserInfo, isFault: boolean, hasNext: boolean, errorMessage?: string }>[] = [];

                userList.forEach((u: UserInfo) => {
                    obs.push(this.updateUser(u).pipe(
                        map((res: { isFault: boolean, errorMessage?: string, user?: UserInfo }) => Object.assign({ hasNext: true }, res))
                    ));
                });

                obs.push(observableOf({
                    hasNext: false,
                    user: null,
                    isFault: false
                }));

                return obs;
            }),
            concatAll()
        )
    }

    updateUser(user: UserInfo): Observable<{ isFault: boolean, errorMessage?: string, user?: UserInfo }> {
        return this.naSvc.updateEnterpriseAccount(user.id, {
            role: user.userRole,
            accountGroupID: user.userGroupID,
            personalData: {
                firstName: user.firstName,
                lastName: user.lastName,
                companyName: user.company,
                departmentName: user.department
            }
        }, this.accountSvc.token).pipe(
            map((res: IAPIRx<EnterpriseAccountBasicInfo>) => {
                if (res.error === 0) {
                    const lastUserGroupID: string = this._userMap[user.id].userGroupID;

                    this._userMap[user.id].firstName = user.firstName;
                    this._userMap[user.id].lastName = user.lastName;
                    this._userMap[user.id].company = user.company;
                    this._userMap[user.id].department = user.department;
                    this._userMap[user.id].userGroupID = res.data.accountGroupID;
                    this._userMap[user.id].userRole = user.userRole;
                    this._userMap[user.id].invitationStatus = UserInviteStatus[res.data.status];

                    this.onUserUpdated.emit({ updatedUserList: [this._userMap[user.id]], remainingUserList: HelperLib.mapToList(this._userMap) });
                    //notify devGroup service to refresh its viewable device groups
                    if (lastUserGroupID !== res.data.accountGroupID && user.id === this.accountSvc.accountID) {
                        this.onTargetDeviceGroupUpdated.emit({ deviceGroupIDList: this._userGroupMap[res.data.accountGroupID].appliedDeviceGroupIDList });
                    }
                }

                return {
                    isFault: res.error !== 0,
                    user: this._userMap[user.id],
                    errorMessage: HelperLib.getErrorMessage(res)
                };
            })
        );
    }

    getUserRoleList(forceRefresh: boolean = false): Observable<{ userRoleList: { name: string, desc: string }[], isFault: boolean, errorMessage?: string }> {
        if (this._updatingUserRole) {
            return new Observable((observer) => {
                HelperLib.checkState(1, () => { return !this._updatingUserRole }, () => {
                    observer.next({ isFault: false, userRoleList: HelperLib.mapToList(this._userRoleMap) });
                    observer.complete();
                });
            });
        }

        //1. no last update
        //2. last update time > 30 min
        //3. no user policy
        const needUpdate: boolean = (forceRefresh || !this._lastUserRoleUpdateTime || Date.now() - this._lastUserRoleUpdateTime.getTime() > this.USER_REFRESH_DURATION) || HelperLib.mapToList(this._userRoleMap).length === 0 ? true : false;
        if (needUpdate) {
            this._updatingUserRole = true;
            return observableOf(true).pipe(
                map(() => {
                    this._lastUserRoleUpdateTime = new Date();
                    this._updatingUserRole = false;

                    return { isFault: false, userRoleList: HelperLib.mapToList(this._userRoleMap) };
                })
            );
        }
        else {
            return observableOf({ isFault: false, userRoleList: HelperLib.mapToList(this._userRoleMap) });
        }
    }

    getUserRole(policyID: string): Observable<{ role: { name: string, desc: string }, isFault: boolean, errorMessage?: string }> {
        if (this._updatingUserRole) {
            return new Observable((observer) => {
                HelperLib.checkState(1, () => { return !this._updatingUserGroup }, () => {
                    observer.next({
                        isFault: !this._userRoleMap[policyID],
                        role: this._userRoleMap[policyID],
                        errorMessage: !this._userRoleMap[policyID] ? 'Policy ' + policyID + ' is not exist' : ''
                    });
                    observer.complete();
                });
            });
        }

        return observableOf({
            isFault: !this._userRoleMap[policyID],
            role: this._userRoleMap[policyID],
            errorMessage: !this._userRoleMap[policyID] ? 'Policy ' + policyID + ' is not exist' : ''
        });
    }

    getUserGroupList(forceRefresh: boolean = false): Observable<{ userGroupList: UserGroupInfo[], isFault: boolean, errorMessage?: string }> {
        if (this._updatingUserGroup) {
            return new Observable((observer) => {
                HelperLib.checkState(1, () => { return !this._updatingUserGroup }, () => {
                    observer.next({ isFault: false, userGroupList: HelperLib.mapToList<UserGroupInfo>(this._userGroupMap) });
                    observer.complete();
                });
            });
        }

        //1. no last update
        //2. last update time > 30 min
        //3. no user policy
        const needUpdate: boolean = (forceRefresh || !this._lastUserGroupUpdateTime || Date.now() - this._lastUserGroupUpdateTime.getTime() > this.USER_REFRESH_DURATION) || HelperLib.mapToList<UserGroupInfo>(this._userGroupMap).length === 0 ? true : false;
        if (needUpdate) {
            this._updatingUserGroup = true;
            return this.naSvc.listEnterpriseAccountGroup(this.accountSvc.token).pipe(
                map((res: IAPIRx<EnterpriseAccountGroupBasicInfo[]>) => {
                    this._lastUserGroupUpdateTime = new Date();
                    this._updatingUserGroup = false;

                    if (res.error === 0 && res.data) {
                        this._lastUserGroupUpdateTime = new Date();
                        res.data.forEach(r => {
                            const ug: UserGroupInfo = new UserGroupInfo(r.groupID, r.groupName, r.isDefault, r.deviceGroupList, r.autoIncludeNewDeviceGroup);
                            this._userGroupMap[ug.id] = ug;

                            if (ug.isDefault) {
                                this._defaultUserGroupID = ug.id;
                            }
                        });
                    }

                    return { isFault: false, userGroupList: HelperLib.mapToList<UserGroupInfo>(this._userGroupMap) };
                })
            );
        }
        else {
            return observableOf({ isFault: false, userGroupList: HelperLib.mapToList<UserGroupInfo>(this._userGroupMap) });
        }
    }

    getCurrentUserGroupByID(userGroupID: string): UserGroupInfo {
        return this._userGroupMap[userGroupID];
    }

    createUserGroup(groupName: string): Observable<{ group?: UserGroupInfo, isFault: boolean, errorMessage?: string }> {
        //check if groupName already exists
        if (this.getUserGroupByName(groupName)) {
            return observableOf({ isFault: true, errorMessage: 'User group name already exists' });
        }

        return this.naSvc.createEnterpriseAccountGroup(groupName, this.accountSvc.token).pipe(
            map((res: IAPIRx<EnterpriseAccountGroupBasicInfo>) => {
                let new_ug: UserGroupInfo;

                if (res.error === 0) {
                    new_ug = new UserGroupInfo(res.data.groupID, res.data.groupName, res.data.isDefault, res.data.deviceGroupList, res.data.autoIncludeNewDeviceGroup);
                    this._userGroupMap[new_ug.id] = new_ug;

                    this.onUserGroupAdded.emit({
                        addedUserGroup: new_ug,
                        remainingUserGroupList: HelperLib.mapToList<UserGroupInfo>(this._userGroupMap)
                    });
                }

                return {
                    isFault: res.error !== 0,
                    group: new_ug,
                    errorMessage: HelperLib.getErrorMessage(res)
                };
            })
        );
    }

    removeUserGroup(ug: UserGroupInfo): Observable<{ group: UserGroupInfo, isFault: boolean, errorMessage?: string }> {
        return this.naSvc.removeEnterpriseAccountGroup(ug.id, this.accountSvc.token).pipe(
            map((res: IAPIRx<{ [groupID: string]: EnterpriseAccountGroupBasicInfo }>) => {
                if (res.error === 0) {
                    delete this._userGroupMap[ug.id];

                    let bAffectCurrentAccount: boolean = this._userMap[this.accountSvc.accountID] && this._userMap[this.accountSvc.accountID].userGroupID === ug.id ? true : false;

                    const affectedUserList: UserInfo[] = Object.keys(this._userMap).filter(userID => this._userMap[userID].userGroupID === ug.id).map(userID => {
                        this._userMap[userID].userGroupID = this.getDefaultUserGroup().id;

                        return this._userMap[userID];
                    });

                    this.onUserGroupRemoved.emit({
                        removedUserGroup: ug,
                        remainingUserGroupList: HelperLib.mapToList<UserGroupInfo>(this._userGroupMap)
                    });

                    this.onUserUpdated.emit({
                        updatedUserList: affectedUserList,
                        remainingUserList: HelperLib.mapToList<UserInfo>(this._userMap)
                    });

                    if (bAffectCurrentAccount) {
                        this.onTargetDeviceGroupUpdated.emit({ deviceGroupIDList: this.getDefaultUserGroup().appliedDeviceGroupIDList });
                    }
                }

                return {
                    isFault: res.error !== 0,
                    group: ug,
                    errorMessage: HelperLib.getErrorMessage(res)
                };
            })
        );
    }

    moveUserToAnotherUserGroup(userList: UserInfo[], from: UserGroupInfo, to: UserGroupInfo): Observable<{ isFault: boolean, errorMessage?: string }> {
        if (from.id === to.id) {
            return observableOf({
                isFault: true,
                errorMessage: 'No need to move'
            });
        }

        return new Observable(observer => {
            const errorList: string[] = [];

            const obs: Observable<{ hasNext: boolean, isFault: boolean, errorMessage?: string }>[] = userList.map(u => {
                return this.naSvc.updateEnterpriseAccount(u.id, { accountGroupID: to.id }, this.accountSvc.token).pipe(
                    map((res: IAPIRx<EnterpriseAccountBasicInfo>) => {
                        if (res.error === 0) {
                            this._userMap[u.id].userGroupID = res.data.accountGroupID;
                            if (u.id === this.accountSvc.accountID) {
                                this.onTargetDeviceGroupUpdated.emit({ deviceGroupIDList: this._userGroupMap[res.data.accountGroupID].appliedDeviceGroupIDList });
                            }
                        }

                        return {
                            hasNext: true,
                            isFault: res.error !== 0,
                            errorMessage: HelperLib.getErrorMessage(res)
                        }
                    })
                )
            });

            obs.push(observableOf({
                hasNext: false,
                isFault: false
            }));

            observableOf(true).pipe(
                concatMap(() => obs),
                concatAll()
            ).subscribe((res: { isFault: boolean, hasNext: boolean, errorMessage?: string }) => {
                if (!res.hasNext) {

                    this.onUserUpdated.emit({
                        updatedUserList: userList,
                        remainingUserList: HelperLib.mapToList<UserInfo>(this._userMap)
                    });

                    observer.next({ isFault: errorList.length > 0, errorMessage: errorList.join(',') });
                    observer.complete();
                    return;
                }

                if (res.errorMessage) {
                    errorList.push(res.errorMessage);
                }
            });
        });
    }

    addOrRemoveDeviceGroupToUserGroup(deviceGroupID: string, targetUserGroupIDList: string[]): Observable<{ isFault: boolean, errorMessage?: string, userGroupList?: UserGroupInfo[] }> {
        return new Observable(observer => {
            const obs: Observable<{ hasNext: boolean, isFault: boolean, errorMessage?: string }>[] = [];

            HelperLib.mapToList(this._userGroupMap).forEach((ug: UserGroupInfo) => {
                const foundDevGroupID: string = ug.appliedDeviceGroupIDList.find(devGroupID => devGroupID === deviceGroupID);
                const foundTargetUserGroupID: string = targetUserGroupIDList.find(uID => uID === ug.id);

                let targetDevGroupIDList: string[];
                if (foundDevGroupID && !foundTargetUserGroupID) {
                    //(v) d group -> (x) d group
                    targetDevGroupIDList = ug.appliedDeviceGroupIDList.map(d => d);
                    targetDevGroupIDList.splice(targetDevGroupIDList.indexOf(foundDevGroupID), 1);
                }
                else if (!foundDevGroupID && foundTargetUserGroupID) {
                    //(x) d group -> (v) d group
                    targetDevGroupIDList = ug.appliedDeviceGroupIDList.map(d => d);
                    targetDevGroupIDList.push(deviceGroupID);
                }

                if (targetDevGroupIDList) {
                    obs.push(this.naSvc.assignDeviceGroupToEnterpriseAccountGroup(ug.id, targetDevGroupIDList, this.accountSvc.token).pipe(
                        map((res: IAPIRx<EnterpriseAccountGroupBasicInfo>) => {
                            if (res.error === 0 && this._userGroupMap[res.data.groupID]) {
                                this._userGroupMap[res.data.groupID].appliedDeviceGroupIDList = res.data.deviceGroupList;
                                if (this._userMap[this.accountSvc.accountID] && this._userMap[this.accountSvc.accountID].userGroupID === res.data.groupID) {
                                    this.onTargetDeviceGroupUpdated.emit({ deviceGroupIDList: res.data.deviceGroupList });
                                }
                            }

                            return {
                                hasNext: true,
                                isFault: res.error !== 0,
                                errorMessage: HelperLib.getErrorMessage(res),
                            }
                        })
                    ));
                }
            });

            obs.push(of({
                hasNext: false,
                isFault: false
            }));

            const errorList: string[] = [];
            of(true).pipe(
                concatMap(() => {
                    return obs;
                }),
                concatAll()
            ).subscribe((res: { hasNext: boolean, isFault: boolean, errorMessage?: string }) => {
                if (!res.hasNext) {
                    observer.next({ isFault: errorList.length > 0, errorMessage: errorList.join(', '), userGroupList: HelperLib.mapToList(this._userGroupMap) });
                    observer.complete();
                }
                else if (res.isFault) {
                    errorList.push(res.errorMessage);
                }
            })
        });
    }

    updateUserGroup(userGroup: UserGroupInfo, updateGroupName: string, autoAssignDeviceGroup: boolean, isTargetDeviceGroupChanged: boolean, targetDeviceGroupIDList?: string[]): Observable<{ isFault: boolean, errorMessage?: string }> {
        //check if group name is empty.
        if (!updateGroupName) {
            return observableOf({
                isFault: true,
                errorMessage: 'Group name is empty'
            });
        }

        //check if group name is exists
        const ug: UserGroupInfo = this.getUserGroupByName(updateGroupName);
        if (ug && ug.id !== userGroup.id) {
            return observableOf({
                isFault: true,
                errorMessage: 'Group name <' + updateGroupName + '> already exists.'
            });
        }

        return this.naSvc.updateEnterpriseAccountGroup(userGroup.id, updateGroupName, autoAssignDeviceGroup, this.accountSvc.token).pipe(
            concatMap((res: IAPIRx<EnterpriseAccountGroupBasicInfo>) => {
                if (res.error !== 0) {
                    throw HelperLib.getErrorMessage(res);
                }

                this._userGroupMap[userGroup.id].name = updateGroupName;
                this._userGroupMap[userGroup.id].autoAssignDeviceGroup = autoAssignDeviceGroup;

                if (isTargetDeviceGroupChanged) {
                    return this.naSvc.assignDeviceGroupToEnterpriseAccountGroup(userGroup.id, targetDeviceGroupIDList, this.accountSvc.token).pipe(
                        map((res: IAPIRx<EnterpriseAccountGroupBasicInfo>) => {
                            if (res.error !== 0) {
                                throw HelperLib.getErrorMessage(res);
                            }

                            this._userGroupMap[userGroup.id].appliedDeviceGroupIDList = res.data.deviceGroupList;

                            //notify devGroup service to refresh its viewable device groups
                            if (this._userMap[this.accountSvc.accountID].userGroupID === userGroup.id) {
                                this.onTargetDeviceGroupUpdated.emit({ deviceGroupIDList: targetDeviceGroupIDList });
                            }

                            return {
                                isFault: false
                            };
                        })
                    );
                }

                return observableOf({
                    isFault: false
                });
            }),
            catchError(err => {
                return observableOf({
                    isFault: true,
                    errorMessage: err.toString()
                })
            })
        );
    }

    private getUserGroupByName(groupName: string): UserGroupInfo {
        const groupID: string = Object.keys(this._userGroupMap).find((groupID: string) => this._userGroupMap[groupID].name.toLocaleLowerCase() === groupName.toLocaleLowerCase());
        return groupID ? this._userGroupMap[groupID] : null;
    }

    getDefaultUserGroup(): UserGroupInfo {
        return this._userGroupMap[this._defaultUserGroupID];
    }

    updateUserGroupCache(): void {
        this._lastUserGroupUpdateTime = null;
    }
}