import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DashboardComponent } from './dashboard.component';
import { DeviceDetailComponent } from './detail/device-detail.component';
import { DeviceActivityMDComponent } from './comp/dev-activity-md.component';

import { DeviceService } from './device.service';
import { TranslateModule } from '../../translate/translate.module';
import { CustomPipeModule } from '../../lib/pipes/custom-pipe.module';
import { UIElementModule } from '../../uiElement/uiElement.module';
import { TroubleshootModule } from '../troubleshoot/troubleshoot.module';

import { CustomDirectiveModule } from '../../directive/custom-directive.module';

import { DevFuncModule } from '../devfunc/dev-func.module';
import { DeviceInfoGeneralComponent } from './detail/comp/device-info-general.component';
import { DeviceInfoNetComponent } from './detail/comp/device-info-net.component';
import { DeviceInfoLicenseComponent } from './detail/comp/device-info-license.component';
import { DeviceInfoScreenshotComponent } from './detail/comp/device-info-screenshot.component';
import { DeviceInfoPolicyComponent } from './detail/comp/device-info-policy.component';
import { DeviceGroupModule } from './group/group.module';
import { PairDeviceCOmponent } from './pair/dev-pair-own.component';
import { DevicePickerComponent } from './comp/dev-picker.component';
import { RemoteModule } from './remote/remote.module';
import { DeviceLabelModule } from './label/device-label.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        BrowserAnimationsModule,

        TranslateModule,
        CustomPipeModule,
        UIElementModule,
        TroubleshootModule,
        DevFuncModule,
        CustomDirectiveModule,
        DeviceGroupModule,
        DeviceLabelModule,
        RemoteModule
    ],
    declarations: [
        DashboardComponent,
        DeviceDetailComponent,
        DevicePickerComponent,
        DeviceDetailComponent,
        DeviceActivityMDComponent,
        DeviceInfoGeneralComponent,
        DeviceInfoNetComponent,
        DeviceInfoPolicyComponent,
        DeviceInfoLicenseComponent,
        DeviceInfoScreenshotComponent,
        PairDeviceCOmponent,
    ],
    providers: [
        DatePipe,
        DeviceService
    ]
})
export class DeviceModule { }