import { Injectable } from '@angular/core';

import { DevFuncItem } from './dev-func-item';
import { FirmwareUpdateFormComponent } from './firmware/firmware-update-form.component';
import { BasicConfigFormComponent } from './config/basic-config-form.component';
import { NetConfigFormComponent } from './config/net-config-form.component';
import { AlertFormComponent } from './alert/alert-form.component';
import { IssueFormComponent } from './troubleshoot/issue-form.component';
import { RebootFormComponent } from './reboot/reboot-form.component';
import { TranslateService } from '../../translate/translate.service';
import { APKFormComponent } from './apk/apk-form.component';
import { MultiBasicConfigFormComponent } from './config-m/m-basic-config-form.component';
import { SecurityFormComponent } from './security/security-form.component';
import { ClearCacheFormComponent } from './clear/clearCache-form.component';
import { OTPFormComponent } from './otp/otp-form.component';
import { ClearAppDataFormComponent } from './clear/clearAppData-form.component';
import { ScriptFormComponent } from './script/script-form.component';

@Injectable()
export class DevFuncService {
  readonly FUNCNAME_REBOOT: string = 'dev-func-reboot';
  readonly FUNCNAME_ISSUE_REPORT: string = 'dev-func-issueReport';
  readonly FUNCNAME_FIRMWARE: string = 'dev-func-firmware';
  readonly FUNCNAME_APK: string = 'dev-func-apk';
  readonly FUNCNAME_BASIC_CONFIG: string = 'dev-func-basicConfig';
  readonly FUNCNAME_MULTI_BASIC_CONFIG: string = 'dev-func-multiBasicConfig';
  readonly FUNCNAME_NET_CONFIG: string = 'dev-func-netConfig';
  readonly FUNCNAME_EMAIL_ALERT_CONFIG: string = 'dev-func-emailAlertConfig';
  readonly FUNCNAME_SECURITY: string = 'dev-func-security';
  readonly FUNCNAME_CLEARCACHE: string = 'dev-func-clearCache';
  readonly FUNCNAME_CLEARAPPDATA: string = 'dev-func-clearAppData';
  readonly FUNCNAME_OTP: string = 'dev-func-otp';
  readonly FUNCNAME_SCRIPT: string = 'dev-func-script';

  private _dev_funs: DevFuncItem[] = [];

  constructor(private translateSvc: TranslateService) {
    this._dev_funs = [
      new DevFuncItem(RebootFormComponent, this.FUNCNAME_REBOOT, this.translateSvc.instant('key-dev-reboot')),
      new DevFuncItem(IssueFormComponent, this.FUNCNAME_ISSUE_REPORT, this.translateSvc.instant('key-troubleshoot')),
      new DevFuncItem(FirmwareUpdateFormComponent, this.FUNCNAME_FIRMWARE, this.translateSvc.instant('key-dev-firmwareUpdate')),
      new DevFuncItem(APKFormComponent, this.FUNCNAME_APK, this.translateSvc.instant('key-dev-apkInstall')),
      new DevFuncItem(BasicConfigFormComponent, this.FUNCNAME_BASIC_CONFIG, this.translateSvc.instant('key-dev-basicConfig')),
      new DevFuncItem(MultiBasicConfigFormComponent, this.FUNCNAME_MULTI_BASIC_CONFIG, this.translateSvc.instant('key-dev-basicConfig')),
      new DevFuncItem(NetConfigFormComponent, this.FUNCNAME_NET_CONFIG, this.translateSvc.instant('key-dev-netConfig')),
      new DevFuncItem(AlertFormComponent, this.FUNCNAME_EMAIL_ALERT_CONFIG, 'alert setting'),
      new DevFuncItem(SecurityFormComponent, this.FUNCNAME_SECURITY, 'Update security password'),
      new DevFuncItem(ClearCacheFormComponent, this.FUNCNAME_CLEARCACHE, 'clear cache'),
      new DevFuncItem(ClearAppDataFormComponent, this.FUNCNAME_CLEARAPPDATA, 'clear app data'),
      new DevFuncItem(OTPFormComponent, this.FUNCNAME_OTP, 'OTP'),
      new DevFuncItem(ScriptFormComponent, this.FUNCNAME_SCRIPT, 'Custom scripts')
    ];
  }

  getAllFuncs(): DevFuncItem[] {
    return this._dev_funs;
  }

  getFunctionByName(name: string): DevFuncItem {
    return this._dev_funs.find(i => i.name === name);
  }
}
