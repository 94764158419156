import { Component } from '@angular/core';

import { DevFuncConfirmInterface } from '../../base/confirm/dev-func-confirm.interface';
import { FwUpdateMethod, FirmwareUpdateScheduleInfo, FwUpdateStatus } from '../firmware-data';
import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { DeviceInfo } from '../../../../../app/content/device/data/device-info';

@Component({
    templateUrl: './firmware-confirm-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './firmware-confirm-subform.component.css']
})
export class FirmwareConfirmSubFormComponent implements DevFuncConfirmInterface {
    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._fwSchedule = this._actionData.fwUpdateSchedule;
            this._devices = this._actionData.devices;
            this._fwStatusMap = this._actionData.fwStatusMap;
            this._fwUpdateMethod = this._actionData.fwUpdateMethod;
            this._customDownloadLink = this._actionData.customDownloadLink;

            this.checkNeedUpdate();
        }
    }

    set allowSubmitCallback(fn: (isAllow: boolean) => void) {
        fn(this._needUpdate ? true : false);
    }

    _needUpdate: boolean = false;
    _enumFwUpdateMethod: typeof FwUpdateMethod = FwUpdateMethod;
    _fwSchedule: FirmwareUpdateScheduleInfo;
    _customDownloadLink: string = '';
    _fwUpdateMethod: FwUpdateMethod = FwUpdateMethod.Unknown;
    _devices: DeviceInfo[] = [];
    _fwStatusMap: { [id: string]: { status: string, needUpdate: boolean, link?: string, version?: string } } = {};

    constructor(private constantSvc: ConstantService) { }

    private checkNeedUpdate(): void {
        if (this._fwUpdateMethod === FwUpdateMethod.FromURL) {
            this._devices.forEach((d: DeviceInfo) => {
                this._fwStatusMap[d.virtualId] = { status: `${d.currentSettings[this.constantSvc.DEVKEY_INFO_FW_VERSION]} → Specific URL`, needUpdate: true, link: this._customDownloadLink };
            });
            this._needUpdate = true;
        }
        else {
            this._devices.forEach((d: DeviceInfo) => {
                if (d.trackingMap.has(this.constantSvc.TASKTYPE_CHECK_FIRMWARE)) {
                    // under checking latest firmware
                    this._fwStatusMap[d.virtualId] = { status: `Checking for updates... Please wait...`, needUpdate: false };
                }
                else {
                    // not checking
                    if (!d.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_STATUS]) {
                        this._fwStatusMap[d.virtualId] = { status: `Unknown status. Please update to newer firmware manually.`, needUpdate: false };
                        return;
                    }
                    switch (d.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_STATUS]) {
                        case FwUpdateStatus.WAITING_INSTALL:
                            {
                                this._fwStatusMap[d.virtualId] = { status: `${d.currentSettings[this.constantSvc.DEVKEY_INFO_FW_VERSION]} → ${d.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_INFO].firmwareVersion}`, needUpdate: true };
                                this._needUpdate = true;
                            }
                            break;
                        case FwUpdateStatus.INSTALLING:
                        case FwUpdateStatus.WAITING_REBOOT:
                            {
                                this._fwStatusMap[d.virtualId] = { status: `Updating... Please wait...`, needUpdate: false };
                            }
                            break;
                        case FwUpdateStatus.UPDATE_STATUS_UNKNOWN:
                            {
                                this._fwStatusMap[d.virtualId] = { status: `Unknown status.`, needUpdate: false };
                            }
                            break;
                        case FwUpdateStatus.CHECK_FAILED:
                        case FwUpdateStatus.DOWNLOAD_FAILED:
                        case FwUpdateStatus.INSTALL_FAILED:
                            {
                                this._fwStatusMap[d.virtualId] = { status: `Last update failed. Please try again.`, needUpdate: false };
                            }
                            break;
                        case FwUpdateStatus.WAITING_CHECK:
                        default:
                            {
                                this._fwStatusMap[d.virtualId] = { status: `You’re up to date. Last checked: ${d.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_LASTCHECKTIME]}`, needUpdate: false };
                            }
                            break;
                    }
                }
            });
        }
    }
}