import { Component } from "@angular/core";
import { VirtualDlgComponent } from "app/content/virtual/dlg/virtual-dlg.component";
import { IRemoteFuncCtrl } from "./remote-func.def";

@Component({
    templateUrl: './remote-dlg-notification.component.html'
})
export class RemoteDlgNotificationComponent extends VirtualDlgComponent implements IRemoteFuncCtrl {
    title: string;
    funcName: string;
    data: { title: string, content?: string, showMsgTimeout?: boolean, timeout?: Date };
    other?: any;
    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;
}