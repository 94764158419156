import { HttpClient, HttpResponse } from '@angular/common/http';
import { APIBaseManager, IAPIRx } from '../../api.base';
import { ILicenseCategoryInfo, ILicenseInfo } from './api.license.common';

export interface IGetLicenseByAssigneeTxData {
    assigneeList: {
        virtualDeviceID: string;
    }[];
}

export interface IGetLicenseByAssigneeRxData {
    assignee: {
        virtualDeviceID: string;
    };
    scope: string[];
    licenses: {
        [licenseCategory: string]: ILicenseCategoryInfo;
    }
}

export class APIGetLicenseByAssigneeManager extends APIBaseManager<void, void, IGetLicenseByAssigneeTxData, IGetLicenseByAssigneeRxData[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetLicenseByAssignee';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'licenseKeys/assignee';
    }

    protected doPostAftermathAction(res: HttpResponse<IAPIRx<IGetLicenseByAssigneeRxData[]>>): boolean {
        const serverTime = parseInt(res.headers.get('IAdea-Server-Timestamp-Milliseconds'));
        if (serverTime) {
            res.body.serverTime = serverTime;
        }

        return true;
    }
}