<!-- group on/off -->
<div class="topbar">
  <div class="ml-2 d-flex align-items-center">
    <div *ngIf="_filterRecord" class="red-1 mr-2">
      <div class="dropdown">
        <i data-toggle="dropdown" aria-expanded="false" class="fas fa-filter red-1"></i>
        <div class="dropdown-menu adv-filter-notification">
          <label>Filters :</label>
          <div class="ml-2">
            <div *ngFor="let filter of _filterRecord | keyvalue">
              <label class="title">{{ filter.key }}</label>
              <ul class="content">
                <li *ngFor="let filterDesc of filter.value" [innerHtml]="filterDesc">
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-4 mb-2 text-center">
            <button type="button" class="btn btn-sm btn-action btn-light" (click)="clearFilters()">Reset all
              filters</button>
          </div>
        </div>
      </div>
    </div>
    <na-dev-adv-filter-container [isReady]="!_isDeviceLoading"></na-dev-adv-filter-container>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div *ngIf="_devGroupSwitch === _enumGroupSwitch.off" class="sidebar">
      <i class="fas fa-angle-right hover" data-target="tooltip" title="Expand the 'device group & tag' sidebar"
        (click)="turnOnOffSidebar(_enumGroupSwitch.on)"></i>
    </div>
    <div [ngClass]="_devGroupSwitch === _enumGroupSwitch.on ? 'col-12 col-lg-3 px-0' : 'd-none'">
      <ul class="nav nav-tabs align-items-center" role="tablist">
        <li class="nav-item">
          <div class="nav-link" [class.active]="_devFilterType === _enumDevFilterType.ByGroup" id="dev-group-tab"
            href="#devGroup" data-toggle="tab" role="tab" aria-controls="dev-group-tab"
            (click)="selectDeviceFilterType(_enumDevFilterType.ByGroup)">
            Device groups
          </div>
        </li>
        <li class="nav-item">
          <div class="nav-link" [class.active]="_devFilterType === _enumDevFilterType.ByLabel" id="dev-label-tab"
            href="#devLabel" data-toggle="tab" role="tab" aria-controls="dev-label-tab"
            (click)="selectDeviceFilterType(_enumDevFilterType.ByLabel)">
            Tag
          </div>
        </li>
        <i class="fas fa-angle-left ml-auto mr-2 hover" data-target="tooltip"
          title="Collapse the 'device group & tag' sidebar" (click)="turnOnOffSidebar(_enumGroupSwitch.off)"></i>
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade" [ngClass]="_devFilterType === _enumDevFilterType.ByGroup ? 'active show' : ''"
          id="devGroup" role="tabpanel" aria-labelledby="dev-group-tab">
          <!-- group trees -->
          <div class="group-tree" [class.d-none]="!_showGroupTree">
            <div class="group-tree-body">
              <na-dev-group-tree-wrapper [allowFilter]="true" [showTitle]="true" [policyMode]="'dlg'" [styleH]="'calc(100vh - 190px)'"
                [styleBorder]="!_groupCtrlAllowHide" [enableCollapseFunc]="_groupCtrlAllowHide"
                [enableUnfoldFunc]="true" [selectGroup]="_selectedDeviceGroup"
                (onGroupTreeDisplayChanged)="_showGroupTree = $event"
                (onGroupFuncTrigger)="playGroupFunc($event.funcName, $event.group)"
                (onGroupSelected)="onDeviceGroupInspect($event)">
              </na-dev-group-tree-wrapper>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="_devFilterType === _enumDevFilterType.ByLabel ? 'active show' : ''"
          id="devLabel" role="tabpanel" aria-labelledby="dev-label-tab">
          <na-dev-label-picker [defaultLabel]="_selectedDeviceLabel"
            (onLabelSelected)="onDeviceLabelSelected($event)"></na-dev-label-picker>
        </div>
      </div>
    </div>
    <div class="px-0" [ngClass]="_devGroupSwitch === _enumGroupSwitch.on ? 'col-12 col-lg-9 pl-lg-2' : 'col-12'">
      <ng-container *ngIf="_devFilterType === _enumDevFilterType.ByGroup">
        <div *ngIf="_devGroupSwitch === _enumGroupSwitch.on" class="d-flex">
          <!-- device group routes -->
          <div class="d-lg-none my-1">
            <i class="fas fa-chevron-circle-down mr-2" data-toggle="tooltip" title="Show group"
              (click)="_showGroupTree = !_showGroupTree">
            </i>
          </div>
          <div *ngIf="_devGroupRouteList.length > 0" class="group-routes">
            <div class="d-flex">
              <div *ngFor="let g of _devGroupRouteList" class="group-route-item" (click)="inspectGroup(g, true)">
                {{ g.name }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="group-content">
        <ng-container *ngIf="_devFilterType === _enumDevFilterType.ByGroup">
          <!-- device group detail -->
          <div *ngIf="_devGroupSwitch === _enumGroupSwitch.on" class="mb-2">
            <div *ngIf="_selectedDeviceGroup" class="mb-2">
              <na-dev-group-detail [source]="_selectedDeviceGroup"
                (onSearchFilterRequested)="onDeviceDetailGroupSearchFilterRequest($event)"></na-dev-group-detail>
            </div>
            <div class="block-titlebar">
              <strong class="txt-bold">Device list</strong>
              <div class="ml-auto">
                <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit" data-toggle="modal"
                  data-target="#groupFuncModalHost" [disabled]="_selectedDeviceCount === 0"
                  (click)="changeDeviceGroup()">
                  <i class="fas fa-exchange-alt mr-1"></i>
                  Change group
                </button>
                <button *ngIf="_selectedDeviceGroup && !devGroupSvc.isDefaultGroup(_selectedDeviceGroup)" type="button"
                  class="btn btn-sm btn-action btn-light btn-nb btn-edit" data-toggle="modal"
                  data-target="#groupFuncModalHost" [disabled]="_selectedDeviceCount === 0"
                  (click)="removeDeviceFromGroup(_selectedDeviceGroup)">
                  <i class="fas fa-trash-alt mr-1"></i>
                  Remove from group
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="_devFilterType === _enumDevFilterType.ByLabel">
          <!-- device label detail -->
          <ng-container *ngIf="_selectedDeviceLabel">
            <na-dev-label-detail [source]="_selectedDeviceLabel"></na-dev-label-detail>
          </ng-container>
        </ng-container>
        <div>
          <na-dev-picker [filter]="_proactiveFilter"
            (onDeviceLoadingStatusChanged)="onDeviceLoadingStatusChange($event)"
            (onDeviceSelectionChanged)="onDeviceSelectionChange($event)">
          </na-dev-picker>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="groupFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog"
  tabindex="-1">
  <ng-template dev-group-func-host></ng-template>
</div>