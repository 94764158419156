import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, concatAll, concatMap, debounceTime, map, takeUntil } from 'rxjs/operators';

import { DeviceGroupFuncDirective } from '../dlg/group-func.directive';
import { DeviceGroupService } from '../dev-group.service';
import { DEVICE_GROUP_FUNC_DELETE, DeviceGroupInfo, DeviceGroupType } from '../group.data';
import { DialogPage } from '../../../../../app/lib/common/common.data';
import { DeviceGroupFuncInterface, DeviceGroupFuncItem } from '../dlg/group-func.def';
import { DeviceGroupFuncService } from '../dlg/group-func.service';
import { PolicyService } from '../../../../../app/content/setting/policy/policy.service';
import { PolicyInfo, PolicyType } from '../../../../../app/content/setting/policy/policy.data';
import { UserService } from '../../../../../app/content/admin/user/user.service';
import { UserGroupInfo } from '../../../../../app/content/admin/user/group/user-group.data';
import { AccountService } from '../../../../../app/entry/account.service';
import { TopMenuService } from '../../../menu.service';
import { HelperLib } from '../../../../lib/common/helper.lib';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    selector: 'na-dev-group-detail',
    templateUrl: './dev-group-detail.component.html',
    styleUrls: ['../dev-group.style.css', './dev-group-detail.component.css']
})
export class DeviceGroupDetailComponent extends AutoUnsubscribeComponent implements OnInit {
    readonly TEXT_LOADING: string = 'loading ...';

    _isInEdit: boolean;
    _errorMessage: string;
    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;

    _isDefaultGroup: boolean;
    _editGroupName: string;
    _userMgmRoute: string;
    _loading: boolean;
    _policyMap: { [type: string]: { displayName: string, current: PolicyInfo, next: PolicyInfo, avaliableList: PolicyInfo[] } } = {};

    _ctrlUserGroupMap: { [userGroupID: string]: { userGroup: UserGroupInfo, checkBefore: boolean, checkAfter: boolean } } = {};

    _devGroup: DeviceGroupInfo;
    @Input('source')
    set source(v: DeviceGroupInfo) {
        this._devGroup = v;
        this.init();
    }

    private _devGroupFuncHost: DeviceGroupFuncDirective;
    @ViewChild(DeviceGroupFuncDirective, { static: true })
    set devGroupFuncHost(host: any) {
        this._devGroupFuncHost = host;
    }

    @Output() onSearchFilterRequested = new EventEmitter<{ deviceGroup?: { groupID: string }, devicePolicy?: { policyID: string, policyState: string } }>();

    constructor(
        private accountSvc: AccountService,
        private devGroupSvc: DeviceGroupService,
        private groupFuncSvc: DeviceGroupFuncService,
        private policySvc: PolicyService,
        private userSvc: UserService,
        private menuSvc: TopMenuService
    ) {
        super();
        this._userMgmRoute = this.menuSvc.getUserRoute();
    }

    ngOnInit(): void {
        this.devGroupSvc.onGroupMoved.pipe(
            debounceTime(500),
            takeUntil(this._unsubscribe$)
        ).subscribe((ev: { movedDevices: DeviceGroupInfo[], targetGroupID: string }) => {
            this.init(true);
        });
    }

    supportDeviceGroupUpdate(): boolean {
        return this.accountSvc.hasScope_device_group_update();
    }

    private init(forceRefreshPolicyList: boolean = false): void {
        if (this._devGroup) {
            this._isDefaultGroup = this.devGroupSvc.isDefaultGroup(this._devGroup) ? true : false;
            this._policyMap = {};
            this._loading = true;

            const supportPolicyTypes: PolicyType[] = this.policySvc.getSupportPolicyTypesByLevel(this.accountSvc.isEnterprise());
            supportPolicyTypes.forEach(type => {
                this._policyMap[type] = { displayName: HelperLib.splitUpperWord(type), current: null, next: null, avaliableList: [] };
            });

            this.policySvc.getPolicyList(forceRefreshPolicyList).pipe(
                concatMap((policyList: PolicyInfo[]) => {
                    policyList.forEach(p => {
                        if (this._policyMap[p.type]) {
                            this._policyMap[p.type].avaliableList.push(p);
                        }
                    });

                    if (this._devGroup.policies) {
                        supportPolicyTypes.forEach(type => {
                            if (this._devGroup.policies[type]?.[0] && this._policyMap[type]) {
                                this._policyMap[type].current = this._policyMap[type].next = this._policyMap[type].avaliableList.find(p => p.id === this._devGroup.policies[type][0]);
                            }
                        });
                    }

                    // get the user groups which have permission to access this device group.
                    return this.userSvc.getUserGroupList();
                }),
                map((res: { userGroupList: UserGroupInfo[], isFault: boolean, errorMessage?: string }) => {
                    if (!res.isFault) {
                        res.userGroupList.forEach(u => {
                            const checked: boolean = u.appliedDeviceGroupIDList.find(devGroupID => devGroupID === this._devGroup.id) ? true : false;
                            this._ctrlUserGroupMap[u.id] = { userGroup: u, checkBefore: checked, checkAfter: checked };
                        });
                    }

                    return true;
                })
            ).subscribe(() => {
                this._loading = false;
            });
        }
    }

    searchUnsyncDeviceInPolicy(policy: PolicyInfo): void {
        this.onSearchFilterRequested.emit({ deviceGroup: { groupID: this._devGroup?.id }, devicePolicy: { policyID: policy?.id, policyState: 'Not Synced' } });
    }

    startEdit(): void {
        this._isInEdit = true;
        this._editGroupName = this._devGroup.name;
        Object.keys(this._policyMap).forEach(key => {
            this._policyMap[key].next = this._policyMap[key].current;
        });
    }

    cancelEdit(): void {
        this._isInEdit = false;
        this._errorMessage = null;
        Object.keys(this._policyMap).forEach((policyType: string) => {
            this._policyMap[policyType].next = this._policyMap[policyType].current;
        })
    }

    removeCurrentPolicy(policy: { displayName: string, current: PolicyInfo, next: PolicyInfo, avaliableList: PolicyInfo[] }): void {
        policy.next = null;
    }

    changeCurrentPolicy(policy: { displayName: string, current: PolicyInfo, next: PolicyInfo, avaliableList: PolicyInfo[] }, to: PolicyInfo): void {
        policy.next = to;
    }

    checkoutUserGroup(userGroupID: string, checked: boolean): void {
        this._ctrlUserGroupMap[userGroupID].checkAfter = checked;
    }

    saveEdit(): void {
        this._errorMessage = '';
        this._page = DialogPage.submit;

        of(true).pipe(
            concatMap(() => {

                if (!this._editGroupName || this._editGroupName.indexOf('/') >= 0) {
                    throw 'Group name should not be empty or contains any "/" characters';
                }

                if (this._devGroup.name !== this._editGroupName) {
                    return this.devGroupSvc.renameGroup(null, this._devGroup, this._editGroupName).pipe(
                        map((res: { isFault: boolean, errorMessage?: string }) => {
                            if (res.isFault) {
                                throw res.errorMessage;
                            }

                            return { isFault: false };
                        })
                    )
                }

                return of({ isFault: false });
            }),
            //change dev group to which this policy should belong
            concatMap((res: { isFault: boolean, errorMessage?: string }) => this.changeGroupPolicy()),
            //change user group to which this device group should belong
            concatMap((res: { isFault: boolean, errorMessage?: string }) => this.userSvc.addOrRemoveDeviceGroupToUserGroup(this._devGroup.id, HelperLib.mapToList(this._ctrlUserGroupMap).filter(u => u.checkAfter).map(u => u.userGroup.id)).pipe(
                map((res: { isFault: boolean, errorMessage?: string, userGroupList?: UserGroupInfo[] }) => {
                    return {
                        isFault: res.isFault,
                        errorMessage: res.errorMessage
                    }
                })
            )),
            catchError((err: any) => of({ isFault: true, errorMessage: err }))
        ).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._page = DialogPage.result;
            this._errorMessage = res.errorMessage;

            if (!res.isFault) {
                this._isInEdit = false;
                this.init();
            }
        })
    }

    private changeGroupPolicy(): Observable<{ isFault: boolean, errorMessage?: string }> {
        const assignedPolicyList: PolicyInfo[] = [];
        const removedPolicyList: PolicyInfo[] = [];
        const policyList: { current: PolicyInfo, next: PolicyInfo }[] = this.policySvc.getSupportPolicyTypesByLevel(this.accountSvc.isEnterprise()).map(type => this._policyMap[type]);

        policyList.forEach(p => {
            if (p.next) {
                if (!p.current || p.current.id !== p.next.id) {
                    assignedPolicyList.push(p.next);
                }
            }
            else if (p.current) {
                removedPolicyList.push(p.current);
            }
        });

        const assignList: { id: string, type: DeviceGroupType, name: string }[] = [{
            id: this._devGroup.id,
            type: DeviceGroupType.group,
            name: this._devGroup.name
        }];

        return new Observable((observer) => {
            const errorMessageList: string[] = [];
            of(true).pipe(
                concatMap(() => {
                    const obs: Observable<{ hasNext: boolean, isFault: boolean, errorMessage?: string }>[] = [];
                    assignedPolicyList.forEach((p: PolicyInfo) => {
                        obs.push(this.policySvc.assignPolicy(p, assignList).pipe(
                            map((res: { error: number | string, errorMessage?: string }) => {
                                return {
                                    isFault: false,
                                    hasNext: true,
                                    errorMessage: res.errorMessage
                                }
                            })
                        ));
                    });

                    removedPolicyList.forEach((p: PolicyInfo) => {
                        obs.push(this.policySvc.assignPolicy(p, [], assignList).pipe(
                            map((res: { error: number | string, errorMessage?: string }) => {
                                return {
                                    isFault: false,
                                    hasNext: true,
                                    errorMessage: res.errorMessage
                                }
                            })
                        ));
                    });

                    if (assignedPolicyList.length > 0 || removedPolicyList.length > 0) {
                        obs.push(
                            //refresh groups to get the latest policies applied on group
                            this.devGroupSvc.refreshOwnerGroupList(false).pipe(
                                map(() => {
                                    return { hasNext: false, isFault: false };
                                })
                            )
                        );
                    }
                    else {
                        obs.push(of({ hasNext: false, isFault: false }));
                    }

                    return obs;
                }),
                concatAll()
            ).subscribe((res: { hasNext: boolean, isFault: boolean, errorMessage?: string }) => {
                if (res.isFault) {
                    errorMessageList.push(res.errorMessage);
                }
                if (!res.hasNext) {
                    observer.next({
                        isFault: errorMessageList.length > 0 ? true : false,
                        errorMessage: errorMessageList.length > 0 ? errorMessageList.join(', ') : ''
                    });
                    observer.complete();
                }
            });
        });
    }

    removeDeviceGroup(): void {
        this.createDevGroupFuncDlg(DEVICE_GROUP_FUNC_DELETE, this._devGroup);
    }

    private createDevGroupFuncDlg(funcName: string, g?: DeviceGroupInfo, other?: any): void {
        const item: DeviceGroupFuncItem = this.groupFuncSvc.getFunctionByName(funcName);
        if (item) {
            const viewContainerRef = this._devGroupFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<DeviceGroupFuncInterface>componentRef.instance).title = item.title;
            (<DeviceGroupFuncInterface>componentRef.instance).group = g || this.devGroupSvc.getActiveGroup();
        }
    }
}