export interface ITheme {
    id: string;
    name: string;
    properties: any;
    mainColorList: string[];
}

export const THEME_STANDARD: ITheme = {
    id: "Standard",
    name: "standard",
    properties: {
        "--white": "#fff",
        "--black": "#000",
        "--red-1": "#d50000",
        "--yellow-1": "#ffd600",
        "--yellow-2": "#f3cd61",
        "--green-1": "#7df9c3",
        "--green-2": "#00cc8b",
        "--green-3": "#009668",
        "--blue-1": "#64abf0",
        "--blue-2": "#0089ff",
        "--blue-3": "#337ab7",
        "--gray-0": "#ececec",
        "--gray-1": "#f5f5f5",
        "--gray-2": "#e5e5e5",
        "--gray-3": "#d5d5d5",
        "--gray-4": "#c5c5c5",
        "--gray-5": "#959595",
        "--gray-6": "#505154",
        "--gray-7": "#333333",
        "--gray-8": "#050505",
        "--menu-fg-color": "#00cc8b"
    },
    mainColorList: [
        "#7df9c3",
        "#00cc8b",
        "#009668",
        "#64abf0",
        "#0089ff",
        "#337ab7"
    ]
};

export const THEME_HIGH_CONTRAST: ITheme = {
    id: "HighContrast",
    name: "high",
    properties: {
        "--white": "#fff",
        "--black": "#000",
        "--red-1": "#d50000",
        "--yellow-1": "#ffd600",
        "--yellow-2": "#f3cd61",
        "--green-1": "#066039",
        "--green-2": "#065130",
        "--green-3": "#064227",
        "--blue-1": "#0f5499",
        "--blue-2": "#0d4a87",
        "--blue-3": "#0a3d70",
        "--gray-0": "#ececec",
        "--gray-1": "#f5f5f5",
        "--gray-2": "#e5e5e5",
        "--gray-3": "#d5d5d5",
        "--gray-4": "#c5c5c5",
        "--gray-5": "#505154",
        "--gray-6": "#505154",
        "--gray-7": "#333333",
        "--gray-8": "#050505",
        "--menu-fg-color": "#fff"
    },
    mainColorList: [
        "#066039",
        "#065130",
        "#064227",
        "#0f5499",
        "#0d4a87",
        "#0a3d70"
    ]
};