<div>
  <div class="mb-2">Following are the change of alerts applied to the device
    <span *ngIf="!_hasChange" class="highlight">
      (nothing changes)
    </span>
  </div>
  <div *ngIf="_hasChange">
    <div *ngIf="_newAlertList.length > 0">
      <strong>New alert(s)</strong>
      <span class="ml-1">({{ _newAlertList.length }})</span>
      <ul>
        <li *ngFor="let r of _newAlertList">
          {{ r.name }}
        </li>
      </ul>
    </div>
    <div *ngIf="_removeAlertList.length > 0">
      <strong>Removed alert(s)</strong>
      <span class="ml-1">({{ _removeAlertList.length }})</span>
      <ul>
        <li *ngFor="let r of _removeAlertList">
          {{ r.name }}
        </li>
      </ul>
    </div>
    <div *ngIf="_defaultAlertList.length > 0">
      <strong>Unchanged alert(s)</strong>
      <span class="ml-1">({{ _defaultAlertList.length }})</span>
      <ul>
        <li *ngFor="let r of _defaultAlertList">
          {{ r.name }}
        </li>
      </ul>
    </div>
  </div>
</div>