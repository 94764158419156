import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IPluginInstance } from './api.plugin.data';

export class APICreatePluginInstanceManager extends APIBaseManager<void, void, { pluginID: string }, IPluginInstance> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_CreatePluginInstance';
        this._apiMethod = 'POST';
        this._allowPayloadPrint = false;
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'pluginInstances';
    }
}