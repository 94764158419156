import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export class IGetEnterpriseDetailInfoRxData {
    enterpriseID: string;
    enterpriseName: string;
    enterpriseDomainList: string[];
    terms: {
        roles: {
            limitations: {
                roleList: string[];
                limit: number;
            }[]
        }
    }
}

export class APIGetEnterpriseDetailInfoManager extends APIBaseManager<void, void, void, IGetEnterpriseDetailInfoRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetEnterpriseDetailInfo';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/detail';
    }
}