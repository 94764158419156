<div class='modal-dialog config-dlg'>
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" [disabled]="_updating" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body'>
            <div>
                <div>Create a group under group <<strong class="red-1">{{ group?.name }}</strong>>.</div>
                <div class="text-right">
                    <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_updating"
                        (click)="addSlot()">
                        <i class="fas fa-folder-plus"></i>
                        <span class="ml-2">Add</span>
                    </button>
                </div>
                <div class="group-list-block">
                    <div *ngFor="let g of _gList; let recordIndex = index" class="form-group">
                        <label [for]="'g-name-' + recordIndex" class="config-name">
                            #{{ recordIndex + 1 }}. Group name
                            <i *ngIf="_gList.length > 1" class="fas fa-trash red-1 ml-2" (click)="removeSlot(g)"></i>
                        </label>
                        <input [id]="'g-name-' + recordIndex" type="text" class="form-control form-control-sm"
                            [(ngModel)]="g.name">
                    </div>
                </div>
                <div *ngIf="_errorMessage" class="mt-2 warning-block">{{ _errorMessage }}</div>
            </div>
            <div *ngIf="_updating" class="progress mt-2">
                <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                    Updating the changes... Please wait...
                </div>
            </div>
            <div class='row control-block d-flex justify-content-center'>
                <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_updating"
                    data-dismiss="modal">
                    <span class="fas fa-times-circle icon-left"></span>
                    {{ 'key-cancel' | translate | capitalFirstWord }}
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_updating || _gList.length === 0" (click)="createGroup()">
                    <span class="fas fa-check-circle icon-left"></span>
                    {{ 'key-apply' | translate | capitalFirstWord }}
                </button>
            </div>
        </div>
    </div>
</div>