import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { IAlertNotificationInfo } from '../api.alert.common';

export interface IAPIListAlertNotificationQueryParameter {
    isClosed?: boolean;
    skip?: number;
    limit?: number;
}

export interface IAPIListAlertNotificationRxData {
    itemList: IAlertNotificationInfo[];
    limit: number;
    skip: number;
    total: number;
}

export class APIListAlertNotificationManager extends APIBaseManager<void, IAPIListAlertNotificationQueryParameter, void, IAPIListAlertNotificationRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListAlertNotification';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: IAPIListAlertNotificationQueryParameter): string {
        return super.getRequestURL() + 'accounts/alertNotifications' + this.transformObjectQueryParameterToStr(queryParameters);
    }
}