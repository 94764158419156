import { Component, OnInit } from '@angular/core';
import * as _semver from 'semver';
import { DevFuncConfirmInterface } from '../../base/confirm/dev-func-confirm.interface';
import { APKService, APKUpdateInfo } from '../apk.service';
import { DeviceInfo } from '../../../device/data/device-info';
import { of as observableOf, Observable, forkJoin } from 'rxjs';
import { concatMap, delay, map } from 'rxjs/operators';

@Component({
    templateUrl: './apk-confirm-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './apk-confirm-subform.component.css']
})
export class APKConfirmSubFormComponent implements DevFuncConfirmInterface, OnInit {
    _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        this._categoryList = Object.keys(this._actionData.updateInfo);
        this._updateInfoMap = this._actionData.updateInfo;
        this._categoryList.forEach((category: string) => {
            this._bLoadingMap[category] = true;
            let toMerge: boolean = true;
            this._actionData.updateInfo[category].devices.forEach(d => {
                this._rowMergeMap[d.origin.virtualId] = toMerge;
                toMerge = false;
            });
        });
    }

    allowSubmitCallback: (isAllow: boolean) => void;

    _loading: boolean = false;
    _categoryList: string[] = [];
    private _bLoadingMap: { [category: string]: boolean } = {};
    private _rowMergeMap: { [virtualDeviceID: string]: boolean } = {};
    private _updateInfoMap: { [category: string]: { info: APKUpdateInfo, devices: { virtualDeviceName: string, currentAPKVersion: string, origin: DeviceInfo, needUpdate?: boolean }[] } } = {};

    constructor(private apkSvc: APKService) { }

    ngOnInit(): void {
        this._loading = true;
        observableOf(true).pipe(
            concatMap(() => {
                const obs: Observable<{ isFault: boolean, info?: APKUpdateInfo, errorMessage?: string }>[] = [];

                this._categoryList.forEach((apkCategory: string) => {
                    obs.push(this.apkSvc.getLatestAPKUpdateInfo(apkCategory).pipe(
                        map((res: { isFault: boolean, info?: APKUpdateInfo, errorMessage?: string }) => {
                            this._bLoadingMap[apkCategory] = false;
                            this._updateInfoMap[apkCategory].info = res.info;
                            if (res.info) {
                                this._updateInfoMap[apkCategory].devices.forEach(d => {
                                    d.needUpdate = this.allowUpdate(d.currentAPKVersion, res.info.version);
                                });
                            }

                            return res;
                        })
                    ));
                });

                //add a default delay to force Angular update
                obs.push(observableOf({ isFault: false }).pipe(delay(500)));

                return forkJoin(obs);
            })
        ).subscribe(() => {
            this._loading = false;

            let allowSubmit: boolean = false;
            for (const category of Object.keys(this._actionData.updateInfo)) {
                for (const d of this._actionData.updateInfo[category].devices) {
                    if (d.needUpdate) {
                        allowSubmit = true;
                        break;
                    }
                }
            }

            this.allowSubmitCallback(allowSubmit);
        });
    }

    private allowUpdate(currentVersion: string, targetVersion: string): boolean {
        if (!currentVersion || !targetVersion) {
            return false;
        }

        return _semver.lt(currentVersion, targetVersion, true);
    }
}