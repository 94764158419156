import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AlertModule } from './alert/alert.module';
import { CustomPipeModule } from '../../lib/pipes/custom-pipe.module';
import { LanToolComponent } from './lanTool/lanTool.component';
import { PolicyModule } from './policy/policy.module';
import { AccessKeyModule } from './accessKey/accessKey.module';
import { ReportModule } from './report/report.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,

        AlertModule,
        PolicyModule,
        ReportModule,
        AccessKeyModule,
        CustomPipeModule
    ],
    declarations: [
        LanToolComponent
    ]
})
export class SettingModule { }