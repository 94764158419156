<div>
    <div class="row-action">
        <div style="flex:0.7">
            <div class="input-group searchbar">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="input-search-user">
                        <i class="fas fa-search"></i>
                    </span>
                </div>
                <input #search class="form-control form-control-sm" type='text' name='search' [disabled]="_loading"
                    placeholder='Search by user name/email' aria-label="Search by user name"
                    aria-describedby="input-search-user">
            </div>
        </div>
        <div class="divider"></div>
        <div>
            <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_loading"
                (click)="refreshUsers()">
                <i class="fas fa-sync-alt"></i>
                <span class="txt d-md-inline-block d-none">Refresh</span>
            </button>
        </div>
        <div *ngIf="_supportCol" class="ml-2">
            <button id="dropdownUserTableColumnLink" type="button" class="btn btn-sm btn-action btn-light"
                [disabled]="_loading" data-toggle="dropdown">
                <i class="fas fa-columns"></i>
                <span class="txt d-md-inline-block d-none">Columns</span>
            </button>
            <ul class="dropdown-menu option-list" data-backdrop="static" data-keyboard="false"
                aria-labelledby="dropdownUserTableColumnLink">
                <ng-template ngFor let-colOption [ngForOf]="_tableHeaderList">
                    <li class="dropdown-item" (click)="$event.stopPropagation()">
                        <input type="checkbox" [id]="'id-user-col-' + colOption.key" class="cmn-checkbox"
                            [checked]="colOption.show" (change)="showColumn(colOption, $event.target.checked)">
                        <label [for]="'id-user-col-' + colOption.key" (click)="$event.stopPropagation()">
                            <svg>
                                <use xlink:href="#checkmark" />
                            </svg>
                            <span class="sr-only">Show column {{ colOption.name | translate }} on table</span>
                        </label>
                        <span class="ml-2">{{ colOption.name | translate | capitalFirstWord }}</span>
                    </li>
                </ng-template>
            </ul>
        </div>
        <div *ngIf="_supportFilter" class="ml-2">
            <button id="dropdownUserTableFilterLink" type="button" class="btn btn-sm btn-action btn-light btn-nb"
                [disabled]="_loading" data-toggle="dropdown">
                <i class="fas fa-filter"></i>
                <span class="txt d-md-inline-block d-none">Add filters</span>
            </button>
            <ul class="dropdown-menu option-list" data-backdrop="static" data-keyboard="false"
                aria-labelledby="dropdownUserTableFilterLink">
                <li *ngFor="let filter of _advFilterList" class="dropdown-item" (click)="$event.stopPropagation()">
                    <input type="checkbox" [id]="'id-user-filter-' + filter.key" class="cmn-checkbox"
                        [checked]="filter.enabled" (change)="enableFilter(filter, $event.target.checked)">
                    <label [for]="'id-user-filter-' + filter.key" (click)="$event.stopPropagation()">
                        <svg>
                            <use xlink:href="#checkmark" />
                        </svg>
                        <span class="sr-only">Enable filter {{ filter.name | translate }} on table</span>
                    </label>
                    <span class="ml-2">{{ filter.name | translate | capitalFirstWord }}</span>
                </li>
            </ul>
        </div>
        <div class="dropdown-wrapper btn-group ml-auto">
            <button id="dropdownUserPageAmountLink" type="button"
                class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" [disabled]="_loading">
                {{ _numInPage }}
            </button>
            <ul class="dropdown-menu option-list" aria-labelledby="dropdownUserPageAmountLink">
                <li *ngFor="let numInPage of NUMBER_IN_PAGE_OPTIONS" class="dropdown-item"
                    [class.selected]="numInPage === _numInPage" (click)="changeNumberInPage(numInPage)">
                    {{ numInPage }}
                </li>
            </ul>
        </div>
    </div>
    <div class="row-filter">
        <ng-template ngFor let-filter [ngForOf]="_advFilterList">
            <div *ngIf="filter.enabled" class="filter mt-2">
                <button [id]="'dropdownUserTableFilter-' + filter.key" type="button"
                    class="btn btn-sm btn-light btn-action btn-filter" data-toggle="dropdown">
                    {{ filter.name }} :
                    <strong [ngSwitch]="filter.key">
                        <ng-template [ngSwitchCase]="userSvc.COL_KEY_STATUS">
                            {{ filter.data?.name }}
                        </ng-template>
                    </strong>
                    <span class="ml-2 fas fa-times" (click)="enableFilter(filter, false)"></span>
                </button>
                <div class="dropdown-menu option-list" data-backdrop="static" data-keyboard="false"
                    [attr.aria-labelledby]="'dropdownUserTableFilter-' + filter.key">
                    <strong>{{ filter.name }}</strong>
                    <div [ngSwitch]="filter.key" class="mt-2" (click)="$event.stopPropagation()">
                        <ng-template [ngSwitchCase]="userSvc.COL_KEY_STATUS">
                            <div *ngFor="let inviteStatus of _invitationStatusFilterList" class="cmn-radio">
                                <input type="radio" [id]="'id-user-invite-' + inviteStatus.key" class="cmn-checkbox"
                                    [checked]="filter.data?.key === inviteStatus.key"
                                    (click)="changeSelectionFilterData(filter, inviteStatus);">
                                <label [for]="'id-user-invite-' + inviteStatus.key" class="radio"></label>
                                <span class="ml-2">{{ inviteStatus.name | translate | capitalFirstWord }}</span>
                            </div>
                        </ng-template>
                        <ng-template [ngSwitchCase]="userSvc.COL_KEY_CREATE">
                            <div *ngFor="let createDate of _createDateFilterList" class="cmn-radio"
                                (click)="$event.stopPropagation()">
                                <input type="radio" [id]="'id-user-createDate-' + createDate.key" class="cmn-checkbox"
                                    [checked]="filter.data?.key === createDate.key"
                                    (click)="changeSelectionFilterData(filter, createDate); $event.stopPropagation()">
                                <label [for]="'id-user-createDate-' + createDate.key" class="radio"></label>
                                <span class="ml-2">{{ createDate.name | translate | capitalFirstWord }}</span>
                            </div>
                            <!--
                            <input type="date" [value]="filter.data" [attr.aria-label]="'Filter: ' + filter.name"
                                (change)="changeTextFilterData(filter, $event.target.value)">
                            -->
                        </ng-template>
                        <div *ngSwitchDefault>
                            <div class="input-group searchbar">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="input-search-user">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                                <input class="form-control form-control-sm" type='text' name='search'
                                    [disabled]="_loading" [attr.placeholder]="'Filter by ' + filter.name"
                                    [attr.aria-label]="'Filter by ' + filter.name"
                                    (change)="changeTextFilterData(filter, $event.target.value)">
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-3">
                        <button type="button" class="btn btn-sm btn-action btn-light btn-round"
                            (click)="applyFilter(filter)">
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
    <div class="list-group mt-2">
        <div class="list-group-item list-group-header">
            <div class="row align-items-center">
                <div class="col title">
                    <input type="checkbox" id="cb-th-user" class="cmn-checkbox cmn-checkbox-white"
                        (change)="selectAllUsers($event.target.checked)">
                    <label for="cb-th-user" class="mr-2">
                        <svg>
                            <use xlink:href="#checkmark" />
                        </svg>
                        <span class="sr-only">Select all users</span>
                    </label>
                    <span>{{ 'User email' | translate | capitalFirstWord }}</span>
                    <span class="sorter">
                        <i class="fas fa-caret-up sort-icon descend"
                            [ngClass]="_currentSortKey === userSvc.COL_KEY_EMAIL && _currentSortType === _enumSortType.descend ? 'active' : ''"
                            (click)="sortDescend(userSvc.COL_KEY_EMAIL)">
                        </i>
                        <i class="fas fa-caret-down sort-icon ascend"
                            [ngClass]="_currentSortKey === userSvc.COL_KEY_EMAIL && _currentSortType === _enumSortType.ascend ? 'active' : ''"
                            (click)="sortAscend(userSvc.COL_KEY_EMAIL)">
                        </i>
                    </span>
                </div>
                <ng-template ngFor let-col [ngForOf]="_tableHeaderList">
                    <div *ngIf="_tableColMap[col.key].show" class="col title">
                        {{ col.name }}
                        <span *ngIf="col.sortSupport" class="sorter">
                            <i class="fas fa-caret-up sort-icon descend"
                                [ngClass]="_currentSortKey === col.key && _currentSortType === _enumSortType.descend ? 'active' : ''"
                                (click)="sortDescend(col.key)">
                            </i>
                            <i class="fas fa-caret-down sort-icon ascend"
                                [ngClass]="_currentSortKey === col.key && _currentSortType === _enumSortType.ascend ? 'active' : ''"
                                (click)="sortAscend(col.key)">
                            </i>
                        </span>
                    </div>
                </ng-template>
            </div>
        </div>
        <div *ngIf="!_loading; else templateLoadingUserTable">
            <div class="list-group-content list-group-hoverless" [style.max-height]="_limitH">
                <div *ngIf="_displayUserList.length === 0; else templateUserList" class="text-center my-4">
                    Drag and drop users to this group.
                </div>
                <ng-template #templateUserList>
                    <div *ngFor="let user of _displayUserList" class="list-group-item">
                        <div class="row align-items-center" draggable="true" (dragstart)="onDragStart($event)">
                            <div class="col text-break d-flex align-items-center">
                                <input type="checkbox" [id]="'cb-th-' + user.id" class="cmn-checkbox"
                                    [checked]="_userSelectMap[user.id]"
                                    (change)="selectUser(user, $event.target.checked)">
                                <label [for]="'cb-th-' + user.id" class="mr-4">
                                    <svg>
                                        <use xlink:href="#checkmark" />
                                    </svg>
                                    <span class="sr-only">Select User {{ user.email }}</span>
                                </label>
                                <a [routerLink]="['/app/admin/users/all', user.id]">
                                    {{ user.email | translate }}
                                </a>
                            </div>
                            <div *ngIf="_tableColMap[userSvc.COL_KEY_POLICY].show" class="col text-break">
                                {{ user.userRole | translate | capitalFirstWord }}
                            </div>
                            <div *ngIf="_tableColMap[userSvc.COL_KEY_STATUS].show" class="col text-break">
                                <span [ngSwitch]="user.invitationStatus">
                                    <!-- user sign up and wait for admin to send invitation mail -->
                                    <ng-template [ngSwitchCase]="_enumUserInviteStatus.requested">
                                        <span class="red-1">Pending invite</span>
                                        <i class="fas fa-envelope ml-1 icon-invite" data-toggle="tooltip"
                                            title="Send invitation" (click)="reInvite(user)"></i>
                                    </ng-template>
                                    <!-- admin send invitation mail and wait for user to accept -->
                                    <ng-template [ngSwitchCase]="_enumUserInviteStatus.invited">
                                        Pending acceptance
                                        <i class="fas fa-paper-plane ml-1 icon-invite" data-toggle="tooltip"
                                            title="Re-invite user" (click)="reInvite(user)"></i>
                                    </ng-template>
                                    <span *ngSwitchDefault>Accepted</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="d-flex">
                <div class="ml-auto">
                    <na-pager [currentPage]="_currentPage" [numberInPage]="_numInPage" [total]="_userList.length"
                        (onPageChanged)="onPageChange($event)">
                    </na-pager>
                </div>
            </div>
        </div>
        <ng-template #templateLoadingUserTable>
            <div class="loading">
                <p>Please wait...</p>
                <div class="loading-box"></div>
            </div>
        </ng-template>
    </div>
</div>