import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IAssignLicenseTxData {
    assignList: {
        licenseKeyToken: string;
        assignee: {
            virtualDeviceID: string;
            accountID?: string;
            virtualDevicePairedID?: string;
            playerID?: string;
        }
    }[];
}

export class APIAssignLicenseManager extends APIBaseManager<void, void, IAssignLicenseTxData, void> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_AssignLicense';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'licenseKeys';
    }
}