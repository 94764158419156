<div class='modal-dialog config-dlg' role="document">
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ _title }}</span>
            <button #dlgClose type="button" class="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div [class.d-none]="_loading">
                <div [ngSwitch]="_page">
                    <ng-template [ngSwitchCase]="_enumPage.action">
                        <div class="row">
                            <div class="col-12">
                                <!-- policy name -->
                                <div class="form-group row">
                                    <label for="col-policy-name"
                                        class="col-5 col-md-4 col-xl-3 col-form-label col-form-label-sm">
                                        Policy name
                                    </label>
                                    <div class="col-7 col-md-8 col-xl-9">
                                        <input id="col-policy-name" type="text" class="form-control form-control-sm" [class.error]="!_policy.name" [disabled]="_policy.isDefault || _policy.isManaged"
                                            [(ngModel)]="_policy.name">
                                    </div>
                                </div>
                                <!-- policy type -->
                                <div class="form-group row">
                                    <label for="col-policy-type"
                                        class="col-5 col-md-4 col-xl-3 col-form-label col-form-label-sm">
                                        Policy type
                                    </label>
                                    <div id="col-policy-type" class="col-7 col-md-8 col-xl-9">
                                        <div class="btn-group w-100">
                                            <button id="dropdownPolicyTypeLink" type="button"
                                                class="btn btn-sm btn-action btn-light dropdown-toggle"
                                                [disabled]="!_bNewPolicy" data-toggle="dropdown">
                                                {{ _policy.type }}
                                                <span class="caret"></span>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-left option-list w-100"
                                                aria-labelledby="dropdownPolicyTypeLink">
                                                <li *ngFor="let policyType of _policyTypeList"
                                                    [class.selected]="_policy.type == policyType.type"
                                                    (click)="selectPolicyType(policyType.type)">
                                                    {{ policyType.displayName }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <ng-template policy-type></ng-template>
                            </div>
                            <div class="col-12 mt-2">
                                <div>
                                    <na-dev-group-tree-wrapper [group]="_devGroupRoot" [mode]="_groupMode" [showTitle]="true"
                                        [title]="'Apply to'" [styleBorder]="true" [enableUnfoldFunc]="true"
                                        [pickMap]="_groupPickMap" [showDevice]="false">
                                    </na-dev-group-tree-wrapper>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_errorMessage" class="warning-block mt-2">{{ _errorMessage }}</div>
                    </ng-template>
                    <ng-template [ngSwitchCase]="_enumPage.submit">
                        <div class="progress">
                            <div class="progress-bar progress-bar-success progress-bar-striped active w-100"
                                role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                Updating the changes... Please wait...
                            </div>
                        </div>
                    </ng-template>
                    <ng-template [ngSwitchCase]="_enumPage.result">
                        <div *ngIf="!_errorMessage; else templateErrorOnResultPage">
                            <p>Policy has been applied successfully.</p>
                            <div class="p-2 bg-gray-2">
                                <div class="red-1">
                                    Please note features below may not be supported on device with different android versions.
                                </div>
                                <div *ngFor="let unsupportItem of UNSUPPORT_FEATURES">
                                    <strong>
                                        For Android {{ unsupportItem.androidVersion }}
                                    </strong>
                                    <ul class="font-italic">
                                        <li *ngFor="let policy of unsupportItem.policyList">
                                            {{ policy.type }}
                                            <ul *ngIf="policy.features">
                                                <li *ngFor="let feature of policy.features">
                                                    {{ feature | translate | capitalFirstWord }}
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>   
                        </div>
                        <ng-template #templateErrorOnResultPage>
                            <p>Failed to apply the policy.</p>
                            <p class="warning-block">{{ _errorMessage }}</p>
                        </ng-template>
                    </ng-template>
                </div>
            </div>
            <div *ngIf="_loading" class="loading">
                <p>Please wait...</p>
                <div class="loading-box"></div>
            </div>
            <div class="row control-block d-flex justify-content-center">
                <button *ngIf="_page === _enumPage.action || _page === _enumPage.result" type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_loading" data-dismiss="modal">
                    <span class="fas fa-times-circle icon-left"></span>
                    <span *ngIf="_page === _enumPage.action">{{ 'key-cancel' | translate | capitalFirstWord }}</span>
                    <span *ngIf="_page === _enumPage.result">{{ 'key-close' | translate | capitalFirstWord }}</span>
                </button>
                <button *ngIf="_page === _enumPage.action" type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_loading || !_policy || !_policy.name || !_policy.type || !_isPolicyDataValid" (click)="submit()">
                    <span class="fas fa-check-circle icon-left"></span>
                    {{ 'key-apply' | translate | capitalFirstWord }}
                </button>
            </div>
        </div>
    </div>
</div>