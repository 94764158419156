import { Injectable } from "@angular/core";
import { UserPreferenceService } from "../content/user-preference.service";
import { AccountService } from "../entry/account.service";
import { ITheme, THEME_STANDARD, THEME_HIGH_CONTRAST } from "./theme";

@Injectable()
export class ThemeService {
    _themeList: ITheme[] = [THEME_STANDARD, THEME_HIGH_CONTRAST];
    _activeTheme: ITheme;

    constructor(private accountSvc: AccountService, private userPrefSvc: UserPreferenceService) {
        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            if (isLogin) {
                this.setActiveThemeByName(this.userPrefSvc.userPreference.global.theme);
            }
        });

        this.setActiveThemeByName(this.userPrefSvc.userPreference.global.theme);
    }

    getThemeList(): ITheme[] {
        return this._themeList;
    }

    getCurrentTheme(): ITheme {
        return this._activeTheme;
    }

    private setActiveThemeByName(themeName: string): ITheme {
        switch (themeName) {
            case 'standard':
                {
                    return this.setActiveTheme(THEME_STANDARD);
                }
            case 'high':
                {
                    return this.setActiveTheme(THEME_HIGH_CONTRAST);
                }
        }
    }

    setActiveTheme(theme: ITheme): ITheme {
        if (this._activeTheme && theme.name === this._activeTheme.name) {
            return this._activeTheme;
        }

        this._activeTheme = theme;
        //update theme css
        Object.keys(this._activeTheme.properties).forEach(p => {
            document.documentElement.style.setProperty(
                p,
                this._activeTheme.properties[p]
            );
        });

        this.userPrefSvc.changeGlobalTheme(theme.name);

        return this._activeTheme;
    }
}