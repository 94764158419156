import { Component, Input } from '@angular/core';
import { MaintenancePlaybackInfo } from './mtPlayback.data';

@Component({
    selector: 'na-mtPlayback-preview',
    templateUrl: './mtPlayback-preview.component.html'
})
export class MaintenancePlaybackPreviewComponent {
    _info: MaintenancePlaybackInfo;
    @Input('data')
    set data(raw: MaintenancePlaybackInfo) {
        if (raw) {
            this._info = raw;
        }
    }

    getSchedule(): string {
        return this._info ? this._info.beginDate + ' ' + this._info.beginTime + ' ~ ' + this._info.endDate + ' ' + this._info.endTime : '';
    }
}