<div class='modal-dialog config-dlg' role="document">
  <div class='modal-content'>
    <div class='modal-header'>
      <span class="modal-title">{{ title }}</span>
      <button #dlgClose type="button" class="close" data-dismiss="modal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="_page === _enumPage.action" class="row">
        <div class="col-12">
          <div class="form-group row">
            <label for="alert-name" class="col-md-2 col-4">Alert name</label>
            <div class="col-md-10 col-8">
              <input id="alert-name" type="text" class="form-control form-control-sm" [(ngModel)]="_alertName">
            </div>
          </div>
          <div class="form-group row">
            <label for="alert-type" class="col-md-2 col-4">Alert type</label>
            <div class="col-md-10 col-8">
              <div class="btn-group w-100">
                <button id="dropdownAlertTypeLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
                  data-toggle="dropdown" [disabled]="_alert?.id">
                  <span *ngIf="_alert?.eventType.type !== _enumAlertType.Unknown; else templateAlertUnknown">
                    {{ _alert?.eventType.displayName }}
                  </span>
                  <ng-template #templateAlertUnknown>
                    Please select an alert type
                  </ng-template>
                  <span class="sr-only">Select alert event type</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-left option-list w-100" aria-labelledby="dropdownAlertTypeLink">
                  <li *ngFor="let eventType of _alertEventTypeList"
                    [class.selected]="_alert?.eventType.type === eventType.type"
                    (click)="changeAlertEventType(eventType)">
                    <div>
                      {{ eventType.displayName | capitalFirstWord }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="form-group mt-1">
            <!-- dynamically change based on different alert event type -->
            <ng-template alert-data-ctrl></ng-template>
          </div>
        </div>
      </div>
      <div *ngIf="_page === _enumPage.submit">
        <div class="progress">
          <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
            Updating the changes... Please wait...
          </div>
        </div>
      </div>
      <div *ngIf="_page === _enumPage.result">
        <p *ngIf="!_errorMessage">New setting has been applied successfully.</p>
        <div *ngIf="_errorMessage">
          <p>New setting is failed</p>
          <p class="warning">{{ _errorMessage }}</p>
        </div>
      </div>
      <p *ngIf="_errorMessage && _page !== _enumPage.result" class="warning-block">{{ _errorMessage }}</p>
      <div class="row control-block d-flex justify-content-center">
        <button *ngIf="_page === _enumPage.action" type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal"
          (click)="cancel()">
          <span class="fas fa-times-circle icon-left"></span>
          {{ 'key-cancel' | translate | capitalFirstWord }}
        </button>
        <button *ngIf="_page === _enumPage.action" type="button" class="btn btn-sm btn-action btn-light" (click)="submit()">
          <span class="fas fa-check-circle icon-left"></span>
          {{ 'key-apply' | translate | capitalFirstWord }}
        </button>
        <button *ngIf="_page === _enumPage.result" type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal">
          <span class="fas fa-times-circle icon-left"></span>
          {{ 'key-close' | translate | capitalFirstWord }}
        </button>
      </div>
    </div>
  </div>
</div>