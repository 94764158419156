import { Component, Input, ViewChild } from "@angular/core";
import { DeviceLabelInfo } from "../dev-label.data";
import { DialogPage } from "app/lib/common/common.data";
import { of } from "rxjs";
import { catchError, concatMap } from "rxjs/operators";
import { DEVICE_LABEL_FUNC_DELETE, DeviceLabelFuncItem, IDeviceLabelFunc } from "../dlg/dev-label-func.def";
import { DeviceLabelFuncService } from "../dlg/dev-label-func.service";
import { DeviceLabelFuncDirective } from "../dlg/dev-label-func.directive";
import { DeviceService } from "../../device.service";

@Component({
    selector: 'na-dev-label-detail',
    templateUrl: './dev-label-detail.component.html',
    styleUrls: ['./dev-label-detail.component.css']
})
export class DeviceLabelDetailComponent {
    
    _isInEdit: boolean = false;
    _editDevLabelName: string;
    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;
    _errorMessage: string;

    _devLabel: DeviceLabelInfo;
    @Input('source')
    set source(v: DeviceLabelInfo) {
        this._devLabel = v;
    }

    @ViewChild(DeviceLabelFuncDirective) private _devLabelFuncHost: DeviceLabelFuncDirective;

    constructor(private devLabelFuncSvc: DeviceLabelFuncService, private devSvc: DeviceService) {}

    startEdit(): void {
        this._isInEdit = true;
        this._editDevLabelName = this._devLabel.name;
    }

    cancelEdit(): void {
        this._isInEdit = false;
        this._errorMessage = null;
    }

    saveEdit(): void {
        this._errorMessage = '';
        this._page = DialogPage.submit;

        of(true).pipe(
            concatMap(() => {
                if (!this._editDevLabelName) {
                    throw 'Label name should not be empty';
                }

                if (this._devLabel.name !== this._editDevLabelName) {
                    return this.devSvc.updateDeviceLabels({ labelName: this._editDevLabelName, virtualDeviceIDList: this._devLabel.deviceList.map(d => d.virtualId)}, { labelName: this._devLabel.name, virtualDeviceIDList: this._devLabel.deviceList.map(d => d.virtualId)});    
                }

                return of({ isFault: false });
            }),
            catchError((err: any) => of({ isFault: true, errorMessage: err }))
        ).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._page = DialogPage.result;
            this._errorMessage = res.errorMessage;

            if (!res.isFault) {
                this._isInEdit = false;
            }
        })
    }

    removeDeviceLabel(): void {
        this.createDevLabelFuncDlg<void>(DEVICE_LABEL_FUNC_DELETE, this._devLabel);
    }

    private createDevLabelFuncDlg<T>(funcName: string, label?: DeviceLabelInfo, other?: T): void {
        const item: DeviceLabelFuncItem = this.devLabelFuncSvc.getFunctionByName(funcName);
        if (item) {
            const viewContainerRef = this._devLabelFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IDeviceLabelFunc<T>>componentRef.instance).title = item.title;
            (<IDeviceLabelFunc<T>>componentRef.instance).label = label;
            (<IDeviceLabelFunc<T>>componentRef.instance).other = other;
        }
    }
}