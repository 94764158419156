import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { AccessKeyInfo } from './api.accessKey.common';

export class APICreateAccessKeyManager extends APIBaseManager<void, void, void, AccessKeyInfo> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_CreateAccessKey';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'accounts/accessKeys';
    }
}