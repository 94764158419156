import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CustomizationItemInfo } from '../../content/admin/customization/customization.data';

@Component({
    selector: 'na-customization-item-img',
    templateUrl: './customization-item-img.component.html',
    styleUrls: ['./customization-item-img.component.css']
})
export class CustomizationItemImageComponent {
    static INSTANCE_ID: number = 1;

    _id: number = CustomizationItemImageComponent.INSTANCE_ID++;

    _fileFormat: string = '.png';
    @Input('fileFormat')
    set fileFormat(v: string | string[]) {
        let formats: string[] = [];
        if (!Array.isArray(v)) {
            formats = v.split(','); 
        }
        else {
            formats = v;
        }

        this._fileFormat = formats.map(i => i.startsWith('.') ? i : '.' + i).join(',');
    }

    @Input('title') _title: string;
    @Input('note') _note: string;
    @Input('data') _data: CustomizationItemInfo;
    @Input('maxAllowSize') _maxAllowSize: number = 0.5; //MB
    @Input('disabled') _disabled: boolean = false;
    @Input('bgColor') _bgColor: string;
    @Input('iconColor') _iconColor: string;
    
    @ViewChild('imgFile', { static: true}) private _imgElementRef: ElementRef;

    @Output() onItemChanged = new EventEmitter<CustomizationItemInfo>();

    selectFile(): void {
        this._imgElementRef.nativeElement.click();
    }

    onFileClick(e: any): void {
        e.target.value = null;
    }

    onFileChanged(e: any): void {
        const f: File = e.target.files[0] as File;

        if (f) {
            if ((f.size / 1024 / 1024) > this._maxAllowSize) {
                alert(`File size should be less than ${this._maxAllowSize} MB`);
                return;
            }

            this._data.urlAfter = window.URL.createObjectURL(f);
            this._data.name = f.name;
            this._data.size = f.size;
            this._data.raw = f;

            this.onItemChanged.emit(this._data);
        }
    }

    reset(): void {
        this._data.reset();
        this.onItemChanged.emit(this._data);
    }
}