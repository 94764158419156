import { APIWrapperBase } from "app/API/api.wrapper.base";
import { APIListCertManager } from "./api.cert.list";
import { HttpClient } from "@angular/common/http";
import { APIAddCertManager } from "./api.cert.add";
import { APIDeleteCertManager } from "./api.cert.delete";


export class APICertWrapper extends APIWrapperBase {
    ListCert: APIListCertManager;
    AddCert: APIAddCertManager;
    DeleteCert: APIDeleteCertManager;

    constructor(private http: HttpClient) {
        super();
        this.ListCert = new APIListCertManager(http);
        this.AddCert = new APIAddCertManager(http);
        this.DeleteCert = new APIDeleteCertManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.ListCert,
            this.AddCert,
            this.DeleteCert
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}