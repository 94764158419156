import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { ReportConfig } from './api.report.data';

export class APIUpdateReportConfigManager extends APIBaseManager<void, void, ReportConfig, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UpdateReportConfig';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'accounts/weeklyReportSettings';
    }
}