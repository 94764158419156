import { Component } from "@angular/core";
import { VirtualDlgComponent } from "app/content/virtual/dlg/virtual-dlg.component";
import { IDeviceLabelFunc } from "./dev-label-func.def";
import { DeviceLabelInfo } from "../dev-label.data";
import { DeviceService } from "../../device.service";
import { AccountService } from "app/entry/account.service";

@Component({
    templateUrl: 'dev-label-delete-dlg.component.html',
    styleUrls: ['../dev-label.style.css']
})
export class DeviceLabelDeleteDlgComponent extends VirtualDlgComponent implements IDeviceLabelFunc<void> {
    title: string;
    label: DeviceLabelInfo;
    other?: void;

    _updating: boolean;
    _errorMessage: string;

    constructor(protected accountSvc: AccountService, private devSvc: DeviceService) {
        super(accountSvc)
    }

    deleteLabel(): void {
        this._errorMessage = '';
        this._updating = true;

        this.devSvc.updateDeviceLabels(null, { labelName: this.label.name, virtualDeviceIDList: this.label.deviceList.map(d => d.virtualId) }).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._updating = false;

            if (res.isFault) {
                this._errorMessage = res.errorMessage;
                return;
            }

            setTimeout(() => this.closeDlg(), 0);
        });
    }
}