import { Component, Input, OnInit } from '@angular/core';

import { AndroidGroupType } from '../../../app/content/devfunc/firmware/firmware-data';
import { AppStartInfo } from '../../../app/content/device/data/device-info';
import { ConstantService } from '../../../app/lib/common/constant.service';
import { AppStartPreviewMode } from './appstart.data';

@Component({
    selector: 'na-appstart-preview',
    templateUrl: './appstart-preview.component.html'
})
export class AppStartPreviewComponent implements OnInit {
    _data: AppStartInfo;
    @Input()
    set data(v: AppStartInfo) {
        this._data = v;
    }

    _compare: AppStartInfo;
    @Input()
    set compare(v: AppStartInfo) {
        this._compare = v;
        this._showDiff = true;
    }

    _keyPair: { [key: string]: { origin: any, value: any } };
    @Input()
    set keyPair(v: { [key: string]: { origin: any, value: any } }) {
        this._keyPair = v;
    }

    _target: string;
    @Input()
    set target(v: string) {
        this._target = v;
    }

    _previewMode: AppStartPreviewMode = AppStartPreviewMode.none;
    _enumPreviewMode: typeof AppStartPreviewMode = AppStartPreviewMode;

    _showDiff: boolean = false;
    _showExtra: boolean = false;
    _diffExtraList: { displayName: string, value: boolean | number }[] = [];
    _appstartExtraList: {
        property: string;
        type: 'checkbox' | 'number',
        displayName: string;
        condition?: (androidVersion: AndroidGroupType) => boolean
    }[] = [];

    constructor(private constantSvc: ConstantService) {
        this._appstartExtraList = this.constantSvc.getAppstartExtraList();
    }

    ngOnInit(): void {
        if (this._data && this._compare) {
            this._previewMode = AppStartPreviewMode.diff;

            // extra settings
            if (this._data.extras && this._compare.extras) {
                this._diffExtraList = this._appstartExtraList.filter(a => this._data.extras[a.property] !== undefined && this._data.extras[a.property] !== this._compare.extras[a.property]).map(a => ({
                    displayName: a.displayName,
                    value: this._data.extras[a.property]
                }));
            }
        }
        else if (this._keyPair && this._target) {
            this._previewMode = AppStartPreviewMode.keyPair;
            // extra settings
            for (let p of this._appstartExtraList) {
                if (this._keyPair[p.property] && (this._keyPair[p.property].origin !== this._keyPair[p.property].value)) {
                    this._showExtra = true;
                    break;
                }
            }
        }
    }
}