import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TroubleshootComponent } from './troubleshoot.component';
import { TroubleshootService } from './troubleshoot.service';

import { TranslateModule } from '../../translate/translate.module';
import { CustomPipeModule } from '../../lib/pipes/custom-pipe.module';
import { TicketReportComponent } from './comp/ticket-report.component';
import { UIElementModule } from '../../uiElement/uiElement.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,

        UIElementModule,
        TranslateModule,
        CustomPipeModule
    ],
    declarations: [
        TroubleshootComponent,
        TicketReportComponent
    ],
    providers: [
        TroubleshootService
    ]
})
export class TroubleshootModule { }