import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { TRANSLATION_PROVIDERS } from './translations';
import { TranslatePipe } from './translate.pipe';
import { TranslateService } from './translate.service';

@NgModule({
    imports: [BrowserModule],
    declarations: [
        TranslatePipe
    ],
    providers: [TRANSLATION_PROVIDERS, TranslateService],
    exports: [TranslatePipe]
})
export class TranslateModule {}

