import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeAliasTransform'
})
export class TimeAliasTransformPipe implements PipeTransform {
    transform(value: string, ...args: string[]): any {
        if (!value) {
            return value;
        }

        value = value.toString();

        const time_splits: string[] = value.split(' ');
        if (time_splits.length === 2) {
            let ret: string = time_splits[0];
            switch (time_splits[1]) {
                case 'M':
                    ret += ' minute';
                    break;
                case 'H':
                    ret += ' hour';
                    break;
                case 'D':
                    ret += ' day';
                    break;
            }

            return ret;
        }
        else {
            return value;
        }
    }
}