import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CustomPipeModule } from '../../../lib/pipes/custom-pipe.module';
import { TranslateModule } from '../../../translate/translate.module';

import { UIElementModule } from '../../../uiElement/uiElement.module';
import { AccessKeyService } from './accessKey.service';
import { AccessKeyComponent } from './accessKey.component';
import { AccessKeyDlgCreateComponent } from './dlg/acckey-dlg-create.component';
import { AccessKeyDlgInactiveComponent } from './dlg/acckey-dlg-inactive.component';
import { AccessKeyDlgRemoveComponent } from './dlg/acckey-dlg-remove.component';
import { AccessKeyFuncService } from './accessKey-func.service';
import { AccessKeyFuncDirective } from './accessKey-func.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CustomPipeModule,
        TranslateModule,
        UIElementModule
    ],
    providers: [
        AccessKeyService,
        AccessKeyFuncService
    ],
    declarations: [
        AccessKeyComponent,
        AccessKeyDlgCreateComponent,
        AccessKeyDlgInactiveComponent,
        AccessKeyDlgRemoveComponent,
        AccessKeyFuncDirective
    ]
})
export class AccessKeyModule { }