<div class="ticket-detail">
  <div class="layout-desktop">
    <div class="title d-flex mb-2 pb-2 pt-1 border-bottom">
      <h4 class="mb-0">Ticket <span class="green-3">{{ _ticket?.subject }}</span></h4>
      <button id="id_ticket_do_refresh" type="button" class="btn btn-sm btn-action btn-light ml-auto"
        [disabled]="!_ticket || _ticketRefreshCountdown > 0" data-toggle="tooltip" title="Refresh selected ticket..."
        (click)="refreshTicket(_ticket.id)">
        <i [class.rotate]="_ticketRefreshCountdown > 0" class="fas fa-redo-alt"></i>
        <span class="ml-2">Refresh</span>
        <span *ngIf="_ticketRefreshCountdown > 0"> - {{ _ticketRefreshCountdown }} s</span>
      </button>
    </div>
  </div>
  <ng-template [ngIf]="!_bLoadingTicketDetail">
    <ng-template [ngIf]="_ticket">
      <div class="container-fluid mt-2">
        <div class="row">
          <div class="col-xl-6 col-lg-12 col-sm-6 col-12">
            <!-- title -->
            <div class="form-group row">
              <label for="col-ticket-title" class="col-xl-4 col-5 col-form-label col-form-label-sm">
                Title
              </label>
              <div class="col-xl-8 col-7">
                <div id="col-ticket-title" class="form-control-plaintext">
                  {{ _ticket?.subject }}
                </div>
              </div>
            </div>
            <!-- status -->
            <div class="form-group row" data-target="tooltip" title="{{ _ticket?.id }}">
              <label for="col-ticket-status" class="col-xl-4 col-5 col-form-label col-form-label-sm">
                Status
              </label>
              <div class="col-xl-8 col-7">
                <span [ngSwitch]="_ticket?.status">
                  <span *ngSwitchCase="'pending'" class="ticket-status status-pending">Pending</span>
                  <span *ngSwitchCase="'reported'" class="ticket-status status-finish">Reported</span>
                  <span *ngSwitchCase="'fail'" class="ticket-status status-fail">Fail</span>
                  <span *ngSwitchCase="'progress'" class="ticket-status status-progress">Progress</span>
                </span>
              </div>
            </div>
            <!-- symptom -->
            <div class="form-group row">
              <label for="col-ticket-symptom" class="col-xl-4 col-5 col-form-label col-form-label-sm">
                Symptom
              </label>
              <div class="col-xl-8 col-7">
                <div id="col-ticket-symptom" class="form-control-plaintext">
                  {{ _ticket?.symptom }}
                </div>
              </div>
            </div>
            <!-- frequency -->
            <div class="form-group row">
              <label for="col-ticket-frequency" class="col-xl-4 col-5 col-form-label col-form-label-sm">
                Frequency
              </label>
              <div class="col-xl-8 col-7">
                <div id="col-ticket-frequency" class="form-control-plaintext">
                  {{ _ticket?.frequency }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-sm-6 col-12">
            <!-- issue time -->
            <div class="form-group row">
              <label for="col-ticket-issue" class="col-xl-4 col-5 col-form-label col-form-label-sm">
                Issue date
              </label>
              <div class="col-xl-8 col-7">
                <div id="col-ticket-issue" class="form-control-plaintext">
                  {{ _ticket?.issueDateTime | date:'yyyy-MM-dd HH:mm:ss Z' }}
                </div>
              </div>
            </div>
            <!-- start time -->
            <div class="form-group row">
              <label for="col-ticket-start" class="col-xl-4 col-5 col-form-label col-form-label-sm">
                Start date
              </label>
              <div class="col-xl-8 col-7">
                <div id="col-ticket-start" class="form-control-plaintext">
                  {{ _ticket?.startedDate | date:'yyyy-MM-dd HH:mm:ss Z' }}
                </div>
              </div>
            </div>
            <!-- end time -->
            <div class="form-group row">
              <label for="col-ticket-end" class="col-xl-4 col-5 col-form-label col-form-label-sm">
                Finish date
              </label>
              <div class="col-xl-8 col-7">
                <div id="col-ticket-end" class="form-control-plaintext">
                  {{ _ticket?.finishedDate | date:'yyyy-MM-dd HH:mm:ss Z' }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- content -->
        <div class="form-group row">
          <label for="col-ticket-frequency" class="col-12 col-form-label col-form-label-sm">
            Content
          </label>
          <div class="col-12">
            <div class="ticket-desc px-2 my-1">{{ _ticket?.content }}</div>
          </div>
        </div>
        <!-- devices -->
        <div class="form-group row">
          <label for="col-ticket-frequency" class="col-12 col-form-label col-form-label-sm">
            Track devices
          </label>
          <div class="col-12">
            <div class="list-group">
              <div class="list-group-item list-group-header black">
                <div class="row">
                  <span class="col title">Device name / ID</span>
                  <span class="col title">Status</span>
                </div>
              </div>
              <div class="list-group-content list-group-hoverless list-group-content-nr-3">
                <div *ngFor="let target of _ticket.targets" class="list-group-item">
                  <div class="row align-items-top">
                    <span class="col-6 text-break" data-placement="bottom">
                      <a *ngIf="target.detailURL"
                        [routerLink]="target.detailURL">{{ target.name || target.virtualDeviceID }}
                      </a>
                      <span *ngIf="!target.detailURL">{{ target.name || target.virtualDeviceID }}</span>
                    </span>
                    <span class="col-6">
                      <span [ngSwitch]="target.status">
                        <span *ngSwitchCase="_enumTaskStatus.pending" class="ticket-status status-pending">
                          {{ _enumTaskStatus[target.status] | capitalFirstWord }}
                        </span>
                        <span *ngSwitchCase="_enumTaskStatus.progress" class="ticket-status status-progress">
                          {{ _enumTaskStatus[target.status] | capitalFirstWord }}
                        </span>
                        <span *ngSwitchCase="_enumTaskStatus.success" class="ticket-status status-success">
                          {{ _enumTaskStatus[target.status] | capitalFirstWord }}
                        </span>
                        <span *ngSwitchCase="_enumTaskStatus.fail">
                          <span
                            class="ticket-status status-fail">{{ _enumTaskStatus[target.status] | capitalFirstWord }}</span>
                          <i class="fas fa-question-circle ml-2 text-danger" data-toggle="collapse"
                            [attr.data-target]="'#ticket-error-' + target.taskId" aria-expanded="false"></i>
                          <div [id]="'ticket-error-' + target.taskId" class="collapse">
                            <div class="card card-body text-danger mt-2 ticket-error-block">
                              {{ target.errorMessage }}
                            </div>
                          </div>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <p *ngIf="!_ticket" class="text-center my-4">
      Choose a ticket to inspect its detail information.
    </p>
  </ng-template>
  <ng-template [ngIf]="_bLoadingTicketDetail">
    <div class="loading">
      <p>Please wait...</p>
      <div class="loading-box"></div>
    </div>
  </ng-template>
</div>