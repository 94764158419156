import { Component, ElementRef, ViewChild } from '@angular/core';

import { NAService } from '../../../../app/API/na.service';
import { AccountService } from '../../../../app/entry/account.service';
import { AppConfigService } from '../../../app.config';

@Component({
    templateUrl: './lanTool.component.html',
    styleUrls: ['./lanTool.component.css']
})
export class LanToolComponent {
    _winDownloadUrl: string = AppConfigService.configs.settingPage.lanTool.winUri;
    _macDownloadUrl: string = AppConfigService.configs.settingPage.lanTool.macUri;
    _webUrl: string = AppConfigService.configs.settingPage.lanTool.webUri;

    _token: string;
    _server: string;

    private lanToolWebRef: ElementRef;
    @ViewChild("lanToolWebForm")
    set signinForm(holder: ElementRef) {
        this.lanToolWebRef = holder;
    }

    constructor(
        private naSvc: NAService,
        private accountSvc: AccountService) {
    }

    openLanToolWebLink(): void {
        this._token = this.accountSvc.token;
        this._server = this.naSvc.Account.ValidateAccountToken.getURL();

        setTimeout(() => {
            const form = this.lanToolWebRef.nativeElement;
            form.submit();
        }, 0);
    }
}