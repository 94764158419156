<div class='modal-dialog config-dlg' role="document">
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body'>
            <div>
                <div>
                    <div class="form-group row">
                        <label for="col-scep-alias" class="col-3 col-form-label txt-bold">
                            SCEP alias
                        </label>
                        <div class="col-9">
                            <div id="col-scep-alias" class="form-control-plaintext">
                                {{ _scep?.alias }}
                            </div>
                        </div>
                    </div>
                    <na-scep-auth (onChallengeChanged)="onChallengeChange($event)"></na-scep-auth>
                </div>
                <div>
                    <div class="block-titlebar">
                        <strong class="font-weight-bold">Device list :</strong>
                        <span class="ml-2">
                            <strong class="red-1">{{ _deviceList.length }}</strong> device(s) are selected.
                        </span>
                    </div>
                    <div class="mt-2">
                        <na-scep-dev-pickup [scep]="_scep" [mode]="_mode" (onDeviceSelected)="onDeviceSelect($event)">
                        </na-scep-dev-pickup>
                    </div>
                </div>
                <div *ngIf="_page === _enumPage.result" class="mt-2">
                    <div *ngIf="_errorMessage" class="block block-error">
                        <i class="fas fa-exclamation-circle"></i>
                        <span class="ml-2">Create credential failed :</span>
                        <div>{{ _errorMessage }}</div>
                    </div>
                </div>
            </div>
            <div class="row control-block d-flex justify-content-center align-items-center mt-4">
                <button type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_page === _enumPage.submit" data-dismiss="modal">
                    <span class="fas fa-times-circle mr-1"></span>
                    {{ 'key-cancel' | translate | capitalFirstWord }}
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_page === _enumPage.submit || _deviceList.length === 0 || !_isChallengeValid"
                    (click)="enroll()">
                    <span class="fas fa-credit-card mr-1"></span>
                    <span *ngIf="_mode === _enumScepDeviceOperationMode.add; else templateUpdateScep">Enroll</span>
                    <ng-template #templateUpdateScep>
                        Update
                    </ng-template>
                </button>
                <div *ngIf="_page === _enumPage.submit" class="loading loading-p0 ml-4">
                    <div class="loading-box"></div>
                </div>
            </div>
        </div>
    </div>
</div>