import { Component } from "@angular/core";
import { VirtualDlgComponent } from "app/content/virtual/dlg/virtual-dlg.component";
import { IRemoteFuncCtrl } from "./remote-func.def";
import { timer } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
    templateUrl: './remote-dlg-idleRemind.component.html'
})
export class RemoteDlgIdleRemindComponent extends VirtualDlgComponent implements IRemoteFuncCtrl {
    title: string;
    funcName: string;
    data: any;
    other?: any;
    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;

    _idleTimeout: Date;

    ngOnInit(): void {
        super.ngOnInit();
        const keepUseTimeout: number = 120;
        this._idleTimeout = new Date();
        this._idleTimeout.setMinutes(keepUseTimeout / 60, 0, 0);

        timer(0, 1000).pipe(
            map((timeIncre: number) => {
                const remainingTime: number = keepUseTimeout - timeIncre;

                this._idleTimeout = new Date(this._idleTimeout);
                this._idleTimeout.setSeconds(this._idleTimeout.getSeconds() - 1);

                if (remainingTime === 0) {
                    //close dialog
                    this.onActionCompleted({ funcName: this.funcName, isFault: false, data: { toDisconnect: true } });
                    this.closeDlg();
                }
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe();;
    }

    resetIdleTimeout(): void {
        this.onActionCompleted({ funcName: this.funcName, isFault: false, data: { toDisconnect: false } });
    }
}