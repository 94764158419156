import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { DialogPage } from '../../../lib/common/common.data';
import { CustomizationItemInfo } from './customization.data';

@Component({
    templateUrl: './customization.component.html'
})
export class CustomizationComponent implements OnInit {
    _logoLight: CustomizationItemInfo = new CustomizationItemInfo();
    _logoDark: CustomizationItemInfo = new CustomizationItemInfo();
    _bg: CustomizationItemInfo = new CustomizationItemInfo();

    _page: DialogPage;
    _enumPage: typeof DialogPage = DialogPage;
    _message: string;

    constructor(
        private location: Location,
        private appSvc: AppService) {
        this._logoLight.reset(this.appSvc.logoLightUrl);
        this._logoDark.reset(this.appSvc.logoDarkUrl);
        this._bg.reset(this.appSvc.bgUrl);
    }

    ngOnInit(): void {
        const data = this.appSvc.cache;
        if (data) {
            this._logoLight = data.raw.logoLight;
            this._logoDark = data.raw.logoDark;
            this._bg = data.raw.bg;
        }
    }

    preview(): void {
        const preivewRoutes: { route: string, hint?: string }[] = [];
        if (this._logoLight.isChanged()) {
            preivewRoutes.push({ route: '/app/device/devices', hint: 'logo on the topbar' });
        }
        if (this._logoDark.isChanged() || this._bg.isChanged()) {
            preivewRoutes.push({ route: '/account/login', hint: 'background or logo' });
        }

        if (preivewRoutes.length > 0) {
            this.appSvc.enterPreviewMode(
                this.location.path(),
                preivewRoutes,
                this.getPreviewData(),
                this.getPreviewData()
            );
        }
    }

    onLogoDarkImageChanged(item: CustomizationItemInfo): void {
        this._logoDark = item;
    }

    onLogoLightImageChanged(item: CustomizationItemInfo): void {
        this._logoLight = item;
    }

    onBackgroundImageChanged(item: CustomizationItemInfo): void {
        this._bg = item;
    }

    isResourceChanged(): boolean {
        return this._logoDark.isChanged() || this._logoLight.isChanged() || this._bg.isChanged();
    }

    save(): void {
        this._page = DialogPage.submit;
        this._message = null;

        this.appSvc.save(this.getPreviewData()).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._page = DialogPage.result;
            if (!res.isFault) {
                this._message = 'Please refresh the website to activate the latest customization updates.';
            }
            else {
                this._message = res.errorMessage;
            }
        });
    }

    private getPreviewData(): { bg: CustomizationItemInfo, logoDark: CustomizationItemInfo, logoLight: CustomizationItemInfo } {
        return {
            bg: this._bg,
            logoDark: this._logoDark,
            logoLight: this._logoLight
        }
    }
}