<div class="row login-page">
  <h1 class="col-12 title">Forget password - verification</h1>
  <div class="col-xl-6 offset-xl-3 col-md-8 offset-md-2 col-12">
    <div class="wrapper">
      <div class="content">
        <form #pwdResetForm="ngForm">
          <div class="form-group">
            <label for="regAccount">Login account</label>
            <input id="regAccount" type="text" readonly class="form-control-plaintext" value="{{_account}}">
          </div>
          <div class="form-group">
            <label for="regVerifyCode">Verification code from your email</label>
            <input id="regVerifyCode" name="regVerifyCode" class="form-control" type="text" required
              #regVerifyCode="ngModel" [(ngModel)]="_verifyCode" autofocus>
            <div *ngIf="regVerifyCode.invalid && (regVerifyCode.dirty || regVerifyCode.touched)"
              class="alert alert-danger">
              <div *ngIf="regVerifyCode.errors.required">
                Verification code is required
              </div>
            </div>
          </div>
        </form>
        <div class="note my-2">If you do not receive the confirmation E-mail within a few minutes, please check your Junk E-mail folder.</div>
      </div>
      <div class="text-center">
        <ng-template [ngIf]="!_isPass">
          <p *ngIf="_errorMessage" class="text-danger">{{ _errorMessage }}</p>
        </ng-template>
        <ng-template [ngIf]="_isPass">
          <p class="remind">New password has been sent to your email.</p>
          <P class="remind">Please use new password to login again and modify your password.</P>
        </ng-template>
      </div>
      <div class="footer">
        <button *ngIf="_isPass" type="button" class="btn btn-custom btn-blue" [routerLink]="['/account/login']">
          Back to login
        </button>
        <button *ngIf="!_isPass" [disabled]="!pwdResetForm.form.valid" class="btn btn-custom btn-green" type="submit"
          (click)="submit()">
          Activate
        </button>
        <div [class.loading-box]="_isRunning"></div>
      </div>
    </div>
  </div>
</div>
