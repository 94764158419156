<div class='modal-dialog config-dlg'>
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body'>
            <div *ngIf="_page === _enumPage.prepare" class="container-fluid">
                <ul class="pl-0">
                    <li *ngIf="_devices.length === 0" class="text-danger">
                        Please select a device.
                    </li>
                </ul>
            </div>
            <div *ngIf="_page === _enumPage.action" class="container-fluid px-1">
                <div class="mb-2">
                    Choose your license file and we will help allocate the license to each device.
                    <i class="fas fa-question-circle" data-toggle="collapse" data-target="#licenseFormatExplanation"
                        aria-expanded="false"></i>
                    <div id="licenseFormatExplanation" class="collapse">
                        <div class="card card-body py-2 px-3">
                            <div>
                                <strong>License file format</strong>
                                <button type="button" class="close" data-toggle="collapse"
                                    data-target="#licenseFormatExplanation">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div>OOOOO-7C3D7-C4D66-A08F2-...</div>
                            <div>OOOOO-7016E-65755-68413-...</div>
                            <div>OOOOO-E85AC-97725-75435-...</div>
                        </div>
                    </div>
                </div>
                <div class="drag-box" [ngClass]="_bDragEnter ? 'active': ''" (drop)="fileDrop($event)"
                    (dragenter)="fileDragEnter($event)" (dragleave)="fileDragLeave($event)"
                    (dragover)="fileDragOver($event)">
                    <i class="fas fa-file-import" style="font-size:2rem;"></i>
                    <div class="mt-1 d-flex align-items-center justify-content-center">
                        <strong class="filepicker-anchor" (click)="triggerFilePicker()">
                            Browse Files
                        </strong>
                        <span class="ml-1">or</span>
                        <span class="ml-1">drag file here</span>
                    </div>
                    <div *ngIf="_licenseFormatError" class="text-danger">{{ _licenseFormatError }}</div>
                    <input #filePicker type="file" accept=".txt" (change)="fileChange($event)"
                        aria-label="Select license file" style="display:none">
                </div>
                <div *ngIf="_licenseTotalAmount > 0" class="d-flex justify-content-between align-items-center mt-1">
                    <span>
                        <strong>File name :</strong>
                        <span class="ml-1">{{ _licenseFileName }}</span>
                    </span>
                    <span>
                        #
                        <span class="text-danger">{{ _licenseTotalAmount }} </span> license(s) available
                    </span>
                    <span>
                        #
                        <span class="text-danger">{{ _licenseAllocateAmount }}</span> license(s) distributed
                    </span>
                </div>
                <div class="list-group mt-1">
                    <div class="list-group-item list-group-header">
                        <div class="row">
                            <span class="col-5 title">Device name</span>
                            <span class="col-7 title text-break">License code</span>
                        </div>
                    </div>
                    <div class="list-group-content list-group-hoverless list-group-content-modal-nr-3">
                        <div *ngFor="let device of _legalDevices" class="list-group-item">
                            <div class="row align-items-top">
                                <div class="col-lg-5 col-12 mb-1 mb-lg-0 text-break">
                                    {{ device.virtualName }}
                                    <button *ngIf="_unusedLicenseList.length > 0"
                                        class="btn btn-sm btn-action btn-light float-right add-license-btn layout-mobile"
                                        type="button" data-toggle="collapse"
                                        [attr.data-target]="'#collapse-' + device.virtualId" aria-expanded="false">
                                        <i class="fas fa-angle-double-down">
                                            <span class="sr-only">Show available license codes</span>
                                        </i>
                                        <i class="fas fa-angle-double-up">
                                            <span class="sr-only">Hide available license codes</span>
                                        </i>
                                    </button>
                                </div>
                                <div class="col-lg-6 col-12 mt-sm-0 mt-1">
                                    <div *ngFor="let licenseCode of _devLicenseMap[device.virtualId].licenseCodes"
                                        class="input-group input-group-sm license-code-item">
                                        <input type="text" class="form-control" disabled value="{{ licenseCode }}"
                                            aria-label="distributed license code">
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-action red-1" type="button"
                                                (click)="removeLicense(device.virtualId, licenseCode)">
                                                <i class="fas fa-minus-circle"></i>
                                                <span class="ml-2 sr-only">Remove this license code</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="_unusedLicenseList.length > 0" class="collapse"
                                        [id]="'collapse-' + device.virtualId">
                                        <div class="card card-body p-2 mt-1">
                                            <strong>Distributable license(s) :</strong>
                                            <div *ngFor="let unusedLicenseCode of _unusedLicenseList"
                                                class="input-group input-group-sm license-code-item">
                                                <input type="text" class="form-control" disabled
                                                    value="{{ unusedLicenseCode }}" aria-label="available license code">
                                                <div class="input-group-append">
                                                    <button class="btn btn-sm btn-secondary" type="button"
                                                        (click)="addLicense(device.virtualId, unusedLicenseCode)">
                                                        <i class="fas fa-plus-circle"></i>
                                                        <span class="sr-only">Add this license code</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-1 layout-desktop">
                                    <button *ngIf="_unusedLicenseList.length > 0"
                                        class="btn btn-sm btn-action btn-light add-license-btn" type="button"
                                        data-toggle="collapse" [attr.data-target]="'#collapse-' + device.virtualId"
                                        aria-expanded="false">
                                        <i class="fas fa-angle-double-down">
                                            <span class="sr-only">Show available license codes</span>
                                        </i>
                                        <i class="fas fa-angle-double-up">
                                            <span class="sr-only">Hide available license codes</span>
                                        </i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="_page === _enumPage.confirm" class="container-fluid px-1 layout-desk">
                <div>
                    Click 'Apply' to confirm your changes, or click 'Prev' to modify again.
                    <span *ngIf="!allowSubmit()" class="text-danger">(No changes)</span>
                </div>
                <div class="list-group mt-1">
                    <div class="list-group-item list-group-header black">
                        <div class="row">
                            <span class="col-6 title">Device name</span>
                            <span class="col-6 title text-break">Applied license(s)</span>
                        </div>
                    </div>
                    <div class="list-group-content list-group-content-modal-nr-1">
                        <div *ngFor="let device of _legalDevices" class="list-group-item list-group-item-action">
                            <div class="row align-items-top">
                                <span class="col-lg-6 col-12 text-break">
                                    {{ device.virtualName }}
                                </span>
                                <span class="col-lg-6 col-12 mt-sm-0 mt-1">
                                    <ul class="mb-1 p-0">
                                        <li *ngFor="let licenseCode of _devLicenseMap[device.virtualId].licenseCodes">
                                            {{ licenseCode }}
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="_page === _enumPage.submit" class="container-fluid px-1">
                <div class="progress">
                    <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
                        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                        Updating the changes... Please wait...
                    </div>
                </div>
            </div>
            <div *ngIf="_page === _enumPage.result" class="container-fluid px-1">
                <p *ngIf="_devLicenseImportErrorList.length === 0">New settings have been applied successfully.</p>
                <div *ngIf="_devLicenseImportErrorList.length > 0">
                    Some settings are failed.
                    <div *ngFor="let errorItem of _devLicenseImportErrorList" class="my-1 p-1 border">
                        <div>
                            <span>Device :</span>
                            <span>
                                {{ errorItem.device?.virtualName }}
                            </span>
                        </div>
                        <strong class="text-danger">Error : </strong>
                        <div class="text-danger ml-2">
                            <div *ngFor="let errorLicenseItem of errorItem.errorMessageList">
                                {{ errorLicenseItem.licenseKey }} : {{ errorLicenseItem.errorMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row control-block d-flex justify-content-center'>
                <button *ngIf="allowGoBack()" type="button" class="btn btn-sm btn-action btn-light" (click)="goBack()">
                    <span class="fas fa-angle-double-left icon-left"></span>
                    {{ 'key-prev' | translate | capitalFirstWord }}
                </button>
                <button *ngIf="allowCancel()" type="button" class="btn btn-sm btn-action btn-light"
                    data-dismiss="modal">
                    <span class="fas fa-times-circle icon-left"></span>
                    {{ 'key-cancel' | translate | capitalFirstWord }}
                </button>
                <button *ngIf="allowGoNext()" type="button" class="btn btn-sm btn-action btn-light" (click)="goNext()">
                    {{ 'key-next' | translate | capitalFirstWord }}
                    <span class="fas fa-angle-double-right icon-right"></span>
                </button>
                <button *ngIf="allowSubmit()" type="button" class="btn btn-sm btn-action btn-light" (click)="goNext();">
                    <span class="fas fa-check-circle icon-left"></span>
                    {{ 'key-apply' | translate | capitalFirstWord }}
                </button>
                <button *ngIf="allowClose()" type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal">
                    <span class="fas fa-times-circle icon-left"></span>
                    {{ 'key-close' | translate | capitalFirstWord }}
                </button>
            </div>
        </div>
    </div>
</div>