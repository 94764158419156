import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { AppStartScreensaverInfo } from "../../content/device/data/device-info";
import { ConstantService } from "../../lib/common/constant.service";
import { IUIElement } from "../uiElement.interface";
import { AutoUnsubscribeComponent } from "app/content/virtual/auto-unsubscribe.component";

@Component({
    selector: 'na-appstart-screensaver',
    templateUrl: './appstart-screensaver.component.html',
    styleUrls: ['../uiElement.style.css', './appstart-screensaver.component.css']
})
export class AppStartScreensaverComponent extends AutoUnsubscribeComponent implements IUIElement {
    _timeoutList: number[] = [];
    _mediaTypeList: { name: string, type: string }[] = [];

    @Input('data') _info: AppStartScreensaverInfo;
    @Input('showBorder') _showBorder: boolean = false;
    @Input('disabled') _disabled: boolean = false; 
    @Input('unsupportReason') _unsupportReason: string;
    @Input('lock') _lockInfo: { isSync: boolean, policyID: string, policyName: string };

    private _screensaverUrlRef: ElementRef;
    @ViewChild('screensaverUrl')
    set screensaverUrl(v: ElementRef) {
        if (!this._screensaverUrlRef && v) {
            this._screensaverUrlRef = v;

            fromEvent(this._screensaverUrlRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._info.data = e.target.value;
                this.notify();
            });
        }
    }

    @Output() onScreensaverChanged = new EventEmitter<{ data: AppStartScreensaverInfo, isValid: boolean }>();

    constructor(private constantSvc: ConstantService) {
        super();
        this._timeoutList = this.constantSvc.SCREEN_SAVER_TIMEOUT_LIST;
        this._mediaTypeList = this.constantSvc.SCREEN_SAVER_MEDIATYPE_LIST;
    }

    enableScreensaver(checked: boolean): void {
        if (this._info.enabled !== checked) {
            this._info.enabled = checked;
            this.notify();
        }
    }

    changeTimeout(timeout: number): void {
        if (this._info.timeout !== timeout) {
            this._info.timeout = timeout;
            this.notify();
        }
    }

    /*     
    changeMediaType(mediaType: { name: string, type: string }): void {
        if (this._info.type !== mediaType.type) {
            this._info.type = mediaType.type;
            this.updateScreensaverChange();
        }
    }  
    isMediaTypeValid(): boolean {
        return this._info.enabled && !this._info.type ? false : true;
    } 
    */

    isTimeoutValid(): boolean {
        return this._info.enabled && !this._info.timeout ? false : true;
    }

    isDataValid(): boolean {
        return this._info.enabled && !this._info.data ? false : true;
    }

    private notify(): void {
        this.onScreensaverChanged.emit({ data: this._info, isValid: this._info.isValid() });
    }
}