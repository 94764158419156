<ul>
    <li>
        <div>
            <span>{{ _actionName }}</span>
            <div class="btn-group ml-2">
                <button [id]="'dropdown-' + _actionName + '-BasicBeginTimeLink'" type="button"
                    [disabled]="!_enabled"
                    class="btn btn-sm btn-action btn-light dropdown-toggle"
                    [class.invalid]="!_info.isBasicTimePeriodScheduleValid(_enabled)" data-toggle="dropdown">
                    {{ _info.basic.beginTime }}
                    <span class="sr-only">modify {{ _actionName }} basic begin time</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-left" [attr.aria-labelledby]="'dropdown-' + _actionName + '-BasicBeginTimeLink'"
                    style="min-width:300px">
                    <div class="mx-2">
                        <na-timePicker [time]="_info.basic.beginTime" [minuteIncre]="1" [showBorder]="false"
                            [showSelectionTime]="false" (onTimeChanged)="changeBasicBeginTime($event)">
                        </na-timePicker>
                    </div>
                </ul>
            </div>
            <span class="ml-2">to</span>
            <div class="btn-group ml-2">
                <button [id]="'dropdown-' + _actionName + '-BasicEndTimeLink'" type="button"
                    [disabled]="!_enabled"
                    class="btn btn-sm btn-action btn-light dropdown-toggle"
                    [class.invalid]="!_info.isBasicTimePeriodScheduleValid(_enabled)" data-toggle="dropdown">
                    {{ _info.basic.endTime }}
                    <span class="sr-only">modify {{ _actionName }} basic end time</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-left" [attr.aria-labelledby]="'dropdown-' + _actionName + '-BasicEndTimeLink'"
                    style="min-width:300px">
                    <div class="mx-2">
                        <na-timePicker [time]="_info.basic.endTime" [minuteIncre]="1" [showBorder]="false"
                            [showSelectionTime]="false" (onTimeChanged)="changeBasicEndTime($event)">
                        </na-timePicker>
                    </div>
                </ul>
            </div>
            <span class="ml-2">on</span>
        </div>
        <div class="ml-2">
            <div>
                <div class="cmn-radio">
                    <input [id]="_actionName + '-radio-weekday'" type="radio" [attr.name]="_actionName + '-radio-option'"
                        [disabled]=" !_enabled"
                        [checked]="_info.basic.scheduleMode === _enumWeekDayScheduleMode.weekday"
                        (click)="changeScheduleMode(_enumWeekDayScheduleMode.weekday)">
                    <label [for]="_actionName + '-radio-weekday'" class="radio"></label>
                    <span class="ml-2">Repeat</span>
                </div>
                <div class="schedule-option d-flex flex-wrap ml-4 mt-1"
                    [class.invalid]="!_info.isBasicWeekdayScheduleValid(_enabled)">
                    <div *ngFor="let ds of _info.basic.dayList" class="d-flex align-items-center m-2">
                        <input type="checkbox" [id]="_actionName + '-basic-' + ds.day" class="cmn-checkbox"
                            [checked]="ds.checked"
                            [disabled]="!_enabled || _info.basic.scheduleMode !== _enumWeekDayScheduleMode.weekday"
                            (change)="checkDay(ds, $event.target.checked)">
                        <label [for]="_actionName + '-basic-' + ds.day">
                            <svg>
                                <use xlink:href="#checkmark" />
                            </svg>
                            <span class="sr-only">Select day {{ ds.day }}</span>
                        </label>
                        <span class="ml-1">{{ ds.day }}</span>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <div class="cmn-radio">
                    <input [id]="_actionName + '-radio-period'" type="radio" [attr.name]="_actionName + '-radio-option'"
                        [disabled]="!_enabled"
                        [checked]="_info.basic.scheduleMode === _enumWeekDayScheduleMode.period"
                        (click)="changeScheduleMode(_enumWeekDayScheduleMode.period)">
                    <label [for]="_actionName + '-radio-period'" class="radio"></label>
                    <span class="ml-2">Specific period</span>
                </div>
                <div class="schedule-option ml-4 mt-1">
                    <label [for]="'input-' + _actionName + '-BeginPeriod'" class="ml-2">From</label>
                    <input [id]="'input-' + _actionName + '-BeginPeriod'" type="date" class="ml-2" [value]="_info.basic.beginDate"
                        [disabled]="!_enabled || _info.basic.scheduleMode !== _enumWeekDayScheduleMode.period"
                        [class.invalid]="!_info.isBasicDatePeriodScheduleValid(_enabled)"
                        (change)="changeBasicPeriodBeginDate($event.target.value)">
                    <label [for]="'input-' + _actionName + '-EndPeriod'" class="ml-2">to</label>
                    <input [id]="'input-' + _actionName + '-EndPeriod'" type="date" class="ml-2" [value]="_info.basic.endDate"
                        [disabled]="!_enabled || _info.basic.scheduleMode !== _enumWeekDayScheduleMode.period"
                        [class.invalid]="!_info.isBasicDatePeriodScheduleValid(_enabled)"
                        (change)="changeBasicPeriodEndDate($event.target.value)">
                </div>
            </div>
        </div>
    </li>
    <li class="mt-2">
        <div class="d-flex align-items-center">
            <input type="checkbox" [id]="'cb-' + _actionName + '-weekend'" class="cmn-checkbox" [checked]="_info.weekend.enabled"
                [disabled]="!_enabled"
                (change)="enableWeekendSetting($event.target.checked)">
            <label [for]="'cb-' + _actionName + '-weekend'">
                <svg>
                    <use xlink:href="#checkmark" />
                </svg>
                <span class="sr-only">Select different hours for weekend</span>
            </label>
            <span class="ml-1">Different hours for weekend</span>
        </div>
        <div class="ml-4 mt-2">
            <span>{{ _actionName }} from</span>
            <div class="btn-group ml-2">
                <button [id]="'dropdown-' + _actionName + '-WeekendBeginTimeLink'" type="button"
                    class="btn btn-sm btn-action btn-light dropdown-toggle"
                    [class.invalid]="!_info.isWeekendTimePeriodScheduleValid(_enabled)"
                    [disabled]=" !_enabled || !_info.weekend.enabled"
                    data-toggle="dropdown">
                    {{ _info.weekend.beginTime }}
                    <span class="sr-only">modify {{ _actionName }} weekend begin time</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-left" [attr.aria-labelledby]="'dropdown-' + _actionName + '-WeekendBeginTimeLink'"
                    style="min-width:300px">
                    <na-timePicker [time]="_info.weekend.beginTime" [minuteIncre]="1" [showBorder]="false"
                        [showSelectionTime]="false" (onTimeChanged)="changeWeekendBeginTime($event)">
                    </na-timePicker>
                </ul>
            </div>
            <span class="ml-2">to</span>
            <div class="btn-group ml-2">
                <button [id]="'dropdown-' + _actionName + '-WeekendEndTimeLink'" type="button"
                    class="btn btn-sm btn-action btn-light dropdown-toggle"
                    [class.invalid]="!_info.isWeekendTimePeriodScheduleValid(_enabled)"
                    [disabled]=" !_enabled || !_info.weekend.enabled"
                    data-toggle="dropdown">
                    {{ _info.weekend.endTime }}
                    <span class="sr-only">modify {{ _actionName }} weekend end time</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-left" [attr.aria-labelledby]="'dropdown-' + _actionName + '-WeekendEndTimeLink'"
                    style="min-width:300px">
                    <na-timePicker [time]="_info.weekend.endTime" [minuteIncre]="1" [showBorder]="false"
                        [showSelectionTime]="false" (onTimeChanged)="changeWeekendEndTime($event)">
                    </na-timePicker>
                </ul>
            </div>
            <span class="ml-2">on</span>
        </div>
        <div class="schedule-option d-flex flex-wrap ml-4 mt-1" [class.invalid]="!_info.isWeekendDayScheduleValid(_enabled)">
            <div *ngFor="let ds of _info.weekend.dayList" class="d-flex align-items-center m-2">
                <input type="checkbox" [id]="_actionName + '-weekend-' + ds.day" class="cmn-checkbox" [checked]="ds.checked"
                    [disabled]="!_enabled || !_info.weekend.enabled"
                    (change)="checkDay(ds, $event.target.checked)">
                <label [for]="_actionName + '-weekend-' + ds.day">
                    <svg>
                        <use xlink:href="#checkmark" />
                    </svg>
                    <span class="sr-only">Select day {{ ds.day }}</span>
                </label>
                <span class="ml-1">{{ ds.day }}</span>
            </div>
        </div>
    </li>
</ul>