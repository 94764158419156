<div class="dropdown">
    <button id="id_dashboard_adv_filter" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
        [class.changed]="isFilterApplied" data-toggle="dropdown" [disabled]="!_isReady"
        (click)="openAdvanceFilter()">
        Advanced filter
    </button>
    <div #dropdownMenuEvent class="dropdown-menu dropdown-menu-left dropdown-menu-event"
        aria-labelledby="id_dashboard_adv_filter" (click)="$event.stopPropagation()">
        <div class="mx-4">
            <!-- online status filter -->
            <div class="filter-title">
                Status
            </div>
            <div class="online-block" [class.changed]="_isOnlineStatusFilterChanged" (click)="$event.stopPropagation()">
                <div *ngFor="let status of _onlineStatusFilter | keyvalue" class="filter-item">
                    <div>
                        <input type="checkbox" [id]="'id_onlineStatusFilter_' + status.key" class="cmn-checkbox"
                            [checked]="status.value" [disabled]="_loading"
                            (change)="changeOnlineStatusFilter(status.key, $event.target.checked)">
                        <label [for]="'id_onlineStatusFilter_' + status.key">
                            <svg>
                                <use xlink:href="#checkmark" />
                            </svg>
                            <span class="sr-only">Filter {{ status.key }}</span>
                        </label>
                    </div>
                    <i class="ball ml-2" [ngClass]="'status-' + status.key"></i>
                    <span class="ml-2">{{ status.key | capitalFirstWord }}</span>
                </div>
            </div>
        </div>
        <div class="divider"></div>
        <div class="mx-4">
            <div class="row">
                <div class="col col-md-2">
                    <div class="filter-title">
                        Categories
                    </div>
                    <div *ngFor="let filter of _advFilters" class="cmn-radio">
                        <ng-container *ngIf="!filter.hide">
                            <input [id]="filter.id" type="radio" name="devAdvFilterOption"
                                [checked]="_filter?.groupName === filter.groupName" [disabled]="_loading"
                                (click)="selectFilterCategory(filter)">
                            <label [for]="filter.id" class="radio"></label>
                            <span class="ml-2">
                                {{ filter.groupName }}
                                <span *ngIf="filter.changedOptionsCount > 0" class="red-1 ml-1">
                                    ({{ filter.changedOptionsCount }})
                                </span>
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="col col-md-10">
                    <div class="adv-filter-content">
                        <na-dev-adv-filter-item [source]="_filter" [loading]="_loading"></na-dev-adv-filter-item>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="mx-auto col-12 col-md-6 offset-md-3" (click)="$event.stopPropagation()">
                <div class="mt-4 mb-2 d-flex align-items-center">
                    <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_loading" (click)="reset()">
                        Reset filter
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-light ml-auto" [disabled]="_loading"
                        (click)="applySearch()">
                        Apply
                    </button>
                    <div *ngIf="_loading" class="loading-box ml-2 mr-4"></div>
                    <button type="button" class="btn btn-sm btn-action btn-light ml-2" [disabled]="_loading"
                        (click)="dropdownMenuEvent.classList.remove('show'); cancelSearch()">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>