import { HttpClient, HttpResponse } from '@angular/common/http';
import { APIBaseManager, IAPIRx } from '../../api.base';
import { IVirtualDeviceRxData } from './virtualDevice.common';

export interface IGetVirtualDevicePathParameter {
    virtualDeviceID: string;
}

export class APIGetVirtualDeviceManager extends APIBaseManager<IGetVirtualDevicePathParameter, string[], void, IVirtualDeviceRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetVirtualDevice';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IGetVirtualDevicePathParameter, queryParameters: string[]): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + ((queryParameters && queryParameters.length > 0) ? '?detail=' + queryParameters.join(',') : '');
    }

    protected doGetAftermathAction(res: HttpResponse<IAPIRx<IVirtualDeviceRxData>>): boolean {
        const serverTime = parseInt(res.headers.get('IAdea-Server-Timestamp-Milliseconds'));
        if (serverTime) {
            res.body.serverTime = serverTime;
        }

        return true;
    }
}