import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DeviceGroupFuncInterface, DeviceGroupFuncItem } from '../dlg/group-func.def';
import { DeviceGroupFuncDirective } from '../dlg/group-func.directive';
import { DeviceGroupFuncService } from '../dlg/group-func.service';
import { DeviceGroupInfo, DeviceGroupType, DeviceGroupMode, DEVICE_GROUP_FUNC_MOVE, DEVICE_GROUP_ID_HOME } from '../group.data';
import { DeviceGroupService } from '../dev-group.service';
import { OnlineStatus } from '../../data/device-info';
import { HelperLib } from 'app/lib/common/helper.lib';

@Component({
    selector: 'na-dev-group-tree',
    templateUrl: './dev-group-tree-view.component.html',
    styleUrls: ['./dev-group-tree-view.component.css'],
})
export class DeviceGroupTreeViewComponent implements OnInit {
    static INSTANCE_ID: number = 1;
    readonly DEVICE_GROUP_ID_HOME: string = DEVICE_GROUP_ID_HOME;
    _id: number;

    _dropTarget: DeviceGroupInfo;
    _dragTarget: DeviceGroupInfo;
    _dragMap: { [devGroupID: string]: boolean } = {};
    _filterMap: Map<string, boolean> = new Map();

    @Input('group') _g: DeviceGroupInfo;
    @Input('mode') _mode: string = DeviceGroupMode.edit;
    @Input('account') _account: string; // account name
    @Input('moveTarget') _moveTarget: DeviceGroupInfo;
    @Input('showDevice') _showDevice: boolean = false;
    @Input('showPolicy') _showPolicy: boolean = false;
    @Input('filter') _filter: { isFilterApplied?: boolean, searchContent?: string, allowedDeviceGroupSource?: Map<string, boolean>,  allowedDeviceSource?: Map<string, boolean> } = {};
    @Input('statistic') _statistic: { [groupId: string]: { online: number, total: number } } = {};


    _pickMap: { [groupID: string]: { checked: boolean, groupType: DeviceGroupType, name?: string } } = {};
    @Input()
    set pickMap(s: { [groupID: string]: { checked: boolean, groupType: DeviceGroupType, name?: string } }) {
        this._pickMap = s;
    }

    _enumGroupMode: typeof DeviceGroupMode = DeviceGroupMode;
    _enumGroupType: typeof DeviceGroupType = DeviceGroupType;
    _isFind: boolean = false;
    _errorMessage: string;

    private _btnDevMoveFuncElementRef: ElementRef;
    @ViewChild('btnMoveFunc', { static: true })
    set btnMoveFunc(v: ElementRef) {
        this._btnDevMoveFuncElementRef = v;
    }
    @ViewChild(DeviceGroupFuncDirective, { static: true }) groupFuncHost: DeviceGroupFuncDirective;

    @Output() onGroupSelected = new EventEmitter<{ devGroup: DeviceGroupInfo, filter?: { onlineStatus: { [state: string]: boolean } } }>();
    @Output() onGroupPolicyInspected = new EventEmitter<DeviceGroupInfo>();
    @Output() onGroupMoveTargetChanged = new EventEmitter<DeviceGroupInfo>();

    constructor(
        private groupSvc: DeviceGroupService,
        private groupFuncSvc: DeviceGroupFuncService) { }

    ngOnInit(): void {
        DeviceGroupTreeViewComponent.INSTANCE_ID++;
        this._id = DeviceGroupTreeViewComponent.INSTANCE_ID;
    }

    findMatchDeviceGroup(g: DeviceGroupInfo): boolean {
        if (!this._filter || (!this._filter.isFilterApplied && !this._filter.searchContent)) {
            return true;
        }

        if (this._filter.allowedDeviceGroupSource) {
            if ((this._filter.isFilterApplied && this._filter.allowedDeviceGroupSource.size == 0) || (this._filter.allowedDeviceGroupSource.size > 0 && !this.findMatchByDeviceSource(g, this._filter.allowedDeviceGroupSource)) )  {
                return false;
            }
        }

        if (this._filter.searchContent && !this.findMatchBySearch(g, this._filter.searchContent)) {
            return false;
        }

        return true;
    }

    private findMatchByDeviceSource(g: DeviceGroupInfo, allowedSet: Map<string, boolean>): boolean {
        if (allowedSet.has(g.id)) {
            g.expanded = true;
            return true;
        }

        for (const subg of g.subgroups) {
            if (subg.type !== DeviceGroupType.group) {
                continue;
            }

            if (this.findMatchByDeviceSource(subg, allowedSet)) {
                g.expanded = true;
                return true;
            }
        }

        return false;
    }

    private findMatchBySearch(g: DeviceGroupInfo, search: string): boolean {
        if (g.name.toLocaleLowerCase().indexOf(this._filter.searchContent) >= 0) {
            g.expanded = true;
            return true;
        }

        for (const subg of g.subgroups) {
            if (subg.type !== DeviceGroupType.group) {
                continue;
            }

            if (this.findMatchBySearch(subg, search)) {
                g.expanded = true;
                return true;
            }
        }

        return false;
    }

    inspectOnlineDeviceInGroup(g: DeviceGroupInfo): void {
        this.inspectGroup(g, { onlineStatus: HelperLib.getOnlineStatusState({ [OnlineStatus.Disconnect]: false, [OnlineStatus.Offline]: false }).onlineStatus });
    }

    inspectGroup(g: DeviceGroupInfo, simpleFilter?: { onlineStatus: { [state: string]: boolean } }): void {
        switch (this._mode) {
            default:
            case DeviceGroupMode.edit:
                {
                    this.groupSvc.inspectGroup(this._account, g, true);
                    this.onGroupSelected.emit({ devGroup: g, filter: simpleFilter });
                }
                break;
            case DeviceGroupMode.pickone:
                {
                    if (g.type === DeviceGroupType.group && (!this._moveTarget || g.id !== this._moveTarget.id)) {
                        this._moveTarget = g;
                        this.onGroupMoveTargetChanged.emit(this._moveTarget);
                    }
                }
                break;
        }
    }

    checkoutGroup(g: DeviceGroupInfo, checked: boolean): void {
        this._pickMap[g.id] = this._pickMap[g.id] || { groupType: g.type, checked: checked, name: g.name };
        this._pickMap[g.id].checked = checked;

        // apply to all descendant groups no matter checked or unchecked
        if (g.type === DeviceGroupType.group) {
            const targetList: { target: DeviceGroupInfo }[] = [{ target: g }];
            while (targetList.length > 0) {
                const next = targetList.pop();

                for (const childGroup of next.target.childs.filter(c => c.type === DeviceGroupType.group)) {
                    targetList.push({ target: childGroup });
                    this._pickMap[childGroup.id] = this._pickMap[childGroup.id] || { groupType: childGroup.type, checked: checked, name: childGroup.name };
                    this._pickMap[childGroup.id].checked = checked;
                }
            }

            if (this._mode === DeviceGroupMode.pickByAdmin && checked) {
                // check parent group until reach to 'g-home'
                let parentGroup: DeviceGroupInfo = this.groupSvc.getEnterpriseGroupByID(g.parentID);
                while (parentGroup && parentGroup.id !== DEVICE_GROUP_ID_HOME) {
                    this._pickMap[parentGroup.id] = this._pickMap[parentGroup.id] || { groupType: DeviceGroupType.group, checked: false, name: parentGroup.name };
                    this._pickMap[parentGroup.id].checked = true;

                    parentGroup = this.groupSvc.getEnterpriseGroupByID(parentGroup.parentID);
                }
            }
        }
    }

    onMoveTargetChange(g: DeviceGroupInfo): void {
        this._moveTarget = g;
        this.onGroupMoveTargetChanged.emit(this._moveTarget);
    }

    onDeviceSubgroupPolicyInspect(event: any): void {
        this.onGroupPolicyInspected.emit(event);
    }

    onDeviceSubgroupSelect(ev: { devGroup: DeviceGroupInfo, filter?: { onlineStatus: { [state: string]: boolean } } }): void {
        this.onGroupSelected.emit(ev);
    }

    inspectGroupPolicy(g: DeviceGroupInfo): void {
        this.onGroupPolicyInspected.emit(g);
    }

    onDragStart(e: DragEvent, g: DeviceGroupInfo): void {
        if (g.active) {
            e.dataTransfer.dropEffect = 'move';
            e.dataTransfer.setData('text/plain', g?.id ? 'fromGroup:' + g.id + ';' : null);
        }
    }

    onDragEnter(e: DragEvent, g: DeviceGroupInfo): void {
        if (!this.drag_prev_check(e)) {
            return;
        }

        this._dragMap[g.id] = true;
    }

    onDragOver(e: DragEvent): void {
        if (!this.drag_prev_check(e)) {
            return;
        }
    }

    onDragLeave(e: DragEvent, g: DeviceGroupInfo): void {
        if (!this.drag_prev_check(e)) {
            return;
        }

        this._dragMap[g.id] = false;
    }

    onDrop(e: DragEvent, g: DeviceGroupInfo): void {
        if (!this.drag_prev_check(e)) {
            return;
        }

        const dragInfoStr: string = e.dataTransfer.getData("text");
        const matchs: string[] = dragInfoStr.match(/fromGroup:([^:;]+);/);
        if (matchs && matchs.length === 2) {
            this._dragTarget = this.groupSvc.getGroupByID(matchs[1]);
        }
        if (dragInfoStr) {
            this._dropTarget = g;
            this._btnDevMoveFuncElementRef.nativeElement.click();
        }

        this._dragMap = {};
    }

    private drag_prev_check(e: DragEvent): boolean {
        if (this._mode !== DeviceGroupMode.edit) {
            return false;
        }

        e.preventDefault();
        e.stopPropagation();

        return true;
    }

    changeGroup(): void {
        this.playGroupFunc(DEVICE_GROUP_FUNC_MOVE, null, { fromGroup: this._dragTarget, targetGroup: this._dropTarget });
        this._dragTarget = null;
    }

    playGroupFunc(funcName: string, g?: DeviceGroupInfo, other?: any): void {
        const item: DeviceGroupFuncItem = this.groupFuncSvc.getFunctionByName(funcName);
        if (item) {
            const viewContainerRef = this.groupFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<DeviceGroupFuncInterface>componentRef.instance).title = item.title;
            (<DeviceGroupFuncInterface>componentRef.instance).group = g || this.groupSvc.getActiveGroup();
            (<DeviceGroupFuncInterface>componentRef.instance).other = other;
        }
    }
}