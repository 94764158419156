import { HttpClient, HttpResponse } from '@angular/common/http';
import { APIBaseManager, IAPIRx } from '../../api.base';
import { IListVirtualDeviceQueryParameter, IListVirtualDeviceRxData } from './api.virtualDevice.list';

export interface ISearchVirtualDeviceByAdvanceFilterTxData {
    advancedFilterList?: string[];
    labelList?: string[];
}

export class APISearchVirtualDeviceByAdvanceFilterManager extends APIBaseManager<void, IListVirtualDeviceQueryParameter, ISearchVirtualDeviceByAdvanceFilterTxData, IListVirtualDeviceRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_SearchVirtualDeviceByAdvanceFilter';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: IListVirtualDeviceQueryParameter): string {
        if (queryParameters) {
            if (Array.isArray(queryParameters.detail)) {
                queryParameters.detail = queryParameters.detail.join(',');
            }
            
            if (queryParameters.sort instanceof Object) {
                queryParameters.sort = Object.keys(queryParameters.sort).map(key => key + ':' + queryParameters.sort[key]).join(',');
            }
    
            if (queryParameters.search instanceof Object) {
                queryParameters.search = JSON.stringify(queryParameters.search);
            }
        }
        
        return super.getRequestURL() + 'virtualDevices/advancedSearch' + this.transformObjectQueryParameterToStr(queryParameters);;
    }

    protected doPostAftermathAction(res: HttpResponse<IAPIRx<IListVirtualDeviceRxData>>): boolean {
        const serverTime = parseInt(res.headers.get('IAdea-Server-Timestamp-Milliseconds'));
        if (serverTime) {
            res.body.serverTime = serverTime;
        }

        return true;
    }
}