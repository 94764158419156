import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CustomPipeModule } from "app/lib/pipes/custom-pipe.module";
import { TranslateModule } from "app/translate/translate.module";
import { UIElementModule } from "app/uiElement/uiElement.module";
import { ServiceNowWrapperComponent } from "./svcnow-wrapper.component";
import { ServiceNowRegisterComponent } from "./comp/svcnow-register.component";
import { ServiceNowPairingComponent } from "./comp/svcnow-pairing.component";
import { ServiceNowCalendarConfigComponent } from "./comp/svcnow-calendar-config.component";
import { WorkplacePluginService } from "../plugin.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        CustomPipeModule,
        TranslateModule,
        UIElementModule
    ],
    providers: [
        WorkplacePluginService,
    ],
    declarations: [
        ServiceNowWrapperComponent,
        ServiceNowRegisterComponent,
        ServiceNowPairingComponent,
        ServiceNowCalendarConfigComponent
    ],
    exports: []
})
export class SvcNowModule { }