import { IGetVirtualDeviceAdvanceFilterRxData } from "app/API/v1/VirtualDevice/api.virtualDevice.advanceFilter.get";

/*
export enum DeviceAdvFilterEnum {
    "group", "simple-select", "select", "multi-select", "text-with-options"
}

export type DeviceAdvFilterType = keyof typeof DeviceAdvFilterEnum;
*/
export type DeviceAdvFilterTypeGroup = 'group';
export type DeviceAdvFilterTypeSimpleSelect = 'simple-select';
export type DeviceAdvFilterTypeSelect = 'select';
export type DeviceAdvFilterTypeMultiSelect = 'multi-select';
export type DeviceAdvFilterTypeTextOption = 'text-with-options';
export type DeviceAdvFilterType = DeviceAdvFilterTypeGroup | DeviceAdvFilterTypeSimpleSelect | DeviceAdvFilterTypeSelect | DeviceAdvFilterTypeMultiSelect | DeviceAdvFilterTypeTextOption;

export class DeviceAdvFilterOptionInfo {
    id: string;
    name: string;
    value: string;
    data: string;
    isDefault: boolean;
    count: number;

    originOptionData?: { [key: string]: any };

    get isChanged(): boolean {
        return this.data != '' || this.isDefault != this.originOptionData['isDefault'];
    }

    constructor(parentID?: string, parentType?: string, option?: { name: string; value: string | null; isDefault: boolean; }) {
        this.id = parentID + '_' + option?.name.replace(' ', '_');
        this.name = option?.name;
        this.value = option?.value;
        this.isDefault = option?.isDefault;
        this.data = '';
        this.count = 0;
        this.originOptionData = {};

        switch (parentType) {
            case 'select':
            case 'simple-select':
            case 'multi-select':
            case 'text-with-options':
                {
                    this.originOptionData['isDefault'] = option?.isDefault;
                }
                break;
        }
    }

    copy(): DeviceAdvFilterOptionInfo {
        const c: DeviceAdvFilterOptionInfo = new DeviceAdvFilterOptionInfo();
        c.id = this.id;
        c.name = this.name;
        c.value = this.value;
        c.isDefault = this.isDefault;
        c.data = this.data;
        c.count = this.count;
        c.originOptionData = this.originOptionData;

        return c;
    }
}

export class DeviceAdvFilterInfo {
    type: DeviceAdvFilterType;
    id: string;
    groupName: string;
    subGroupList: DeviceAdvFilterInfo[];
    optionList: DeviceAdvFilterOptionInfo[];
    isDefault: boolean;
    option: DeviceAdvFilterOptionInfo;
    dropdownShow: boolean; // if the dropdown is shown, for simple-select item
    hide: boolean; // if to hide the filter
    layer: number = 1;

    get changedOptionsCount(): number {
        let total: number = 0;
        if (this.option) {
            total += this.option.isChanged ? 1 : 0;
        }
        else {
            total += this.optionList.filter(op => op.isChanged).length;
        }
        
        this.subGroupList?.forEach(sub => total += sub.changedOptionsCount);

        return total;
    }

    constructor(filter?: IGetVirtualDeviceAdvanceFilterRxData, layer?: number) {
        this.type = filter?.type as DeviceAdvFilterType;
        this.layer = layer || 1;
        this.groupName = filter?.groupName;
        this.id = 'id_adv_filter_' + filter?.groupName.replace(' ', '_');
        this.subGroupList = filter?.subGroupList?.map(subGroup => new DeviceAdvFilterInfo(subGroup, this.layer + 1)) || [];
        this.optionList = filter?.optionList?.map(op => new DeviceAdvFilterOptionInfo(this.id, this.type, op)) || [];
        this.isDefault = false;
        this.option = this.optionList.find(op => op.isDefault);
        this.dropdownShow = false;
        this.hide = this.layer > 1 ? this.optionList.length + this.subGroupList.length === 0 : false;
        if (this.groupName === 'Device Policy' || this.groupName === 'Device Group') {
            this.hide = true;
        }
    }

    copy(): DeviceAdvFilterInfo {
        const c: DeviceAdvFilterInfo = new DeviceAdvFilterInfo();
        c.type = this.type;
        c.layer = this.layer;
        c.groupName = this.groupName;
        c.id = this.id;
        c.subGroupList = this.subGroupList.map(sub => sub.copy());
        c.optionList = this.optionList.map(op => op.copy());
        c.isDefault = this.isDefault;
        c.option = this.option?.copy();
        c.dropdownShow = this.dropdownShow;
        c.hide = this.hide;

        return c;
    }

    export(): string[] {
        const results: string[] = [];

        switch (this.type) {
            case 'select':
            case 'simple-select':
                {
                    const selectedOption = this.optionList.find(op => op.isDefault);
                    if (selectedOption && selectedOption.value) {
                        results.push(selectedOption.value);
                    }
                }
                break;
            case 'text-with-options':
                {
                    const selectedOption = this.optionList.find(op => op.isDefault);
                    if (selectedOption && selectedOption.value) {
                        const replaceBeginIndex = selectedOption.value.indexOf('{{');
                        const replaceEndIndex = selectedOption.value.indexOf('}}', replaceBeginIndex);
                        results.push(selectedOption.value.substring(0, replaceBeginIndex) + selectedOption.data + selectedOption.value.substring(replaceEndIndex + 2));
                    }
                }
                break;
            case 'multi-select':
                {
                    this.optionList.forEach(op => {
                        if (op.isDefault) {
                            results.push(op.value);
                        }
                    });
                }
                break;
        }

        if (this.subGroupList.length > 0) {
            this.subGroupList.forEach(sub => results.push(...sub.export()));
        }

        return results;
    }

    reset(): void {
        this.isDefault = false;
        this.optionList.forEach(op => {
            op.isDefault = op.originOptionData['isDefault'];
            op.data = '';
        });
        this.option = this.optionList.find(op => op.isDefault);
        this.subGroupList.forEach(sub => sub.reset());
    }
}

export const DevAdvFilterOptionNone = 'No filter';
export const DevAdvFilterOptionEnabled = 'Enabled';
export const DevAdvFilterOptionDisabled = 'Disabled';
export const DevAdvFilterOptionTextInclude = 'Contain following text';
export const DevAdvFilterOptionTextExclude = 'Does not contain following text';
export const DevAdvFilterOptionTextEmpty = 'Is empty';