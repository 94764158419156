import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { of as observableOf, Observable, throwError } from 'rxjs';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { Logger } from '../lib/common/logger';
import { HelperLib } from '../lib/common/helper.lib';
import { AppConfigService } from '../app.config';
import { LOCALSTORAGE_KEY_ACCOUNT, LOCALSTORAGE_KEY_TOKEN, LOCALSTORAGE_KEY_TOKEN_INIT_SERVER_TIME, LOCALSTORAGE_KEY_TOKEN_EXPIRE_SERVER_TIME } from '../lib/common/common.data';
import { delayWhen } from 'rxjs/operators';

export interface IAPIRx<T> {
    data: T;
    error: number | string;
    errorMessage: string;
    serverTime?: number;
    updateTime?: number;
    needLogout?: boolean;
}

export interface IRequestOption {
    responseType?: string;
    contentType?: string;
    logSourceID?: string;
}

export const CONTENT_TYPE_JSON = 'application/json';

export class APIBaseManager<P, Q, T, R> {
    protected _apiName: string = 'APIv1Base';
    protected _apiMethod: string = 'GET';
    protected _allowPayloadPrint: boolean = true;
    protected _bLogSource: boolean = true;

    static USER_TOKEN: string;
    static isTokenRefreshing: boolean = false;

    private API_HOST: string = AppConfigService.configs.server.api.baseUrl;
    private API_PORT: number = AppConfigService.configs.server.api.port;
    private API_VERSION: string = AppConfigService.configs.server.api.version;
    private API_NAME: string = 'iAdeaCare';
    private API_PROTOCOL: string = AppConfigService.configs.server.api.protocol;

    constructor(protected http: HttpClient) {
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        this.API_PROTOCOL = protocol;
        this.API_HOST = baseUrl;
        this.API_PORT = port;
    }

    protected transformObjectQueryParameterToStr(queryParameters: Q): string {
        if (!queryParameters) {
            return '';
        }

        const queryStr: string = Object.keys(queryParameters).reduce((prev: string[], curr: string) => {
            return (queryParameters[curr] !== undefined && queryParameters[curr] !== null) ? [...prev, curr + '=' + queryParameters[curr]] : prev;
        }, []).join('&');

        return queryStr ? '?' + queryStr : '';
    }

    protected getRequestURL(pathParameters: P = null, queryParameters: Q = null): string {
        return this.API_PROTOCOL + "://" + this.API_HOST + ':' + this.API_PORT + '/' + this.API_NAME + '/' + this.API_VERSION + '/';
    }

    public send(pathParameters: P, queryParameters: Q, txData: T, userToken: string = null, appToken?: string, options?: IRequestOption): Observable<IAPIRx<R>> {
        if (APIBaseManager.isTokenRefreshing) {
            //wait until token is finish refreshing.
            Logger.logInfo(this._apiName, 'send', 'Token is refreshing');
            const waitOb = new Observable((observer) => {
                this.wait_token_refreshing(() => {
                    observer.next();
                    observer.complete();
                })
            });
            return observableOf(true).pipe(
                delayWhen(() => waitOb),
                concatMap(() => {
                    Logger.logInfo(this._apiName, 'send', 'Token has refreshed ');
                    return this.internal_send(pathParameters, queryParameters, txData, userToken, appToken, options);
                })
            );
        }
        else {
            return this.internal_send(pathParameters, queryParameters, txData, userToken, appToken, options);
        }
    }

    private internal_send(pathParameters: P, queryParameters: Q, txData: T, userToken: string = null, appToken?: string, options?: IRequestOption): Observable<IAPIRx<R>> {
        if (userToken && APIBaseManager.USER_TOKEN) {
            userToken = APIBaseManager.USER_TOKEN;
        }
        switch (this._apiMethod) {
            case 'GET':
                {
                    return this.get(pathParameters, queryParameters, userToken, options);
                }
            case 'POST':
                {
                    return this.post(pathParameters, queryParameters, txData, userToken, appToken, options);
                }
            case 'DELETE':
                {
                    return this.delete(pathParameters, queryParameters, userToken, options);
                }
            case 'PATCH':
                {
                    return this.patch(pathParameters, queryParameters, txData, userToken, appToken, options);
                }
        }
    }

    protected post(pathParameters: P, queryParameters: Q, txData: T, userToken: string = null, appToken: string = null, options?: IRequestOption): Observable<IAPIRx<R>> {
        if (this._allowPayloadPrint) {
            Logger.logInfo(this._apiName, 'POST', 'Request with data = ', txData);
        }

        let url = this.getRequestURL(pathParameters, queryParameters);
        if (!this.doPostPrepareAction(pathParameters, queryParameters, txData, userToken)) {
            return throwError('Do POST prepare action failed');
        }

        Logger.logInfo(this._apiName, 'POST', url);
        if (!appToken) {
            return this.checkTokenReliability(userToken).pipe(
                concatMap((tokenRes: { originalToken: string, renewToken: string, needLogout: boolean }) => {
                    //Logger.logInfo(this._apiName, 'POST', 'Request with token = ', tokenRes);
                    if (!tokenRes.needLogout) {
                        return this.http.post<IAPIRx<R>>(url, txData, this.getRequestOption(tokenRes.renewToken ? tokenRes.renewToken : tokenRes.originalToken, options)).pipe(
                            tap((res: HttpResponse<IAPIRx<R>>) => Logger.logInfo(this._apiName, 'POST', 'Response = ', res)),
                            map((res: HttpResponse<IAPIRx<R>>) => {
                                if (!this.doPostAftermathAction(res)) {
                                    throw 'Do POST aftermath action failed';
                                }

                                return res.body;
                            })
                        );
                    }
                    else {
                        throw new Error('Token expire, need login again');
                    }
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
        }
        else {
            return this.http.post<IAPIRx<R>>(url, txData, this.getRequestOption(appToken, options)).pipe(
                tap((res: HttpResponse<IAPIRx<R>>) => Logger.logInfo(this._apiName, 'POST', 'Response = ', res)),
                map((res: HttpResponse<IAPIRx<R>>) => {
                    if (!this.doPostAftermathAction(res)) {
                        throw 'Do POST aftermath action failed';
                    }

                    return res.body;
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
        }
    }

    protected patch(pathParameters: P, queryParameters: Q, txData: T, userToken: string = null, appToken?: string, options?: IRequestOption): Observable<IAPIRx<R>> {
        if (this._allowPayloadPrint) {
            Logger.logInfo(this._apiName, 'PATCH', 'Request with data = ', txData);
        }

        let url = this.getRequestURL(pathParameters, queryParameters);
        if (!this.doPatchPrepareAction(pathParameters, queryParameters, txData, userToken)) {
            return throwError('Do PATCH prepare action failed');
        }

        Logger.logInfo(this._apiName, 'PATCH', url);
        if (!appToken) {
            return this.checkTokenReliability(userToken).pipe(
                concatMap((tokenRes: { originalToken: string, renewToken: string, needLogout: boolean }) => {
                    //Logger.logInfo(this._apiName, 'PATCH', 'Request with token = ', tokenRes);
                    if (!tokenRes.needLogout) {
                        return this.http.patch<IAPIRx<R>>(url, txData, this.getRequestOption(tokenRes.renewToken ? tokenRes.renewToken : tokenRes.originalToken, options)).pipe(
                            tap((res: HttpResponse<IAPIRx<R>>) => Logger.logInfo(this._apiName, 'PATCH', 'Response = ', res)),
                            map((res: HttpResponse<IAPIRx<R>>) => {
                                if (!this.doPatchAftermathAction(res)) {
                                    throw 'Do PATCH aftermath action failed';
                                }

                                return res.body;
                            })
                        );
                    }
                    else {
                        throw new Error('Token expire, need login again');
                    }
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
        }
        else {
            return this.http.patch<IAPIRx<R>>(url, txData, this.getRequestOption(appToken, options)).pipe(
                tap((res: HttpResponse<IAPIRx<R>>) => Logger.logInfo(this._apiName, 'PATCH', 'Response = ', res)),
                map((res: HttpResponse<IAPIRx<R>>) => {
                    if (!this.doPatchAftermathAction(res)) {
                        throw 'Do PATCH aftermath action failed';
                    }

                    return res.body;
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
        }
    }

    protected delete(pathParameters: P, queryParameters: Q, token: string = null, options?: IRequestOption): Observable<IAPIRx<R>> {
        const url = this.getRequestURL(pathParameters, queryParameters);
        if (!this.doDeletePrepareAction(pathParameters, queryParameters, token)) {
            return throwError('Do DELETE prepare action failed');
        }

        Logger.logInfo(this._apiName, 'DELETE', url);
        return this.checkTokenReliability(token).pipe(
            concatMap((tokenRes: { originalToken: string, renewToken: string, needLogout: boolean }) => {
                //Logger.logInfo(this._apiName, 'DELETE', 'Request with token = ', tokenRes);
                if (!tokenRes.needLogout) {
                    return this.http.delete<IAPIRx<R>>(url, this.getRequestOption(tokenRes.renewToken ? tokenRes.renewToken : tokenRes.originalToken, options)).pipe(
                        tap((res: HttpResponse<IAPIRx<R>>) => Logger.logInfo(this._apiName, 'DELETE', 'Response = ', res)),
                        map((res: HttpResponse<IAPIRx<R>>) => {
                            if (!this.doDeleteAftermathAction(res)) {
                                throw 'Do POST aftermath action failed';
                            }

                            return res.body;
                        })
                    );
                }
                else {
                    throw new Error('Token expire, need login again');
                }
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    protected get(pathParameters: P, queryParameters: Q, token: string = null, options?: IRequestOption): Observable<IAPIRx<R>> {
        let url = this.getRequestURL(pathParameters, queryParameters);
        if (!this.doGetPrepareAction(pathParameters, queryParameters, token)) {
            return throwError('Do GET prepare action failed');
        }

        Logger.logInfo(this._apiName, 'GET', url);
        return this.checkTokenReliability(token).pipe(
            concatMap((tokenRes: { originalToken: string, renewToken: string, needLogout: boolean }) => {
                if (!tokenRes.needLogout) {
                    return this.http.get<IAPIRx<R>>(url, this.getRequestOption(tokenRes.renewToken ? tokenRes.renewToken : tokenRes.originalToken, options)).pipe(
                        tap((res: HttpResponse<IAPIRx<R>>) => Logger.logInfo(this._apiName, 'GET', 'Response = ', res)),
                        map((res: HttpResponse<IAPIRx<R>>) => {
                            if (!this.doGetAftermathAction(res)) {
                                throw 'Do GET aftermath action failed';
                            }

                            return res.body;
                        }),
                    );
                }
                else {
                    throw new Error('Token expire, need login again');
                }
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    private handleError(errorRes: any) {
        if (errorRes instanceof HttpErrorResponse) {
            if (errorRes.error instanceof ErrorEvent) {
                Logger.logError(this._apiName, 'Error', 'An error occured: ', errorRes.error.message);
                return observableOf({ data: null, error: -1, errorMessage: errorRes.error.message, needLogout: true });
            }
            else {
                Logger.logError(this._apiName, 'Error', 'Backend returned code ' + errorRes.status + ' with message = ', errorRes.message);
                return observableOf({ data: null, error: -1, errorMessage: errorRes.message, needLogout: true });
            }
        }
        else if (typeof errorRes === 'string') {
            Logger.logError(this._apiName, 'Error', errorRes);
            return observableOf({ data: null, error: -1, errorMessage: errorRes, needLogout: true });
        }
        else {
            Logger.logError(this._apiName, 'Error', errorRes.errorMessage);
            return observableOf({ data: null, error: errorRes.error, errorMessage: errorRes, needLogout: true });
        }
    }

    private wait_token_refreshing(cb: () => void): void {
        setTimeout(() => {
            if (!APIBaseManager.isTokenRefreshing) {
                cb();
            }
            else {
                this.wait_token_refreshing(cb);
            }
        }, 1000);
    }

    protected doGetPrepareAction(pathParameters: P, queryParameters: Q, token: string = null): boolean {
        return true;
    }

    protected doGetAftermathAction(res: HttpResponse<IAPIRx<R>>): boolean {
        return true;
    }

    protected doPostPrepareAction(pathParameters: P, queryParameters: Q, txData: T, token: string = null): boolean {
        return true;
    }

    protected doPostAftermathAction(res: HttpResponse<IAPIRx<R>>): boolean {
        return true;
    }

    protected doPatchPrepareAction(pathParameters: P, queryParameters: Q, txData: T, token: string = null): boolean {
        return true;
    }

    protected doPatchAftermathAction(res: HttpResponse<IAPIRx<R>>): boolean {
        return true;
    }

    protected doDeletePrepareAction(pathParameters: P, queryParameters: Q, token: string = null): boolean {
        return true;
    }

    protected doDeleteAftermathAction(res: HttpResponse<IAPIRx<R>>): boolean {
        return true;
    }

    private getRequestOption(token: string = null, options?: IRequestOption): { headers?: HttpHeaders, observe: 'response', params?: HttpParams, reportProgress?: boolean, responseType?: any, withCredentials?: boolean } {
        options = options || {};
        options.responseType = options.responseType || 'json';
        options.contentType = options.contentType;
        options.logSourceID = options.logSourceID || 'app:care.iadea.com';

        let headers: HttpHeaders = new HttpHeaders({});

        if (token) {
            headers = headers.append('Authorization', 'Bearer ' + token);
        }
        if (options.contentType) {
            headers = headers.append('Content-Type', options.contentType);
        }

        //for debug
        if (this._bLogSource) {
            headers = headers.append('IAdeaCare-Player-ID', options.logSourceID);
        }

        return {
            headers: headers,
            observe: 'response',
            reportProgress: false,
            responseType: options.responseType,
            withCredentials: false
        };
    }

    protected checkTokenReliability(originalToken: string): Observable<{ originalToken: string, renewToken: string, needLogout: boolean }> {
        //1. check accountName in memory with that in localStorage, if it is not the same, use the token in localStorage.
        //2-1. check the current time, if it exceeds the half of the expire time, use the refresh token to get the new app token.
        //2-1. if app token has refreshed, update those in localStorage.
        if (!originalToken) {
            return observableOf({
                originalToken: null,
                renewToken: null,
                needLogout: false
            });
        }

        return observableOf(originalToken).pipe(
            concatMap((originalToken: string) => {
                const accountName: string = HelperLib.getLocalStorageRecord(LOCALSTORAGE_KEY_ACCOUNT);
                if (accountName) {
                    const now: Date = new Date();
                    const nowTimeUtc: number = Math.floor(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()) / 1000);
                    const tokenIniServerTimeStr: string = HelperLib.getLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN_INIT_SERVER_TIME);
                    const tokenExpServerTimeStr: string = HelperLib.getLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN_EXPIRE_SERVER_TIME);
                    if (tokenIniServerTimeStr && tokenExpServerTimeStr) {
                        const iniServerTime: number = parseInt(tokenIniServerTimeStr);
                        const expServerTime: number = parseInt(tokenExpServerTimeStr);
                        const diffTokenTime: number = expServerTime - iniServerTime;
                        const diffNowTime: number = nowTimeUtc - iniServerTime;
                        const usage: number = diffNowTime / diffTokenTime;
                        Logger.logInfo(this._apiName, 'checkTokenReliability', 'now = ' + nowTimeUtc + ', ratio = ' + diffNowTime + '/' + diffTokenTime + ' = ' + usage);
                        
                        if (usage < 0.6) {
                            return observableOf({
                                originalToken: originalToken,
                                renewToken: null,
                                needLogout: false
                            });
                        }
                        else if (usage < 1) {
                            //need refresh token
                            Logger.logInfo(this._apiName, 'checkTokenReliability', 'Refresh token');
                            APIBaseManager.isTokenRefreshing = true;
                            return this.http.post<IAPIRx<{ token: string }>>(this.API_PROTOCOL + "://" + this.API_HOST + ':' + this.API_PORT + '/' + this.API_NAME + '/' + this.API_VERSION + '/tokens/refresh', null, this.getRequestOption(originalToken)).pipe(
                                map((refreshTokenRes: HttpResponse<IAPIRx<{ token: string }>>) => {
                                    APIBaseManager.isTokenRefreshing = false;
                                    if (refreshTokenRes.body && refreshTokenRes.body.error === 0 && refreshTokenRes.body.data) {
                                        if (originalToken !== refreshTokenRes.body.data.token) {
                                            Logger.logInfo(this._apiName, 'checkTokenReliability', 'Update with new token');
                                            const accountInfo = HelperLib.parseToken(refreshTokenRes.body.data.token);
                                            if (accountInfo) {
                                                APIBaseManager.USER_TOKEN = refreshTokenRes.body.data.token;
                                                HelperLib.setLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN, refreshTokenRes.body.data.token);
                                                HelperLib.setLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN_INIT_SERVER_TIME, accountInfo.iat.toString());
                                                HelperLib.setLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN_EXPIRE_SERVER_TIME, accountInfo.exp.toString());
                                            }
                                        }

                                        return {
                                            originalToken: originalToken,
                                            renewToken: refreshTokenRes.body.data.token,
                                            needLogout: false
                                        };
                                    }

                                    Logger.logError(this._apiName, 'checkTokenReliability', 'Update with refresh token failed.', refreshTokenRes.body);
                                    return {
                                        originalToken: originalToken,
                                        renewToken: null,
                                        needLogout: true
                                    };
                                })
                            );
                        }
                        else {
                            Logger.logError(this._apiName, 'checkTokenReliability', 'Usage >= 1');
                            return observableOf({
                                originalToken: originalToken,
                                renewToken: null,
                                needLogout: true
                            });
                        }
                    }
                }

                Logger.logError(this._apiName, 'checkTokenReliability', 'Do not have account info');
                return observableOf({
                    originalToken: originalToken,
                    renewToken: null,
                    needLogout: true
                });
            })
        );
    }
}