import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { AppStartOverlayInfo } from '../../content/device/data/device-info';
import { ConstantService } from '../../lib/common/constant.service';
import { IUIElement } from '../uiElement.interface';
import { TriState } from '../../../app/lib/common/common.data';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    selector: 'na-overlay-qrcode',
    templateUrl: './overlay-qrcode.component.html',
    styleUrls: ['../uiElement.style.css', './overlay-qrcode.component.css']
})
export class OverlayQRCodeComponent extends AutoUnsubscribeComponent implements OnInit, IUIElement {
    _info: AppStartOverlayInfo;
    _enableState: TriState = TriState.Undetermin;

    _config: { [name: string]: { value: any } };
    @Input('config')
    set config(d: { [name: string]: { value: any } }) {
        this._config = d;
    }

    _keepSupport: boolean = false;
    @Input('keepSupport')
    set keepSupport(d: boolean) {
        this._keepSupport = d;
    }

    _disabled: boolean = false;
    @Input('disabled')
    set disabled(d: boolean) {
        this._disabled = d;
    }

    _unsupportReason: string;
    @Input('unsupportReason')
    set unsupportReason(v: string) {
        this._unsupportReason = v;
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(d: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = d;
    }

    private _overlayUrlRef: ElementRef;
    @ViewChild('overlayUrl')
    set overlayUrl(v: ElementRef) {
        if (!this._overlayUrlRef && v) {
            this._overlayUrlRef = v;

            fromEvent(this._overlayUrlRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._info.data = e.target.value;
                this.updateOverlayChange();
            });
        }
    }

    private _overlayLandscapeWidthRef: ElementRef;
    @ViewChild('overlayLandscapeWidth')
    set overlayLandscapeWidth(v: ElementRef) {
        if (!this._overlayLandscapeWidthRef && v) {
            this._overlayLandscapeWidthRef = v;

            fromEvent(this._overlayLandscapeWidthRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._info.landscape.width = e.target.value;
                this.updateOverlayChange();
            });
        }
    }

    private _overlayLandscapeHeightRef: ElementRef;
    @ViewChild('overlayLandscapeHeight')
    set overlayLandscapeHeight(v: ElementRef) {
        if (!this._overlayLandscapeHeightRef && v) {
            this._overlayLandscapeHeightRef = v;

            fromEvent(this._overlayLandscapeHeightRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._info.landscape.height = e.target.value;
                this.updateOverlayChange();
            });
        }
    }

    private _overlayLandscapeLeftRef: ElementRef;
    @ViewChild('overlayLandscapeLeft')
    set overlayLandscapeLeft(v: ElementRef) {
        if (!this._overlayLandscapeLeftRef && v) {
            this._overlayLandscapeLeftRef = v;

            fromEvent(this._overlayLandscapeLeftRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._info.landscape.left = e.target.value;
                this.updateOverlayChange();
            });
        }
    }

    private _overlayLandscapeTopRef: ElementRef;
    @ViewChild('overlayLandscapeTop')
    set overlayLandscapeTop(v: ElementRef) {
        if (!this._overlayLandscapeTopRef && v) {
            this._overlayLandscapeTopRef = v;

            fromEvent(this._overlayLandscapeTopRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._info.landscape.top = e.target.value;
                this.updateOverlayChange();
            });
        }
    }

    private _overlayPortraitWidthRef: ElementRef;
    @ViewChild('overlayPortraitWidth')
    set overlayPortraitWidth(v: ElementRef) {
        if (!this._overlayPortraitWidthRef && v) {
            this._overlayPortraitWidthRef = v;

            fromEvent(this._overlayPortraitWidthRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._info.portrait.width = e.target.value;
                this.updateOverlayChange();
            });
        }
    }

    private _overlayPortraitHeightRef: ElementRef;
    @ViewChild('overlayPortraitHeight')
    set overlayPortraitHeight(v: ElementRef) {
        if (!this._overlayPortraitHeightRef && v) {
            this._overlayPortraitHeightRef = v;

            fromEvent(this._overlayPortraitHeightRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._info.portrait.height = e.target.value;
                this.updateOverlayChange();
            });
        }
    }

    private _overlayPortraitLeftRef: ElementRef;
    @ViewChild('overlayPortraitLeft')
    set overlayPortraitLeft(v: ElementRef) {
        if (!this._overlayPortraitLeftRef && v) {
            this._overlayPortraitLeftRef = v;

            fromEvent(this._overlayPortraitLeftRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._info.portrait.left = e.target.value;
                this.updateOverlayChange();
            });
        }
    }

    private _overlayPortraitTopRef: ElementRef;
    @ViewChild('overlayPortraitTop')
    set overlayPortraitTop(v: ElementRef) {
        if (!this._overlayPortraitTopRef && v) {
            this._overlayPortraitTopRef = v;

            fromEvent(this._overlayPortraitTopRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._info.portrait.top = e.target.value;
                this.updateOverlayChange();
            });
        }
    }

    @Output() onOverlayQRCodeChanged = new EventEmitter<AppStartOverlayInfo>();

    constructor(private constantSvc: ConstantService) {
        super();
    }

    ngOnInit(): void {
        this._info = this._config[this.constantSvc.DEVKEY_APPSTART_OVERLAY].value;
        if (this._info) {
            this._enableState = this._info.keep ? TriState.Undetermin : (this._info.enabled ? TriState.Positive : TriState.Negative);
        }
    }

    onQRCodeTriToggleChanged(state: TriState): void {
        if (state === TriState.Undetermin) {
            this._info.keep = true;
        }
        else {
            this._info.keep = false;
            this._info.enabled = state === TriState.Positive ? true : false;
        }
        this.updateOverlayChange();
    }

    enableQRCode(checked: boolean): void {
        if (this._info.enabled !== checked) {
            this._info.enabled = checked;
            this.updateOverlayChange();
        }
    }

    enableLandscape(checked: boolean): void {
        if (this._info.landscape.enabled !== checked) {
            this._info.landscape.enabled = checked;
            this.updateOverlayChange();
        }
    }

    enablePortrait(checked: boolean): void {
        if (this._info.portrait.enabled !== checked) {
            this._info.portrait.enabled = checked;
            this.updateOverlayChange();
        }
    }

    updateOverlayChange(): void {
        this.onOverlayQRCodeChanged.emit(this._info);
    }
}