<div class="user-block">
    <div *ngIf="!_loading; else templateLoadingPolicy">
        <div *ngIf="_loadErrorMessage; else templateLoadPolicyPass">
            <strong>Error message : </strong>
            <div class="warning-block">
                {{ _loadErrorMessage }}
            </div>
        </div>
        <ng-template #templateLoadPolicyPass>
            <div class="ctrl-bar">
                <h5>Users with role <strong class="red-1">
                        <{{ _role?.name }}>
                    </strong>
                </h5>
            </div>
            <div class="d-flex">
                <div class="input-group searchbar" style="flex:0.5">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="input-search-user">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                    <input #search class="form-control form-control-sm" type='text' name='search' [disabled]="_loading"
                        placeholder='Search by email' aria-label="Search user" aria-describedby="input-search-user">
                </div>
                <button *ngIf="supportUpdateUser() && _userList.length > 0" type="button"
                    class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-auto" [class.active]="_isInEdit"
                    (click)="startEdit()">
                    <i class="fas fa-pen mr-1"></i>
                    Edit
                </button>
            </div>
            <div class="list-group mt-1">
                <div class="list-group-item list-group-header black">
                    <div class="row">
                        <div class="col title text-break">
                            Email
                        </div>
                        <div class="col title text-break">
                            User group
                        </div>
                        <div class="col title text-break">
                            Edit role
                        </div>
                    </div>
                </div>
                <div *ngIf="_userList.length > 0; else templateNoUser" class="list-group-content list-group-hoverless">
                    <ng-template ngFor let-user [ngForOf]="_displayUserList">
                        <div class="list-group-item">
                            <div class="row">
                                <div class="col text-break">
                                    {{ user.email }}
                                </div>
                                <div class="col text-break">
                                    {{ getCurrentUserGroupName(user.userGroupID) }}
                                </div>
                                <div class="col">
                                    <div *ngIf="!_isInEdit; else templateInEditPolicy">
                                        {{ user.userRole }}
                                    </div>
                                    <ng-template #templateInEditPolicy>
                                        <div class="position-relative">
                                            <button id="dropdownUserRoleLink" type="button"
                                                class="btn btn-sm btn-action btn-light dropdown-toggle"
                                                [disabled]="_page === _enumPage.submit" data-toggle="dropdown">
                                                {{ _editUserRoleMap[user.id]?.name }}
                                            </button>
                                            <ul class="dropdown-menu option-list w-100" data-backdrop="static"
                                                data-keyboard="false" aria-labelledby="dropdownUserRoleLink">
                                                <ng-template ngFor let-role [ngForOf]="_availableUserRoleList">
                                                    <li class="dropdown-item"
                                                        [class.active]="_editUserRoleMap[user.id]?.origin.userRole === role.name"
                                                        (click)="changeUserRole(user, role)">
                                                        <span class="ml-2">
                                                            {{ role.name | capitalFirstWord }}
                                                        </span>
                                                    </li>
                                                </ng-template>
                                            </ul>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <ng-template #templateNoUser>
                    <div class="mt-4 text-center">No users under this role.</div>
                </ng-template>
            </div>
            <div *ngIf="_page === _enumPage.result" class="mt-2">
                <div *ngIf="_editErrorMessage" class="block block-error">
                    <i class="fas fa-exclamation-circle"></i>
                    <span class="ml-2">Edit user(s) failed :</span>
                    <div>{{ _editErrorMessage }}</div>
                </div>
            </div>
            <div *ngIf="_isInEdit" class="mt-2 d-flex align-items-center">
                <button type="button" class="btn btn-sm btn-action btn-light ml-auto"
                    [disabled]="_page === _enumPage.submit" (click)="cancelEdit()">
                    <span class="fas fa-times-circle"></span>
                    Cancel
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light ml-2"
                    [disabled]="_page === _enumPage.submit" (click)="confirmEdit()">
                    <span class="fas fa-check"></span>
                    Apply
                </button>
                <div *ngIf="_page === _enumPage.submit" class="loading loading-p0 ml-4">
                    <div class="loading-box"></div>
                </div>
            </div>
        </ng-template>
    </div>
    <ng-template #templateLoadingPolicy>
        <div class="loading">
            <div class="loading-box"></div>
        </div>
    </ng-template>
</div>