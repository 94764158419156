import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { of} from 'rxjs';
import { catchError, concatMap, takeUntil, map } from 'rxjs/operators';
import { IUserDlgFuncContent } from '../user-dlg-func.def';
import { UserInfo } from '../../user.data';
import { UserService } from '../../user.service';
import { UserGroupInfo } from '../../group/user-group.data';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    templateUrl: './user-dlg-invite-content.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserInviteDlgContentComponent extends AutoUnsubscribeComponent implements IUserDlgFuncContent<UserInfo[], { user?: UserInfo, domain?: string, message?: string, isFormValid?: boolean }>, OnInit {
    funcName: string;
    data: UserInfo[];
    other?: { user?: UserInfo, domain?: string, message?: string, isFormValid?: boolean } = {};
    onActionPrepared: () => void;
    onActionValidChanged: (valid: boolean) => void;

    _currentUserGroupName: string;
    _availableUserRoleList: { name: string, desc: string }[] = [];
    _availableUserGroupList: UserGroupInfo[] = [];
    _availableDomainList: string[] = [];
    _isReInvite: boolean = false;

    private _isFormValid: boolean = false;
    private _inviteForm: NgForm;
    @ViewChild('inviteUserForm', { static: true })
    set inviteUserForm(f: NgForm) {
        this._inviteForm = f;
    }
    
    constructor(private userSvc: UserService) {
        super();
    }

    ngOnInit(): void {
        this._inviteForm.statusChanges.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((status: string) => {
            this._isFormValid = status === 'VALID';
            this.updateDataValidity();
        });

        this._isReInvite = this.other.user.id ? true : false;

        this.userSvc.getEnterpriseInfo().pipe(
            map((res: { data: { domainList: string[], licenses: { [roleName: string]: { limit: number, usage: number } } }, isFault: boolean, errorMessage?: string }) => {
                if (res.isFault) {
                    throw res.errorMessage;
                }

                this._availableDomainList = res.data.domainList;
                return true;
            }),
            concatMap(() => {
                return this.other.user.id 
                ? this.userSvc.getUserByID(this.other.user.id).pipe(
                    map((res: { user: UserInfo, isFault: boolean, errorMessage?: string }) => {
                        if (res.isFault) {
                            throw res.errorMessage
                        }

                        this.other.user.firstName = res.user.firstName;
                        this.other.user.lastName = res.user.lastName;
                        this.other.user.company = res.user.company;
                        this.other.user.department = res.user.department;

                        return {
                            isFault: false
                        };
                    })
                )
                : this.userSvc.getUserRoleList().pipe(
                    concatMap((res: { userRoleList: { name: string, desc: string }[], isFault: boolean, errorMessage?: string }) => {
                        if (res.isFault) {
                            throw res.errorMessage;
                        }
        
                        this._availableUserRoleList = res.userRoleList;
        
                        return this.userSvc.getUserGroupList();
                    }),
                    map((res: { userGroupList: UserGroupInfo[], isFault: boolean, errorMessage?: string }) => {
                        if (res.isFault) {
                            throw res.errorMessage;
                        }
        
                        this._availableUserGroupList = res.userGroupList;
        
                        return {
                            isFault: false
                        }
                    })
                )
            }),
            catchError((err: any) => {
                return of({
                    isFault: true,
                    errorMessage: err.toString()
                })
            })
        ).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            if (!res.isFault) {
                this.updateCurrentUserGroupName();
                if (!this._isReInvite && this._availableDomainList.length > 0) {
                    this.changeUserDomain(this._availableDomainList[0]);
                }
            }
            this.onActionPrepared();
        });

        this.updateDataValidity();
    }

    changeUserDomain(domain: string): void {
        this.other.domain = domain;
    }

    changeUserRole(role: { name: string, desc: string }): void {
        this.other.user.userRole = role.name;
        this.updateDataValidity();
    }

    changeUserGroup(group: { id: string, name: string }): void {
        this.other.user.userGroupID = group.id;
        this.updateDataValidity();
        this._currentUserGroupName = group.name;
    }

    private updateCurrentUserGroupName(): void {
        const currentUserGroup: UserGroupInfo = this.userSvc.getCurrentUserGroupByID(this.other.user.id);
        if (currentUserGroup) {
            this._currentUserGroupName = currentUserGroup.name;
        }
    }

    private updateDataValidity(): void {
        this.onActionValidChanged(this._isReInvite || (this._isFormValid && this.other.user.userGroupID && this.other.user.userRole ? true : false));
    }
}