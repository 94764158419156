<label for="config-timeserver" class="title">
  Time server
  <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
    [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
</label>
<div *ngIf="_unsupportReason" class="warning-block mb-1">{{ _unsupportReason }}</div>
<div [ngClass]="_showBorder? 'showBorder' : ''">
  <div class='switch-container'>
    <input id="cmn-toggle-timeserver" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
      [disabled]="_lockInfo || _disabled || _unsupportReason" [checked]="_timeserverInfo.enabled ? 'checked': null"
      (change)="turnOnOff($event.target.checked)">
    <label for="cmn-toggle-timeserver">
      <span class="sr-only">Enable time server</span>
    </label>
  </div>
  <fieldset class="mt-2">
    <legend class="sr-only">Time server options</legend>
    <div>
      <div class="cmn-radio">
        <input id="config-timeserver-default" type="radio" name="radiobtn-option"
          [disabled]="_lockInfo || _disabled || _unsupportReason || !_timeserverInfo.enabled"
          [checked]="_timeserverInfo.isOptionEnabled(_enumTimeserverOption.default)"
          (click)="changeTimeserverOption(_enumTimeserverOption.default)">
        <label for="config-timeserver-default" class="radio"></label>
        <span class="ml-2">Default</span>
      </div>
      <div class="cmn-radio mt-1">
        <input id="config-timeserver-content" type="radio" name="radiobtn-option"
          [disabled]="_lockInfo || _disabled || _unsupportReason || !_timeserverInfo.enabled"
          [checked]="_timeserverInfo.isOptionEnabled(_enumTimeserverOption.content)"
          (click)="changeTimeserverOption(_enumTimeserverOption.content)">
        <label for="config-timeserver-content" class="radio"></label>
        <span class="ml-2">Use AppStart URL as time server</span>
      </div>
      <div class="mt-1">
        <div class="cmn-radio">
          <input id="config-timeserver-custom" type="radio" name="radiobtn-option"
            [disabled]="_lockInfo || _disabled || _unsupportReason || !_timeserverInfo.enabled"
            [checked]="_timeserverInfo.isOptionEnabled(_enumTimeserverOption.customize)"
            (click)="changeTimeserverOption(_enumTimeserverOption.customize)">
          <label for="config-timeserver-custom" class="radio"></label>
          <span class="ml-2">Customized</span>
        </div>
        <div class="form-group ml-4 mt-1">
          <input #customUrl type="text" class="form-control form-control-sm"
            aria-label="custom timeserver sync location" [value]="_timeserverInfo.url"
            [disabled]="_lockInfo || _disabled || _unsupportReason || !_timeserverInfo.enabled || !_timeserverInfo.isOptionEnabled(_enumTimeserverOption.customize)"
            [class.invalid]="_timeserverInfo.isOptionEnabled(_enumTimeserverOption.customize) && !_timeserverInfo.url"
            placeholder="ntp://pool.ntp.org">
        </div>
      </div>
    </div>
  </fieldset>
</div>