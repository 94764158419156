import { Injectable } from '@angular/core';

import { AlertConfigDlgEditComponent } from './alertConfig-dlg-edit.component';
import { AlertConfigDlgRemoveComponent } from './alertConfig-dlg-remove.component';
import { AlertFuncItem } from './alert-func.def';

export const ALERT_RULE_FUNC_EDIT: string = 'alert-edit';
export const ALERT_RULE_FUNC_NEW: string = 'alert-new';
export const ALERT_RULE_FUNC_REMOVE: string = 'alert-remove';

@Injectable()
export class AlertFuncService {
  private _items: AlertFuncItem[] = [];

  constructor() {
    this._items = [
      new AlertFuncItem(AlertConfigDlgEditComponent, ALERT_RULE_FUNC_NEW, 'Create alert'),
      new AlertFuncItem(AlertConfigDlgEditComponent, ALERT_RULE_FUNC_EDIT, 'Edit alert setting'),
      new AlertFuncItem(AlertConfigDlgRemoveComponent, ALERT_RULE_FUNC_REMOVE, 'Remove alert')
    ];
  }

  getAllItems(): AlertFuncItem[] {
    return this._items;
  }

  getItemByName(name: string): AlertFuncItem {
    return this._items.find(i => i.name === name);
  }
}
