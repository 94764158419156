<div>
  <label for="config-appstart" class="title">
    Maintenance playback
    <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
      [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
  </label>
  <div *ngIf="_unsupportReason" class="warning-block mb-1">{{ _unsupportReason }}</div>
  <div class='switch-container'>
    <input id="cmn-toggle-maintenance" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
      [checked]="_info.enabled" [disabled]="_lockInfo || _disabled || _unsupportReason"
      (change)="enableMaintenance($event.target.checked)">
    <label for="cmn-toggle-maintenance">
      <span class="sr-only">Enable maintenance playback</span>
    </label>
  </div>
  <ul>
    <li>
      Display maintenance message from
      <div class="btn-group ml-2">
        <button id="dropdownMaintenanceTimeFromLink" type="button"
          class="btn btn-sm btn-action btn-light dropdown-toggle"
          [class.invalid]="!_info.isPlaybackTimePeriodScheduleValid()"
          [disabled]="_lockInfo || _disabled || _unsupportReason || !_info.enabled" data-toggle="dropdown">
          {{ _info.beginTime }}
          <span class="sr-only">modify maintenance playback begin time</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMaintenanceTimeFromLink"
          style="min-width:300px">
          <div class="mx-2">
            <na-timePicker [time]="_info.beginTime" [minuteIncre]="1" [showBorder]="false" [showSelectionTime]="false"
              (onTimeChanged)="changeBeginTime($event)">
            </na-timePicker>
          </div>
        </ul>
      </div>
      <span class="ml-2">to</span>
      <div class="btn-group ml-2">
        <button id="dropdownMaintenanceTimeToLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
          [class.invalid]="!_info.isPlaybackTimePeriodScheduleValid()"
          [disabled]="_lockInfo || _disabled || _unsupportReason || !_info.enabled" data-toggle="dropdown">
          {{ _info.endTime }}
          <span class="sr-only">modify maintenance playback end time</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMaintenanceTimeToLink"
          style="min-width:300px">
          <div class="mx-2">
            <na-timePicker [time]="_info.endTime" [minuteIncre]="1" [showBorder]="false" [showSelectionTime]="false"
              (onTimeChanged)="changeEndTime($event)">
            </na-timePicker>
          </div>
        </ul>
      </div>
      <div>
        <div>
          <div class="cmn-radio">
            <input id="mt-radio-period" type="radio" name="mt-radio-option"
              [disabled]="_lockInfo || _disabled || _unsupportReason || !_info.enabled"
              [checked]="_info.scheduleMode === _enumMaintenanceScheduleMode.period"
              (click)="changeScheduleMode(_enumMaintenanceScheduleMode.period)">
            <label for="mt-radio-period" class="radio"></label>
            <span class="ml-2">Specific period</span>
          </div>
          <div class="gray-block">
            <span>From</span>
            <input type="date" class="ml-2" [value]="_info.beginDate" aria-label="Maintenance begin period"
              [disabled]="_lockInfo || _disabled || _unsupportReason || !_info.enabled || _info.scheduleMode !== _enumMaintenanceScheduleMode.period"
              [class.invalid]="!_info.isPlaybackDatePeriodScheduleValid()"
              (change)="changePeriodBeginDate($event.target.value)">
            <span class="ml-2">to</span>
            <input type="date" class="ml-2" [value]="_info.endDate" aria-label="Maintenance end period"
              [disabled]="_lockInfo || _disabled || _unsupportReason || !_info.enabled || _info.scheduleMode !== _enumMaintenanceScheduleMode.period"
              [class.invalid]="!_info.isPlaybackDatePeriodScheduleValid()"
              (change)="changePeriodEndDate($event.target.value)">
          </div>
        </div>
        <div class="cmn-radio">
          <input id="mt-radio-always" type="radio" name="mt-radio-option"
            [disabled]="_lockInfo || _disabled || _unsupportReason || !_info.enabled"
            [checked]="_info.scheduleMode === _enumMaintenanceScheduleMode.always"
            (click)="changeScheduleMode(_enumMaintenanceScheduleMode.always)">
          <label for="mt-radio-always" class="radio"></label>
          <span class="ml-2">Repeat until canceled</span>
        </div>
      </div>
    </li>
    <li class="mt-2">
      <div class="form-group">
        <label for="mt-url">Maintenance content URL</label>
        <input #maintenanceURL id="mt-url" type="text" class="form-control form-control-sm"
          [disabled]="_lockInfo || _disabled || _unsupportReason || !_info.enabled" [value]="_info.url"
          [class.invalid]="_info.enabled && !_info.url">
      </div>
    </li>
  </ul>
</div>