import { Component, OnInit } from "@angular/core";
import { CustomizationItemInfo } from "app/content/admin/customization/customization.data";
import { PluginCalendarInfo } from "../../plugin.data";
import { catchError, concatMap } from "rxjs/operators";
import { of } from "rxjs";
import { WorkplacePluginService } from "../../plugin.service";
import { Router } from "@angular/router";
import { PluginHelper } from "../../plugin.helper";

@Component({
    templateUrl: './svcnow-calendar-config.component.html',
    styleUrls: ['./svcnow.style.css']
})
export class ServiceNowCalendarConfigComponent implements OnInit {
    _appname: string;
    _data: PluginCalendarInfo;
    _errorMessage: string;
    _loading: boolean = false;
    _updating: boolean = false;
    _fontsizeOptionList: { id: string, ratio: number, displayName: string }[] = [
        { id: 'fs080', ratio: 0.8, displayName: 'Small' },
        { id: 'fs100', ratio: 1, displayName: 'Median' },
        { id: 'fs120', ratio: 1.2, displayName: 'Large' }
    ];

    constructor(private router: Router, private pluginSvc: WorkplacePluginService) { 

    }

    ngOnInit(): void {
        this._appname = PluginHelper.getPluginAppCategory(this.router.url);

        this._loading = true;
        this.pluginSvc.getCalendarConfig(this._appname).subscribe((res: { isFault: boolean, errorCode?: number, errorMessage?: string, data?: PluginCalendarInfo }) => {
            console.log('[calendarConfig] get: ', res);
            this._loading = false;
            if (res.isFault) {
                this._errorMessage = res.errorMessage;
                return;
            }

            this._data = res.data;
        });
    }

    resetCalendarInfo(): void {
        this._data.reset();
    }

    updateCalendarInfo(): void {
        this._updating = true;
        this._errorMessage = null;

        this.pluginSvc.updateCalendarConfig(this._appname, this._data.export()).pipe(
            concatMap((res: { isFault: boolean, errorMessage?: string, data?: PluginCalendarInfo }) => {
                if (res.isFault) {
                    throw res.errorMessage;
                }

                let isImageChanged: boolean = this._data.background.isChanged || this._data.logo.isChanged ? true : false;
                const files: { file: File, fieldname: string, filename: string }[] = [];
                if (this._data.background.isChanged) {
                    files.push({ file: this._data.background.raw, filename: this._data.background.name, fieldname: 'bg' });
                }
                if (this._data.logo.isChanged) {
                    files.push({ file: this._data.logo.raw, filename: this._data.logo.name, fieldname: 'logo' });
                }

                return isImageChanged ? this.pluginSvc.updateCalendarImage(this._appname, files) : of(true);
            }),
            concatMap(() => this.pluginSvc.getCalendarConfig(this._appname, true)),
            catchError((err) => {
                this._errorMessage = err;
                return of(err);
            })
        ).subscribe((res: { isFault: boolean, errorCode?: number, errorMessage?: string, data?: PluginCalendarInfo }) => {
            this._updating = false;

            if (res.isFault) {
                this._errorMessage = res.errorMessage;
                return;
            }

            this._data = res.data;
        });
    }

    onLogoImageChanged(item: CustomizationItemInfo): void {
        this._data.logo = item;
    }

    onBackgroundImageChanged(item: CustomizationItemInfo): void {
        this._data.background = item;
    }
}