import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CustomPipeModule } from "../../../../app/lib/pipes/custom-pipe.module";
import { TranslateModule } from "../../../../app/translate/translate.module";
import { UIElementModule } from "../../../../app/uiElement/uiElement.module";
import { DeviceRemoteControlComponent } from "./dev-remote-ctrl.component";
import { RemoteDlgTakeScreenshotComponent } from "./dlg/remote-dlg-takeScreenshot.component";
import { RemoteFuncDirective } from "./dlg/remote-func.directive";
import { RemoteDlgSendMsgComponent } from "./dlg/remote-dlg-sendMsg.component";
import { RemoteFuncService } from "./dlg/remote-func.service";
import { RemoteDlgIdleRemindComponent } from "./dlg/remote-dlg-idleRemind.component";
import { RemoteDlgNotificationComponent } from "./dlg/remote-dlg-notification.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        CustomPipeModule,
        TranslateModule,
        UIElementModule
    ],
    providers: [
        RemoteFuncService
    ],
    declarations: [
        RemoteFuncDirective,
        RemoteDlgTakeScreenshotComponent,
        RemoteDlgSendMsgComponent,
        RemoteDlgIdleRemindComponent,
        RemoteDlgNotificationComponent,
        DeviceRemoteControlComponent
    ],
    exports: [

    ]
})
export class RemoteModule { }