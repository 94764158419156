<div *ngIf="Enable_THEME" class="theme-block">
    <div class="btn-group ml-auto">
        <button id="dropdownThemeLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
            Theme : <strong>{{ _theme?.name }}</strong>
        </button>
        <ul class="dropdown-menu p-0 w-100" aria-labelledby="dropdownThemeLink">
            <li *ngFor="let theme of _themeList" class="dropdown-item" (click)="changeTheme(theme)">
                <div>{{ theme.name | capitalFirstWord }}</div>
                <div class="color-indicator-block">
                    <span *ngFor="let mainColor of theme.mainColorList" [style.background-color]="mainColor"></span>
                </div>
            </li>
        </ul>
    </div>
</div>
<ng-template login-company-host></ng-template>