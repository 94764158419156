<div class="tri-toggle-outer" [class.disabled]="_disabled" [class.off]="_currentState === _enumTriState.Negative"
    [class.keep]="_currentState === _enumTriState.Undetermin" [class.on]="_currentState === _enumTriState.Positive">
    <div class="tri-toggle-inner">
        <div class="toggle" [class.checked]="_dataMap[_enumTriState.Negative].checked" (click)="clickNegative()">
            {{ _dataMap[_enumTriState.Negative].displayName }}
        </div>
        <div class="line"></div>
        <div class="toggle" [class.checked]="_dataMap[_enumTriState.Undetermin].checked" (click)="clickUndetermin()">
            {{ _dataMap[_enumTriState.Undetermin].displayName }}
        </div>
        <div class="line"></div>
        <div class="toggle" [class.checked]="_dataMap[_enumTriState.Positive].checked" (click)="clickPositive()">
            {{ _dataMap[_enumTriState.Positive].displayName }}
        </div>
    </div>
</div>