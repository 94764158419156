export class CustomizationItemInfo {
    raw?: File;
    urlBefore: string;
    urlAfter: string;
    name: string
    size: number;

    isChanged(): boolean {
        return this.raw && this.urlAfter !== this.urlBefore;
    }

    reset(urlBefore?: string): void {
        this.raw = null;
        this.urlBefore = urlBefore || this.urlBefore;
        this.urlAfter = urlBefore || this.urlBefore;
        this.name = '';
        this.size = 0;
    }
}