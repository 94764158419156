import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ReportInfo } from '../report.data';
import { ReportService } from '../report.service';

@Component({
    templateUrl: './report-overview.component.html',
    styleUrls: ['./report-overview.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ReportOverviewComponent implements OnInit {
    _reportList: ReportInfo[] = [];
    _report: ReportInfo;

    _isLoading: boolean;
    _currentPage: number = 1;
    _total: number;
    _errorMessage: string;
    _showReportDetail: boolean = false;

    constructor(private reportSvc: ReportService) {}

    ngOnInit(): void {
        this.getReportList();
    }

    private getReportList(force: boolean = false): void {
        this._isLoading = true;
        this.reportSvc.getReports(force, this._currentPage - 1).subscribe((res: { data: ReportInfo[], page?: number, total?: number, limit?: number, waiting: boolean, errorMessage?: string }) => {
            if (!res.errorMessage) {
                this._reportList = res.data;
                this._total = res.total;
            }

            this._isLoading = false;
        });
    }

    viewReport(report: any): void {
        this._report = report;
        this._showReportDetail = true;
    }

    closeReport(): void {
        this._report = null;
        this._showReportDetail = false;
    }

    onPageChange(page: number): void {
        if (this._currentPage !== page) {
            this._currentPage = page;
            this.getReportList();
        }
    }
}