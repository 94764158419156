import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
    selector: 'na-pager',
    templateUrl: './pager.component.html',
    styleUrls: ['./pager.component.css']
})
export class PagerComponent implements OnInit {
    private readonly DISPLAY_RANGE: number = 3;
    private readonly PAGE_EXPRESS_LIMIT: number = 10;

    _showDropdown: boolean = false;
    _showMoreL: boolean = false;
    _showMoreR: boolean = false;
    _pageIndexes: number[] = [];

    private _numberInPage: number = 50;
    @Input('numberInPage')
    set numberInPage(n: number) {
        this._numberInPage = n;
        const pageAmount = Math.ceil(this._total / this._numberInPage);
        this.changePageAmount(pageAmount);
    }

    _currentPage: number = 1;
    @Input('currentPage')
    set currentPage(page: number) {
        if (this._currentPage !== page) {
            this._currentPage = page;
            this.updateShowMoreHint();
        }
    }

    private _total: number = 0;
    @Input('total')
    set total(total: number) {
        this._total = total;
        const pageAmount = Math.ceil(total / this._numberInPage);
        this.changePageAmount(pageAmount);
    }

    @Output() onPageChanged = new EventEmitter<number>();

    ngOnInit(): void { }

    private show(page): boolean {
        return Math.abs(page - this._currentPage) < this.DISPLAY_RANGE ? true : false;
    }

    private updateShowMoreHint(): void {
        this._showMoreR = this._pageIndexes.length - this._currentPage > this.DISPLAY_RANGE ? true : false;
        this._showMoreL = this._currentPage > this.DISPLAY_RANGE ? true : false;
    }

    private changePageAmount(newPageAmount: number): void {
        this._showDropdown = newPageAmount > this.PAGE_EXPRESS_LIMIT ? true : false;

        const currentPageAmount = this._pageIndexes.length;
        if (currentPageAmount != newPageAmount) {
            if (newPageAmount > currentPageAmount) {
                for (let i = currentPageAmount + 1; i <= newPageAmount; ++i) {
                    this._pageIndexes.push(i);
                }
            }
            else {
                this._pageIndexes.splice(newPageAmount, currentPageAmount - newPageAmount);
            }

            if (this._currentPage > newPageAmount && newPageAmount > 0) {
                this.goToPage(newPageAmount - 1 > 0 ? newPageAmount - 1 : 1);
            }
        }

        this.updateShowMoreHint();
    }

    goToPage(page: number): void {
        if (this._currentPage != page && (page <= this._pageIndexes.length && page > 0)) {
            this._currentPage = page;
            this.updateShowMoreHint();
            this.onPageChanged.next(this._currentPage);
        }
    }

}