import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DeviceLicenseComponent } from './device-license.component';
import { LicenseService } from './license.service';
import { LicenseDlgModule } from './dlg/license-dlg.module';

import { UIElementModule } from '../../uiElement/uiElement.module';
import { TranslateModule } from '../../translate/translate.module';
import { CustomPipeModule } from '../../lib/pipes/custom-pipe.module';
import { AccountLicenseComponent } from './account-license.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,

        UIElementModule,
        TranslateModule,
        CustomPipeModule,

        LicenseDlgModule
    ],
    declarations: [
        DeviceLicenseComponent,
        AccountLicenseComponent
    ],
    providers: [
        LicenseService
    ],
    exports: [
    ]
})
export class LicenseModule { }