import { Injectable, EventEmitter, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { of, Subject, forkJoin, Observable, EMPTY, Subscription, interval } from 'rxjs';
import { delay, concatMap, catchError, map, takeUntil, expand, concatAll, buffer, filter, scan, reduce } from 'rxjs/operators';

import { DeviceInfo, AvailableOptionInfo, OnlineStatus, DeviceActionInfo, SSIDInfo, TaskStatus, AppStartInfo, AppStartOverlayInfo, DeviceCacheType, AppStartScreensaverInfo, IDevicePairStatusChangeEventArgs, IDeviceUnpairStatusChangeEventArgs, IPolicyLockMap } from './data/device-info';
import { AccountService } from '../../entry/account.service';
import { NAService } from '../../API/na.service';
import { IAPIRx } from '../../API/api.base';
import { Logger } from '../../lib/common/logger';
import { IShadowDeviceRxData, IVirtualDeviceCalendarItem, IVirtualDeviceRxData } from '../../API/v1/VirtualDevice/virtualDevice.common';
import { CustomResponse, IClass } from '../../lib/common/common.data';
import { HelperLib, REFRESH_DURATION } from '../../lib/common/helper.lib';
import { IGetVirtualDeviceAvailableOptionRxData } from '../../API/v1/VirtualDevice/api.virtualDevice.availableOption.get';
import { ITaskData } from '../../API/v1/Task/api.task.common';
import { AppConfigService } from '../../app.config';
import { ConstantService } from '../../../app/lib/common/constant.service';
import { VirtualDeviceStatusHint } from './data/virtual-device-info';
import { ISetVirtualDeviceCalendarTxData } from '../../API/v1/VirtualDevice/api.virtualDevice.calendar.set';
import { IRemoveVirtualDeviceCalendarQueryParameter } from '../../API/v1/VirtualDevice/api.virtualDevice.calendar.remove';
import { LockScreenInfo, ScreenOffInfo, ScreenSaverInfo } from '../../uiElement/schedule/screen/screen.data';
import { MaintenancePlaybackInfo } from '../../uiElement/maintenancePlayback/mtPlayback.data';
import { IGetWarrantyRxData } from '../../API/v1/License/api.warranty.get';
import { AndroidGroupType } from '../devfunc/firmware/firmware-data';
import { IListVirtualDeviceRxData } from '../../API/v1/VirtualDevice/api.virtualDevice.list';
import { IListTaskRxData } from '../../../app/API/v1/Task/api.task.list';
import { ITaskStatusData, ITicketData } from '../../API/v1/Ticket/api.ticket.common';
import { ScepChallengeInfo, ScepServerInfo } from '../admin/scep/scep.data';
import { PolicyInfo, PolicyType } from '../setting/policy/policy.data';
import { ICreateTicketTxData } from '../../API/v1/Ticket/api.ticket.create';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LockScreenMode } from 'app/uiElement/schedule/screen/screen.data';
import { DEVICE_GROUP_ID_HOME, DeviceGroupInfo, GroupSwitch } from './group/group.data';
import { IGetVirtualDeviceAdvanceFilterRxData } from 'app/API/v1/VirtualDevice/api.virtualDevice.advanceFilter.get';
import { DeviceAdvFilterInfo, DeviceAdvFilterOptionInfo } from 'app/uiElement/dev/dev-adv-filter.data';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class DeviceService implements IClass {
    private readonly DEVICE_REFRESH_DURATION: number = REFRESH_DURATION * 60000; //30min
    private readonly BATCH_TASK_COUNT: number = 50;
    private readonly DEVICE_QUERY_LIMIT: number = AppConfigService.configs.devPage.pageLimit || 50;
    private readonly DEV_DISCONNECT_THRESHOLD: number = AppConfigService.configs.trigger.heartbeatDisconnectPeriod;
    private readonly DEV_OFFLINE_THRESHOLD: number = AppConfigService.configs.trigger.heartbeatOfflinePeriod;
    private readonly TASK_TRACK_ABILITY: number = 10;
    private readonly DEV_REQ_ATTRIBUTES: string[] = [
        'status.info',
        'status.app',
        'status.debug',
        'status.hardware',
        'status.net',
        'status.schedule',
        'status.time',
        'status.features',
        'settings.hardware',
        'status.system'
    ];

    @Output() devicesChanged = new EventEmitter<DeviceInfo[]>();
    @Output() devicePairStatusChanged = new EventEmitter<IDevicePairStatusChangeEventArgs>();
    @Output() deviceUnpairStatusChanged = new EventEmitter<IDeviceUnpairStatusChangeEventArgs>();
    @Output() deviceTaskUpdated = new EventEmitter<DeviceInfo>();
    @Output() deviceLabelUpdated = new EventEmitter<{ query?: { appended?: { labelName: string, virtualDeviceIDList: string[] }, removed?: { labelName: string, virtualDeviceIDList: string[] } }, labelNames: string[] }>();
    @Output() deviceFilterApplied = new EventEmitter<{ isApplied: boolean, devices?: DeviceInfo[], sourceFilters?: { rules?: string[], labels?: string[], onlineStatus?: { [state: string]: boolean }, search?: { key: string, value: string, langKey?: string } } }>();

    private _deviceCache: {
        [virtualDeviceID: string]: {
            [cacheName: string]: {
                data?: any,
                updating: boolean,
                lastUpdateTime: Date,
                errorMessage?: string
            }
        }
    } = {};

    private _deviceTotalAmount: number = 0;
    private _deviceMap: { [virtualDeviceID: string]: { keep: boolean, dev: DeviceInfo } } = {};
    private _deviceList: DeviceInfo[] = [];
    private _lastUpdateAdvFilterListTime: Date;
    private _labelNames: string[] = [];
    private _lastUpdateDeviceLabelTime: Date;
    private _advFilterItems: IGetVirtualDeviceAdvanceFilterRxData[] = [];
    private _currentFilter: { rules?: string[], labels?: string[], onlineStatus?: { [state: string]: boolean }, search?: { key: string, value: string, langKey?: string }, devGroup?: { id: string, switch: GroupSwitch } } = {};
    private _deviceUpdate$: Subject<{ hasNext: boolean, addedDeviceList: DeviceInfo[] }> = new Subject();

    private _lastUpdateDeviceTime: Date;
    private _updating: boolean = false;
    className: string;
    private _unsubscribe$: Subject<void> = new Subject();

    get devices(): DeviceInfo[] { return this._deviceList; }
    get isAdvanceFilterApplied(): boolean {
        return this._currentFilter?.rules?.length > 0 ||
            this._currentFilter?.labels?.length > 0 ||
            HelperLib.getOnlineStatusState(this._currentFilter?.onlineStatus).isChanged ||
            this._currentFilter?.search?.value ||
            this._currentFilter?.devGroup?.id ? true : false;
    }

    constructor(
        private router: Router,
        private naSvc: NAService,
        private accountSvc: AccountService,
        private constantSvc: ConstantService,
        private datePipe: DatePipe,
        private domSan: DomSanitizer
    ) {
        this.className = 'DevSvc';

        // reset filters when switch back to dashboard page
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((ev: NavigationEnd) => {
            if (ev.url.indexOf('app/device/devices') >= 0) {
                this.resetAdvanceFilter();
            }
        });

        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            Logger.logInfo(this.className, '', 'Login status changed. IsLogin? = ' + isLogin);

            if (!isLogin) {
                this.logout();
            }
            else {
                this._unsubscribe$ = new Subject();
                this.startAutoRefresh();
            }
        });

        this.startAutoRefresh();
    }

    private startAutoRefresh(): void {
        interval(this.DEVICE_REFRESH_DURATION).pipe(
            takeUntil(this._unsubscribe$),
        ).subscribe(() => {
            if (!this.accountSvc.token) {
                return;
            }
            this.refreshDevices(true);
        });
    }

    getDeviceName(virtualDeviceID: string): string {
        return this._deviceMap[virtualDeviceID] ? this._deviceMap[virtualDeviceID].dev.virtualName : virtualDeviceID;
    }

    getDeviceByID(virtualDeviceID: string, autoCheckSetting: boolean = true, autoCheckOption: boolean = true, forceRefreshSetting: boolean = false, forceRefreshAvailableOptions: boolean = false): Observable<CustomResponse<DeviceInfo>> {
        const device: DeviceInfo = this._deviceMap[virtualDeviceID]?.dev;
        const bRefreshSetting: boolean = forceRefreshSetting || (autoCheckSetting && (!device || Object.keys(device.applySettings).length === 0) ? true : false);
        const bRefreshAvailableOption: boolean = forceRefreshAvailableOptions || (autoCheckOption && (!device || device.availableOptions.IsEmpty) ? true : false);

        Logger.logInfo(this.className, 'getDeviceByID', 'Refresh device with (setting, options) = (' + bRefreshSetting + ', ' + bRefreshAvailableOption + ')');
        if (device && !bRefreshSetting && !bRefreshAvailableOption) {
            return of(CustomResponse.success(device));
        }

        return this.naSvc.getVirtualDevice({ virtualDeviceID: virtualDeviceID }, this.DEV_REQ_ATTRIBUTES, this.accountSvc.token).pipe(
            concatMap((res: IAPIRx<IVirtualDeviceRxData>) => {
                if (res.error !== 0 || !res.data) {
                    throw CustomResponse.fail(res.error, res.errorMessage);
                }

                let dev: DeviceInfo = device;
                dev = this.transformDevice(res.data, dev, res.serverTime);

                return of(true).pipe(
                    concatMap(() => bRefreshAvailableOption ? this.naSvc.getDeviceAvailableOptions({ virtualDeviceID: res.data.virtualDeviceID, virtualDevicePairedID: res.data.virtualDevicePairedID }, this.accountSvc.token) : of(null)),
                    map(refreshOptionRet => {
                        this.update_available_option(refreshOptionRet, dev.availableOptions);

                        return CustomResponse.success(dev);
                    })
                );
            }),
            catchError((err: CustomResponse<any>) => {
                Logger.logError(this.className, 'extract_device_by_virtualD', 'Exception = ', err);

                return of(err);
            })
        );
    }

    logout(): void {
        Logger.logInfo(this.className, 'logout', '');
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
        this._unsubscribe$ = null;

        this._deviceList = [];
        this._deviceMap = {};
        this._lastUpdateDeviceTime = null;
        this._updating = false;
        this._lastUpdateAdvFilterListTime = null;
        this._currentFilter = {};
        this._advFilterItems = [];
        this._lastUpdateDeviceLabelTime = null;
        this._labelNames = [];
    }

    private refreshDevices(notify: boolean = false): void {
        let skip: number = 0;
        const limit: number = this.DEVICE_QUERY_LIMIT;

        this._updating = true;
        this._deviceList = [];
        Object.keys(this._deviceMap).forEach(vID => this._deviceMap[vID].keep = false);

        this.listDeviceQuery(skip, limit).pipe(
            expand((res: { isFault: boolean, devices: DeviceInfo[], nextQuery: boolean, errorMessage?: string }) => {
                skip += limit;
                return res.nextQuery ? this.listDeviceQuery(skip, limit) : EMPTY;
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe((res: { isFault: boolean, devices: DeviceInfo[], nextQuery: boolean, errorMessage?: string }) => {
            if (!res.nextQuery) {
                this._lastUpdateDeviceTime = new Date();
                this._updating = false;

                if (notify) {
                    Logger.logInfo('devSvc', 'refreshDevices', 'Auto update notify');
                    this.devicesChanged.emit(this._deviceList);
                }
            }
        });
    }

    getDevicesByBatch(from: string, forceRefresh: boolean = false): Observable<{ isFault: boolean, hasNext: boolean, devices: DeviceInfo[], total: number, errorMessage?: string }> {
        return this.getDevices(from, forceRefresh, false, false);
    }

    getDevices(from: string, forceRefresh: boolean = false, waitForAll: boolean = true, bMergeResult: boolean = true): Observable<{ isFault: boolean, hasNext: boolean, devices: DeviceInfo[], total: number, errorMessage?: string }> {
        Logger.logInfo('devSvc', 'getDevices', 'getDevices from ' + from + ', updating ? ' + this._updating);

        if ((forceRefresh || this.needUpdate(this._lastUpdateDeviceTime, this.DEVICE_REFRESH_DURATION)) && !this._updating) {
            this.refreshDevices();
        }

        if (waitForAll) {
            return new Observable((observer) => {
                HelperLib.checkState(1, () => { return !this._updating }, () => {
                    observer.next({ isFault: false, hasNext: false, devices: this._deviceList, total: this._deviceTotalAmount });
                    observer.complete();
                });
            });
        }

        return new Observable((observer) => {
            //!forceRefresh && 
            if (!this._updating || this._deviceList.length > 0) {
                //return current devices
                observer.next({ isFault: false, hasNext: this._updating, devices: this._deviceList, total: this._deviceTotalAmount });
            }

            if (!this._updating) {
                observer.complete();
                return;
            }

            let requestCount: number = 0;
            const subscription: Subscription = this._deviceUpdate$.subscribe((res: { hasNext: boolean, addedDeviceList: DeviceInfo[] }) => {
                if (!requestCount++ || res.addedDeviceList.length > 0) {
                    observer.next({ isFault: false, hasNext: res.hasNext, devices: bMergeResult ? this._deviceList : res.addedDeviceList, total: this._deviceTotalAmount });
                }

                if (!res.hasNext) {
                    observer.complete();
                    subscription.unsubscribe();
                }
            })
        });
    }

    getAdvanceFilterList(forceRefresh: boolean = false): Observable<{ isFault: boolean, data: IGetVirtualDeviceAdvanceFilterRxData[], errorMessage?: string }> {
        if (forceRefresh || this._advFilterItems.length == 0 || this.needUpdate(this._lastUpdateAdvFilterListTime, this.DEVICE_REFRESH_DURATION)) {
            return this.naSvc.getVirtualDeviceAdvanceFilter(this.accountSvc.token).pipe(
                map((res: IAPIRx<IGetVirtualDeviceAdvanceFilterRxData[]>) => {
                    this._advFilterItems = res.data;
                    this._lastUpdateAdvFilterListTime = new Date();

                    return { isFault: res.error != 0, data: this._advFilterItems, errorMessage: res.errorMessage };
                })
            );
        }

        return of({ isFault: false, data: this._advFilterItems });
    }

    getAdvanceFilterOptionsByPolicy(policy: PolicyInfo, state: string = 'Not Synced'): Observable<DeviceAdvFilterOptionInfo> {
        if (!policy) {
            return of(null);
        }

        return this.getAdvanceFilterList().pipe(
            map((res: { isFault: boolean, data: DeviceAdvFilterInfo[], errorMessage?: string }) => {
                if (res.isFault) {
                    return null;
                }

                const policyFilter: DeviceAdvFilterInfo = res.data.find((f: DeviceAdvFilterInfo) => f.groupName === 'Device Policy');
                const policyFilterWithMatchType: DeviceAdvFilterInfo = this.findMatchAdvanceFilterByPolicyAttribute(policyFilter, policy.type);
                const policyFilterWithMatchName: DeviceAdvFilterInfo = this.findMatchAdvanceFilterByPolicyAttribute(policyFilterWithMatchType, policy.name);

                if (policyFilterWithMatchName) {
                    return policyFilterWithMatchName.optionList.find(op => op.name === state);
                }

                return null;
            })
        );
    }

    private findMatchAdvanceFilterByPolicyAttribute(filter: DeviceAdvFilterInfo, attribute: string,): DeviceAdvFilterInfo {
        if (!filter) {
            return null;
        }

        let found: DeviceAdvFilterInfo = filter.subGroupList.find(sub => sub.groupName === attribute);
        if (!found) {
            for (let sub of filter.subGroupList) {
                found = this.findMatchAdvanceFilterByPolicyAttribute(sub, attribute);
                if (found) {
                    break;
                }
            }
        }

        return found;
    }

    getAdvanceFilterOptionByDeviceGroupID(groupId: string): Observable<DeviceAdvFilterOptionInfo> {
        if (!groupId) {
            return of(null);
        }

        return this.getAdvanceFilterList().pipe(
            map((res: { isFault: boolean, data: DeviceAdvFilterInfo[], errorMessage?: string }) => {
                if (res.isFault) {
                    return null;
                }

                const groupFilter: DeviceAdvFilterInfo = res.data.find((f: DeviceAdvFilterInfo) => f.groupName === 'Device Group');
                if (groupFilter) {
                    const option: DeviceAdvFilterOptionInfo = groupFilter.optionList.find(op => op.value.indexOf(groupId) >= 0);
                    return option;
                }

                return null;
            })
        );
    }

    getDevicesByFilter(filterIn: {
        rules?: string[],
        labels?: string[],
        onlineStatus?: { [state: string]: boolean },
        search?: { key: string, value: string, langKey?: string },
        devGroup?: { id: string, switch: GroupSwitch }
    }, options?: { skipOnlineStatusFilter?: boolean }): Observable<{ isFault: boolean, devices?: DeviceInfo[], errorMessage?: string }> {
        Logger.logInfo('devSvc', 'getDevicesByAdvFilter', 'filter input ', filterIn, options);

        this._currentFilter.rules = filterIn.rules ?? this._currentFilter.rules;
        this._currentFilter.onlineStatus = filterIn.onlineStatus ?? this._currentFilter.onlineStatus;
        this._currentFilter.search = filterIn.search ?? this._currentFilter.search;
        this._currentFilter.devGroup = filterIn.devGroup ?? this._currentFilter.devGroup;
        this._currentFilter.labels = filterIn.labels ?? this._currentFilter.labels;

        Logger.logInfo('devSvc', 'getDevicesByAdvFilter', 'use filter: ', this._currentFilter);

        return of(true).pipe(
            concatMap(() => {
                if (this._currentFilter.rules?.length > 0 || this._currentFilter.labels?.length > 0) {
                    let skip: number = 0, limit = this.DEVICE_QUERY_LIMIT;
                    return this.naSvc.listVirtualDeviceByAdvanceFilter({ skip: skip, limit: limit }, { advancedFilterList: this._currentFilter.rules, labelList: this._currentFilter.labels }, this.accountSvc.token).pipe(
                        expand((res: IAPIRx<IListVirtualDeviceRxData>) => {
                            let hasNext: boolean = res.data.skip + res.data.limit < res.data.total ? true : false;
                            skip += limit;
                            return hasNext ? this.naSvc.listVirtualDeviceByAdvanceFilter({ skip: skip, limit: limit }, { advancedFilterList: this._currentFilter.rules, labelList: this._currentFilter.labels }, this.accountSvc.token) : EMPTY;
                        }),
                        map((res: IAPIRx<IListVirtualDeviceRxData>) => {
                            if (res.error !== 0) {
                                return { isFault: true, devices: [] };
                            }

                            const devices: DeviceInfo[] = res.data?.itemList?.filter(v => v.virtualDevicePairedID).map(v => {
                                this.transformDevice(v, this._deviceMap[v.virtualDeviceID]?.dev, res.serverTime);

                                return this._deviceMap[v.virtualDeviceID].dev;
                            });

                            return { isFault: false, devices: devices };
                        }),
                        scan((acc, curr) => {
                            if (!curr.isFault) {
                                acc.devices = acc.devices.concat(curr.devices);
                            }

                            return acc;
                        }, { isApplied: true, devices: [] })
                    );
                }

                return of({ isApplied: false, devices: this._deviceList });
            }),
            map((res: { isApplied: boolean, devices: DeviceInfo[] }) => {
                Logger.logInfo('devSvc', 'advFilter', 'after adv req: ', res.devices.length);
                let isApplied: boolean = res.isApplied;
                let devices: DeviceInfo[] = res.devices;

                if (this._currentFilter.devGroup && this._currentFilter.devGroup.switch == GroupSwitch.on) {
                    if (this._currentFilter.devGroup.id) {
                        isApplied = true;
                        if (this._currentFilter.devGroup.id !== DEVICE_GROUP_ID_HOME) {
                            devices = devices.filter(d => d.groupID === this._currentFilter.devGroup.id);
                            Logger.logInfo('devSvc', 'advFilter', 'after do dev group: ', devices.length);
                        }
                    }
                }

                if (this._currentFilter.onlineStatus) {
                    const onlineStatusState = HelperLib.getOnlineStatusState(this._currentFilter.onlineStatus);
                    if (onlineStatusState.isChanged) {
                        isApplied = true;
                        if (!options?.skipOnlineStatusFilter) {
                            devices = this.filterDevice(devices, this._currentFilter.onlineStatus);
                        }

                        Logger.logInfo('devSvc', 'advFilter', 'after do online filter: ', devices.length);
                    }
                }

                if (this._currentFilter.search) {
                    if (this._currentFilter.search.value) {
                        isApplied = true;
                        devices = this.filterDevice(devices, null, this._currentFilter.search);
                        Logger.logInfo('devSvc', 'advFilter', 'after do search filter: ', devices.length);
                    }
                }

                return { isApplied: isApplied, devices: devices };
            }),
            map((res: { isApplied: boolean, devices: DeviceInfo[] }) => {
                const emitEventData = { isApplied: res.isApplied, devices: res.devices, sourceFilters: this._currentFilter };
                Logger.logInfo('devSvc', 'advFilter', 'emit: ', emitEventData);

                this.deviceFilterApplied.emit(emitEventData);

                return { isFault: false };
            }),
            takeUntil(this._unsubscribe$)
        );
    }

    resetAdvanceFilter(): void {
        this._currentFilter = {};

        Logger.logInfo('devSvc', 'resetAdvanceFilter', 'emit: ', { isApplied: false });
        this.deviceFilterApplied.emit({ isApplied: false });
    }

    getDeviceLabels(forceRefresh: boolean = false): Observable<string[]> {
        if (forceRefresh || this._labelNames.length == 0 || this.needUpdate(this._lastUpdateDeviceLabelTime, this.DEVICE_REFRESH_DURATION)) {
            return this.naSvc.listVirtualDeviceLabels(this.accountSvc.token).pipe(
                map((res: IAPIRx<string[]>) => {
                    this._labelNames = res.data || [];
                    this._lastUpdateDeviceLabelTime = new Date();

                    return this._labelNames;
                })
            );
        }

        return of(this._labelNames);
    }

    updateDeviceLabels(appended?: { labelName: string, virtualDeviceIDList: string[] }, removed?: { labelName: string, virtualDeviceIDList: string[] }): Observable<{ isFault: boolean, errorMessage?: string }> {
        return this.naSvc.updateVirtualDeviceLabel(appended, removed, this.accountSvc.token).pipe(
            concatMap((res: IAPIRx<string[]>) => {
                if (res.error !== 0) {
                    return of({ isFault: true, errorMessage: res.errorMessage });
                }

                this._labelNames = res.data;
                this._lastUpdateDeviceLabelTime = new Date();

                if (removed) {
                    removed.virtualDeviceIDList.forEach(vID => {
                        if (this._deviceMap[vID]) {
                            if (this._deviceMap[vID].dev?.virtualDeviceLabelMap?.has(removed.labelName)) {
                                this._deviceMap[vID].dev?.virtualDeviceLabelMap?.delete(removed.labelName);
                            }
                        }
                    });
                }
                if (appended) {
                    // call api to update labels on the cache device
                    let skip: number = 0, limit = this.DEVICE_QUERY_LIMIT;
                    return this.naSvc.listVirtualDeviceByAdvanceFilter({ skip: skip, limit: limit }, { labelList: [appended.labelName] }, this.accountSvc.token).pipe(
                        expand((res: IAPIRx<IListVirtualDeviceRxData>) => {
                            let hasNext: boolean = res.data.skip + res.data.limit < res.data.total ? true : false;
                            skip += limit;
                            return hasNext ? this.naSvc.listVirtualDeviceByAdvanceFilter({ skip: skip, limit: limit }, { labelList: [appended.labelName] }, this.accountSvc.token) : EMPTY;
                        }),
                        map((res: IAPIRx<IListVirtualDeviceRxData>) => {
                            if (res.error !== 0) {
                                return { isFault: true, devices: [] };
                            }

                            const devices: DeviceInfo[] = res.data?.itemList?.filter(v => v.virtualDevicePairedID).map(v => {
                                this.transformDevice(v, this._deviceMap[v.virtualDeviceID]?.dev, res.serverTime);

                                return this._deviceMap[v.virtualDeviceID].dev;
                            });

                            return { isFault: false, devices: devices };
                        }),
                        reduce((acc, curr) => {
                            if (!curr.isFault) {
                                acc.devices = acc.devices.concat(curr.devices);
                            }

                            if (acc.devices.length > 0) {
                                acc.isApplied = true;
                            }

                            return acc;
                        }, { isApplied: false, devices: [] }),
                        map(() => ({ isFault: false }))
                    )
                }

                return of({ isFault: false });
            }),
            map((res: { isFault: boolean, errorMessage?: string }) => {
                this.deviceLabelUpdated.emit({ labelNames: this._labelNames, query: { appended: appended, removed: removed } });
                return res;
            })
        );
    }

    private listDeviceQuery(skip: number, limit: number): Observable<{ isFault: boolean, devices: DeviceInfo[], nextQuery: boolean, errorMessage?: string }> {
        Logger.logInfo('devSvc', 'listDeviceQuery', 'Get query with range (' + skip + ' - ' + (skip + limit) + ')');

        return this.naSvc.listVirtualDevice({ detail: this.DEV_REQ_ATTRIBUTES, skip: skip, limit: limit }, this.accountSvc.token).pipe(
            map((res: IAPIRx<IListVirtualDeviceRxData>) => {
                if (res.error !== 0) {
                    return { isFault: true, devices: [], nextQuery: false, errorMessage: res.errorMessage || res.error.toString() };
                }

                const addedDeviceMap: { [virtualDeviceID: string]: DeviceInfo } = {};
                res.data.itemList.filter(v => v.virtualDeviceID).forEach((v: IVirtualDeviceRxData) => {
                    if (!this._deviceMap[v.virtualDeviceID]) {
                        const new_dev: DeviceInfo = this.transformDevice(v, null, res.serverTime);
                        addedDeviceMap[v.virtualDeviceID] = new_dev;
                    }
                    else {
                        this.transformDevice(v, this._deviceMap[v.virtualDeviceID].dev, res.serverTime);
                        this._deviceMap[v.virtualDeviceID].keep = true;
                        addedDeviceMap[v.virtualDeviceID] = this._deviceMap[v.virtualDeviceID].dev;
                    }
                });

                this._deviceTotalAmount = res.data.total;
                const hasNext: boolean = res.data.skip + res.data.limit < res.data.total ? true : false;
                if (!hasNext) {
                    //no more datas, remove devices that do not need to keep on cache
                    Object.keys(this._deviceMap).filter(vID => !this._deviceMap[vID].keep).forEach(vID => {
                        delete this._deviceMap[vID];
                    });
                }
                if (res.data.itemList.length > 0) {
                    this.updateDeviceListByMap();
                }

                this._deviceUpdate$.next({ hasNext: hasNext, addedDeviceList: HelperLib.mapToList(addedDeviceMap) });

                return { isFault: false, devices: this._deviceList, nextQuery: hasNext };
            })
        );
    }

    private updateDeviceListByMap(): void {
        this._deviceList = Object.keys(this._deviceMap).filter(vID => this._deviceMap[vID].keep).map(vID => this._deviceMap[vID].dev);
    }

    resetCache(device: DeviceInfo, ...cacheNameList: DeviceCacheType[]): void {
        if (!device || !this._deviceCache[device.virtualId] || !cacheNameList || cacheNameList.length === 0) {
            return;
        }

        cacheNameList.forEach(c => {
            if (this._deviceCache[device.virtualId][c]) {
                this._deviceCache[device.virtualId][c].lastUpdateTime = null;
                this._deviceCache[device.virtualId][c].errorMessage = null;
            }
        });
    }

    updateScreenshot(device: DeviceInfo, force: boolean = false): Observable<{ isFault: boolean, url: SafeUrl, captureTime: Date, useCache?: boolean, lastUpdateTime: Date }> {
        return this.doDeviceCachePreAction(DeviceCacheType.screenshot, device, force).pipe(
            concatMap((updateRequired: boolean) => {
                if (!updateRequired) {
                    return of({
                        isFault: false,
                        url: this._deviceCache[device.virtualId][DeviceCacheType.screenshot].data.url,
                        captureTime: this._deviceCache[device.virtualId][DeviceCacheType.screenshot].data.captureTime,
                        useCache: true,
                        lastUpdateTime: this._deviceCache[device.virtualId][DeviceCacheType.screenshot].lastUpdateTime
                    });
                }

                return this.naSvc.getDeviceScreenshot({ virtualDeviceID: device.virtualId, virtualDevicePairedID: device.virtualPairId }, { raw: true, dummy: true }, this.accountSvc.token).pipe(
                    map((res: any) => {
                        let isFault: boolean = false;

                        try {
                            const dataUrl = window.URL.createObjectURL(res);
                            this._deviceCache[device.virtualId][DeviceCacheType.screenshot].data.url = this.domSan.bypassSecurityTrustUrl(dataUrl);
                            this._deviceCache[device.virtualId][DeviceCacheType.screenshot].data.captureTime = res.serverTime ? new Date(res.serverTime) : null;
                            this._deviceCache[device.virtualId][DeviceCacheType.screenshot].errorMessage = null;
                            this._deviceCache[device.virtualId][DeviceCacheType.screenshot].updating = false;
                            this._deviceCache[device.virtualId][DeviceCacheType.screenshot].lastUpdateTime = new Date();
                        }
                        catch (e) {
                            isFault = true;
                        }

                        return {
                            isFault: isFault,
                            url: this._deviceCache[device.virtualId][DeviceCacheType.screenshot].data.url,
                            captureTime: this._deviceCache[device.virtualId][DeviceCacheType.screenshot].data.captureTime,
                            lastUpdateTime: this._deviceCache[device.virtualId][DeviceCacheType.screenshot].lastUpdateTime
                        }
                    })
                );
            })
        );
    }

    updateActivity(device: DeviceInfo, force: boolean = false): Observable<boolean> {
        return this.doDeviceCachePreAction(DeviceCacheType.activity, device, force).pipe(
            concatMap((updateRequired: boolean) => {
                if (!updateRequired) {
                    return of(true);
                }

                //1. list all history tasks
                return this.naSvc.listTasks({ virtualDeviceID: device.virtualId, virtualDevicePairedID: device.virtualPairId }, this.accountSvc.token).pipe(
                    map((res: IAPIRx<IListTaskRxData>) => {
                        this._deviceCache[device.virtualId][DeviceCacheType.activity].errorMessage = res.errorMessage;
                        this._deviceCache[device.virtualId][DeviceCacheType.activity].updating = false;

                        if (res.error !== 0) {
                            Logger.logError(this.className, 'updateActivity', 'Update device activity failed. Error = ', res.errorMessage);
                            return false;
                        }

                        this._deviceCache[device.virtualId][DeviceCacheType.activity].lastUpdateTime = new Date();

                        //step 2. reserve only main tasks from user, remove auxiliary tasks and those from servers.
                        let mainTaskList: ITaskData[] = res.data ? res.data.itemList.filter((t: ITaskData) => (!t.isAdminTask && !t.taskLevel) && t.taskAction !== this.constantSvc.TASKTYPE_ACTIVEMODE) : [];

                        //step 3. only show all unfinished tasks
                        let reserveTaskList: ITaskData[] = [];
                        if (mainTaskList.length <= this.TASK_TRACK_ABILITY) {
                            reserveTaskList = mainTaskList;
                        }
                        else {
                            let counter: number = 0;
                            let counter_finish: number = 0;
                            for (const task of mainTaskList) {
                                if (counter > this.TASK_TRACK_ABILITY && counter_finish > 2) {
                                    break;
                                }

                                if (task.taskStatus.finishedTimestamp) {
                                    //finished task
                                    reserveTaskList.push(task);
                                    counter_finish++;
                                }
                                else {
                                    //un-finished task
                                    reserveTaskList.push(task);
                                }

                                counter++;
                            }
                        }

                        //transform to taskInfos of device
                        device.taskInfos = reserveTaskList.map(t => {
                            const task = new DeviceActionInfo();

                            task.id = t.taskID;
                            task.type = t.taskAction;
                            if (t.taskResourceData) {
                                if (t.taskResourceData.settings) {
                                    task.resources = t.taskResourceData.settings;
                                }

                                if (t.taskResourceData.rawData) {
                                    try {
                                        const rawList: any[] = JSON.parse(t.taskResourceData.rawData);
                                        if (rawList && rawList.length > 0) {
                                            task.resources = task.resources || [];
                                            rawList.forEach(r => {
                                                switch (r.type) {
                                                    case LockScreenMode.maintenance:
                                                        {
                                                            task.resources.push({
                                                                langKey: 'key-maintenancePlayback',
                                                                name: this.constantSvc.DEVKEY_FAKE_MAINTENANCE,
                                                                value: new MaintenancePlaybackInfo(r.metaData ? r.metaData.rawData : null)
                                                            });
                                                        }
                                                        break;
                                                    case LockScreenMode.screenOff:
                                                        {
                                                            task.resources.push({
                                                                langKey: 'key-screenoff',
                                                                name: this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF,
                                                                value: new ScreenOffInfo(r.metaData ? r.metaData.rawData : null)
                                                            });
                                                        }
                                                        break;
                                                    case LockScreenMode.screenSaver:
                                                        {
                                                            task.resources.push({
                                                                langKey: 'key-screenSaver',
                                                                name: this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENSAVER,
                                                                value: new ScreenSaverInfo(r.metaData ? r.metaData.rawData : null)
                                                            });
                                                        }
                                                        break;
                                                }
                                            });
                                        }
                                    }
                                    catch {

                                    }
                                }
                            }
                            //task.resources = t.taskResourceData ? t.taskResourceData.settings : null;
                            if (t.issueDate) {
                                task.issueDate = new Date(t.issueDate);
                            }
                            task.name = t.taskAction;
                            task.status = this.task_status_mapping(t.taskStatus.currentStatus, t.taskStatus.success);
                            task.errorMessage = t.taskStatus.errorMessage;
                            if (task.status === TaskStatus.progress) {
                                task.progress = t.taskStatus.progress || 0;
                            }
                            if (t.taskStatus.startTimestamp) {
                                task.startDate = new Date(t.taskStatus.startTimestamp);
                            }
                            if (t.taskStatus.finishedTimestamp) {
                                task.finishDate = new Date(t.taskStatus.finishedTimestamp);
                            }
                            return task;
                        });

                        device.taskTrackTime = new Date();
                        this.deviceTaskUpdated.emit(device);

                        return true;
                    })
                );
            })
        );
    }

    updateWarranty(device: DeviceInfo, force: boolean = false): Observable<boolean> {
        return this.doDeviceCachePreAction(DeviceCacheType.warranty, device, force).pipe(
            concatMap((updateRequired: boolean) => {
                if (!updateRequired) {
                    return of(true);
                }

                return this.naSvc.getWarranty(this.accountSvc.token, device.currentSettings[this.constantSvc.DEVKEY_INFO_PID]).pipe(
                    map((res: IAPIRx<IGetWarrantyRxData[]>) => {
                        this._deviceCache[device.virtualId][DeviceCacheType.warranty].errorMessage = res.errorMessage;
                        this._deviceCache[device.virtualId][DeviceCacheType.warranty].updating = false;

                        if (res.error !== 0) {
                            Logger.logError(this.className, 'updateWarranty', 'Update device warranty failed. Error = ', res.errorMessage);
                            return false;
                        }

                        this._deviceCache[device.virtualId][DeviceCacheType.warranty].lastUpdateTime = new Date();

                        if (res.data.length > 0) {
                            device.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_STARTDATE] = res.data[0].warrantyOverrideStartDate;
                            device.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_SN] = res.data[0].serialNumber;
                            device.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE] = res.data[0].warrantyOverrideEndDate;
                        }

                        return true;
                    })
                );
            })
        );
    }

    updateCalendar(device: DeviceInfo, force: boolean = false): Observable<boolean> {
        return this.doDeviceCachePreAction(DeviceCacheType.calendar, device, force).pipe(
            concatMap((updateRequired: boolean) => {
                if (!updateRequired) {
                    return of(true);
                }

                return this.naSvc.getVirtualDeviceCalendar({ virtualDeviceID: device.virtualId, virtualDevicePairedID: device.virtualPairId }, this.accountSvc.token).pipe(
                    map((res: IAPIRx<IVirtualDeviceCalendarItem[]>) => {
                        this._deviceCache[device.virtualId][DeviceCacheType.calendar].errorMessage = res.errorMessage;
                        this._deviceCache[device.virtualId][DeviceCacheType.calendar].updating = false;

                        if (res.error !== 0) {
                            Logger.logError(this.className, 'updateCalendar', 'Update device calendar failed. Error = ', res.errorMessage);
                            return false;
                        }

                        this._deviceCache[device.virtualId][DeviceCacheType.calendar].lastUpdateTime = new Date();
                        if (res.data && res.data.length > 0) {
                            for (let data of res.data) {
                                if (data.metaData && data.metaData.rawData) {
                                    switch (data.type) {
                                        case LockScreenMode.screenOff:
                                        case LockScreenMode.screenSaver:
                                            {
                                                // always transform to lock-screen structure
                                                device.currentSettings[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN] = device.currentSettings[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN] ? (device.currentSettings[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN] as LockScreenInfo).update(data.metaData.rawData, data.type) : new LockScreenInfo(data.metaData.rawData, data.type);
                                            }
                                            break;
                                        case LockScreenMode.maintenance:
                                            {
                                                device.currentSettings[this.constantSvc.DEVKEY_FAKE_MAINTENANCE] = new MaintenancePlaybackInfo(data.metaData.rawData);
                                            }
                                            break;
                                    }
                                }
                            }
                        }

                        return true;
                    })
                );
            })
        );
    }

    updateShadowDevice(device: DeviceInfo, force: boolean = false): Observable<{ isFault: boolean, errorMessage?: string, data: IPolicyLockMap }> {
        return this.doDeviceCachePreAction(DeviceCacheType.shadow, device, force).pipe(
            concatMap((updateRequired: boolean) => {
                if (!updateRequired) {
                    return of({ isFault: false, data: this._deviceCache[device.virtualId][DeviceCacheType.shadow].data });
                }

                return this.naSvc.getVirtualDeviceShadow({ virtualDeviceID: device.virtualId, virtualDevicePairedID: device.virtualPairId }, this.accountSvc.token).pipe(
                    map((res: IAPIRx<IShadowDeviceRxData>) => {
                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].errorMessage = null;
                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].updating = false;

                        if (res.error !== 0) {
                            Logger.logError(this.className, 'updateShadowDevice', 'Update shadow device failed. Error = ', res.errorMessage);
                            return {
                                isFault: true,
                                data: null,
                                errorMessage: res.error + ' ' + res.errorMessage
                            };
                        }

                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].lastUpdateTime = new Date();
                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].data = {};

                        // current settings
                        if (res.data.currentSettings && res.data.currentSettings.settings) {
                            //app
                            if (res.data.currentSettings.settings.app) {
                                if (res.data.currentSettings.settings.app.start) {
                                    //check if it belongs to Application policy or Configuration policy
                                    let policyType: PolicyType = PolicyType.Configuration;
                                    if (res.data.applicationList && res.data.applicationList.find(app => app.policyID === res.data.currentSettings.settings.app.start.policyID)) {
                                        policyType = PolicyType.Application;
                                    }
                                    this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_APPSTART] = {
                                        isSync: res.data.currentSettings.settings.app.start.isDeviceSynced,
                                        policyID: res.data.currentSettings.settings.app.start.policyID,
                                        policyName: res.data.currentSettings.settings.app.start.policyName,
                                        policyType: policyType
                                    };
                                }

                                if (res.data.currentSettings.settings.app.settings && res.data.currentSettings.settings.app.settings.console) {
                                    if (res.data.currentSettings.settings.app.settings.console.disableUsbSmilUpdate) {
                                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_USBSMILUPDATE] = {
                                            isSync: res.data.currentSettings.settings.app.settings.console.disableUsbSmilUpdate.isDeviceSynced,
                                            policyID: res.data.currentSettings.settings.app.settings.console.disableUsbSmilUpdate.policyID,
                                            policyName: res.data.currentSettings.settings.app.settings.console.disableUsbSmilUpdate.policyName,
                                            policyType: PolicyType.Security
                                        };
                                    }
                                    if (res.data.currentSettings.settings.app.settings.console.disableNetworkAccess) {
                                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_NETACCESS] = {
                                            isSync: res.data.currentSettings.settings.app.settings.console.disableNetworkAccess.isDeviceSynced,
                                            policyID: res.data.currentSettings.settings.app.settings.console.disableNetworkAccess.policyID,
                                            policyName: res.data.currentSettings.settings.app.settings.console.disableNetworkAccess.policyName,
                                            policyType: PolicyType.Security
                                        };
                                    }
                                    if (res.data.currentSettings.settings.app.settings.console.disablePowerSaveTimer) {
                                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE] = {
                                            isSync: res.data.currentSettings.settings.app.settings.console.disablePowerSaveTimer.isDeviceSynced,
                                            policyID: res.data.currentSettings.settings.app.settings.console.disablePowerSaveTimer.policyID,
                                            policyName: res.data.currentSettings.settings.app.settings.console.disablePowerSaveTimer.policyName,
                                            policyType: PolicyType.Configuration
                                        };
                                    }
                                    if (res.data.currentSettings.settings.app.settings.console.powerSaveTimerTimeoutAction) {
                                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_ACTION] = {
                                            isSync: res.data.currentSettings.settings.app.settings.console.powerSaveTimerTimeoutAction.isDeviceSynced,
                                            policyID: res.data.currentSettings.settings.app.settings.console.powerSaveTimerTimeoutAction.policyID,
                                            policyName: res.data.currentSettings.settings.app.settings.console.powerSaveTimerTimeoutAction.policyName,
                                            policyType: PolicyType.Configuration
                                        };
                                    }
                                    if (res.data.currentSettings.settings.app.settings.console.powerSaveTimerTimeoutMinutes) {
                                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT] = {
                                            isSync: res.data.currentSettings.settings.app.settings.console.powerSaveTimerTimeoutMinutes.isDeviceSynced,
                                            policyID: res.data.currentSettings.settings.app.settings.console.powerSaveTimerTimeoutMinutes.policyID,
                                            policyName: res.data.currentSettings.settings.app.settings.console.powerSaveTimerTimeoutMinutes.policyName,
                                            policyType: PolicyType.Configuration
                                        };
                                    }
                                }
                                if (res.data.currentSettings.settings.app.solutions) {
                                    const solutionActions: string[] = ['insert', 'delete'];
                                    const calendarPrefix: string = 'com.iadea.iadeacare.calendar';
                                    for (let action of solutionActions) {
                                        if (res.data.currentSettings.settings.app.solutions[action] && res.data.currentSettings.settings.app.solutions[action].targetValue) {
                                            const actionTargetValues: { name: string, policyID: string, policyName: string }[] = Array.isArray(res.data.currentSettings.settings.app.solutions[action].targetValue) ? res.data.currentSettings.settings.app.solutions[action].targetValue : [res.data.currentSettings.settings.app.solutions[action].targetValue];
                                            actionTargetValues.forEach(s => {
                                                let calendarPrefixIndex: number = s.name?.indexOf(calendarPrefix);
                                                if (calendarPrefixIndex == 0) {
                                                    let shadowKey: string;
                                                    switch (s.name.substring(calendarPrefix.length + 1)) {
                                                        case 'screenOff':
                                                            {
                                                                shadowKey = this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF;
                                                            }
                                                            break;
                                                        case 'screenSaver':
                                                            {
                                                                shadowKey = this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENSAVER;
                                                            }
                                                            break;
                                                        case 'maintenance':
                                                            {
                                                                shadowKey = this.constantSvc.DEVKEY_FAKE_MAINTENANCE;
                                                            }
                                                            break;
                                                    }

                                                    if (shadowKey) {
                                                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[shadowKey] = {
                                                            isSync: res.data.currentSettings.settings.app.solutions[action].isDeviceSynced,
                                                            policyID: s.policyID,
                                                            policyName: s.policyName,
                                                            policyType: PolicyType.Configuration
                                                        };
                                                    }
                                                }
                                                else {
                                                    this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_FAKE_APPINSTALL] = {
                                                        isSync: res.data.currentSettings.settings.app.solutions[action].isDeviceSynced,
                                                        policyID: s.policyID,
                                                        policyName: s.policyName,
                                                        policyType: PolicyType.Application
                                                    };
                                                }
                                            });
                                        }
                                    }
                                }
                            }
                            // security
                            if (res.data.currentSettings.settings.security) {
                                if (res.data.currentSettings.settings.security.users?.admin) {
                                    this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_SECURITY_USER_ADMIN] = {
                                        isSync: res.data.currentSettings.settings.security.users.admin.isDeviceSynced,
                                        policyID: res.data.currentSettings.settings.security.users.admin.policyID,
                                        policyName: res.data.currentSettings.settings.security.users.admin.policyName,
                                        policyType: PolicyType.Security
                                    };
                                }
                                if (res.data.currentSettings.settings.security.certs?.insert) {
                                    this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_CERTIFICATE_INSERT] = {
                                        isSync: res.data.currentSettings.settings.security.certs.insert.isDeviceSynced,
                                        policyName: res.data.currentSettings.settings.security.certs.insert.policyName,
                                        policyID: res.data.currentSettings.settings.security.certs.insert.policyID,
                                        policyType: PolicyType.Certificate
                                    }
                                }
                                if (res.data.currentSettings.settings.security.certs?.enroll) {
                                    this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_CERTIFICATE_ENROLL] = {
                                        isSync: res.data.currentSettings.settings.security.certs.enroll.isDeviceSynced,
                                        policyName: res.data.currentSettings.settings.security.certs.enroll.policyName,
                                        policyID: res.data.currentSettings.settings.security.certs.enroll.policyID,
                                        policyType: PolicyType.Certificate
                                    }
                                }
                                if (res.data.currentSettings.settings.security.certs?.delete) {
                                    this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_CERTIFICATE_DELETE] = {
                                        isSync: res.data.currentSettings.settings.security.certs.delete.isDeviceSynced,
                                        policyName: res.data.currentSettings.settings.security.certs.delete.policyName,
                                        policyID: res.data.currentSettings.settings.security.certs.delete.policyID,
                                        policyType: PolicyType.Certificate
                                    }
                                }
                            }
                            // hardware
                            if (res.data.currentSettings.settings.hardware) {
                                if (res.data.currentSettings.settings.hardware.audioOut && res.data.currentSettings.settings.hardware.audioOut[0] && res.data.currentSettings.settings.hardware.audioOut[0].masterSoundLevel) {
                                    this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL] = {
                                        isSync: res.data.currentSettings.settings.hardware.audioOut[0].masterSoundLevel.isDeviceSynced,
                                        policyID: res.data.currentSettings.settings.hardware.audioOut[0].masterSoundLevel.policyID,
                                        policyName: res.data.currentSettings.settings.hardware.audioOut[0].masterSoundLevel.policyName,
                                        policyType: PolicyType.Configuration
                                    };
                                }
                                if (res.data.currentSettings.settings.hardware.videoOut && res.data.currentSettings.settings.hardware.videoOut[0] && res.data.currentSettings.settings.hardware.videoOut[0].rotation) {
                                    this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_HD_VIDEO_ROTATION] = {
                                        isSync: res.data.currentSettings.settings.hardware.videoOut[0].rotation.isDeviceSynced,
                                        policyID: res.data.currentSettings.settings.hardware.videoOut[0].rotation.policyID,
                                        policyName: res.data.currentSettings.settings.hardware.videoOut[0].rotation.policyName,
                                        policyType: PolicyType.Configuration
                                    };
                                }
                            }
                            // schedule
                            if (res.data.currentSettings.settings.schedule) {
                                if (res.data.currentSettings.settings.schedule.reboot) {
                                    if (res.data.currentSettings.settings.schedule.reboot) {
                                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_SCHEDULE_REBOOT] = {
                                            isSync: res.data.currentSettings.settings.schedule.reboot.isDeviceSynced,
                                            policyID: res.data.currentSettings.settings.schedule.reboot.policyID,
                                            policyName: res.data.currentSettings.settings.schedule.reboot.policyName,
                                            policyType: PolicyType.Configuration
                                        };
                                    }
                                    if (res.data.currentSettings.settings.schedule['reboot.enabled']) {
                                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_ENABLED] = {
                                            isSync: res.data.currentSettings.settings.schedule['reboot.enabled'].isDeviceSynced,
                                            policyID: res.data.currentSettings.settings.schedule['reboot.enabled'].policyID,
                                            policyName: res.data.currentSettings.settings.schedule['reboot.enabled'].policyName,
                                            policyType: PolicyType.Configuration
                                        };
                                    }
                                    if (res.data.currentSettings.settings.schedule.reboot['reboot.data']) {
                                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_TIME] = {
                                            isSync: res.data.currentSettings.settings.schedule['reboot.data'].isDeviceSynced,
                                            policyID: res.data.currentSettings.settings.schedule['reboot.data'].policyID,
                                            policyName: res.data.currentSettings.settings.schedule['reboot.data'].policyName,
                                            policyType: PolicyType.Configuration
                                        };
                                    }
                                }
                            }
                            // time
                            if (res.data.currentSettings.settings.time) {
                                if (res.data.currentSettings.settings.time.timeZone) {
                                    this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_TIME_TIMEZONE] = {
                                        isSync: res.data.currentSettings.settings.time.timeZone.isDeviceSynced,
                                        policyID: res.data.currentSettings.settings.time.timeZone.policyID,
                                        policyName: res.data.currentSettings.settings.time.timeZone.policyName,
                                        policyType: PolicyType.Configuration
                                    };
                                }
                                if (res.data.currentSettings.settings.time.autoTime) {
                                    if (res.data.currentSettings.settings.time.autoTime.enabled) {
                                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED] = {
                                            isSync: res.data.currentSettings.settings.time.autoTime.enabled.isDeviceSynced,
                                            policyID: res.data.currentSettings.settings.time.autoTime.enabled.policyID,
                                            policyName: res.data.currentSettings.settings.time.autoTime.enabled.policyName,
                                            policyType: PolicyType.Configuration
                                        };
                                    }
                                    if (res.data.currentSettings.settings.time.autoTime.server) {
                                        this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE] = {
                                            isSync: res.data.currentSettings.settings.time.autoTime.server.isDeviceSynced,
                                            policyID: res.data.currentSettings.settings.time.autoTime.server.policyID,
                                            policyName: res.data.currentSettings.settings.time.autoTime.server.policyName,
                                            policyType: PolicyType.Configuration
                                        };
                                    }
                                }
                            }

                            // system (firmware) update
                            if (res.data.currentSettings.settings.system) {
                                if (res.data.currentSettings.settings.system.update?.policy) {
                                    this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_UPDATE_POLICY] = {
                                        isSync: res.data.currentSettings.settings.system.update.policy.isDeviceSynced,
                                        isPartialSync: res.data.currentSettings.settings.system.update.policy.isPartialSynced,
                                        partialSyncDesc: res.data.currentSettings.settings.system.update.policy.partialSyncDescription,
                                        policyID: res.data.currentSettings.settings.system.update.policy.policyID,
                                        policyName: res.data.currentSettings.settings.system.update.policy.policyName,
                                        policyType: PolicyType.FirmwareUpdate
                                    };
                                }
                            }

                            // sys, ex: device password
                            if (res.data.currentSettings.settings.sys) {
                                if (res.data.currentSettings.settings.sys.secure?.password?.encrypted) {
                                    this._deviceCache[device.virtualId][DeviceCacheType.shadow].data[this.constantSvc.DEVKEY_FAKE_DEVICE_PASSWORD] = {
                                        isSync: res.data.currentSettings.settings.sys.secure.password.encrypted.isDeviceSynced,
                                        isPartialSync: res.data.currentSettings.settings.sys.secure.password.encrypted.isPartialSynced,
                                        partialSyncDesc: res.data.currentSettings.settings.sys.secure.password.encrypted.partialSyncDescription,
                                        policyID: res.data.currentSettings.settings.sys.secure.password.encrypted.policyID,
                                        policyName: res.data.currentSettings.settings.sys.secure.password.encrypted.policyName,
                                        policyType: PolicyType.Security
                                    }
                                }
                            }
                        }

                        return {
                            isFault: false,
                            data: this._deviceCache[device.virtualId][DeviceCacheType.shadow].data
                        };
                    })
                );
            })
        );
    }

    private doDeviceCachePreAction(cacheName: DeviceCacheType, device: DeviceInfo, force: boolean = false, updatePeriodTime: number = this.DEVICE_REFRESH_DURATION * 10000): Observable<boolean> {
        return of(true).pipe(
            concatMap(() => {
                if (this._deviceCache[device.virtualId] && this._deviceCache[device.virtualId][cacheName]) {
                    if (this._deviceCache[device.virtualId][cacheName].updating) {
                        //wait until previous update finished
                        return new Observable<boolean>((observer) => {
                            HelperLib.checkState(1, () => { return !this._deviceCache[device.virtualId][cacheName].updating }, () => {
                                observer.next(this._deviceCache[device.virtualId][cacheName].errorMessage ? true : false);
                                observer.complete();
                            });
                        });
                    }

                    if (!force && this._deviceCache[device.virtualId][cacheName].lastUpdateTime && (Date.now() - this._deviceCache[device.virtualId][cacheName].lastUpdateTime.getTime() < updatePeriodTime)) {
                        //use cache
                        return of(false);
                    }
                }

                this._deviceCache[device.virtualId] = this._deviceCache[device.virtualId] || {};
                this._deviceCache[device.virtualId][cacheName] = this._deviceCache[device.virtualId][cacheName] || { data: {}, updating: true, lastUpdateTime: null };
                this._deviceCache[device.virtualId][cacheName].updating = true;
                this._deviceCache[device.virtualId][cacheName].errorMessage = null;

                return of(true);
            })
        );
    }

    private transformDevice(from: IVirtualDeviceRxData, to: DeviceInfo, serverTime: number): DeviceInfo {
        if (!to) {
            to = new DeviceInfo();
            this._deviceMap[from.virtualDeviceID] = {
                keep: true,
                dev: to
            };
        }

        to.virtualId = from.virtualDeviceID;
        to.virtualPairId = from.virtualDevicePairedID;
        to.virtualName = to.currentSettings[this.constantSvc.DEVKEY_FAKE_DISPLAYNAME] = from.virtualDeviceName;
        //if login account is enterprise, virtualDeviceOwnerAccountName will be '@[domain name]'.
        to.virtualDeviceOwner = from.virtualDeviceOwnerAccountName;
        //if login account is enterprise, this virtualDeviceOwnerAccountID will be id of the enterpriseAccountID
        to.virtualDeviceOwnerID = from.virtualDeviceOwnerAccountID;
        to.isPaired = from.virtualDevicePairedID ? true : false;
        to.isSelect = false;
        to.virtualDeviceLabelMap = new Map();
        from.virtualDeviceLabelList?.forEach(labelName => to.virtualDeviceLabelMap.set(labelName, true));

        to.groupID = from.virtualDeviceGroupID;
        to.groupIDPath = from.virtualDeviceGroup.groupIDPath;
        to.virtualDeviceGroup = from.virtualDeviceGroup;
        to.scep = { inUse: null, candidates: [] };

        if (from.virtualDeviceDetail) {
            if (from.virtualDeviceDetail.heartbeatTime > 0) {
                to.currentSettings[this.constantSvc.DEVKEY_FAKE_HEARTBEAT] = from.virtualDeviceDetail.heartbeatTime;
                // status
                if (from.virtualDeviceDetail.status) {
                    // info
                    if (from.virtualDeviceDetail.status.info) {
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_APP] = from.virtualDeviceDetail.status.info.app;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_PCBNAME] = from.virtualDeviceDetail.status.info.PCBName;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_PCBREVISION] = from.virtualDeviceDetail.status.info.PCBRevision;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_FW_VERSION] = from.virtualDeviceDetail.status.info.firmwareInfo?.firmwareVersion || from.virtualDeviceDetail.status.info.firmware;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_FW_FAMILY] = from.virtualDeviceDetail.status.info.firmwareInfo?.family;
                        
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_APKVERSION] = from.virtualDeviceDetail.status.info.iCareVersion;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_MODEL] = from.virtualDeviceDetail.status.info.model;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_MODELDESC] = from.virtualDeviceDetail.status.info.modelDescription;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_PID] = from.virtualDeviceDetail.status.info.playerId;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_PNAME] = from.virtualDeviceDetail.status.info.playerName;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_SYSTEM_UPTIME] = from.virtualDeviceDetail.status.info.system?.upTime || '';
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_SYSTEM_ISLOCK] = from.virtualDeviceDetail.status.info.system?.isLocked ?? false;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_NAME] = from.virtualDeviceDetail.status.info.warranty?.name || from.virtualDeviceDetail.status.info.warrantyName;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE] = from.virtualDeviceDetail.status.info.warranty?.overrideEndDate || from.virtualDeviceDetail.status.info.warrantyOverrideEndDate;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_SN] = from.virtualDeviceDetail.status.info.warranty?.serialNumber || from.virtualDeviceDetail.status.info.serialNumber;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_ETHERNETMACS] = from.virtualDeviceDetail.status.info.warranty?.ethernetMacList;
                        to.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_WIFIMACS] = from.virtualDeviceDetail.status.info.warranty?.wifiMacList;
                        to.currentSettings[this.constantSvc.DEVKEY_FAKE_REMOTECTRL_CONNECTED] = from.virtualDeviceDetail.status.info.remoteControl?.isConnected;

                        if (from.virtualDeviceDetail.status.info.security && from.virtualDeviceDetail.status.info.security.certs && from.virtualDeviceDetail.status.info.security.certs.list) {
                            to.scep.candidates = from.virtualDeviceDetail.status.info.security.certs.list.filter(key => key.name.startsWith(this.constantSvc.SCEP_SERVER_ALIAS_PREFIX)).map(key => ({
                                certName: key.name,
                                state: key.state,
                                usages: key.usages,
                                renewalDaysBeforeExpiration: key.renewalDaysBeforeExpiration,
                                notAfter: key.details && key.details.keystore ? key.details.keystore.find(key => key.status === 'IN_USE')?.notAfter : '',
                                provider: key.provider
                            }));
                        }

                        // webview provider
                        if (from.virtualDeviceDetail.status.info.firmwareInfo?.webViewProvider) {
                            to.currentSettings[this.constantSvc.DEVKEY_INFO_FW_WEBVIEWPROVIDER] = from.virtualDeviceDetail.status.info.firmwareInfo.webViewProvider.applicationLabel + ' - ' + from.virtualDeviceDetail.status.info.firmwareInfo.webViewProvider.versionName;
                        }
                    }
                    // security
                    if (from.virtualDeviceDetail.status.security) {
                        to.currentSettings[this.constantSvc.DEVKEY_SECURITY_USER_ADMIN] = from.virtualDeviceDetail.status.security;
                    }
                    // net
                    if (from.virtualDeviceDetail.status.net) {
                        if (from.virtualDeviceDetail.status.net.ethernet) {
                            to.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] = from.virtualDeviceDetail.status.net.ethernet.connected;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_LAN_DHCP_ENABLED] = from.virtualDeviceDetail.status.net.ethernet.dhcp ? from.virtualDeviceDetail.status.net.ethernet.dhcp.enabled : false;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_LAN_DNS1] = from.virtualDeviceDetail.status.net.ethernet.dns1;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_LAN_DNS2] = from.virtualDeviceDetail.status.net.ethernet.dns2;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_LAN_ENABLED] = from.virtualDeviceDetail.status.net.ethernet.enabled;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_LAN_GATEWAY] = from.virtualDeviceDetail.status.net.ethernet.gateway;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_LAN_IP] = from.virtualDeviceDetail.status.net.ethernet.ip;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC] = from.virtualDeviceDetail.status.net.ethernet.mac;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_LAN_NETMASK] = from.virtualDeviceDetail.status.net.ethernet.netmask;

                            // scep
                            if (from.virtualDeviceDetail.status.net.ethernet.authentication === "EAP" && from.virtualDeviceDetail.status.net.ethernet.eap && from.virtualDeviceDetail.status.net.ethernet.eap.client_cert) {
                                const certInfo = to.scep.candidates.find(scep => scep.certName === from.virtualDeviceDetail.status.net.ethernet.eap.client_cert) || { provider: null, state: 'Unknown', usages: [], renewalDaysBeforeExpiration: 0, notAfter: '' };
                                to.scep.inUse = {
                                    certName: from.virtualDeviceDetail.status.net.ethernet.eap.client_cert,
                                    provider: certInfo.provider,
                                    state: certInfo.state,
                                    usages: certInfo.usages,
                                    renewalDaysBeforeExpiration: certInfo.renewalDaysBeforeExpiration,
                                    notAfter: certInfo.notAfter
                                };
                            }
                        }
                        if (from.virtualDeviceDetail.status.net.wifi) {
                            to.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_SSID] = from.virtualDeviceDetail.status.net.wifi.SSID;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_AUTH] = from.virtualDeviceDetail.status.net.wifi.authentication;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] = from.virtualDeviceDetail.status.net.wifi.connected;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_DHCP_ENABLED] = from.virtualDeviceDetail.status.net.wifi.dhcp ? from.virtualDeviceDetail.status.net.wifi.dhcp.enabled : false;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_DNS1] = from.virtualDeviceDetail.status.net.wifi.dns1;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_DNS2] = from.virtualDeviceDetail.status.net.wifi.dns2;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_ENABLED] = from.virtualDeviceDetail.status.net.wifi.enabled;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_GATEWAY] = from.virtualDeviceDetail.status.net.wifi.gateway;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_IP] = from.virtualDeviceDetail.status.net.wifi.ip;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC] = from.virtualDeviceDetail.status.net.wifi.mac;
                            to.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_NETMASK] = from.virtualDeviceDetail.status.net.wifi.netmask;
                        }
                    }
                    // time
                    if (from.virtualDeviceDetail.status.time) {
                        to.currentSettings[this.constantSvc.DEVKEY_TIME_TIMEZONE] = from.virtualDeviceDetail.status.time.timeZone;
                        to.currentSettings[this.constantSvc.DEVKEY_TIME_LOCAL] = from.virtualDeviceDetail.status.time.localTime;
                        if (from.virtualDeviceDetail.status.time.autoTime) {
                            to.currentSettings[this.constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED] = from.virtualDeviceDetail.status.time.autoTime.enabled;
                            to.currentSettings[this.constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE] = from.virtualDeviceDetail.status.time.autoTime.server;
                        }
                    }
                    // schedule
                    if (from.virtualDeviceDetail.status.schedule && from.virtualDeviceDetail.status.schedule.reboot) {
                        // feature to support weekly settings on new package
                        to.currentSettings[this.constantSvc.DEVKEY_SCHEDULE_REBOOT] = from.virtualDeviceDetail.status.schedule.reboot;
                        // for old package compatibility
                        to.currentSettings[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_ENABLED] = from.virtualDeviceDetail.status.schedule.reboot.enabled;
                        to.currentSettings[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_TIME] = from.virtualDeviceDetail.status.schedule.reboot.time;
                    }
                    // debug
                    if (from.virtualDeviceDetail.status.debug && from.virtualDeviceDetail.status.debug.adb) {
                        to.currentSettings[this.constantSvc.DEVKEY_DEBUG_ADB_ENABLED] = from.virtualDeviceDetail.status.debug.adb.enabled;
                        if (from.virtualDeviceDetail.status.debug.adb.tcp) {
                            to.currentSettings[this.constantSvc.DEVKEY_DEBUG_ADB_TCP_ENABLED] = from.virtualDeviceDetail.status.debug.adb.tcp.enabled;
                            to.currentSettings[this.constantSvc.DEVKEY_DEBUG_ADB_TCP_PORT] = from.virtualDeviceDetail.status.debug.adb.tcp.port;
                        }
                    }
                    // hardware
                    if (from.virtualDeviceDetail.status.hardware) {
                        if (from.virtualDeviceDetail.status.hardware.audioOut && from.virtualDeviceDetail.status.hardware.audioOut[0]) {
                            to.currentSettings[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL] = from.virtualDeviceDetail.status.hardware.audioOut[0].masterSoundLevel;
                            to.currentSettings[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_INDEX] = from.virtualDeviceDetail.status.hardware.audioOut[0].masterSoundIndex;
                            to.currentSettings[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_MAXINDEX] = from.virtualDeviceDetail.status.hardware.audioOut[0].masterSoundMaxIndex;
                        }
                        if (from.virtualDeviceDetail.status.hardware.videoOut && from.virtualDeviceDetail.status.hardware.videoOut[0]) {
                            to.currentSettings[this.constantSvc.DEVKEY_HD_VIDEO_FORMAT] = from.virtualDeviceDetail.status.hardware.videoOut[0].format;
                            to.currentSettings[this.constantSvc.DEVKEY_HD_VIDEO_ROTATION] = from.virtualDeviceDetail.status.hardware.videoOut[0].rotation;
                            to.currentSettings[this.constantSvc.DEVKEY_HD_VIDEO_HDCP_ENABLED] = from.virtualDeviceDetail.status.hardware.videoOut[0].hdcp ? from.virtualDeviceDetail.status.hardware.videoOut[0].hdcp.enabled : false;
                        }
                    }
                    // app
                    if (from.virtualDeviceDetail.status.app) {
                        // app-start
                        const appstart: AppStartInfo = this.parseAppstart(from.virtualDeviceDetail.status.app.start);
                        to.currentSettings[this.constantSvc.DEVKEY_APPSTART] = appstart;
                        to.currentSettings[this.constantSvc.DEVKEY_APPSTART_CLASSNAME] = appstart.className;
                        to.currentSettings[this.constantSvc.DEVKEY_APPSTART_CONTENTURL] = appstart.uri;
                        to.currentSettings[this.constantSvc.DEVKEY_APPSTART_PACKAGENAME] = appstart.packageName;
                        to.currentSettings[this.constantSvc.DEVKEY_APPSTART_ACTION] = appstart.action;

                        // app-setting-console
                        if (from.virtualDeviceDetail.status.app.settings && from.virtualDeviceDetail.status.app.settings.console) {
                            to.currentSettings[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE] = from.virtualDeviceDetail.status.app.settings.console.disablePowerSaveTimer;
                            to.currentSettings[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT] = from.virtualDeviceDetail.status.app.settings.console.powerSaveTimerTimeoutMinutes;
                            to.currentSettings[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_ACTION] = from.virtualDeviceDetail.status.app.settings.console.powerSaveTimerTimeoutAction;
                        }

                        // metadata
                        if (from.virtualDeviceDetail.status.app.metadata) {
                            to.currentSettings[this.constantSvc.DEVKEY_APPMETADATA] = from.virtualDeviceDetail.status.app.metadata;
                        }
                    }

                    // fw
                    if (from.virtualDeviceDetail.status.system && from.virtualDeviceDetail.status.system.update && from.virtualDeviceDetail.status.system.update.info) {
                        to.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_INFO] = from.virtualDeviceDetail.status.system.update.info.firmware;
                        to.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_LASTCHECKTIME] = from.virtualDeviceDetail.status.system.update.info.lastCheckTime ? new Date(from.virtualDeviceDetail.status.system.update?.info?.lastCheckTime) : null;
                        to.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_RECVTIME] = new Date(from.virtualDeviceDetail.status.system.update.info.receivedTime);
                        to.currentSettings[this.constantSvc.DEVKEY_SYSTEM_UPDATE_FW_STATUS] = from.virtualDeviceDetail.status.system.update.info.updateStatus;
                    }

                    // features
                    if (from.virtualDeviceDetail.status.features) {
                        to.features.clearCache.isSupport = from.virtualDeviceDetail.status.features.clearCache ? from.virtualDeviceDetail.status.features.clearCache.isSupported : false;
                        to.features.maintenance.isSupport = from.virtualDeviceDetail.status.features.maintenance ? from.virtualDeviceDetail.status.features.maintenance.isSupported : false;
                        to.features.screenOff.isSupport = from.virtualDeviceDetail.status.features.scheduleScreenoff ? from.virtualDeviceDetail.status.features.scheduleScreenoff.isSupported : false;
                        to.features.otp.isSupport = from.virtualDeviceDetail.status.features.otp ? from.virtualDeviceDetail.status.features.otp.isSupported : false;
                        to.features.solution.isSupport = from.virtualDeviceDetail.status.features.solution ? from.virtualDeviceDetail.status.features.solution.isSupported : false;
                        to.features.powersave.isSupport = HelperLib.getAndroidGroup(to.currentSettings[this.constantSvc.DEVKEY_INFO_FW_VERSION]) === AndroidGroupType.And_44 ? false : true;
                        to.features.screensaver.isSupport = HelperLib.getAndroidGroup(to.currentSettings[this.constantSvc.DEVKEY_INFO_FW_VERSION]) === AndroidGroupType.And_44 ? false : true;
                        to.features.remoteControl.isSupport = from.virtualDeviceDetail.status.features.remoteControl ? from.virtualDeviceDetail.status.features.remoteControl.isSupported : false;
                        to.features.remoteControl.keyEventSupport = from.virtualDeviceDetail.status.features.remoteControl ? from.virtualDeviceDetail.status.features.remoteControl.keyEventSupported : false;
                    }

                    //if no enrolled scep, check if there has any enrolling scep.
                    if (!to.scep.inUse && to.scep.candidates.length === 1 && to.scep.candidates[0].certName.startsWith(this.constantSvc.SCEP_SERVER_ALIAS_PREFIX)) {
                        to.scep.inUse = to.scep.candidates[0];
                    }
                }

                //settings
                //info
                if (from.virtualDeviceDetail.settings) {
                    //hardware
                    if (from.virtualDeviceDetail.settings.hardware) {
                        if (from.virtualDeviceDetail.settings.hardware.audioOut && from.virtualDeviceDetail.settings.hardware.audioOut[0]) {
                            to.applySettings[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL] = from.virtualDeviceDetail.settings.hardware.audioOut[0].masterSoundLevel;
                            to.applySettings[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_INDEX] = from.virtualDeviceDetail.settings.hardware.audioOut[0].masterSoundIndex;
                            to.applySettings[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_MAXINDEX] = from.virtualDeviceDetail.settings.hardware.audioOut[0].masterSoundMaxIndex;
                        }
                        if (from.virtualDeviceDetail.status.hardware.videoOut && from.virtualDeviceDetail.settings.hardware.videoOut[0]) {
                            to.applySettings[this.constantSvc.DEVKEY_HD_VIDEO_FORMAT] = from.virtualDeviceDetail.settings.hardware.videoOut[0].format;
                            to.applySettings[this.constantSvc.DEVKEY_HD_VIDEO_ROTATION] = from.virtualDeviceDetail.settings.hardware.videoOut[0].rotation;
                            to.applySettings[this.constantSvc.DEVKEY_HD_VIDEO_HDCP_ENABLED] = from.virtualDeviceDetail.settings.hardware.videoOut[0].hdcp ? from.virtualDeviceDetail.settings.hardware.videoOut[0].hdcp.enabled : false;
                        }
                    }
                }
            }
            else if (to.isPaired) {
                to.onlineStatus = OnlineStatus.Syncing;
            }
        }

        this.update_online_status_by_servertime(to, serverTime);

        return to;
    }

    /**
     * Pair device (v1)
     * @param virtualDeviceName 
     * @param pairingCode 
     */
    pair(virtualDeviceName: string, pairingCode: string, existVirtualDeviceID: string, toGroupID?: string): void {
        Logger.logInfo(this.className, 'pair', 'Pair device ' + virtualDeviceName + ' with pairing code = ' + pairingCode + ' to device group ' + toGroupID + '. Exist vID? = ' + existVirtualDeviceID);
        //Add device and denotes its status as pairing.
        const dev_new: DeviceInfo = this._deviceMap[existVirtualDeviceID] ? this._deviceMap[existVirtualDeviceID].dev : new DeviceInfo();
        dev_new.virtualName = virtualDeviceName;
        dev_new.onlineStatus = OnlineStatus.Pairing;
        dev_new.currentSettings[this.constantSvc.DEVKEY_FAKE_DISPLAYNAME] = virtualDeviceName;

        of(dev_new).pipe(
            concatMap((device: DeviceInfo) => {
                if (existVirtualDeviceID) {
                    //virtual device already exists
                    device.virtualId = existVirtualDeviceID;
                    return of(device);
                }

                //should create a virtual device
                return this.naSvc.createVirtualDevice({ virtualDeviceName: virtualDeviceName }, this.accountSvc.token).pipe(
                    map((res: IAPIRx<IVirtualDeviceRxData>) => {
                        if (res.error !== 0) {
                            throw CustomResponse.fail(res.error, res.errorMessage);
                        }

                        device.virtualId = res.data.virtualDeviceID;
                        this._deviceMap[res.data.virtualDeviceID] = this._deviceMap[res.data.virtualDeviceID] || { dev: device, keep: true };

                        this.devicePairStatusChanged.emit({
                            virtualDeviceName: virtualDeviceName,
                            pairingCode: pairingCode,
                            virtualDeviceID: device.virtualId,
                            isPaired: false,
                            statusHint: VirtualDeviceStatusHint.DeviceCreated,
                            device: device,
                            isFault: false
                        });

                        return device;
                    })
                )
            }),
            concatMap((device: DeviceInfo) => {
                return this.naSvc.pairVirtualDevice({ virtualDeviceID: device.virtualId }, { pairingCode: pairingCode }, this.accountSvc.token).pipe(
                    map((res: IAPIRx<IVirtualDeviceRxData>) => {
                        if (res.error !== 0) {
                            throw CustomResponse.failWithData<string>(device.virtualId, res.error, res.errorMessage);
                        }

                        device.virtualId = res.data.virtualDeviceID;
                        device.virtualPairId = res.data.virtualDevicePairedID;
                        device.virtualDeviceOwner = res.data.virtualDeviceOwnerAccountName;
                        device.virtualDeviceOwnerID = res.data.virtualDeviceOwnerAccountID;
                        device.onlineStatus = OnlineStatus.Syncing;
                        device.groupID = res.data.virtualDeviceGroupID;
                        device.isPaired = true;

                        this.updateDeviceListByMap();

                        this.devicePairStatusChanged.emit({
                            virtualDeviceName: virtualDeviceName,
                            pairingCode: pairingCode,
                            virtualDeviceID: device.virtualId,
                            statusHint: VirtualDeviceStatusHint.DevicePaired,
                            isPaired: true,
                            device: device,
                            isFault: false
                        });

                        Logger.logInfo(this.className, 'pair', 'Pair device ' + virtualDeviceName + ' with code ' + pairingCode + ' pass. Wait heartbeat');

                        return device;
                    }),
                )
            }),
            concatMap((device: DeviceInfo) => {
                if (toGroupID) {
                    //update to new group
                    return this.naSvc.updateDeviceInDeviceGroup({ deviceGroupID: toGroupID }, { addList: [device.virtualId] }, this.accountSvc.token).pipe(
                        map((res: IAPIRx<any>) => {
                            if (res.error === 0) {
                                device.groupID = toGroupID;
                            }

                            return device;
                        })
                    );
                }

                return of(device);
            }),
            delay(30000),
            concatMap((device: DeviceInfo) => {
                if (this._deviceMap[device.virtualId]) {
                    return this.naSvc.getVirtualDevice({ virtualDeviceID: device.virtualId }, this.DEV_REQ_ATTRIBUTES, this.accountSvc.token).pipe(
                        map((res: IAPIRx<IVirtualDeviceRxData>) => {
                            if (res.error === 0 && res.data) {
                                this.transformDevice(res.data, device, res.serverTime);

                                this.devicePairStatusChanged.emit({
                                    virtualDeviceName: virtualDeviceName,
                                    pairingCode: pairingCode,
                                    virtualDeviceID: device.virtualId,
                                    statusHint: VirtualDeviceStatusHint.DeviceSynced,
                                    isPaired: true,
                                    device: device,
                                    isFault: false
                                });
                            }

                            return CustomResponse.success(device);
                        })
                    );
                }
                else {
                    return of(CustomResponse.success(null));
                }
            }),
            catchError((err: CustomResponse<any>) => {
                Logger.logError(this.className, 'pair', 'Pair failed. Exception = ', err);

                if (this.devicePairStatusChanged) {
                    this.devicePairStatusChanged.emit({
                        virtualDeviceName: virtualDeviceName,
                        pairingCode: pairingCode,
                        virtualDeviceID: err.data,
                        isPaired: false,
                        device: null,
                        statusHint: VirtualDeviceStatusHint.Failed,
                        isFault: true,
                        error: err.error ? err.error.toString() : 'Unknown',
                        errorMessage: err.errorMessage || (err.error ? err.error.toString() : 'Unknown')
                    });
                }

                return of(err);
            })
        ).subscribe((res: CustomResponse<DeviceInfo>) => { });
    }

    /**
     * Unpair device (v1)
     * @param virtualDeviceId
     * @param forceDelete If to remove the virtual device after unpairing.
     */
    unPair(virtualDeviceID: string): void {
        Logger.logInfo(this.className, 'unpair', 'Unpair device with vID = ' + virtualDeviceID);

        this.naSvc.unpairVirtualDevice({ virtualDeviceID: virtualDeviceID }, { removeVirtualDevice: true }, this.accountSvc.token).pipe(
            map((res: IAPIRx<IVirtualDeviceRxData>) => {
                if (res && res.error !== 0) {
                    throw CustomResponse.fail(res.error, res.errorMessage);
                }

                const device: DeviceInfo = this._deviceMap[virtualDeviceID].dev;
                delete this._deviceMap[virtualDeviceID];
                this.updateDeviceListByMap();

                //emit a event to notify the device is unpaired
                if (this.deviceUnpairStatusChanged) {
                    this.deviceUnpairStatusChanged.emit({
                        virtualDeviceID: virtualDeviceID,
                        isPaired: false,
                        device: device,
                        isFault: false
                    });
                }

                return CustomResponse.success<null>();
            }),
            catchError((err: CustomResponse<null>) => {
                Logger.logError(this.className, 'unpair', 'Exception = ', err);

                if (this.deviceUnpairStatusChanged) {
                    this.deviceUnpairStatusChanged.emit({
                        virtualDeviceID: virtualDeviceID,
                        isPaired: false,
                        device: null,
                        isFault: true,
                        error: err.error.toString(),
                        errorMessage: err.errorMessage || err.error.toString()
                    });
                }

                return of(err);
            })
        ).subscribe((res: CustomResponse<DeviceInfo>) => { });
    }

    /**
     * Delete a virtual device. (v1)
     * @param virtualDeviceId 
     */
    delete(virtualDeviceID: string): Observable<CustomResponse<null>> {
        Logger.logInfo(this.className, 'delete', 'Delete device with vID = ' + virtualDeviceID);
        if (!virtualDeviceID) {
            return of(CustomResponse.success<null>());
        }

        return this.naSvc.removeVirtualDevice({ virtualDeviceID: virtualDeviceID }, this.accountSvc.token).pipe(
            map((res: IAPIRx<IVirtualDeviceRxData>) => {
                if (!res || res.error !== 0) {
                    throw CustomResponse.fail(res.error, res.errorMessage);
                }

                const device: DeviceInfo = this._deviceMap[virtualDeviceID].dev;
                delete this._deviceMap[virtualDeviceID];
                this.updateDeviceListByMap();

                if (this.deviceUnpairStatusChanged) {
                    this.deviceUnpairStatusChanged.emit({
                        virtualDeviceID: virtualDeviceID,
                        isPaired: false,
                        device: null,
                        isFault: false,
                    });
                }

                return CustomResponse.success<null>();
            }),
            catchError((err: CustomResponse<null>) => {
                Logger.logError(this.className, 'delete', 'Exception = ', err);

                if (this.deviceUnpairStatusChanged) {
                    this.deviceUnpairStatusChanged.emit({
                        virtualDeviceID: virtualDeviceID,
                        isPaired: false,
                        device: null,
                        isFault: true,
                        error: err.error.toString(),
                        errorMessage: err.errorMessage
                    });
                }

                return of(err);
            })
        );
    }

    batchScheduleUpdate(configList: { device: DeviceInfo, lockScreenData?: LockScreenInfo, maintenanceData?: MaintenancePlaybackInfo, options?: { updated?: { appStart?: { data?: string, packageName?: string, className?: string, action?: string, extras?: { [key: string]: any }, overlay?: { data: string, type: string, extras?: any, styles?: any[] } } } } }[]): Observable<{ device: DeviceInfo, isFault: boolean, errorMessage?: string }[]> {
        Logger.logInfo(this.className, 'batchScheduleUpdate', 'schedule configList = ', configList);

        return of(true).pipe(
            concatMap(() => {
                const obs: Observable<{ device: DeviceInfo, isFault: boolean, errorMessage?: string }>[] = [];

                configList.forEach(config => {
                    let setCalendarTxData: ISetVirtualDeviceCalendarTxData = { calendarList: [] };
                    let removeCalendarTxData: IRemoveVirtualDeviceCalendarQueryParameter = { typeList: [] };
                    if (config.lockScreenData) {
                        if (config.lockScreenData.enabled) {
                            setCalendarTxData.calendarList.push(config.lockScreenData.transformToRawdata({ appStart: config.options?.updated?.appStart }));
                            switch (config.lockScreenData.lockScreenMode) {
                                case LockScreenMode.screenOff:
                                    {
                                        removeCalendarTxData.typeList.push(LockScreenMode.screenSaver);
                                    }
                                    break;
                                case LockScreenMode.screenSaver:
                                    {
                                        removeCalendarTxData.typeList.push(LockScreenMode.screenOff);
                                    }
                                    break;
                            }
                        }
                        else {
                            removeCalendarTxData.typeList.push(LockScreenMode.screenSaver, LockScreenMode.screenOff);
                        }
                    }
                    if (config.maintenanceData) {
                        if (config.maintenanceData.enabled) {
                            setCalendarTxData.calendarList.push(config.maintenanceData.transformToRawdata());
                        }
                        else {
                            removeCalendarTxData.typeList.push('maintenance');
                        }
                    }

                    if (setCalendarTxData.calendarList.length > 0 || removeCalendarTxData.typeList.length > 0) {
                        const errorList: string[] = [];
                        obs.push(
                            of(true).pipe(
                                concatMap(() => {
                                    if (setCalendarTxData.calendarList.length > 0) {
                                        return this.naSvc.setVirtualDeviceCalendar(
                                            { virtualDeviceID: config.device.virtualId, virtualDevicePairedID: config.device.virtualPairId },
                                            setCalendarTxData,
                                            this.accountSvc.token
                                        ).pipe(
                                            map((res: IAPIRx<IVirtualDeviceCalendarItem[]>) => {
                                                if (res.error === 0) {
                                                    if (config.lockScreenData) {
                                                        config.device.currentSettings[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN] = config.lockScreenData;
                                                    }
                                                    if (config.maintenanceData) {
                                                        config.device.currentSettings[this.constantSvc.DEVKEY_FAKE_MAINTENANCE] = config.maintenanceData;
                                                    }
                                                }
                                                else {
                                                    errorList.push(res.errorMessage);
                                                }

                                                return true;
                                            })
                                        )
                                    }

                                    return of(true);
                                }),
                                concatMap(() => {
                                    if (removeCalendarTxData.typeList.length > 0) {
                                        return this.naSvc.removeVirtualDeviceCalendar(
                                            { virtualDeviceID: config.device.virtualId, virtualDevicePairedID: config.device.virtualPairId },
                                            removeCalendarTxData,
                                            this.accountSvc.token
                                        ).pipe(
                                            map((res: IAPIRx<IVirtualDeviceCalendarItem[]>) => {
                                                if (res.error === 0) {
                                                    if (config.lockScreenData) {
                                                        config.device.currentSettings[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN] = config.lockScreenData;
                                                    }
                                                    if (config.maintenanceData) {
                                                        config.device.currentSettings[this.constantSvc.DEVKEY_FAKE_MAINTENANCE] = config.maintenanceData;
                                                    }
                                                }
                                                else {
                                                    errorList.push(res.errorMessage);
                                                }

                                                return true;
                                            })
                                        )
                                    }

                                    return of(true);
                                }),
                                map(() => {
                                    return {
                                        device: config.device,
                                        isFault: errorList.length > 0 ? true : false,
                                        errorMessage: errorList.join(', ')
                                    };
                                })
                            )
                        );
                    }
                });

                return forkJoin(obs);
            })
        );
    }

    batchRemoteConnect(deviceList: DeviceInfo[], options: { room: string }, subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Remote control';
        return this.batchTaskSet(subject, comment, null, deviceList, [{ taskAction: this.constantSvc.TASKTYPE_REMOTE_CONTROL, resourceData: { room: options.room } }]);
    }

    batchReboot(deviceList: DeviceInfo[], subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Reboot';
        return this.batchTaskSet(subject, comment, null, deviceList, [{ taskAction: this.constantSvc.TASKTYPE_REBOOT, resourceData: null }]);
    }

    batchNetConfig(deviceList: DeviceInfo[], configData: { name: string, value: any, origin?: any, langKey?: string, hidden?: boolean }[], subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Network configuration';
        const modified = {
            modified: configData.map(data => {
                return {
                    name: data.name,
                    value: data.value
                }
            })
        };

        return this.batchTaskSet(subject, comment, null, deviceList, [{ taskAction: this.constantSvc.TASKTYPE_CONFIG_NET, resourceData: Object.assign({ settings: configData }, modified) }]);
    }

    batchBasicConfig(groupDataList: { device: DeviceInfo, configData: { name: string, value: any, origin?: any, langKey?: string, hidden?: boolean }[] }[], subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Basic configuration';
        return groupDataList.length === 1 ?
            this.batchBasicConfigForSingleDevice(groupDataList[0].device, groupDataList[0].configData, subject) :
            this.batchTaskSetByGroup(this.constantSvc.TASKTYPE_CONFIG_BASIC, subject, '', null, groupDataList.map(g => {
                return {
                    device: g.device,
                    resourceData: Object.assign({ settings: g.configData }, { modified: g.configData.map(c => { return { name: c.name, value: c.value } }) })
                }
            }));
    }

    private batchBasicConfigForSingleDevice(d: DeviceInfo, configData: { name: string, value: any, origin?: any, langKey?: string, hidden?: boolean }[], subject?: string, comment?: string): Observable<CustomResponse<any>> {
        const modified = {
            modified: configData.map(data => {
                return {
                    name: data.name,
                    value: data.value
                }
            })
        };

        const updatePlayerNamePair: { name: string, value: any } = configData.find(i => i.name === this.constantSvc.DEVKEY_INFO_PNAME);
        return of(true).pipe(
            concatMap(() => {
                if (updatePlayerNamePair) {
                    return this.naSvc.updateVirtualDevice({ virtualDeviceID: d.virtualId }, { virtualDeviceName: updatePlayerNamePair.value }, this.accountSvc.token).pipe(
                        map((res: IAPIRx<IVirtualDeviceRxData>) => {
                            if (res.error === 0 && res.data) {
                                d.virtualName = res.data.virtualDeviceName;
                                d.currentSettings[this.constantSvc.DEVKEY_FAKE_DISPLAYNAME] = res.data.virtualDeviceName;
                            }

                            return true;
                        })
                    )
                }
                else { return of(true); }
            }),
            concatMap((res: boolean) => {
                const taskDataList = [
                    {
                        taskAction: this.constantSvc.TASKTYPE_CONFIG_BASIC,
                        resourceData: Object.assign({ settings: configData }, modified)
                    }
                ]
                return this.batchTaskSet(subject, comment, null, [d], taskDataList);
            })
        );
    }

    batchClearCache(deviceList: DeviceInfo[], subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Clear cache';
        return this.batchTaskSet(subject, comment, null, deviceList, [{ taskAction: this.constantSvc.TASKTYPE_CLEARCACHE, resourceData: null }]);
    }

    batchClearAppData(deviceList: DeviceInfo[], packageList: string[], subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Clear app data';
        return this.batchTaskSet(subject, comment, null, deviceList, [{
            taskAction: this.constantSvc.TASKTYPE_CLEARAPPDATA, resourceData: {
                pkgList: { packages: packageList }
            }
        }]);
    }

    batchReloadLicenseByID(virtualDeviceIDList: string[]): Observable<CustomResponse<any>> {
        return this.batchReloadLicense(virtualDeviceIDList.map(vID => this._deviceMap[vID] ? this._deviceMap[vID].dev : null));
    }

    batchReloadLicense(deviceList: DeviceInfo[], subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Reload license';
        deviceList = deviceList.filter(d => d);

        return this.batchTaskSet(subject, comment, null, deviceList, [{ taskAction: this.constantSvc.TASKTYPE_APP_LICENSE_RELOAD, resourceData: null }]);
    }

    batchAPKUpdate(groupDataList: { device: DeviceInfo, downloadLink: string, downloadVersion: string, md5: string }[], subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Update IAdeaCare APK';

        let bCommon: boolean = true;
        if (groupDataList.length > 1) {
            for (let i = 1; i < groupDataList.length; ++i) {
                if (groupDataList[i].downloadLink !== groupDataList[0].downloadLink) {
                    bCommon = false;
                    break;
                }
            }
        }

        if (bCommon) {
            return this.batchTaskSet(
                subject,
                comment,
                null,
                groupDataList.map(data => data.device),
                [{ taskAction: this.constantSvc.TASKTYPE_INSTALL_APK, resourceData: { url: groupDataList[0].downloadLink, version: groupDataList[0].downloadVersion, md5: groupDataList[0].md5 ? groupDataList[0].md5 : '', settings: [{ name: 'APK version', value: groupDataList[0].downloadVersion }, { name: 'download link', value: groupDataList[0].downloadLink }] }, retryTimeout: 3600 }]
            )
        }
        else {
            return this.batchTaskSetByGroup(
                this.constantSvc.TASKTYPE_INSTALL_APK,
                subject,
                comment,
                null,
                groupDataList.map(data => {
                    return {
                        device: data.device,
                        resourceData: { url: data.downloadLink, version: data.downloadVersion, md5: data.md5 ? data.md5 : '', settings: [{ name: 'APK version', value: data.downloadVersion }, { name: 'download link', value: data.downloadLink }] }
                    }
                }),
                3600
            );
        }
    }

    batchFirmwareCheck(devices: DeviceInfo[], scheduleDate?: Date, subject?: string, comment?: string): Observable<CustomResponse<ITicketData>> {
        subject = subject || 'Check firmware';

        return this.batchTaskSet(
            subject,
            comment,
            scheduleDate,
            devices,
            [{ taskAction: this.constantSvc.TASKTYPE_CHECK_FIRMWARE, resourceData: null }]
        );
    }

    batchFirmwareUpdate(groupDataList: { device: DeviceInfo, downloadLink: string, downloadVersion?: string, md5?: string }[], scheduleDate?: Date, subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Update firmware';

        let bCommon: boolean = true;
        if (groupDataList.length > 1) {
            for (let i = 1; i < groupDataList.length; ++i) {
                if (groupDataList[i].downloadLink !== groupDataList[0].downloadLink) {
                    bCommon = false;
                    break;
                }
            }
        }

        if (bCommon) {
            return this.batchTaskSet(
                subject,
                comment,
                scheduleDate,
                groupDataList.map(data => data.device),
                [{ taskAction: this.constantSvc.TASKTYPE_INSTALL_FIRMWARE, resourceData: groupDataList[0].downloadLink ? { url: groupDataList[0].downloadLink, settings: [{ name: 'link', value: groupDataList[0].downloadLink }] } : { settings: [{ name: 'link', value: 'latest firmware' }] }, retryTimeout: 60 * 60 * 3 }]
            )
        }
        else {
            return this.batchTaskSetByGroup(
                this.constantSvc.TASKTYPE_INSTALL_FIRMWARE,
                subject,
                comment,
                scheduleDate,
                groupDataList.map(data => {
                    return {
                        device: data.device,
                        resourceData: data.downloadLink ? { url: data.downloadLink, settings: [{ name: 'link', value: data.downloadLink }] } : { settings: [{ name: 'link', value: 'latest firmware' }] }
                    }
                }),
                60 * 60 * 3
            );
        }
    }

    batchUpdateLocalPassword(deviceList: DeviceInfo[], pwd: string, subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Update local password';
        return this.batchTaskSet(subject, comment, null, deviceList, [{ taskAction: this.constantSvc.TASKTYPE_SECURITY_LOCALPWD, resourceData: { [this.constantSvc.DEVKEY_FAKE_DEVICE_PASSWORD]: pwd } }]);
    }

    batchTaskScreenshot(deviceList: DeviceInfo[], size: string = 'small', toSave: boolean = false, subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Take screenshot';
        deviceList.forEach(dev => {
            this.resetCache(dev, DeviceCacheType.screenshot);
        });

        return this.batchTaskSet(subject, comment, null, deviceList, [{ taskAction: this.constantSvc.TASKTYPE_SCREENSHOT, resourceData: { Size: size, SaveAs: toSave } }]);
    }

    batchUpdateAvailableOption(deviceList: DeviceInfo[], subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Sync available option';
        return this.batchTaskSet(subject, comment, null, deviceList, [{ taskAction: this.constantSvc.TASKTYPE_DATA_SYNC, resourceData: { SyncItemList: [{ name: 'availableOptions' }] }, retryTimeout: 180 }]);
    }

    batchSyncCurrentSetting(deviceList: DeviceInfo[], subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Sync current settings';
        return this.batchTaskSet(subject, comment, null, deviceList, [{ taskAction: this.constantSvc.TASKTYPE_DATA_SYNC, resourceData: { SyncItemList: [{ name: 'currentSettings' }, { name: 'shadowDevice' }] }, retryTimeout: 180 }]);
    }

    batchDoCustomAction(deviceList: DeviceInfo[], options: { actionID: string, resourceData?: any, subject?: string, comment?: string }): Observable<CustomResponse<any>> {
        return this.batchTaskSet(options?.subject || 'CA instructions', options?.comment, null, deviceList, [{ taskAction: options.actionID, resourceData: options.resourceData, retryTimeout: 180 }]);
    }

    batchScepEnroll(update: boolean, deviceList: DeviceInfo[], scepInfo: ScepServerInfo, scepChallengeInfo: ScepChallengeInfo, subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Enroll scep credential';

        return this.batchTaskSet(
            subject,
            comment || '',
            null,
            deviceList,
            [
                {
                    taskAction: this.constantSvc.TASKTYPE_CONFIG_NET,
                    resourceData: {
                        modified: [
                            {
                                name: 'security.certs.' + (update ? 'update' : 'insert'),
                                value: {
                                    name: scepInfo.alias,
                                    key: {
                                        size: scepInfo.keysize || null,
                                        algorithm: null
                                    },
                                    provider: {
                                        url: scepInfo.url,
                                        profile: scepInfo.profile || null,
                                        type: 'SCEP',
                                        fingerprint: null,
                                        fingerprintAlgorithm: null
                                    },
                                    renewalDaysBeforeExpiration: scepInfo.autoRenewDay,
                                    request: {
                                        subject: scepInfo.subject || null,
                                        signatureAlgorithm: null
                                    },
                                    usages: [scepInfo.usage]
                                }
                            },
                            {
                                name: 'security.certs.enroll',
                                value: {
                                    name: scepInfo.alias,
                                    password: scepChallengeInfo.challengePassword
                                }
                            }
                        ]
                    },
                    retryTimeout: 86400 * 365
                }
            ]
        ).pipe(
            concatMap((res: CustomResponse<ITicketData>) => {
                Logger.logInfo('devSvc', 'batchScepEnroll', 'set 802.1x', res);
                if (res.isFault()) {
                    return of(res);
                }

                const groupDataList: { device: DeviceInfo, resourceData: any, preTaskID?: string }[] = [];
                res.data.ticketStatus.taskList.forEach((td: ITaskStatusData) => {
                    if (td.virtualDevice) {
                        const dev: DeviceInfo = this._deviceMap[td.virtualDevice.virtualDeviceID]?.dev;
                        if (dev) {
                            groupDataList.push({
                                device: dev,
                                preTaskID: td.taskID,
                                resourceData: {
                                    modified: [
                                        {
                                            name: 'net.ethernet.enabled',
                                            value: true
                                        },
                                        {
                                            name: 'net.ethernet.authentication',
                                            value: 'EAP'
                                        },
                                        {
                                            name: 'net.ethernet.eap.method',
                                            value: 'TLS'
                                        },
                                        {
                                            name: 'net.ethernet.eap.identity',
                                            value: scepChallengeInfo.eapIdentity === 'MAC' ? dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC] : dev.currentSettings[this.constantSvc.DEVKEY_INFO_PID]
                                        },
                                        {
                                            name: 'security.certs.sync',
                                            value: {
                                                name: scepInfo.alias
                                            }
                                        }
                                        /*,
                                        {
                                            name: 'net.ethernet.eap.domain_suffix_match',
                                            value: null
                                        },
                                        {
                                            name: 'net.ethernet.eap.ca_path',
                                            value: 'system' || ''
                                        }
                                        */
                                    ]
                                }
                            });
                        }
                    }
                });

                return this.batchTaskSetByGroup(this.constantSvc.TASKTYPE_CONFIG_NET, 'Network setting', comment, null, groupDataList);
            })
        );
    }

    batchScepRenew(deviceList: DeviceInfo[], scepInfo: ScepServerInfo, scepChallengeInfo?: ScepChallengeInfo, subject?: string, comment?: string): Observable<CustomResponse<any>> {
        subject = subject || 'Renew scep credential';

        return this.batchTaskSet(
            subject,
            comment,
            null,
            deviceList,
            [
                {
                    taskAction: this.constantSvc.TASKTYPE_CONFIG_NET,
                    resourceData: {
                        modified: [
                            {
                                name: 'security.certs.renew',
                                value: {
                                    name: scepInfo.alias,
                                    //password: scepChallengeInfo.challengePassword
                                }
                            }
                        ]
                    },
                    retryTimeout: 180
                }
            ]
        );
    }

    private batchTaskSetForSingleData(subject: string, comment: string, deviceList: DeviceInfo[], ticketID: string, isHold: boolean, taskDataList: { taskAction: string, resourceData: any, retryTimeout?: number }[], scheduleDateTime: Date): Observable<IAPIRx<ITicketData>> {
        const txData: ICreateTicketTxData = {
            ticketAction: this.constantSvc.TICKETTYPE_TASK,
            isHold: isHold,
            scheduledDate: scheduleDateTime,
            resourceData: {
                ticketSubject: subject,
                ticketBody: comment,
                virtualDeviceList: deviceList.map(d => { return { virtualDeviceID: d.virtualId, virtualDevicePairedID: d.virtualPairId } })
            }
        };

        if (ticketID) {
            txData.ticketID = ticketID;
        }
        if (taskDataList) {
            txData.resourceData.taskList = taskDataList;
        }

        return this.naSvc.createTicket(txData, this.accountSvc.token).pipe(
            map(res => {
                if (res.error === 0) {
                    deviceList.forEach(d => {
                        this.resetCache(d, DeviceCacheType.activity);
                    });
                }

                return res;
            })
        );
    }

    private batchTaskSet(subject: string, comment: string = '', scheduleDateTime: Date, deviceList: DeviceInfo[], taskDataList: { taskAction: string, resourceData: any, retryTimeout?: number }[]): Observable<CustomResponse<ITicketData>> {
        //use first isHold trial to get the ticketID
        if (deviceList.length <= this.BATCH_TASK_COUNT) {
            return this.batchTaskSetForSingleData(subject, comment, deviceList, null, false, taskDataList, scheduleDateTime).pipe(
                map((res: IAPIRx<ITicketData>) => {
                    if (res.error !== 0) {
                        throw CustomResponse.fail(res.error, res.errorMessage);
                    }

                    return CustomResponse.success(res.data);
                }),
                catchError((err: CustomResponse<any>) => {
                    return of(err);
                })
            );
        }
        else {
            Logger.logInfo('devSvc', 'batchTaskSet', 'Divide to incremental tasks');
            let isHold: boolean = true;
            //get ticketID by first batch task trial
            const firstrunDeviceList: DeviceInfo[] = [];
            for (let i = 0; i < this.BATCH_TASK_COUNT; ++i) {
                firstrunDeviceList.push(deviceList[i]);
            }

            return this.batchTaskSetForSingleData(subject, comment, firstrunDeviceList, null, true, taskDataList, scheduleDateTime).pipe(
                concatMap((res: IAPIRx<ITicketData>) => {
                    if (res.error !== 0) {
                        throw CustomResponse.fail(res.error, res.errorMessage);
                    }

                    const obs: Observable<{
                        hasNext: boolean;
                        error?: number | string;
                        errorMessage?: string;
                        taskStatusList?: ITaskStatusData[]
                    }>[] = [];

                    for (let i = this.BATCH_TASK_COUNT; i < deviceList.length; i += this.BATCH_TASK_COUNT) {
                        let end: number = i + this.BATCH_TASK_COUNT;
                        if (end >= deviceList.length) {
                            end = deviceList.length;
                            isHold = false;
                        }

                        const updateDeviceList: DeviceInfo[] = [];
                        for (let j = i; j < end; ++j) {
                            updateDeviceList.push(deviceList[j]);
                        }

                        obs.push(
                            this.batchTaskSetForSingleData(subject, comment, updateDeviceList, res.data.ticketID, isHold, null, scheduleDateTime).pipe(
                                map((res: IAPIRx<ITicketData>) => ({
                                    hasNext: true,
                                    error: res.error,
                                    errorMessage: res.error !== 0 ? HelperLib.getErrorMessage(res) : '',
                                    data: res.data
                                }))
                            )
                        );
                    }

                    obs.push(of({ hasNext: false }));

                    const emitOb: Subject<void> = new Subject();
                    return of(true).pipe(
                        concatMap(() => obs),
                        concatAll(),
                        map((res: {
                            hasNext: boolean;
                            error?: number | string;
                            errorMessage?: string;
                            data?: ITicketData;
                        }) => {
                            if (!res.hasNext) {
                                emitOb.next();
                                emitOb.complete();
                            }

                            return res;
                        }),
                        buffer(emitOb),
                        map((res: {
                            hasNext: boolean;
                            error?: number | string;
                            errorMessage?: string;
                            data?: ITicketData
                        }[]) => {
                            Logger.logInfo('devSvc', 'batchTaskSet', 'incremental result = ', res);
                            const lastRet = res[res.length - 1];
                            return lastRet.error === 0 ? CustomResponse.success(lastRet.data) : CustomResponse.fail(lastRet.error, lastRet.errorMessage);
                        })
                    )
                }),
                catchError((err: CustomResponse<any>) => of(err))
            )
        }
    }

    private batchTaskSetForMultiData(subject: string, comment: string, taskAction: string, ticketID: string, isHold: boolean, groupDataList: { device: DeviceInfo, resourceData: any, preTaskID?: string }[], scheduleDateTime: Date, retryTimeout?: number): Observable<IAPIRx<ITicketData>> {
        const txData: ICreateTicketTxData = {
            ticketAction: this.constantSvc.TICKETTYPE_TASK,
            scheduledDate: scheduleDateTime,
            isHold: isHold,
            resourceData: {
                ticketSubject: subject,
                ticketBody: comment,
                groupedTaskList: groupDataList.map(gt => {
                    const d: any = {
                        virtualDeviceID: gt.device.virtualId,
                        virtualDevicePairedID: gt.device.virtualPairId,
                        taskAction: taskAction,
                        resourceData: gt.resourceData,
                        retryTimeout: retryTimeout
                    }

                    if (gt.preTaskID) {
                        d.preConditionList = [gt.preTaskID + ':success']
                    }

                    return d;
                })
            }
        };

        if (ticketID) {
            txData.ticketID = ticketID;
        }

        return this.naSvc.createTicket(txData, this.accountSvc.token).pipe(
            map(res => {
                if (res.error === 0) {
                    groupDataList.forEach(d => {
                        this.resetCache(d.device, DeviceCacheType.activity);
                    });
                }

                return res;
            })
        );
    }

    private batchTaskSetByGroup(taskAction: string, subject: string, comment: string = '', scheduleDate: Date, groupDataList: { device: DeviceInfo, resourceData: any, preTaskID?: string }[], retryTimeout?: number): Observable<CustomResponse<ITaskStatusData[]>> {
        //use first isHold trial to get the ticketID
        if (groupDataList.length <= this.BATCH_TASK_COUNT) {
            return this.batchTaskSetForMultiData(subject, comment, taskAction, null, false, groupDataList, scheduleDate, retryTimeout).pipe(
                map((res: IAPIRx<ITicketData>) => {
                    if (res.error !== 0) {
                        throw CustomResponse.fail(res.error, res.errorMessage);
                    }

                    return CustomResponse.success(res.data.ticketStatus.taskList);
                }),
                catchError((err: CustomResponse<any>) => {
                    Logger.logError('DeviceSvc', 'batchTaskSetByGroup', 'Set batch task for ' + taskAction + ' Failed.', err);

                    return of(err);
                })
            );
        }
        else {
            Logger.logInfo('devSvc', 'batchTaskSet', 'Divide to incremental tasks');
            let isHold: boolean = true;
            //get ticketID by first batch task trial
            const firstrunGroupDataList: { device: DeviceInfo, resourceData: any, preTaskID?: string }[] = [];
            for (let i = 0; i < this.BATCH_TASK_COUNT; ++i) {
                firstrunGroupDataList.push(groupDataList[i]);
            }

            return this.batchTaskSetForMultiData(subject, comment, taskAction, null, true, firstrunGroupDataList, scheduleDate, retryTimeout).pipe(
                concatMap((res: IAPIRx<ITicketData>) => {
                    if (res.error !== 0) {
                        throw CustomResponse.fail(res.error, res.errorMessage);
                    }

                    const obs: Observable<{
                        hasNext: boolean;
                        error?: number | string;
                        errorMessage?: string;
                        taskDataList?: ITaskStatusData[]
                    }>[] = [];

                    for (let i = this.BATCH_TASK_COUNT; i < groupDataList.length; i += this.BATCH_TASK_COUNT) {
                        let end: number = i + this.BATCH_TASK_COUNT;
                        if (end >= groupDataList.length) {
                            end = groupDataList.length;
                            isHold = false;
                        }

                        const updateGroupDataList: { device: DeviceInfo, resourceData: any, preTaskID?: string }[] = [];
                        for (let j = i; j < end; ++j) {
                            updateGroupDataList.push(groupDataList[j]);
                        }

                        obs.push(
                            this.batchTaskSetForMultiData(subject, comment, taskAction, res.data.ticketID, isHold, updateGroupDataList, scheduleDate, retryTimeout).pipe(
                                map((res: IAPIRx<ITicketData>) => ({
                                    hasNext: true,
                                    error: res.error,
                                    errorMessage: res.error !== 0 ? HelperLib.getErrorMessage(res) : '',
                                    taskDataList: res.error === 0 ? res.data.ticketStatus.taskList : []
                                }))
                            )
                        );
                    }

                    obs.push(of({
                        hasNext: false
                    }));

                    const emitOb: Subject<void> = new Subject();

                    return of(true).pipe(
                        concatMap(() => obs),
                        concatAll(),
                        map((res: {
                            hasNext: boolean,
                            error?: number | string,
                            errorMessage?: string,
                            taskDataList?: ITaskStatusData[]
                        }) => {
                            if (!res.hasNext) {
                                emitOb.next();
                                emitOb.complete();
                            }

                            return res;
                        }),
                        buffer(emitOb),
                        map(res => {
                            Logger.logInfo('devSvc', 'batchTaskSet', 'incremental result = ', res);
                            const lastRet = res[res.length - 1];
                            return lastRet.error === 0 ? CustomResponse.success(lastRet.taskDataList) : CustomResponse.fail(lastRet.error, lastRet.errorMessage);
                        })
                    )
                }),
                catchError((err: CustomResponse<any>) => {
                    return of(err);
                })
            );
        }
    }

    private task_status_mapping(status: string, bSuccess: boolean = true): TaskStatus {
        switch (status) {
            default:
            case 'pending':
                return TaskStatus.pending;
            case 'progress':
                return TaskStatus.progress;
            case 'finish':
                return bSuccess ? TaskStatus.success : TaskStatus.fail;
        }
    }

    private needUpdate(trackUpdateTime: Date, updateDuration: number): boolean {
        return (!trackUpdateTime || Date.now() - trackUpdateTime.getTime() > updateDuration) ? true : false;
    }

    private update_online_status_by_servertime(devInfo: DeviceInfo, serverTime?: number): void {
        if (!serverTime) {
            return;
        }

        if (devInfo.currentSettings[this.constantSvc.DEVKEY_FAKE_HEARTBEAT]) {
            const diff_time = serverTime - devInfo.currentSettings[this.constantSvc.DEVKEY_FAKE_HEARTBEAT];
            devInfo.onlineStatus = this.update_online_status(diff_time);
        }
        else {
            devInfo.onlineStatus = OnlineStatus.Offline;
        }

        return;
    }

    private update_online_status(diff_time: number): OnlineStatus {
        return diff_time <= this.DEV_DISCONNECT_THRESHOLD ? OnlineStatus.Online : (
            diff_time <= this.DEV_OFFLINE_THRESHOLD ? OnlineStatus.Disconnect : OnlineStatus.Offline
        );
    }

    private update_available_option(from: IAPIRx<IGetVirtualDeviceAvailableOptionRxData>, to: AvailableOptionInfo): void {
        if (!from || !from.data || from.error) {
            return;
        }

        to.IsEmpty = false;
        to.Resolutions = from.data.Resolutions;
        to.Rotations = from.data.Rotations;
        to.Timezones = HelperLib.getTimezoneList(from.data.Timezones);
        to.HDCP = from.data.HDCP;

        to.ssids = [];
        from.data.SSIDs.forEach((ssid: string) => {
            let ssid_splits = ssid.split(',');

            if (ssid_splits[0] && ssid_splits[0] !== '' && ssid_splits[1] && ssid_splits[1] !== '') {
                if (ssid_splits[1].indexOf('EAP') > 0) {
                    return;
                }

                let si = new SSIDInfo();
                si.name = ssid_splits[0];
                si.auth = ssid_splits[1];

                to.ssids.push(si);
            }
        });
    }

    private parseAppstart(rawstr: any): AppStartInfo {
        let raw: any;
        try {
            raw = JSON.parse(rawstr);
        }
        catch (e) {
            raw = rawstr;
        }

        if (!raw) {
            return new AppStartInfo();
        }

        const appstart: AppStartInfo = new AppStartInfo();
        appstart.packageName = raw.packageName || ''; // || 'com.iadea.player';
        appstart.className = raw.className || ''; // || 'com.iadea.player.SmilActivity';
        appstart.uri = appstart.data = raw.uri || raw.data;
        appstart.action = raw.action || ''; // || 'android.intent.action.VIEW';
        appstart.extras = {};
        this.constantSvc.getAppstartExtraList().map(extra => {
            if (extra.type === 'checkbox') {
                appstart.extras[extra.property] = false;
            }
            else if (extra.type === 'number') {
                appstart.extras[extra.property] = undefined;
            }
        });

        if (raw.extras) {
            if (raw.extras instanceof Array) {
                raw.extras.forEach((extra: { name: string, value: any }) => {
                    appstart.extras[extra.name] = extra.value;
                });
            }
            else {
                Object.keys(raw.extras).forEach(key => {
                    appstart.extras[key] = raw.extras[key];
                });
            }
        }
        appstart.overlay = new AppStartOverlayInfo(raw.overlay);
        appstart.screensaver = new AppStartScreensaverInfo(raw.screensaver);

        return appstart;
    }

    isPlayerNameValid(name: string): boolean {
        return this._deviceList.find(d => d.virtualName === name) ? false : true;
    }

    //check if remote control is support
    isDeviceSupportRemoteCtrl(device?: DeviceInfo): boolean {
        //by config
        if (!AppConfigService.configs.devPage.func.enableRemoteControl) {
            return false;
        }
        //no user scope
        if (!this.accountSvc.hasScope_device_remoteControl()) {
            return false;
        }
        //UI is on mobile device
        if (HelperLib.isMobileDevice()) {
            return false;
        }
        //device itself not support
        if (device && !device.features.remoteControl.isSupport) {
            return false;
        }
        //enterprise
        if (this.accountSvc.isEnterprise()) {
            if (!this.accountSvc.hasScope_enterprise_admin() && !this.accountSvc.hasScope_enterprise_editor()) {
                return false;
            }
        }

        return true;
    }

    filterDevice(sources: DeviceInfo[], onlineStatusFilter?: { [state: string]: boolean }, filter?: { key: string, value: any }): DeviceInfo[] {
        let ds: DeviceInfo[] = sources.filter(d => d.isPaired);

        if (onlineStatusFilter) {
            ds = ds.filter((d: DeviceInfo) => onlineStatusFilter[d.onlineStatus]);
        }

        return filter?.value ? ds.filter((d: DeviceInfo) => {
            switch (filter.key) {
                case this.constantSvc.DEVKEY_INFO_PNAME:
                    {
                        if (d.virtualName) {
                            return d.virtualName.toLocaleLowerCase().indexOf(filter.value) >= 0;
                        }
                        else if (d.currentSettings[filter.key]) {
                            return d.currentSettings[filter.key].toLocaleLowerCase().indexOf(filter.value) >= 0;
                        }
                    }
                    break;
                case this.constantSvc.DEVKEY_NET_LAN_MAC:
                    {
                        if (d.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !d.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED]) {
                            if (d.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC]) {
                                return d.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC].toLocaleLowerCase().indexOf(filter.value) >= 0;
                            }
                        }
                        else if (d.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC]) {
                            return d.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC].toLocaleLowerCase().indexOf(filter.value) >= 0;
                        }
                    }
                    break;
                case this.constantSvc.DEVKEY_NET_LAN_IP:
                    {
                        if (d.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !d.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED]) {
                            if (d.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_IP]) {
                                return d.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_IP].toLocaleLowerCase().indexOf(filter.value) >= 0;
                            }
                        }
                        else if (d.currentSettings[this.constantSvc.DEVKEY_NET_LAN_IP]) {
                            return d.currentSettings[this.constantSvc.DEVKEY_NET_LAN_IP].toLocaleLowerCase().indexOf(filter.value) >= 0;
                        }
                    }
                    break;
                default:
                    {
                        if (d.currentSettings[filter.key]) {
                            return d.currentSettings[filter.key].toLocaleLowerCase().indexOf(filter.value) >= 0;
                        }
                    }
                    break;
            }
            return false;
        }) : ds;
    }

    exportDevices(sources: DeviceInfo[], activeGroupID: string, allDeviceGroupMap: { [groupID: string]: DeviceGroupInfo } = {}, policies: PolicyInfo[], options?: { onlineStatusFilter?: { [state: string]: boolean }, searchFilter?: { key: string, value: any } }): Observable<{ header: string, metadata?: string[], dataList: string[][], date: string }> {
        return of(true).pipe(
            map(() => {
                const date: string = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH_mm_ss');
                const header = 'DeviceList_' + date + '\n';

                const metadata: string[] = [`Input: ${this._deviceList.length}`];
                const rowList: string[][] = [];

                const policyColNames: string[] = Object.values(PolicyType).map(pt => `Policy (${pt})`);
                // column names
                rowList.push([
                    'Status',
                    'Device Name',
                    'Device ID',
                    'Virtual Device ID',
                    'Model',
                    'MAC',
                    'Serial number',
                    'IP(main)',
                    'Gateway',
                    'Subnet mask',
                    'Firmware',
                    'Last Heartbeat',
                    'Warranty',
                    'Owner',
                    'Group',
                    'IAdeaCare apk version',
                    '802.1x',
                    'EAP CA Enroll Status',
                    'EAP CA Expiry Date',
                    'Content URL',
                    ...policyColNames,
                    'Metadata',
                    'Physical LAN MAC',
                    'Physical Wi-Fi MAC',
                    'WebView provider'
                ]);
                let targetDeviceList: DeviceInfo[] = sources;
                let filterDescription: string = `Filter: ${targetDeviceList.length}, Condition:, Paired=>(true);${activeGroupID ? ` Group=>(${activeGroupID}); ` : ''}`;
                if (this._currentFilter.onlineStatus) {
                    filterDescription += `OnlineStatus=>(${Object.keys(this._currentFilter.onlineStatus).map(state => state + ':' + this._currentFilter.onlineStatus[state]).join(' | ')});`;
                }
                filterDescription += `Search=>(${this._currentFilter.search ? `key=${this._currentFilter.search.key} | value=${this._currentFilter.search.value}` : 'N/A'});`;
                if (this._currentFilter.rules?.length > 0) {
                    filterDescription += `Number of advanced filter items: (${this._currentFilter.rules.length})`;
                }

                metadata.push(filterDescription);
                const selectedDeviceList: DeviceInfo[] = targetDeviceList.filter(d => d.isSelect);
                metadata.push(`Checkbox: ${selectedDeviceList.length > 0 ? selectedDeviceList.length : 'N/A'}`);

                const policyMap: { [policyID: string]: PolicyInfo } = policies.reduce((acc, curr) => {
                    acc[curr.id] = curr;
                    return acc;
                }, {});

                targetDeviceList = selectedDeviceList.length === 0 ? targetDeviceList : selectedDeviceList;
                targetDeviceList.forEach(d => {
                    const useWifi: boolean = d.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !d.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED];
                    rowList.push([
                        d.onlineStatus,
                        '"' + d.virtualName + '"',
                        d.currentSettings[this.constantSvc.DEVKEY_INFO_PID],
                        d.virtualId,
                        d.currentSettings[this.constantSvc.DEVKEY_INFO_MODELDESC] || d.currentSettings[this.constantSvc.DEVKEY_INFO_MODEL],
                        useWifi ? d.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC] : d.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC],
                        d.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_SN],
                        useWifi ? d.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_IP] : d.currentSettings[this.constantSvc.DEVKEY_NET_LAN_IP],
                        useWifi ? d.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_GATEWAY] : d.currentSettings[this.constantSvc.DEVKEY_NET_LAN_GATEWAY],
                        useWifi ? d.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_NETMASK] : d.currentSettings[this.constantSvc.DEVKEY_NET_LAN_NETMASK],
                        d.currentSettings[this.constantSvc.DEVKEY_INFO_FW_VERSION],
                        d.currentSettings[this.constantSvc.DEVKEY_FAKE_HEARTBEAT] ? this.datePipe.transform(new Date(d.currentSettings[this.constantSvc.DEVKEY_FAKE_HEARTBEAT]), 'yyyy-MM-dd HH:mm:ss Z') : '',
                        d.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE],
                        d.virtualDeviceOwner,
                        '"' + d.currentSettings[this.constantSvc.DEVKEY_FAKE_GROUPNAME] + '"',
                        d.currentSettings[this.constantSvc.DEVKEY_INFO_APKVERSION],
                        d.scep.inUse?.certName || 'Disable',
                        d.scep.inUse?.state,
                        d.scep.inUse?.notAfter,
                        d.currentSettings[this.constantSvc.DEVKEY_APPSTART_CONTENTURL],
                        policyMap[allDeviceGroupMap[d.groupID]?.policies?.Application?.[0]]?.name,
                        policyMap[allDeviceGroupMap[d.groupID]?.policies?.Configuration?.[0]]?.name,
                        policyMap[allDeviceGroupMap[d.groupID]?.policies?.FirmwareUpdate?.[0]]?.name,
                        policyMap[allDeviceGroupMap[d.groupID]?.policies?.Security?.[0]]?.name,
                        policyMap[allDeviceGroupMap[d.groupID]?.policies?.Certificate?.[0]]?.name,
                        HelperLib.parseDataToCsvInlineText(d.currentSettings[this.constantSvc.DEVKEY_APPMETADATA]),
                        HelperLib.parseDataToCsvInlineText(d.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_ETHERNETMACS]),
                        HelperLib.parseDataToCsvInlineText(d.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_WIFIMACS]),
                        d.currentSettings[this.constantSvc.DEVKEY_INFO_FW_WEBVIEWPROVIDER]
                    ]);
                });

                return { header, metadata: metadata, dataList: rowList, date: date };
            })
        );
    }
}
