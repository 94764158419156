import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IUpdatePasswordTxData {
    password: string;
}

export class APIUpdateAccountPasswordManager extends APIBaseManager<void, void, IUpdatePasswordTxData, void> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UpdateAccountPassword';
        this._apiMethod = 'POST';
        this._allowPayloadPrint = false;
    }

    protected getRequestURL(): string {
        return super.getRequestURL() + 'accounts/password';
    }
}