<div class="dev-rctrl">
    <div class="header">
        <h4 class="mb-0">Remote control</h4>
        <div class="name">{{ _device?.virtualName }}</div>
    </div>
    <div *ngIf="_errorMessage" class="block block-error text-center mt-1">
        {{ _errorMessage }}
    </div>
    <div class="main" [class.d-none]="_initMsg">
        <div class="projection">
            <div class="canvas" [class.dragable]="_isPanMode" [style.width.%]="_zoomRatio"
                [style.top.px]="_panModeOffset.y" [style.left.px]="_panModeOffset.x">
                <img #img width="100%" height="100%" [src]="_currentBase64Image" alt=""
                    [style.display]="_currentBase64Image ? 'inherit' : 'none'"
                    [style.transform]="'rotate(' + _rotateDegree + 'deg)'" draggable="true" />
            </div>
        </div>
        <div class="setting-wrapper">
            <div data-tooltip="Connect" *ngIf="!_isConnect; else templateDisconnect" class="setting"
                [class.disabled]="!_room" (click)="startConnect()">
                <i class="fas fa-play-circle"></i>
            </div>
            <ng-template #templateDisconnect>
                <div data-tooltip="Disconnect" class="setting" (click)="stopConnect()">
                    <i class="fas fa-stop-circle"></i>
                </div>
            </ng-template>
            <div data-tooltip="Zoom out" class="setting" [class.disabled]="!_zoomOutSupport || !_currentBase64Image"
                (click)="zoomOut()">
                <i class="fas fa-search-minus"></i>
            </div>
            <div data-tooltip="Zoom in" class="setting" [class.disabled]="!_zoomInSupport || !_currentBase64Image"
                (click)="zoomIn()">
                <i class="fas fa-search-plus"></i>
            </div>
            <div data-tooltip="Pan mode" class="setting" [class.active]="_isPanMode"
                [class.disabled]="!_currentBase64Image" (click)="panMode()">
                <i class="fas fa-arrows-alt"></i>
            </div>
            <div class="dropright">
                <div data-tooltip="Rotate" class="setting" [class.disabled]="!_currentBase64Image"
                    [attr.data-toggle]="_currentBase64Image ? 'dropdown' : ''" aria-expanded="false">
                    <i class="fas fa-sync-alt"></i>
                </div>
                <div class="dropdown-menu p-0">
                    <div class="rotate-wrapper">
                        <div *ngFor="let degree of ROTATE_DEGREE_OPTIONS" class="rotate-option"
                            [class.active]="_rotateDegree === degree" (click)="rotate(degree)">
                            <i class="fas fa-arrow-alt-circle-up" [style.transform]="'rotate(' + degree + 'deg)'"></i>
                            <div>{{ degree }}&#176;</div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="_keyEventSupport" data-tooltip="Text" class="setting" [class.disabled]="!_currentBase64Image"
                [attr.data-toggle]="_currentBase64Image ? 'modal' : ''" data-target="#remoteFuncModalHost" (click)="sendMessage()">
                <i class="fas fa-font"></i>
            </div>
            <div data-tooltip="Take screenshot" class="setting" [class.disabled]="!_currentBase64Image"
                [attr.data-toggle]="_currentBase64Image ? 'modal' : ''" data-target="#remoteFuncModalHost" (click)="downloadScreenshot()">
                <i class="fas fa-camera"></i>
            </div>
            <div data-tooltip="Setting page" class="setting" [class.disabled]="!_isConnect" (click)="sendHomeCmd()">
                <i class="fas fa-cog"></i>
            </div>
            <div #idleDlgPopBtn class="d-none" data-toggle="modal" data-target="#remoteFuncModalHost"></div>
            <div #errorDlgPopBtn class="d-none" data-toggle="modal" data-target="#remoteFuncModalHost"></div>
        </div>
    </div>
    <div class="text-center mt-5" [class.d-none]="!_initMsg">
        <h3>{{ _initMsg }}</h3>
        <div *ngIf="_loading" class="loading">
            <div class="loading-box"></div>
        </div>
    </div>
</div>
<div id="remoteFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <div class='modal-dialog config-dlg' role="document">
        <div class='modal-content'>
            <ng-template remote-func></ng-template>
        </div>
    </div>
</div>