import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { IAlertSetting } from '../api.alert.common';

export interface IDeleteAlertPathParameter {
    alertSettingID: string;
}

export class APIDeleteAlertManager extends APIBaseManager<IDeleteAlertPathParameter, void, void, IAlertSetting> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_DeleteAlert';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IDeleteAlertPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'accounts/alertSettings/' + pathParameters.alertSettingID;
    }
}