import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ActivityModule } from './activity/activity.module';
import { EventLogOverviewComponent } from './log/log-overview.component';
import { EventLogService } from './log/eventLog.service';
import { UIElementModule } from '../../uiElement/uiElement.module';
import { TranslateModule } from '../../translate/translate.module';
import { CustomPipeModule } from '../../lib/pipes/custom-pipe.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,

        UIElementModule,
        TranslateModule,
        CustomPipeModule,

        ActivityModule
    ],
    declarations: [
        EventLogOverviewComponent
    ],
    exports: [
        EventLogOverviewComponent
    ],
    providers: [
        EventLogService
    ]
})
export class EventFeedModule { }