import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { IUIElement } from '../uiElement.interface';
import { AppManagementInfo, AppManagementSplitInfo } from './appmgr.data';
import { DeviceService } from 'app/content/device/device.service';
import { DeviceInfo } from 'app/content/device/data/device-info';
import { ConstantService } from 'app/lib/common/constant.service';

@Component({
    selector: 'na-app-management',
    templateUrl: './appmgr.component.html',
    styleUrls: ['../uiElement.style.css', './appmgr.style.css']
})
export class AppManagementComponent implements OnInit, IUIElement {
    readonly APP_ID_NONE: string = 'App-None';

    _unsupportReason: string;
    _disabled: boolean;
    _lockInfo: { isSync: boolean; policyID: string; policyName: string; };

    _appList: AppManagementInfo[] = [];
    _app: AppManagementInfo;
    _currentAppIndex: number = 0;

    _appLaunchList: AppManagementInfo[] = [];
    _appToLaunch: AppManagementInfo;

    _split: AppManagementSplitInfo;
    _isSplitNameConfirmed: boolean = false;

    _availableDeviceModels: string[] = [];
    _appModels: Map<string, Map<string, boolean>> = new Map();
    _loadingDeviceModel: boolean = false;

    _appFormStatus: string;


    @Input('data')
    set data(raw: AppManagementInfo[]) {
        this._appList = raw;
        if (this._appList) {
            this._app = this._appList[0];
            this._currentAppIndex = 0;
        }
    }

    private _appDetailForm: NgForm;
    @ViewChild('appDetailForm')
    set appDetailForm(f: NgForm) {
        this._appDetailForm = f;
        if (this._appDetailForm) {
            this._appDetailForm.statusChanges.pipe(
                debounceTime(200),
            ).subscribe((status: string) => {
                this._appFormStatus = status;
                this.validateApp();
            });
        }
    }

    @Output() onValidChanged = new EventEmitter<boolean>();

    constructor(private constantSvc: ConstantService, private devSvc: DeviceService) { }

    ngOnInit(): void {
        // app assigned to be launched
        this._appLaunchList = [new AppManagementInfo({ id: this.APP_ID_NONE, name: 'None', modelList: [] })].concat(this._appList?.map(a => a));
        const launchedApp: AppManagementInfo = this._appList.find(app => app.bindToAppstart);
        this._appToLaunch = launchedApp ? launchedApp : this._appLaunchList[0];
        this.onValidChanged.emit(this._appList.length > 0 ? true : false);

        this.updateSplit();

        this._loadingDeviceModel = true;
        this.devSvc.getDevices('app-policy').subscribe((res: { isFault: boolean, hasNext: boolean, devices: DeviceInfo[], total: number, errorMessage?: string }) => {
            let deviceModelSet: Set<string> = new Set();
            if (!res.isFault) {
                res.devices.forEach(d => deviceModelSet.add(d.currentSettings[this.constantSvc.DEVKEY_INFO_MODEL] || ''));
            }

            this._availableDeviceModels = Array.from(deviceModelSet.values()).filter(model => model);
            if (this._appList) {
                this.initDeviceModels();
            }
            this._loadingDeviceModel = false;
            this.validateApp();
        });
    }

    addApp(): void {
        const newApp: AppManagementInfo = new AppManagementInfo();
        this._appList.push(newApp);
        this._app = newApp;
        this.selectAllDeviceModels(this._app, true);
        this._currentAppIndex = this._appList.indexOf(this._app);

        this._appLaunchList.push(newApp);

        this.updateSplit();
    }

    goPrevApp(): void {
        if (this._currentAppIndex > 0) {
            this._currentAppIndex--;
            this._app = this._appList[this._currentAppIndex];

            this.updateSplit();
        }
    }

    goNextApp(): void {
        if (this._currentAppIndex < this._appList.length - 1) {
            this._currentAppIndex++;
            this._app = this._appList[this._currentAppIndex];

            this.updateSplit();
        }
    }

    removeApp(): void {
        if (this._app) {
            const a = this._appLaunchList.find(a => a.id === this._app.id);
            if (a) {
                this._appLaunchList.splice(this._appLaunchList.indexOf(a), 1);
                if (this._appToLaunch && a.id === this._appToLaunch.id) {
                    this._appToLaunch = this._appLaunchList[0];
                }
            }

            this._appList.splice(this._appList.indexOf(this._app), 1);

            if (this._currentAppIndex >= this._appList.length) {
                this._currentAppIndex = this._appList.length - 1 >= 0 ? this._appList.length - 1 : 0;
            }
            this._app = this._appList[this._currentAppIndex];

            this.updateSplit();
        }

        this.validateApp();
    }

    initDeviceModels(): void {
        this._appList.forEach(app => {
            const appModel: Map<string, boolean> = this.initAppDeviceModel(app.id);
            if (app.modelList.length > 0) {
                app.modelList.forEach(model => {
                    appModel.set(model, true);
                });
            }
            else {
                // for backward compatibility -> old app does not have modelList property, it should support all models by default.
                this._availableDeviceModels.forEach(model => {
                    appModel.set(model, true);
                });

                app.modelList = this._availableDeviceModels;
            }
        });
    }

    selectDeviceModel(app: AppManagementInfo, model: string, checked: boolean): void {
        const appModel: Map<string, boolean> = this.initAppDeviceModel(app.id);
        if (checked) {
            appModel.set(model, true);
            if (this._availableDeviceModels.length == appModel.size) {
                app.isAllModelSelected = true;
            }
        }
        else {
            appModel.delete(model);
            app.isAllModelSelected = false;
        }

        app.modelList = Array.from(appModel.keys());
        this.validateApp();
    }

    selectAllDeviceModels(app: AppManagementInfo, checked: boolean): void {
        const appModel: Map<string, boolean> = this.initAppDeviceModel(app.id);
        app.isAllModelSelected = checked;
        if (checked) {
            this._availableDeviceModels.forEach(model => appModel.set(model, true));
        }
        else {
            appModel.clear();
        }

        app.modelList = Array.from(appModel.keys());
        this.validateApp();
    }

    private initAppDeviceModel(appID: string): Map<string, boolean> {
        if (!this._appModels.has(appID)) {
            this._appModels.set(appID, new Map());
        }

        return this._appModels.get(appID);
    }

    changeExecuteByAppstart(appInfo: AppManagementInfo): void {
        this._appList.forEach(a => a.bindToAppstart = false);
        this._appToLaunch = appInfo;

        const app: AppManagementInfo = this._appList.find(app => app.id === appInfo.id);
        if (app) {
            app.bindToAppstart = true;
        }
    }

    changeAllowDowngrade(app: AppManagementInfo, allow: boolean): void {
        if (app.allowDowngrade !== allow) {
            app.allowDowngrade = allow;
        }
    }

    updateSplit(): void {
        // update selected split according to different app
        if (this._app) {
            this._split = this._app.splits?.[0];
            if (this._split) {
                // split should be confirmed on last setting
                this._isSplitNameConfirmed = true;
            }
        }
    }

    addSplit(): void {
        this._split = this._app.addSplit();
        this._isSplitNameConfirmed = false;
    }

    confirmSplitName(): void {
        this._isSplitNameConfirmed = true;
    }

    selectSplit(split: AppManagementSplitInfo): void {
        this._split = split;
    }

    removeSplit(split: AppManagementSplitInfo): void {
        split = split || this._split;
        this._app.removeSplit(split.id);
        this._split = this._app.splits[0];
        this._isSplitNameConfirmed = true;
    }

    private validateApp(): void {
        let isAppValid: boolean = false;
        try {
            if (this._appFormStatus === 'INVALID') {
                throw false;
            }
            if (this._appList.length === 0) {
                throw false;
            }
            if (this._loadingDeviceModel) {
                throw false;
            }

            for (let app of this._appList) {
                if (!app.name || !app.apkLink || !app.packageName) {
                    throw false;
                }
                if (app.name === 'None') {
                    throw false;
                }

                const appDeviceModel: Map<string, boolean> = this._appModels.get(app.id);
                if (appDeviceModel.size === 0) {
                    throw false;
                }

                if (app.splits) {
                    for (let split of app.splits) {
                        if (!split.name || !split.link) {
                            throw false;
                        }
                    }

                    if (this.isAppSplitDuplicate(app)) {
                        throw false;
                    }
                }
            }

            if (this.isAppNameDuplicate()) {
                throw false;
            }

            throw true;
        }
        catch (isValid) {
            isAppValid = isValid;
        }
        finally {
            this.onValidChanged.emit(isAppValid);
        }
    }

    isAppNameDuplicate(compared?: AppManagementInfo): boolean {
        if (!compared) {
            const appNameMap: { [name: string]: boolean } = {};
            for (let app of this._appList) {
                if (appNameMap[app.name]) {
                    return true;
                }
                appNameMap[app.name] = true;
            }

            return false;
        }

        if (!compared.name) {
            return false;
        }

        for (let app of this._appList) {
            if (app !== compared && app.name == compared.name) {
                return true;
            }
        }

        return false;
    }

    isAppSplitDuplicate(app: AppManagementInfo): boolean {
        if (!app || app.splits.length === 0) {
            return false;
        }

        const appSplitNameMap: { [name: string]: boolean } = {};
        for (let split of app.splits) {
            if (appSplitNameMap[split.name]) {
                return true;
            }
            appSplitNameMap[split.name] = true;
        }

        return false;
    }
}