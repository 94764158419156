<div class='modal-dialog config-dlg' role="document">
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body'>
            <div>
                Are you sure to renew the scep for <strong class="red-1">{{ _deviceList.length }}</strong>
                device(s)?
                <div *ngIf="_page === _enumPage.result" class="mt-2">
                    <div *ngIf="_errorMessage; else templateRenewScepPass" class="block block-error">
                        <i class="fas fa-exclamation-circle"></i>
                        <span class="ml-2">Renew device under {{ _scep?.alias }} failed :</span>
                        <div>{{ _errorMessage }}</div>
                    </div>
                    <ng-template #templateRenewScepPass>
                        <div class="block block-success">
                            Renew request for <strong>{{ _deviceList.length }}</strong> device(s) has sent.
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="row control-block d-flex justify-content-center align-items-center mt-4">
                <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_page === _enumPage.submit"
                    data-dismiss="modal">
                    <span class="fas fa-times-circle mr-1"></span>
                    {{ 'key-close' | translate | capitalFirstWord }}
                </button>
                <button *ngIf="_page !== _enumPage.result || _errorMessage" type="button"
                    class="btn btn-sm btn-action btn-light"
                    [disabled]="_page === _enumPage.submit || _deviceList.length === 0" (click)="renew()">
                    <span class="fas fa-sync-alt mr-1"></span>
                    Renew
                </button>
                <div *ngIf="_page === _enumPage.submit" class="loading loading-p0 ml-4">
                    <div class="loading-box"></div>
                </div>
            </div>
        </div>
    </div>
</div>