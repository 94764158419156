import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export class APIGetAlertSampleManager extends APIBaseManager<void, void, void, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetAlertSample';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'alertEvents';
    }
}