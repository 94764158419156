import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { of as observableOf, from } from 'rxjs';
import { concatMap, catchError } from 'rxjs/operators';

import { AppConfigService } from '../../app.config';
import { AccountService } from '../../entry/account.service';
import { DialogPage } from '../../lib/common/common.data';
import { Logger } from '../../lib/common/logger';

declare var grecaptcha;

@Component({
    selector: 'account-pwd',
    templateUrl: './account-pwd.component.html',
    styleUrls: ['./account-pwd.component.css']
})
export class AccountPasswordComponent implements OnInit {
    readonly PWD_MIN_LEN: number = 10;
    _oldPwd: string;
    _newPwd: string;
    _confirmPwd: string;
    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;
    _errorMessage: string;

    @Output() onClosed = new EventEmitter<void>();

    constructor(
        public accountSvc: AccountService
    ) {

    }

    ngOnInit(): void {
        this.reset();
    }

    reset(): void {
        this._oldPwd = this._newPwd = this._confirmPwd = null;
        this._errorMessage = null;
        this._page = DialogPage.action;
    }

    changeAccountPwd(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;

        from(grecaptcha.execute(AppConfigService.configs.common.reCaptcha.key, { action: 'loginPage' })).pipe(
            concatMap((reCaptchaToken: string) => {
                return this.accountSvc.checkPassword(this._oldPwd, reCaptchaToken);
            }),
            concatMap((checkPwdResult: { isFault: boolean, errorMessage?: string }) => {
                if (checkPwdResult.isFault) {
                    return observableOf(checkPwdResult);
                }

                return this.accountSvc.updatePassword(this._newPwd);
            }),
            catchError(error => {
                Logger.logError('Account-pwd', 'submit', error || 'reCAPTCHA failed: Unknown error')
                return observableOf({ isFault: true, errorMessage: 'reCAPTCHA failed. Error = ' + error.toString() });
            }),
        ).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._page = DialogPage.result;
            this._errorMessage = res.errorMessage;
        });
    }

    logout(): void {
        setTimeout(() => {
            this.reset();
            this.accountSvc.logout();
        }, 500);
    }

    close(): void {
        this.onClosed.emit();
    }
}