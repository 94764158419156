import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IVirtualDeviceRxDataApp, IVirtualDeviceRxDataDebug, IVirtualDeviceRxDataHardware, IVirtualDeviceRxDataInfo, IVirtualDeviceRxDataNet, IVirtualDeviceRxDataSchedule, IVirtualDeviceRxDataTime } from './virtualDevice.common';

export interface IGetVirtualDeviceCurrentSettingPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export class IGetVirtualDeviceCurrentSettingRxData {
    settings?: {
        app: IVirtualDeviceRxDataApp,
        debug: IVirtualDeviceRxDataDebug,
        hardware: IVirtualDeviceRxDataHardware,
        info: IVirtualDeviceRxDataInfo,
        net: IVirtualDeviceRxDataNet,
        schedule: IVirtualDeviceRxDataSchedule,
        time: IVirtualDeviceRxDataTime
    };
    status?: {
        app: IVirtualDeviceRxDataApp,
        debug: IVirtualDeviceRxDataDebug,
        hardware: IVirtualDeviceRxDataHardware,
        info: IVirtualDeviceRxDataInfo,
        net: IVirtualDeviceRxDataNet,
        schedule: IVirtualDeviceRxDataSchedule,
        time: IVirtualDeviceRxDataTime
    };
}

export class APIGetVirtualDeviceCurrentSettingManager extends APIBaseManager<IGetVirtualDeviceCurrentSettingPathParameter, void, void, IGetVirtualDeviceCurrentSettingRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetVirtualDeviceCurrentSetting';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IGetVirtualDeviceCurrentSettingPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/' + pathParameters.virtualDevicePairedID + '/currentSettings';
    }
}