<div class="container-fluid">
    <div class="row">
        <div class="col-12 px-0">
            <div class="d-flex">
                <button id="id_pair_own_do_refreshAll" type="button" class="btn btn-sm btn-action btn-light" [disabled]="_refreshCounter > 0 || _isLoading"
                    data-toggle="tooltip" title='{{ "key-dev-refresh" | translate | capitalFirstWord }}'
                    (click)="refreshDevices()">
                    <i [class.rotate]="_refreshCounter > 0" class="fas fa-sync-alt"></i>
                    <span class="sr-only">{{ "key-dev-refresh" | translate }}</span>
                    <ng-template [ngIf]="_refreshCounter > 0"> - {{ _refreshCounter }} s</ng-template>
                </button>
                <div class="flex-fill ml-2">
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fas fa-search"></i>
                            </span>
                        </div>
                        <input #search class="form-control" type='text' [disabled]="_isLoading" name='search'
                            placeholder="Search by device name ..." aria-label="Search devices">
                    </div>
                </div>
                <div class="dropdown-wrapper btn-group ml-2">
                    <button id="dropdownPageAmountLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="_isLoading">
                        {{ _numberInPage }}
                    </button>
                    <ul class="dropdown-menu option-list" aria-labelledby="dropdownPageAmountLink">
                        <li *ngFor="let numberInPageOption of NUMBER_IN_PAGE_OPTIONS" class="dropdown-item"
                            [class.selected]="numberInPageOption === _numberInPage"
                            (click)="changeNumberInPage(numberInPageOption)">
                            {{ numberInPageOption }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 d-flex px-0">
            <button id="id_pair_own_do_add" class="btn btn-sm btn-action btn-light btn-add-player my-1 ml-auto" (click)="add()">
                <i class="fas fa-plus-circle"></i>
                Add new device
            </button>
        </div>
        <div class="layout-mobile scroll scroll-md col-12 px-0 mt-2">
            <div *ngFor="let p of _displayVirtualDeviceList; let recordIndex = index" class="card card-list">
                <div class="card-header">
                    # {{ recordIndex + 1 }}
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <span class="col-4">Device name</span>
                        <div class="col-8 pl-0">
                            <input
                                *ngIf="p.status === _enumDeviceStatus.None; else templateMobilePairedNameColumn"
                                type="text" class="form-control form-control-sm" aria-label="Enter device name"
                                [(ngModel)]="p.name">
                            <ng-template #templateMobilePairedNameColumn>
                                <span>{{ p.name }}</span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="form-group row">
                        <span class="col-4">Group</span>
                        <div class="col-8 pl-0">
                            <button *ngIf="allowChangeGroup(p); else templateMobileStaticGroup" type="button"
                                class="btn btn-sm btn-action btn-light" data-toggle="modal"
                                data-target="#groupSelectHost" (click)="popupGroupDialog(p)">
                                {{ p.group?.name }}
                                <i class="fas fa-caret-down ml-2"></i>
                            </button>
                            <ng-template #templateMobileStaticGroup>
                                {{ p.group?.name }}
                            </ng-template>
                        </div>
                    </div>
                    <div class="form-group row">
                        <span class="col-4">Status</span>
                        <div class="col-8 pl-0"
                            [class.text-loading]="p.status === _enumDeviceStatus.Pairing || p.status === _enumDeviceStatus.Unpairing || p.status === _enumDeviceStatus.Syncing || p.status === _enumDeviceStatus.Removing"
                            [attr.data-text]="_enumDeviceStatus[p.status]">
                            <span
                                *ngIf="p.status !== _enumDeviceStatus.None && p.status !== _enumDeviceStatus.Created">
                                {{ p.status }}
                                <span *ngIf="p.status === _enumDeviceStatus.Syncing">
                                    ( paired )
                                </span>
                            </span>
                            <span
                                *ngIf="p.status === _enumDeviceStatus.None || p.status === _enumDeviceStatus.Created">
                                Not paired
                            </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <span class="col-4">Pairing code</span>
                        <div class="col-8 pl-0">
                            <input
                                *ngIf="p.status === _enumDeviceStatus.None || p.status === _enumDeviceStatus.Created; else templateMobilePairedCodeColumn"
                                type="text" class="form-control form-control-sm" aria-label="Enter pairing code"
                                [(ngModel)]="p.pairingCode" upperCase>
                            <ng-template #templateMobilePairedCodeColumn>
                                <span>
                                    {{ p.pairingCode }}
                                </span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="form-group row">
                        <span class="col-4">Action</span>
                        <div class="col-8 pl-0">
                            <button
                                *ngIf="p.status === _enumDeviceStatus.None || p.status === _enumDeviceStatus.Pairing || p.status === _enumDeviceStatus.Created"
                                type="button" class="btn btn-sm btn-action btn-light mr-1" [disabled]="!p.actions.Pair.allow"
                                (click)="doAction(p, _enumDeviceAction.Pair)">
                                {{ p.actions.Pair.type }}
                            </button>
                            <button
                                *ngIf="p.status === _enumDeviceStatus.Unpairing || p.status === _enumDeviceStatus.Paired || p.status === _enumDeviceStatus.Syncing"
                                type="button" class="btn btn-sm btn-action btn-light mr-1"
                                [disabled]="!p.actions.UnPair.allow"
                                (click)="doAction(p, _enumDeviceAction.UnPair)">
                                {{ p.actions.UnPair.type }}
                            </button>
                            <button
                                *ngIf="p.status === _enumDeviceStatus.Removing || p.status === _enumDeviceStatus.None || p.status === _enumDeviceStatus.Created"
                                type="button" class="btn btn-sm btn-action btn-light"
                                [disabled]="!p.actions.Delete.allow"
                                (click)="doAction(p, _enumDeviceAction.Delete)">
                                {{ p.actions.Delete.type }}
                            </button>
                            <div *ngIf="p.errorMessage" class="text-danger">{{ p.errorMessage }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-desktop col-12 px-0">
            <div class="list-group">
                <div class="list-group-item list-group-header">
                    <div class="row">
                        <span class="col-1 title" style="max-width: 5%">#</span>
                        <span class="col-3 title">Device name</span>
                        <span class="col-2 title">Group</span>
                        <span class="col-2 title">Pairing code</span>
                        <span class="col title">Status</span>
                        <span class="col title">Action</span>
                    </div>
                </div>
                <div *ngIf="!_isLoading" class="list-group-content list-group-hoverless">
                    <div *ngFor="let p of _displayVirtualDeviceList; let recordIndex = index" class="list-group-item list-group-item-action">
                        <div class="row align-items-center">
                            <span class="col-1" style="max-width: 5%">{{ recordIndex + 1 }}</span>
                            <span class="col-3 text-break" data-toggle="tooltip" [title]="p.id">
                                <input *ngIf="p.status === _enumDeviceStatus.None; else templateColStaticName"
                                    type="text" class="form-control form-control-sm" aria-label="Enter device name"
                                    [(ngModel)]="p.name">
                                <ng-template #templateColStaticName>
                                    <span>{{ p.name }}</span>
                                </ng-template>
                            </span>
                            <span class="col-2">
                                <button *ngIf="allowChangeGroup(p); else templateColStaticGroup" type="button"
                                    class="btn btn-sm btn-action btn-light" data-toggle="modal"
                                    data-target="#groupSelectHost" (click)="popupGroupDialog(p)">
                                    {{ p.group?.name }}
                                    <i class="fas fa-caret-down ml-2"></i>
                                </button>
                                <ng-template #templateColStaticGroup>
                                    {{ p.group?.name }}
                                </ng-template>
                            </span>
                            <span class="col-2">
                                <input
                                    *ngIf="p.status === _enumDeviceStatus.None || p.status === _enumDeviceStatus.Created; else templateColStaticPairingCode"
                                    type="text" class="form-control form-control-sm" aria-label="Enter pairing code"
                                    [(ngModel)]="p.pairingCode" upperCase>
                                <ng-template #templateColStaticPairingCode>
                                    <span>
                                        {{ p.pairingCode }}
                                    </span>
                                </ng-template>
                            </span>
                            <span class="col" [class.text-loading]="underAction(p.status)"
                                [attr.data-text]="_enumDeviceStatus[p.status]">
                                <span
                                    *ngIf="p.status !== _enumDeviceStatus.None && p.status !== _enumDeviceStatus.Created; else templateColStatusNotPair">
                                    {{ p.status }}
                                    <ng-template [ngIf]="p.status === _enumDeviceStatus.Syncing">
                                        (paired)
                                    </ng-template>
                                </span>
                                <ng-template #templateColStatusNotPair>
                                    Not paired
                                </ng-template>
                            </span>
                            <span class="col">
                                <button
                                    *ngIf="p.status === _enumDeviceStatus.None || p.status === _enumDeviceStatus.Pairing || p.status === _enumDeviceStatus.Created"
                                    type="button" class="btn btn-sm btn-light btn-action mr-1"
                                    [disabled]="!p.actions.Pair.allow"
                                    (click)="doAction(p, _enumDeviceAction.Pair)">
                                    Pair
                                </button>
                                <button
                                    *ngIf="p.status === _enumDeviceStatus.Unpairing || p.status === _enumDeviceStatus.Paired || p.status === _enumDeviceStatus.Syncing"
                                    type="button" class="btn btn-sm btn-light btn-action mr-1"
                                    [disabled]="!p.actions.UnPair.allow"
                                    (click)="doAction(p, _enumDeviceAction.UnPair)">
                                    Delete
                                </button>
                                <button
                                    *ngIf="p.status === _enumDeviceStatus.Removing || p.status === _enumDeviceStatus.None || p.status === _enumDeviceStatus.Created"
                                    type="button" class="btn btn-sm btn-light btn-action"
                                    [disabled]="!p.actions.Delete.allow"
                                    (click)="doAction(p, _enumDeviceAction.Delete)">
                                    Delete
                                </button>
                            </span>
                        </div>
                        <div *ngIf="p.errorMessage" class="text-danger">
                            {{ p.errorMessage }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <p *ngIf="!_isLoading && _displayVirtualDeviceList?.length === 0" class="text-center p-2">
                You do not have any devices. Add one?
            </p>
            <div class="loading" *ngIf="_isLoading">
                <p>Please wait...</p>
                <div class="loading-box"></div>
            </div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="bottom-bar col-12 px-0 d-flex">
            <div class="ml-auto">
                <na-pager [currentPage]="_page" [numberInPage]="_numberInPage" [total]="_displayPlayerAmount"
                    (onPageChanged)="changePage($event)">
                </na-pager>
            </div>
        </div>
    </div>
    <div id="groupSelectHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog"
        tabindex="-1">
        <div class='modal-dialog'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <span class="modal-title">Change group</span>
                    <button #dlgClose type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class='modal-body'>
                    <div class="border px-2 py-1">
                        <na-dev-group-tree-wrapper [group]="_devGroupRoot" [moveTarget]="_targetPairInfo?.group"
                            [mode]="_groupMode" [showTitle]="true"
                            [title]="'Change to <' + (_targetPairInfo?.group?.name ?  _targetPairInfo?.group?.name : '') + '>'"
                            [enableUnfoldFunc]="true" (onGroupMoveTargetChanged)="onTargetGroupChange($event)">
                        </na-dev-group-tree-wrapper>
                    </div>
                    <div class='row control-block d-flex justify-content-center'>
                        <button type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal">
                            {{ 'key-ok' | translate | capitalFirstWord }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="layout-mobile">
    <div class="control-block-bottom fixed-bottom">
        <a class="control-item" [ngClass]="_isLoading ? 'disabled' : ''" [routerLink]="'/app/device/devices'">
            <div class="icon fas fa-home"></div>
            <span>Device overview</span>
        </a>
    </div>
</div>