import { HttpClient } from '@angular/common/http';
import { APIBaseManager} from '../../api.base';

export interface IGetWarrantyRxData {
    playerID: string;
    serialNumber: string;
    warrantyStartDate: string;
    warrantyEndDate: string;
    warrantyOverrideStartDate: string;
    warrantyOverrideEndDate: string;
}

export class APIGetWarrantyManager extends APIBaseManager<void, void, void, IGetWarrantyRxData[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetWarranty';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'players/warranty';
    }
}