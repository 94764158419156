<div class="card p-0 shadow-sm screenshot-card">
  <div class="card-img-top">
    <div *ngIf="_bScreenshotLoadFail; else templateShowScreenshot" class="img-fail">
      <div>{{ 'key-noAvail' | translate | capitalAllWords }}</div>
      <div class="sub-title">{{ _errorMessage }}</div>
    </div>
    <ng-template #templateShowScreenshot>
      <div *ngIf="_screenshotUrl" class="screenshot-content" [ngClass]="_screenshotUrl ? 'fadeIn' : ''"
        [ngStyle]="{ 'background-image': 'url(' + _screenshotUrl + ')'}">
      </div>
    </ng-template>
    <div *ngIf="_isDeviceLocked" class="lock-overlay">
      <i class="fas fa-lock"></i>
    </div>
  </div>
  <div class="card-body">
    <div>
      <strong>{{ 'key-dev-screenCaptureTime' | translate | capitalFirstWord }}: </strong>
      <div class="text-muted">{{ _screenshotCaptureTime | date:'yyyy-MM-dd HH:mm:ss Z' }}</div>
    </div>
    <div class="d-flex justify-content-center">
      <button *ngIf="supportRemoteCtrl" id="id_dev_do_remoteCtrl" type="button"
        class="btn btn-outline-light btn-action btn-sm" [disabled]="!isRemoteCtrlValid">
        <a [routerLink]="isRemoteCtrlValid ? ['/app/device/remotectrl', _device.virtualId] : null" target="_blank"
          role="link" [attr.aria-disabled]="!isRemoteCtrlValid">
          <i class="fab fa-mixcloud mr-1"></i>
          Remote control
        </a>
      </button>
      <button *ngIf="supportScreenshot" id="id_dev_do_screenshot" type="button"
        class="btn btn-light btn-sm btn-action ml-2" [disabled]="!isScreenshotValid"
        (click)="isScreenshotValid ? takeScreenshot() : $event.stopPropagation()">
        <span class="fas fa-camera mr-1" [class.rotate]="_countdown > 0"></span>
        Capture Screen
        <span *ngIf="_countdown > 0"> - {{ _countdown }} s</span>
      </button>
    </div>
  </div>
</div>