import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { SortType } from "app/lib/common/common.data";
import { HelperLib } from "app/lib/common/helper.lib";
import { fromEvent } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { CertInfo, CertInstallStatus } from "../cert.data";
import { CertService } from "../cert.service";
import { CertFuncItem, CERT_FUNC_ADD, CERT_FUNC_REMOVE, ICertFuncCtrl } from "../dlg/cert-func.def";
import { CertFuncDirective } from "../dlg/cert-func.directive";
import { CertFuncService } from "../dlg/cert-func.service";
import { Logger } from "app/lib/common/logger";
import { AutoUnsubscribeComponent } from "app/content/virtual/auto-unsubscribe.component";

@Component({
    selector: 'na-cert-pickup',
    templateUrl: './cert-pickup.component.html',
    styleUrls: ['./cert-pickup.component.css']
})
export class CertPickupComponent extends AutoUnsubscribeComponent implements OnInit {
    readonly CERT_COLKEY_NAME: string = 'name';
    readonly CERT_COLKEY_EXPIRYDATE: string = 'expiryDate';

    _loading: boolean = false;
    _currentSortKey: string;
    _currentSortType: SortType = SortType.ascend;
    _enumSortType: typeof SortType = SortType;
    _currentPage: number = 1;
    _displayAmount: number = 1;

    _certSelectionMap: { [certID: string]: { cert: CertInfo, selected: boolean } } = {};
    _certInstallStatusMap: { [certID: string]: { cert: CertInfo, fromStatus: CertInstallStatus, toStatus: CertInstallStatus } } = {};
    _enumCertStatus: typeof CertInstallStatus = CertInstallStatus;
    private _useExternalSource: boolean = false;
    private _certSrcList: CertInfo[] = [];
    _certDisplayList: CertInfo[] = [];

    @Input() title: string;
    @Input() numberInPage: number = 30;

    @Input()
    set certInstallStatusMap(v: { [certID: string]: { cert: CertInfo, fromStatus: CertInstallStatus, toStatus: CertInstallStatus } }) {
        this._certInstallStatusMap = v;
    }
    @Input()
    set sources(v: CertInfo[]) {
        this._useExternalSource = true;
        this._certSrcList = v;
        this.refactor();
    }
    @Input() supportChooseCertAction: boolean = true;
    @Input() supportAddCertAction: boolean = true;
    @Input() supportRemoveCertAction: boolean = true;
    @Input() supportCertManageLink: boolean = false;
    @Input() supportRefreshCert: boolean = false;
    @Input() supportViewInstallStatus: boolean = false;
    @Input() supportUpdateInstallStatus: boolean = false;

    private _searchRef: ElementRef;
    @ViewChild('search')
    set search(v: ElementRef) {
        if (!this._searchRef && v) {
            this._searchRef = v;
            
            fromEvent(this._searchRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this.refactor([{ key: this.CERT_COLKEY_NAME, value: e.target.value.toLocaleLowerCase() }]);
            });
        }
    }

    private _funcHost: CertFuncDirective;
    @ViewChild(CertFuncDirective, { static: true })
    set funcHost(host: any) {
        this._funcHost = host;
    }

    @Output() onCertSelected = new EventEmitter<CertInfo[]>();
    @Output() onCertManagementLinkClicked = new EventEmitter<void>();
    @Output() onCertInstallStatusChanged = new EventEmitter<{ cert: CertInfo, fromStatus: CertInstallStatus, toStatus: CertInstallStatus }>();

    constructor(private certSvc: CertService, private certFuncSvc: CertFuncService) {
        super();
     }

    ngOnInit(): void {
        if (!this._useExternalSource) {
            this.certSvc.onCertAdded.pipe(
                takeUntil(this._unsubscribe$)
            ).subscribe((res: { addedCerts: CertInfo[], currentCerts: CertInfo[] }) => {
                this._certSrcList = res.currentCerts;
                this.refactor();
            });

            this.certSvc.onCertRemoved.pipe(
                takeUntil(this._unsubscribe$)
            ).subscribe((res: { removedCerts: CertInfo[], currentCerts: CertInfo[] }) => {
                this._certSrcList = res.currentCerts;
                this.refactor();
            });

            this.refreshCert(true);
        }
    }

    selectCert(cert: CertInfo, checked: boolean): void {
        this._certSelectionMap[cert.id] = this._certSelectionMap[cert.id] || { cert: cert, selected: false };
        this._certSelectionMap[cert.id].selected = checked;

        this.onCertSelected.emit(HelperLib.mapToList<{ cert: CertInfo, selected: boolean }>(this._certSelectionMap).filter(c => c.selected).map(c => c.cert));
    }

    addCert(): void {
        this.createScepFuncDlg(CERT_FUNC_ADD);
    }

    removeCert(cert: CertInfo): void {
        this.createScepFuncDlg(CERT_FUNC_REMOVE, [cert]);
    }

    changeCertInstallStatus(cert: CertInfo, targetStatus: CertInstallStatus): void {
        Logger.logInfo('Cert', 'ChangeInstallStatus', `Change cert ${cert.name} to ${targetStatus}`);
        if (this.supportUpdateInstallStatus) {
            this._certInstallStatusMap[cert.id].toStatus = targetStatus;
            this.onCertInstallStatusChanged.emit({ cert: cert, fromStatus: this._certInstallStatusMap[cert.id].fromStatus, toStatus: this._certInstallStatusMap[cert.id].toStatus });
        }
    }

    refreshCert(force: boolean = false): void {
        this._loading = true;
        this.certSvc.getCerts(force).subscribe((res: { certs: CertInfo[], isFault: boolean, errorMessage?: string }) => {
            if (!res.isFault) {
                this._certSrcList = res.certs;
                this.refactor();
            }

            this._loading = false;
        });
    }

    clickManageCertLink(): void {
        this.onCertManagementLinkClicked.emit();
    }

    onPageChanged(page: number): void {
        if (this._loading) {
            return;
        }

        if (this._currentPage !== page) {
            this._currentPage = page;
            this.refactor();
        }
    }

    sortDescend(key: string): void {
        if (this._currentSortKey === key && this._currentSortType === SortType.descend) {
            return;
        }

        this._currentSortKey = key;
        this._currentSortType = SortType.descend;
        this.refactor();
    }

    sortAscend(key: string): void {
        if (this._currentSortKey === key && this._currentSortType === SortType.ascend) {
            return;
        }

        this._currentSortKey = key;
        this._currentSortType = SortType.ascend;
        this.refactor();
    }

    private refactor(filters: { key: string, value: string }[] = []): void {
        // search
        this._certDisplayList = filters.length > 0 ? this._certSrcList.filter(c => {
            for (let filter of filters) {
                if (c[filter.key].toLocaleLowerCase().indexOf(filter.value) < 0) {
                    return false;
                }
            }

            return true;
        }) : this._certSrcList.map(c => c);

        // sort
        this._certDisplayList = this._certDisplayList.sort((a: CertInfo, b: CertInfo) => {
            let ca: string = a[this._currentSortKey];
            let cb: string = b[this._currentSortKey];

            if (ca === cb) {
                return 0;
            }

            switch (this._currentSortType) {
                case SortType.ascend:
                    {
                        return ca >= cb ? 1 : -1;
                    }
                case SortType.descend:
                    {
                        return ca >= cb ? -1 : 1;
                    }
            }
        });

        //notify result page amount.
        this._displayAmount = this._certDisplayList.length;

        //filter by page
        if (this._certDisplayList.length > this.numberInPage) {
            const startIndex = (this._currentPage - 1) * this.numberInPage;
            this._certDisplayList = this._certDisplayList.slice(startIndex, startIndex + this.numberInPage);
        }
    }

    private onActionComplete(res: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }): void { }

    private createScepFuncDlg(funcName: string, data: CertInfo[] = [], other?: any): void {
        const item: CertFuncItem = this.certFuncSvc.getItemByName(funcName);
        if (item) {
            const viewContainerRef = this._funcHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<ICertFuncCtrl>componentRef.instance).title = item.title;
            (<ICertFuncCtrl>componentRef.instance).funcName = funcName;
            (<ICertFuncCtrl>componentRef.instance).certList = data;
            (<ICertFuncCtrl>componentRef.instance).other = other;
            (<ICertFuncCtrl>componentRef.instance).onActionCompleted = this.onActionComplete.bind(this);
        }
    }
}