import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TopMenuService, IMenu } from './menu.service';

@Component({
    selector: 'na-top-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
})
export class TopMenuViewComponent implements OnInit {
    _menu: IMenu;
    @Input('menu')
    set menu(m: IMenu) {
        this._menu = m;
    }

    _collapseID: string;
    @Input('collapseID')
    set collapseID(v: string) {
        this._collapseID = v;
    }

    _rootIconLayout: string = 'left';
    @Input('rootIconLayout')
    set iconLayout(v: string) {
        this._rootIconLayout = v; //top | left
    }

    _useDarkTheme: boolean = false;
    @Input('useDarkTheme')
    set darkTheme(v: boolean) {
        this._useDarkTheme = v; //true | false
    }

    _expandIcon: string = 'down';
    _foldIcon: string = 'up';
    _expandDirection: string = 'down';
    @Input('direction')
    set direction(v: string) {
        this._expandDirection = v;
        if (this._expandDirection === 'up') {
            this._expandIcon = 'up';
            this._foldIcon = 'down';
        }
    }

    @Output() onMenuRouteChanged = new EventEmitter<IMenu[]>();
    @Output() onMenuExpanded = new EventEmitter<{ menu: IMenu, expand: boolean }>();

    constructor(
        private router: Router,
        private menuSvc: TopMenuService) {

    }

    ngOnInit(): void {
        if (!this._menu) {
            this._menu = this.menuSvc.menu;
        }
    }

    isMenuActive(menu: IMenu): boolean {
        return this.menuSvc.isMenuActive(menu);
    }

    expandMenu(menu: IMenu): void {
        this.menuSvc.expandMenu(menu);
    }

    goToMenu(menu: IMenu): void {
        if (menu.isLink) {
            this.onChildMenuRouteChange([menu]);
        }
    }

    onChildMenuRouteChange(menuRoutes: IMenu[]): void {
        this.tryNavigate(menuRoutes);
        this.onMenuRouteChanged.emit([this._menu].concat(menuRoutes));
    }

    private tryNavigate(menuRoutes?: IMenu[]): void {
        //navigate only on the root component
        if (this._menu.isRoot) {
            this.router.navigate([[this._menu.path, ...menuRoutes.map(m => m.path)].join('/')]);
        }
    }
}