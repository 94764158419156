import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { ICertData } from './api.cert.data';

export interface IListCertQueryParameter {
    limit?: number; //how much to query
    skip?: number; //start index
    search?: { [searchKey: string]: string } | string;
    sort?: { [sortKey: string]: number } | string;
}

export interface IListCertRxData {
    skip: number;
    limit: number;
    total: number;
    itemList: ICertData[]
}

export class APIListCertManager extends APIBaseManager<void, IListCertQueryParameter, void, IListCertRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListCert';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: IListCertQueryParameter): string {
        if (queryParameters.sort instanceof Object) {
            queryParameters.sort = Object.keys(queryParameters.sort).map(key => key + ':' + queryParameters.sort[key]).join(',');
        }

        if (queryParameters.search instanceof Object) {
            queryParameters.search = JSON.stringify(queryParameters.search);
        }

        return  `${super.getRequestURL()}accounts/certificates${this.transformObjectQueryParameterToStr(queryParameters)}`;
    }
}