import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';

export interface IQueryRemoteCtrlPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export interface IQueryRemoteCtrlRxData {
    room: string;
    account: string;
}

export class APIQueryRemoteCtrlManager extends APIBaseManager<IQueryRemoteCtrlPathParameter, void, void, IQueryRemoteCtrlRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_QueryRemoteCtrl';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IQueryRemoteCtrlPathParameter, queryParameters: void): string {
        return `${super.getRequestURL()}virtualDevices/${pathParameters.virtualDeviceID}/${pathParameters.virtualDevicePairedID}/remoteControl`;
    }
}