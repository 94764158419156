import { Component, OnInit } from '@angular/core';
import { IPolicyTypeFunc } from './policy-type.service';
import { PolicyType, PolicyDataSecurity } from '../policy.data';

@Component({
    templateUrl: './policy-security.component.html',
    styleUrls: ['../policy.style.css']
})
export class PolicyTypeSecurityComponent implements OnInit,  IPolicyTypeFunc {
    onPolicyDataValidate?: (valid: boolean) => void;
    type: PolicyType;
    _data: PolicyDataSecurity;
    set data(d: PolicyDataSecurity) {
        this._data = d;
    }
    isInEdit: boolean;

    _showPasswordPlainText: boolean = false;

    ngOnInit(): void {
        if (this.isInEdit) {
            this.onPolicyDataValidate?.(this._data.isValid);
        }
    }

    enableDevicePassword(checked: boolean): void {
        this._data.enableDevicePassword = checked;
        this.onPolicyDataValidate(this._data.isValid);
    }

    enableSpecificPassword(): void {
        this._data.devicePassword.enabled = true;
        this._data.enableOTP = false;
        this.onPolicyDataValidate(this._data.isValid);
    }

    enableOTP(): void {
        this._data.devicePassword.enabled = false;
        this._data.enableOTP = true;
        this.onPolicyDataValidate(this._data.isValid);
    }

    checkoutDevicePassword(checked: boolean): void {
        this._data.devicePassword.enabled = checked;
        this.onPolicyDataValidate(this._data.isValid);
    }

    showPasswordPlainText(show: boolean): void {
        this._showPasswordPlainText = show;
    }

    changeDeviceNewPassword(pwd: string): void {
        this._data.devicePassword.new = pwd;
        this.onPolicyDataValidate(this._data.isValid);
    }

    changeDeviceConfirmPassword(pwd: string): void {
        this._data.devicePassword.confirm = pwd;
        this.onPolicyDataValidate(this._data.isValid);
    }
}