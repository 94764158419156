<!-- net type -->
<div class="form-group row">
  <label for="col-net-type" class="col-xl-4 col-5 col-form-label col-form-label-sm">
    Type
  </label>
  <div class="col-xl-8 col-7">
    <div id="col-net-type" class="form-control-plaintext">
      {{ _currentNetworkType }}
    </div>
  </div>
</div>
<ng-template [ngIf]="_currentNetworkType === _enumNetworkType.Ethernet">
  <!-- eth ip -->
  <div class="form-group row">
    <label for="col-eth-ip" class="col-xl-4 col-5 col-form-label col-form-label-sm">
      {{ 'key-net-ip' | translate | capitalFirstWord }}
    </label>
    <div class="col-xl-8 col-7">
      <div id="col-eth-ip" class="form-control-plaintext">
        {{ _device?.currentSettings[constantSvc.DEVKEY_NET_LAN_IP] }}
      </div>
    </div>
  </div>
  <!-- eth gateway -->
  <div class="form-group row">
    <label for="col-eth-gateway" class="col-xl-4 col-5 col-form-label col-form-label-sm">
      {{ 'key-net-gateway' | translate | capitalFirstWord }}
    </label>
    <div class="col-xl-8 col-7">
      <div id="col-eth-gateway" class="form-control-plaintext">
        {{ _device?.currentSettings[constantSvc.DEVKEY_NET_LAN_GATEWAY] }}
      </div>
    </div>
  </div>
  <!-- eth mask -->
  <div class="form-group row">
    <label for="col-eth-mask" class="col-xl-4 col-5 col-form-label col-form-label-sm">
      {{ 'key-net-mask' | translate | capitalFirstWord }}
    </label>
    <div class="col-xl-8 col-7">
      <div id="col-eth-mask" class="form-control-plaintext">
        {{ _device?.currentSettings[constantSvc.DEVKEY_NET_LAN_NETMASK] }}
      </div>
    </div>
  </div>
  <!-- eth dns1 -->
  <div class="form-group row">
    <label for="col-eth-dns1" class="col-xl-4 col-5 col-form-label col-form-label-sm">
      DNS 1
    </label>
    <div class="col-xl-8 col-7">
      <div id="col-eth-dns1" class="form-control-plaintext">
        {{ _device?.currentSettings[constantSvc.DEVKEY_NET_LAN_DNS1] }}
      </div>
    </div>
  </div>
  <!-- eth dns2 -->
  <div class="form-group row">
    <label for="col-eth-dns2" class="col-xl-4 col-5 col-form-label col-form-label-sm">
      DNS 2
    </label>
    <div class="col-xl-8 col-7">
      <div id="col-eth-dns2" class="form-control-plaintext">
        {{ _device?.currentSettings[constantSvc.DEVKEY_NET_LAN_DNS2] }}
      </div>
    </div>
  </div>
</ng-template>
<ng-template [ngIf]="_currentNetworkType === _enumNetworkType.Wifi">
  <!-- wifi ssid -->
  <div class="form-group row">
    <label for="col-wifi-ssid" class="col-xl-4 col-5 col-form-label col-form-label-sm">
      SSID
    </label>
    <div class="col-xl-8 col-7">
      <div id="col-wifi-ssid" class="form-control-plaintext">
        {{ _device?.currentSettings[constantSvc.DEVKEY_NET_WIFI_SSID] }}
      </div>
    </div>
  </div>
  <!-- wifi ip -->
  <div class="form-group row">
    <label for="col-wifi-ip" class="col-xl-4 col-5 col-form-label col-form-label-sm">
      {{ 'key-net-ip' | translate | capitalFirstWord }}
    </label>
    <div class="col-xl-8 col-7">
      <div id="col-wifi-ip" class="form-control-plaintext">
        {{ _device?.currentSettings[constantSvc.DEVKEY_NET_WIFI_IP] }}
      </div>
    </div>
  </div>
  <!-- wifi gateway -->
  <div class="form-group row">
    <label for="col-wifi-gateway" class="col-xl-4 col-5 col-form-label col-form-label-sm">
      {{ 'key-net-gateway' | translate | capitalFirstWord }}
    </label>
    <div class="col-xl-8 col-7">
      <div id="col-wifi-gateway" class="form-control-plaintext">
        {{ _device?.currentSettings[constantSvc.DEVKEY_NET_WIFI_GATEWAY] }}
      </div>
    </div>
  </div>
  <!-- wifi mask -->
  <div class="form-group row">
    <label for="col-wifi-mask" class="col-xl-4 col-5 col-form-label col-form-label-sm">
      {{ 'key-net-mask' | translate | capitalFirstWord }}
    </label>
    <div class="col-xl-8 col-7">
      <div id="col-wifi-mask" class="form-control-plaintext">
        {{ _device?.currentSettings[constantSvc.DEVKEY_NET_WIFI_NETMASK] }}
      </div>
    </div>
  </div>
  <!-- wifi dns1 -->
  <div class="form-group row">
    <label for="col-wifi-dns1" class="col-xl-4 col-5 col-form-label col-form-label-sm">
      DNS 1
    </label>
    <div class="col-xl-8 col-7">
      <div id="col-wifi-dns1" class="form-control-plaintext">
        {{ _device?.currentSettings[constantSvc.DEVKEY_NET_WIFI_DNS1] }}
      </div>
    </div>
  </div>
  <!-- wifi dns2 -->
  <div class="form-group row">
    <label for="col-wifi-dns2" class="col-xl-4 col-5 col-form-label col-form-label-sm">
      DNS 2
    </label>
    <div class="col-xl-8 col-7">
      <div id="col-wifi-dns2" class="form-control-plaintext">
        {{ _device?.currentSettings[constantSvc.DEVKEY_NET_WIFI_DNS2] }}
      </div>
    </div>
  </div>
</ng-template>