import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CustomPipeModule } from '../../../lib/pipes/custom-pipe.module';
import { TranslateModule } from '../../../translate/translate.module';

import { UIElementModule } from '../../../uiElement/uiElement.module';

import { RouterModule } from '@angular/router';
import { ReportWrapperComponent } from './report-wrapper.component';
import { ReportOverviewComponent } from './comp/report-overview.component';
import { ReportConfigComponent } from './comp/report-config.component';
import { ReportService } from './report.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        CustomPipeModule,
        TranslateModule,
        UIElementModule
    ],
    providers: [
        ReportService
    ],
    declarations: [
        ReportWrapperComponent,
        ReportOverviewComponent,
        ReportConfigComponent
    ],
    exports: []
})
export class ReportModule { }