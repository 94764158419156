import { Component, OnInit } from '@angular/core';
import { TopMenuService } from '../../../../../app/content/menu.service';
import { AccountService } from '../../../../../app/entry/account.service';
import { AlertService } from '../alert.service';
import { AlertTabType } from '../alertConfig/alert.data';

@Component({
    templateUrl: './alert-open-overview.component.html',
    styleUrls: []
})
export class OpenAlertOverviewComponent implements OnInit {
    _notificationList: { id: string, lastDateTime: string, type: string, subject: string, sourceAlertID: string, isAck: boolean }[] = [];
    _selectionMap: { [notificationID: string]: { acked: boolean, checked: boolean, working: boolean } } = {};
    _allChecked: boolean;

    _alertConfigRoute: string;
    _isLoading: boolean = false;
    _isAcking: boolean = false;

    _currentPage: number = 1;
    _total: number;
    _errorMessage: string;

    constructor(
        private accountSvc: AccountService,
        private alertSvc: AlertService,
        private menuSvc: TopMenuService
    ) { }

    ngOnInit(): void {
        this._alertConfigRoute = this.menuSvc.getAlertRoute(AlertTabType.config);
        this.getNotificationList();
    }

    supportAckOpenAlert(): boolean {
        return this.accountSvc.hasScope_alertNotification_ack();
    }

    private getNotificationList(): void {
        this._isLoading = true;
        this.alertSvc.getOpenAlertNotificationList(this._currentPage - 1).subscribe((res: { data: { id: string, lastDateTime: string, type: string, subject: string, sourceAlertID: string, isAck: boolean }[], total?: number, skip?: number, limit?: number, errorMessage?: string }) => {
            if (res.errorMessage) {
                this._errorMessage = res.errorMessage;
            }
            else {
                this._total = res.total;
                this._notificationList = res.data;
                this._notificationList.forEach(a => {
                    const checked: boolean = this._selectionMap[a.id] !== undefined ? this._selectionMap[a.id].checked : false;
                    if (!checked) {
                        this._allChecked = false;
                    }

                    this._selectionMap[a.id] = {
                        acked: a.isAck,
                        checked: checked,
                        working: false
                    };
                });
            }

            this._isLoading = false;
        });
    }

    ack(): void {
        this._isAcking = true;
        this.alertSvc.ackOpenNotifications(Object.keys(this._selectionMap).filter((notificationID: string) => this._selectionMap[notificationID].checked)).subscribe((res: { notificationID: string, success: boolean }[]) => {
            res.forEach(r => {
                if (r.success) {
                    this._selectionMap[r.notificationID].acked = true;
                    this._selectionMap[r.notificationID].checked = false;
                }
            });
            this._isAcking = false;
        });
    }

    unack(notificationID: string): void {
        this._isAcking = true;
        this._selectionMap[notificationID].working = true;
        this.alertSvc.unackOpenNotification(notificationID).subscribe((res: boolean) => {
            if (res) {
                this._selectionMap[notificationID].acked = false;
            }

            this._isAcking = false;
            this._selectionMap[notificationID].working = false;
        });
    }

    refresh(): void {
        this.getNotificationList();
    }

    selectAllNotifications(checked: boolean): void {
        this._allChecked = checked;
        this._notificationList.map(a => {
            if (!this._selectionMap[a.id].acked) {
                this._selectionMap[a.id].checked = checked;
            }
        });
    }

    selectNotification(a: { id: string, lastDateTime: string, type: string, subject: string, isAck: boolean }, checked: boolean): void {
        this._selectionMap[a.id].checked = checked;
    }

    onPageChange(page: number): void {
        if (this._currentPage !== page) {
            this._currentPage = page;
            this.getNotificationList();
        }
    }
}