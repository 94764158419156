export class CertInfo {
    static CERT_ID: number = 1;

    id: string;
    name: string;
    md5: string;
    fingerprint: string;
    createDate: Date;
    expiryDate: Date;
    updateDate: Date;

    constructor(options?: { id?: string, name?: string, expiryDate?: string, fingerprint?: string }) {
        this.id = options?.id || 'cert-' + CertInfo.CERT_ID++;
        this.name = options?.name || '';
        this.fingerprint = options?.fingerprint;
        this.expiryDate = new Date(options?.expiryDate);
    }

    copy(): CertInfo {
        const copied: CertInfo = new CertInfo({ id: this.id, name: this.name, fingerprint: this.fingerprint});
        copied.expiryDate = new Date(this.expiryDate);
        copied.createDate = new Date(this.createDate);
        copied.updateDate = new Date(this.updateDate);

        return copied;
    }
}

export enum CertInstallStatus {
    Free = 'Free',
    Install = 'Install',
    Revoke = 'Revoke',
    Unmanageable = 'Unmanageable'
}