import { Component } from '@angular/core';
import { DialogPage } from '../../../../lib/common/common.data';
import { ScepDeviceInfo, ScepServerInfo } from '../scep.data';
import { ScepService } from '../scep.service';
import { IScepFuncCtrl } from './scep-func.def';
import { VirtualDlgComponent } from 'app/content/virtual/dlg/virtual-dlg.component';
import { AccountService } from 'app/entry/account.service';

@Component({
    templateUrl: './scep-dlg-renew.component.html',
    styleUrls: ['../scep.style.css']
})
export class ScepRenewDlgComponent extends VirtualDlgComponent implements IScepFuncCtrl {
    title: string;
    funcName: string;
    scepList: ScepServerInfo[];
    other?: any;
    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;

    _scep: ScepServerInfo;
    _deviceList: ScepDeviceInfo[] = [];
    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;
    _errorMessage: string;

    constructor(protected accountSvc: AccountService, private scepSvc: ScepService) { super(accountSvc); }

    ngOnInit(): void {
        super.ngOnInit();
        this._scep = this.scepList ? this.scepList[0] : null;
        this._deviceList = this.other ? this.other : [];
    }

    renew(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;

        this.scepSvc.renewScepDevice(this._deviceList, this._scep).subscribe((res: { scep: ScepServerInfo, isFault: boolean, errorMessage?: string }) => {
            this._page = DialogPage.result;
            this._errorMessage = res.errorMessage;
        });
    }
}