import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
    selector: 'na-timePicker',
    templateUrl: './timePicker.component.html',
    styleUrls: ['./timePicker.component.css']
})
export class TimePickerComponent implements OnInit {
    private static TimePickerInstanceNumber: number = 1;

    private _defaultTime: string = '';
    @Input('time')
    set defaultTime(time: string) {
        this._defaultTime = time || '04:00';
        this._selectH = this._defaultTime.split(':')[0];
        this._selectM = this._defaultTime.split(':')[1];
        this.updateCurrentTime();
    }
    get defaultTime(): string {
        return this._defaultTime;
    }

    _disabled: boolean;
    @Input('disabled')
    set disabled(v: boolean) {
        this._disabled = v;
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(d: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = d;
    }

    _on: boolean = true;
    @Input('on')
    set on(v: boolean) {
        this._on = v;
    }

    private _halfHourOnly: boolean = false;
    @Input('halfHourOnly')
    set halfHourOnly(isHalfHourOnly: boolean) {
        this._halfHourOnly = isHalfHourOnly;
    }

    private _minuteIncre: number = 1;
    @Input('minuteIncre')
    set minuteIncre(incre: number) {
        this._minuteIncre = incre;
    }

    private _showSelectionTime: boolean = true;
    @Input('showSelectionTime')
    set showSelectionTime(show: boolean) {
        this._showSelectionTime = show;
    }
    get showSelectionTime(): boolean {
        return this._showSelectionTime;
    }

    private _showBorder: boolean = true;
    @Input()
    set showBorder(show: boolean) {
        this._showBorder = show;
    }
    get showBorder(): boolean {
        return this._showBorder;
    }

    _autoNotify: boolean = false;
    @Input()
    set autoNotify(enabled: boolean) {
        this._autoNotify = enabled;
    }
    get autoNotify(): boolean {
        return this._autoNotify;
    }

    private _minH: string = '00';
    private _minM: string = '00';
    private _minTime: string = '00:00';
    @Input()
    set minTime(minTime: string) {
        this._minTime = minTime;
        this._minH = minTime.split(':')[0];
        this._minM = minTime.split(':')[1];
    }

    private _maxH: string = '24';
    private _maxM: string = '00';
    private _maxTime: string = '24:00';
    @Input()
    set maxTime(maxTime: string) {
        this._maxTime = maxTime;
        this._maxH = maxTime.split(':')[0];
        this._maxM = maxTime.split(':')[1];
    }

    @Output() onTimeChanged = new EventEmitter<string>();

    _selectH: string;
    private _selectM: string;
    private _selectTime: string;
    private _random: number;

    _hourList: string[] = [];
    _minuteList: string[] = [];

    constructor() { }

    ngOnInit(): void {
        this._random = TimePickerComponent.TimePickerInstanceNumber++;

        for (let i = 0; i <= 24; i++) {
            this._hourList.push((i < 10 ? '0' + i : i).toString());
        }

        for (let j = 0; j < 60; j = j + this._minuteIncre) {
            this._minuteList.push((j < 10 ? '0' + j : j).toString());
        }
    }

    private isHourDisabled(h: string): boolean {
        if (this._minTime) {
            return h < this._minH ? true : false;
        }
        else if (this._maxTime) {
            return h > this._maxH ? true : false;
        }

        return false;
    }

    private isMinuteDisabled(m: string): boolean {
        if (this._minTime) {
            if (this._selectH > this._minH) {
                return false;
            }
            else {
                //_selectH === _minH
                return m < this._minM ? true : false;
            }
        }
        else if (this._maxTime) {
            if (this._selectH < this._maxH) {
                return false;
            }
            else {
                //_selectH === _maxH
                return m > this._maxM ? true : false;
            }
        }

        return false;
    }

    private isTimeIllegal(): boolean {
        if (this._minTime) {
            return this._selectH + ":" + this._selectM < this._minTime ? true : false;
        }
        else if (this._maxTime) {
            return this._selectH + ":" + this._selectM > this._maxTime ? true : false;
        }

        return false;
    }

    private changeHour(h): void {
        this._selectH = h;
        let toNotify: boolean = false;

        if (this._minTime) {
            if (this._selectH === this._minH && this._selectM < this._minM) {
                this._selectM = this._minM;
                toNotify = true;
            }
        }
        if (this._maxTime) {
            if (this._selectH === this._maxH && this._selectM > this._maxM) {
                this._selectM = this._maxM;
                toNotify = true;
            }
        }

        if (this._autoNotify || toNotify) {
            this.updateCurrentTime(true);
        }
    }

    private changeMinute(m): void {
        this._selectM = m;
        if (this._autoNotify) {
            this.updateCurrentTime(true);
        }
    }

    private updateCurrentTime(emit: boolean = false): void {
        this._selectTime = this._selectH + ":" + this._selectM;
        if (emit) {
            this.onTimeChanged.emit(this._selectTime);
        }
    }

    private cancel(): void {
        this._selectH = this._defaultTime.split(':')[0];
        this._selectM = this._defaultTime.split(':')[1];
    }

    private ok(): void {
        this.updateCurrentTime(true);
    }
}