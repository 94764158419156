<div class='modal-dialog config-dlg' role="document">
    <div class='modal-content'>
        <div class='modal-header'>
            <h4 class="modal-title">{{ title }}</h4>
            <button #dlgClose type="button" class="close" [disabled]="!isCacelValid()" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body'>
            <ng-template user-dlg-func></ng-template>
            <div *ngIf="_page === _enumPage.result" class="mt-2">
                <div *ngIf="!_errorMessage; else templateDeleteError" class="block block-success">
                    <i class="fas fa-check-circle white"></i>
                    <span class="ml-2">{{ title }} successfully</span>
                </div>
                <ng-template #templateDeleteError>
                    <div class="block block-error">
                        <i class="fas fa-exclamation-circle"></i>
                        <span class="ml-2">{{ title }} failed : </span>
                        <div>{{ _errorMessage }}</div>
                    </div>
                </ng-template>
            </div>
            <div class="row control-block d-flex justify-content-center align-items-center mt-4">
                <button *ngIf="_page === _enumPage.result && !_errorMessage; else templateDlgInAction" type="button"
                    class="btn btn-sm btn-action btn-light" data-dismiss="modal" (click)="close()">
                    <span class="fas fa-times-circle"></span>
                    {{ 'key-close' | translate | capitalFirstWord }}
                </button>
                <ng-template #templateDlgInAction>
                    <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="!isCacelValid()"
                        data-dismiss="modal" (click)="cancel()">
                        <span class="fas fa-times-circle"></span>
                        {{ 'key-cancel' | translate | capitalFirstWord }}
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="!isSubmitValid()"
                        (click)="submit()">
                        <span [ngClass]="'fas ' + _action_icon"></span>
                        {{ _action_txt }}
                    </button>
                    <div *ngIf="_page === _enumPage.submit" class="loading loading-p0 ml-4">
                        <div class="loading-box"></div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>