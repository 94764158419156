import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../api.wrapper.base';
import { APICreateAlertManager } from './setting/api.alert.create';
import { APIUpdateAlertManager } from './setting/api.alert.update';
import { APIListAlertManager } from './setting/api.alert.list';
import { APIGetAlertManager } from './setting/api.alert.get';
import { APIDeleteAlertManager } from './setting/api.alert.delete';
import { APIGetAlertSampleManager } from './api.alert.sample.get';
import { APIListAlertNotificationManager } from './notification/api.alert.notification.list';
import { APIAckAlertNotificationManager } from './notification/api.alert.notification.ack';
import { APIUnAckAlertNotificationManager } from './notification/api.alert.notification.unack';

export class APIAlertWrapper extends APIWrapperBase {
    CreateAlert: APICreateAlertManager;
    UpdateAlert: APIUpdateAlertManager;
    ListAlert: APIListAlertManager;
    GetAlert: APIGetAlertManager;
    DeleteAlert: APIDeleteAlertManager;
    GetAlertSample: APIGetAlertSampleManager;
    ListAlertNotifications: APIListAlertNotificationManager;
    AckAlertNotification: APIAckAlertNotificationManager;
    UnackAlertNotification: APIUnAckAlertNotificationManager;

    constructor(private http: HttpClient) {
        super();

        this.CreateAlert = new APICreateAlertManager(http);
        this.UpdateAlert = new APIUpdateAlertManager(http);
        this.ListAlert = new APIListAlertManager(http);
        this.GetAlert = new APIGetAlertManager(http);
        this.DeleteAlert = new APIDeleteAlertManager(http);
        this.GetAlertSample = new APIGetAlertSampleManager(http);
        this.ListAlertNotifications = new APIListAlertNotificationManager(http);
        this.AckAlertNotification = new APIAckAlertNotificationManager(http);
        this.UnackAlertNotification = new APIUnAckAlertNotificationManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.CreateAlert, 
            this.UpdateAlert, 
            this.ListAlert,
            this.GetAlert,
            this.DeleteAlert,
            this.GetAlertSample,
            this.ListAlertNotifications,
            this.AckAlertNotification,
            this.UnackAlertNotification
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}