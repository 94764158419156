import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IVirtualDeviceRxData } from './virtualDevice.common';

export interface IUnPairVirtualDevicePathParameter {
    virtualDeviceID: string;
}

export interface IUnpairVirtualDeviceOptinalQueryParamter {
    removeVirtualDevice: boolean;
}

export class APIUnPairVirtualDeviceManager extends APIBaseManager<IUnPairVirtualDevicePathParameter, IUnpairVirtualDeviceOptinalQueryParamter, void, IVirtualDeviceRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UnPairVirtualDevice';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IUnPairVirtualDevicePathParameter, queryParameters: IUnpairVirtualDeviceOptinalQueryParamter): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/pair' + this.transformObjectQueryParameterToStr(queryParameters);
    }
}