import { Component, EventEmitter, Input, Output, OnInit, ElementRef, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DeviceInfo } from '../../content/device/data/device-info';
import { DeviceService } from '../../content/device/device.service';
import { AccountService } from '../../entry/account.service';
import { OwnerType, SortType } from '../../lib/common/common.data';
import { ConstantService } from '../../lib/common/constant.service';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    selector: 'na-dev-select',
    templateUrl: './dev-select.component.html',
    styleUrls: ['./dev-select.component.css']
})
export class DeviceSelectionComponent extends AutoUnsubscribeComponent implements OnInit {
    _isLoading: boolean = false;
    _deviceList: DeviceInfo[] = [];
    _displayDeviceList: DeviceInfo[] = [];
    _currentSortKey: string;
    _currentSortType: SortType;
    _enumSortType: typeof SortType = SortType;
    _allOwnerDeviceSelected: boolean = false;
    _deviceOwnerType: OwnerType = OwnerType.self;
    _enumPlayerOwnerType: typeof OwnerType = OwnerType;
    _deviceCheckMap: { [virtualDeviceID: string]: boolean } = {};
    _isEnterprise: boolean = this.accountSvc.isEnterprise();

    _title: string;
    @Input('title')
    set title(v: string) {
        this._title = v;
    }

    _searchSupport: boolean = true;
    @Input('searchSupport')
    set searchSupport(v: boolean) {
        this._searchSupport = v;
    }

    _sortSupport: boolean = true;
    @Input('sortSupport')
    set sortSupport(v: boolean) {
        this._sortSupport = v;
    }

    @Input('selectDeviceIDList')
    set selectDeviceIDList(v: string[]) {
        if (v) {
            v.forEach(vID => {
                this._deviceCheckMap[vID] = true;
            });
        }
    }

    _searchRef: ElementRef;
    @ViewChild('search')
    set search(v: ElementRef) {
        if (!this._searchRef && v) {
            this._searchRef = v;

            fromEvent(this._searchRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this.refactor_devices(e ? e.target.value.toLocaleLowerCase() : '');
            });
        }
    }

    @Output() onDeviceSelectChanged = new EventEmitter<DeviceInfo[]>();

    constructor(
        private constantSvc: ConstantService,
        private accountSvc: AccountService,
        private devSvc: DeviceService
    ) { 
        super();
    }

    ngOnInit(): void {
        this._isLoading = true;
        this.devSvc.getDevices('dev-select.onInit').subscribe((res: { isFault: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
            if (!res.isFault) {
                this._deviceList = res.devices.filter(d => d.isPaired);
                this._deviceList.forEach(d => this._deviceCheckMap[d.virtualId] = this._deviceCheckMap[d.virtualId] || false);
                this.refactor_devices();
            }
            this._isLoading = false;
        });
    }

    sortDescend(sortKey: string): void {
        this._currentSortKey = sortKey;
        this._currentSortType = SortType.descend;
        this.refactor_devices();
    }

    sortAscend(sortKey: string): void {
        this._currentSortKey = sortKey;
        this._currentSortType = SortType.ascend;
        this.refactor_devices();
    }

    changeDeviceOwnerType(ownerType: OwnerType): void {
        this._deviceOwnerType = ownerType;
    }

    selectAllPlayer(checked: boolean) {
        const comparedAccountID: string = this.accountSvc.isEnterprise() ? this.accountSvc.enterpriseAccountID : this.accountSvc.accountID;
        if (this._deviceOwnerType === OwnerType.self) {
            if (this._allOwnerDeviceSelected !== checked) {
                this._allOwnerDeviceSelected = checked;
                this._deviceList.filter(d => d.virtualDeviceOwnerID === comparedAccountID).forEach((d: DeviceInfo) => {
                    this._deviceCheckMap[d.virtualId] = this._allOwnerDeviceSelected;
                });

                this.update();
            }
        }
    }

    selectPlayer(dev: DeviceInfo, checked: boolean) {
        if (this._deviceCheckMap[dev.virtualId] !== checked) {
            this._deviceCheckMap[dev.virtualId] = checked;
            if (!this._deviceCheckMap[dev.virtualId]) {
                if (this._deviceOwnerType === OwnerType.self) {
                    this._allOwnerDeviceSelected = false;
                }
            }

            this.update();
        }
    }

    private refactor_devices(searchText?: string): void {
        //filter
        this._displayDeviceList = searchText ? this._deviceList.filter(d => d.virtualName.toLocaleLowerCase().indexOf(searchText) >= 0) : this._deviceList;

        //sort
        this._displayDeviceList = this._displayDeviceList.sort((a: DeviceInfo, b: DeviceInfo) => {
            const comp_a: string = a.currentSettings[this._currentSortKey];
            const comp_b: string = b.currentSettings[this._currentSortKey];

            if (comp_a === comp_b) {
                return 0;
            }

            switch (this._currentSortType) {
                case SortType.ascend:
                    {
                        return comp_a >= comp_b ? 1 : -1;
                    }
                case SortType.descend:
                    {
                        return comp_a >= comp_b ? -1 : 1;
                    }
            }
        });
    }

    update(): void {
        this.onDeviceSelectChanged.emit(this._deviceList.filter(d => this._deviceCheckMap[d.virtualId]));
    }
}