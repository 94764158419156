<div class="d-flex align-items-center">
    <div style="flex:0.5">
        <div class="input-group searchbar">
            <div class="input-group-prepend">
                <span class="input-group-text" id="input-search-user">
                    <i class="fas fa-search"></i>
                </span>
            </div>
            <input #search class="form-control form-control-sm" type='text' name='search' [disabled]="_loading"
                placeholder='Search device by name or mac' aria-label="Search device"
                aria-describedby="input-search-user">
        </div>
    </div>
    <button type="button" class="btn btn-sm btn-action btn-light ml-2" [disabled]="_refreshCountdown > 0"
        (click)="refreshDevices(true)">
        <i [class.rotate]="_refreshCountdown > 0" class="fas fa-sync-alt mr-1"></i>
        Refresh devices
        <span *ngIf="_refreshCountdown > 0"> - {{ _refreshCountdown }} s</span>
    </button>
</div>
<div>
    <div class="list-group mt-2">
        <div class="list-group-item list-group-header black">
            <div class="row align-items-center">
                <div class="col title">
                    <ng-template [ngIf]="_mode !== _enumScepDeviceOpMode.selectAll">
                        <input type="checkbox" [id]="'cb-th-scep-all-' + _compID"
                            class="cmn-checkbox cmn-checkbox-white" [checked]="_bSelectAll"
                            (change)="selectAllScepDevices($event.target.checked)">
                        <label [for]="'cb-th-scep-all-' + _compID" class="mr-1">
                            <svg>
                                <use xlink:href="#checkmark" />
                            </svg>
                            <span class="sr-only">Select all devices</span>
                        </label>
                    </ng-template>
                    Device name
                    <span class="sorter">
                        <i class="fas fa-caret-up sort-icon descend"
                            [ngClass]="_currentSortKey === COL_DEV_NAME && _currentSortType === _enumSortType.descend ? 'active' : ''"
                            (click)="sortDescend(COL_DEV_NAME)">
                        </i>
                        <i class="fas fa-caret-down sort-icon ascend"
                            [ngClass]="_currentSortKey === COL_DEV_NAME && _currentSortType === _enumSortType.ascend ? 'active' : ''"
                            (click)="sortAscend(COL_DEV_NAME)">
                        </i>
                    </span>
                </div>
                <ng-template ngFor let-col [ngForOf]="_tableHeaderMap | keyvalue">
                    <div *ngIf="col.value.show" class="col title">
                        {{ col.value.name }}
                        <span *ngIf="col.value.sortSupport" class="sorter">
                            <i class="fas fa-caret-up sort-icon descend"
                                [ngClass]="_currentSortKey === col.key && _currentSortType === _enumSortType.descend ? 'active' : ''"
                                (click)="sortDescend(col.key)">
                            </i>
                            <i class="fas fa-caret-down sort-icon ascend"
                                [ngClass]="_currentSortKey === col.key && _currentSortType === _enumSortType.ascend ? 'active' : ''"
                                (click)="sortAscend(col.key)">
                            </i>
                        </span>
                    </div>
                </ng-template>
            </div>
        </div>
        <div *ngIf="!_loading; else templateLoadingScepDeviceTable">
            <div class="list-group-content list-group-hoverless">
                <div *ngFor="let dev of _devDisplayList" class="list-group-item">
                    <div class="row align-items-center">
                        <div class="col text-break d-flex align-items-center">
                            <ng-template [ngIf]="_mode !== _enumScepDeviceOpMode.selectAll">
                                <input type="checkbox" [id]="'cb-th-scep-' + _compID + '-' + dev.info.dev.virtualId"
                                    class="cmn-checkbox" [checked]="dev.checked" [disabled]="!dev.hasLicense"
                                    (change)="selectDevice(dev, $event.target.checked)">
                                <label [for]="'cb-th-scep-' + _compID + '-' + dev.info.dev.virtualId" class="mr-2">
                                    <svg>
                                        <use xlink:href="#checkmark" />
                                    </svg>
                                    <span class="sr-only">Select device {{ dev.info.dev.virtualName }}</span>
                                </label>
                            </ng-template>
                            <i *ngIf="!dev.hasLicense" class="fas fa-exclamation-triangle red-1 mr-1"></i>
                            <a [routerLink]="['/app/device/devices', dev.info.dev.virtualId]" data-toggle="tooltip"
                                [title]="dev.info.dev.virtualId">
                                {{ dev.info.dev.virtualName }}
                            </a>
                        </div>
                        <div *ngIf="_tableHeaderMap[COL_DEV_GROUP].show" class="col text-break">
                            {{ dev.info.devGroup }}
                        </div>
                        <div *ngIf="_tableHeaderMap[COL_DEV_MAC].show" class="col text-break">
                            {{ dev.info.devMAC }}
                        </div>
                        <div *ngIf="_tableHeaderMap[COL_SCEP_STATUS].show" class="col text-break">
                            {{ dev.info.scep.status }}
                        </div>
                        <div *ngIf="_tableHeaderMap[COL_SCEP_ALIAS].show" class="col text-break">
                            {{ dev.info.scep.alias }}
                        </div>
                        <div *ngIf="_tableHeaderMap[COL_SCEP_EXPIRY].show" class="col text-break">
                            {{ dev.info.scep.expiryDate | date:'yyyy-MM-dd HH:mm' }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex mt-1">
                <small>
                    <i class="fas fa-exclamation-triangle red-1 mr-1"></i>
                    : No license
                </small>
                <div class="ml-auto">
                    <na-pager [currentPage]="_currentPage" [numberInPage]="_numInPage" [total]="_devDisplayTotal"
                        (onPageChanged)="onPageChange($event)">
                    </na-pager>
                </div>
            </div>
        </div>
        <ng-template #templateLoadingScepDeviceTable>
            <div class="loading">
                <p>Please wait...</p>
                <div class="loading-box"></div>
            </div>
        </ng-template>
    </div>
</div>