import { Component } from '@angular/core';
import { DialogPage } from '../../../../lib/common/common.data';
import { ScepChallengeInfo, ScepDeviceInfo, ScepDeviceOperationMode, ScepServerInfo } from '../scep.data';
import { ScepService } from '../scep.service';
import { IScepFuncCtrl } from './scep-func.def';
import { VirtualDlgComponent } from 'app/content/virtual/dlg/virtual-dlg.component';
import { AccountService } from 'app/entry/account.service';

@Component({
    templateUrl: './scep-dlg-addDevice.component.html',
    styleUrls: ['../scep.style.css']
})
export class ScepAddDeviceDlgComponent extends VirtualDlgComponent implements IScepFuncCtrl {
    title: string;
    funcName: string;
    scepList: ScepServerInfo[];
    other?: { mode: ScepDeviceOperationMode };
    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;

    _scep: ScepServerInfo;
    _challenge: ScepChallengeInfo = new ScepChallengeInfo();
    _isChallengeValid: boolean = false;
    _deviceList: ScepDeviceInfo[] = [];

    _mode: ScepDeviceOperationMode = ScepDeviceOperationMode.add;
    _enumScepDeviceOperationMode: typeof ScepDeviceOperationMode = ScepDeviceOperationMode;
    
    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;
    _errorMessage: string;

    constructor(protected accountSvc: AccountService, private scepSvc: ScepService) { super(accountSvc); }

    ngOnInit(): void {
        super.ngOnInit();

        this._scep = this.scepList[0];
        if (this.other && this.other.mode) {
            this._mode = this.other.mode;
        }
    }

    onChallengeChange(challenge: ScepChallengeInfo): void {
        this._challenge = challenge;
        this.testChallenge();
    }

    private testChallenge(): void {
        this._isChallengeValid = this._challenge && this._challenge.isValid() ? true : false;
    }

    onDeviceSelect(deviceList: ScepDeviceInfo[]): void {
        this._deviceList = deviceList;
    }

    enroll(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;

        this.scepSvc.enrollScepDevice(this._scep, this._challenge, this._deviceList, this._mode === ScepDeviceOperationMode.update || this._mode === ScepDeviceOperationMode.selectAll ? true : false).subscribe((res: { scep: ScepServerInfo, isFault: boolean, errorMessage?: string }) => {
            this._page = DialogPage.result;
            this._errorMessage = res.errorMessage;

            if (this.onActionCompleted) {
                this.onActionCompleted({
                    funcName: this.funcName,
                    isFault: res.isFault
                });
            }

            if (!res.isFault) {
                setTimeout(() => {
                    this._dlgCloseElementRef.nativeElement.click();
                }, 0);
            }
        });
    }
}