import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { ITicketData, ITicketResourceData } from './api.ticket.common';

export interface ICreateTicketTxData {
    ticketID?: string; //empty for new ticket, required for incremental ticket
    ticketAction?: string;
    resourceData: ITicketResourceData;
    isHold?: boolean; //true for incremental ticket
    scheduledDate?: Date;
    retryTimes?: number;
    retryTimeout?: number;
    notAfterDate?: number;
}

export class APICreateTicketManager extends APIBaseManager<void, void, ICreateTicketTxData, ITicketData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_CreateTicket';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'accounts/tickets';
    }
}