import { Component } from '@angular/core';
import { AppService } from '../../app/app.service';

@Component({
    selector: 'page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent {

    constructor(public appSvc: AppService) {}
}