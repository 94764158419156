import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../api.wrapper.base';
import { APIListPluginManager } from './api.plugin.list';
import { APIListPluginInstanceManager } from './api.plugin.inst.list';
import { APICreatePluginInstanceManager } from './api.plugin.inst.create';
import { APIRemovePluginInstanceManager } from './api.plugin.inst.remove';
import { APISetPluginInstanceRemarkManager } from './api.plugin.inst.setRemark';
import { APISetPluginInstanceCredentialManager } from './api.plugin.inst.setCred';
import { APISetPluginInstanceCallbackUrlManager } from './api.plugin.inst.setCallbackUrl';
import { APIRunPluginInstanceManager } from './api.plugin.inst.try';
import { APISetPluginInstanceEnableManager } from './api.plugin.inst.setEnable';
import { APISetPluginInstanceEventManager } from './api.plugin.inst.setEvent';

export class APIPluginWrapper extends APIWrapperBase {
    ListPlugins: APIListPluginManager;
    ListPluginInstances: APIListPluginInstanceManager;
    CreatePluginInstance: APICreatePluginInstanceManager;
    RemovePluginInstance: APIRemovePluginInstanceManager;
    SetPluginInstanceRemark: APISetPluginInstanceRemarkManager;
    SetPluginInstanceCredential: APISetPluginInstanceCredentialManager;
    SetPluginInstanceCallbackUrl: APISetPluginInstanceCallbackUrlManager;
    SetPluginInstanceEnable: APISetPluginInstanceEnableManager;
    SetPluginInstanceEventListeners: APISetPluginInstanceEventManager;
    RunPluginInstance: APIRunPluginInstanceManager;

    constructor(private http: HttpClient) {
        super();

        this.ListPlugins = new APIListPluginManager(http);
        this.ListPluginInstances = new APIListPluginInstanceManager(http);
        this.CreatePluginInstance = new APICreatePluginInstanceManager(http);
        this.RemovePluginInstance = new APIRemovePluginInstanceManager(http);
        this.SetPluginInstanceRemark = new APISetPluginInstanceRemarkManager(http);
        this.SetPluginInstanceCredential = new APISetPluginInstanceCredentialManager(http);
        this.SetPluginInstanceCallbackUrl = new APISetPluginInstanceCallbackUrlManager(http);
        this.SetPluginInstanceEnable = new APISetPluginInstanceEnableManager(http);
        this.SetPluginInstanceEventListeners = new APISetPluginInstanceEventManager(http);
        this.RunPluginInstance = new APIRunPluginInstanceManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.ListPlugins,
            this.ListPluginInstances,
            this.CreatePluginInstance,
            this.RemovePluginInstance,
            this.SetPluginInstanceRemark,
            this.SetPluginInstanceCredential,
            this.SetPluginInstanceCallbackUrl,
            this.SetPluginInstanceEnable,
            this.SetPluginInstanceEventListeners,
            this.RunPluginInstance
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}