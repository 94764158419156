import { Component, OnInit } from '@angular/core';
import { IPolicyTypeFunc } from './policy-type.service';
import { PolicyType, PolicyDataCertificate } from '../policy.data';
import { CertInfo, CertInstallStatus } from 'app/content/admin/cert/cert.data';

@Component({
    templateUrl: './policy-cert.component.html',
    styleUrls: ['../policy.style.css']
})
export class PolicyTypeCertificateComponent implements OnInit, IPolicyTypeFunc {
    onPolicyDataValidate?: (valid: boolean) => void;
    onPolicyFormCancelled?: () => void;
    type: PolicyType;
    _data: PolicyDataCertificate;
    set data(d: PolicyDataCertificate) {
        this._data = d;
    }
    isInEdit: boolean;

    _certInstallStatusMap: { [certID: string]: { cert: CertInfo, fromStatus: CertInstallStatus, toStatus: CertInstallStatus } } = {};
    _installedCerts: CertInfo[] = [];
    _unmanagedCerts: CertInfo[] = [];

    _selectedUnusedCerts: CertInfo[] = [];

    ngOnInit(): void {
        if (this._data?.certificateMap) {
            Object.keys(this._data.certificateMap).forEach((certID: string) => {
                const certInfo: { cert: CertInfo, installStatus: CertInstallStatus } = this._data.certificateMap[certID];
                this._certInstallStatusMap[certID] = { cert: certInfo.cert, fromStatus: certInfo.installStatus, toStatus: certInfo.installStatus };

                switch (certInfo.installStatus) {
                    case CertInstallStatus.Install:
                    case CertInstallStatus.Revoke:
                        {
                            this._installedCerts.push(certInfo.cert);
                        }
                        break;
                    case CertInstallStatus.Unmanageable:
                        {
                            this._unmanagedCerts.push(certInfo.cert);
                        }
                        break;
                }
            });
        }
    }

    // add cert from unused(Free) to used(Installed) status
    addCertificate(): void {
        this._selectedUnusedCerts.forEach((cert: CertInfo) => {
            this._certInstallStatusMap[cert.id] = this._certInstallStatusMap[cert.id] || { cert: cert, fromStatus: CertInstallStatus.Free, toStatus: CertInstallStatus.Free };
            this._certInstallStatusMap[cert.id].toStatus = CertInstallStatus.Install;
            this._data.certificateMap[cert.id] = { cert: cert, installStatus: CertInstallStatus.Install };
        });
        this._selectedUnusedCerts = [];
        this._installedCerts = [];
        Object.keys(this._certInstallStatusMap).forEach(certID => {
            if (this._certInstallStatusMap[certID].toStatus === CertInstallStatus.Install || this._certInstallStatusMap[certID].toStatus === CertInstallStatus.Revoke) {
                this._installedCerts.push(this._certInstallStatusMap[certID].cert);
            }
        });
    }

    onAppDataValidChange(valid: boolean): void {
        this.onPolicyDataValidate(valid);
    }

    onUnsedCertSelected(certs: CertInfo[]): void {
        this._selectedUnusedCerts = certs;
    }

    onUsedCertInstallStatusChanged(ev: { cert: CertInfo, fromStatus: CertInstallStatus, toStatus: CertInstallStatus }): void {
        if (this._certInstallStatusMap[ev.cert.id]) {
            this._certInstallStatusMap[ev.cert.id].toStatus = ev.toStatus;
            if (ev.fromStatus === CertInstallStatus.Free) {
                if (ev.toStatus === CertInstallStatus.Revoke) {
                    // Select a new cert but mark it as 'revoked' --> no need to set this cert to device
                    delete this._data.certificateMap[ev.cert.id];
                }
            }
            else {
                this._data.certificateMap[ev.cert.id] = this._data.certificateMap[ev.cert.id] || { cert: ev.cert, installStatus: ev.toStatus };
                this._data.certificateMap[ev.cert.id].installStatus = ev.toStatus;
            }
        }
    }

    onCertManagementLinkClicked(): void {
        this.onPolicyFormCancelled();
    }
}