import { Component, OnInit } from '@angular/core';

import { AlertService } from '../../../setting/alert/alert.service';
import { AlertInfo, AlertType } from '../../../setting/alert/alertConfig/alert.data';
import { DevFuncConfirmInterface } from '../../base/confirm/dev-func-confirm.interface';

@Component({
    templateUrl: './alert-confirm-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './alert-confirm-subform.component.css']
})
export class AlertConfirmSubFormComponent implements DevFuncConfirmInterface, OnInit {
    _hasChange: boolean = false;

    private _defaultAlertList: AlertInfo[] = [];
    private _newAlertList: AlertInfo[] = [];
    private _removeAlertList: AlertInfo[] = [];

    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
    }

    allowSubmitCallback: (isAllow: boolean) => void;

    constructor(private alertSvc: AlertService) { }

    ngOnInit(): void {
        this.alertSvc.getAlertSettingList().subscribe((alerts: AlertInfo[]) => {
            alerts.filter(a => a.eventType.type !== AlertType.AccountAccess).forEach((r: AlertInfo) => {
                if (this._actionData.ruleAssignMap[r.id].current === this._actionData.ruleAssignMap[r.id].after) {
                    if (this._actionData.ruleAssignMap[r.id].current) {
                        this._defaultAlertList.push(r);
                    }
                }
                else {
                    if (this._actionData.ruleAssignMap[r.id].current) {
                        this._removeAlertList.push(r);
                    }
                    else {
                        this._newAlertList.push(r);
                    }
                }
            });

            if (this._removeAlertList.length !== 0 || this._newAlertList.length !== 0) {
                this._hasChange = true;
            }

            setTimeout(() => {
                this.allowSubmitCallback(this._hasChange);
            }, 0);
        });
    }
}