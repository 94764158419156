import { IVirtualDeviceCalendarItem } from "../../VirtualDevice/virtualDevice.common";

export class PolicyRawInfoContent {
    Task?: {
        [settingKey: string]: { resourceData: any };
    };
    Calendar?: {
        calendarList: IVirtualDeviceCalendarItem[];
    };
    Application?: {
        applicationList: {
            name: string,
            version: string,
            modelList: string[],
            components: {
                base: {
                    type: string;
                    link: string;
                    checksum?: string;
                    checksumAlgorithm?: string;
                    mimeType?: string;
                    packageName?: string;
                    className?: string;
                    action?: string;
                    versionCode?: string;
                    versionName?: string;
                    allowUninstall?: boolean;
                    splits?: string[];
                },
                [splitKey: string]: {
                    type: string;
                    link: string;
                    checksum?: string;
                    checksumAlgorithm?: string;
                    mimeType?: string;
                    packageName?: string;
                    className?: string;
                    action?: string;
                    versionCode?: string;
                    versionName?: string;
                    allowUninstall?: boolean;
                    splits?: string[];
                }
            }
        }[];
    };
    Certificate?: {
        certificateList: { certificateID: string, isRevoked: boolean }[];
        metadata?: {
            rawData: any;
        }
    }
}

export class PolicyInfoUsage {
    group: {
        groupID: string;
        groupIDPath: string[];
        groupName: string;
        groupNamePath: string[];
        isDefault: boolean;
        parentID: string;
        policies: {
            Application?: string[];
            Configuration?: string[];
            Security?: string[];
            FirmwareUpdate?: string[];
            Certificate?: string[];
        }
    };
    total: number;
    synced: number;
}

export class PolicyRawInfo {
    isDefault?: boolean;
    isManaged?: boolean;
    isUsed?: boolean;
    policyName: string;
    policyType: string;
    policyID: string;
    policyContent: PolicyRawInfoContent;
    usage?: PolicyInfoUsage[];
    source?: any; // { System: xxx}, {PluginInstance: xxx}
}