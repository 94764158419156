import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { GroupRawInfo } from '../group/api.group.common';

export interface IListPolicyGroupPathParameter {
    devicePolicyID: string;
}

export class APIListPolicyGroupManager extends APIBaseManager<IListPolicyGroupPathParameter, void, void, GroupRawInfo[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListGroupInPolicy';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IListPolicyGroupPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'devicePolicies/' + pathParameters.devicePolicyID + '/groups';
    }
}