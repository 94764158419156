import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IReportData } from './api.report.data';

export interface IGetReportPathParameter {
    weeklyReportID: string;
}

export class APIGetReportManager extends APIBaseManager<IGetReportPathParameter, void, void, IReportData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetReport';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IGetReportPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'accounts/weeklyReports/' + pathParameters.weeklyReportID;
    }
}