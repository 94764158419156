<div class="row">
    <div class="col-12">
        <button id="id_accKey_do_create" type="button" class="btn btn-sm btn-action btn-light" data-toggle="modal" data-target="#accKeyFuncModalHost"
            (click)="createNewAccessKey()">
            <span>Create </span>
            <span class="d-none d-md-inline-block">access key</span>
        </button>
        <button id="id_accKey_do_delete" type="button" class="btn btn-sm btn-action btn-light ml-2" [disabled]="!_key" data-toggle="modal"
            data-target="#accKeyFuncModalHost" (click)="removeAccessKey()">
            <span>Delete </span>
            <span class="d-none d-md-inline-block">access key</span>
        </button>
        <button id="id_accKey_do_inactive" *ngIf="_key && _key.isAccessKeyEnabled" type="button" class="btn btn-sm btn-action btn-light btn-status ml-2"
            data-toggle="modal" data-target="#accKeyFuncModalHost" (click)="changeAccessKeyStatus()">
            <span class="red-1">Make inactive</span>
        </button>
        <button id="id_accKey_do_active" *ngIf="_key && !_key.isAccessKeyEnabled" type="button" class="btn btn-sm btn-action btn-light btn-status ml-2"
            (click)="changeAccessKeyStatus()">
            <span class="green-3">Make active</span>
        </button>
        <button id="id_accKey_do_refreshAll" type="button" class="btn btn-sm btn-action btn-light ml-2" (click)="refreshAccessKeyList()">
            <i class="fas fa-redo-alt"></i>
        </button>
    </div>
    <div *ngIf="_loginResult" class="col-12 mt-2">
        <label>Login token by accessKey {{ _key?.accessKeyID }}</label>
        <div class="border p-2 text-break">
            <ng-template [ngIf]="_loginResult.token">{{ _loginResult.token }}</ng-template>
            <div *ngIf="_loginResult.error" class="red-1">{{ _loginResult.error }}</div>
        </div>
    </div>
    <div class="col-12 mt-2">
        <div class="list-group">
            <div class="list-group-item list-group-header">
                <div class="row">
                    <span class="col-4 title">AccessKey ID</span>
                    <span class="col-2 title">Created</span>
                    <span class="col-2 title">Last used</span>
                    <span class="col title">Status</span>
                    <span class="col title">Note</span>
                </div>
            </div>
            <div *ngIf="!_loading; else templateLoadKey" class="list-group-content list-group-hoverless">
                <div *ngFor="let key of _keyList" class="list-group-item" (click)="selectAccessKey(key)">
                    <div class="row align-items-center">
                        <div class="col-4">
                            <i class="check-item fas fa-check-circle mr-1"
                                [class.active]="_key?.accessKeyID === key.accessKeyID"></i>
                            {{ key.accessKeyID }}
                        </div>
                        <div class="col-2">{{ key.createdDate | date: 'yyyy-MM-dd HH:mm:ss' }}</div>
                        <div class="col-2">{{ key.lastUsedDate | date: 'yyyy-MM-dd HH:mm:ss' }}</div>
                        <div class="col">
                            <span *ngIf="key.isAccessKeyEnabled; else templateInactive" class="key-status-active">
                                Active
                            </span>
                            <ng-template #templateInactive>
                                <span *ngIf="!_keyStatusUpdateMap[key.accessKeyID]; else templateUpdateStatus"
                                    class="key-status-inactive">
                                    Inactive
                                </span>
                                <ng-template #templateUpdateStatus>
                                    <span class="ml-2">
                                        Inactive -> Active
                                    </span>
                                </ng-template>
                            </ng-template>
                        </div>
                        <div class="col block-remark">
                            <div *ngIf="!_keyRemarkMap[key.accessKeyID].inEditMode; else templateRemarkEdit"
                                class="d-flex align-items-center">
                                <span *ngIf="!_keyRemarkMap[key.accessKeyID].updating; else templateRemarkUpdate"
                                    class="form-control-plaintext form-control-sm">
                                    {{ key.accessKeyRemark }}
                                </span>
                                <ng-template #templateRemarkUpdate>
                                    <span class="form-control-plaintext form-control-sm"
                                        [class.text-loading]="_keyRemarkMap[key.accessKeyID].updating"
                                        [attr.data-text]="_keyRemarkMap[key.accessKeyID].remark">
                                        {{ _keyRemarkMap[key.accessKeyID].remark }}
                                    </span>
                                </ng-template>
                                <i class="fas fa-pen ml-2" (click)="turnToEditMode(key)"></i>
                            </div>
                            <ng-template #templateRemarkEdit>
                                <div class="d-flex align-items-center">
                                    <input type="text" class="form-control form-control-sm"
                                        [(ngModel)]="_keyRemarkMap[key.accessKeyID].remark">
                                    <i class="fas fa-check ml-2" (click)="updateAccessKeyRemark(key)"></i>
                                </div>
                            </ng-template>
                            <div *ngIf="_keyRemarkMap[key.accessKeyID].errorMessage" class="red-1">
                                {{ _keyRemarkMap[key.accessKeyID].errorMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #templateLoadKey>
                <div class="loading">
                    <div class="loading-box"></div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<div id="accKeyFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template accesskey-func></ng-template>
</div>