import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { GroupRawInfo } from './api.group.common';

export interface ICreateGroupTxData {
    groupNameList: string[];
    parentID?: string;
}

export class APICreateGroupManager extends APIBaseManager<void, void, ICreateGroupTxData, GroupRawInfo[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_CreateDevGroup';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'deviceGroups';
    }
}