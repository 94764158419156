<div class="list-group-item">
  <div>
    <strong>{{'key-dev-usbDebugEnable' | translate | capitalFirstWord}} :</strong>
    <span class="ml-2">{{_debug_usb_enabled}}</span>
  </div>
  <div>
    <strong>{{'key-dev-tcpDebugEnable' | translate | capitalFirstWord}} :</strong>
    <span class="ml-2">{{_debug_tcp_enabled}}</span>
  </div>
</div>
