<div class="d-flex">
    <button type="button" class="btn btn-sm btn-action btn-light ml-auto" data-toggle="modal"
        data-target="#userPolicyPermissionModalHost">
        <i class="fas fa-book mr-1"></i>
        Role permission
    </button>
</div>
<div class="list-group mt-2">
    <div class="list-group-item list-group-header">
        <div class="row align-items-center">
            <div class="col title">
                <span>Name</span>
            </div>
            <div class="col title">
                <span>Description</span>
            </div>
            <div class="col title">
                <span># of users</span>
            </div>
        </div>
    </div>
    <div *ngIf="!_loading; else templateLoadingUserPolicyTable" class="list-group-content list-group-hoverless">
        <div *ngFor="let role of _userRoleList" class="list-group-item">
            <div class="row align-items-center">
                <div class="col d-flex align-items-center">
                    {{ role.name }}
                </div>
                <div class="col text-break">
                    {{ role.desc }}
                </div>
                <div class="col text-break">
                    <a [routerLink]="'/app/admin/users/role/' + role.name" (click)="viewUserList(role)">
                        <u>{{ role.userList?.length }}</u>
                    </a>
                    <i *ngIf="_activeUserRoleName === role.name" class="fas fa-eye ml-2"></i>
                </div>
            </div>
        </div>
    </div>
    <ng-template #templateLoadingUserPolicyTable>
        <div class="loading">
            <p>Please wait...</p>
            <div class="loading-box"></div>
        </div>
    </ng-template>
</div>
<div class="text-right">
    <small class="txt-bold">License -> </small>
    <small *ngFor="let roleUsage of _userRoleUsageList; let last = last">
        {{ roleUsage.roleName }} : <strong><span class="red-1">{{ roleUsage.usage }}</span> / {{ roleUsage.limit
            }}</strong>
        <span *ngIf="!last">, </span>
    </small>
</div>
<div class="mt-2">
    <router-outlet></router-outlet>
</div>
<div id="userPolicyPermissionModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <div class='modal-dialog config-dlg' role="document">
        <div class='modal-content'>
            <div class='modal-header'>
                <span class="modal-title">Role permission</span>
                <button #dlgClose type="button" class="close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class='modal-body'>
                <na-user-policy-scope></na-user-policy-scope>
                <div class="row control-block d-flex justify-content-center align-items-center mt-4">
                    <button type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal">
                        <span class="fas fa-times-circle"></span>
                        {{ 'key-close' | translate | capitalFirstWord }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>