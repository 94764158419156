import { Component, Input } from '@angular/core';
import { DeviceInfo } from '../../data/device-info';
import { NetworkType } from '../../data/device-net-info';
import { ConstantService } from '../../../../../app/lib/common/constant.service';

@Component({
    selector: 'na-device-network-info',
    templateUrl: './device-info-net.component.html',
    styleUrls: ['./device.style.css']
})
export class DeviceInfoNetComponent {
    _enumNetworkType: typeof NetworkType = NetworkType;

    @Input('device') _device: DeviceInfo;
    @Input('networkType') _currentNetworkType = NetworkType.None;

    constructor(public constantSvc: ConstantService) {}
}