import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IVirtualDeviceCalendarItem } from './virtualDevice.common';

export interface ISetVirtualDeviceCalendarPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export interface ISetVirtualDeviceCalendarTxData {
    calendarList: IVirtualDeviceCalendarItem[];
}

export class APISetVirtualDeviceCalendarManager extends APIBaseManager<ISetVirtualDeviceCalendarPathParameter, void, ISetVirtualDeviceCalendarTxData, IVirtualDeviceCalendarItem[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_SetVirtualDeviceCalendar';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: ISetVirtualDeviceCalendarPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/' + pathParameters.virtualDevicePairedID + '/calendars';
    }
}