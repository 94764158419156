<div>
  <div *ngIf="_isLoading; else templateAlertNotLoading" class="loading">
    <div class="loading-box"></div>
  </div>
  <ng-template #templateAlertNotLoading>
    <div *ngIf="_alertList.length === 0; else templateAlertApply" class="mb-4">
      Do not have any alert.
      <a type="button" data-dismiss="modal" class="ml-2" [routerLink]="['/app/notification/alert/config']">
        Click to create an alert
      </a>
    </div>
    <ng-template #templateAlertApply>
      <div>
        <div class="mb-1 d-flex align-items-center">
          Reassign the alert applied to your device <strong class="ml-1">{{ _actionData?.deviceNames }}</strong>
          <button type="button" class="btn btn-sm btn-action btn-light ml-auto" (click)="reset()">
            Reset
          </button>
        </div>
        <div class="alert-block-container">
          <div class="alert-block">
            <label for="id-assigned-alerts" class="txt-bold">Assigned alerts</label>
            <div id="id-assigned-alerts" class="list-group mb-2 mb-sm-0">
              <div class="list-group-item list-group-header black">
                <div class="row">
                  <div class="col title">
                    Alert name
                  </div>
                  <div class="col title">
                    Alert type
                  </div>
                </div>
              </div>
              <div id="accordion-assigned-alerts"
                class="accordion list-group-content list-group-hoverless list-group-content-modal">
                <div *ngIf="!hasAlertAssigned()" class="no-alert-hint">No assigned alert</div>
                <ng-template ngFor let-alert [ngForOf]="_alertList">
                  <ng-template [ngIf]="_actionData.ruleAssignMap[alert.id].after">
                    <div class="list-group-item" [class.active]="_alert?.id === alert.id" data-toggle="collapse"
                      [attr.data-target]="'#alert-t-' + alert.shortID" aria-expanded="false" (click)="viewAlert(alert)">
                      <div class="row align-items-center">
                        <div class="col text-break">
                          <i class=" fas fa-minus-circle alert-ctrl" (click)="removeAlert(alert)">
                            <i class="sr-only">Unassign the alert {{alert.name}}</i>
                          </i>
                          {{ alert.name }}
                        </div>
                        <div class="col text-break">{{ alert.eventType.displayName }}</div>
                      </div>
                    </div>
                    <div [id]="'alert-t-' + alert.shortID" class="collapse layout-mobile border"
                      data-parent="#accordion-assigned-alerts">
                      <alert-config-report *ngIf="_alert?.id === alert.id" [alert]="_alert">
                      </alert-config-report>
                    </div>
                  </ng-template>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="alert-transfer-block">
            <i class="fas fa-exchange-alt"></i>
          </div>
          <div class="alert-block">
            <label for="id-free-alerts" class="txt-bold">Available alerts</label>
            <div id="id-free-alerts" class="list-group mb-2 mb-sm-0">
              <div class="list-group-item list-group-header black">
                <div class="row">
                  <div class="col title">
                    Alert name
                  </div>
                  <div class="col title">
                    Alert type
                  </div>
                </div>
              </div>
              <div id="accordion-unassigned-alerts"
                class="accordion list-group-content list-group-hoverless list-group-content-modal">
                <ng-template ngFor let-alert [ngForOf]="_alertList">
                  <ng-template [ngIf]="!_actionData.ruleAssignMap[alert.id].after">
                    <div class="list-group-item" [ngClass]="_alert?.id === alert.id ? 'active' : ''"
                      data-toggle="collapse" [attr.data-target]="'#alert-b-' + alert.shortID" aria-expanded="false"
                      (click)="viewAlert(alert)">
                      <div class="row align-items-center">
                        <div class="col text-break">
                          <i class="fas fa-plus-circle alert-ctrl" (click)="assignAlert(alert)">
                            <i class="sr-only">Assgin the alert {{alert.name}}</i>
                          </i>
                          {{ alert.name }}
                        </div>
                        <div class="col text-break">{{ alert.eventType.displayName }}</div>
                      </div>
                    </div>
                    <div [id]="'alert-b-' + alert.shortID" class="collapse layout-mobile border"
                      data-parent="#accordion-unassigned-alerts">
                      <alert-config-report *ngIf="_alert?.id === alert.id" [alert]="_alert">
                      </alert-config-report>
                    </div>
                  </ng-template>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="row layout-desktop">
          <div class="col-12">
            <label for="id-alert-detail" class="txt-bold">Alert detail :</label>
            <div id="id-alert-detail" class="border">
              <alert-config-report [alert]="_alert"></alert-config-report>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>