import { Component } from "@angular/core";
import { VirtualDlgComponent } from "app/content/virtual/dlg/virtual-dlg.component";
import { IRemoteFuncCtrl } from "./remote-func.def";


@Component({
    templateUrl: './remote-dlg-takeScreenshot.component.html'
})
export class RemoteDlgTakeScreenshotComponent extends VirtualDlgComponent implements IRemoteFuncCtrl {
    title: string;
    funcName: string;
    data: any;
    other?: any;
    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;

    downloadPhoto(filenameObject: any): void {
        const filename: string = filenameObject.value + ".jpg";
        this.onActionCompleted({ funcName: this.funcName, isFault: false, data: filename });

        setTimeout(() => { filenameObject.value = ''; }, 0);
    }
}