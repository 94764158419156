import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPipeModule } from '../../../../app/lib/pipes/custom-pipe.module';
import { TranslateModule } from '../../../../app/translate/translate.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DeviceLabelPickerComponent } from './dev-label-picker.component';
import { DeviceLabelDetailComponent } from './comp/dev-label-detail.component';
import { DeviceLabelFuncService } from './dlg/dev-label-func.service';
import { DeviceLabelDeleteDlgComponent } from './dlg/dev-label-delete-dlg.component';
import { DeviceLabelAttachDlgComponent } from './dlg/dev-label-attach-dlg.component';
import { DeviceLabelFuncDirective } from './dlg/dev-label-func.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        CustomPipeModule
    ],
    providers: [
        DeviceLabelFuncService
    ],
    declarations: [
        DeviceLabelPickerComponent,
        DeviceLabelDetailComponent,
        DeviceLabelDeleteDlgComponent,
        DeviceLabelAttachDlgComponent,
        DeviceLabelFuncDirective
    ],
    exports: [
        DeviceLabelPickerComponent,
        DeviceLabelDetailComponent,
        DeviceLabelDeleteDlgComponent,
        DeviceLabelAttachDlgComponent,
        DeviceLabelFuncDirective
    ]
})
export class DeviceLabelModule {}