import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
    name: 'safe'
})
export class SafePipe implements PipeTransform {

    constructor(private san: DomSanitizer) { }

    transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html':
                {
                    return this.san.bypassSecurityTrustHtml(value);
                }
            case 'style':
                {
                    return this.san.bypassSecurityTrustStyle(value);
                }
            case 'script':
                {
                    return this.san.bypassSecurityTrustScript(value);
                }
            case 'url':
                {
                    return this.san.bypassSecurityTrustUrl(value);
                }
            case 'resourceUrl':
                {
                    return this.san.bypassSecurityTrustResourceUrl(value);
                }
            default: 
                throw new Error(`invalid safe type specified: ${type}`);
        }
    }
}