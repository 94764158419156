import { EventEmitter, Injectable, Output } from "@angular/core";
import { Logger } from "app/lib/common/logger";
import { EMPTY, Observable, of } from "rxjs";
import { catchError, expand, map, reduce } from "rxjs/operators";
import { CertInfo } from "./cert.data";
import { REFRESH_DURATION } from "app/lib/common/helper.lib";
import { NAService } from "app/API/na.service";
import { AccountService } from "app/entry/account.service";
import { IListCertRxData } from "app/API/v1/cert/api.cert.list";
import { IAPIRx } from "app/API/api.base";
import { ICertData } from "app/API/v1/cert/api.cert.data";
import { IAddCertRxData } from "app/API/v1/cert/api.cert.add";

@Injectable()
export class CertService {
    private readonly TAG: string = 'Cert';
    private readonly AUTO_UPDATE_DURATION: number = REFRESH_DURATION * 60 * 1000;
    private readonly QUERY_LIMIT: number = 100;
    _certList: CertInfo[] = [];
    private _lastUpdateTime: number = 0;

    @Output() onCertRemoved = new EventEmitter<{ removedCerts: CertInfo[], currentCerts: CertInfo[] }>();
    @Output() onCertAdded = new EventEmitter<{ addedCerts: CertInfo[], currentCerts: CertInfo[] }>();

    constructor(private accountSvc: AccountService, private naSvc: NAService) { }

    getCerts(refresh: boolean = false): Observable<{ certs: CertInfo[], isFault: boolean, errorMessage?: string }> {
        if (this.needUpdate(refresh)) {
            let skip: number = 0;
            let limit: number = this.QUERY_LIMIT;
            this._certList = [];

            return this.getCertQuery(skip, limit).pipe(
                expand((res: { certs: CertInfo[], isFault: boolean, hasNext?: boolean, errorMessage?: string }) => {
                    skip += limit;
                    return res.hasNext ? this.getCertQuery(skip, limit) : EMPTY
                }),
                reduce((acc, curr) => {
                    acc.certs = !curr.isFault ? acc.certs.concat(curr.certs) : acc.certs;
                    return acc;
                }, { certs: [], isFault: false }),
                catchError((error: string) => of({ certs: [], isFault: true, errorMessage: error }))
            );
        }

        return of({ certs: this._certList, isFault: false });
    }

    private getCertQuery(skip: number, limit: number): Observable<{ certs: CertInfo[], isFault: boolean, hasNext?: boolean, errorMessage?: string }> {
        return this.naSvc.listCerts({ skip: skip, limit: limit }, this.accountSvc.token).pipe(
            map((res: IAPIRx<IListCertRxData>) => {
                if (res.error) {
                    throw `Get certificates failed. Error: ${res.errorMessage}`;
                }

                const transformedCerts: CertInfo[] = res.data.itemList.map(rawCert => {
                    const transformedCert: CertInfo = this.transformCertData(rawCert);
                    this._certList.push(transformedCert);
                    return transformedCert;
                });

                return { certs: transformedCerts, hasNext: res.data?.skip + res.data?.itemList.length < res.data?.total, isFault: false };
            }),
        );
    }

    private transformCertData(cert: ICertData | IAddCertRxData): CertInfo {
        return new CertInfo({ id: cert.certificateID, name: cert.certificateName, expiryDate: cert.certificateExpiryDate, fingerprint: cert.certificateFingerprint });
    }

    addCert(certName: string, certContent: string): Observable<{ isFault: boolean, errorMessage?: string }> {
        Logger.logInfo(this.TAG, 'addCert', `Add cert ${certName}`);
        // check if the certName exists already
        if (this._certList.find(cert => cert.name === certName)) {
            return of({isFault: true, errorMessage: 'Duplicate certificate name'});
        }

        return this.naSvc.addCert({ certificateName: certName, certificateData: certContent }, this.accountSvc.token).pipe(
            map((res: IAPIRx<IAddCertRxData>) => {
                if (res.error != 0) {
                    return { isFault: true, errorMessage: res.errorMessage };
                }

                const newCert: CertInfo = this.transformCertData(res.data);
                this._certList.push(newCert);
                this.onCertAdded.emit({ addedCerts: [newCert], currentCerts: this._certList });

                return { isFault: false };
            })
        );
    }

    deleteCert(cert: CertInfo): Observable<{ isFault: boolean, errorMessage?: string }> {
        Logger.logInfo(this.TAG, 'deleteCert', 'Delete cert: ', cert);

        return this.naSvc.deleteCert(cert.id, this.accountSvc.token).pipe(
            map((res: IAPIRx<void>) => {
                if (res.error != 0) {
                    return { isFault: true, errorMessage: res.errorMessage };
                }

                const found: CertInfo = this._certList.find(currentCert => currentCert.id === cert.id);
                if (found) {
                    this._certList.splice(this._certList.indexOf(found), 1);
                }

                this.onCertRemoved.emit({ removedCerts: [found], currentCerts: this._certList });

                return { isFault: false };
            })
        );
    }

    private needUpdate(force: boolean): boolean {
        return force || this._certList.length == 0 || (new Date().getTime() - this._lastUpdateTime > this.AUTO_UPDATE_DURATION);
    }
}