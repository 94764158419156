<div>
    <div *ngIf="!_loading; else templateLoadingCalendarConfig">
        <div *ngIf="!_data; else templateProviderRegistered" class="text-center">
            <p>App is not registered.</p>
            <p>Please register it from the 'Registration' page first.</p>
        </div>
        <ng-template #templateProviderRegistered>
            <div class="mx-2">Customize the look & feel</div>
            <div class="content">
                <div class="data-block">
                    <h5>General settings</h5>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label for="col-ge-pin" class="col-12 col-md-4 col-form-label col-form-label-sm">
                                    PIN
                                </label>
                                <div class="col-12 col-md-8">
                                    <input id="col-ge-pin" type="text" class="form-control form-control-sm"
                                        minlength="4" maxlength="4" [class.changed]="_data.pin.isChanged"
                                        [disabled]="_updating" [(ngModel)]="_data.pin.current">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex align-items-center">
                            <input id="ch-ge-onsite-book" type="checkbox" class="cmn-checkbox"
                                [class.changed]="_data.calendar.enableOnsiteBook.isChanged" [disabled]="_updating"
                                [(ngModel)]="_data.calendar.enableOnsiteBook.current" />
                            <label for="ch-ge-onsite-book">
                                <svg>
                                    <use xlink:href="#checkmark" />
                                </svg>
                            </label>
                            <span class="ml-2">Enable Onsite booking</span>
                        </div>
                        <div class="col-12 d-flex align-items-center">
                            <input id="ch-ge-future-book" type="checkbox" class="cmn-checkbox"
                                [class.changed]="_data.calendar.enableFutureEventBook.isChanged" [disabled]="_updating"
                                [(ngModel)]="_data.calendar.enableFutureEventBook.current" />
                            <label for="ch-ge-future-book">
                                <svg>
                                    <use xlink:href="#checkmark" />
                                </svg>
                            </label>
                            <span class="ml-2">Enable Future-Event booking</span>
                        </div>
                        <div class="col-12 d-flex align-items-center">
                            <input id="ch-ge-future-cancel" type="checkbox" class="cmn-checkbox"
                                [class.changed]="_data.calendar.enableFutureEventCancel.isChanged"
                                [disabled]="_updating" [(ngModel)]="_data.calendar.enableFutureEventCancel.current" />
                            <label for="ch-ge-future-cancel">
                                <svg>
                                    <use xlink:href="#checkmark" />
                                </svg>
                            </label>
                            <span class="ml-2">Enable Future-Event cancelling</span>
                        </div>
                        <div class="col-12 d-flex align-items-center">
                            <input id="ch-timeline-enable" type="checkbox" class="cmn-checkbox"
                                [class.changed]="_data.calendar.hideTimeline.isChanged" [disabled]="_updating"
                                [(ngModel)]="_data.calendar.hideTimeline.current" />
                            <label for="ch-timeline-enable">
                                <svg>
                                    <use xlink:href="#checkmark" />
                                </svg>
                            </label>
                            <span class="ml-2">Hide timeline</span>
                        </div>
                        <div class="col-12">
                            <div class="ctrl-block d-flex align-items-center">
                                <label for="ch-fontsize">Font size</label>
                                <div class="d-flex ctrl-block-options form-control form-control-sm w-auto" [class.changed]="_data.fontsizeRatio.isChanged">
                                    <div *ngFor="let fs of _fontsizeOptionList" class="cmn-radio mx-2">
                                        <input [id]="fs.id" type="radio" name="fontsizeOption"
                                            [checked]="fs.ratio === _data.fontsizeRatio.current"
                                            [disabled]="_updating"
                                            (click)="_data.fontsizeRatio.current = fs.ratio">
                                        <label [for]="fs.id" class="radio"></label>
                                        <span class="ml-2">{{ fs.displayName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12 col-lg-6">
                            <na-customization-item-img [title]="'Logo'" [fileFormat]="['png', 'svg']"
                                [disabled]="_updating" [data]="_data.logo" [maxAllowSize]="0.5"
                                (onItemChanged)="onLogoImageChanged($event)">
                            </na-customization-item-img>
                        </div>
                        <div class="col-12 col-lg-6 mt-2 mt-lg-0">
                            <na-customization-item-img [title]="'Background image'" [fileFormat]="['png', 'svg']"
                                [data]="_data.background" [maxAllowSize]="4" [disabled]="_updating"
                                (onItemChanged)="onBackgroundImageChanged($event)">
                            </na-customization-item-img>
                        </div>
                    </div>
                </div>
                <div class="data-block">
                    <h5>Main content color</h5>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="form-group row">
                                <label for="col-main-fg" class="col-12 col-form-label col-form-label-sm">
                                    Foreground
                                </label>
                                <div class="col-12">
                                    <input id="col-main-fg" type="color" class="form-control form-control-sm"
                                        [class.changed]="_data.theme.foreground.isChanged" [disabled]="_updating"
                                        [(ngModel)]="_data.theme.foreground.current">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="form-group row">
                                <label for="col-main-free" class="col-12 col-form-label col-form-label-sm">
                                    Available
                                </label>
                                <div class="col-12">
                                    <na-colorPicker [color]="_data.theme.freeColor.current"
                                        [isChanged]="_data.theme.freeColor.isChanged" [disabled]="_updating"
                                        (onColorChanged)="_data.theme.freeColor.current = $event">
                                    </na-colorPicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="form-group row">
                                <label for="col-main-inuse" class="col-12 col-form-label col-form-label-sm">
                                    In Use
                                </label>
                                <div class="col-12">
                                    <na-colorPicker [color]="_data.theme.busyColor.current"
                                        [isChanged]="_data.theme.busyColor.isChanged" [disabled]="_updating"
                                        (onColorChanged)="_data.theme.busyColor.current = $event">
                                    </na-colorPicker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="data-block">
                    <h5>Timeline color</h5>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="form-group row">
                                <label for="col-timeline-bg" class="col-12 col-form-label col-form-label-sm">
                                    Background
                                </label>
                                <div class="col-12">
                                    <input id="col-timeline-bg" type="color" class="form-control form-control-sm"
                                        [class.changed]="_data.theme.timeline.bgColor.isChanged" [disabled]="_updating"
                                        [(ngModel)]="_data.theme.timeline.bgColor.current">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="form-group row">
                                <label for="col-timeline-future" class="col-12 col-form-label col-form-label-sm">
                                    Future event
                                </label>
                                <div class="col-12">
                                    <na-colorPicker [color]="_data.theme.timeline.futureEventTimeBlockColor.current"
                                        [isChanged]="_data.theme.timeline.futureEventTimeBlockColor.isChanged"
                                        [disabled]="_updating"
                                        (onColorChanged)="_data.theme.timeline.futureEventTimeBlockColor.current = $event">
                                    </na-colorPicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="form-group row">
                                <label for="col-timeline-current" class="col-12 col-form-label col-form-label-sm">
                                    Current event
                                </label>
                                <div class="col-12">
                                    <na-colorPicker [color]="_data.theme.timeline.currentEventTimeBlockColor.current"
                                        [isChanged]="_data.theme.timeline.currentEventTimeBlockColor.isChanged"
                                        [disabled]="_updating"
                                        (onColorChanged)="_data.theme.timeline.currentEventTimeBlockColor.current = $event">
                                    </na-colorPicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="form-group row">
                                <label for="col-timeline-expire" class="col-12 col-form-label col-form-label-sm">
                                    Expired event
                                </label>
                                <div class="col-12">
                                    <na-colorPicker [color]="_data.theme.timeline.expiredEventTimeBlockColor.current"
                                        [isChanged]="_data.theme.timeline.expiredEventTimeBlockColor.isChanged"
                                        [disabled]="_updating"
                                        (onColorChanged)="_data.theme.timeline.expiredEventTimeBlockColor.current = $event">
                                    </na-colorPicker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="data-block">
                    <h5>Lightbar color</h5>
                    <div class="row">
                        <div class="col-12 d-flex align-items-center">
                            <input id="ch-lightbar-activate" type="checkbox" class="cmn-checkbox"
                                [class.changed]="_data.lightbar.activate.isChanged" [disabled]="_updating"
                                [(ngModel)]="_data.lightbar.activate.current" />
                            <label for="ch-lightbar-activate">
                                <svg>
                                    <use xlink:href="#checkmark" />
                                </svg>
                            </label>
                            <span class="ml-2">Activate</span>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="form-group row">
                                <label for="col-lightbar-free" class="col-12 col-form-label col-form-label-sm">
                                    Available
                                </label>
                                <div class="col-12">
                                    <input id="col-lightbar-free" type="color" class="form-control form-control-sm"
                                        [class.changed]="_data.lightbar.available.color.isChanged"
                                        [disabled]="_updating || !_data.lightbar.activate.current"
                                        [(ngModel)]="_data.lightbar.available.color.current">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="form-group row">
                                <label for="col-lightbar-inuse" class="col-12 col-form-label col-form-label-sm">
                                    InUse
                                </label>
                                <div class="col-12">
                                    <input id="col-lightbar-inuse" type="color" class="form-control form-control-sm"
                                        [class.changed]="_data.lightbar.busy.color.isChanged"
                                        [disabled]="_updating || !_data.lightbar.activate.current"
                                        [(ngModel)]="_data.lightbar.busy.color.current">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4 text-center">
                <div *ngIf="_errorMessage" class="my-2">{{ _errorMessage }}</div>
                <div *ngIf="_data" class="d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_updating"
                        (click)="resetCalendarInfo()">
                        <i class="fas fa-times mr-1"></i>
                        Reset
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-ligth ml-2"
                        [disabled]="_updating || !_data?.isValid() || !_data.isChanged()"
                        (click)="updateCalendarInfo()">
                        <i class="fas fa-check mr-1"></i>
                        Update
                    </button>
                    <div *ngIf="_updating" class="loading loading-p0 ml-4">
                        <div class="loading-box"></div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
    <ng-template #templateLoadingCalendarConfig>
        <div class="loading">
            <p>Please wait...</p>
            <div class="loading-box"></div>
        </div>
    </ng-template>
</div>