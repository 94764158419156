import { Injectable, Directive } from '@angular/core';
import { AppConfigService } from '../app.config';
import { AccountService } from '../entry/account.service';
import { ConstantService } from '../lib/common/constant.service';
import { LOCALSTORAGE_KEY_USER_PREFERENCE } from '../lib/common/common.data';
import { HelperLib } from '../lib/common/helper.lib';
import { DashboardView, UserPreferenceEventLogInfo, UserPreferenceGlobalInfo, UserPreferenceHomeInfo, UserPreferenceInfo, UserPreferenceLicenseInfo } from './user-preference.data';

@Directive()
@Injectable()
export class UserPreferenceService {
    _userPreferenceMap: { [accountName: string]: UserPreferenceInfo };

    get userPreference(): UserPreferenceInfo {
        return this._userPreferenceMap[this.accountSvc.accountName];
    }

    constructor(private accountSvc: AccountService, private constantSvc: ConstantService) {
        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            if (isLogin) {
                this.init();
            }
        });

        this.init();
    }

    private init(): void {
        this.getUserPrefereceFromCache();

        this._userPreferenceMap[this.accountSvc.accountName].global = this._userPreferenceMap[this.accountSvc.accountName].global || new UserPreferenceGlobalInfo();
        this._userPreferenceMap[this.accountSvc.accountName].global.theme = this._userPreferenceMap[this.accountSvc.accountName].global.theme || 'standard';
        this._userPreferenceMap[this.accountSvc.accountName].home = this._userPreferenceMap[this.accountSvc.accountName].home || new UserPreferenceHomeInfo();
        this._userPreferenceMap[this.accountSvc.accountName].home.overviewLayout = this._userPreferenceMap[this.accountSvc.accountName].home.overviewLayout || AppConfigService.configs.preference.home.overviewLayout;
        this._userPreferenceMap[this.accountSvc.accountName].home.group = this._userPreferenceMap[this.accountSvc.accountName].home.group || { defaultAccount: this.accountSvc.accountName };
        this._userPreferenceMap[this.accountSvc.accountName].home.device = this._userPreferenceMap[this.accountSvc.accountName].home.device || {};
        this._userPreferenceMap[this.accountSvc.accountName].home.device.view = this._userPreferenceMap[this.accountSvc.accountName].home.device.view || DashboardView.table;
        this._userPreferenceMap[this.accountSvc.accountName].home.device.pageCapacity = this._userPreferenceMap[this.accountSvc.accountName].home.device.pageCapacity || 30;
        this._userPreferenceMap[this.accountSvc.accountName].home.device.tableLayoutColumnOptionMap = this._userPreferenceMap[this.accountSvc.accountName].home.device.tableLayoutColumnOptionMap || [
            { key: this.constantSvc.DEVKEY_FAKE_DISPLAYNAME, checked: true },
            { key: this.constantSvc.DEVKEY_INFO_MODEL, checked: true },
            { key: this.constantSvc.DEVKEY_NET_LAN_MAC, checked: false },
            { key: this.constantSvc.DEVKEY_NET_LAN_IP, checked: false },
            { key: this.constantSvc.DEVKEY_INFO_FW_VERSION, checked: true },
            { key: this.constantSvc.DEVKEY_INFO_APKVERSION, checked: false },
            { key: this.constantSvc.DEVKEY_FAKE_HEARTBEAT, checked: false },
            { key: this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE, checked: false },
            { key: this.constantSvc.DEVKEY_FAKE_GROUPNAME, checked: true }
        ].reduce((acc, curr) => {
            acc[curr.key] = curr.checked;
            return acc;
        }, {});

        this._userPreferenceMap[this.accountSvc.accountName].eventLog = this._userPreferenceMap[this.accountSvc.accountName].eventLog || new UserPreferenceEventLogInfo();
        this._userPreferenceMap[this.accountSvc.accountName].eventLog.filter = this._userPreferenceMap[this.accountSvc.accountName].eventLog.filter || {
            level: {},
            time: {},
            event: {}
        };
        this._userPreferenceMap[this.accountSvc.accountName].license = this._userPreferenceMap[this.accountSvc.accountName].license || new UserPreferenceLicenseInfo();
        this._userPreferenceMap[this.accountSvc.accountName].license.filter = this._userPreferenceMap[this.accountSvc.accountName].license.filter || {
            licenseType: {},
            expiryDate: {}
        };
    }

    private getUserPrefereceFromCache(): void {
        //get user preference from localstorage
        const p_raw: string = HelperLib.getLocalStorageRecord(LOCALSTORAGE_KEY_USER_PREFERENCE);
        try {
            this._userPreferenceMap = JSON.parse(p_raw);
        }
        catch (ex) {

        }

        this._userPreferenceMap = this._userPreferenceMap || {};
        this._userPreferenceMap[this.accountSvc.accountName] = this._userPreferenceMap[this.accountSvc.accountName] || new UserPreferenceInfo();
    }

    changeGlobalTheme(themeName: string): void {
        if (this._userPreferenceMap[this.accountSvc.accountName].global.theme !== themeName) {
            this._userPreferenceMap[this.accountSvc.accountName].global.theme = themeName;
            this.setUserPreference();
        }
    }

    changeHomeLayout(group: boolean): void {
        const targetLayout: string = group ? 'group' : 'device';
        if (this._userPreferenceMap[this.accountSvc.accountName].home.overviewLayout !== targetLayout) {
            this._userPreferenceMap[this.accountSvc.accountName].home.overviewLayout = targetLayout;
            this.setUserPreference();
        }
    }

    changeHomeDeviceView(view: DashboardView): void {
        if (this._userPreferenceMap[this.accountSvc.accountName].home.device.view !== view) {
            this._userPreferenceMap[this.accountSvc.accountName].home.device.view = view;
            this.setUserPreference();
        }
    }

    changeHomeDevicePageCapacity(c: number): void {
        if (this._userPreferenceMap[this.accountSvc.accountName].home.device.pageCapacity !== c) {
            this._userPreferenceMap[this.accountSvc.accountName].home.device.pageCapacity = c;
            this.setUserPreference();
        }
    }

    changeHomeTableViewColumn(key: string, show: boolean): void {
        if (this._userPreferenceMap[this.accountSvc.accountName].home.device.tableLayoutColumnOptionMap[key] !== show) {
            this._userPreferenceMap[this.accountSvc.accountName].home.device.tableLayoutColumnOptionMap[key] = show;
            this.setUserPreference();
        }
    }

    changeEventLogFilter(
        level: { [levelName: string]: boolean },
        time: { beginDate: string, beginTime: string, endDate: string, endTime: string },
        event: { [categoryName: string]: { [eventName: string]: boolean } }
    ): void {
        this._userPreferenceMap[this.accountSvc.accountName].eventLog.filter = {
            level: level,
            time: time,
            event: event
        };
        this.setUserPreference();
    }

    changeLicenseFilter(type: {[type: string]: boolean}, expiryDate: { beginDate: string, endDate: string}): void {
        this._userPreferenceMap[this.accountSvc.accountName].license.filter = {
            licenseType: type,
            expiryDate: expiryDate
        };
        this.setUserPreference();
    }

    private setUserPreference(): void {
        HelperLib.setLocalStorageRecord(LOCALSTORAGE_KEY_USER_PREFERENCE, JSON.stringify(this._userPreferenceMap));
    }
}